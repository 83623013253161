import React, { useImperativeHandle } from "react"
import styled from "styled-components"

import { useMerchantSettings } from "@onelocal/frontend-dashboard/settings"

// This component serves as an example of how to create a ref to be consumed by frontend-dashboard-lib

export interface SaveButtonProps {

}

export interface SaveButtonRef {
  save(): void
}

const Container = styled.div`
  color: black;
  background-color: white;
`

export const SaveButton = React.forwardRef<SaveButtonRef, SaveButtonProps>( ( props, ref ) => {
  const { merchantSettings } = useMerchantSettings()
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const callback = () => {}

  useImperativeHandle( ref, () => ( {
    save: callback,
  } ) )

  return (
    merchantSettings && <Container onClick={ callback }> { merchantSettings.name } </Container>
  )
} )

SaveButton.displayName = "SaveButton"


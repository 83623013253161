
import { filter, isEmpty, keyBy } from "lodash"

import { apiHelper, eventEmitterHelpers, pubSubService } from "@onelocal/frontend/common"
import { formattingHelpers } from "@onelocal/shared/common"
import type { ConnectConversation, MessagesMessage } from "../types"
import { parseConnectConversationFromApi, parseMessagesNotificationFromApi } from "../types/api"

export namespace ConversationService {
  export interface ConversationUpdatedEvent {
    conversation_id: string
    merchant_id: string
    notification_id?: string
    type?: "mark_as_read"
  }

  export interface InboundCallEvent {
    merchant_id: string
    notification_id: string
  }
}

const CONVERSATION_UPDATED_EVENT = "conversation_updated"
const CONVERSATION_NOTIFICATION_EVENT = "conversation_notification"
const EMPLOYEE_TAG_REGEX = /\[employee:([a-f0-9]+)+\|([^\]]+)\]/i
const INBOUND_PHONE_CALL_EVENT = "inbound_phone_call"
const MESSENGER_CONVERSATION_UPDATED_EVENT = "messenger_conversation_updated"

export const conversationsService = {
  getConversationName( conversation: ConnectConversation ) {
    if( ! conversation ) {
      return null
    }

    if( conversation.matchedCustomersCount > 1 || isEmpty( conversation.customer.name ) ) {
      return conversation.customer.phoneNumber.nationalFormat
    }

    return formattingHelpers.getDisplayName( conversation.customer )
  },
  async getConversation( merchantId: string, conversationId: string ) {
    return apiHelper.request( {
      method: "GET",
      url: `/merchants/${ merchantId }/messenger/conversations/${ conversationId }`,
      parsingData: parseConnectConversationFromApi,
    } )
  },
  async getNotification( merchantId: string, notificationId: string ) {
    return apiHelper.request( {
      method: "GET",
      url: `/merchants/${ merchantId }/quick-connect/notifications/${ notificationId }`,
      parsingData: parseMessagesNotificationFromApi,
    } )
  },
  onConversationNotification( handler: ( data: { body: string, conversationId: string, notificationId: string } ) => void ) {
    eventEmitterHelpers.addEventListener( CONVERSATION_NOTIFICATION_EVENT, handler )

    return () => {
      eventEmitterHelpers.removeListener( CONVERSATION_NOTIFICATION_EVENT, handler )
    }
  },
  onConversationUpdatedHandler( employeeId: string, handler?: ( data: ConversationService.ConversationUpdatedEvent ) => void ) {
    if( ! handler ) {
      const displayNotificationHandler = ( newMessages: MessagesMessage[], body: string, employeeIds: string[] ) => {
        if( isEmpty( newMessages ) || isEmpty( body ) || isEmpty( employeeIds ) ) {
          return
        }

        const newMessage = newMessages[ 0 ]

        if( newMessage.created?.by?.ref === "employees"
            && newMessage.created.by.id === employeeId
        ) {
          return
        }

        if( ! employeeIds.includes( employeeId ) ) {
          return
        }

        const employeeMatch = EMPLOYEE_TAG_REGEX.exec( body )

        if( employeeMatch ) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const [ _fullMatch, matchEmployeeId, matchEmployeeName ] = employeeMatch
          body = body.replace( EMPLOYEE_TAG_REGEX, matchEmployeeId === employeeId ? "You" : matchEmployeeName )
        }

        eventEmitterHelpers.emit( CONVERSATION_NOTIFICATION_EVENT, { body, conversationId: newMessage.conversationId, notificationId: newMessage.id } )
      }

      handler = async ( data: ConversationService.ConversationUpdatedEvent ) => {
        if( data.type === "mark_as_read" || ! data.notification_id ) {
          const conversation = await conversationsService.getConversation( data.merchant_id, data.conversation_id )
          eventEmitterHelpers.emit( CONVERSATION_UPDATED_EVENT, conversation )
          return
        }

        const notification = await conversationsService.getNotification( data.merchant_id, data.notification_id! )
        const messagesHash = keyBy( notification.messages, "id" )

        if( ! isEmpty( notification.newMessageIds ) ) {
          const newMessages = filter(
            notification.newMessageIds.map( ( newMessageId ) => messagesHash[ newMessageId ] ),
          )

          if( ! isEmpty( newMessages ) ) {
            displayNotificationHandler( newMessages, notification.text, notification.employeeIds )
          }
        }

        if( notification.conversation ) {
          eventEmitterHelpers.emit( CONVERSATION_UPDATED_EVENT, notification.conversation )
        }
      }
    }

    return pubSubService.on( MESSENGER_CONVERSATION_UPDATED_EVENT, handler )
  },
  onInboundCallUpdatedHandler( employeeId: string, handler?: ( data: ConversationService.InboundCallEvent ) => void ) {
    if( ! handler ) {
      const displayNotificationHandler = ( conversationId: string, notificationId: string, body: string, employeeIds: string[] ) => {
        if( ! employeeIds.includes( employeeId ) ) {
          return
        }

        eventEmitterHelpers.emit( CONVERSATION_NOTIFICATION_EVENT, { body, conversationId, notificationId } )
      }

      handler = async ( data: ConversationService.InboundCallEvent ) => {
        const notification = await conversationsService.getNotification( data.merchant_id, data.notification_id! )
        displayNotificationHandler( notification.conversationId!, notification.id, notification.text, notification.employeeIds )
      }
    }

    return pubSubService.on( INBOUND_PHONE_CALL_EVENT, handler )
  },
}

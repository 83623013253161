// eslint-disable-next-line @nx/enforce-module-boundaries
import type { MerchantSettings } from "@onelocal/frontend-dashboard/settings"
import type { Name } from "@onelocal/shared/common"

export interface ResponseConversation {
  callAudioFileUrl: string
  customer?: {
    id: string
    name?: Name
  }
  date: string
  duration: {
    total: number
  }
  flows: ResponseConversation.Flow[]
  fromPhoneNumber: ResponseConversation.FromPhoneNumber
  id: string
  isTesting?: boolean
  merchantId: string
  settingsFlows: ResponseMerchantSettings.Flow[]
  stats: {
    isNewCaller: boolean
  }
  textIndex: string[]
  transcript?: ResponseConversation.Transcription
  type: ResponseConversation.Type
  voicemailAudioFileUrl?: string

}

export namespace ResponseConversation {
  export type Flow = FlowCommon | FlowForward

  export interface FlowBase {
    date: string
    flowId: string
    name: string
    sms?: {
      date: string
      message: string
    }
  }

  export type FlowCommon = FlowBase

  export interface FlowForward extends FlowBase {
    forward: {
      to: string
    }
  }

  export type FromPhoneNumber = {
    nationalFormat: string
    value: string
  } | null

  export interface ParsedTranscriptionResult {
    conversationId: string
    error?: boolean
    transcript?: Transcript
  }

  export interface Transcript {
    sentences?: TranscriptSentence[]
    status?: TranscriptStatus
    totalDuration?: number
    type?: TranscriptType
  }

  export interface TranscriptSentence {
    order: number
    text: string
    speaker: TranscriptSpeaker
  }

  export interface Transcription {
    callRecording?: Transcript
    voicemail?: Transcript
  }

  export enum TranscriptSpeaker {
    BOT = "bot",
    CALLER = "caller",
    MERCHANT = "merchant",
  }

  export enum TranscriptStatus {
    COMPLETED = "completed",
    EMPTY = "empty",
    ERROR = "error",
    IN_PROGRESS = "in_progress",
  }

  export enum TranscriptType {
    CALL_RECORDING = "call_recording",
    VOICEMAIL = "voicemail",
  }

  export enum TranscriptErrorType {
    EXCEED_LIMIT = "exceed_limit",
  }

  export enum Type {
    CALL = "call",
  }
}

export interface ResponseDashboardStats {
  last30d: ResponseDashboardStats.PeriodStats
  last60d: ResponseDashboardStats.PeriodStats
}

export namespace ResponseDashboardStats {
  export interface PeriodStats {
    avgCallDuration: number
    conversationCount: number
    endDate: Date
    newCaller: CallerInfo
    returningCaller: CallerInfo
    startDate: Date
    totalCallDuration: number
  }

  export interface CallerInfo {
    count: number
    percent: number
  }
}

export interface ResponseMerchantSettings {
  id: string
  monthlyInboundMins: number
  v: number
  agent?: {
    greeting: string
    greetingAudioFileUrl?: string
    mode: ResponseMerchantSettings.AgentMode
    voice: string
  }
  autopilotAssistantId?: string
  fallbackFlow?: {
    id: ResponseMerchantSettings.StaticFlow.FALLBACK_FLOW
    maxRetries: number
    name: string
    nextAction: ResponseMerchantSettings.Flow.ActionForward | ResponseMerchantSettings.Flow.ActionHangUp | ResponseMerchantSettings.Flow.ActionSendSms
    text: string
  }
  flows?: ResponseMerchantSettings.Flow[]
  forwardFlow?: {
    enabled: boolean
    id?: ResponseMerchantSettings.StaticFlow.FORWARD_FLOW
    name?: string
    text?: string
    to?: string
  }
  status?: MerchantSettings.ProductStatus | null
  voicemailFlow?: {
    audioFileUrl?: string
    id: ResponseMerchantSettings.StaticFlow.VOICEMAIL_FLOW
    name: string
    text: string
  }
  whitelist?: string[]
}

export namespace ResponseMerchantSettings {
  export enum AgentMode {
    // The call will always be answer by the agent
    ALWAYS = "always",
    /** The call will be answer by the agent if the merchant line is busy */
    BUSY = "busy",
    /** The call won't be answer by the agent */
    NEVER = "never",
    /** The call will be answer by the agent if the merchant doesn't pickup after 30sec */
    NO_ANSWER = "no_answer",
  }

  export enum StaticFlow {
    FALLBACK_FLOW = "fallback_flow",
    FORWARD_FLOW = "forward_flow",
    NO_ANSWER_FLOW = "no_answer_flow",
    VOICEMAIL_FLOW = "voicemail_flow",
  }

  export interface Flow {
    answer: string
    enabled: boolean
    id: string
    name: string
    nextAction: Flow.Action
    steps: Flow.Step[]
    triggers: string[]
  }

  export namespace Flow {
    export type Action = ActionForward | ActionHangUp | ActionPrompt | ActionSendSms

    export interface ActionBase {
      type: ActionType
    }

    export enum ActionType {
      FORWARD = "forward",
      HANGUP = "hangup",
      PROMPT = "prompt",
      SEND_SMS = "send_sms",
    }

    export interface ActionForward extends ActionBase {
      phoneNumber: string
      text?: string
      type: ActionType.FORWARD
    }

    export interface ActionHangUp extends ActionBase {
      type: ActionType.HANGUP
    }

    export interface ActionPrompt extends ActionBase {
      type: ActionType.PROMPT
    }

    export interface ActionSendSms extends ActionBase {
      answer: string
      text: string
      type: ActionType.SEND_SMS
    }

    export enum StepType {
      PROMPT_SMS = "prompt_sms",
      SEND_SMS = "send_sms",
    }

    export type Step = StepPrompt | StepSendSMS

    export interface StepPrompt {
      prompt: string
      text: string
      type: StepType.PROMPT_SMS
    }

    export interface StepSendSMS {
      text: string
      type: StepType.SEND_SMS
    }
  }

  export enum StatusType {
    LIVE = "live",
  }
}

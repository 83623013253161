import { isEmpty } from "lodash"

import type { Name } from "@onelocal/shared/common"
import type { Merchant, ProductUpdate } from "."

export interface ApiMerchant {
  agency?: {
    id: string
    group_ids: string[]
  }
  id: string
  location_nickname?: string
  members: Array<{
    id: string
    ref: "merchants"
  }>
  name: string
  parent_id?: string
  reference_prefix?: string
  root_id: string
  slug: string
  timezone: string
}

export interface ApiGataUser {
  id: string
  avatar_filename?: string
  avatar_url?: string
  bio_text?: string
  calendly_link?: string
  contact_points: {
    email: {
      value: string
    }
  }
  name: {
    family: string
    given: string
  }
  salesforce_id?: string
}

export interface ApiProductUpdate {
  action?: ApiProductUpdate.Action
  assigned_expert?: {
    avatar_url?: string
    gata_user_id?: string
    name?: Name
    salesforce_id: string
  }
  created: {
    at: string
  }
  html?: string
  id: string
  merchant_id: string
  text: string
  type: ProductUpdate.Type
}

export namespace ApiProductUpdate {
  export interface Action {
    enabled?: boolean
    label?: string
    status?: ProductUpdate.Status
    url?: string
  }
}

export function parseMerchantFromApi( apiMerchant: ApiMerchant ) {
  const merchant: Merchant = {
    agency: ( apiMerchant.agency
      ? {
        groupIds: apiMerchant.agency.group_ids,
        id: apiMerchant.agency.id,
      }
      : undefined
    ),
    id: apiMerchant.id,
    locationNickname: apiMerchant.location_nickname,
    members: apiMerchant.members,
    name: apiMerchant.name,
    parentId: apiMerchant.parent_id,
    referencePrefix: apiMerchant.reference_prefix,
    rootId: apiMerchant.root_id,
    slug: apiMerchant.slug,
    timezone: apiMerchant.timezone,
  }

  return merchant
}

export function parseProductUpdateFromApi( apiProductUpdate: ApiProductUpdate ) {
  const productUpdate: ProductUpdate = {
    created: {
      at: apiProductUpdate.created.at,
    },
    id: apiProductUpdate.id,
    merchantId: apiProductUpdate.merchant_id,
    text: apiProductUpdate.text,
    type: apiProductUpdate.type,
  }

  if( ! isEmpty( apiProductUpdate.action ) ) {
    productUpdate.action = apiProductUpdate.action! as ProductUpdate.Action
  }

  if( ! isEmpty( apiProductUpdate.assigned_expert ) ) {
    const assigned_expert = apiProductUpdate.assigned_expert!
    productUpdate.assignedExpert = {
      avatarUrl: assigned_expert.avatar_url,
      gataUserId: assigned_expert.gata_user_id,
      name: assigned_expert.name,
      salesforceId: assigned_expert.salesforce_id,
    }
  }

  if( ! isEmpty( apiProductUpdate.html ) ) {
    productUpdate.html = apiProductUpdate.html
  }

  return productUpdate
}

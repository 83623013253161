import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

import type { DefaultThunkAction, ModuleStoreConfig, PaginatedItems, PaginatedQueryOptions } from "@onelocal/frontend/common"
import type { ApiRequestProgressEvent } from "@onelocal/shared/common"
import type { ActivitiesService } from "../services/activitiesService"
import { activitiesService } from "../services/activitiesService"
import { mainSettingsService } from "../services/settingsService"
import type { TaskService } from "../services/taskService"
import { taskService } from "../services/taskService"
import type { Activity, FileAsset, InformationTaskBody, JiraTaskBody, MainSettings, Task } from "../types"
import { mainSaga } from "./mainSagas"

export interface MainState {
  activities: {
    byId: { [id: string]: Activity }
  }
  settings: {
    disableBlogSocial?: boolean
  }
  tasks: {
    byId: { [id: string]: Task }
  }
}

const mainStoreKey = "main"

export interface RootStateMain {
  [ mainStoreKey ]: MainState
}

type MainThunkAction<TReturnType> = DefaultThunkAction<RootStateMain, TReturnType>

export const mainInitialState: MainState = {
  activities: {
    byId: {},
  },
  tasks: {
    byId: {},
  },
  settings: {},
}

const mainSlice = createSlice( {
  name: mainStoreKey,
  initialState: mainInitialState,
  reducers: {
    updateActivities: ( state, action: PayloadAction<Activity[]> ) => {
      for( const activity of action.payload ) {
        state.activities.byId[ activity.id ] = activity
      }

      return state
    },
    updateSettings: ( state, action: PayloadAction<MainSettings> ) => {
      state.settings = action.payload

      return state
    },
    updateTasks: ( state, action: PayloadAction<Task[]> ) => {
      for( const task of action.payload ) {
        state.tasks.byId[ task.id ] = task
      }

      return state
    },
  },
} )

export const mainActions = {
  activities: {
    query( filter: ActivitiesService.QueryFilter, options: PaginatedQueryOptions ): MainThunkAction<PaginatedItems<Activity>> {
      return async ( dispatch ) => {
        const paginatedReviewActivities = await activitiesService.queryActivities( filter, options )
        dispatch( mainSlice.actions.updateActivities( paginatedReviewActivities.items ) )
        return paginatedReviewActivities
      }
    },
    updateApprovalStatus( activityId: string, model: ActivitiesService.UpdateApprovalStatusModel ): MainThunkAction<Activity> {
      return async ( dispatch ) => {
        const activity = await activitiesService.updateActivityApprovalStatus( activityId, model )
        dispatch( mainSlice.actions.updateActivities( [ activity ] ) )
        return activity
      }
    },
  },
  settings: {
    get(): MainThunkAction<MainSettings> {
      return async ( dispatch ) => {
        const settings = await mainSettingsService.getSettings()
        dispatch( mainSlice.actions.updateSettings( settings ) )
        return settings
      }
    },
  },
  tasks: {
    query( filter: TaskService.QueryFilter, options: PaginatedQueryOptions ): MainThunkAction<PaginatedItems<Task>> {
      return async ( dispatch ) => {
        const paginatedTasks = await taskService.queryTasks( filter, options )
        dispatch( mainSlice.actions.updateTasks( paginatedTasks.items ) )
        return paginatedTasks
      }
    },
    bulkCreate( file: File ): MainThunkAction<void> {
      return async () => {
        await taskService.createBulkTasks( file )
      }
    },
    create( body: JiraTaskBody | InformationTaskBody ): MainThunkAction<Task> {
      return async ( dispatch ) => {
        const task = await taskService.createTask( body )
        dispatch( mainSlice.actions.updateTasks( [ task ] ) )
        return task
      }
    },
    startConversation( taskId: string, merchantId: string, message: string ): MainThunkAction<void> {
      return async ( dispatch ) => {
        const task = await taskService.startConversation( taskId, merchantId, message )
        dispatch( mainSlice.actions.updateTasks( [ task ] ) )
      }
    },
    uploadAsset( merchantId: string, image: File, onUploadProgress?: ( progressEvent: ApiRequestProgressEvent ) => void ): MainThunkAction<FileAsset> {
      return async () => {
        const fileAsset = await taskService.uploadAsset( merchantId, image, onUploadProgress )
        return fileAsset
      }
    },
  },
}

export const mainStoreConfig: ModuleStoreConfig<MainState> = {
  initialState: mainInitialState,
  key: mainStoreKey,
  reducers: mainSlice.reducer,
  saga: mainSaga,
}

import CheckIcon from "@mui/icons-material/Check"
import { isEmpty } from "lodash"
import React, { useCallback, useMemo, useState } from "react"
import type { SubmitHandler } from "react-hook-form"
import { Controller, FormProvider } from "react-hook-form"
import { useSelector } from "react-redux"
import styled from "styled-components"

import { useAsyncDispatch, useForm } from "@onelocal/frontend/common"
import type { DialogAction, DialogAlert } from "@onelocal/frontend-web/common"
import { CheckBox, Dialog, DialogAlertType, Grid, GridItem, Select, StyledFormSectionTitle, TextField, useAlert, useModalWithParams } from "@onelocal/frontend-web/common"
import { useDudaTemplates } from "../hooks/useDudaTemplates"
import { siteSelectors } from "../store/siteSelectors"
import { siteActions } from "../store/siteStore"
import { Site } from "../types"

const StyledSelect = styled( Select )`
  margin: 20px 0;
  width: 100%;
`

const StyledFieldContainer = styled.div`
  display: flex;
  margin: 20px 0;
  flex-direction: column;
`

enum FormFieldKeys {
  DUDA_ID = "duda_id",
  IS_AVAILABLE = "is_available",
  NAME = "name",
  TYPES = "types",
}

type FormValues = {
  [ FormFieldKeys.DUDA_ID ]: string
  [ FormFieldKeys.IS_AVAILABLE ]: boolean
  [ FormFieldKeys.NAME ]: string
  [ FormFieldKeys.TYPES ]: Site.Type[]
}

export interface SiteTemplateModalProps {
  isOpen: boolean
  onClose( reOpen?: boolean ): void
  onExited: () => void
  onSaved(): void
  siteTemplateId?: string
}

export const SiteTemplateModal: React.FC<SiteTemplateModalProps> = ( {
  isOpen,
  onClose,
  onExited,
  onSaved,
  siteTemplateId,
} ) => {
  const { dudaTemplates } = useDudaTemplates()
  const dudaTemplateOptions = useMemo(
    () => dudaTemplates ? dudaTemplates.map( ( dudaTemplate ) => ( {
      label: dudaTemplate.name,
      value: dudaTemplate.id,
    } ) ) : []
    , [ dudaTemplates ],
  )

  const dispatch = useAsyncDispatch()
  const siteTemplate = useSelector( siteTemplateId ? siteSelectors.siteTemplates.byId( siteTemplateId ) : () => null )

  const [ alert, setAlert ] = useState<DialogAlert | undefined>( undefined )
  const formMethods = useForm<FormValues>( {
    defaultValues: {
      [ FormFieldKeys.DUDA_ID  ]: siteTemplate?.dudaId,
      [ FormFieldKeys.IS_AVAILABLE  ]: siteTemplateId ? siteTemplate?.isAvailable : true,
      [ FormFieldKeys.NAME  ]: siteTemplate?.name || "",
      [ FormFieldKeys.TYPES  ]: siteTemplateId ? siteTemplate?.types : [ Site.Type.LANDING_PAGE, Site.Type.WEBSITE ],
    },
  } )

  const { control, formState, handleSubmit, setValue, trigger } = formMethods
  const { showSuccess } = useAlert()

  const onSave: SubmitHandler<FormValues> = useCallback( async ( data ) => {
    try {
      if( siteTemplate ) {
        await dispatch( siteActions.siteTemplates.update( siteTemplate.id, {
          dudaId: siteTemplate.dudaId,
          isAvailable: data.is_available,
          name: data.name,
          types: data.types,
        } ) )
        showSuccess( "Site template has been updated" )
      } else {
        await dispatch( siteActions.siteTemplates.create( {
          dudaId: data.duda_id?.toString(),
          isAvailable: data.is_available,
          name: data.name,
          types: data.types,
        } ) )

        showSuccess( "Site template has been added" )
      }

      onClose()
      onSaved()
    } catch( err ) {
      setAlert( {
        message: err,
        type: DialogAlertType.ERROR,
      } )
    }
  }, [ siteTemplate, onClose, dispatch, onSaved, showSuccess ] )

  const modalActions: DialogAction[] = useMemo( () => [
    { title: "Cancel", type: "dismiss" },
    {
      icon: ( <CheckIcon style={ { height: 18, width: 18 } }/> ),
      onClick: handleSubmit( onSave, () => {
        setAlert( {
          message: "Please make sure all required fields are filled.",
          type: DialogAlertType.ERROR,
        } )
      } ),
      title: "Confirm",
      type: "primary",
    },
  ], [ handleSubmit, onSave ] )

  return (
    <Dialog
      actions={ modalActions }
      alert={ alert }
      contentContainerStyle={ {
        paddingLeft: "24px",
        paddingRight: "24px",
      } }
      isOpen={ isOpen }
      onClose={ onClose }
      onExited={ onExited }
      showDividers={ false }
      title={ siteTemplate ? `Edit Site Template ${ siteTemplate.name } ` : "Add Site Template" }
    >
      <FormProvider { ...formMethods }>
        { ! siteTemplate && (
          <Controller
            control={ control }
            name={ FormFieldKeys.DUDA_ID }
            render={ ( { field: { onChange, value } } ) => {
              const error = formState.errors?.[ FormFieldKeys.DUDA_ID ]?.message
              return (
                <StyledSelect
                  disabled={ isEmpty( dudaTemplates ) }
                  error={ error != null }
                  helpText={ error }
                  label="Duda Template"
                  onChange={ ( selectedItem: number ) => {
                    onChange( selectedItem )

                    if( selectedItem ) {
                      const selectedItemLabel = dudaTemplateOptions.find( ( option ) => option.value === selectedItem )?.label || ""
                      setValue( FormFieldKeys.NAME, selectedItemLabel )
                      trigger( FormFieldKeys.NAME )
                    }
                  } }
                  options={ dudaTemplateOptions }
                  required={ true }
                  value={ value || "" }
                />
              )
            } }
            rules={ {
              validate: ( value ) => {
                if( ! value ) {
                  return "Duda Template is required"
                }
                return undefined
              },
            } }
          />
        ) }

        <StyledFieldContainer>
          <Controller
            control={ control }
            name={ FormFieldKeys.NAME }
            render={ ( { field: { onChange, value } } ) => (
              <TextField
                errorMessage={ formState.errors?.[ FormFieldKeys.NAME ]?.message }
                fullWidth
                label="Template Name"
                onChange={ onChange }
                required={ true }
                value={ value || "" }
              />
            ) }
            rules={ {
              validate: ( value ) => {
                value = value.trim()

                if( ! value ) {
                  return "Template Name is required"
                }
                return undefined
              },
            } }
          />
        </StyledFieldContainer>

        <StyledFieldContainer>
          <StyledFormSectionTitle>Status</StyledFormSectionTitle>
          <Controller
            control={ control }
            name={ FormFieldKeys.IS_AVAILABLE }
            render={ ( { field: { onChange, value } } ) => {
              return (
                <CheckBox
                  checkboxColor="primary"
                  checked={ value || false }
                  label={ "Enabled" }
                  name={ FormFieldKeys.IS_AVAILABLE }
                  noLabelWrap={ true }
                  onChange={ onChange }
                />
              )
            } }
          />
        </StyledFieldContainer>

        <StyledFieldContainer>
          <StyledFormSectionTitle>Availability</StyledFormSectionTitle>
          <Controller
            control={ control }
            name={ FormFieldKeys.TYPES }
            render={ ( { field: { onChange, value } } ) => {
              return (
                <Grid spacing={ 4 }>
                  { [ Site.Type.LANDING_PAGE, Site.Type.WEBSITE ].map( ( sitType ) => (
                    <GridItem key={ sitType } xs={ 6 }>
                      <CheckBox
                        checkboxColor="primary"
                        checked={ value?.includes( sitType ) }
                        label={ Site.TypeLabelMap[ sitType ] }
                        onChange={ ( isSelected ) => {
                          if( isSelected ) {
                            onChange( [ ...value, sitType ] )
                          } else {
                            onChange( value.filter( ( type ) => type !== sitType ) )
                          }
                        } }
                        name={ FormFieldKeys.TYPES }
                        noLabelWrap={ true }
                      />
                    </GridItem>
                  ) ) }
                </Grid>
              )
            } }
          />
        </StyledFieldContainer>
      </FormProvider>
    </Dialog>
  )
}

export interface useSiteTemplateModalParams {
  siteTemplateId?: string
  onSaved(): void
}

export const useSiteTemplateModal = () => {
  const { showModal, hideModal } = useModalWithParams<useSiteTemplateModalParams>( ( { open, onExited, params } ) => {
    if( ! params ) {
      return null
    }

    return (
      <SiteTemplateModal
        isOpen={ open }
        onClose={ hideModal }
        onExited={ onExited }
        onSaved={ params.onSaved }
        siteTemplateId={ params.siteTemplateId }
      />
    )
  }, [] )

  return {
    hideSiteTemplateModal: hideModal,
    showSiteTemplateModal: showModal,
  }
}


// eslint-disable-next-line no-restricted-imports
import type { CheckboxProps } from "@mui/material"
// eslint-disable-next-line no-restricted-imports
import { Checkbox as MuiCheckbox, FormControlLabel as MuiFormControlLabel } from "@mui/material"
import React, { useCallback } from "react"
import styled from "styled-components"

const StyledFormControlLabel = styled( MuiFormControlLabel )<{ $noLabelWrap?: boolean, $labelMargin?: string }>`
  max-width: 100%;

  & .MuiFormControlLabel-label {
    max-width: ${ ( { $noLabelWrap } ) => $noLabelWrap ? "100%" : "calc( 100% - 38px )" };
    overflow-wrap: ${ ( { $noLabelWrap } ) => $noLabelWrap ? "normal" : "break-word" };
  }

  ${ ( { $labelMargin } ) => $labelMargin != null && `
    &.MuiFormControlLabel-root {
      margin: ${ $labelMargin };
    }
  ` }
`

export interface CheckBoxProps {
  checked: boolean
  checkboxColor?: CheckboxProps[ "color" ]
  disabled?: boolean
  inputRef?: React.Ref<HTMLInputElement>
  label: string
  labelMargin?: string
  name?: string
  noLabelWrap?: boolean
  onChange( value: boolean ): void
}

export const CheckBox: React.FC<CheckBoxProps> = ( {
  checked,
  checkboxColor,
  disabled,
  inputRef,
  label,
  labelMargin,
  name,
  noLabelWrap,
  onChange,
} ) => {
  const handleChange = useCallback( ( event: React.ChangeEvent<HTMLInputElement>, newValue: boolean ) => {
    onChange( newValue )
  }, [ onChange ] )

  return (
    <StyledFormControlLabel
      control={ <MuiCheckbox disabled={ disabled } inputRef={ inputRef } checked={ checked } color={ checkboxColor } onChange={ handleChange } name={ name } /> }
      label={ label }
      $noLabelWrap={ noLabelWrap }
      $labelMargin={ labelMargin }
    />
  )
}

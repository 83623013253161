import InfoIcon from "@mui/icons-material/InfoOutlined"
// eslint-disable-next-line no-restricted-imports
import { Fade } from "@mui/material"
// eslint-disable-next-line no-restricted-imports
import type { TooltipProps as MuiTooltipProps } from "@mui/material/Tooltip"
// eslint-disable-next-line no-restricted-imports
import MuiTooltip from "@mui/material/Tooltip"
import React from "react"
import styled from "styled-components"

import { styleHelpers } from "../helpers/styleHelpers"

/**
 * @note
 * - Not sure why only tooltip is not working as others but I found a workaround from
 *   https://github.com/mui-org/material-ui/issues/11467#issuecomment-423845900
 */
const StyledTooltip = styled( ( props: MuiTooltipProps ) => (
  <MuiTooltip classes={ { popper: props.className } } { ...props } /> ),
)`
  & .MuiTooltip-tooltip {
    background-color: ${ styleHelpers.colors.darkerGray };
    font-size: ${ styleHelpers.fonts.sizes.small };
    font-weight: ${ styleHelpers.fonts.weight.default };
    max-width: 470px;
    padding: 4px 8px;
  }
`

const StyledTooltipIcon = styled( InfoIcon )`
  height: 20px;
  width: 20px;
`

const TooltipIconWrapper = styled.div<Pick<TooltipProps, "color">>`
  align-items: center;
  color: ${ ( props ) => props.color || styleHelpers.colors.darkGray };
  display: flex;
  margin: 0 0 0 8px;
`

export type Placement = "bottom"
                        | "left"
                        | "right"
                        | "top"

export interface TooltipProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: React.ReactElement<any, any>
  color?: string
  onClose?: ( event: React.SyntheticEvent | Event ) => void
  placement?: Placement
  showArrow?: boolean
  text: string | React.ReactElement
  wrapperStyle?: React.CSSProperties

  onOpen?(): void
}

export const Tooltip: React.FC<TooltipProps> = ( {
  children,
  color,
  onClose,
  onOpen,
  placement = "right",
  showArrow = false,
  text,
  wrapperStyle = {},
} ) => (
  <StyledTooltip
    arrow={ showArrow }
    onClose={ onClose }
    onOpen={ onOpen }
    placement={ placement }
    title={ text }
    TransitionComponent={ Fade }
  >
    {
      children != null
        ? (
          <div>
            { children }
          </div>
        )
        : (
          <TooltipIconWrapper color={ color } style={ wrapperStyle }>
            <StyledTooltipIcon fontSize="inherit"/>
          </TooltipIconWrapper>
        )
    }
  </StyledTooltip>
)


import React, { useCallback, useEffect, useState } from "react"
import styled from "styled-components"

import type { Option } from "@onelocal/frontend/common"
import { useDebounce } from "@onelocal/frontend/common"
import { AuthInternalAccount, useCurrentInternalAccount } from "@onelocal/frontend-admin-web/auth"
import { LoadingOverlay } from "@onelocal/frontend-admin-web/common"
import { Flex, Link, SearchBar, SelectInline, styleHelpers, TablePagination } from "@onelocal/frontend-web/common"
import { usePaginatedMerchants } from "../hooks/usePaginatedMerchants"
import type { Merchant } from "../types"

const StyledMerchantList = styled.ul`
  border-bottom: 1px solid ${ styleHelpers.colors.borderGray };
  border-top: 1px solid ${ styleHelpers.colors.borderGray };
  flex: 1;
  margin: 0;
  overflow-y: scroll;
  padding: 0;
`

const ALL_VALUES = "all"

const merchantTypeOptions: Array<Option<string>> = [
  {
    label: "All",
    value: ALL_VALUES,
  },
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Testing",
    value: "testing",
  },
  {
    label: "Demo",
    value: "demo",
  },
  {
    label: "Churn",
    value: "churn",
  },
  {
    label: "Billing Issues",
    value: "billing_issues",
  },
  {
    label: "Partner",
    value: "partner",
  },
]

export interface MerchantsListMenuProps {}

export const MerchantsListMenu: React.FC<MerchantsListMenuProps> = ( props ) => {
  const { isLoading, loadPage, merchants, merchantsQueryOptions, setQueryFilter, totalMerchantsCount } = usePaginatedMerchants()
  const [ merchantType, setMerchantType ] = useState<string>( ALL_VALUES )
  const [ searchText, setSearchText ] = useState<string>( "" )
  const { debouncedValue: debounceSearchText } = useDebounce( searchText, 300 )
  const { currentInternalAccount } = useCurrentInternalAccount()

  const onMerchantTypeOptionChanged = useCallback( ( value: string ) => {
    setMerchantType( value )
    setQueryFilter( { type: value } )
  }, [ setQueryFilter ] )

  const onPageChanged = useCallback( ( page: number ) => {
    loadPage( page )
  }, [ loadPage ] )

  useEffect( () => {
    setQueryFilter( { q: debounceSearchText ?? null } )
  }, [ debounceSearchText, setQueryFilter ] )

  return (
    <Flex
      display="flex"
      flexDirection="column"
      justifyContent="stretch"
      style={ {
        borderRight: "1px solid #ccc",
        height: "100%",
        width: "300px",
      } }
    >
      <div
        style={ {
          padding: "15px 10px",
        } }
      >
        {
          currentInternalAccount.role !== AuthInternalAccount.Role.SALES && (
            <SelectInline
              label="Filter"
              onValueSelected={ onMerchantTypeOptionChanged }
              options={ merchantTypeOptions }
              style={ { marginBottom: "10px", width: "100%" } }
              value={ merchantType }
            />
          )
        }
        <SearchBar
          onChange={ setSearchText }
          placeholder="Search by Name"
          style={ { } }
          value={ searchText }
        />
      </div>

      { <LoadingOverlay isLoading={ isLoading } /> }
      {
        merchants != null && isLoading === false && (
          <>
            <StyledMerchantList>
              {
                merchants?.map( ( merchant ) => (
                  <MerchantListItem key={ merchant.id } merchant={ merchant }/>
                ) )
              }
            </StyledMerchantList>
            {
              totalMerchantsCount != null && totalMerchantsCount > 0 && (
                <TablePagination
                  onPageChange={ onPageChanged }
                  page={ merchantsQueryOptions.page }
                  perPage={ merchantsQueryOptions.perPage }
                  totalItems={ totalMerchantsCount || 0 }
                />
              )
            }
          </>
        )
      }

    </Flex>
  )
}

const StyledMerchantItem = styled.li`
  list-style: none;
  margin: 0 10px;

  & + & {
    border-top: 1px solid ${ styleHelpers.colors.borderGray };
  }
`

const StyledMerchantLink = styled( Link )`
  display: block;
  padding: 15px 0;

  text-decoration: none;
`

interface MerchantListItemProps {
  merchant: Merchant
}

const MerchantListItem: React.FC<MerchantListItemProps> = ( { merchant } ) => {
  return (
    <StyledMerchantItem>
      <StyledMerchantLink href={ `/merchants/${ merchant.id }` }>{ merchant.name }</StyledMerchantLink>
    </StyledMerchantItem>
  )
}


import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

import type { DefaultThunkAction, ModuleStoreConfig } from "@onelocal/frontend/common"
import type { AuthSession } from "@onelocal/frontend-dashboard/auth"
import { agencyService, contextService, merchantService } from "../services"
import type { DashboardContext, Merchant } from "../types"

export interface CommonState {
  children: Merchant[]
  // @todo Should not be hardcoded to MerchantContext
  context: DashboardContext.MerchantContext | null
  selectedMerchantId: string | null
}

type CommonThunkAction<TReturnType> = DefaultThunkAction<{ common: CommonState }, TReturnType>

const commonStoreKey = "common"

export const commonInitialState: CommonState = {
  children: [],
  context: null,
  selectedMerchantId: null,
}

const commonSlice = createSlice( {
  name: commonStoreKey,
  initialState: commonInitialState,
  reducers: {
    selectMerchantId( state, action: PayloadAction<string> ) {
      state.selectedMerchantId = action.payload
      return state
    },
    updateChildren( state, action: PayloadAction<Merchant[]> ) {
      state.children = [ ...action.payload ]
      return state
    },
    updateContext( state, action: PayloadAction<DashboardContext.MerchantContext | null> ) {
      if( action.payload ) {
        state.context = action.payload
      } else {
        state.context = null
      }

      return state
    },
  },
} )

export const commonActions = {
  loadChildren( session: AuthSession ): CommonThunkAction<Merchant[]> {
    return async ( dispatch ) => {
      const children = session.agencyId
        ? await agencyService.getMerchants( session.employeeOrganizationId )
        : await merchantService.getChildren( session.employeeOrganizationId )
      dispatch( commonSlice.actions.updateChildren( children ) )
      return children
    }
  },
  loadContext( merchantId: string ): CommonThunkAction<DashboardContext.MerchantContext | null> {
    return async ( dispatch ) => {
      const context = await contextService.getMerchantContext( merchantId )
      dispatch( commonSlice.actions.updateContext( context ) )
      dispatch( commonSlice.actions.selectMerchantId( merchantId ) )
      return context
    }
  },
  selectMerchant( merchantId: string ) {
    return commonSlice.actions.selectMerchantId( merchantId )
  },
}

export const commonStoreConfig: ModuleStoreConfig<CommonState> = {
  key: commonStoreKey,
  initialState: commonInitialState,
  reducers: commonSlice.reducer,
}

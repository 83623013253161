import { isEqual } from "lodash"
import { useCallback, useRef, useState } from "react"
import { useSelector } from "react-redux"

import { errorHelper } from "../helpers/errorHelper"
import type { DefaultThunkAction } from "../store"
import { useAsyncDispatch } from "../store"

export interface useResourcesOptions<TResource, TFilter, TRootState> {
  defaultFilter: TFilter
  getQueryAction: ( filter: TFilter ) => DefaultThunkAction<TRootState, TResource[]> | undefined
  selector: ( itemIds: string[] ) => ( ( state: TRootState ) => ( TResource[] | null ) )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useResources = <TResource extends { id: string }, TFilter extends { [key: string]: any }, TRootState>( options: useResourcesOptions<TResource, TFilter, TRootState> ) => {
  const { defaultFilter, getQueryAction, selector } = options

  const dispatch = useAsyncDispatch()
  const [ isInitialLoad, setIsInitialLoad ] = useState( true )
  const [ isRefreshing, setIsRefreshing ] = useState( false )
  const [ loadingError, setLoadingError ] = useState<Error | null>( null )
  const [ resourceIds, setResourceIds ] = useState<string[]>( [] )

  const queryFilterRef = useRef<TFilter>( defaultFilter )
  const resources = useSelector( selector( resourceIds ) )
  const resourcesRef = useRef<TResource[] | null>( resources )

  const updateResources = useCallback( ( updatedResources: TResource[], reset?: boolean ) => {
    setIsInitialLoad( false )

    setResourceIds( ( currentItemIds ) => {
      const updatedResourceIds = reset ? [] : [ ...currentItemIds ]
      let updated = false

      for( const resource of updatedResources ) {
        if( ! updatedResourceIds.includes( resource.id ) ) {
          updatedResourceIds.push( resource.id )
          updated = true
        }
      }

      if( updated ) {
        return updatedResourceIds
      }

      return currentItemIds
    } )
  }, [ ] )

  const loadResources = useCallback( async () => {
    const queryFilter = queryFilterRef.current

    const queryAction = getQueryAction( queryFilter )

    if( ! queryAction ) {
      return
    }

    const newResources = await dispatch( queryAction )

    // If a new query has been started while waiting from the API response
    if( ! isEqual( queryFilterRef.current, queryFilter ) ) {
      return
    }

    updateResources( newResources )

  }, [ dispatch, getQueryAction, updateResources ] )

  const reloadResources = useCallback( async ( silent?: boolean ) => {
    if( ! silent ) {
      setIsRefreshing( true )
    }
    try {

      const queryAction = getQueryAction( queryFilterRef.current )

      if( ! queryAction ) {
        return
      }

      const newResources = await dispatch( queryAction )
      updateResources( newResources, true )
    } catch( err: unknown ) {
      setLoadingError( errorHelper.getDisplayErrorMessage( err ) )
    }

    if( ! silent ) {
      setIsRefreshing( false )
    }
  }, [ dispatch, getQueryAction, updateResources ] )

  const setQueryFilter = useCallback( async ( filter: TFilter ) => {
    queryFilterRef.current = filter
    setResourceIds( [] )
    await loadResources()
  }, [ loadResources ] )

  // Use a ref so the ref doesn't change on re-render
  if( ! isEqual( resources, resourcesRef.current ) ) {
    resourcesRef.current = resources
  }

  return {
    isRefreshing,
    loadingError,
    resources: isInitialLoad ? null : resourcesRef.current,
    queryFilter: queryFilterRef.current,
    loadResources,
    reloadResources,
    setQueryFilter,
  }
}

import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

import type { DefaultThunkAction, ModuleStoreConfig, PaginatedItems } from "@onelocal/frontend/common"
import { InternalError } from "@onelocal/frontend/common"
import { utilHelpers } from "@onelocal/frontend-dashboard-lib"
import type { RootThunk } from "@onelocal/frontend-dashboard-web/main"
import { getItemFromLocalStorage, setItemInLocalStorage } from "@onelocal/frontend-web/common"
import { authSlice } from "../../../auth/src/store/authStore"
import { contentSlice } from "../../../content/src/store/contentStore"
import type { ActivitiesService } from "../services/activitiesService"
import { activitiesService } from "../services/activitiesService"
import { mainPerformancesService } from "../services/performanceService"
import { taskService } from "../services/taskService"
import type { MainPerformanceInsight, MainTask } from "../types"
import { MainActivity } from "../types"

export interface MainState {
  activeActivityId: string | null
  activities: {
    byId: {
      [ activityId: string ]: MainActivity
    }
    stats: MainActivity.Stats
    hasNewActivity: boolean
  }
  performance: {
    byPeriod: Partial<Record<MainPerformanceInsight.Period, MainPerformanceInsight>>
    summary: MainPerformanceInsight[ "summary" ] | null
  }
  isActivityExpanded: boolean
  tasks: {
    byId: {
      [ taskId: string ]: MainTask
    }
  }
  taskIdsInProcess: string[]
}

const mainStoreKey = "main"

export interface RootStateMain {
  [ mainStoreKey ]: MainState
}

type MainThunkAction<TReturnType> = DefaultThunkAction<RootStateMain, TReturnType>

const createActivityUpdateAction = (
  updateFn: ( merchantId: string, activityId: string ) => Promise<MainActivity>,
): ( merchantId: string, activityId: string ) => RootThunk<MainActivity> => {
  return (
    merchantId: string,
    activityId: string,
  ) => {
    return async ( dispatch ) => {
      const updatedActivity = await updateFn( merchantId, activityId )
      dispatch( mainSlice.actions.updateActivities( [ updatedActivity ] ) )
      await dispatch( mainActions.queryStats( merchantId ) )
      return updatedActivity
    }
  }
}

export const mainInitialState: MainState = {
  activeActivityId: null,
  isActivityExpanded: false,
  activities: {
    byId: {},
    stats: {
      all: 0,
      assignments: 0,
      message: 0,
      missedCall: 0,
      review: 0,
      task: 0,
    },
    hasNewActivity: getItemFromLocalStorage( "has_new_activity" ) === "true",
  },
  performance: {
    byPeriod: {},
    summary: null,
  },
  tasks: {
    byId: {},
  },
  taskIdsInProcess: [],
}

const mainSlice = createSlice( {
  name: mainStoreKey,
  initialState: mainInitialState,
  reducers: {
    addProcessingTaskId: ( state, action: PayloadAction<string> ) => {
      state.taskIdsInProcess.push( action.payload )
      return state
    },
    removeProcessTaskId: ( state, action: PayloadAction<string> ) => {
      state.taskIdsInProcess = state.taskIdsInProcess.filter( ( taskId ) => taskId !== action.payload )
      return state
    },
    deleteActivity: ( state, action: PayloadAction<string> ) => {
      const activityId = action.payload
      delete state.activities.byId[ activityId ]

      return state
    },
    setExpanded: ( state, action: PayloadAction<boolean> ) => {
      state.isActivityExpanded = action.payload
      return state
    },
    setHasNewActivity: ( state, action: PayloadAction<boolean> ) => {
      state.activities.hasNewActivity = action.payload
      return
    },
    setActiveActivityId: ( state, action: PayloadAction<string | null> ) => {
      state.activeActivityId = action.payload
      return state
    },
    updateActivities: ( state, action: PayloadAction<MainActivity[]> ) => {
      const activities = action.payload

      for( const activity of activities ) {
        state.activities.byId[ activity.id ] = activity
      }

      return state
    },
    updatePerformanceInsights: ( state, action: PayloadAction<{ period: MainPerformanceInsight.Period, insights: MainPerformanceInsight }> ) => {
      const { insights, period } = action.payload
      state.performance.byPeriod[ period ] = insights
      state.performance.summary = insights.summary
      return state
    },
    updateStats: ( state, action: PayloadAction<MainActivity.Stats> ) => {
      state.activities.stats = action.payload

      return state
    },
    updateTask: ( state, action: PayloadAction<MainTask[]> ) => {
      const tasks = action.payload

      for( const task of tasks ) {
        state.tasks.byId[ task.id ] = task
      }

      return state
    },
  },
  extraReducers: builder => {
    builder.addCase( authSlice.actions.updateSession, ( state, action ) => {
      const session = action.payload

      if( session == null ) {
        return mainInitialState
      }

      return state
    } )

    builder.addCase( contentSlice.actions.deleteSocialPostSuggestion, ( state, action ) => {
      const { socialPostSuggestionId } = action.payload

      for( const activity of Object.values( state.activities.byId ) as MainActivity[] ) {
        const activityType = activity.type
        switch( activityType ) {
          case MainActivity.Type.SHARE_BEFORE_AFTER_PHOTOS:
          case MainActivity.Type.SHARE_BULK_MESSAGE:
          case MainActivity.Type.SHARE_PHOTOS:
          case MainActivity.Type.SHARE_REVIEW:
            if( activity.socialPostSuggestionId === socialPostSuggestionId ) {
              delete state.activities.byId[ activity.id ]
            }
            break
          case MainActivity.Type.SHARE_BLOG_POST:
            if( activity.socialPostSuggestionIds.includes( socialPostSuggestionId ) ) {
              delete state.activities.byId[ activity.id ]
            }
            break
          case MainActivity.Type.CONVERSATION:
          case MainActivity.Type.KEYWORD_RANKING_BLOG:
          case MainActivity.Type.REVIEW:
          case MainActivity.Type.TASK:
            break
          default:
            utilHelpers.assertNever( activityType )
            throw new InternalError( `Unsupported notification field type: ${ activityType }` )
        }
      }

      return state
    } )
  },
} )

export const mainActions = {
  archiveActivity: createActivityUpdateAction( activitiesService.archiveActivity ),
  dismissActivity: createActivityUpdateAction( activitiesService.dismissActivity ),
  deleteActivity: (
    merchantId: string,
    activityId: string,
  ): RootThunk<void> => {
    return async ( dispatch ) => {
      await activitiesService.deleteActivity( merchantId, activityId )
      dispatch( mainSlice.actions.deleteActivity( activityId ) )
    }

  },
  setActiveActivityId: ( activityId: string | null ): RootThunk<void> => {
    return async ( dispatch ) => {
      dispatch( mainSlice.actions.setActiveActivityId( activityId ) )
    }
  },
  setExpanded( expanded: boolean ): RootThunk<void> {
    return async ( dispatch ) => {
      dispatch( mainSlice.actions.setExpanded( expanded ) )
    }
  },
  setHasNewActivity( unread: boolean ): RootThunk<void> {
    return async ( dispatch ) => {
      setItemInLocalStorage( "has_new_activity", unread ? "true" : null )
      dispatch( mainSlice.actions.setHasNewActivity( unread ) )
    }
  },
  getPerformanceInsight( merchantId: string, period: MainPerformanceInsight.Period ): RootThunk<MainPerformanceInsight> {
    return async ( dispatch ) => {
      const insights = await mainPerformancesService.getInsights( merchantId, period )
      dispatch( mainSlice.actions.updatePerformanceInsights( { insights, period } ) )
      return insights
    }
  },
  markAsRead(
    merchantId: string,
    query: { conversationId?: string, reviewId?: string },
  ): RootThunk<void> {
    return async ( dispatch ) => {
      try {
        const activity = await activitiesService.markAsRead( merchantId, query )
        dispatch( mainSlice.actions.updateActivities( [ activity ] ) )
        dispatch( mainActions.queryStats( merchantId ) )
      } catch( err ) {
        // Activity might not exists and currently the server will throw an error
      }
    }
  },
  queryPaginatedActivities(
    merchantId: string,
    filter: ActivitiesService.QueryFilter,
    options: ActivitiesService.QueryOptions,
  ): MainThunkAction<PaginatedItems<MainActivity>> {
    return async ( dispatch ) => {
      const paginatedActivities = await activitiesService.query( merchantId, filter, options )
      dispatch( mainSlice.actions.updateActivities( paginatedActivities.items ) )
      return paginatedActivities
    }
  },
  queryStats( merchantId: string ): RootThunk<MainActivity.Stats> {
    return async ( dispatch ) => {
      const stats = await activitiesService.getStats( merchantId )
      dispatch( mainSlice.actions.updateStats( stats ) )
      return stats
    }
  },
  restoreActivity: createActivityUpdateAction( activitiesService.restoreActivity ),
  restoreDeletedActivity: createActivityUpdateAction( activitiesService.restoreDeletedActivity ),
  restoreActivityFromDismiss: createActivityUpdateAction( activitiesService.restoreActivityFromDismiss ),

  tasks: {
    getById(
      merchantId: string,
      taskId: string,
    ): RootThunk<MainTask> {
      return async ( dispatch ) => {
        const task = await taskService.getById( merchantId, taskId )
        dispatch( mainSlice.actions.updateTask( [ task ] ) )
        return task
      }
    },
    startConversation(
      merchantId: string,
      taskId: string,
    ): RootThunk<MainTask> {
      return async ( dispatch ) => {
        try {
          dispatch( mainSlice.actions.addProcessingTaskId( taskId ) )
          const task = await taskService.startConversation( merchantId, taskId )
          dispatch( mainSlice.actions.updateTask( [ task ] ) )
          return task
        } finally {
          dispatch( mainSlice.actions.removeProcessTaskId( taskId ) )
        }
      }
    },
  },
}

export const mainStoreConfig: ModuleStoreConfig<MainState> = {
  key: mainStoreKey,
  initialState: mainInitialState,
  reducers: mainSlice.reducer,
}

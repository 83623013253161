/* eslint-disable no-restricted-imports */
import SuccessIcon from "@mui/icons-material/CheckCircleOutlined"
import CloseIcon from "@mui/icons-material/CloseOutlined"
import ErrorIcon from "@mui/icons-material/ErrorOutlineOutlined"
import InfoIcon from "@mui/icons-material/InfoOutlined"
import WarningIcon from "@mui/icons-material/WarningAmberOutlined"
import { IconButton } from "@mui/material"
import { useSnackbar as useSnackbarNotistack } from "notistack"
import React, { useCallback, useMemo } from "react"

import { styleHelpers } from "../helpers/styleHelpers"

export interface AlertIconProps {
  variant: AlertProps[ "variant" ]
}

export const AlertIcon: React.FC<AlertIconProps> = React.memo( ( { variant } ) => {
  const iconBaseStyle: React.CSSProperties = {
    height: "18px",
    width: "18px",
  }
  switch( variant ) {
    case "error": {
      return (
        <ErrorIcon
          style={ {
            ...iconBaseStyle,
            color: styleHelpers.colors.error,
          } }
        />
      )
    }
    case "info": {
      return (
        <InfoIcon
          style={ {
            ...iconBaseStyle,
            color: styleHelpers.colors.info,
          } }
        />
      )
    }
    case "warning": {
      return (
        <WarningIcon
          style={ {
            ...iconBaseStyle,
            color: styleHelpers.colors.warning,
          } }
        />
      )
    }
    case "success":
    default: {
      return (
        <SuccessIcon
          style={ {
            ...iconBaseStyle,
            color: styleHelpers.colors.success,
          } }
        />
      )
    }
  }
} )

export interface AlertProps {
  id: string | number
  isCloseable?: boolean
  message?: string | React.ReactNode
  style?: React.CSSProperties
  variant?: "error" | "info" | "success" | "warning"

  onClose?: () => void
  renderAction?: ( () => React.ReactNode ) | null
}

export const Alert = React.forwardRef<HTMLDivElement, AlertProps>( ( {
  id,
  isCloseable,
  message,
  renderAction,
  variant,
  style,
  onClose,
}, forwardRef ) => {
  const { closeSnackbar } = useSnackbarNotistack()
  const close = useCallback( () => {
    closeSnackbar( id )
    onClose?.()
  }, [ closeSnackbar, id, onClose ] )

  const color = useMemo( () => {
    switch( variant ) {
      case "error":
        return styleHelpers.colors.errorDark
      case "info":
        return styleHelpers.colors.infoDark
      case "warning":
        return styleHelpers.colors.warningDark
      case "success":
      default:
        return styleHelpers.colors.successDark
    }
  }, [ variant ] )

  const backgroundColor = useMemo( () => {
    switch( variant ) {
      case "error":
        return styleHelpers.colors.errorLight
      case "info":
        return styleHelpers.colors.infoLight
      case "warning":
        return styleHelpers.colors.warningLight
      case "success":
      default:
        return styleHelpers.colors.successLight
    }
  }, [ variant ] )

  return (
    <div
      key={ id }
      ref={ forwardRef }
      style={ {
        alignItems: "center",
        backgroundColor,
        borderRadius: "4px",
        display: "flex",
        padding: "15px 16px",
        marginRight: "-250px", // This is width of sidebar so it can be centered on the page content
        width: "600px",
        ...style,
      } }>
      <AlertIcon variant={ variant }/>
      <div
        style={ {
          color,
          flex: 1,
          fontSize: styleHelpers.fonts.sizes.default,
          fontWeight: styleHelpers.fonts.weight.default,
          marginLeft: "12px",
        } }
      >
        { message }
      </div>
      { renderAction?.() }
      { 
        isCloseable && (
          <IconButton onClick={ close }>
            <CloseIcon
              style={ {
                color: styleHelpers.colors.lightDarkGray,
              } }
            />
          </IconButton>
        )
      }
    </div>
  )
} )

/* eslint-disable @typescript-eslint/ban-ts-comment */
import type { MessagesMessage } from "libs/frontend-dashboard/connect/src/types"
import type { ContentBlogPost, ContentSocialPostSuggestionBeforeAfterPhotos, ContentSocialPostSuggestionBlogPost, ContentSocialPostSuggestionBulkMessage, ContentSocialPostSuggestionPhotos, ContentSocialPostSuggestionReview } from "libs/frontend-dashboard/content/src/types"
import type { ApiContentSocialPostSuggestionBeforeAfterPhotos, ApiContentSocialPostSuggestionBlogPost, ApiContentSocialPostSuggestionBulkMessage, ApiContentSocialPostSuggestionPhotos, ApiContentSocialPostSuggestionReview } from "libs/frontend-dashboard/content/src/types/api"
import { parseContentSocialPostSuggestionFromApi } from "libs/frontend-dashboard/content/src/types/api"
import type { ReviewsReview } from "libs/frontend-dashboard/reviews/src/types"
import { omit } from "lodash"

import { InternalError } from "@onelocal/frontend/common"
import type { Name } from "@onelocal/shared/common"
import { utilHelpers } from "@onelocal/shared/common"
import type { MainActivityBase, MainActivityConversation, MainActivityKeywordRankingBlog, MainActivityReview, MainActivityShareBeforeAfterPhotos, MainActivityShareBlogPost, MainActivityShareBulkMessage, MainActivitySharePhotos, MainActivityShareReview, MainActivityTask, MainPerformanceInsight, MainTaskBase, MainTaskInformation, MainTaskTicket } from "."
import { MainActivity, MainTask } from "."

export interface ApiActivityBase {
  id: string
  archived_account: ApiMainActivity.ActivityUserRef | null
  assigned_account: ApiMainActivity.ActivityUserRef | null
  customer: ApiMainActivity.Customer
  date: string
  dismissed_by: {
    [account_id: string]: string
  }
  categories: MainActivity.Category[]
  merchant_id: string
  read_by: {
    [account_id: string]: string
  }
  status: MainActivity.Status
  type: MainActivity.Type
}

export interface ApiActivityConversation extends ApiActivityBase {
  call_ids: string[]
  conversation_id: string
  last_message?: ApiMainActivity.Message | null
  message_ids: string[]
  stats: {
    missed_calls: number
    unread_messages: number
  }
  type: MainActivity.Type.CONVERSATION
}

export interface ApiActivityKeywordRankingBlog extends ApiActivityBase {
  blog_post: {
    id: string
    keyword: string
    publish_date?: string | null
    status: ContentBlogPost.Status
  }
  blog_post_id: string
  type: MainActivity.Type.KEYWORD_RANKING_BLOG
}

export interface ApiActivityReview extends ApiActivityBase {
  review: {
    comment: string
    rating: number
    source: ReviewsReview.Source
  }
  review_id: string
  type: MainActivity.Type.REVIEW
}

export interface ApiActivityShareBeforeAfterPhotos extends ApiActivityBase {
  bulk_message_id: string
  social_post_suggestion: ApiContentSocialPostSuggestionBeforeAfterPhotos
  social_post_suggestion_id: string
  type: MainActivity.Type.SHARE_BEFORE_AFTER_PHOTOS
}

export interface ApiActivityShareBlogPost extends ApiActivityBase {
  blog_post_id: string
  social_post_suggestions: ApiContentSocialPostSuggestionBlogPost[]
  social_post_suggestion_ids: string[]
  type: MainActivity.Type.SHARE_BLOG_POST
}

export interface ApiActivityShareBulkMessage extends ApiActivityBase {
  bulk_message_id: string
  social_post_suggestion: ApiContentSocialPostSuggestionBulkMessage
  social_post_suggestion_id: string
  type: MainActivity.Type.SHARE_BULK_MESSAGE
}

export interface ApiActivitySharePhotos extends ApiActivityBase {
  social_post_suggestion: ApiContentSocialPostSuggestionPhotos
  social_post_suggestion_id: string
  type: MainActivity.Type.SHARE_PHOTOS
}

export interface ApiActivityShareReview extends ApiActivityBase {
  review_id: string
  social_post_suggestion: ApiContentSocialPostSuggestionReview
  social_post_suggestion_id: string
  type: MainActivity.Type.SHARE_REVIEW
}

export interface ApiActivityTask extends ApiActivityBase {
  title: string
  task: ApiMainTask
  type: MainActivity.Type.TASK
}

export type ApiActivity = (
  ApiActivityConversation
  | ApiActivityKeywordRankingBlog
  | ApiActivityReview
  | ApiActivityShareBeforeAfterPhotos
  | ApiActivityShareBlogPost
  | ApiActivityShareBulkMessage
  | ApiActivitySharePhotos
  | ApiActivityShareReview
  | ApiActivityTask
)

export namespace ApiMainActivity {
  export interface Customer {
    id?: string
    name?: string
    phone_number?: string
  }

  export interface Message {
    id: string
    date: string
    text: string
    type: MessagesMessage.Type
  }

  export interface Stats {
    all: number
    assignments: number
    message: number
    missed_call: number
    review: number
    task: number
  }

  export interface ActivityUserRef extends Name {
    account_id: string
    date: string
  }
}

const parseApiUser = ( user: ApiMainActivity.ActivityUserRef | null ) => (
  user
    ? {
      accountId: user.account_id,
      date: user.date,
      display: user.display,
      family: user.family,
      given: user.given,
    } as MainActivity.AccountRef
    : null
)

const parseUsersMapDate = ( usersMap: {
  [account_id: string]: string
} ) => {
  const parsedUsersMap: MainActivity.UserIdToDate = {}

  for( const userId in usersMap ) {
    parsedUsersMap[ userId ] = usersMap[ userId ]
  }

  return parsedUsersMap
}

export const parseActivityFromApi = ( apiActivity: ApiActivity ): MainActivity => {
  const activity: MainActivityBase = {
    id: apiActivity.id,
    archived: parseApiUser( apiActivity.archived_account ),
    assignedAccount: parseApiUser( apiActivity.assigned_account ),
    date: apiActivity.date,
    dismissedBy: parseUsersMapDate( apiActivity.dismissed_by ),
    categories: apiActivity.categories,
    merchantId: apiActivity.merchant_id,
    readBy: parseUsersMapDate( apiActivity.read_by ),
    status: apiActivity.status,
    type: apiActivity.type,
  }

  const activityType = apiActivity.type

  switch( activityType ) {
    case MainActivity.Type.CONVERSATION: {
      const activityConversation: MainActivityConversation = {
        ...activity,
        callIds: apiActivity.call_ids,
        conversationId: apiActivity.conversation_id,
        customer: {
          name: apiActivity.customer.name,
          phoneNumber: apiActivity.customer.phone_number,
        },
        lastMessage: apiActivity.last_message,
        messageIds: apiActivity.message_ids,
        stats: {
          missedCalls: apiActivity.stats.missed_calls,
          unreadMessages: apiActivity.stats.unread_messages,
        },
        type: MainActivity.Type.CONVERSATION,
      }

      return activityConversation
    }
    case MainActivity.Type.KEYWORD_RANKING_BLOG: {
      const activityKeywordRankingBlog: MainActivityKeywordRankingBlog = {
        ...activity,
        blogPost: {
          id: apiActivity.blog_post.id,
          keyword: apiActivity.blog_post.keyword,
          publishDate: apiActivity.blog_post.publish_date,
          status: apiActivity.blog_post.status,
        },
        blogPostId: apiActivity.blog_post_id,
        type: MainActivity.Type.KEYWORD_RANKING_BLOG,
      }

      return activityKeywordRankingBlog
    }
    case MainActivity.Type.REVIEW: {
      const activityReview: MainActivityReview = {
        ...activity,
        customer: {
          name: apiActivity.customer.name,
          phoneNumber: apiActivity.customer.phone_number,
        },
        review: apiActivity.review,
        reviewId: apiActivity.review_id,
        type: MainActivity.Type.REVIEW,
      }

      return activityReview
    }
    case MainActivity.Type.SHARE_BEFORE_AFTER_PHOTOS: {
      const socialPostSuggestion = parseContentSocialPostSuggestionFromApi( apiActivity.social_post_suggestion ) as ContentSocialPostSuggestionBeforeAfterPhotos
      const activityShareBeforeAfterPhotos: MainActivityShareBeforeAfterPhotos = {
        ...activity,
        socialPostSuggestion,
        socialPostSuggestionId: apiActivity.social_post_suggestion_id,
        type: MainActivity.Type.SHARE_BEFORE_AFTER_PHOTOS,
      }

      return activityShareBeforeAfterPhotos
    }
    case MainActivity.Type.SHARE_BLOG_POST: {
      const socialPostSuggestions = apiActivity.social_post_suggestions.map( parseContentSocialPostSuggestionFromApi ) as ContentSocialPostSuggestionBlogPost[]
      const activityShareBlogPost: MainActivityShareBlogPost = {
        ...activity,
        blogPostId: apiActivity.blog_post_id,
        socialPostSuggestions,
        socialPostSuggestionIds: apiActivity.social_post_suggestion_ids,
        type: MainActivity.Type.SHARE_BLOG_POST,
      }

      return activityShareBlogPost
    }
    case MainActivity.Type.SHARE_BULK_MESSAGE: {
      const socialPostSuggestion = parseContentSocialPostSuggestionFromApi( apiActivity.social_post_suggestion ) as ContentSocialPostSuggestionBulkMessage
      const activityShareBulkMessage: MainActivityShareBulkMessage = {
        ...activity,
        bulkMessageId: apiActivity.bulk_message_id,
        socialPostSuggestion,
        socialPostSuggestionId: apiActivity.social_post_suggestion_id,
        type: MainActivity.Type.SHARE_BULK_MESSAGE,
      }

      return activityShareBulkMessage
    }
    case MainActivity.Type.SHARE_PHOTOS: {
      const socialPostSuggestion = parseContentSocialPostSuggestionFromApi( apiActivity.social_post_suggestion ) as ContentSocialPostSuggestionPhotos
      const activityShareReview: MainActivitySharePhotos = {
        ...activity,
        socialPostSuggestion,
        socialPostSuggestionId: apiActivity.social_post_suggestion_id,
        type: MainActivity.Type.SHARE_PHOTOS,
      }

      return activityShareReview
    }
    case MainActivity.Type.SHARE_REVIEW: {
      const socialPostSuggestion = parseContentSocialPostSuggestionFromApi( apiActivity.social_post_suggestion ) as ContentSocialPostSuggestionReview
      const activityShareReview: MainActivityShareReview = {
        ...activity,
        reviewId: apiActivity.review_id,
        socialPostSuggestion,
        socialPostSuggestionId: apiActivity.social_post_suggestion_id,
        type: MainActivity.Type.SHARE_REVIEW,
      }

      return activityShareReview
    }
    case MainActivity.Type.TASK: {
      const activityTask: MainActivityTask = {
        ...activity,
        task: parseTaskFromApi( apiActivity.task ),
        type: MainActivity.Type.TASK,
      }

      return activityTask
    }
    default:
      utilHelpers.assertNever( activityType )
      throw new InternalError( `Unsupported activity type: ${ activityType }` )
  }
}

export const parseActivitiesStatsFromApi = ( stats: ApiMainActivity.Stats ) => {
  return {
    ...( omit( stats, "missed_call" ) ),
    missedCall: stats.missed_call,
  } as MainActivity.Stats
}

interface ApiPerformanceInsight {
  google_business_profile_events: {
    delta: {
      traffic_rate: number | null
    }
    historical: {
      interactions: MainPerformanceInsight.HistoricalData[]
    }
    total: {
      all: number
      call_clicks: number
      conversations: number
      other: number
      website_clicks: number
    }
  }
  lead: {
    delta: {
      total_rate: number | null
    }
    total: {
      all: number
      assistant_conversation_voicemail: number
      referral: number
      visit: number
      website_call_link_click: number
      website_chat_message: number
      website_form_submission: number
      website_sms_link_click: number
    }
  }
  organic_search: {
    delta: {
      conversions_rate: number | null
      traffic_rate: number | null
    }
    historical: {
      conversions: MainPerformanceInsight.HistoricalData[]
      traffic: MainPerformanceInsight.HistoricalData[]
    }
    total: {
      conversions: number
      traffic: number
    }
  }
  paid_ad: {
    delta: {
      conversions_rate: number | null
      traffic_rate: number | null
    }
    historical: {
      conversions: MainPerformanceInsight.HistoricalData[]
      traffic: MainPerformanceInsight.HistoricalData[]
    }
    total: {
      conversions: number
      traffic: number
    }
  }
  review: {
    average_rating: number
    delta: {
      total_rate: number | null
    }
    total: {
      all: number
      facebook: number
      google: number
      reviewedge: number
      yellow_pages: number
      yelp: number
    }
  }
  site: {
    delta: {
      total_traffic_rate: number | null
    }
    historical: {
      conversions: MainPerformanceInsight.HistoricalData[]
      traffic: MainPerformanceInsight.HistoricalData[]
    }
    total: {
      conversions: ApiPerformanceInsight.SiteSourceStats
      traffic: ApiPerformanceInsight.SiteSourceStats
    }
  }
  summary: {
    lead: {
      average_response_time: number
      lead_channel: string
      top_source: string
      total: number
    }
    review: {
      average_rating: number
      top_source: string
      total: number
    }
  }
}

export namespace ApiPerformanceInsight {
  export interface SiteSourceStats {
    all: number
    direct: number
    organic_search: number
    paid_ads: number
    referral: number
    social: number
  }
}

export const parseInsightsFromApi = ( apiPerformanceInsight: ApiPerformanceInsight ) => {
  const performanceInsight: MainPerformanceInsight = {
    googleBusinessProfileEvents: {
      delta: {
        trafficRate: apiPerformanceInsight.google_business_profile_events.delta.traffic_rate,
      },
      historical: {
        interactions: apiPerformanceInsight.google_business_profile_events.historical.interactions,
      },
      total: {
        all: apiPerformanceInsight.google_business_profile_events.total.all,
        callClicks: apiPerformanceInsight.google_business_profile_events.total.call_clicks,
        conversations: apiPerformanceInsight.google_business_profile_events.total.conversations,
        other: apiPerformanceInsight.google_business_profile_events.total.other,
        websiteClicks: apiPerformanceInsight.google_business_profile_events.total.website_clicks,
      },
    },
    lead: {
      delta: {
        totalRate: apiPerformanceInsight.lead.delta.total_rate,
      },
      total: {
        all: apiPerformanceInsight.lead.total.all,
        assistantConversationVoicemail: apiPerformanceInsight.lead.total.assistant_conversation_voicemail,
        referral: apiPerformanceInsight.lead.total.referral,
        visit: apiPerformanceInsight.lead.total.visit,
        websiteCallLinkClick: apiPerformanceInsight.lead.total.website_call_link_click,
        websiteChatMessage: apiPerformanceInsight.lead.total.website_chat_message,
        websiteFormSubmission: apiPerformanceInsight.lead.total.website_form_submission,
        websiteSMSLinkClick: apiPerformanceInsight.lead.total.website_sms_link_click,
      },
    },
    organicSearch: {
      delta: {
        conversionsRate: apiPerformanceInsight.organic_search.delta.conversions_rate,
        trafficRate: apiPerformanceInsight.organic_search.delta.traffic_rate,
      },
      historical: {
        conversions: apiPerformanceInsight.organic_search.historical.conversions,
        traffic: apiPerformanceInsight.organic_search.historical.traffic,
      },
      total: {
        conversions: apiPerformanceInsight.organic_search.total.conversions,
        traffic: apiPerformanceInsight.organic_search.total.traffic,
      },
    },
    paidAd: {
      delta: {
        conversionsRate: apiPerformanceInsight.paid_ad.delta.conversions_rate,
        trafficRate: apiPerformanceInsight.paid_ad.delta.traffic_rate,
      },
      historical: {
        conversions: apiPerformanceInsight.paid_ad.historical.conversions,
        traffic: apiPerformanceInsight.paid_ad.historical.traffic,
      },
      total: {
        conversions: apiPerformanceInsight.paid_ad.total.conversions,
        traffic: apiPerformanceInsight.paid_ad.total.traffic,
      },
    },
    review: {
      averageRating: apiPerformanceInsight.review.average_rating,
      delta: {
        totalRate: apiPerformanceInsight.review.delta.total_rate,
      },
      total: {
        all: apiPerformanceInsight.review.total.all,
        facebook: apiPerformanceInsight.review.total.facebook,
        google: apiPerformanceInsight.review.total.google,
        reviewedge: apiPerformanceInsight.review.total.reviewedge,
        yellowPages: apiPerformanceInsight.review.total.yellow_pages,
        yelp: apiPerformanceInsight.review.total.yelp,
      },
    },
    site: {
      delta: {
        totalTrafficRate: apiPerformanceInsight.site.delta.total_traffic_rate,
      },
      historical: {
        conversions: apiPerformanceInsight.site.historical.conversions,
        traffic: apiPerformanceInsight.site.historical.traffic,
      },
      total: {
        conversions: {
          all: apiPerformanceInsight.site.total.conversions.all,
          direct: apiPerformanceInsight.site.total.conversions.direct,
          organicSearch: apiPerformanceInsight.site.total.conversions.organic_search,
          paidAds: apiPerformanceInsight.site.total.conversions.paid_ads,
          referral: apiPerformanceInsight.site.total.conversions.referral,
          social: apiPerformanceInsight.site.total.conversions.social,
        },
        traffic: {
          all: apiPerformanceInsight.site.total.traffic.all,
          direct: apiPerformanceInsight.site.total.traffic.direct,
          organicSearch: apiPerformanceInsight.site.total.traffic.organic_search,
          paidAds: apiPerformanceInsight.site.total.traffic.paid_ads,
          referral: apiPerformanceInsight.site.total.traffic.referral,
          social: apiPerformanceInsight.site.total.traffic.social,
        },
      },
    },
    summary: {
      lead: apiPerformanceInsight.summary.lead && {
        averageResponseTime: apiPerformanceInsight.summary.lead.average_response_time,
        leadChannel: apiPerformanceInsight.summary.lead.lead_channel,
        topSource: apiPerformanceInsight.summary.lead.top_source,
        total: apiPerformanceInsight.summary.lead.total,
      },
      review: apiPerformanceInsight.summary.review && {
        averageRating: apiPerformanceInsight.summary.review.average_rating,
        topSource: apiPerformanceInsight.summary.review.top_source,
        total: apiPerformanceInsight.summary.review.total,
      },
    },
  }

  return performanceInsight
}

export interface ApiMainTaskBase {
  category: MainTask.Category
  created: {
    at: string
    by: {
      ref: "accounts" | "internal_accounts" | "merchants"
      id: string
    }
  }
  description: string
  id: string
  merchant_id: string
  query_index: {
    merchant_name: string
    salesforce_account_id?: string
  }
  text_index: string[]
  title: string
  type: MainTask.Type
}

export interface ApiMainTaskInformation extends ApiMainTaskBase {
  attachments?: Array<{
    content_type: string
    filename: string
    original_filename: string
    url: string
  }>
  subject: string
  type: MainTask.Type.INFORMATION
}

export interface ApiMainTaskTicket extends ApiMainTaskBase {
  change_type?: MainTask.ChangeType
  initial_message?: string
  intercom?: {
    account_id: string
    contact_id: string
    conversation_id: string
  }
  jira: {
    id: string
    key: string
  }
  status: {
    at: string
    details?: string
    labels: MainTask.Labels
    logs: MainTask.StatusLog[]
    type: MainTask.StatusType
  }
  type: MainTask.Type.TICKET
}

export type ApiMainTask = (
  ApiMainTaskInformation
  | ApiMainTaskTicket
)

export const parseTaskFromApi = ( apiTask: ApiMainTask ) => {
  const taskBase: MainTaskBase = {
    category: apiTask.category,
    created: apiTask.created,
    description: apiTask.description,
    id: apiTask.id,
    merchant_id: apiTask.merchant_id,
    title: apiTask.title,
    type: apiTask.type,
  }

  switch( apiTask.type ) {
    case MainTask.Type.INFORMATION: {
      const taskInformation: MainTaskInformation = {
        ...taskBase,
        attachments: apiTask.attachments?.map( ( attachment ) => ( {
          contentType: attachment.content_type,
          filename: attachment.filename,
          originalFilename: attachment.original_filename,
          url: attachment.url,
        } ) ),
        subject: apiTask.subject,
        type: MainTask.Type.INFORMATION,
      }

      return taskInformation
    }
    case MainTask.Type.TICKET: {
      const taskTicket: MainTaskTicket = {
        ...taskBase,
        changeType: apiTask.change_type,
        initialMessage: apiTask.initial_message,
        intercom: (
          apiTask.intercom
            ? {
              accountId: apiTask.intercom.account_id,
              contactId: apiTask.intercom.contact_id,
              conversationId: apiTask.intercom.conversation_id,
            }
            : undefined
        ),
        jira: apiTask.jira,
        status: apiTask.status,
        type: MainTask.Type.TICKET,
      }

      return taskTicket
    }
    default:
      utilHelpers.assertNever( apiTask )
      throw new InternalError( `Unsupported task type: ${ apiTask }` )
  }
}

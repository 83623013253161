import { useResource } from "@onelocal/frontend/common"
import { merchantsSelectors } from "../store/merchantsSelectors"
import { merchantsActions } from "../store/merchantsStore"

export const useMerchantSettings = ( merchantId: string, skipInitialLoad?: boolean ) => {
  const { resource: merchantSettings, reloadResource: reloadMerchantSettings } = useResource( {
    getAction: merchantsActions.merchantsSettings.get,
    id: merchantId,
    selector: merchantsSelectors.merchantSettings.byId,
    skipInitialLoad,
  } )

  return {
    merchantSettings,
    reloadMerchantSettings,
  }
}

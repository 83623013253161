import type { Selector } from "@reduxjs/toolkit"
import { createSelector } from "@reduxjs/toolkit"
import type { RootState } from "libs/frontend-dashboard-web/main/src/store"

import type { ContentState } from "./contentStore"

const contentSelector: Selector<RootState, ContentState> = state => state.content

const createContentSelector = <T>( combiner: ( contentState: ContentState ) => T ) => {
  return createSelector(
    contentSelector,
    combiner,
  )
}

export const contentSelectors = {
  activeContent: createContentSelector( ( contentState ) => contentState.activeContent ),
  blogPost: {
    byId: ( blogPostId: string ) => (
      createContentSelector(
        ( contentState ) => contentState.blogPost.byId[ blogPostId ] || null,
      )
    ),
  },
  socialPostSuggestion: {
    byId: ( socialPostSuggestionId: string ) => (
      createContentSelector(
        ( contentState ) => contentState.socialPostSuggestion.byId[ socialPostSuggestionId ] || null,
      )
    ),
  },
}

import { isEmpty, isEqual } from "lodash"
import { useCallback, useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"

import type { DefaultThunkAction } from ".."
import { useAsyncDispatch } from ".."

export interface useResourceOptions<TResource, TRootState> {
  id: string | null
  getAction: ( id: string ) => DefaultThunkAction<TRootState, TResource | null>
  selector: ( itemId: string ) => ( ( state: TRootState ) => ( TResource | null ) )
  skipInitialLoad?: boolean
}

export const useResource = <TResource extends { id: string }, TRootState>( options: useResourceOptions<TResource, TRootState> ) => {
  const { id, getAction } = options
  const [ isInitialLoad, setIsInitialLoad ] = useState( true )
  const resource = useSelector( id ? options.selector( id ) : () => null )
  const resourceRef = useRef<TResource | null>( resource )

  const dispatch = useAsyncDispatch()

  const reloadResource = useCallback( async ( useCache?: boolean, newId?: string ) => {
    if( ! id && ! newId ) {
      return null
    }

    if( id && newId && id !== newId ) {
      console.error( "Cannot pass a different ID" )
      return null
    }

    // Use ref to avoid to recreate the reloadResource function which will cause unwanted re-render
    if( useCache && ! isEmpty( resourceRef.current ) ) {
      return resourceRef.current
    }

    const newItem = await dispatch( getAction( ( id ?? newId ) as string ) )
    setIsInitialLoad( false )
    return newItem
  }, [ dispatch, getAction, id ] )

  // Use a ref so the ref doesn't change on re-render
  if( ! isEqual( resource, resourceRef.current ) ) {
    resourceRef.current = resource
  }

  useEffect( () => {
    if( options.skipInitialLoad === true ) {
      return
    }
    reloadResource()
  }, [ id ] )

  return {
    resource: isInitialLoad && isEmpty( resourceRef.current ) ? null : resourceRef.current,
    reloadResource,
  }
}

import { isArray, reduce } from "lodash"

// eslint-disable-next-line @nx/enforce-module-boundaries
import type { MerchantSettings } from "@onelocal/frontend-dashboard/settings"
import type { Name } from "@onelocal/shared/common"
import { utilHelpers } from "@onelocal/shared/common"
import type { ReviewsReview, ReviewsStats, ReviewsSurveyRequest, ReviewsSurveyResponse, ReviewsSurveyScheduledRequest } from "."
import { ReviewExternalAccount, ReviewsMerchantSettings, ReviewsSurveyTemplate } from "."

export interface ApiReviewsMerchantSettings {
  attribution?: {
    enabled?: boolean
  }
  bulk?: {
    allow_resend?: boolean
    email?: {
      enabled?: boolean
    }
    sms?: {
      enabled?: boolean
    }
  }
  connected_accounts: ApiReviewsMerchantSettings.ConnectedAccount[]
  enabled: boolean
  providers: ApiReviewsMerchantSettings.Provider[]
  status: MerchantSettings.ProductStatus
}

export namespace ApiReviewsMerchantSettings {
  export interface ConnectedAccount {
    created: {
      at: string
    }
    id: string
    type: ReviewsMerchantSettings.ConnectedAccountType
    error_count?: number
    has_auth_error?: boolean
    location_id?: string
    location_name?: string
    location_url?: string
    name?: string
  }

  export type Provider = ProviderFacebook | ProviderGoogle | ProviderOther

  export interface ProviderBase {
    id?: string
    type: ReviewsMerchantSettings.ProviderType
    text: string
    url: string
  }

  export interface ProviderFacebook extends ProviderBase {
    type: ReviewsMerchantSettings.ProviderType.FACEBOOK
  }

  export interface ProviderGoogle extends ProviderBase {
    type: ReviewsMerchantSettings.ProviderType.GOOGLE
    placeId: string
  }

  export interface ProviderOther extends ProviderBase {
    type: ReviewsMerchantSettings.ProviderType.OTHER
    name: string
  }
}

export function parseReviewsMerchantSettingsFromApi( apiMerchantSettings: ApiReviewsMerchantSettings ) {
  const merchantSettings: ReviewsMerchantSettings = {
    attribution: {
      enabled: apiMerchantSettings.attribution?.enabled === true,
    },
    bulk: {
      defaultResend: apiMerchantSettings.bulk?.allow_resend || false,
      email: {
        enabled: apiMerchantSettings.bulk?.email?.enabled || false,
      },
      sms: {
        enabled: apiMerchantSettings.bulk?.sms?.enabled || false,
      },
    },
    connectedAccounts: ( apiMerchantSettings.connected_accounts || [] ).map( ( apiConnectedAccount ) => ( {
      created: apiConnectedAccount.created,
      id: apiConnectedAccount.id,
      type: apiConnectedAccount.type,
      errorCount: apiConnectedAccount.error_count,
      hasAuthError: apiConnectedAccount.has_auth_error,
      locationId: apiConnectedAccount.location_id,
      locationName: apiConnectedAccount.location_name,
      locationUrl: apiConnectedAccount.location_url,
      name: apiConnectedAccount.name,
    } ) ),
    enabled: apiMerchantSettings.enabled,
    providers: apiMerchantSettings.providers.map( parseReviewsMerchantSettingsProviderFromApi ),
    status: apiMerchantSettings.status,
  }

  return merchantSettings
}

export function parseReviewsMerchantSettingsProviderFromApi( apiMerchantSettingsProvider: ApiReviewsMerchantSettings.Provider ) {
  const providerBase: ApiReviewsMerchantSettings.ProviderBase = {
    text: apiMerchantSettingsProvider.text,
    type: apiMerchantSettingsProvider.type,
    url: apiMerchantSettingsProvider.url,
    id: apiMerchantSettingsProvider.id,
  }

  const type = apiMerchantSettingsProvider.type

  switch( apiMerchantSettingsProvider.type ) {
    case ReviewsMerchantSettings.ProviderType.FACEBOOK:
      return {
        ...providerBase,
      } as ReviewsMerchantSettings.ProviderFacebook
    case ReviewsMerchantSettings.ProviderType.GOOGLE:
      return {
        ...providerBase,
        placeId: apiMerchantSettingsProvider.placeId,
      } as ReviewsMerchantSettings.ProviderGoogle
    case ReviewsMerchantSettings.ProviderType.OTHER:
      return {
        ...providerBase,
        name: apiMerchantSettingsProvider.name,
      } as ReviewsMerchantSettings.ProviderOther
    default: {
      utilHelpers.assertNever( apiMerchantSettingsProvider )
      throw new Error( `Unknown type ${ type }` )
    }
  }
}

export interface ApiReviewsReview {
  id: string
  comment?: string
  connected_account?: {
    id: string
    merchant_ids: string[]
    review_id: string
  }
  consent_to_share?: boolean
  created: {
    at: string
    by?: {
      id?: string
      ref?: string
      display_name?: string
    }
  }
  customer?: {
    mobile: string
    name: string
    phone: string
  }
  include_widget?: boolean
  merchant_id: string
  // provider_name: string
  rating?: number
  // rating_label: string
  reply?: {
    date: string
    comment: string
  }
  // request_id: string
  // reviewer_name?: string
  sentiment?: ReviewsReview.Sentiment
  source: ReviewsReview.Source
  // source_help_text: string
  // source_label: string
  survey_request?: ApiReviewsSurveyRequest
  survey_response?: ApiReviewsSurveyResponse
  widget_merchant_ids?: string[]
  updated?: {
    at: string
  }
  attribution?: {
    id: string
    value: string
    type: string
    updated: {
      at: string
      by: {
        id: string
        ref: string
      }
    }
    employee_id?: string
    survey_request_id?: string
    survey_response_id?: string
    survey_request?: ApiReviewsSurveyRequest
    survey_response?: ApiReviewsSurveyResponse
  }
}

export function parseReviewsReviewFromApi( apiReview: ApiReviewsReview ) {
  const review: ReviewsReview = {
    attribution: (
      apiReview.attribution
        ? {
          id: apiReview.attribution.id,
          type: apiReview.attribution.type,
          updated: apiReview.attribution.updated,
          value: apiReview.attribution.value,
          employeeId: apiReview.attribution.employee_id,
          surveyRequestId: apiReview.attribution.survey_request_id,
          surveyResponseId: apiReview.attribution.survey_response_id,
          surveyRequest: (
            apiReview.attribution.survey_request
              ? parseReviewsSurveyRequestFromApi( apiReview.attribution.survey_request )
              : undefined
          ),
          surveyResponse: (
            apiReview.attribution.survey_response
              ? parseReviewsSurveyResponseFromApi( apiReview.attribution.survey_response )
              : undefined
          ),
        }
        : undefined
    ),
    created: {
      at: apiReview.created.at,
      by: (
        apiReview.created.by
          ? {
            displayName: apiReview.created.by.display_name,
            id: apiReview.created.by.id,
            ref: apiReview.created.by.ref,
          }
          : undefined
      ),
    },
    id: apiReview.id,
    merchantId: apiReview.merchant_id,
    source: apiReview.source,
    comment: apiReview.comment,
    connectedAccount: (
      apiReview.connected_account
        ? {
          id: apiReview.connected_account.id,
          merchantIds: apiReview.connected_account.merchant_ids,
          reviewId: apiReview.connected_account.review_id,
        }
        : undefined
    ),
    consentToShare: apiReview.consent_to_share,
    customer: (
      apiReview.customer
        ? {
          mobile: apiReview.customer.mobile,
          name: apiReview.customer.name,
          phone: apiReview.customer.phone,
        }
        : undefined
    ),
    includeWidget: apiReview.include_widget,
    rating: apiReview.rating,
    reply: apiReview.reply,
    sentiment: apiReview.sentiment,
    surveyRequest: (
      apiReview.survey_request
        ? parseReviewsSurveyRequestFromApi( apiReview.survey_request )
        : undefined
    ),
    surveyResponse: (
      apiReview.survey_response
        ? parseReviewsSurveyResponseFromApi( apiReview.survey_response )
        : undefined
    ),
    updated: (
      apiReview.updated
        ? {
          at: apiReview.updated.at,
        }
        : undefined
    ),
    widgetMerchantIds: apiReview.widget_merchant_ids,
  }

  return review
}

export interface ApiReviewsReviewGeneratedReply {
  text: string
}

export function parseReviewsReviewGeneratedReplyFromApi ( apiReviewReply: ApiReviewsReviewGeneratedReply ) {
  return apiReviewReply.text
}

export interface ApiReviewsSurveyRequest {
  id: string
  template: {
    id: string
    name: string
  }
  customer: {
    id?: string
    contact: string
    contact_type: "phone" | "email"
    name?: Name
  }
  sender?: {
    app_id?: string
    id: string
    ref: string
    name: Name
  }
  app?: string
  location?: string
  created?: {
    at: string
    by: {
      id: string
      ref: "customer" | "employees"
      type?: ReviewsSurveyRequest.RequestType
    }
  }
  message?: ApiReviewsSurveyRequest.Message
  schedule?: {
    date: string
  }
  response?: ApiReviewsSurveyResponse
  opened_at?: string
  reminder_opened_at?: string
  source?: {
    app_event_id: string
    event_key: string
    data: {
      service: {
        assignees?: Array<{
          name: string
        }>
        service_date: string
        source: string
        text: string
        type?: string
      }
    }
    merchant_workflow_id: string
  }
}

export namespace ApiReviewsSurveyRequest {
  export interface Message {
    asset?: {
      id?: string
      preview?: string
      default?: string
      filename?: string
      small?: {
        url: string
        size: {
          width: number
          height: number
        }
      }
      medium?: {
        url: string
        size: {
          width: number
          height: number
        }
      }
      original?: {
        url: string
        size: {
          width: number
          height: number
        }
      }
    }
    channel?: string
    custom?: string
    default?: string
    cta_label?: {
      custom?: string
      default?: string
    }
    start_component?: {
      custom_id?: string
      default_id?: string
    }
    subject?: {
      custom?: string
      default?: string
    }
    title?: {
      custom?: string
      default?: string
    }
  }
}

export function parseReviewsSurveyRequestFromApi( apiSurveyRequest: ApiReviewsSurveyRequest ) {
  const surveyRequest: ReviewsSurveyRequest = {
    customer: {
      contact: apiSurveyRequest.customer.contact,
      contactType: apiSurveyRequest.customer.contact_type,
      id: apiSurveyRequest.customer.id,
      name: apiSurveyRequest.customer.name,
    },
    template: apiSurveyRequest.template,
    app: apiSurveyRequest.app,
    created: apiSurveyRequest.created,
    id: apiSurveyRequest.id,
    location: apiSurveyRequest.location,
    message: apiSurveyRequest.message,
    openedAt: apiSurveyRequest.opened_at,
    reminderOpenedAt: apiSurveyRequest.reminder_opened_at,
    response: (
      apiSurveyRequest.response
        ? parseReviewsSurveyResponseFromApi( apiSurveyRequest.response )
        : undefined
    ),
    schedule: apiSurveyRequest.schedule,
    sender: apiSurveyRequest.sender ? utilHelpers.toCamelCase( apiSurveyRequest.sender ) : undefined,
    source: apiSurveyRequest.source ? utilHelpers.toCamelCase( apiSurveyRequest.source ) : undefined,
  }

  return surveyRequest
}

export interface ApiReviewsSurveyResponse {
  id: string
  created: {
    at: string
  }
  template_id: string
  merchant_id: string
  customer?: {
    name?: Name
    email?: string
    phone?: string
  }
  answers?: Array<{
    component_id: string
    label: string
    value: unknown
    text: string
  }>
  review_triage?: {
    type: string
    comment?: string
    consent_sharing?: boolean
    provider?: {
      id: string
      name: string
      type: string
    }
  }
  request?: ApiReviewsSurveyRequest
  completed_at: string
  query_index?: {
    sentiment?: ReviewsReview.Sentiment
  }
}

export function parseReviewsSurveyResponseFromApi( apiSurveyResponse: ApiReviewsSurveyResponse ) {
  const surveyResponse: ReviewsSurveyResponse = {
    completedAt: apiSurveyResponse.completed_at,
    created: apiSurveyResponse.created,
    id: apiSurveyResponse.id,
    merchantId: apiSurveyResponse.merchant_id,
    templateId: apiSurveyResponse.template_id,
    answers: (
      apiSurveyResponse.answers
        ? (
          apiSurveyResponse.answers.map( ( answer ) => ( {
            componentId: answer.component_id,
            label: answer.label,
            text: answer.text,
            value: answer.value,
          } ) )
        )
        : undefined
    ),
    customer: apiSurveyResponse.customer,
    request: apiSurveyResponse.request ? parseReviewsSurveyRequestFromApi( apiSurveyResponse.request ) : undefined,
    reviewTriage: (
      apiSurveyResponse.review_triage
        ? {
          comment: apiSurveyResponse.review_triage.comment,
          consentSharing: apiSurveyResponse.review_triage.consent_sharing,
          provider: apiSurveyResponse.review_triage.provider,
          type: apiSurveyResponse.review_triage.type,
        }
        : undefined
    ),
    queryIndex: apiSurveyResponse.query_index,
  }

  return surveyResponse
}

export interface ApiReviewsStats {
  initial: {
    [type in ReviewExternalAccount.Type]?: ApiReviewsStats.InitialStat
  } & { start_date: string }
  total: {
    reviews: number
    responses: {
      completed: number
      count: number
    }
    requests: {
      count: number
      total_opened_count: number
      open_rate: number
      opened_count: number
    }
  }
  reviews: {
    [type in ReviewExternalAccount.Type]?: ApiReviewsStats.ReviewsStat
    // reviewedge: {
    //     0: number
    //     100: number
    // }
  } & { reviewedge?: ApiReviewsStats.ReviewsStat }
  performance: {
    requests: {
      count: number
      opened_count: number
      open_rate: number
    }
    responses: {
      completed: number
      count: number
    }
    facebook: ApiReviewsStats.PerformanceStat
    google: ApiReviewsStats.PerformanceStat
    yelp: ApiReviewsStats.PerformanceStat
    yellow_pages: ApiReviewsStats.PerformanceStat
    reviews: {
      last_period_count: number
      positive: number
      neutral: number
      negative: number
      count: number
      reviewedge: {
        positive: number
        neutral: number
        negative: number
        count: number
      }
    }
    sentiment: {
      positive_rate: number
      positive: number
      neutral: number
      negative: number
      count: number
      delta_positive_rate: number
      total_delta_positive_rate: number
    }
    widget: {
      views_count: number
    }
    locations: []
    connected_accounts: {
        [id: string]: {
          average_rating: number
          delta_count: number
          delta_rating: number
          first_five_star_review: boolean
          five_star_rating_count: number
          last_period_average_rating: number
          last_period_reviews_count: number
          last_period_total_ratings: number
          reviews_count: number
          total_ratings: number
          type: number
        }
    }
  }
  sentiment: {
    total: ApiReviewsStats.SentimentStat
    total_last_period: ApiReviewsStats.SentimentStat
    last_period: ApiReviewsStats.SentimentStat
}
}

export namespace ApiReviewsStats {
  export interface ReviewsStat {
    25?: number
    50?: number
    75?: number
    100?: number
    count?: number
    total_rating?: number
    average_rating?: number
  }

  export interface InitialStat {
    enabled: boolean
    rating: number
    reviews: number
  }

  export interface PerformanceStat {
    average_rating: number
    current_period_reviews_count: number
    delta_count: number
    delta_rating: number
    first_five_star_review: boolean
    five_star_rating_count: number
    last_period_average_rating: number
    last_period_reviews_count: number
    last_period_total_ratings: number
    reviews_count: number
    total_ratings: number
  }

  export interface SentimentStat {
    count: number
    negative: number
    neutral: number
    positive_rate: number
    positive: number
  }
}

export function parseReviewsReviewStatsFromApi( apiReviewsStats: ApiReviewsStats ) {
  const reviewStats: ReviewsStats = {
    allReviews: {
      deltaPositiveRate: (
        apiReviewsStats.performance.sentiment.total_delta_positive_rate && apiReviewsStats.sentiment.total_last_period.count
          ? apiReviewsStats.performance.sentiment.total_delta_positive_rate
          : undefined
      ),
      deltaTotal: (
        apiReviewsStats.performance.reviews.count && apiReviewsStats.performance.reviews.last_period_count
          ? apiReviewsStats.performance.reviews.count
          : undefined
      ),
      positiveRate: apiReviewsStats.sentiment.total.positive_rate || undefined,
      total: apiReviewsStats.total.reviews,
    },
    connectedAccounts: {
      totalAverageRating: 0,
      totalCount: 0,
    },
    requestOpenRate: apiReviewsStats.total.requests.open_rate,
    responseCount: apiReviewsStats.total.responses.completed,
    highlights: {
      reviewNegativeCount: apiReviewsStats.performance.reviews.reviewedge.negative || undefined,
      reviewPositiveCount: apiReviewsStats.performance.reviews.reviewedge.positive || undefined,
      widgetViewCount: apiReviewsStats.performance.widget.views_count || undefined,
    },
  }

  let totalCount = 0
  let totalRating = 0

  for( const accountType of ReviewExternalAccount.ALL_TYPES ) {
    const accountPerformance = apiReviewsStats.performance[ accountType ]
    const accountReviews = apiReviewsStats.reviews[ accountType ]

    if( ! accountReviews?.average_rating || ! accountReviews.count || ! accountReviews.total_rating ) {
      continue
    }

    reviewStats.connectedAccounts[ accountType ] = {
      averageRating: accountReviews.average_rating,
      reviewCount: accountReviews.count,
      initial: {
        enabled: false,
      },
    }

    if( apiReviewsStats.initial[ accountType ]?.enabled ) {
      reviewStats.connectedAccounts[ accountType ]!.initial.averageRating = apiReviewsStats.initial[ accountType ]?.rating || undefined
      reviewStats.connectedAccounts[ accountType ]!.initial.reviewCount = apiReviewsStats.initial[ accountType ]?.reviews || undefined
    }

    totalCount += accountReviews.count
    totalRating += accountReviews.total_rating

    reviewStats.highlights[ accountType ] = {
      deltaRating: accountPerformance.delta_rating || undefined,
      fiveStarRatingCount: accountPerformance.five_star_rating_count || undefined,
    }
  }

  reviewStats.connectedAccounts = {
    ...reviewStats.connectedAccounts,
    totalAverageRating: utilHelpers.roundDecimal( ( totalRating / totalCount ) / 25 + 1, 1 ),
    totalCount: totalCount,
  }

  return reviewStats
}

export interface ApiReviewsSurveyScheduledRequest {
  id: string
  merchant_id: string
  template: {
    id: string
    name: string
  }
  customer: {
    id?: string
    contact: string
    contact_type: "phone" | "email"
    name?: Name
  }
  sender?: {
    id: string
    ref: string
    name: Name
  }
  created?: {
    at: string
    by: {
      id: string
      ref: "customer" | "employees"
      type?: ReviewsSurveyRequest.RequestType
    }
  }
  message?: ApiReviewsSurveyRequest.Message
  schedule: {
    date: string
  }
}

export function parseReviewsSurveyScheduledRequestFromApi( apiSurveyScheduledRequest: ApiReviewsSurveyScheduledRequest ) {
  const surveyScheduledRequest: ReviewsSurveyScheduledRequest = {
    created: apiSurveyScheduledRequest.created,
    customer: {
      contact: apiSurveyScheduledRequest.customer.contact,
      contactType: apiSurveyScheduledRequest.customer.contact_type,
      id: apiSurveyScheduledRequest.customer.id,
      name: apiSurveyScheduledRequest.customer.name,
    },
    id: apiSurveyScheduledRequest.id,
    merchantId: apiSurveyScheduledRequest.merchant_id,
    message: apiSurveyScheduledRequest.message,
    schedule: apiSurveyScheduledRequest.schedule,
    sender: apiSurveyScheduledRequest.sender,
    template: apiSurveyScheduledRequest.template,
  }

  return surveyScheduledRequest
}

export interface ApiReviewsSurveyTemplate {
  id: string
  merchant_id?: string
  name: string
  description?: string
  slug: string
  created: {
    at: string
    by: {
      id: string
      ref: string
    }
  }
  components: ApiReviewsSurveyTemplate.Component[]
  design?: {
    colors?: {
      background?: string
      button_background?: string
      button_text?: string
      text?: string
      title?: string
    }
  }
  duplicates_policy?: {
    checker?: ReviewsSurveyTemplate.DuplicatePolicyMode
    threshold?: number
  }
  email: {
    subject: string
    title: string
    text: string
    cta_label?: string
    start_component_id?: string
  }
  notifications?: {
    email?: {
      enabled?: boolean
      send_for?: ReviewsSurveyTemplate.EmailNotificationMode
    }
  }
  sms: {
    cta_text: string
    start_component_id?: string
    send_image: boolean
    text: string
  }
  reminders?: {
    enabled: boolean
    channel: ReviewsSurveyTemplate.ReminderChannel
    send_for_incomplete_responses: boolean
    threshold: number
    sms: {
      content: string
      start_component_id: string
      text: string
    }
    email: {
      start_component_id: string
      subject: string
      text: string
      title: string
    }
  }
  stats: {
    requests_count: number
    opened_count: number
    responses_count: number
    completed_count: number
    questions: {
      [id: string]: {
        responses_count: number
        skipped_count: number
        values: unknown
      }
    }
    review_triage?: {
      negative_count: number
      online_review_count: number
      positive_count: number
      responses_count: number
      values: {
        [id: string]: number
      }
    }
  }
  meta?: {
    title: string
    description: string
    image?: {
      small_url: string
      medium_url: string
      original_url: string
    }
  }
  translation: {
    mappings: {
      custom: unknown
      meta: {
        description: string
      }
    }
  }
}

export namespace ApiReviewsSurveyTemplate {
  export type Component = (
    ComponentIntro
    | ComponentExit
    | ComponentSentiment
    | ComponentMultiChoice
    | ComponentScale
    | ComponentShortAnswer
    | ComponentParagraph
    | ComponentYesNo
    | ComponentReviewTriage
  )

  export interface ComponentBase {
    id: string
    type: ReviewsSurveyTemplate.ComponentType
    label: string
    description: string
    next_component?: {
      id: string
      review_triage_type?: string
    }
    logic?: ComponentLogicRule[]
    // data_type?: string
    // collect_contact?: string
  }

  export interface ComponentLogicRule {
    id?: string
    operator: string
    conditions: ComponentLogicRuleCondition[]
    action: {
      type: string
      component_id?: string
    }
  }

  export interface ComponentLogicRuleCondition {
    input: {
      type: string
      id?: string
    }
    condition: string
    value: unknown
  }

  export interface ComponentIntro extends ComponentBase {
    type: ReviewsSurveyTemplate.ComponentType.INTRO
    collect_contact: boolean
    name_field: string
    email_field: string
    phone_field: string
    show_logo: boolean
    image_type: string
    collect_contact_anonymous_only: boolean
  }

  export type ComponentExit = ComponentExitRedirect | ComponentExitStatic

  export interface ComponentExitBase extends ComponentBase {
    type: ReviewsSurveyTemplate.ComponentType.EXIT
    exit_type: ReviewsSurveyTemplate.ComponentExitType
  }

  export interface ComponentExitRedirect extends ComponentExitBase {
    exit_type: ReviewsSurveyTemplate.ComponentExitType.REDIRECT
    url: string
  }

  export interface ComponentExitStatic extends ComponentExitBase {
    exit_type: ReviewsSurveyTemplate.ComponentExitType.STATIC
    image_type: string
    show_logo?: boolean
    cta_link?: {
      label: string
      url: string
    }
  }

  export interface ComponentMultiChoice extends ComponentBase {
    type: ReviewsSurveyTemplate.ComponentType.MULTI_CHOICE
    allow_multiple_answers: boolean
    include_other_choice: boolean
    options: ComponentMultiChoiceOption[]
  }

  export interface ComponentMultiChoiceOption {
    id: string
    label: string
    value: string
  }

  export interface ComponentShortAnswer extends ComponentBase {
    type: ReviewsSurveyTemplate.ComponentType.SHORT_ANSWER
    data_type: string
    required: boolean
  }

  export interface ComponentParagraph extends ComponentBase {
    type: ReviewsSurveyTemplate.ComponentType.PARAGRAPH
    required: boolean
  }

  export interface ComponentScale extends ComponentBase {
    type: ReviewsSurveyTemplate.ComponentType.SCALE
    start_at: number
    end_at: number
    min_label: string
    middle_label: string
    max_label: string
    required: boolean
  }

  export interface ComponentYesNo extends ComponentBase {
    type: ReviewsSurveyTemplate.ComponentType.YES_NO
    yes_label?: string
    no_label?: string
    yes_primary: boolean
  }

  export type ComponentSentiment = ComponentSentimentEmotion | ComponentSentimentNPS | ComponentSentimentRating | ComponentSentimentYesNo

  export interface ComponentSentimentBase extends ComponentBase {
    type: ReviewsSurveyTemplate.ComponentType.SENTIMENT
    sentiment_type: ReviewsSurveyTemplate.ComponentSentimentType
  }

  export interface ComponentSentimentRating extends ComponentSentimentBase {
    sentiment_type: ReviewsSurveyTemplate.ComponentSentimentType.RATING
  }

  export interface ComponentSentimentEmotion extends ComponentSentimentBase {
    sentiment_type: ReviewsSurveyTemplate.ComponentSentimentType.EMOTION
  }

  export interface ComponentSentimentNPS extends ComponentSentimentBase {
    sentiment_type: ReviewsSurveyTemplate.ComponentSentimentType.NPS
    product_name?: string
  }

  export interface ComponentSentimentYesNo extends ComponentSentimentBase {
    sentiment_type: ReviewsSurveyTemplate.ComponentSentimentType.YES_NO
    yes_label?: string
    no_label?: string
    yes_primary: boolean
  }

  export interface ComponentReviewTriage extends ComponentBase {
    type: ReviewsSurveyTemplate.ComponentType.REVIEW_TRIAGE
    online_review: {
      enabled: boolean
      provider_ids: string[]
    }
    positive: {
      label: string
      description: string
      ask_consent_sharing: boolean
      next_component_id: string
    }
    negative: {
      label: string
      description: string
      next_component_id: string
    }
  }
}

export function parseReviewsSurveyTemplateFromApi( apiSurveyTemplate: ApiReviewsSurveyTemplate ) {
  const surveyTemplate: ReviewsSurveyTemplate = {
    components: apiSurveyTemplate.components.map( parseReviewsSurveyTemplateComponentFromApi ).filter( ( component ) => component != null ) as ReviewsSurveyTemplate.Component[],
    created: apiSurveyTemplate.created,
    email: apiSurveyTemplate.email,
    id: apiSurveyTemplate.id,
    name: apiSurveyTemplate.name,
    notifications: {
      email: {
        enabled: apiSurveyTemplate.notifications?.email?.enabled || false,
        sendFor: apiSurveyTemplate.notifications?.email?.send_for || ReviewsSurveyTemplate.EmailNotificationMode.NONE,
      },
    },
    reminders: (
      apiSurveyTemplate.reminders
        ? {
          channel: apiSurveyTemplate.reminders.channel,
          email: {
            startComponentId: apiSurveyTemplate.reminders.email.start_component_id,
            subject: apiSurveyTemplate.reminders.email.subject,
            text: apiSurveyTemplate.reminders.email.text,
            title: apiSurveyTemplate.reminders.email.title,
          },
          enabled: apiSurveyTemplate.reminders.enabled,
          sendForIncompleteResponses: apiSurveyTemplate.reminders.send_for_incomplete_responses,
          sms: {
            startComponentId: apiSurveyTemplate.reminders.sms.start_component_id,
            text: apiSurveyTemplate.reminders.sms.text,
          },
          threshold: apiSurveyTemplate.reminders.threshold,
        }
        : undefined
    ),
    slug: apiSurveyTemplate.slug,
    sms: {
      ctaText: apiSurveyTemplate.sms.cta_text,
      sendImage: apiSurveyTemplate.sms.send_image,
      text: apiSurveyTemplate.sms.text,
      startComponentId: apiSurveyTemplate.sms.start_component_id,
    },
    stats: {
      completedCount: apiSurveyTemplate.stats.completed_count,
      openedCount: apiSurveyTemplate.stats.opened_count,
      questions: reduce( apiSurveyTemplate.stats.questions, ( result, value, key ) => {
        result[ key ] = {
          responsesCount: value.responses_count,
          skippedCount: value.skipped_count,
          values: ( isArray( value.values ) ? value.values.map( utilHelpers.toCamelCase ) : value.values ) as ReviewsSurveyTemplate.ComponentValues,
        }

        return result
      }, { } as { [questionId: string]: { responsesCount: number, skippedCount: number, values: ReviewsSurveyTemplate.ComponentValues } } ),
      requestsCount: apiSurveyTemplate.stats.requests_count,
      responsesCount: apiSurveyTemplate.stats.responses_count,
      reviewTriage: (
        apiSurveyTemplate.stats.review_triage
          ? {
            negativeCount: apiSurveyTemplate.stats.review_triage.negative_count,
            onlineReviewCount: apiSurveyTemplate.stats.review_triage.online_review_count,
            positiveCount: apiSurveyTemplate.stats.review_triage.positive_count,
            responsesCount: apiSurveyTemplate.stats.review_triage.responses_count,
            values: apiSurveyTemplate.stats.review_triage.values,
          }
          : undefined
      ),
    },
    translation: apiSurveyTemplate.translation,
    description: apiSurveyTemplate.description,
    design: (
      apiSurveyTemplate.design
        ? {
          colors: (
            apiSurveyTemplate.design.colors
              ? {
                background: apiSurveyTemplate.design.colors.background,
                buttonBackground: apiSurveyTemplate.design.colors.button_background,
                buttonText: apiSurveyTemplate.design.colors.button_text,
                text: apiSurveyTemplate.design.colors.text,
                title: apiSurveyTemplate.design.colors.title,
              }
              : undefined
          ),
        }
        : undefined
    ),
    duplicatesPolicy: (
      apiSurveyTemplate.duplicates_policy
        ? {
          checker: apiSurveyTemplate.duplicates_policy.checker,
          threshold: apiSurveyTemplate.duplicates_policy.threshold,
        }
        : undefined
    ),
    merchantId: apiSurveyTemplate.merchant_id,
    meta: (
      apiSurveyTemplate.meta
        ? {
          description: apiSurveyTemplate.meta.description,
          title: apiSurveyTemplate.meta.title,
          image: (
            apiSurveyTemplate.meta.image
              ? {
                mediumUrl: apiSurveyTemplate.meta.image.medium_url,
                originalUrl: apiSurveyTemplate.meta.image.original_url,
                smallUrl: apiSurveyTemplate.meta.image.small_url,
              }
              : undefined
          ),
        }
        : undefined
    ),
  }

  return surveyTemplate
}

function parseReviewsSurveyTemplateComponentFromApi( apiSurveyTemplateComponent: ApiReviewsSurveyTemplate.Component ): ReviewsSurveyTemplate.Component | null{
  const surveyTemplateComponentBase: ReviewsSurveyTemplate.ComponentBase = {
    description: apiSurveyTemplateComponent.description,
    id: apiSurveyTemplateComponent.id,
    label: apiSurveyTemplateComponent.label,
    logic: apiSurveyTemplateComponent.logic ? apiSurveyTemplateComponent.logic.map( parseReviewsSurveyTemplateComponentLogicRuleFromApi ) : undefined,
    type: apiSurveyTemplateComponent.type,
    nextComponent: (
      apiSurveyTemplateComponent.next_component
        ? ( {
          id: apiSurveyTemplateComponent.next_component.id,
          reviewTriageType: apiSurveyTemplateComponent.next_component.review_triage_type,
        } )
        : undefined
    ),
  }

  switch( apiSurveyTemplateComponent.type ) {
    case ReviewsSurveyTemplate.ComponentType.EXIT: {
      const exitComponent: ReviewsSurveyTemplate.ComponentExitBase = {
        ...surveyTemplateComponentBase,
        exitType: apiSurveyTemplateComponent.exit_type,
        type: ReviewsSurveyTemplate.ComponentType.EXIT,
      }

      switch( apiSurveyTemplateComponent.exit_type ) {
        case ReviewsSurveyTemplate.ComponentExitType.REDIRECT: {
          const redirectExitComponent: ReviewsSurveyTemplate.ComponentExitRedirect = {
            ...exitComponent,
            exitType: apiSurveyTemplateComponent.exit_type,
            url: apiSurveyTemplateComponent.url,
          }

          return redirectExitComponent
        }
        case ReviewsSurveyTemplate.ComponentExitType.STATIC: {
          const staticExitComponent: ReviewsSurveyTemplate.ComponentExitStatic = {
            ...exitComponent,
            exitType: apiSurveyTemplateComponent.exit_type,
            imageType: apiSurveyTemplateComponent.image_type,
            showLogo: apiSurveyTemplateComponent.show_logo,
            ctaLink: apiSurveyTemplateComponent.cta_link,
          }

          return staticExitComponent
        }
      }

      break
    }
    case ReviewsSurveyTemplate.ComponentType.INTRO: {
      const introComponent: ReviewsSurveyTemplate.ComponentIntro = {
        ...surveyTemplateComponentBase,
        type: apiSurveyTemplateComponent.type,
        collectContact: apiSurveyTemplateComponent.collect_contact,
        nameField: apiSurveyTemplateComponent.name_field,
        emailField: apiSurveyTemplateComponent.email_field,
        phoneField: apiSurveyTemplateComponent.phone_field,
        showLogo: apiSurveyTemplateComponent.show_logo,
        imageType: apiSurveyTemplateComponent.image_type,
        collectContactAnonymousOnly: apiSurveyTemplateComponent.collect_contact_anonymous_only,
      }

      return introComponent
    }
    case ReviewsSurveyTemplate.ComponentType.MULTI_CHOICE: {
      const multiChoiceComponent: ReviewsSurveyTemplate.ComponentMultiChoice = {
        ...surveyTemplateComponentBase,
        type: apiSurveyTemplateComponent.type,
        allowMultipleAnswers: apiSurveyTemplateComponent.allow_multiple_answers,
        includeOtherChoice: apiSurveyTemplateComponent.include_other_choice,
        options: apiSurveyTemplateComponent.options,
      }

      return multiChoiceComponent
    }
    case ReviewsSurveyTemplate.ComponentType.PARAGRAPH: {
      const paragraphComponent: ReviewsSurveyTemplate.ComponentParagraph = {
        ...surveyTemplateComponentBase,
        type: apiSurveyTemplateComponent.type,
        required: apiSurveyTemplateComponent.required,
      }
      return paragraphComponent
    }
    case ReviewsSurveyTemplate.ComponentType.SCALE: {
      const scaleComponent: ReviewsSurveyTemplate.ComponentScale = {
        ...surveyTemplateComponentBase,
        type: apiSurveyTemplateComponent.type,
        startAt: apiSurveyTemplateComponent.start_at,
        endAt: apiSurveyTemplateComponent.end_at,
        minLabel: apiSurveyTemplateComponent.min_label,
        middleLabel: apiSurveyTemplateComponent.middle_label,
        maxLabel: apiSurveyTemplateComponent.max_label,
        required: apiSurveyTemplateComponent.required,
      }
      return scaleComponent
    }
    case ReviewsSurveyTemplate.ComponentType.SENTIMENT:
      switch( apiSurveyTemplateComponent.sentiment_type ) {
        case ReviewsSurveyTemplate.ComponentSentimentType.EMOTION: {
          const emotionSentimentComponent: ReviewsSurveyTemplate.ComponentSentimentEmotion = {
            ...surveyTemplateComponentBase,
            type: apiSurveyTemplateComponent.type,
            sentimentType: apiSurveyTemplateComponent.sentiment_type,
          }

          return emotionSentimentComponent
        }
        case ReviewsSurveyTemplate.ComponentSentimentType.NPS: {
          const npsSentimentComponent: ReviewsSurveyTemplate.ComponentSentimentNPS = {
            ...surveyTemplateComponentBase,
            type: apiSurveyTemplateComponent.type,
            sentimentType: apiSurveyTemplateComponent.sentiment_type,
            productName: apiSurveyTemplateComponent.product_name,
          }

          return npsSentimentComponent
        }
        case ReviewsSurveyTemplate.ComponentSentimentType.RATING: {
          const ratingSentimentComponent: ReviewsSurveyTemplate.ComponentSentimentRating = {
            ...surveyTemplateComponentBase,
            type: apiSurveyTemplateComponent.type,
            sentimentType: apiSurveyTemplateComponent.sentiment_type,
          }

          return ratingSentimentComponent
        }
        case ReviewsSurveyTemplate.ComponentSentimentType.YES_NO: {
          const yesNoSentimentComponent: ReviewsSurveyTemplate.ComponentSentimentYesNo = {
            ...surveyTemplateComponentBase,
            type: apiSurveyTemplateComponent.type,
            sentimentType: apiSurveyTemplateComponent.sentiment_type,
            yesLabel: apiSurveyTemplateComponent.yes_label,
            noLabel: apiSurveyTemplateComponent.no_label,
            yesPrimary: apiSurveyTemplateComponent.yes_primary,
          }

          return yesNoSentimentComponent
        }
      }
      break
    case ReviewsSurveyTemplate.ComponentType.SHORT_ANSWER: {
      const shortAnswerComponent: ReviewsSurveyTemplate.ComponentShortAnswer = {
        ...surveyTemplateComponentBase,
        type: apiSurveyTemplateComponent.type,
        dataType: apiSurveyTemplateComponent.data_type,
        required: apiSurveyTemplateComponent.required,
      }
      return shortAnswerComponent
    }
    case ReviewsSurveyTemplate.ComponentType.YES_NO: {
      const yesNoComponent: ReviewsSurveyTemplate.ComponentYesNo = {
        ...surveyTemplateComponentBase,
        type: apiSurveyTemplateComponent.type,
        yesLabel: apiSurveyTemplateComponent.yes_label,
        noLabel: apiSurveyTemplateComponent.no_label,
        yesPrimary: apiSurveyTemplateComponent.yes_primary,
      }
      return yesNoComponent
    }
    case ReviewsSurveyTemplate.ComponentType.REVIEW_TRIAGE: {
      const reviewTriageComponent: ReviewsSurveyTemplate.ComponentReviewTriage = {
        ...surveyTemplateComponentBase,
        type: apiSurveyTemplateComponent.type,
        onlineReview: {
          enabled: apiSurveyTemplateComponent.online_review.enabled,
          providerIds: apiSurveyTemplateComponent.online_review.provider_ids,
        },
        positive: {
          label: apiSurveyTemplateComponent.positive.label,
          description: apiSurveyTemplateComponent.positive.description,
          askConsentSharing: apiSurveyTemplateComponent.positive.ask_consent_sharing,
          nextComponentId: apiSurveyTemplateComponent.positive.next_component_id,
        },
        negative: {
          label: apiSurveyTemplateComponent.negative.label,
          description: apiSurveyTemplateComponent.negative.description,
          nextComponentId: apiSurveyTemplateComponent.negative.next_component_id,
        },
      }
      return reviewTriageComponent
    }
    default:
      utilHelpers.assertNever( apiSurveyTemplateComponent )
      return null
  }
}

function parseReviewsSurveyTemplateComponentLogicRuleFromApi( apiSurveyTemplateComponentLogicRule: ApiReviewsSurveyTemplate.ComponentLogicRule ) {
  const surveyTemplateComponentLogicRule: ReviewsSurveyTemplate.ComponentLogicRule = {
    action: apiSurveyTemplateComponentLogicRule.action,
    conditions: apiSurveyTemplateComponentLogicRule.conditions,
    operator: apiSurveyTemplateComponentLogicRule.operator,
    id: apiSurveyTemplateComponentLogicRule.id,
  }

  return surveyTemplateComponentLogicRule
}

import type { SnakeCasedPropertiesDeep } from "type-fest"

// eslint-disable-next-line @nx/enforce-module-boundaries
import type { MerchantSettings } from "@onelocal/frontend-dashboard/settings"
import { utilHelpers } from "@onelocal/shared/common"
import type { ChatConversation, SiteBlogPost, SiteMerchantSettings } from "."

type ApiSiteBlogPost= SnakeCasedPropertiesDeep<SiteBlogPost>

export function parseSiteBlogPostFromApi( apiBlogPost: ApiSiteBlogPost ) {
  const blogPost: SiteBlogPost = utilHelpers.toCamelCase( apiBlogPost )
  return blogPost
}

type ApiChatConversation = SnakeCasedPropertiesDeep<ChatConversation>

export function parseSiteChatConversationFromApi( apiChatConversation: ApiChatConversation ) {
  const chatConversation: ChatConversation = utilHelpers.toCamelCase( apiChatConversation )

  if( apiChatConversation.messages ) {
    chatConversation.messages = utilHelpers.toCamelCase( apiChatConversation.messages )
  }

  return chatConversation
}

export interface ApiSiteMerchantSettings {
  customer_notifications?: {
    email?: {
      enabled: boolean
      html: string
      subject: string
    }
    sms?: {
      enabled: boolean
      text: string
    }
  }
  status: "live"
  track_date: string
  v: number
}

export namespace ApiSiteMerchantSettings {

}

export function parseSiteMerchantSettingsFromApi( apiSiteMerchantSettings: ApiSiteMerchantSettings ) {
  const siteMerchantSettings: SiteMerchantSettings = {
    status: apiSiteMerchantSettings.status as MerchantSettings.ProductStatus.LIVE,
    trackDate: apiSiteMerchantSettings.track_date,
    v: apiSiteMerchantSettings.v,
  }

  if( apiSiteMerchantSettings.customer_notifications ) {
    siteMerchantSettings.customerNotifications = utilHelpers.toCamelCase( apiSiteMerchantSettings.customer_notifications )
  }

  return siteMerchantSettings
}

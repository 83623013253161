import { useResources } from "@onelocal/frontend/common"
import { siteSelectors } from "../store/siteSelectors"
import { siteActions } from "../store/siteStore"
import type { Site } from "../types"

export const useSiteTemplates = ( filter?: { isAvailable?: boolean, type?: Site.Type } ) => {
  const {
    resources,
    reloadResources,
  } = useResources( {
    defaultFilter: {
      isAvailable: filter?.isAvailable,
      type: filter?.type,
    },
    getQueryAction: siteActions.siteTemplates.query,
    selector: siteSelectors.siteTemplates.byIds,
  } )

  return {
    siteTemplates: resources,
    reloadSiteTemplates: reloadResources,
  }
}

// eslint-disable-next-line @nx/enforce-module-boundaries
import type { SocialPostSuggestion } from "@onelocal/frontend-admin-web/content"
import type { MessagesMessage } from "@onelocal/frontend-dashboard/connect"
import type { MerchantSettings } from "@onelocal/frontend-dashboard/settings"
import type { Name } from "@onelocal/shared/common"

export interface ReviewsMerchantSettings {
  attribution: {
    enabled: boolean
  }
  bulk: {
    defaultResend: boolean
    email: {
      enabled: boolean
    }
    sms: {
      enabled: boolean
    }
  }
  connectedAccounts: ReviewsMerchantSettings.ConnectedAccount[]
  enabled: boolean
  providers: ReviewsMerchantSettings.Provider[]
  status: MerchantSettings.ProductStatus | null
}

export namespace ReviewsMerchantSettings {
  export interface ConnectedAccount {
    created: {
      at: string
    }
    id: string
    type: ConnectedAccountType
    errorCount?: number
    hasAuthError?: boolean
    name?: string
    locationId?: string
    locationName?: string
    locationUrl?: string
  }

  export enum ConnectedAccountType {
    FACEBOOK = "facebook",
    GOOGLE = "google",
    YELLOW_PAGES = "yellow_pages",
    YELP = "yelp",
  }

  export type Provider = ProviderFacebook | ProviderGoogle | ProviderOther

  export enum ProviderType {
    FACEBOOK = "facebook",
    GOOGLE = "google",
    OTHER = "other",
  }

  export interface ProviderBase {
    id: string
    type: ProviderType
    text: string
    url: string
  }

  export interface ProviderFacebook extends ProviderBase {
    type: ProviderType.FACEBOOK
  }

  export interface ProviderGoogle extends ProviderBase {
    type: ProviderType.GOOGLE
    placeId: string
  }

  export interface ProviderOther extends ProviderBase {
    type: ProviderType.OTHER
    name: string
  }
}

export interface ReviewsReview {
  id: string
  attribution?: {
    id: string
    value: string
    type: string
    updated: {
      at: string
      by: {
        id: string
        ref: string
      }
    }
    employeeId?: string
    surveyRequestId?: string
    surveyResponseId?: string
    surveyRequest?: ReviewsSurveyRequest
    surveyResponse?: ReviewsSurveyResponse
  }
  comment?: string
  connectedAccount?: {
    id: string
    merchantIds: string[]
    reviewId: string
  }
  consentToShare?: boolean
  created: {
    at: string
    by?: {
      id?: string
      ref?: string
      displayName?: string
    }
  }
  customer?: {
    mobile: string
    name: string
    phone: string
  }
  includeWidget?: boolean
  merchantId: string
  rating?: number
  reply?: {
    date: string
    comment: string
  }
  sentiment?: ReviewsReview.Sentiment
  social_post_suggestion_id?: string
  social_post_suggestion?: SocialPostSuggestion
  source: ReviewsReview.Source
  surveyRequest?: ReviewsSurveyRequest
  surveyResponse?: ReviewsSurveyResponse
  updated?: {
    at: string
  }
  widgetMerchantIds?: string[]
}

export namespace ReviewsReview {
  export enum Source {
    REVIEWEDGE = "reviewedge",
    REQUEST = "request",
    FACEBOOK = "facebook",
    GOOGLE = "google",
    YELP = "yelp",
    YELLOW_PAGES = "yellow_pages",
  }

  export enum Sentiment {
    NEGATIVE = "negative",
    NEUTRAL = "neutral",
    POSITIVE = "positive",
  }
}

export interface ReviewExternalAccount {
  accountId?: string
  id: string
  origin: ReviewExternalAccount.Origin
  refreshToken: string
  token: string
  tokenExpirationDate: string
  type: ReviewExternalAccount.Type
}

export namespace ReviewExternalAccount {
  export enum Origin {
    IOS = "ios",
    WEB = "web",
  }

  export enum Type {
    FACEBOOK = "facebook",
    GOOGLE = "google",
    YELLOW_PAGES = "yellow_pages",
    YELP = "yelp",
  }

  export const ALL_TYPES = [
    ReviewExternalAccount.Type.FACEBOOK,
    ReviewExternalAccount.Type.GOOGLE,
    ReviewExternalAccount.Type.YELLOW_PAGES,
    ReviewExternalAccount.Type.YELP,
  ]
}

export interface ReviewsStats {
  allReviews: {
    deltaPositiveRate?: number
    deltaTotal?: number
    positiveRate?: number
    total: number
  }
  connectedAccounts: {
    [type in ReviewExternalAccount.Type]?: {
      averageRating: number
      reviewCount: number
      initial: {
        enabled: boolean
        averageRating?: number
        reviewCount?: number
      }
    }
  } & { totalAverageRating: number, totalCount: number }

  highlights: {
    [type in ReviewExternalAccount.Type]?: {
      fiveStarRatingCount?: number
      deltaRating?: number
    }
  } & { reviewPositiveCount?: number, reviewNegativeCount?: number, widgetViewCount?: number }
  requestOpenRate: number
  responseCount: number
}

export interface ReviewsSurveyRequest {
  id: string
  template: {
    id: string
    name: string
  }
  customer: {
    id?: string
    contact: string
    contactType: "phone" | "email"
    name?: Name
  }
  sender?: {
    appId?: string
    id: string
    ref: string
    name: Name
  }
  app?: string
  location?: string
  created?: {
    at: string
    by: {
      id: string
      ref: "customer" | "employees"
    }
    type?: ReviewsSurveyRequest.RequestType
  }
  message?: ReviewsSurveyRequest.Message
  schedule?: {
    date: string
  }
  source?: {
    appEventId: string
    eventKey: string
    data: {
      service: {
        assignees?: Array<{
          name: string
        }>
        serviceDate: string
        source: string
        text: string
        type?: string
      }
    }
    merchantWorkflowId: string
  }
  response?: ReviewsSurveyResponse
  openedAt?: string
  reminderOpenedAt?: string
}

export namespace ReviewsSurveyRequest {
  export interface Message {
    asset?: {
      id?: string
      preview?: string
      default?: string
      filename?: string
      small?: MessagesMessage.MessageMediaStyle
      medium?: MessagesMessage.MessageMediaStyle
      original?: MessagesMessage.MessageMediaStyle
    }
    channel?: string
    custom?: string
    default?: string
    ctaLabel?: {
      custom?: string
      default?: string
    }
    startComponent?: {
      customId?: string
      defaultId?: string
    }
    subject?: {
      custom?: string
      default?: string
    }
    title?: {
      custom?: string
      default?: string
    }
  }

  export enum Channel {
    EMAIL = "email",
    SMS = "sms"
  }

  export enum RequestType {
    AUTOMATED = "automated",
    BULK = "bulk",
    MANUAL = "manual",
  }
}

export interface ReviewsSurveyResponse {
  id: string
  created: {
    at: string
  }
  templateId: string
  merchantId: string
  customer?: {
    name?: Name
    email?: string
    phone?: string
  }
  answers?: ReviewsSurveyResponse.Answer[]
  reviewTriage?: {
    type: string
    comment?: string
    consentSharing?: boolean
    provider?: {
      id: string
      name: string
      type: string
    }
  }
  request?: ReviewsSurveyRequest
  completedAt: string
  queryIndex?: {
    sentiment?: ReviewsReview.Sentiment
  }
}

export namespace ReviewsSurveyResponse {
  export interface Answer {
    componentId: string
    label: string
    value: unknown
    text: string
  }
}

export interface ReviewsSurveyScheduledRequest {
  id: string
  merchantId: string
  template: {
    id: string
    name: string
  }
  customer: {
    id?: string
    contact: string
    contactType: "phone" | "email"
    name?: Name
  }
  sender?: {
    id: string
    ref: string
    name: Name
  }
  created?: {
    at: string
    by: {
      id: string
      ref: "customer" | "employees"
    }
    type?: ReviewsSurveyRequest.RequestType
  }
  message?: ReviewsSurveyRequest.Message
  schedule: {
    date: string
  }
}

export interface ReviewsSurveyTemplate {
  id: string
  merchantId?: string
  name: string
  description?: string
  slug: string
  created: {
    at: string
    by: {
      id: string
      ref: string
    }
  }
  components: ReviewsSurveyTemplate.Component[]
  design?: {
    colors?: {
      background?: string
      buttonBackground?: string
      buttonText?: string
      text?: string
      title?: string
    }
  }
  duplicatesPolicy?: {
    checker?: ReviewsSurveyTemplate.DuplicatePolicyMode
    threshold?: number
  }
  email: {
    subject: string
    title: string
    text: string
    ctaLabel?: string
    startComponentId?: string
  }
  notifications: {
    email: {
      enabled: boolean
      sendFor: ReviewsSurveyTemplate.EmailNotificationMode
    }
  }
  sms: {
    ctaText: string
    startComponentId?: string
    sendImage: boolean
    text: string
  }
  reminders?: {
    enabled: boolean
    channel: ReviewsSurveyTemplate.ReminderChannel
    sendForIncompleteResponses: boolean
    threshold: number
    sms: {
      startComponentId: string
      text: string
    }
    email: {
      startComponentId: string
      subject: string
      text: string
      title: string
    }
  }
  stats: {
    requestsCount: number
    openedCount: number
    responsesCount: number
    completedCount: number
    questions: {
      [id: string]: {
        responsesCount: number
        skippedCount: number
        values: ReviewsSurveyTemplate.ComponentValues
      }
    }
    reviewTriage?: {
      negativeCount: number
      positiveCount: number
      responsesCount: number
      onlineReviewCount?: number
      values: {
        [id: string]: number
      }
    }
  }
  meta?: {
    title: string
    description: string
    image?: {
      smallUrl: string
      mediumUrl: string
      originalUrl: string
    }
  }
  translation: {
    mappings: {
      custom: unknown
      meta: {
        description: string
      }
    }
  }
}

export namespace ReviewsSurveyTemplate {
  export enum ReminderChannel {
    EMAIL_ONLY = "email",
    SMS_ONLY = "sms",
    EMAIL_PREFERRED = "email > sms",
    SMS_PREFERRED = "sms > email",
  }

  export enum DuplicatePolicyMode {
    DAYS = "days",
    ALWAYS = "always",
    NEVER = "never",
  }

  export enum EmailNotificationMode {
    ALL = "all",
    NONE = "none",
    NEGATIVE = "negative",
  }

  export type Component = (
    ComponentIntro
    | ComponentExit
    | ComponentSentiment
    | ComponentMultiChoice
    | ComponentScale
    | ComponentShortAnswer
    | ComponentParagraph
    | ComponentYesNo
    | ComponentReviewTriage
  )

  export enum ComponentType{
    INTRO = "intro",
    EXIT = "exit",
    MULTI_CHOICE = "multi_choice",
    SENTIMENT = "sentiment",
    SHORT_ANSWER = "short_answer",
    PARAGRAPH = "paragraph",
    SCALE = "scale",
    YES_NO = "yes_no",
    REVIEW_TRIAGE = "review_triage",
  }

  export enum ComponentSentimentType{
    RATING = "rating",
    EMOTION = "emotion",
    NPS = "nps",
    YES_NO = "yes_no",
  }

  export enum ComponentExitType{
    REDIRECT = "redirect",
    STATIC = "static",
  }

  export interface ComponentBase {
    id: string
    type: ComponentType
    label: string
    description: string
    nextComponent?: {
      id: string
      reviewTriageType?: string
    }
    logic?: ComponentLogicRule[]
  }

  export interface ComponentIntro extends ComponentBase {
    type: ComponentType.INTRO
    collectContact: boolean
    nameField: string
    emailField: string
    phoneField: string
    showLogo: boolean
    imageType: string
    collectContactAnonymousOnly: boolean
  }

  export type ComponentExit = ComponentExitRedirect | ComponentExitStatic

  export interface ComponentExitBase extends ComponentBase {
    type: ComponentType.EXIT
    exitType: ComponentExitType
  }

  export interface ComponentExitRedirect extends ComponentExitBase {
    exitType: ComponentExitType.REDIRECT
    url: string
  }

  export interface ComponentExitStatic extends ComponentExitBase {
    exitType: ComponentExitType.STATIC
    imageType: string
    showLogo?: boolean
    ctaLink?: {
      label: string
      url: string
    }
  }

  export interface ComponentMultiChoice extends ComponentBase {
    type: ComponentType.MULTI_CHOICE
    allowMultipleAnswers: boolean
    includeOtherChoice: boolean
    options: ComponentMultiChoiceOption[]
  }

  export interface ComponentMultiChoiceOption {
    id: string
    label: string
    value: string
  }

  export interface ComponentShortAnswer extends ComponentBase {
    type: ComponentType.SHORT_ANSWER
    dataType: string
    required: boolean
  }

  export interface ComponentParagraph extends ComponentBase {
    type: ComponentType.PARAGRAPH
    required: boolean
  }

  export interface ComponentScale extends ComponentBase {
    type: ComponentType.SCALE
    startAt: number
    endAt: number
    minLabel: string
    middleLabel: string
    maxLabel: string
    required: boolean
  }

  export interface ComponentYesNo extends ComponentBase {
    type: ComponentType.YES_NO
    yesLabel?: string
    noLabel?: string
    yesPrimary: boolean
  }

  export type ComponentSentiment = ComponentSentimentEmotion | ComponentSentimentNPS | ComponentSentimentRating | ComponentSentimentYesNo

  export interface ComponentSentimentBase extends ComponentBase {
    type: ComponentType.SENTIMENT
    sentimentType: ComponentSentimentType
  }

  export interface ComponentSentimentRating extends ComponentSentimentBase {
    sentimentType: ComponentSentimentType.RATING
  }

  export interface ComponentSentimentEmotion extends ComponentSentimentBase {
    sentimentType: ComponentSentimentType.EMOTION
  }

  export interface ComponentSentimentNPS extends ComponentSentimentBase {
    sentimentType: ComponentSentimentType.NPS
    productName?: string
  }

  export interface ComponentSentimentYesNo extends ComponentSentimentBase {
    sentimentType: ComponentSentimentType.YES_NO
    yesLabel?: string
    noLabel?: string
    yesPrimary: boolean
  }

  export interface ComponentReviewTriage extends ComponentBase {
    type: ComponentType.REVIEW_TRIAGE
    onlineReview: {
      enabled: boolean
      providerIds: string[]
    }
    positive: {
      label: string
      description: string
      askConsentSharing: boolean
      nextComponentId: string
    }
    negative: {
      label: string
      description: string
      nextComponentId: string
    }
  }

  export interface ComponentLogicRule {
    id?: string
    operator: string
    conditions: ComponentLogicRuleCondition[]
    action: {
      type: string
      componentId?: string
    }
  }

  export interface ComponentLogicRuleCondition {
    input: {
      type: string
      id?: string
    }
    condition: string
    value: unknown
  }

  export type ComponentValues = ComponentValuesNPS | ComponentValuesMultiChoice | ComponentValuesScale | ComponentValuesText | ComponentValuesYesNo

  export type ComponentValuesMultiChoice = {
    [optionId: string]: number
  } & { other?: number }

  export interface ComponentValuesNPS {
    [value: string]: number
  }

  export interface ComponentValuesScale {
    [value: string]: number
  }

  export type ComponentValuesText = Array<{
    date: string
    responseId: string
    text: string
  }>

  export interface ComponentValuesYesNo {
    no: number
    yes: number
  }
}

interface IntegrationMerchantCredentialBase {
  credentials?: {
    username: string
    password: string
  }
  id: string
  isValid: boolean | null
  log: IntegrationMerchantCredential.LogEntry[]
  merchantIds: string[]
  rootMerchantId: string
  status: IntegrationMerchantCredential.Status
  salesforce?: {
    verifyTaskId?: string
  }
  url: string
}

export interface IntegrationMerchantCredentialDashboard extends IntegrationMerchantCredentialBase {
  type: {
    label: string
    description: string
    iconSrc?: string
    value: string
  }
}

export interface IntegrationMerchantCredential extends IntegrationMerchantCredentialBase {
  type: string
}

export namespace IntegrationMerchantCredential {
  export enum LogEntryAction {
    CREATED = "created",
    PASSWORD_READ = "password_read",
    SET_VALID = "set_valid",
    UPDATED = "updated",
  }

  interface LogEntryBase {
    at: string
    by?: {
      ref: string
      id: string
    }
    details: Record<string, unknown>
    id: string
  }

  export interface LogEntryCreated extends LogEntryBase {
    action: LogEntryAction.CREATED
  }

  export interface LogEntrySetValid extends LogEntryBase {
    action: LogEntryAction.SET_VALID
  }

  export type LogEntry = LogEntryCreated
                       | LogEntrySetValid

  export enum Status {
    INVALID = "invalid",
    PENDING = "pending",
    REQUEST = "request",
    VALID = "valid",
  }
}

import React, { useMemo } from "react"
import styled from "styled-components"

import { MetricDecreaseIcon, MetricIncreaseIcon, MetricStaleIcon, styleHelpers } from "@onelocal/frontend-web/common"

export enum PerformanceBadgeTheme {
  DECREASE = "decrease",
  INCREASE = "increase",
  STALE = "stale",
}

export interface PerformanceBadgeProps {
  theme: PerformanceBadgeTheme
  title: string
}

const StyledPerformanceBadgeContainer = styled.div`
  align-items: center;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  height: 18px;
  justify-content: center;
  padding: 5px 8px;
`

const StyledPerformanceLabel = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: ${ styleHelpers.fonts.weight.bold };
`

export const PerformanceBadge: React.FC<PerformanceBadgeProps> = ( {
  theme,
  title,
} ) => {
  const { icon, textStyle, viewStyle } = useMemo( () => {
    switch( theme ) {
      case PerformanceBadgeTheme.DECREASE:
        return {
          icon: <MetricDecreaseIcon />,
          textStyle: { color: "#E31B0C" },
          viewStyle: { backgroundColor: "#FFF0EF" },
        }
      case PerformanceBadgeTheme.INCREASE:
        return {
          icon: <MetricIncreaseIcon />,
          textStyle: { color: "#00A86B" },
          viewStyle: { backgroundColor: "#DEFFE1" },
        }
      case PerformanceBadgeTheme.STALE:
        return {
          icon: <MetricStaleIcon />,
          textStyle: { color: "#757575" },
          viewStyle: { backgroundColor: "#E0E0E0" },
        }
    }
  }, [ theme ] )

  return (
    <StyledPerformanceBadgeContainer style={ viewStyle }>
      <StyledPerformanceLabel style={ textStyle }>
        { title }
      </StyledPerformanceLabel>
      { icon }
    </StyledPerformanceBadgeContainer>
  )
}

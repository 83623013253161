import React, { createContext, useContext } from "react"

export interface AdminContextType {
  applicationType: AdminApplicationType
}

export enum AdminApplicationType {
  LIB = "lib",
  WEB = "web",
}

const AdminContext = createContext<AdminContextType>( null! )

export const AdminContextProvider: React.FunctionComponent<React.PropsWithChildren<{ value: AdminContextType }>> = ( { children, value } ) => {
  return <AdminContext.Provider value={ value }>{ children }</AdminContext.Provider>
}

export function useAdminContext(): AdminContextType {
  return useContext( AdminContext )
}

import { useCallback, useMemo, useState } from "react"
import { Controller } from "react-hook-form"
import styled from "styled-components"

import { useForm } from "@onelocal/frontend/common"
import { SocialPost } from "@onelocal/frontend-admin-web/content"
import { useCurrentSession } from "@onelocal/frontend-dashboard/auth"
import type { ContentSocialPostSuggestionReview } from "@onelocal/frontend-dashboard/content"
import { contentActions } from "@onelocal/frontend-dashboard/content"
import type { MainActivity } from "@onelocal/frontend-dashboard/main"
import { reviewHelpers } from "@onelocal/frontend-dashboard/reviews"
import { Button, Flex, InfoIcon, StarRating, styleHelpers, TextField, useConfirmationModal } from "@onelocal/frontend-web/common"
import { dateHelpers } from "@onelocal/shared/common"
import { useRootDispatch } from "../../../store"
import { ActivityCardContainer } from "../../ActivityContent/components/ActivityCardContainer"
import { ActivityCardContentWrapper } from "../../ActivityContent/components/ActivityCardContentWrapper"
import { ActivityContentFooter } from "../../ActivityContent/components/ActivityContentAction"
import { ActivityContentHeader } from "../../ActivityContent/components/ActivityContentHeader"
import { useDismissPostModal } from "../../ActivityContent/components/DismissPostModal"
import { usePublishPostModal } from "../../ActivityContent/components/PublishPostModal"
import { ShareSettings } from "../../ActivityContent/components/ShareSettings"
import type { ContentSocialPreviewProps } from "./ContentPreview"

enum FormFieldKeys {
  MESSAGE = "message",
}

type FormValues = {
  [ FormFieldKeys.MESSAGE ]: string
}

interface ShareReviewCardContentProps extends ContentSocialPreviewProps {
  activityType?: MainActivity.Type
  socialPostSuggestion: ContentSocialPostSuggestionReview
  title?: string | React.ReactNode
}

const ShareReviewCardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`

const ReviewCardContentImageContainer = styled.div`
  margin-right: 16px;
`

const ReviewComment = styled.div`
  color: ${ styleHelpers.colors.black };
  font-size: 14px;
  font-weight: 400;
`

const ReviewDate = styled.div`
  color: #9B9B9B;
  font-size: 12px;
  font-weight: ${ styleHelpers.fonts.weight.thin };
  margin-left: 10px;
`

const ReviewEditWarningContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
  padding: 0px 16px 16px 16px;
`

const ReviewEditWarningText = styled.span`
  color: ${ styleHelpers.colors.midGray };
  font-size: ${ styleHelpers.fonts.sizes.medium };
  line-height: 20px;
`

const ReviewRatingDateContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
`
const ReviewCardContentDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ReviewTitle = styled.div`
  color: ${ styleHelpers.colors.black };
  font-size: ${ styleHelpers.fonts.sizes.medium };
  font-weight: ${ styleHelpers.fonts.weight.semiBold };
  margin-bottom: 8px;
`

const RecentReviewTitle = styled.h6`
  color: ${ styleHelpers.colors.dark };
  font-size: ${ styleHelpers.fonts.sizes.large };
  font-weight: ${ styleHelpers.fonts.weight.semiBold };
  line-height: 25px;
  margin: 12px 0 16px;
`

const PreviewPostWrapper = styled.div`
  & h6 {
    color: ${ styleHelpers.colors.dark };
    font-size: ${ styleHelpers.fonts.sizes.large };
    font-weight: ${ styleHelpers.fonts.weight.semiBold };
    line-height: 125%;
    margin: 16px 0;
  }

  & img {
    width: 100%;
    margin-bottom: 16px;
  }
`

const EditIcon = ( props: React.SVGProps<SVGSVGElement> ) => {
  return (
    <svg style={ props.style } xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M21.3113 6.87845L17.1216 2.68971C16.9823 2.55038 16.8169 2.43986 16.6349 2.36446C16.4529 2.28905 16.2578 2.25024 16.0608 2.25024C15.8638 2.25024 15.6687 2.28905 15.4867 2.36446C15.3047 2.43986 15.1393 2.55038 15 2.68971L3.4397 14.25C3.2998 14.3888 3.18889 14.554 3.11341 14.736C3.03792 14.9181 2.99938 15.1133 3.00001 15.3103V19.5C3.00001 19.8978 3.15804 20.2794 3.43935 20.5607C3.72065 20.842 4.10218 21 4.50001 21H20.25C20.4489 21 20.6397 20.921 20.7803 20.7803C20.921 20.6397 21 20.4489 21 20.25C21 20.0511 20.921 19.8603 20.7803 19.7197C20.6397 19.579 20.4489 19.5 20.25 19.5H10.8113L21.3113 9.00002C21.4506 8.86072 21.5611 8.69535 21.6365 8.51334C21.7119 8.33133 21.7507 8.13625 21.7507 7.93924C21.7507 7.74222 21.7119 7.54714 21.6365 7.36513C21.5611 7.18312 21.4506 7.01775 21.3113 6.87845ZM8.6897 19.5H4.50001V15.3103L12.75 7.06033L16.9397 11.25L8.6897 19.5ZM18 10.1897L13.8113 6.00002L16.0613 3.75002L20.25 7.9397L18 10.1897Z" fill="#F15F4B"/>
    </svg>
  )
}

export const ShareReviewCardContent: React.FC<ShareReviewCardContentProps> = ( {
  activityType,
  activityId,
  activityStatus,
  isPosted,
  onClose,
  onRestore,
  refresh,
  scheduleAt,
  socialPostSuggestion,
  title,
} ) => {
  const dispatch = useRootDispatch()
  const session = useCurrentSession()
  const { showConfirmationModal } = useConfirmationModal()

  const [ isUpdateDescriptionActive, setIsUpdateDescriptionActive ] = useState( false )

  const { showPublishPostModel } = usePublishPostModal( socialPostSuggestion.id, refresh )
  const { showDismissPostModel } = useDismissPostModal( socialPostSuggestion.id, refresh )

  const formMethods = useForm<FormValues>( {
    defaultValues: {
      [ FormFieldKeys.MESSAGE ]: "",
    },
  } )

  const { control, formState, watch, handleSubmit } = formMethods
  const message = watch( FormFieldKeys.MESSAGE )

  const isSubmitButtonDisabled = useMemo( () => {
    if( ! socialPostSuggestion ) {
      return true
    }

    if( isUpdateDescriptionActive ) {
      return ! message || message === socialPostSuggestion?.text
    }

    return Object
      .entries( socialPostSuggestion?.platform )
      .filter( ( [ platform ] ) => platform !== SocialPost.PlatformType.GOOGLE )
      .every( ( [ , setting ] ) => ! setting.enabled )
  }, [ socialPostSuggestion, isUpdateDescriptionActive, message ] )

  const suggestionImageUrl = useMemo( () => {
    if( ! socialPostSuggestion?.image.ids.length ) {
      return ""
    }

    return socialPostSuggestion?.image.suggestions[ 0 ].url.small
  }, [ socialPostSuggestion ] )

  const onSubmitDescription = useCallback( async ( data: FormValues ) => {
    await dispatch( contentActions.socialPostSuggestion?.updateSuggestion(
      socialPostSuggestion?.merchantId || "",
      socialPostSuggestion?.id || "",
      { text: data.message },
    ) )
    setIsUpdateDescriptionActive( false )
  }, [ socialPostSuggestion?.id, socialPostSuggestion?.merchantId, dispatch ] )

  const onCloseHandler = useCallback( async () => {
    if( onClose ) {
      onClose()
    }
  }, [ onClose ] )

  const onDismiss = useCallback( () => {
    if( isUpdateDescriptionActive ) {
      if( message !== socialPostSuggestion?.text ) {
        showConfirmationModal( {
          cancelText: "Stay",
          confirmText: "Leave",
          message: "Your changes are not saved. Please finish editing before leaving.",
          onConfirm: () => {
            setIsUpdateDescriptionActive( false )
          },
          title: "Are you sure you want to leave?",
        } )
        return
      }
      setIsUpdateDescriptionActive( false )
      return
    }

    showDismissPostModel( {} )
  }, [ isUpdateDescriptionActive, message, showConfirmationModal, showDismissPostModel, socialPostSuggestion?.text ] )

  const extraMenuItems = useMemo( () => {
    return ! isPosted
      ? [
        {
          icon: <EditIcon style={ { fontSize: 24, marginRight: "12px", color: styleHelpers.colors.primary } }/>,
          title: "Edit Post",
          onClick: () => {
            formMethods.setValue( FormFieldKeys.MESSAGE, socialPostSuggestion?.text || "" )
            setIsUpdateDescriptionActive( true )
          },
        },
      ]
      : undefined
  }, [ formMethods, isPosted, socialPostSuggestion ] )

  if( ! socialPostSuggestion ) {
    return null
  }

  return (
    <ActivityCardContainer>
      <ActivityContentHeader
        activityType={ activityType! }
        activityStatus={ activityStatus! }
        activityId={ activityId! }
        isEditing={ isUpdateDescriptionActive }
        title={ title ?? "Promote Your Review" }
        onCloseHandler={ onCloseHandler }
        onRestore={ isUpdateDescriptionActive ? onRestore : undefined }
        extraMenuItems={ extraMenuItems }
      />
      <ActivityCardContentWrapper id="content-wrapper">
        <ShareReviewCardContentContainer>
          <RecentReviewTitle>Your Recent Review</RecentReviewTitle>
          <Flex display="flex" flexDirection="row" style={ { padding: "10px" } }>
            <ReviewCardContentImageContainer>
              { reviewHelpers.getIconForReviewSource( socialPostSuggestion.review?.source, { height: 30, style: { alignSelf: "center" }, width: 30 } ) }
            </ReviewCardContentImageContainer>
            <ReviewCardContentDetailsContainer>
              <ReviewTitle>{ socialPostSuggestion.review.name }</ReviewTitle>
              <ReviewRatingDateContainer>
                { socialPostSuggestion.review.rating != null && <StarRating rating={ ( socialPostSuggestion.review.rating / 25 ) + 1 } size={ 15 }/> }
                <ReviewDate>{ dateHelpers.formatToMediumDate( socialPostSuggestion.review.date ) }</ReviewDate>
              </ReviewRatingDateContainer>
              <ReviewComment>{ socialPostSuggestion.review.text }</ReviewComment>
            </ReviewCardContentDetailsContainer>
          </Flex>
          <PreviewPostWrapper>
            <h6>Preview Post</h6>
            <img src={ suggestionImageUrl } alt="Social Post Suggestion" />
            {
              isUpdateDescriptionActive
                ? (
                  <>
                    <ReviewEditWarningContainer>
                      <InfoIcon color={ styleHelpers.colors.midGray }/>
                      <ReviewEditWarningText>
                        Please note that you can edit only the written content of the generated post. The images provided in the suggested content cannot be modified.
                      </ReviewEditWarningText>
                    </ReviewEditWarningContainer>
                    <Controller
                      control={ control }
                      name={ FormFieldKeys.MESSAGE }
                      render={ ( { field: { onChange, value } } ) => (
                        <TextField
                          fullWidth
                          label="Content"
                          minRows={ 5 }
                          multiline
                          placeholder="Type here..."
                          value={ value || "" }
                          onChange={ onChange }
                          errorMessage={ formState.errors?.[ FormFieldKeys.MESSAGE ]?.message }
                        />
                      ) }
                    />
                  </>
                )
                : <p>{ socialPostSuggestion?.text }</p>
            }
          </PreviewPostWrapper>
          { ! session?.isGataEmployee && ! isUpdateDescriptionActive && ( <ShareSettings disabled={ isPosted } socialPostSuggestion={ socialPostSuggestion }/> ) }
        </ShareReviewCardContentContainer>
      </ActivityCardContentWrapper>
      {
        ! session?.isGataEmployee && ! isPosted && (
          <ActivityContentFooter>
            <Button style={ { color: styleHelpers.colors.dark, height: "44px" } } fullWidth onClick={ onDismiss } variant="text">
              { isUpdateDescriptionActive ? "Cancel" : "Dismiss" }
            </Button>
            <Button
              color="primary"
              disabled={ isSubmitButtonDisabled }
              fullWidth
              onClick={ isUpdateDescriptionActive ? handleSubmit( onSubmitDescription ) : showPublishPostModel }
              style={ { height: "44px" } }
            >
              {
                isUpdateDescriptionActive
                  ? "Done"
                  : scheduleAt
                    ? "Reschedule"
                    : "Publish"
              }
            </Button>
          </ActivityContentFooter>
        )
      }
    </ActivityCardContainer>
  )
}


export const styleHelpers = {
  colors: {
    active: "#3b99fc",
    black: "#000000",
    blue: "#2196F3",
    borderGray: "#EEEEEE",
    danger: "#da0028",
    dark: "#333333",
    darkBlue: "#43566B",
    darkerBlue: "#334252",
    darkerBlueGray: "#465362",
    darkerGray: "#464646",
    darkGray: "#777777",
    darkSecondary: "#64778C",
    default: "#bababa",
    error: "#F44336",
    errorDark: "#621B16",
    errorLight: "#FDECEA",
    gray: "#F7F8F8",
    gray85: "#D9D9D9",
    grayishWhite: "#F5F5F5",
    grayLight: "#ccc",
    green: "#4caf50",
    inactive: "#BDBDBD",
    info: "#2196F3",
    infoDark: "#0D3C61",
    infoLight: "#EAF4FC",
    infoSolid: "#2B78CA",
    lightBlue: "#EAF4FC",
    lightDarkGray: "#757575",
    lighterDarkGray: "#BBBBBB",
    lighterGray: "#F5F5F5",
    lightGray: "#E5E5E5",
    midGray: "#949494",
    orangeLight: "#FDEAE8",
    primary: "#f15f4b",
    primaryLight: "#F87A53",
    primaryLighter: "#FAA489",
    red: "#ee241f",
    secondary: "#2e4c6b",
    secondaryDisable: "#D1D7DD",
    secondaryOrange: "#FFF1F0",
    success: "#4CAF50",
    successDark: "#1E4620",
    successLight: "#EFF7EE",
    tertiary: "#27b99b",
    warning: "#FF9800",
    warningDark: "#663D00",
    warningLight: "#FDF4E7",
    white: "#FFFFFF",
    whiteSmoke: "#F0F0F0",
  },
  fonts: {
    default: [
      "Gotham Rounded A",
      "Gotham Rounded B",
      "sans-serif",
    ].join( "," ),
    sizes: {
      default: "14px",
      large: "20px",
      mediumLarge: "18px",
      medium: "16px",
      small: "12px",
      veryLarge: "24px",
      verySmall: "11px",
      tiny: "10px",
    },
    weight: {
      thinner: 325,
      thin: 350,
      default: 400,
      semiBold: 500,
      bold: 600,
    },
  },
}

import { apiHelper } from "@onelocal/frontend/common"
import type { DudaTemplate } from "../types"

export namespace DudaTemplateService {}

export const dudaTemplateService = {
  async query() {
    return apiHelper.request( {
      method: "GET",
      parsingData: ( apiDudaTemplates: DudaTemplate[] ) => {
        return apiDudaTemplates
      },
      url: "/local-site/duda-templates",
    } )
  },
}

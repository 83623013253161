import {
  add,
  differenceInDays,
  differenceInMinutes,
  differenceInSeconds,
  format,
  formatDistance,
  getDaysInMonth,
  isAfter,
  isBefore,
  isSameDay,
  isSameYear,
  isThisWeek,
  isToday,
  isValid,
  isWeekend,
  isYesterday,
  parse,
  parseISO,
  sub,
} from "date-fns"
import { format as formatTZ, utcToZonedTime } from "date-fns-tz"
import { isDate, isString } from "lodash"

import { formattingHelpers } from "./formattingHelpers"

const parseDate = ( date: string | Date ) => {
  if( isDate( date ) ) {
    return date
  } else if( isString( date ) ) {
    return parseISO( date )
  }

  return NaN
}

const formatDate = ( date: string | Date, dateFormat: string ) => {
  const parsedDate = parseDate( date )

  if( ! parsedDate || ! isValid( parsedDate ) ) {
    return null
  }

  return format( parsedDate, dateFormat )
}

const TIME_VALUE_REGEX = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/

export const dateHelpers = {
  LONG_DATE_FORMAT: "EEEE, MMMM d, yyyy",
  TIME_12HR_FORMAT: "h:mm a",
  TIME_24HR_FORMAT: "HH:mm",
  add( date: string | Date, values: { days?: number } ) {
    return add( parseDate( date ), values )
  },
  convertSecondsToMinutes( value: number ) {
    if( ! value ) {
      return value
    }

    value = formattingHelpers.roundDecimal( value )

    if( ! isFinite( value ) ) {
      return value
    }

    return formattingHelpers.roundDecimal( Math.abs( value ) / 60 )
  },
  differenceInDays( date: string | Date, compareDate: string | Date ) {
    if( ! dateHelpers.isValidDate( date ) || ! dateHelpers.isValidDate( compareDate ) ) {
      return NaN
    }
    return differenceInDays( parseDate( date ), parseDate( compareDate ) )
  },
  differenceInMinutes( date: string | Date, compareDate: string | Date ) {
    if( ! dateHelpers.isValidDate( date ) || ! dateHelpers.isValidDate( compareDate ) ) {
      return NaN
    }
    return differenceInMinutes( parseDate( date ), parseDate( compareDate ) )
  },
  formatDateAs24HrFormatTimeString( date: string | Date ) {
    return format( parseDate( date ), dateHelpers.TIME_24HR_FORMAT )
  },
  formatDistance( date: string | Date, referenceDate?: string | Date ) {
    const parsedDate = parseDate( date )
    if( ! parsedDate || ! isValid( parsedDate ) ) {
      return null
    }

    const parsedReferenceDate = referenceDate ? parseDate( referenceDate ) : new Date()

    if( ! parsedReferenceDate || ! isValid( parsedReferenceDate ) ) {
      return null
    }

    return formatDistance( parsedDate, parsedReferenceDate )
  },
  formatToDateMonth( date: string | Date ) {
    return formatDate( date, "yyyy-MM" )
  },
  formatToHumanDate( date: string | Date ) {
    const parsedDate = parseDate( date )
    if( ! isDate( parsedDate ) && isNaN( parsedDate ) ) {
      return null
    }

    return isToday( parsedDate )
      ? formatDate( date, "h:mm a" )?.toLowerCase()
      : isYesterday( parsedDate )
        ? "Yesterday"
        : isThisWeek( parsedDate )
          ? this.formatToWeekDay( date )
          : this.formatToShortDate( date )
  },
  formatDurationSeconds( seconds: number ) {
    if( seconds < 60 ) {
      return `${ seconds }s`
    } else if( seconds < 3600 ) {
      const remainingSeconds = seconds % 60
      const minutes = Math.floor( seconds / 60 )

      if( remainingSeconds ) {
        return `${ minutes }m${ remainingSeconds }s`
      } else {
        return `${ minutes }m`
      }
    } else {
      const remainingSeconds = seconds % 3600
      const remainingMinutes = remainingSeconds % 60
      const minutes = Math.floor( remainingSeconds / 60 )
      const hours = Math.floor( seconds / 3600 )

      if( remainingMinutes ) {
        return `${ hours }h${ minutes }m`
      } else {
        return `${ hours }h`
      }
    }
  },
  formatDurationSecondsShort( seconds: number ) {
    if( seconds < 3600 ) {
      const remainingSeconds = seconds % 60
      const minutes = Math.floor( seconds / 60 )

      return `${ minutes <= 9 ? "0" : "" }${ minutes }:${ remainingSeconds <= 9 ? "0" : "" }${ remainingSeconds }`
    } else {
      const hours = Math.floor( seconds / 3600 )
      const remainingMinutes = Math.floor( ( seconds % 3600 ) / 60 )
      const remainingSeconds = ( seconds % 3600 ) % 60

      return `${ hours <= 9 ? "0" : "" }${ hours }:${ remainingMinutes <= 9 ? "0" : "" }${ remainingMinutes }:${ remainingSeconds <= 9 ? "0" : "" }${ remainingSeconds }`
    }
  },
  formatTimeAs12HrFormatTimeString( timeString: string ) {
    const referenceDate = new Date()
    const parsedDate = parse( timeString, dateHelpers.TIME_24HR_FORMAT, referenceDate )
    return format( parsedDate, dateHelpers.TIME_12HR_FORMAT )
  },
  formatTimeToTimeValue( time: string ) {
    const hoursMinutes = time.split( ":" )
    if( hoursMinutes.length !== 2 ) {
      return null
    }

    let formattedTime = ""
    const hours = parseInt( hoursMinutes[ 0 ], 10 )
    if( isNaN( hours ) ) {
      return null
    }
    if( hours > 12 ) {
      formattedTime = `${ hours - 12 }:${ hoursMinutes[ 1 ] }pm`
    } else if( hours < 12 ) {
      formattedTime = `${ time }am`
    } else {
      formattedTime = `${ time }pm`
    }

    return formattedTime
  },
  formatToDateTime( date: string | Date ) {
    return formatDate( date, "PP - p" )
  },
  formatToDateValue( date: string | Date ) {
    return formatDate( date, "yyyy-MM-dd" )
  },
  formatToDay( date: string | Date ) {
    return formatDate( date, "cccc" )
  },
  formatToMediumDate( date: string | Date ) {
    return formatDate( date, "PP" )
  },
  formatToMonth( date?: string | Date ) {
    return formatDate( date || new Date(), "MMMM" )
  },
  formatToShortDate( date: string | Date ) {
    return formatDate( date, "MMM d" )
  },
  formatToShortDateTime( date: string | Date ) {
    return formatDate( date, "MMM d, h:mma" )
  },
  formatToShortMonth( date?: string | Date ) {
    return formatDate( date || new Date(), "MMM" )
  },
  formatToMonthDayFullYearDate( date: string | Date ) {
    return formatDate( date, "MMM d, yyyy" )
  },
  formatToTime( date: string | Date ) {
    return formatDate( date, "h:mma" )
  },
  formatToTimeValue( date: string | Date ) {
    return formatDate( date, "HH:mm" )
  },
  formatToUtcMonth( date: string ) {
    return dateHelpers.formatTZ( date, "MMM yyyy", "UTC" )
  },
  formatToUtcMonthShort( date: string ) {
    return dateHelpers.formatTZ( date, "MMM yy", "UTC" )
  },
  formatToWeekDay( date: string | Date ) {
    return formatDate( date, "EEEE" )
  },
  formatToWeekShortDay( date: string | Date ) {
    return formatDate( date, "EEE" )
  },
  formatTZ( date: string | Date, dateFormat: string, timezone: string ) {
    const parsedDate = parseDate( date )
    if( ! isDate( parsedDate ) && isNaN( parsedDate ) ) {
      return null
    }

    const zonedDate = utcToZonedTime( parseDate( date ), timezone )
    return formatTZ( zonedDate, dateFormat, { timeZone: timezone } )
  },
  getDateFromTimeValue( value: string ) {
    if( value == null ) {
      return null
    }

    if( ! TIME_VALUE_REGEX.test( value ) ) {
      return null
    }

    const hours = parseInt( value.substring( 0, 2 ), 10 )
    const minutes = parseInt( value.substring( 3 ), 10 )
    // Can't use 0 for the year, month, day otherwise it create issue in the date picker component
    return new Date( 1970, 0, 1, hours, minutes, 0, 0 )
  },
  getDayFromMs( value: number ) {
    return value / ( 24 * 60 * 60 * 1000 )
  },
  getDaySince( date: string | Date, referenceDate?: string | Date ) {
    const parsedDate = parseDate( date )
    if( ! parsedDate || ! isValid( parsedDate ) ) {
      return null
    }

    const parsedReferenceDate = referenceDate ? parseDate( referenceDate ) : new Date()

    if( ! parsedReferenceDate || ! isValid( parsedReferenceDate ) ) {
      return null
    }

    return differenceInDays( parsedReferenceDate, parseDate( date ) )
  },
  getMsFromDays( value: number ) {
    return value * ( 24 * 60 * 60 * 1000 )
  },
  /**
  * Gets the number of days in the specified month.
  * @param {string} month - The month in the format 'yyyy-MM'.
  * @returns {number} The number of days in the specified month.
  */
  getNumberOfDaysForMonth( month: string ) {
    return getDaysInMonth( parseDate( month ) )
  },
  getSecondsSince( date: string | Date, referenceDate?: string | Date ) {
    const parsedDate = parseDate( date )
    if( ! parsedDate || ! isValid( parsedDate ) ) {
      return null
    }

    const parsedReferenceDate = referenceDate ? parseDate( referenceDate ) : new Date()

    if( ! parsedReferenceDate || ! isValid( parsedReferenceDate ) ) {
      return null
    }

    return differenceInSeconds( parsedReferenceDate, parseDate( date ) )
  },
  isAfter( date: string | Date, compareDate: string | Date ) {
    return isAfter( parseDate( date ), parseDate( compareDate ) )
  },
  isBefore( date: string | Date, compareDate: string | Date ) {
    return isBefore( parseDate( date ), parseDate( compareDate ) )
  },
  isSameDay( date: string | Date, compareDate: string | Date ) {
    return isSameDay( parseDate( date ), parseDate( compareDate ) )
  },
  isSameYear( date: string | Date, compareDate: string | Date ) {
    return isSameYear( parseDate( date ), parseDate( compareDate ) )
  },
  isValidDate( date: string | Date ) {
    return ! isNaN( ( new Date( date ) ).getTime() )
  },
  isWeekend( date: string | Date ) {
    return isWeekend( parseDate( date ) )
  },
  mergeDateAndTime( date: string, time: string ): Date {
    const formatedDate = formatDate( date, "yyyy-MM-dd" )
    const mergedDateAndTime = parse(
      `${ formatedDate?.toString() } ${ time }`,
      "yyyy-MM-dd HH:mm",
      new Date(),
    )

    return mergedDateAndTime
  },
  parseDayDate( input: string ) {
    input = input.replace( /-/g, "" )
    const referenceDate = new Date()
    referenceDate.setHours( 0 )
    referenceDate.setMinutes( 0 )
    referenceDate.setSeconds( 0 )
    referenceDate.setMilliseconds( 0 )
    return parse( input, "yyyyMMdd", referenceDate )
  },
  sub( date: string | Date, values: { days?: number } ) {
    return sub( parseDate( date ), values )
  },
}

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
// eslint-disable-next-line no-restricted-imports
import { Menu, MenuItem } from "@mui/material"
import React, { useCallback, useMemo, useState } from "react"
import { matchPath, useLocation } from "react-router-dom"
import styled from "styled-components"

import { useAsyncDispatch } from "@onelocal/frontend/common"
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthInternalAccount, useCurrentInternalAccount } from "@onelocal/frontend-admin-web/auth"
import { AdminLink, AdminRoutePath, commonActions, OldAdminRoutePath, useCurrentSession } from "@onelocal/frontend-admin-web/common"
import { Link, styleHelpers } from "@onelocal/frontend-web/common"
import { formattingHelpers } from "@onelocal/shared/common"
import { useMainSettings } from "../hooks/useMainSettings"

const Container = styled.div`
  background-color: ${ styleHelpers.colors.darkBlue };
  border-right: 1px solid ${ styleHelpers.colors.grayLight };
  color: white;
  display: flex;
  flex-direction: column;
  flex: 0 1 200px;
  height: 100vh;
  z-index: 2;
`

const SidebarFooter = styled.footer`
  background-color: ${ styleHelpers.colors.darkerBlue };
  padding: 12px 0;
`

/* eslint-disable-next-line */
export interface SideMenuProps {}

export interface PageItemProps {
  label: string
  url: string
}

export interface PageProps extends PageItemProps {
  subPages: PageItemProps[]
}

export const SideMenu: React.FC<SideMenuProps> = ( props ) => {
  const { currentSession } = useCurrentSession()
  const { currentInternalAccount } = useCurrentInternalAccount()
  const mainSettings = useMainSettings()
  const dispatch = useAsyncDispatch()
  const location = useLocation()
  const [ profileAnchorElement, setProfileAnchorElement ] = useState<Element | null>( null )
  const profileMenuOpen = Boolean( profileAnchorElement )

  const pages = useMemo( () => {
    const currentPages = [
      {
        label: "Tasks",
        url: AdminRoutePath.TASKS,
      },
      {
        label: "Merchants",
        url: OldAdminRoutePath.MERCHANTS,
      },
      (
        [
          AuthInternalAccount.Role.ADMIN,
          AuthInternalAccount.Role.CUSTOMER_SUCCESS,
          AuthInternalAccount.Role.DELIVERY,
          AuthInternalAccount.Role.TECH,
        ].includes( currentInternalAccount.role )
          ? {
            label: "Agency",
            url: OldAdminRoutePath.AGENCY,
          }
          : null
      ),
      (
        [ AuthInternalAccount.Role.ADMIN, AuthInternalAccount.Role.CUSTOMER_SUCCESS, AuthInternalAccount.Role.SALES ].includes( currentInternalAccount.role )
          ? {
            label: "Opportunities",
            url: AdminRoutePath.OPPORTUNITIES,
          }
          : null
      ),
      (
        [
          AuthInternalAccount.Role.ADMIN,
          AuthInternalAccount.Role.CUSTOMER_SUCCESS,
          AuthInternalAccount.Role.DELIVERY,
          AuthInternalAccount.Role.TECH,
        ].includes( currentInternalAccount.role )
          ? {
            label: "Analytics",
            url: OldAdminRoutePath.ANALYTICS,
          }
          : null
      ),
      (
        currentInternalAccount.permissions.includes( AuthInternalAccount.Permission.QA )
          ? {
            label: "Testing",
            url: OldAdminRoutePath.TESTING,
          }
          : null
      ),
      {
        label: "Settings",
        url: OldAdminRoutePath.SETTINGS,
      },
      (
        currentInternalAccount.role === AuthInternalAccount.Role.ADMIN || currentInternalAccount.permissions.includes( AuthInternalAccount.Permission.USER_MANAGEMENT )
          ? {
            label: "Users",
            url: AdminRoutePath.ACCOUNTS,
          }
          : null
      ),
      (
        [
          AuthInternalAccount.Role.ADMIN,
          AuthInternalAccount.Role.TECH,
        ].includes( currentInternalAccount.role )
          ? {
            label: "A2P",
            url: AdminRoutePath.A2P,
          }
          : null
      ),
      (
        [
          AuthInternalAccount.Role.ADMIN,
          AuthInternalAccount.Role.TECH,
        ].includes( currentInternalAccount.role )
          ? {
            label: "Phone Numbers",
            url: AdminRoutePath.MERCHANTS_PHONE_NUMBERS,
          }
          : null
      ),
      (
        [
          AuthInternalAccount.Role.ADMIN,
          AuthInternalAccount.Role.DELIVERY,
        ].includes( currentInternalAccount.role )
          ? {
            label: "Site Templates",
            url: AdminRoutePath.SITE_TEMPLATES,
          }
          : null
      ),
      (
        [
          AuthInternalAccount.Role.ADMIN,
          AuthInternalAccount.Role.DELIVERY,
        ].includes( currentInternalAccount.role )
          && ! mainSettings?.disableBlogSocial
          ? {
            label: "Delivery",
            url: AdminRoutePath.DELIVERY_AI_REVIEW,
            subPages: [
              {
                label: "AI Review",
                url: AdminRoutePath.DELIVERY_AI_REVIEW,
              },
            ],
          }
          : null
      ),

    ].filter( ( page ) => page != null ) as PageProps[]

    return currentPages
  }, [ currentInternalAccount.permissions, currentInternalAccount.role, mainSettings ] )

  const profileLabel = useMemo( () => {
    if( ! currentSession ) {
      return
    }

    return formattingHelpers.getDisplayName( currentSession ) || currentSession.email || currentSession.username
  }, [ currentSession ] )

  const logout = useCallback( async () => {
    await dispatch( commonActions.session.removeSession() )
    window.location.href = `${ OldAdminRoutePath.SIGNIN }?redirect=${ encodeURIComponent( window.location.pathname ) }`
  }, [ dispatch ] )

  const handleProfileMenuOpen = useCallback( ( event: React.MouseEvent<HTMLElement> ) => {
    setProfileAnchorElement( event.currentTarget )
  }, [] )

  const handleProfileMenuClose = useCallback( () => {
    setProfileAnchorElement( null )
  }, [] )

  return (
    <Container>
      <nav style={ { flex: 1, overflow: "auto" } }>
        {
          pages.map( ( page ) => {
            const isActive = (
              matchPath( { path: page.url, end: false }, location.pathname ) != null
              || page.subPages?.some( subPage => matchPath( { path: subPage.url, end: false }, location.pathname ) != null )
            )
            const subMenuItems = page?.subPages?.map( subPage => ( {
              href: subPage.url,
              isActive: matchPath( { path: page.url, end: false }, location.pathname ) != null,
              label: subPage.label,
            } ) )

            return (
              <SideMenuItem
                key={ page.url }
                href={ page.url }
                isActive={ isActive }
                label={ page.label }
                subMenuItems={ subMenuItems }
              />
            )
          } )
        }

      </nav>
      <SidebarFooter>
        <SideMenuItem
          label={ profileLabel || "-" }
          onClick={ handleProfileMenuOpen }
        />
        <Menu
          anchorEl={ profileAnchorElement }
          anchorOrigin={ { horizontal: "right", vertical: "top" } }
          autoFocus={ false }
          onClose={ handleProfileMenuClose }
          open={ profileMenuOpen }
          PaperProps={ {
            sx: {
              backgroundColor: styleHelpers.colors.darkerBlue,
              color: styleHelpers.colors.white,
              padding: "4px 0",
              width: 250,
            },
          } }
        >
          <SideMenuFooterItem onClick={ logout }>
            <span style={ { fontFamily: styleHelpers.fonts.default } }>Sign Out</span>
          </SideMenuFooterItem>
        </Menu>
      </SidebarFooter>
    </Container>
  )
}

const SideMenuItemContainer = styled( AdminLink )<{ $isActive: boolean }>`
  align-items: center;
  background-color: ${ ( props ) => props.$isActive ? styleHelpers.colors.white : "transparent" };
  color: ${ ( props ) => props.$isActive ? styleHelpers.colors.primary : "white" };
  cursor: pointer;
  display: flex;
  font-size: ${ styleHelpers.fonts.sizes.small };
  font-weight: ${ styleHelpers.fonts.weight.semiBold };
  padding: 12px 10px 12px 30px;
  text-decoration: none;

  &:focus, &:hover {
    color: ${ ( props ) => props.$isActive ? styleHelpers.colors.primary : "white" };
    text-decoration: none;
  }

  &:hover {
    background-color: ${ ( props ) => props.$isActive ? styleHelpers.colors.white : styleHelpers.colors.darkerBlue };
  }
`

const SideMenuParentItemContainer = styled.div<{ $isActive: boolean }>`
  align-items: center;
  background-color: ${ ( { $isActive } ) => $isActive ? styleHelpers.colors.darkerBlueGray : "transparent" };
  color: white;
  cursor: pointer;
  display: flex;
  font-size: ${ styleHelpers.fonts.sizes.small };
  font-weight: ${ styleHelpers.fonts.weight.semiBold };
  justify-content: space-between;
  padding: 12px 10px 12px 30px;
  text-decoration: none;

  &:focus, &:hover {
    color: white;
    text-decoration: none;
  }

  &:hover {
    background-color: ${ ( { $isActive } ) => $isActive ? styleHelpers.colors.darkerBlueGray : styleHelpers.colors.darkerBlue };
  }
`

const SideMenuParentChildrenContainer = styled.div`
  a {
    padding-left: 46px;
  }
`

export interface SideMenuItemProps {
  containerStyle?: React.CSSProperties
  href?: string
  icon?: () => React.ReactNode
  isActive?: boolean
  label: string
  onClick?( event: React.MouseEvent<HTMLElement> ): void
  subMenuItems?: SideMenuItemProps[]
}

const SideMenuItem: React.FC<SideMenuItemProps> = ( {
  containerStyle,
  href,
  isActive,
  label,
  onClick,
  subMenuItems,
} ) => {
  const [ isOpenParentMenu, setIsOpenParentMenu ] = useState(
    subMenuItems?.some( subMenuItem => subMenuItem.isActive ) ?? false,
  )

  const toggleParentMenu = useCallback( ()=> {
    setIsOpenParentMenu( ! isOpenParentMenu )
  }, [ isOpenParentMenu ] )

  if( subMenuItems ) {
    return (
      <>
        <SideMenuParentItemContainer
          $isActive={ isActive || false }
          onClick={ toggleParentMenu }
          style={ containerStyle }
        >
          <div style={ { fontFamily: styleHelpers.fonts.default } }>
            { label }
          </div>
          <div>
            {
              isOpenParentMenu ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
            }
          </div>
        </SideMenuParentItemContainer>

        {
          isOpenParentMenu && (
            <SideMenuParentChildrenContainer>
              {
                subMenuItems.map( ( subMenuItem, index )=> (
                  <SideMenuItemContainer
                    $isActive={ subMenuItem.isActive || false }
                    href={ subMenuItem.href }
                    key={ index }
                    onClick={ subMenuItem.onClick }
                    style={ subMenuItem.containerStyle }
                  >
                    <div style={ { flex: 1 } }>
                      <div style={ { fontFamily: styleHelpers.fonts.default } }>{ subMenuItem.label }</div>
                    </div>
                  </SideMenuItemContainer>
                ) )
              }
            </SideMenuParentChildrenContainer>
          )
        }
      </>
    )
  }

  return (
    <SideMenuItemContainer
      $isActive={ isActive || false }
      href={ href }
      onClick={ onClick }
      style={ containerStyle }
    >
      <div style={ { flex: 1 } }>
        <div style={ { fontFamily: styleHelpers.fonts.default } }>{ label }</div>
      </div>
    </SideMenuItemContainer>
  )
}

const StyledSideMenuFooterItem = styled( Link )`
  color: ${ styleHelpers.colors.white };
  display: flex;
  flex: 1;
  font-weight: ${ styleHelpers.fonts.weight.semiBold };
  font-size: ${ styleHelpers.fonts.sizes.small };
  gap: 0 12px;
  text-decoration: none ;

  &:focus, &:hover {
    color: white;
    text-decoration: none;
  }
`

export interface SideMenuFooterItemProps {
  children?: React.ReactNode
  path?: string
  onClick?( event: React.MouseEvent<HTMLElement> ): void
  shouldOpenNewTab?: boolean
}

const SideMenuFooterItem: React.FC<SideMenuFooterItemProps> = ( {
  children,
  onClick,
  path,
  shouldOpenNewTab,
} ) => {
  return (
    <MenuItem
      onClick={ onClick }
      style={ { padding: "15px 10px 15px 30px" } }
    >
      <StyledSideMenuFooterItem href={ path } target={ shouldOpenNewTab ? "_blank" : undefined }>
        { children }
      </StyledSideMenuFooterItem>
    </MenuItem>
  )
}

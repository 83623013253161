import { useCallback, useEffect, useState } from "react"

import { authInternalAccountService, useCurrentInternalAccount } from "@onelocal/frontend-admin-web/auth"
import { useCurrentSession } from "@onelocal/frontend-admin-web/common"

export const useAdmin = () => {
  const { currentInternalAccount, reloadCurrentInternalAccount } = useCurrentInternalAccount()
  const { currentSession, reloadCurrentSession } = useCurrentSession()
  const [ isLoading, setIsLoading ] = useState( true )

  const loadAdmin = useCallback( async () => {
    const session = await reloadCurrentSession()
    if( session ) {
      await Promise.all( [
        reloadCurrentInternalAccount(),
      ] )
    }
  }, [ reloadCurrentSession, reloadCurrentInternalAccount ] )

  useEffect( () => {
    const run = async () => {
      await loadAdmin()
      setIsLoading( false )
    }
    run()
  }, [ loadAdmin ] )

  useEffect( () => {
    let handler: NodeJS.Timeout | number

    const cancelUpdateLastActiveDateInterval = () => {
      if( handler ) {
        clearInterval( handler as NodeJS.Timeout )
      }
    }

    const scheduleUpdateLastActiveDateInterval = () => {
      cancelUpdateLastActiveDateInterval()

      handler = setInterval( () => {
        updateLastActiveDate()
      // update every 15min
      }, 15 * 60 * 1000 )
    }

    const updateLastActiveDate = async () => {
      cancelUpdateLastActiveDateInterval()

      if( currentInternalAccount ) {
        try {
          await authInternalAccountService.updateLastActiveDate( currentInternalAccount.id )
        } finally {
          scheduleUpdateLastActiveDateInterval()
        }
      }
    }

    updateLastActiveDate()

    return () => {
      cancelUpdateLastActiveDateInterval()
    }
  }, [ currentInternalAccount ] )

  return {
    currentInternalAccount,
    currentSession,
    isLoading,
    loadAdmin,
  }
}

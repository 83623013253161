import InfoOutlined from "@mui/icons-material/InfoOutlined"
import React, { useCallback, useMemo, useState } from "react"
import type { SubmitHandler, UseFormReturn } from "react-hook-form"
import { Controller, FormProvider, useFieldArray } from "react-hook-form"
import styled from "styled-components"

import { useAsyncDispatch, useForm } from "@onelocal/frontend/common"
import { LoadingOverlay } from "@onelocal/frontend-admin-web/common"
import type { MerchantSelectorModalValue } from "@onelocal/frontend-admin-web/merchants"
import { MerchantSelectorBody } from "@onelocal/frontend-admin-web/merchants"
import { environment } from "@onelocal/frontend-admin-web-env/environment"
import type { DialogAction, DialogAlert, SelectInlineOption } from "@onelocal/frontend-web/common"
import { Dialog, DialogAlertType, Flex, RadioGroup, Select, TextEditor, TextField, useAlert, useModalWithParams } from "@onelocal/frontend-web/common"
import { mainActions } from "../../store/mainStore"
import type { FileAsset, InformationTaskBody, JiraTaskBody } from "../../types"
import { Task } from "../../types"
import { StyledInfoBanner } from "../components/StyledInfoBanner"
import { StartIntercomModal } from "../StartIntercomChatModal"
import { Attachments } from "./Attachments"
import { NewTaskCreated } from "./NewTaskCreated"

const CATEGORY_OPTIONS: Array<SelectInlineOption<Task.Category>> = [
  {
    label: "Site",
    value: Task.Category.SITE,
  },
  {
    label: "SEO",
    value: Task.Category.SEO,
  },
  {
    label: "Ads",
    value: Task.Category.ADS,
  },
  {
    label: "Social",
    value: Task.Category.SOCIAL,
  },
  {
    label: "Client Management",
    value: Task.Category.CLIENT_MANAGEMENT,
  },
  {
    label: "Onboarding",
    value: Task.Category.ONBOARDING,
  },
]

const CHANGE_TYPE_OPTIONS: Record<Task.Category, Array<SelectInlineOption<Task.ChangeType>>> = {
  [ Task.Category.ADS ]: [
    {
      label: "Add User to Google Analytics",
      value: Task.ChangeType.ADD_USER_TO_GA,
    },
    {
      label: "Add GST Number to an Ads Account",
      value: Task.ChangeType.ADD_GST_NUMBER,
    },
    {
      label: "Credit Card Failed",
      value: Task.ChangeType.CREDIT_CARD_FAILED,
    },
    {
      label: "Ad Account Verification Required",
      value: Task.ChangeType.ADD_ACCOUNT_VERIFICATION_REQUIRED,
    },
    {
      label: "Optimization Report - Ongoing Recurring Task",
      value: Task.ChangeType.OPTIMIZATION_REPORT,
    },
    {
      label: "Ongoing Optimization - Scheduled Landing Page Optimization",
      value: Task.ChangeType.ONGOING_OPTIMIZATION,
    },
    {
      label: "Monthly Detailed Ads Report",
      value: Task.ChangeType.MONTHLY_DETAIL_REPORT,
    },
    {
      label: "Custom",
      value: Task.ChangeType.CUSTOM_CHANGE,
    },
  ],
  [ Task.Category.CLIENT_MANAGEMENT ]: [
    {
      label: "Automation Disconnected - Request Updated Credentials",
      value: Task.ChangeType.AUTOMATION_DISCONNECTED,
    },
    {
      label: "LocalReview Reporting",
      value: Task.ChangeType.LOCAL_REVIEW_REPORTING,
    },
    {
      label: "Custom",
      value: Task.ChangeType.CUSTOM_CHANGE,
    },
  ],
  [ Task.Category.ONBOARDING ]: [
    {
      label: "Custom",
      value: Task.ChangeType.CUSTOM_CHANGE,
    },
  ],
  [ Task.Category.SITE ]: [
    {
      label: "Custom",
      value: Task.ChangeType.CUSTOM_CHANGE,
    },
  ],
  [ Task.Category.SOCIAL ]: [
    {
      label: "Custom",
      value: Task.ChangeType.CUSTOM_CHANGE,
    },
  ],
  [ Task.Category.SEO ]: [
    {
      label: "Indexing Issues",
      value: Task.ChangeType.INDEXING_ISSUES,
    },
    {
      label: "General Recommendations",
      value: Task.ChangeType.GENERAL_RECOMMENDATIONS,
    },
    {
      label: "Content Gaps",
      value: Task.ChangeType.CONTENT_GAPS,
    },
    {
      label: "Custom",
      value: Task.ChangeType.CUSTOM_CHANGE,
    },
  ],
}

const TASK_TYPE_OPTIONS = [
  {
    label: "Client Interaction",
    tooltip: "Select this option to create a Jira ticket and generate a user-facing action card in the Activity Feed. You can follow up with users through the Intercom chat.",
    value: Task.TicketType.TICKET,
  },
  {
    label: "Information Only",
    tooltip: "Select this option to generate a user-facing action card in the Activity Feed without creating a Jira ticket. You won’t be able to communicate with users further.",
    value: Task.TicketType.INFORMATION,
  },
]

const StyledTextField = styled( TextField )`
  width: 100%;
`

export interface NewTakModalModalProps {
  isOpen: boolean
  onClose( reOpen?: boolean ): void
  onExited(): void
  onTaskCreated(): void
}

interface NewTaskBodyProps {
  formMethods: UseFormReturn<FormValues>
  merchant: MerchantSelectorModalValue
}

enum Tab {
  DETAILS = "details",
  MERCHANTS = "merchants",
  START_INTERCOM = "start_intercom",
  TASK_CREATED = "task_created",
}

enum FormFieldKeys {
  ATTACHMENTS = "attachments",
  CHANGE_NAME = "change_name",
  CHANGE_TYPE = "change_type",
  DESCRIPTION = "description",
  TITLE = "title",
  TASK_TYPE = "type",
  TEAM = "team",
}

type FormValues = {
  [ FormFieldKeys.TASK_TYPE ]: Task.TicketType
  [ FormFieldKeys.ATTACHMENTS ]: FileAsset[]
  [ FormFieldKeys.TEAM ]: string
  [ FormFieldKeys.CHANGE_TYPE ]: Task.ChangeType
  [ FormFieldKeys.CHANGE_NAME ]: string
  [ FormFieldKeys.TITLE ]: string
  [ FormFieldKeys.DESCRIPTION ]: string
}

export const NewTaskModal: React.FC<NewTakModalModalProps> = ( {
  isOpen,
  onClose,
  onExited,
  onTaskCreated,
} ) => {
  const [ alert, setAlert ] =useState<DialogAlert | undefined>()
  const { showError } = useAlert()
  const dispatch = useAsyncDispatch()
  const formMethods = useForm<FormValues>( {
    defaultValues: {
      [ FormFieldKeys.TASK_TYPE ]: Task.TicketType.TICKET,
      [ FormFieldKeys.ATTACHMENTS ]: [],
      [ FormFieldKeys.TEAM ]: "",
      [ FormFieldKeys.CHANGE_TYPE ]: "",
      [ FormFieldKeys.CHANGE_NAME ]: "",
      [ FormFieldKeys.TITLE ]: "",
      [ FormFieldKeys.DESCRIPTION ]: "",
    },
  } )

  useFieldArray( { control: formMethods.control!, name: FormFieldKeys.ATTACHMENTS } )
  const [ newTask, setNewTask ] = useState<Task | undefined>()
  const [ isLoading, setIsLoading ] = useState<boolean>( false )
  const [ selectedTab, setSelectedTab ] = useState<Tab>( Tab.MERCHANTS )
  const [ selectedMerchant, setMerchantSelectorModalValue ] = useState<MerchantSelectorModalValue | undefined>()

  const { handleSubmit } = formMethods

  const description = formMethods.watch( FormFieldKeys.DESCRIPTION )

  const openJiraIssue = useCallback( () => {
    window.open( `${ environment.jiraIssueBaseUrl }/${ newTask?.jira?.key }`, "_blank" )
  }, [ newTask?.jira?.key ] )

  const onSave: SubmitHandler<FormValues> = useCallback( async ( data ) => {
    try {
      setAlert( undefined )
      setIsLoading( true )

      let task = null

      if( data[ FormFieldKeys.TASK_TYPE ] === Task.TicketType.INFORMATION ) {
        task = await dispatch( mainActions.tasks.create( {
          attachments: data[ FormFieldKeys.ATTACHMENTS ].map( attachment => ( { asset_id: attachment.assetId } ) ),
          category: data[ FormFieldKeys.TEAM ],
          description: data[ FormFieldKeys.DESCRIPTION ],
          merchant_id: selectedMerchant?.merchantId || "",
          title: data[ FormFieldKeys.TITLE ],
          type: data[ FormFieldKeys.TASK_TYPE ],
        } as InformationTaskBody ) )
      } else {
        task = await dispatch( mainActions.tasks.create( {
          category: data[ FormFieldKeys.TEAM ],
          change_name: data[ FormFieldKeys.CHANGE_NAME ],
          change_type: data[ FormFieldKeys.CHANGE_TYPE ],
          description: data[ FormFieldKeys.DESCRIPTION ],
          merchant_id: selectedMerchant?.merchantId || "",
          type: data[ FormFieldKeys.TASK_TYPE ],
        } as JiraTaskBody ) )
      }

      setNewTask( task )
      setSelectedTab( Tab.TASK_CREATED )
      onTaskCreated()
    } catch( err ) {
      showError( err )
    } finally {
      setIsLoading( false )
    }
  }, [ dispatch, onTaskCreated, selectedMerchant?.merchantId, showError ] )

  const modalActions: DialogAction[] = useMemo( () =>
    selectedTab === Tab.TASK_CREATED
      ? [ { onClick: openJiraIssue, title: `Open ${ newTask?.jira?.key }`, type: "primary" } ]
      : [
        {
          onClick: selectedTab === Tab.MERCHANTS ? () => onClose() : () => setSelectedTab( Tab.MERCHANTS ),
          title: selectedTab === Tab.MERCHANTS ? "Cancel" : "Back",
          type: "dismiss",
        },
        {
          onClick: selectedTab === Tab.MERCHANTS ? () => setSelectedTab( Tab.DETAILS ) : handleSubmit( onSave, () => {
            setAlert( {
              message: "Please make sure all required fields are filled properly.",
              type: DialogAlertType.ERROR,
            } )
          } ),
          title: selectedTab === Tab.MERCHANTS ? "Next" :"Сreate Task",
          disabled: selectedTab === Tab.DETAILS && ! description,
          type: "primary",
        },
      ],
  [ handleSubmit, newTask, onClose, onSave, openJiraIssue, selectedTab, description ] )

  if( selectedTab === Tab.START_INTERCOM ) {
    return <StartIntercomModal { ...{ isOpen, onClose, onExited, task: newTask! } } />
  }

  return (
    <Dialog
      alert={ alert }
      actions={ isLoading || newTask?.type === Task.TicketType.INFORMATION ? [] : modalActions }
      contentContainerStyle={ {
        paddingLeft: "24px",
        paddingRight: "24px",
        width: "500px",
      } }
      isOpen={ isOpen }
      onClose={ onClose }
      onExited={ onExited }
      showDividers={ false }
      title={ "" }
    >
      <LoadingOverlay isLoading={ isLoading } />
      { selectedTab === Tab.MERCHANTS && (
        <>
          <StyledInfoBanner>
            <InfoOutlined /> Please select a merchant to your new task. You can search by merchant, ID or salesforce ID.
          </StyledInfoBanner>
          <MerchantSelectorBody { ...{ selectedMerchant, setMerchantSelectorModalValue } } />
        </>
      ) }
      { selectedTab === Tab.DETAILS && ! isLoading && selectedMerchant && ( <NewTaskBody { ...{ formMethods, merchant: selectedMerchant } }/> ) }
      { selectedTab === Tab.TASK_CREATED && newTask && ( <NewTaskCreated task={ newTask } startIntercom={ () => { setSelectedTab( Tab.START_INTERCOM ) } } /> ) }
    </Dialog>
  )
}

const NewTaskBody: React.FC<NewTaskBodyProps> = ( { formMethods, merchant } ) => {
  const { watch, setValue, register, formState } = formMethods

  const updateAttachments = useCallback( ( attachments: FileAsset[] ) => {
    register( FormFieldKeys.ATTACHMENTS )
    setValue( FormFieldKeys.ATTACHMENTS, attachments )
  }, [ register, setValue ] )

  return (
    <>
      <StyledInfoBanner>
        <InfoOutlined /> You are creating a new task for { merchant.merchantName }. <br/><br/> Please note, this task will create a new ticket on Jira’s board based on your selections or preferences.
      </StyledInfoBanner>

      <h4>Select a task format</h4>

      <FormProvider { ...formMethods }>
        <Flex display="flex" flexDirection="column" style={ { gap: 16 } }>
          <Controller
            control={ formMethods.control }
            name={ FormFieldKeys.TASK_TYPE }
            render={ ( { field: { onChange, value } } ) => (
              <RadioGroup onChange={ onChange } options={ TASK_TYPE_OPTIONS } value={ value } />
            ) }
          />
          <Controller
            control={ formMethods.control }
            name={ FormFieldKeys.TEAM }
            render={ ( { field: { onChange, value } } ) => (
              <Select
                fullWidth
                label="Select a channel"
                options={ CATEGORY_OPTIONS }
                value={ value || "" }
                onChange={ onChange }
                required={ true }
              />
            ) }
          />
          {
            watch( FormFieldKeys.TASK_TYPE ) === Task.TicketType.TICKET && (
              <Controller
                control={ formMethods.control }
                name={ FormFieldKeys.CHANGE_TYPE }
                render={ ( { field: { onChange, value } } ) => (
                  <Select
                    disabled={ ! watch( FormFieldKeys.TEAM ) }
                    fullWidth
                    label="Change Type"
                    onChange={ onChange }
                    options={ CHANGE_TYPE_OPTIONS[ watch( FormFieldKeys.TEAM ) as Task.Category ] || [] }
                    value={ value || "" }
                  />
                ) }
              />
            )
          }

          {
            watch( FormFieldKeys.TASK_TYPE ) === Task.TicketType.INFORMATION && (
              <Controller
                control={ formMethods.control }
                name={ FormFieldKeys.TITLE }
                render={ ( { field: { onChange, value } } ) => (
                  <StyledTextField
                    label="Subject"
                    placeholder="Enter with the subject"
                    errorMessage={ formState.errors?.[ FormFieldKeys.TITLE ]?.message }
                    onChange={ onChange }
                    value={ value || "" }
                  />
                ) }
                rules={ {
                  validate: ( value ) => {
                    if( value.length > 43 ) {
                      return "Change Name must be less than 43 characters"
                    }

                    return undefined
                  },
                } }
              />
            )
          }

          {
            watch( FormFieldKeys.CHANGE_TYPE ) === Task.ChangeType.CUSTOM_CHANGE && (
              <Controller
                control={ formMethods.control }
                name={ FormFieldKeys.CHANGE_NAME }
                render={ ( { field: { onChange, value } } ) => (
                  <StyledTextField
                    disabled={ ! watch( FormFieldKeys.TEAM ) }
                    errorMessage={ formState.errors?.[ FormFieldKeys.CHANGE_NAME ]?.message }
                    label="Change Name"
                    onChange={ onChange }
                    value={ value || "" }
                  />
                ) }
                rules={ {
                  validate: ( value ) => {
                    if( value.length > 43 ) {
                      return "Change Name must be less than 43 characters"
                    }

                    return undefined
                  },
                } }
              />
            )
          }
          <Controller
            control={ formMethods.control }
            name={ FormFieldKeys.DESCRIPTION }
            render={ ( { field: { onChange, value } } ) => watch( FormFieldKeys.TASK_TYPE ) === Task.TicketType.INFORMATION ? (
              <div id="editor-bounds">
                <TextEditor
                  bounds="#editor-bounds"
                  disabled={ ! watch( FormFieldKeys.TEAM ) }
                  errorMessage={ formState.errors?.[ FormFieldKeys.DESCRIPTION ]?.message }
                  height="250px"
                  onChange={ onChange }
                  value={ value || "" }
                />
              </div>
            ) : (
              <StyledTextField
                disabled={ ! watch( FormFieldKeys.TEAM ) }
                errorMessage={ formState.errors?.[ FormFieldKeys.DESCRIPTION ]?.message }
                label="Description"
                placeholder="Description for client facing additional information"
                multiline
                onChange={ onChange }
                rows={ 11 }
                value={ value || "" }
              />
            ) }
          />
        </Flex>
      </FormProvider>
      { watch( FormFieldKeys.TASK_TYPE ) === Task.TicketType.INFORMATION && merchant.merchantId && ( <Attachments updateAttachments={ updateAttachments } merchantId={ merchant.merchantId }/> ) }
    </>
  )
}

export const useNewTaskModal = ( onTaskCreated: () => void ) => {
  const { showModal, hideModal } = useModalWithParams( ( { open, onExited, params } ) => {
    return ( <NewTaskModal isOpen={ open } onClose={ hideModal } onExited={ onExited } onTaskCreated={ onTaskCreated }/> )
  }, [] )

  return {
    hideNewTaskModal: hideModal,
    showNewTaskModal: showModal,
  }
}

// eslint-disable-next-line no-restricted-imports
import CircularProgress from "@mui/material/CircularProgress"
import React from "react"

export interface LoadingProps {
  size?: number
}

export const Loading: React.FC<LoadingProps> = React.memo( ( { size } ) => (
  <CircularProgress color="inherit" size={ size ?? 100 }/>
) )

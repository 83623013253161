import FirstPageIcon from "@mui/icons-material/FirstPage"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import LastPageIcon from "@mui/icons-material/LastPage"
// eslint-disable-next-line no-restricted-imports
import IconButton from "@mui/material/IconButton"
import React, { useCallback, useMemo } from "react"
import styled from "styled-components"

import { Flex } from "."

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
`

export interface TablePaginationProps {
  onPageChange: ( page: number ) => void
  page: number
  perPage: number
  style?: React.CSSProperties
  totalItems: number
}

export const TablePagination: React.FC<TablePaginationProps> = React.memo( ( {
  onPageChange,
  page,
  perPage,
  style,
  totalItems,
  ...otherProps
} ) => {
  const lastPage = useMemo( () => {
    return Math.ceil( totalItems / perPage )
  }, [ perPage, totalItems ] )

  const onFirstPageHandler = useCallback( () => {
    onPageChange( 1 )
  }, [ onPageChange ] )

  const onPreviousPageHandler = useCallback( () => {
    onPageChange( page - 1 )
  }, [ onPageChange, page ] )

  const onNextPageHandler = useCallback( () => {
    onPageChange( page + 1 )
  }, [ onPageChange, page ] )

  const onLastPageHandler = useCallback( () => {
    onPageChange( lastPage )
  }, [ lastPage, onPageChange ] )

  return (
    <Container
      style={ {
        ...style,
      } }
    >
      <div
        style={ {

        } }
      >
        { 1 + ( ( page - 1 ) * perPage ) }-{ Math.min( page * perPage, totalItems ) } of { totalItems }
      </div>
      <Flex
        alignItems="center"
        flexDirection="row"
        style={ {
          marginLeft: "25px",
        } }
      >

        <IconButton aria-label="first" disabled={ page === 1 } onClick={ onFirstPageHandler }>
          <FirstPageIcon />
        </IconButton>
        <IconButton aria-label="previous" disabled={ page === 1 } onClick={ onPreviousPageHandler }>
          <KeyboardArrowLeftIcon />
        </IconButton>
        <IconButton aria-label="next" disabled={ page === lastPage } onClick={ onNextPageHandler }>
          <KeyboardArrowRightIcon />
        </IconButton>
        <IconButton aria-label="previous" disabled={ page === lastPage } onClick={ onLastPageHandler }>
          <LastPageIcon />
        </IconButton>
      </Flex>
    </Container>
  )
} )

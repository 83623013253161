import React, { useCallback, useEffect, useMemo, useState } from "react"

import type { PaginatedQueryOptions } from "@onelocal/frontend/common"
import { useAsyncDispatch, useDebounce, usePaginatedResources } from "@onelocal/frontend/common"
import { LoadingOverlay } from "@onelocal/frontend-admin-web/common"
import type { SelectOption } from "@onelocal/frontend-web/common"
import { Button, Flex, Page, SearchBar, SelectInline, StyledTable, StyledTableRow, TablePagination, useAlert } from "@onelocal/frontend-web/common"
import { connectMerchantSettingsHelpers } from "../helpers/merchantSettingsHelpers"
import type { ConnectMerchantSettingsService } from "../services/merchantSettingsService"
import { connectSelectors } from "../store/connectSelectors"
import type { RootStateConnect } from "../store/connectStore"
import { connectActions } from "../store/connectStore"
import { ConnectMerchantSettings } from "../types"

const ALL_VALUE = "all"

const statusesOptions: Array<SelectOption<string>> = [
  {
    label: "All",
    value: ALL_VALUE,
  },
  ...[
    ConnectMerchantSettings.A2PStatus.IN_PROGRESS,
    ConnectMerchantSettings.A2PStatus.IN_REVIEW,
    ConnectMerchantSettings.A2PStatus.REGISTERED,
    ConnectMerchantSettings.A2PStatus.INVALID,
  ].map<SelectOption<string>>( ( status ) => ( {
    label: connectMerchantSettingsHelpers.getA2PStatus( status ),
    value: status,
  } ) ),
]

export interface ApplicationToPersonPageProps {
}

export const ApplicationToPersonPage: React.FC<ApplicationToPersonPageProps> = () => {
  const dispatch = useAsyncDispatch()
  const { showError, showSuccess } = useAlert()
  const getQueryAction = useCallback( ( queryFilter: ConnectMerchantSettingsService.QueryFilter, queryOptions: PaginatedQueryOptions ) => {
    return connectActions.merchantSettings.query( queryFilter, queryOptions )
  }, [] )

  const [ searchText, setSearchText ] = useState<string>( "" )
  const [ selectedStatus, setSelectedStatus ] = useState<string>( ALL_VALUE )
  const { debouncedValue: debounceSearchText } = useDebounce( searchText, 300 )

  const {
    isLoading,
    items,
    queryOptions,
    loadPage,
    setQueryFilter,
    totalItemsCount,
  } = usePaginatedResources<ConnectMerchantSettings, ConnectMerchantSettingsService.QueryFilter, RootStateConnect>( {
    getQueryAction,
    defaultFilter: {
      a2p_started: true,
      a2p_status: ALL_VALUE,
    },
    perPage: 25,
    selector: connectSelectors.merchantSettings.byIds,
  } )

  const onStatusOptionChanged = useCallback( ( value: ConnectMerchantSettings.A2PStatus ) => {
    setSelectedStatus( value )
    setQueryFilter( { a2p_status: value } )
  }, [ setQueryFilter ] )

  const onPageChanged = useCallback( ( page: number ) => {
    loadPage( page )
  }, [ loadPage ] )

  const onRegister = useCallback( async ( merchantSettings: ConnectMerchantSettings ) => {
    try {
      await dispatch( connectActions.merchantSettings.registerA2P( merchantSettings.id ) )
      showSuccess( "Registration process started" )
    } catch( err ) {
      showError( err )
    }
  }, [ dispatch, showError, showSuccess ] )

  useEffect( () => {
    setQueryFilter( { q: debounceSearchText ?? null } )
  }, [ debounceSearchText, setQueryFilter ] )

  return (
    <Page
      buttons={ [] }
      title="A2P"
    >
      <Flex
        alignItems="center"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        style={ {
          margin: "25px 0",
        } }
      >
        <SearchBar
          onChange={ setSearchText }
          placeholder="Search by Name"
          style={ {
            flexGrow: 1,
            marginRight: "40px",
            maxWidth: "350px",
          } }
          value={ searchText }
        />
        <Flex
          alignItems="center"
          display="flex"
          flexDirection="row"
        >
          <SelectInline
            label="Status"
            onChange={ onStatusOptionChanged }
            options={ statusesOptions }
            value={ selectedStatus }
          />
        </Flex>
      </Flex>
      { <LoadingOverlay isLoading={ isLoading } /> }
      {
        items != null && isLoading === false && (
          <>
            <StyledTable>
              <thead>
                <tr>
                  <th>Merchant Name</th>
                  <th>Status</th>
                  <th>Details</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  items.length > 0
                    ? (
                      items.map( ( item ) => (
                        <MerchantSettingsRow key={ item.id } merchantSettings={ item } onRegister={ onRegister }/>
                      ) )
                    )
                    : (
                      <tr>
                        <td colSpan={ 8 }>
                          <Flex
                            alignItems="center"
                            display="flex"
                            justifyContent="center"
                            style={ { height: "300px" } }
                          >
                            No merchant found. Please try to adjust your search or filters.
                          </Flex>
                        </td>
                      </tr>
                    )
                }
                {

                }
              </tbody>
            </StyledTable>
            {
              totalItemsCount != null && totalItemsCount > 0 && (
                <TablePagination
                  onPageChange={ onPageChanged }
                  page={ queryOptions.page }
                  perPage={ queryOptions.perPage }
                  totalItems={ totalItemsCount || 0 }
                />
              )
            }
          </>
        )
      }
    </Page>
  )
}

interface MerchantSettingsRowProps {
  merchantSettings: ConnectMerchantSettings
  onRegister( merchantSettings: ConnectMerchantSettings ): void
}

const MerchantSettingsRow: React.FC<MerchantSettingsRowProps> = React.memo( ( { merchantSettings, onRegister } ) => {
  const errorMessages = useMemo( () => {
    return connectMerchantSettingsHelpers.getA2PErrorMessages( merchantSettings )
  }, [ merchantSettings ] )

  const onRegisterHandler = useCallback( () => {
    onRegister( merchantSettings )
  }, [ merchantSettings, onRegister ] )

  return (
    <StyledTableRow>
      <td>{ merchantSettings.queryIndex?.merchantName || "-" }  </td>
      <td>{ connectMerchantSettingsHelpers.getA2PStatus( merchantSettings.twilio.a2pStatus ) }</td>
      <td dangerouslySetInnerHTML={ { __html: errorMessages.join( "<br />" ) } } />
      <td>
        {
          merchantSettings.twilio.a2pStatus !== ConnectMerchantSettings.A2PStatus.REGISTERED && (
            <Button onClick={ onRegisterHandler }>Register</Button>
          )
        }
      </td>
    </StyledTableRow>
  )
} )

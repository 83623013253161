// eslint-disable-next-line @nx/enforce-module-boundaries
import type { MerchantSettings } from "@onelocal/frontend-dashboard/settings"
import type { PhoneNumber } from "@onelocal/shared/common"

export interface MessagesChannel {
  id: string
  type: "phone"
  platform: MessagesChannel.TwilioPlatform
  phoneNumber: PhoneNumber
  callForwarding?: {
    enabled?: boolean
    phoneNumber?: PhoneNumber
  }
  blockVoip: boolean
}

export namespace MessagesChannel {
  export interface TwilioPlatform {
    type: "twilio"
    twilioPhoneId: string
    capabilities?: TwilioPlatformCapability[]
  }

  export enum TwilioPlatformCapability {
    FAX = "fax",
    MMS = "mms",
    SMS = "sms",
    VOICE = "voice",
  }
}

export interface MessagesMerchantsSettings {
  assignment: {
    enabled: boolean
    assignOnReply?: boolean
    unassignClosed?: boolean
    defaultAssignee?: {
      ref: "employees"
      id: string
    }
  }
  autoresponder: {
    status: MessagesMerchantsSettings.AutoResponderStatusType
    message?: string
    periods?: MessagesMerchantsSettings.AutoResponderPeriod[]
  }
  channel?: MessagesChannel
  enabled: boolean
  status: MerchantSettings.ProductStatus | null
  v?: number
  widget?: {
    chatbot?: {
      name?: string
    }
  }
}

export namespace MessagesMerchantsSettings {
  export interface AutoResponderPeriod {
    day: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday" | "everyday" | "weekdays" | "weekend"
    from: string
    to: string
  }

  export enum AutoResponderStatusType {
    OFF = "off",
    ON = "on",
    SCHEDULE = "schedule",
  }
}

export interface MessagesPhoneNumber {
  type: "mobile" | "landline" | "voip" | "unknown"
  value: string
  internationalFormat: string
  nationalFormat: string
  countryCallingCode: number
  countryCode: string
}

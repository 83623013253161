import { reactToAngular } from "angulareact"
import { noConflict } from "lodash"
import { createRoot } from "react-dom/client"

import { eventEmitterHelpers, ValidationError } from "@onelocal/frontend/common"
import { merchantService } from "@onelocal/frontend-dashboard/common"
import type { connectCallHelpers, connectMessagesHelpers, connectService, ConnectUsageStatsSummary } from "@onelocal/frontend-dashboard/connect"
import { ConnectEventType, conversationsService, EventType, phoneService } from "@onelocal/frontend-dashboard/connect"
import { ContactEventType } from "@onelocal/frontend-dashboard/contacts"
import { activitiesService } from "@onelocal/frontend-dashboard/main"
import type { addMerchantSettingsListener } from "@onelocal/frontend-dashboard/settings"
import { siteSettingsService } from "@onelocal/frontend-dashboard/site"
import type { CalendarEvent } from "@onelocal/frontend-dashboard/visits"
import { BookingEventType } from "@onelocal/frontend-dashboard/visits"
import type { doesRouteExist, SaveButtonProps } from "@onelocal/frontend-dashboard-web/common"
import { DesktopNotification, SaveButton } from "@onelocal/frontend-dashboard-web/common"
import type { CallHistoryProps } from "@onelocal/frontend-dashboard-web/connect"
import { CallHistory } from "@onelocal/frontend-dashboard-web/connect"
import type { DatePickerProps } from "@onelocal/frontend-web/common"
import { DatePicker } from "@onelocal/frontend-web/common"
import type { utilHelpers } from "@onelocal/shared/common"
import { ComponentWrapper, ComponentWrapperRef } from "./components/ComponentWrapper"
import { DashboardContainerLib } from "./components/DashboardContainerLib"

noConflict()

export { connectCallHelpers, connectMessagesHelpers, connectService, conversationsService } from "@onelocal/frontend-dashboard/connect"
export { doesRouteExist } from "@onelocal/frontend-dashboard-web/common"
export { utilHelpers } from "@onelocal/shared/common"

export function addCallErrorEventListener( listener: ( message: string ) => void ) {
  eventEmitterHelpers.addEventListener( EventType.CALL_ERROR, listener )
}

export function addOpenBookingCancelModalEventListener( listener: ( calendarEvent: CalendarEvent ) => void ) {
  eventEmitterHelpers.addEventListener( BookingEventType.OPEN_CANCEL_MODAL, listener )
}

export function addOpenBookingChooseTimeSlotModalEventListener( listener: ( calendarEvent: CalendarEvent ) => void ) {
  eventEmitterHelpers.addEventListener( BookingEventType.OPEN_CHOOSE_TIME_SLOT_MODAL, listener )
}

export function addOpenBookingCompleteModalEventListener( listener: ( calendarEvent: CalendarEvent ) => void ) {
  eventEmitterHelpers.addEventListener( BookingEventType.OPEN_COMPLETE_MODAL, listener )
}

export function addOpenBookingEditModalEventListener( listener: ( calendarEvent: CalendarEvent ) => void ) {
  eventEmitterHelpers.addEventListener( BookingEventType.OPEN_EDIT_MODAL, listener )
}

export const onActivityCreatedHandler = activitiesService.onActivityCreated

export const onConversationUpdatedHandler = conversationsService.onConversationUpdatedHandler

export function openAgencyAnalyticsLoginUrl( merchantId: string, location: string ) {
  return merchantService.openAgencyAnalyticsLoginUrl( merchantId, location )
}

export function openBookingDetailsModal( calendarEventId: string ) {
  eventEmitterHelpers.emit( BookingEventType.OPEN_DETAILS_MODAL, calendarEventId )
}

export function openExportContactsModal( customerIds: string[] ) {
  eventEmitterHelpers.emit( ContactEventType.OPEN_EXPORT_MODAL, customerIds )
}

export function openChatBotConversationModal( chatConversationId: string, sender: string ) {
  eventEmitterHelpers.emit( ConnectEventType.OPEN_CHATBOT_CONVERSATION_MODAL, { chatConversationId, sender } )
}

export function openGenerateMessageModal( merchantId?: string ) {
  eventEmitterHelpers.emit( ConnectEventType.OPEN_GENERATE_MESSAGE_MODAL, { merchantId } )

  return new Promise<string | undefined>( ( resolve ) => {
    eventEmitterHelpers.addEventListenerOnce( ConnectEventType.OPEN_GENERATE_MESSAGE_MODAL_RESULT, ( result: string | undefined ) => {
      return resolve( result )
    } )
  } )
}

export function openNewCallModal() {
  eventEmitterHelpers.emit( EventType.OPEN_NEW_CALL_MODAL )
}

export function openSiteLoginUrl( merchantId: string, location: string ) {
  return siteSettingsService.openSiteLoginUrl( merchantId, location )
}

export function renderCallHistory( props: Array<( keyof CallHistoryProps | "children" )> ) {
  const CallHistoryComponent = ComponentWrapper( CallHistory )
  // We have to return as any because when we add @types/angular project it fails the build for the old frontend due to error `Subsequent property declarations must have the same type.  Property '$inject' must be of type 'string[]', but here has type 'readonly string[]'`
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return reactToAngular( CallHistoryComponent, props ) as any
}

export function renderDatePicker( props: Array<( keyof DatePickerProps | "children" )> ) {
  const DatePickerComponent = ComponentWrapper( DatePicker )
  // We have to return as any because when we add @types/angular project it fails the build for the old frontend due to error `Subsequent property declarations must have the same type.  Property '$inject' must be of type 'string[]', but here has type 'readonly string[]'`
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return reactToAngular( DatePickerComponent, props ) as any
}

export function renderDesktopNotificationBanner() {
  const DesktopNotificationComponent = ComponentWrapper( DesktopNotification )
  // We have to return as any because when we add @types/angular project it fails the build for the old frontend due to error `Subsequent property declarations must have the same type.  Property '$inject' must be of type 'string[]', but here has type 'readonly string[]'`
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return reactToAngular( DesktopNotificationComponent ) as any
}

export function renderSaveButton( props: Array<( keyof SaveButtonProps | "children" )> ) {
  const SaveButtonComponent = ComponentWrapperRef( SaveButton )
  // We have to return as any because when we add @types/angular project it fails the build for the old frontend due to error `Subsequent property declarations must have the same type.  Property '$inject' must be of type 'string[]', but here has type 'readonly string[]'`
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return reactToAngular( SaveButtonComponent, props ) as any
}

export function renderRoot( el: HTMLElement ) {
  const root = createRoot( el )
  root.render( <DashboardContainerLib /> )
}

export function startOutboundCall( merchantId: string, to: string ) {
  const isOnCall = phoneService.isOnCall()

  if( isOnCall ) {
    throw new ValidationError( "You cannot place a call if you're already on another call." )
  }

  eventEmitterHelpers.emit( EventType.CALL_OUTBOUND, { merchantId, to } )
}

// Types below will be exposed in legacy dashboard under the NX global var ( i.e NX.renderSideMenu )
declare global {
  export interface FrontendDashboardLib {
    addCallErrorEventListener: typeof addCallErrorEventListener
    addOpenBookingCancelModalEventListener: typeof addOpenBookingCancelModalEventListener
    addOpenBookingChooseTimeSlotModalEventListener: typeof addOpenBookingChooseTimeSlotModalEventListener
    addOpenBookingCompleteModalEventListener: typeof addOpenBookingCompleteModalEventListener
    addOpenBookingEditModalEventListener: typeof addOpenBookingEditModalEventListener
    addMerchantSettingsListener: typeof addMerchantSettingsListener
    connectCallHelpers: typeof connectCallHelpers
    connectMessagesHelpers: typeof connectMessagesHelpers
    connectService: typeof connectService
    conversationsService: typeof conversationsService
    doesRouteExist: typeof doesRouteExist
    onActivityCreatedHandler: typeof onActivityCreatedHandler
    onConversationUpdatedHandler: typeof onConversationUpdatedHandler
    openAgencyAnalyticsLoginUrl: typeof openAgencyAnalyticsLoginUrl
    openBookingDetailsModal: typeof openBookingDetailsModal
    openExportContactsModal: typeof openExportContactsModal
    openChatBotConversationModal: typeof openChatBotConversationModal
    openGenerateMessageModal: typeof openGenerateMessageModal
    openNewCallModal: typeof openNewCallModal
    openSiteLoginUrl: typeof openSiteLoginUrl
    renderRoot: typeof renderRoot
    renderCallHistory: typeof renderCallHistory
    renderDatePicker: typeof renderDatePicker
    renderDesktopNotificationBanner: typeof renderDesktopNotificationBanner
    renderSaveButton: typeof renderSaveButton
    startOutboundCall: typeof startOutboundCall
    utilHelpers: typeof utilHelpers

    ConnectUsageStatsSummary: ConnectUsageStatsSummary
  }
}


import React, { useCallback } from "react"

import type { SelectOption } from "@onelocal/frontend-web/common"
import { Flex, SearchBar, SelectInline } from "@onelocal/frontend-web/common"
import { Site } from "../../types"

const ALL_VALUE = "all"
const STATUS_ENABLED = "enabled"
const STATUS_DISABLED = "disabled"
const statusOptions: Array<SelectOption<string>> = [
  {
    label: "All",
    value: ALL_VALUE,
  },
  {
    label: "Enabled",
    value: STATUS_ENABLED,
  },
  {
    label: "Disabled",
    value: STATUS_DISABLED,
  },
]
const typeOptions: Array<SelectOption<string>> = [
  {
    label: "All",
    value: ALL_VALUE,
  },
  {
    label: Site.TypeLabelMap[ Site.Type.LANDING_PAGE ],
    value: Site.Type.LANDING_PAGE,
  },
  {
    label: Site.TypeLabelMap[ Site.Type.WEBSITE ],
    value: Site.Type.WEBSITE,
  },
]

export type SiteTypesFilter = Site.Type | typeof ALL_VALUE;
export type StatusFilter = typeof STATUS_ENABLED | typeof STATUS_DISABLED | typeof ALL_VALUE;

export interface SiteTemplateFilter {
  searchText: string
  selectedStatus: StatusFilter
  selectedType: SiteTypesFilter
}

export interface SiteTemplateTableFilterProps {
  onFilterChanged: ( filter: SiteTemplateFilter ) => void
  searchText: string
  selectedStatus: StatusFilter
  selectedType: SiteTypesFilter
}

export const SiteTemplateTableFilter: React.FC<SiteTemplateTableFilterProps> = ( {
  onFilterChanged,
  searchText,
  selectedStatus,
  selectedType,
} ) => {
  const onSearchChanged = useCallback( ( newSearchText: string ) => {
    onFilterChanged( {
      searchText: newSearchText,
      selectedStatus,
      selectedType,
    } )
  }, [ onFilterChanged, selectedStatus, selectedType ] )

  const onStatusOptionChanged = useCallback( ( status: StatusFilter ) => {
    onFilterChanged( {
      searchText,
      selectedStatus: status,
      selectedType,
    } )
  }, [ onFilterChanged, searchText, selectedType ] )

  const onTypeOptionChanged = useCallback( ( type: SiteTypesFilter ) => {
    onFilterChanged( {
      searchText,
      selectedStatus,
      selectedType: type,
    } )
  }, [ onFilterChanged, searchText, selectedStatus ] )

  return (
    <Flex
      alignItems="center"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      style={ {
        margin: "0 0 25px 0",
      } }
    >
      <SearchBar
        onChange={ onSearchChanged }
        placeholder="Search by template name"
        style={ {
          flexGrow: 1,
          marginRight: "40px",
          maxWidth: "350px",
        } }
        value={ searchText }
      />
      <Flex
        alignItems="center"
        display="flex"
        flexDirection="row"
      >
        <SelectInline
          label="Status"
          onChange={ onStatusOptionChanged }
          options={ statusOptions }
          value={ selectedStatus }
        />
        <SelectInline
          label="Type"
          onChange={ onTypeOptionChanged }
          options={ typeOptions }
          style={ { marginLeft: "10px" } }
          value={ selectedType }
        />
      </Flex>
    </Flex>
  )
}


export namespace Activity {
  export const enum ApprovalStatus {
    APPROVED = "approved",
    NOT_REQUIRED = "not_required",
    PENDING = "pending",
    REJECTED = "rejected",
  }

  export const enum Status {
    ARCHIVE = "archive",
    INBOX = "inbox",
    PROCESSING = "processing",
  }

  export const enum Type {
    CONVERSATION = "conversation",
    KEYWORD_RANKING_BLOG = "keyword_ranking_blog",
    REVIEW = "review",
    SHARE_BEFORE_AFTER_PHOTOS = "share_before_after_photos",
    SHARE_BULK_MESSAGE = "share_bulk_message",
    SHARE_BLOG_POST = "share_blog_post",
    SHARE_REVIEW = "share_review",
    SHARE_PHOTOS = "share_photos",
  }

  export const ApprovalStatusLabel = {
    [ ApprovalStatus.APPROVED ]: "Approved",
    [ ApprovalStatus.NOT_REQUIRED ]: "Not required",
    [ ApprovalStatus.PENDING ]: "Pending",
    [ ApprovalStatus.REJECTED ]: "Rejected",
  }

  export const TypeLabelMap = {
    [ Type.CONVERSATION ]: "Conversation",
    [ Type.KEYWORD_RANKING_BLOG ]: "Keyword Ranking Blog",
    [ Type.REVIEW ]: "Review",
    [ Type.SHARE_BEFORE_AFTER_PHOTOS ]: "Share Before After Photos",
    [ Type.SHARE_BLOG_POST ]: "Share Blog Post",
    [ Type.SHARE_BULK_MESSAGE ]: "Share Bulk Message",
    [ Type.SHARE_PHOTOS ]: "Share Photos",
    [ Type.SHARE_REVIEW ]: "Share Review",
  }

  export const enum Category {
    MESSAGE = "message",
    MISSED_CALL = "missed_call",
    REVIEW = "review",
    TASK = "task",
  }

  export interface Customer {
    id?: string
    name?: string
    phoneNumber?: string
  }

  export interface ActivitySummaryAccount {
    accountId: string
    date: string
    name: {
      given?: string
      family?: string
      display?: string
    }
  }

  export interface ActivityBase {
    approval: Activity.ApprovalStatus
    archived: {
      accountId: string
      date: string
    } | null
    assignee: {
      accountId: string
      date: string
    } | null
    date: string
    id: string
    merchantId: string
    merchantName: string
    dismissedBy: {
      [accountId: string]: string
    }
    categories: Activity.Category[]
    readBy: {
      [accountId: string]: string
    }
    status: Activity.Status
    type: Activity.Type
  }

  export interface ActivityConversation extends ActivityBase {
    callIds: string[]
    conversationId: string
    messageIds: string[]
    stats: {
      missedCalls: number
      unreadMessages: number
    }
    type: Activity.Type.CONVERSATION
  }

  export interface ActivityKeywordRankingBlog extends ActivityBase {
    blogPostId: string
    type: Activity.Type.KEYWORD_RANKING_BLOG
  }

  export interface ActivityReview extends ActivityBase {
    connectedAccountId?: string
    reviewId: string
    type: Activity.Type.REVIEW
  }

  export interface ActivityShareBeforeAfterPhotos extends ActivityBase {
    socialPostSuggestionId?: string | null
    type: Activity.Type.SHARE_BEFORE_AFTER_PHOTOS
  }

  export interface ActivityShareBlogPost extends ActivityBase {
    blogPostId: string
    socialPostSuggestionIds: string[]
    type: Activity.Type.SHARE_BLOG_POST
  }

  export interface ActivityShareBulkMessage extends ActivityBase {
    bulkMessageId: string
    socialPostSuggestionId: string
    type: Activity.Type.SHARE_BULK_MESSAGE
  }

  export interface ActivityShareReview extends ActivityBase {
    reviewId: string
    socialPostSuggestionId: string
    type: Activity.Type.SHARE_REVIEW
  }

  export interface ActivitySharePhotos extends ActivityBase {
    socialPostSuggestionId: string | null
    type: Activity.Type.SHARE_PHOTOS
  }
}

export type Activity = (
  Activity.ActivityConversation
  | Activity.ActivityKeywordRankingBlog
  | Activity.ActivityReview
  | Activity.ActivityShareBeforeAfterPhotos
  | Activity.ActivityShareBlogPost
  | Activity.ActivityShareBulkMessage
  | Activity.ActivitySharePhotos
  | Activity.ActivityShareReview
)

export interface Task {
  attachments?: Attachment[]
  category: Task.Category
  changeName?: string
  changeType?: Task.ChangeType
  created: { at: string }
  description: string
  id: string
  initialMessage?: string
  intercom: {
    contactId: string
    conversationId: string
  }
  jira: {
    id: string
    key: string
  }
  merchantId: string
  queryIndex: {
    merchantName: string
    salesforceAccountId: string
  }
  status: {
    at: string
    details?: string
    labels?: {
      admin: string
      customer: string
      jira: string
    }
    logs: Task.StatusLog[]
    type: Task.StatusType
  }
  title: string
  tags: string[]
  type: Task.TicketType
}

export interface FileAsset {
  assetId: string
  filename: string
  originalFilename: string
  url: string
}

export interface JiraTaskBody {
  category: string
  change_type: Task.ChangeType
  description: string
  change_name: string
  merchant_id: string
  type: Task.TicketType.TICKET
}

export interface InformationTaskBody {
  attachments: Array<{
    asset_id: string
    filename: string
    original_filename: string
  }>
  description: string
  category: Task.Category
  merchant_id: string
  title: string
  type: Task.TicketType.INFORMATION
}

export interface Attachment {
  filename: string
  originalFilename: string
  url: string
}
export namespace Task {
  export const enum Category {
    ADS = "ads",
    CLIENT_MANAGEMENT = "client_management",
    ONBOARDING = "onboarding",
    SEO = "seo",
    SITE = "site",
    SOCIAL = "social",
  }

  export const enum StatusType {
    BLOCKED = "blocked",
    CLIENT_FEEDBACK_SUPPLIED = "client_feedback_supplied",
    CLIENT_INFO_SUPPLIED = "client_info_supplied",
    COMPLETED = "completed",
    CREDENTIALS_REQUIRED = "credentials_required",
    DONE = "done",
    DONE_HIDDEN = "done_hidden",
    FEEDBACK_REQUIRED = "feedback_required",
    IN_PROGRESS = "in_progress",
    IN_PROGRESS_HIDDEN = "in_progress_hidden",
    INFORMATION_ONLY = "information",
    NOT_REQUIRED = "not_required",
    READY_FOR_CLIENT_REVIEW = "ready_for_client_review",
    READY_FOR_DEVELOPMENT = "ready_for_development",
    WAITING_FOR_SUPPORT = "waiting_for_support",
    WAITING_TO_START = "waiting_to_start",
  }

  export const enum ChangeType {
    CUSTOM_CHANGE = "custom",
    OPTIMIZATION = "optimization",
    INDEXING_ISSUES = "indexing_issues",
    CONTENT_GAPS = "content_gaps",
    GENERAL_RECOMMENDATIONS = "general_recommendations",
    ADD_USER_TO_GA = "add_user_to_ga",
    ADD_GST_NUMBER = "add_gst_number",
    CREDIT_CARD_FAILED = "credit_card_failed",
    ADD_ACCOUNT_VERIFICATION_REQUIRED = "add_account_verification_required",
    OPTIMIZATION_REPORT = "optimization_report",
    ONGOING_OPTIMIZATION = "ongoing_optimization",
    MONTHLY_DETAIL_REPORT = "monthly_detail_report",
    AUTOMATION_DISCONNECTED = "automation_disconnected",
    LOCAL_REVIEW_REPORTING = "local_review_reporting",
  }

  export interface StatusLog {
    at: string
    details?: string
    type: StatusType
  }

  export enum TicketType {
    INFORMATION = "information",
    TICKET = "ticket",
  }
}

export interface MainSettings {
  disableBlogSocial: boolean
}

import { isEmpty } from "lodash"

// eslint-disable-next-line @nx/enforce-module-boundaries
import { MerchantSettings, merchantSettingsService } from "@onelocal/frontend-dashboard/settings"
import { ProductTypes } from "@onelocal/shared/common"
import { DashboardContext } from "../types"

export const contextService = {
  async getMerchantContext( merchantId: string ) {
    const merchantSettings = await merchantSettingsService.getSettings( merchantId )

    const context: DashboardContext.MerchantContext = {
      contextType: DashboardContext.ContextType.MERCHANT,
      merchantId,
      products: [],
      rootMerchantId: merchantSettings.rootMerchantId,
      timezone: merchantSettings.timezone,
    }

    if(
      ( merchantSettings?.messages?.status === MerchantSettings.ProductStatus.LIVE || merchantSettings?.messages?.status === MerchantSettings.ProductStatus.TRIAL )
      && ! isEmpty( merchantSettings?.messages.channel?.phoneNumber )
    ) {
      context.products.push( ProductTypes.MESSAGES )
    }

    if( merchantSettings?.reviews.status === MerchantSettings.ProductStatus.LIVE ) {
      context.products.push( ProductTypes.REVIEWS )
    }

    if( merchantSettings?.seo.status === MerchantSettings.ProductStatus.LIVE ) {
      context.products.push( ProductTypes.SEO )
    }

    if( merchantSettings?.ads.status === MerchantSettings.ProductStatus.LIVE ) {
      context.products.push( ProductTypes.ADS )
    }

    if( merchantSettings?.site.status === MerchantSettings.ProductStatus.LIVE ) {
      context.products.push( ProductTypes.SITE )
    }

    if( merchantSettings?.referrals.status === MerchantSettings.ProductStatus.LIVE ) {
      context.products.push( ProductTypes.REFERRALS )
    }

    if( merchantSettings?.response?.status === MerchantSettings.ProductStatus.LIVE ) {
      context.products.push( ProductTypes.RESPONSE )
    }

    if( merchantSettings.visits?.status === MerchantSettings.ProductStatus.LIVE ) {
      context.products.push( ProductTypes.VISITS )
    }

    return context
  },
}

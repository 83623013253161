import React from "react"
import styled from "styled-components"

import { styleHelpers } from "../helpers/styleHelpers"
import { Link } from "."
import { StyledText } from "./Typography"

const StyledContainer = styled.div`
  margin: 10px 0;
`

const StyledLabel = styled( StyledText )`
  color: ${ styleHelpers.colors.darkGray };
  font-size: ${ styleHelpers.fonts.sizes.small };
  font-weight: ${ styleHelpers.fonts.weight.semiBold };
  margin-bottom: 3px;
`

const StyledValue = styled( StyledText )`
  color: ${ styleHelpers.colors.dark };
`

export interface ReadOnlyFieldProps {
  containerStyle?: React.CSSProperties
  isLink?: boolean
  label: string
  value: string | number | React.ReactNode
}

export const ReadOnlyField: React.FC<ReadOnlyFieldProps> = ( {
  containerStyle,
  isLink,
  label,
  value,
} ) => {
  return (
    <StyledContainer style={ containerStyle }>
      <StyledLabel>{ label }</StyledLabel>
      {
        isLink
          ? ( <Link href={ value as string }>{ value }</Link> )
          : ( <StyledValue>{ value }</StyledValue> )
      }

    </StyledContainer>
  )
}

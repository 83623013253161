import type { PaginatedItems, PaginatedQueryOptions } from "@onelocal/frontend/common"
import { apiHelper } from "@onelocal/frontend/common"
import type { ApiRequestProgressEvent } from "@onelocal/shared/common"
import type { FileAsset, InformationTaskBody, JiraTaskBody, Task } from "../types"
import { parseAssetFromApi, parseTaskFromApi } from "../types/api"

export namespace TaskService {
  export interface QueryFilter {
    q?: string
    type?: string
    status?: string | null
    category?: string | null
    merchantIds?: string
  }

  export interface UpdateApprovalStatusModel {
    approvalStatus: string
  }
}

export const taskService = {
  async queryTasks( query: TaskService.QueryFilter, options: PaginatedQueryOptions ): Promise<PaginatedItems<Task>> {
    return apiHelper.getPaginatedItems( {
      method: "GET",
      params: {
        status: query.status,
        category: query.category,
        q: query.q,
        merchant_ids: query.merchantIds,
        page: options.page,
        per_page: options.perPage,
        types: query.type,
      },
      parsingData: parseTaskFromApi,
      url: "/main/tasks",
    } )
  },
  async createBulkTasks( file: File ): Promise<void> {
    const formData = new FormData()
    formData.append( "file", file )

    return apiHelper.request( {
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      method: "POST",
      url: "/main/bulk-tasks",
    } )
  },
  async createTask( data: JiraTaskBody | InformationTaskBody ): Promise<Task> {
    return apiHelper.request( {
      data: data,
      parsingData: parseTaskFromApi,
      url: "/main/tasks",
      method: "POST",
    } )
  },
  async startConversation( taskId: string, merchantId: string, message: string ): Promise<Task> {
    return apiHelper.request( {
      data: { merchant_id: merchantId, message },
      method: "POST",
      parsingData: parseTaskFromApi,
      url: `/main/tasks/${ taskId }/start-conversation`,
    } )
  },
  async uploadAsset( merchantId: string, file: File, onUploadProgress?: ( progressEvent: ApiRequestProgressEvent ) => void ): Promise<FileAsset> {
    const formData = new FormData()
    formData.append( "file", file )
    formData.append( "merchant_id", merchantId )

    return apiHelper.request( {
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      method: "POST",
      parsingData: parseAssetFromApi,
      url: "/main/tasks/assets",
      onUploadProgress,
    } )
  },
}

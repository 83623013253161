import type { Option } from "@onelocal/frontend/common"
// eslint-disable-next-line @nx/enforce-module-boundaries
import type { ReviewsReview } from "@onelocal/frontend-dashboard/reviews"
import type { Name, PhoneNumber } from "@onelocal/shared/common"

export interface ConnectAssistantConversation {
  id: string
  assistant: ConnectAssistant
  callId: string
  flows: ConnectAssistantConversation.Flow[]
  fromPhoneNumber: ConnectPhoneNumberDetails
  isTesting: boolean
  merchantId: string
  smsMessages: ConnectAssistantConversation.SmsMessage[]
  status: ConnectAssistantConversation.Status
  voicemail?: {
    audioUrl?: string
    status: ConnectAssistantConversation.VoicemailStatus
    transcript?: ConnectAssistantConversation.Transcript
  }
}

export namespace ConnectAssistantConversation {
  export interface Flow {
    date: string
    flowId: string
    forward?: {
      to: string
    }
    inputType: FlowInputType
    name: string
  }

  export const enum FlowInputType {
    KEYPAD = "dtmf",
    NONE = "none",
    VOICE = "speech",
  }

  export const enum VoicemailStatus {
    /** When there is an issue with voicemail recording from twilio side */
    ABSENT = "absent",
    COMPLETED = "completed",
    IN_PROGRESS = "in-progress",
  }

  export interface SmsMessage {
    date: string
    error?: SmsMessageError
    // The previous implementation didn't sent the SMS from the platform and don't have a SMS id
    id: string | null
    flowId?: string
    messageId?: string
    text: string
  }

  export interface SmsMessageError {
    message?: string
    type: SmsMessageErrorType
  }

  export const enum SmsMessageErrorType {
    MONTHLY_LIMIT_EXCEEDED = "monthly_limit_exceeded",
    UNKNOWN = "unknown",

    // Copy from SmsMessage.StatusErrorType because the SmsMessage type is not exposed in the shared module
    INVALID_NUMBER = "invalid_number",
    UNAVAILABLE = "unavailable",
    VIOLATION = "violation",
  }

  export const enum Status {
    COMPLETED = "completed",
    IN_PROGRESS = "in_progress",
  }

  export interface Transcript {
    errorMessage?: string
    result?: string
    status: TranscriptStatus
  }

  export const enum TranscriptStatus {
    COMPLETED = "completed",
    FAILED = "failed",
    IN_PROGRESS = "in_progress",
  }
}

export interface ConnectAssistant {
  acknowledgeFlowChanges?: boolean
  fallback: {
    maxRetries: number
    prompt: ConnectAssistant.Response
  }
  flows: ConnectAssistant.Flow[]
  greeting: ConnectAssistant.Response
  emails: string[]
  id: string
  merchantId: string
  phoneNumberId: string
  /** @todo - make it required when #9984 is merged */
  prompt?: ConnectAssistant.Response
  voice: ConnectAssistant.Voice
	voicemail: ConnectAssistant.Response
  voicemailPhrase?: string | null
  whitelist: string[]
}

export namespace ConnectAssistant {
  export interface AudioClip {
    created: {
      at: string
    }
    id: string
    merchantId: string
    url: string
  }

  /** This must have either audio_url or text */
  export interface Response {
    audioUrl?: string
    text?: string
  }

  export type Flow = FlowAnswer | FlowForward | FlowSendSms

  export interface FlowBase {
    digit?: number
    enabled: boolean
    id: string
    name: string
    nextAction: ConnectAssistant.NextAction
    phrase: string | null
  }

  export const enum FlowType {
    ANSWER = "answer",
    FORWARD = "forward",
    SEND_SMS = "send_sms",
  }

  export interface FlowAnswer extends FlowBase {
    answer: ConnectAssistant.Response
    type: FlowType.ANSWER
  }

  export interface FlowForward extends FlowBase {
    answer?: ConnectAssistant.Response
    toNumber: string
    type: FlowType.FORWARD
  }

  export interface FlowSendSms extends FlowBase {
    confirmation: ConnectAssistant.Response
    prompt?: ConnectAssistant.Response
    smsText: string
    type: FlowType.SEND_SMS
  }

  export interface FlowTemplate extends Omit<FlowBase, "digit"> {
    answer?: {
      text: string
    }
    confirmation?: {
      text: string
    }
    prompt?: {
      text: string
    }
    smsText?: string
    type: FlowType
  }

  export interface FlowOrderUpdateModel {
    flow_ids: string[]
  }

  export interface FlowDeleteModel {
    flow_id: string
  }

  export interface NextAction {
    type: ConnectAssistant.NextActionType
  }

  export const enum NextActionType {
    HANGUP = "hangup",
    PROMPT = "prompt",
  }

  export const enum StaticFlow {
    FORWARD = "forward",
  }

  export const enum Step {
    ENTRY = "entry",
    FALLBACK = "fallback",
    GATHER_RESULT = "gather_result",
    HANGUP = "hangup",
    INBOUND = "inbound",
    VOICEMAIL = "voicemail",
  }

  export type AssistantFlowResponseProperties = Partial<
    Record<
      `${ "answer" | "confirmation" | "prompt" }${ "AudioId" | "AudioUrl" | "Text" }`,
      string | null
    >
  >

  export interface FlowModelBase extends AssistantFlowResponseProperties {
    digit?: number
    enabled: boolean
    id?: string
    name: string
    nextActionType: ConnectAssistant.NextActionType
    phrase: string | null
    smsText?: string
    toNumber?: string
    type: ConnectAssistant.FlowType
  }

  export interface FlowCreateModel extends FlowModelBase {
    id?: string
  }

  export interface FlowUpdateModel extends FlowModelBase {
    id: string
  }

  export type UpdateModelBase = Partial<
    Record<
      `${ "greeting" | "fallbackPrompt" | "prompt" | "voicemail" }${ "AudioId" | "AudioUrl" | "Text" }`,
      string | null
    >
  >

  export interface UpdateModel extends UpdateModelBase {
    acknowledgeFlowChanges?: boolean
    emails?: string[]
    fallbackMaxRetries?: number
    flows?: ConnectAssistant.Flow[]
    voice?: ConnectAssistant.Voice
    voicemailPhrase?: string | null
    whitelist?: string[]
  }

  export enum Voice {
    IVY = "Polly.Ivy-Neural",
    JOANNA = "Polly.Joanna-Neural",
    JOEY = "Polly.Joey-Neural",
    JUSTIN = "Polly.Justin-Neural",
    KENDRA = "Polly.Kendra-Neural",
    KIMBERLY = "Polly.Kimberly-Neural",
    MATTHEW = "Polly.Matthew-Neural",
    SALLI = "Polly.Salli-Neural",
  }
}

export interface ConnectConversation {
  id: string
  assignee?: {
    id: string
    ref: "employees"
  }
  blockInbound: boolean
  blockOutbound: boolean
  channelId: string
  channelType: "phone"
  created?: {
    at: string
    by: {
      id: string
      ref: "employees" | "merchants" | "customers"
    }
  }
  customer: {
    id: string
    email: string
    name?: {
      family: string
      given: string
    }
    phoneNumber: PhoneNumber
    // contactFields: ContactsContactFieldValue[]
  }
  // lastMessage?: MessagesMessage
  matchedCustomersCount: number
  merchantId: string
  muted: boolean
  readtime: {
    merchant: string
  }
  status: ConnectConversation.Status
  stats: {
    customer: {
      lastMessageAt: string
      unreadCount: number
      unrepliedCount: number
    }
    hasMessages: boolean
    merchant: {
      lastMessageAt: string
      unreadCount: number
      unrepliedCount: number
    }
  }
  tagIds: string[]
  type: string
}

export namespace ConnectConversation {
  export enum Status {
    CLOSED = "closed",
    OPEN = "open",
  }
}

export interface ConnectInbox {
  closedCount: number
  id: ConnectInbox.Type
  name: string
  openedCount: number
  totalCount: number
  unreadCount: number
  unrepliedCount: number
}

export namespace ConnectInbox {
  export enum Type {
    ALL = "all",
    UNASSIGNED = "unassigned",
    YOU = "you",
  }
}

export type ConnectCall = ConnectCallInbound | ConnectCallOutbound

export namespace ConnectCall {
  export enum Direction {
    INBOUND = "inbound",
    OUTBOUND = "outbound",
  }

  export interface PlatformTwilio {
    accountId: string
    callId: string
    status: PlatformTwilio.StatusType
    type: PlatformType.TWILIO
  }

  export namespace PlatformTwilio {
    export enum StatusType {
      BUSY = "busy",
      CANCELED = "canceled",
      COMPLETED = "completed",
      FAILED = "failed",
      IN_PROGRESS = "in-progress",
      NO_ANSWER = "no-answer",
      RINGING = "ringing",
    }
  }

  export enum PlatformType {
    TWILIO = "twilio",
  }

  export interface Status {
    at: string
    errorType?: string
    type: StatusType
  }

  export enum StatusType {
    BUSY = "busy",
    CANCELED = "canceled",
    COMPLETED = "completed",
    FAILED = "failed",
    IN_PROGRESS = "in_progress",
    NO_ANSWER = "no_answer",
    RINGING = "ringing",
    UNKNOWN = "unknown",
  }
}

export interface ConnectCallBase {
  callId: string
  channel: ConnectChannel
  customer: {
    id: string | null
    name: {
      display: string
      family: string
      given: string
    }
  } | null
  direction: ConnectCall.Direction
  duration: number
  from: string
  id: string
  merchantId: string
  to: string
  viewedAt: string | null
}

export interface ConnectCallInbound extends ConnectCallBase {
  assistantConversation?: ConnectAssistantConversation
  created: {
    at: string
  }
  direction: ConnectCall.Direction.INBOUND
  plannedSteps: ConnectCallInbound.Step[]
  steps: ConnectCallInbound.Step[]
  stats: {
    isMissedCall: boolean
  }
}

export namespace ConnectCallInbound {
  export enum StepType {
    ASSISTANT = "assistant",
    ASSISTANT_BUSINESS_HOUR = "assistant_business_hour",
    FORWARD = "forward",
    FORWARD_BUSINESS_HOUR = "forward_business_hour",
    RING_MEMBERS = "ring_members",
  }

  export type Step = StepAssistant | StepForward | StepRing

  export interface StepBase {
    at: string
    id: string
    type: StepType
  }

  export interface StepAssistant extends StepBase {
    type: StepType.ASSISTANT | StepType.ASSISTANT_BUSINESS_HOUR
    assistantConversationId: string | null
  }

  export interface StepForward extends StepBase {
    duration?: number
    platform?: ConnectCall.PlatformTwilio
    ringDuration: number | null
    status: ConnectCall.Status
    type: StepType.FORWARD | StepType.FORWARD_BUSINESS_HOUR
    to: string
  }

  export interface StepRing extends StepBase {
    accountIds: string[]
    answeredAccount?: {
      id: string
      name?: Name
    }
    duration?: number
    platform?: ConnectCall.PlatformTwilio
    ringDuration: number | null
    status: ConnectCall.Status
    type: StepType.RING_MEMBERS
  }
}

export interface ConnectCallOutbound extends ConnectCallBase {
  created: {
    at: string
    by: {
      id: string
      ref: "accounts"
      name?: Name
    }
  }
  direction: ConnectCall.Direction.OUTBOUND
  dial: {
    duration?: number
    platform?: ConnectCall.PlatformTwilio
    status: ConnectCall.Status
  } | null
}

export type ConnectCallSummary = ConnectCall & {
  assistantConversation?: ConnectAssistantConversation
}

export interface ConnectChannel {
  allowInternationalTexting?: boolean
  autoresponder: ConnectChannel.AutoResponder
  blockVoip?: boolean
  businessHours: ConnectChannel.BusinessHourPeriod[]
  id: string
  incomingCallSteps: ConnectChannel.IncomingCallStep[]
  merchantId: string
  phoneNumber?: ConnectPhoneNumberDetails
  phoneNumberId: string
  type: ConnectChannel.Type
}

export namespace ConnectChannel {
  export type BusinessHourAction = BusinessHourActionAssistant | BusinessHourActionForward

  export interface AutoResponder {
    missedCall?: string
    missedCallAfterHour?: string
    sms?: string
    smsAfterHour?: string
    widget?: string
    widgetAfterHour?: string
  }
  export interface BusinessHourActionAssistant {
    type: BusinessHourActionType.ASSISTANT
  }

  export interface BusinessHourActionForward {
    type: BusinessHourActionType.FORWARD
    phoneNumber: ConnectPhoneNumberDetails
    ringDuration?: number | null
  }

  export enum BusinessHourActionType {
    ASSISTANT = "assistant",
    FORWARD = "forward",
  }

  export enum BusinessHourPeriodDay {
    EVERYDAY = "everyday",
    WEEKEND = "weekend",
    WEEKDAYS = "weekdays",
    MONDAY = "monday",
    TUESDAY = "tuesday",
    WEDNESDAY = "wednesday",
    THURSDAY = "thursday",
    FRIDAY = "friday",
    SATURDAY = "saturday",
    SUNDAY = "sunday",
  }

  export interface BusinessHourPeriod {
    day: BusinessHourPeriodDay
    from: string
    id?: string
    to: string
  }

  export type IncomingCallStep = IncomingCallStepBusinessHours | IncomingCallStepCallForwarding | IncomingCallStepRing

  export interface IncomingCallStepBase {
    id?: string
    type: IncomingCallStepType
  }

  export interface IncomingCallStepBusinessHours extends IncomingCallStepBase {
    action?: BusinessHourAction
    enabled: boolean
    type: IncomingCallStepType.BUSINESS_HOURS
  }

  export interface IncomingCallStepCallForwarding extends IncomingCallStepBase {
    enabled: boolean
    phoneNumber?: ConnectPhoneNumberDetails
    ringDuration?: number | null
    type: IncomingCallStepType.CALL_FORWARDING
  }

  export interface IncomingCallStepRing extends IncomingCallStepBase {
    enabled: boolean
    steps?: RingStep[]
    type: IncomingCallStepType.RING
  }

  export enum IncomingCallStepType {
    BUSINESS_HOURS = "business_hours",
    CALL_FORWARDING = "call_forwarding",
    RING = "ring",
  }

  export interface RingStep {
    accountIds: string[]
    ringDuration: number | null
  }

  export const enum Type {
    PHONE = "phone",
  }

  export interface UpdateModel {
    allowInternationalTexting?: boolean
    autoresponder?: ConnectChannel.AutoResponder
    blockVoip?: boolean
    businessHours?: ConnectChannel.BusinessHourPeriod[]
    incomingCallSteps?: UpdateModel.IncomingCallStepModel[]
    phoneNumberId?: string
  }

  export namespace UpdateModel {
    export type IncomingCallStepModel = IncomingCallStepBusinessHoursModel | IncomingCallStepCallForwardingModel | IncomingCallStepRingModel

    interface IncomingCallStepBaseModel {
      id: string
      type: ConnectChannel.IncomingCallStepType
    }

    export interface IncomingCallStepBusinessHoursModel extends IncomingCallStepBaseModel {
      action?: (
        { type: BusinessHourActionType.ASSISTANT }
        | {
          type: BusinessHourActionType.FORWARD
          phoneNumber: string
          ringDuration?: number | null
        }
      )
      enabled: boolean
      type: ConnectChannel.IncomingCallStepType.BUSINESS_HOURS
    }

    export interface IncomingCallStepCallForwardingModel extends IncomingCallStepBaseModel {
      enabled: boolean
      phoneNumber?: string
      ringDuration?: number | null
      type: ConnectChannel.IncomingCallStepType.CALL_FORWARDING
    }

    export interface IncomingCallStepRingModel extends IncomingCallStepBaseModel {
      enabled: boolean
      steps?: ConnectChannel.RingStep[]
      type: ConnectChannel.IncomingCallStepType.RING
    }
  }

  export const enum ActionType {
    CHANNEL_UPDATE = "channel_update",
  }
}

export interface ConnectGenerateMessage {
  text: string
}

export interface ConnectMerchantSettings {
  features: Record<ConnectMerchantSettings.Features, boolean>
}

export namespace ConnectMerchantSettings {
  export enum Features {
    PHONE_CALL = "phone_call",
  }
}

export interface ConnectPhoneNumberDetails {
  carrierName: string | null
  countryCallingCode: number | null
  countryCode: string | null
  internationalFormat: string
  mobileCountryCode: string | null
  mobileNetworkCode: string | null
  nationalFormat: string
  type: string
  value: string
}

export interface ConnectUsageStats {
  date: string
  inboundCallCount: number
  inboundCallSecondsCount: number
  outboundCallCount: number
  outboundCallSecondsCount: number
  period: ConnectUsageStats.Period
}

export namespace ConnectUsageStats {
  export const enum Period {
    DAY = "day",
    MONTH = "month",
  }
}

export interface ConnectUsageStatsSummary {
  usageStats: ConnectUsageStats[]
}

export interface ConnectVoiceToken {
  expireAt: string
  value: string
}

export interface OutboundCallListenerParams {
  merchantId: string
  to: string
}

export interface PhoneActiveCall {
  connectCallId: string
  digits: string
  sid: string
  from: string
  isMuted: boolean
  state: PhoneActiveCallState
  startedAt?: string
  to?: string
}

export enum PhoneActiveCallState {
  CANCELLED = "cancelled",
  CONNECTED = "connected",
  CONNECTING = "connecting",
  DISCONNECTED = "disconnected",
  RECONNECTING = "reconnecting",
  RINGING = "ringing",
}

export interface UpdatedAudioDevices {
  currentInputDeviceId: string
  currentOutputDeviceId: string
  inputDevices: Array<Option<string>>
  outputDevices: Array<Option<string>>
}

export enum AssistantVoiceDemoUrl {
  IVY = "https://gatalabs.s3.amazonaws.com/local_connect/voice-demo/voice-ivy.mp3",
  JOANNA = "https://gatalabs.s3.amazonaws.com/local_connect/voice-demo/voice-joanna.mp3",
  JOEY = "https://gatalabs.s3.amazonaws.com/local_connect/voice-demo/voice-joey.mp3",
  JUSTIN = "https://gatalabs.s3.amazonaws.com/local_connect/voice-demo/voice-justin.mp3",
  KENDRA = "https://gatalabs.s3.amazonaws.com/local_connect/voice-demo/voice-kendra.mp3",
  KIMBERLY = "https://gatalabs.s3.amazonaws.com/local_connect/voice-demo/voice-kimberly.mp3",
  MATTHEW = "https://gatalabs.s3.amazonaws.com/local_connect/voice-demo/voice-matthew.mp3",
  SALLI = "https://gatalabs.s3.amazonaws.com/local_connect/voice-demo/voice-salli.mp3",
}

export type MessagesMessage = (
  MessagesMessage.MessageAction
| MessagesMessage.MessageAssign
| MessagesMessage.MessageCall
| MessagesMessage.MessageConnectCall
| MessagesMessage.MessageCustomerChanged
| MessagesMessage.MessageNote
| MessagesMessage.MessagePayment
| MessagesMessage.MessageReferralInviteSent
| MessagesMessage.MessageSurveyRequestPreviewSent
| MessagesMessage.MessageSurveyRequestSent
| MessagesMessage.MessageSurveyRequestResent
| MessagesMessage.MessageTag
| MessagesMessage.MessageText
| MessagesMessage.MessageTransferDestination
| MessagesMessage.MessageTransferOrigin
| MessagesMessage.MessageVisit
)

export namespace MessagesMessage {
  export enum Type {
    ASSIGN = "assign",
    BLOCK_INBOUND = "block_inbound",
    BLOCK_OUTBOUND = "block_outbound",
    CALL = "call",
    CALL_IN = "call_in",
    CALL_OUT = "call_out",
    CLOSE = "close",
    CUSTOMER_CHANGED = "customer_changed",
    MESSAGE_IN = "message_in",
    MESSAGE_OUT = "message_out",
    MESSAGE_OUT_AUTOMATED = "message_out_automated",
    MUTE = "mute",
    NOTE = "note",
    OPEN = "open",
    PAYMENT_LINK_SENT = "payment_link_sent",
    PAYMENT_RECEIPT_LINK_SENT = "payment_receipt_link_sent",
    REFERRAL_INVITE_SENT = "referral_invite_sent",
    SCHEDULED_MESSAGE = "scheduled_message",
    SURVEY_REQUEST_PREVIEW_SENT = "survey_request_preview_sent",
    SURVEY_REQUEST_REMINDER_SENT = "survey_request_reminder_sent",
    SURVEY_REQUEST_RESENT = "survey_request_resent",
    SURVEY_REQUEST_SENT = "survey_request_sent",
    TAG_ADDED = "tag_added",
    TAG_REMOVED = "tag_removed",
    TRANSFER_DESTINATION = "transfer_destination",
    TRANSFER_ORIGIN = "transfer_origin",
    UNBLOCK_INBOUND = "unblock_inbound",
    UNBLOCK_OUTBOUND = "unblock_outbound",
    UNMUTE = "unmute",
    VISIT_INVITE_SENT = "visit_invite_sent",
    VISIT_NOTIFIED = "visit_notified",
  }

  export interface MessageAction extends MessageBase {
    type: (
        Type.BLOCK_INBOUND
      | Type.BLOCK_OUTBOUND
      | Type.CLOSE
      | Type.MUTE
      | Type.OPEN
      | Type.UNBLOCK_INBOUND
      | Type.UNBLOCK_OUTBOUND
      | Type.UNMUTE
    )
  }

  export interface MessageAssign extends MessageBase {
    newAssignee: {
      ref: "employees"
      id: string
      name: string
    } | null
    oldAssignee: {
      ref: "employees"
      id: string
    } | null
    type: Type.ASSIGN
  }

  export interface MessageBase {
    channelId: string
    conversation?: {
      blockInbound: boolean
      blockOutbound: boolean
      customer: {
        email: string
        id: string
        name: {
          given: string
          family: string
        }
        phoneNumber: PhoneNumber
      }
      muted: boolean
      status: ConnectConversation.Status
    }
    conversationId: string
    created?: {
      at: string
      by?: {
        id: string
        ref: "employees" | "merchants" | "customers"
        name?: string
        apiV?: number
      }
      source?: "autoresponder" | "bulk" | "opt_in" | "opt_out" | "assistant_conversation"
    }
    customerIds: string[]
    id: string
    merchantId: string
    date: string
    type: Type
  }

  export interface MessageCall extends MessageBase {
    duration?: number
    type: Type.CALL
  }

  export interface MessageConnectCall extends MessageBase {
    connectCall: ConnectCall
    type: Type.CALL_IN | Type.CALL_OUT
  }

  export interface MessageCustomerChanged extends MessageBase {
    newCustomer?: {
      id: string
      name: {
        given: string
        family: string
      }
    }
    oldCustomer?: {
      id: string
      name: {
        given: string
        family: string
      }
    }
    type: Type.CUSTOMER_CHANGED
  }

  export interface MessageMedia {
    medium: MessageMediaStyle
    original: MessageMediaStyle
    previewData: string
    small: MessageMediaStyle
  }

  export interface MessageMediaStyle {
    size: {
      height: number
      width: number
    }
    url: string
  }

  export interface MessageNote extends MessageBase {
    text: string
    type: Type.NOTE
  }

  export interface MessagePayment extends MessageBase {
    paymentId: string
    text: string
    type: Type.PAYMENT_LINK_SENT | Type.PAYMENT_RECEIPT_LINK_SENT
  }

  export interface MessageReferralInviteSent extends MessageBase {
    type: Type.REFERRAL_INVITE_SENT
    text?: string
    referralInvite: MessagesReferralInviteSummary
  }

  export interface MessageSurveyRequestPreviewSent extends MessageBase {
    isReminder: boolean
    originalText?: string
    text?: string
    type: Type.SURVEY_REQUEST_PREVIEW_SENT
  }

  export interface MessageSurveyRequestSent extends MessageBase {
    medias?: MessageMedia[]
    surveyRequest: MessagesSurveyRequestSummary
    text?: string
    type: Type.SURVEY_REQUEST_SENT | Type.SURVEY_REQUEST_REMINDER_SENT
  }

  export interface MessageSurveyRequestResent extends MessageBase {
    originalText?: string
    text?: string
    type: Type.SURVEY_REQUEST_RESENT
  }

  export interface MessageTag extends MessageBase {
    conversationTagId: string
    type: Type.TAG_ADDED | Type.TAG_REMOVED
  }

  export interface MessageText extends MessageBase {
    error?: {
      code: string
      message: string
    }
    medias: MessageMedia[]
    platform?: MessageText.OneLocalPlatform | MessageText.TwilioPlatform
    status: {
      type: "queued" | "sent" | "delivered" | "error" | "unknown"
      errorType?: "invalid_number" | "unavailable" | "violation"
      detail?: string
    } | null
    text: string
    type: Type.MESSAGE_OUT | Type.MESSAGE_OUT_AUTOMATED | Type.MESSAGE_IN | Type.SCHEDULED_MESSAGE
  }

  export namespace MessageText {
    export interface OneLocalPlatform {
      location?: {
        text: string
      }
      source: "widget"
      type: "onelocal"
    }

    export interface TwilioPlatform {
      messageId: string
      segmentCount: number
      status: "accepted" | "queued" | "sending" | "sent,failed" | "delivered" | "undelivered" | "receiving" | "received"
      type: "twilio"
    }
  }

  export interface MessageTransferDestination extends MessageBase {
    originConversationId: string
    originMerchant: {
      id: string
      name: string
    }
    type: Type.TRANSFER_DESTINATION
  }

  export interface MessageTransferOrigin extends MessageBase {
    destinationConversationId: string
    destinationMerchant: {
      id: string
      name: string
    }
    type: Type.TRANSFER_ORIGIN
  }

  export interface MessageVisit extends MessageBase {
    text: string
    type: MessagesMessage.Type.VISIT_INVITE_SENT | MessagesMessage.Type.VISIT_NOTIFIED
    visitId: string
  }
}

export interface MessagesNotification {
  conversation?: ConnectConversation
  conversationId?: string
  created: {
    at: string
  }
  employeeIds: string[]
  id: string
  merchantId: string
  messages?: MessagesMessage[]
  mobileAccountIds: string[]
  mobile?: {
    body: string
    title: string
  }
  newMessageIds: string[]
  removedMessageIds: string[]
  text: string
  updatedMessageIds: string[]
}

export interface MessagesReferralInviteSummary {
  created: {
    at: string
  }
  id: string
  state: {
    code: string
    label: string
    at: string
  }
}

export interface MessagesSurveyRequestSummary {
  completedAt: string
  id: string
  responseId: string
  sentiment: ReviewsReview.Sentiment
  sentAt: string
  templateName: string
}

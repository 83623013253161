import React from "react"
import styled from "styled-components"

import { styleHelpers } from "../helpers/styleHelpers"
import { Loading } from "."

const LoadingOverlayContainer = styled.div`
  background-color: rgba( 255, 255, 255, 0.8);
  bottom: 0;
  color: ${ styleHelpers.colors.secondary };
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
`

const LoadingOverlayContainerInner = styled.div`
  align-items: center;
  color: ${ styleHelpers.colors.secondary };
  display: flex;
  height: 100%;
  justify-content: center;
`

const LoadingOverlayCenterSection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const LoadingText = styled.span`
  font-size: ${ styleHelpers.fonts.sizes.medium };
  font-weight: ${ styleHelpers.fonts.weight.semiBold };
  margin-top: 10px;
`

export interface LoadingOverlayProps {
  containerStyle?: React.CSSProperties
  loaderSize?: number
  maxTopPx?: number
  text?: string
}

export const LoadingOverlay: React.FC<LoadingOverlayProps> = React.memo( ( { containerStyle, maxTopPx, text, loaderSize } ) => (
  <LoadingOverlayContainer style={ containerStyle }>
    <LoadingOverlayContainerInner style={ { maxHeight: maxTopPx ? `${ 130 + ( 2 * maxTopPx ) }px` : undefined } }>
      <LoadingOverlayCenterSection>
        <Loading size={ loaderSize } />
        <LoadingText>{ text || "Loading..." }</LoadingText>
      </LoadingOverlayCenterSection>
    </LoadingOverlayContainerInner>
  </LoadingOverlayContainer>
) )

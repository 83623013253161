import React from "react"
import { FormProvider } from "react-hook-form"
import styled from "styled-components"

import type { SelectOption } from "@onelocal/frontend-web/common"
import { Grid, GridItem, StyledFormSectionTitle } from "@onelocal/frontend-web/common"
import { validationHelpers } from "@onelocal/shared/common"
import { useOnboardingForm } from "../../hooks/useOnboardingForm"
import { OnboardingForm } from "../../types"
import { FIELD_LABELS, OFFERS_OPTIONS, PACKAGE_NAMES } from "./constants"
import { OnboardingFormButtons, OnboardingFormDisclaimerLabel, OnboardingFormFieldCheckBox, OnboardingFormFieldDate, OnboardingFormFieldSelect, OnboardingFormFieldText, OnboardingFormNotes, OnboardingFormSaving } from "./OnboardingFormFields"
import { OnboardingFormStepSyncForm } from "./OnboardingFormStepSyncForm"

const packagesOptions: Array<SelectOption<string>> = [
  OnboardingForm.Package.ENGAGE,
  OnboardingForm.Package.ENGAGE_ADS,
  OnboardingForm.Package.COMPETE,
  OnboardingForm.Package.COMPETE_ADS,
  OnboardingForm.Package.DOMINATE,
  OnboardingForm.Package.DOMINATE_ADS,
  OnboardingForm.Package.SUMMIT,
  OnboardingForm.Package.SUMMIT_ADS,
].map( ( packageType ) => ( { label: PACKAGE_NAMES[ packageType ], value: packageType } ) )

const StyledFieldContainer = styled.div`
  margin: 20px 0;
`

const StyledAdditionalProductNote = styled.div`
  margin: 10px 0 20px 0;
`

type FormValues = {
  [ OnboardingForm.Field.ADDITIONAL_TOOL_BOOKING_ENABLED ]: boolean
  [ OnboardingForm.Field.ADDITIONAL_TOOL_BOOKING_NOTES ]: string
  [ OnboardingForm.Field.ADDITIONAL_TOOL_CHECKIN_ENABLED ]: boolean
  [ OnboardingForm.Field.ADDITIONAL_TOOL_CHECKIN_NOTES ]: string
  [ OnboardingForm.Field.ADDITIONAL_TOOL_CONTACTS_ENABLED ]: boolean
  [ OnboardingForm.Field.ADDITIONAL_TOOL_CONTACTS_NOTES ]: string
  [ OnboardingForm.Field.ADDITIONAL_TOOL_LOCAL_SITE_ENABLED ]: boolean
  [ OnboardingForm.Field.ADDITIONAL_TOOL_LOCAL_SITE_NOTES ]: string
  [ OnboardingForm.Field.ADDITIONAL_TOOL_MESSAGES_ENABLED ]: boolean
  [ OnboardingForm.Field.ADDITIONAL_TOOL_MESSAGES_NOTES ]: string
  [ OnboardingForm.Field.ADDITIONAL_TOOL_PAYMENT_ENABLED ]: boolean
  [ OnboardingForm.Field.ADDITIONAL_TOOL_PAYMENT_NOTES ]: string
  [ OnboardingForm.Field.ADDITIONAL_TOOL_PHONE_ENABLED ]: boolean
  [ OnboardingForm.Field.ADDITIONAL_TOOL_PHONE_NOTES ]: string
  [ OnboardingForm.Field.ADDITIONAL_TOOL_REFERRALS_ENABLED ]: boolean
  [ OnboardingForm.Field.ADDITIONAL_TOOL_REFERRALS_NOTES ]: string
  [ OnboardingForm.Field.ADDITIONAL_TOOL_REVIEWS_ENABLED ]: boolean
  [ OnboardingForm.Field.ADDITIONAL_TOOL_REVIEWS_NOTES ]: string
  [ OnboardingForm.Field.EXTERNAL_BOOKING_SYSTEM ]: string
  [ OnboardingForm.Field.KICKOFF_DATE ]: string
  [ OnboardingForm.Field.NOTES_PRODUCT ]: string
  [ OnboardingForm.Field.OFFERS ]: string
  [ OnboardingForm.Field.PACKAGE_PURCHASED ]: string
  [ OnboardingForm.Field.SOCIAL_ADD_ON ]: string
}

type AdditionalTool = {
  enabledField: keyof FormValues
  helpText?: string
  name: string
  noteField: keyof FormValues
}

const additionalProducts = [
  {
    name: "Contacts",
    enabledField: OnboardingForm.Field.ADDITIONAL_TOOL_CONTACTS_ENABLED,
    noteField: OnboardingForm.Field.ADDITIONAL_TOOL_CONTACTS_NOTES,
    helpText: "Will the client want to upload contacts into the database, and begin sending promotional text messages immediately?  If so, what type of promotion?  Any feedback here is helpful.",
  },
  {
    name: "Messages",
    enabledField: OnboardingForm.Field.ADDITIONAL_TOOL_MESSAGES_ENABLED,
    noteField: OnboardingForm.Field.ADDITIONAL_TOOL_MESSAGES_NOTES,
    helpText: "Messages will be enabled on the website. Any specific message / tags / templates desired?",
  },
  {
    name: "Reviews",
    enabledField: OnboardingForm.Field.ADDITIONAL_TOOL_REVIEWS_ENABLED,
    noteField: OnboardingForm.Field.ADDITIONAL_TOOL_REVIEWS_NOTES,
    helpText: "Google and Facebook? Any other platforms required in reviews system?",
  },
  {
    name: "Referral",
    enabledField: OnboardingForm.Field.ADDITIONAL_TOOL_REFERRALS_ENABLED,
    noteField: OnboardingForm.Field.ADDITIONAL_TOOL_REFERRALS_NOTES,
  },
  {
    name: "Booking",
    enabledField: OnboardingForm.Field.ADDITIONAL_TOOL_BOOKING_ENABLED,
    noteField: OnboardingForm.Field.ADDITIONAL_TOOL_BOOKING_NOTES,
  },
  {
    name: "Phone",
    enabledField: OnboardingForm.Field.ADDITIONAL_TOOL_PHONE_ENABLED,
    noteField: OnboardingForm.Field.ADDITIONAL_TOOL_PHONE_NOTES,
  },
  {
    name: "Payment",
    enabledField: OnboardingForm.Field.ADDITIONAL_TOOL_PAYMENT_ENABLED,
    noteField: OnboardingForm.Field.ADDITIONAL_TOOL_PAYMENT_NOTES,
  },
  {
    name: "Check-In",
    enabledField: OnboardingForm.Field.ADDITIONAL_TOOL_CHECKIN_ENABLED,
    noteField: OnboardingForm.Field.ADDITIONAL_TOOL_CHECKIN_NOTES,
  },
] as AdditionalTool[]

const localSiteAdditionalProduct: AdditionalTool = {
  name: "LocalSite",
  enabledField: OnboardingForm.Field.ADDITIONAL_TOOL_LOCAL_SITE_ENABLED,
  noteField: OnboardingForm.Field.ADDITIONAL_TOOL_LOCAL_SITE_NOTES,
}

export interface OnboardingFormStepProductProps {
}

export const OnboardingFormStepProduct: React.FC<OnboardingFormStepProductProps> = () => {
  const {
    formMethods,
    isSaving,
    onChangeHandlers,
    onSubmit,
    onSyncValuesFromSalesforce,
  } = useOnboardingForm<FormValues>( {
    defaultValues: {},
    fieldNames: [
      OnboardingForm.Field.ADDITIONAL_TOOL_BOOKING_ENABLED,
      OnboardingForm.Field.ADDITIONAL_TOOL_BOOKING_NOTES,
      OnboardingForm.Field.ADDITIONAL_TOOL_CHECKIN_ENABLED,
      OnboardingForm.Field.ADDITIONAL_TOOL_CHECKIN_NOTES,
      OnboardingForm.Field.ADDITIONAL_TOOL_CONTACTS_ENABLED,
      OnboardingForm.Field.ADDITIONAL_TOOL_CONTACTS_NOTES,
      OnboardingForm.Field.ADDITIONAL_TOOL_LOCAL_SITE_ENABLED,
      OnboardingForm.Field.ADDITIONAL_TOOL_LOCAL_SITE_NOTES,
      OnboardingForm.Field.ADDITIONAL_TOOL_MESSAGES_ENABLED,
      OnboardingForm.Field.ADDITIONAL_TOOL_MESSAGES_NOTES,
      OnboardingForm.Field.ADDITIONAL_TOOL_PAYMENT_ENABLED,
      OnboardingForm.Field.ADDITIONAL_TOOL_PAYMENT_NOTES,
      OnboardingForm.Field.ADDITIONAL_TOOL_PHONE_ENABLED,
      OnboardingForm.Field.ADDITIONAL_TOOL_PHONE_NOTES,
      OnboardingForm.Field.ADDITIONAL_TOOL_REFERRALS_ENABLED,
      OnboardingForm.Field.ADDITIONAL_TOOL_REFERRALS_NOTES,
      OnboardingForm.Field.ADDITIONAL_TOOL_REVIEWS_ENABLED,
      OnboardingForm.Field.ADDITIONAL_TOOL_REVIEWS_NOTES,
      OnboardingForm.Field.EXTERNAL_BOOKING_SYSTEM,
      OnboardingForm.Field.KICKOFF_DATE,
      OnboardingForm.Field.NOTES_PRODUCT,
      OnboardingForm.Field.OFFERS,
      OnboardingForm.Field.PACKAGE_PURCHASED,
      OnboardingForm.Field.SOCIAL_ADD_ON,
    ],
    step: OnboardingForm.Step.PRODUCT,
  } )

  const { watch } = formMethods

  const packageSelected = watch( OnboardingForm.Field.PACKAGE_PURCHASED )
  const isEngagePackage = packageSelected?.startsWith( "engage" )

  return (
    <div>
      <OnboardingFormStepSyncForm syncForm={ onSyncValuesFromSalesforce } />
      <OnboardingFormSaving isSaving={ isSaving } />
      <FormProvider { ...formMethods }>
        <form onSubmit={ onSubmit } noValidate>
          <StyledFormSectionTitle>Product</StyledFormSectionTitle>
          <StyledFieldContainer>
            <Grid spacing={ 4 }>
              <GridItem xs={ 6 }>
                <OnboardingFormFieldSelect
                  fieldName={ OnboardingForm.Field.PACKAGE_PURCHASED }
                  isRequired={ true }
                  label={ FIELD_LABELS[ OnboardingForm.Field.PACKAGE_PURCHASED ] }
                  onChange={ onChangeHandlers[ OnboardingForm.Field.PACKAGE_PURCHASED ] }
                  options={ packagesOptions }
                />
              </GridItem>
              <GridItem xs={ 6 }>
                <OnboardingFormFieldDate
                  fieldName={ OnboardingForm.Field.KICKOFF_DATE }
                  isRequired={ true }
                  label={ FIELD_LABELS[ OnboardingForm.Field.KICKOFF_DATE ] }
                  onChange={ onChangeHandlers[ OnboardingForm.Field.KICKOFF_DATE ] }
                />
              </GridItem>
              <GridItem xs={ 12 }>
                <OnboardingFormFieldCheckBox
                  fieldName={ OnboardingForm.Field.SOCIAL_ADD_ON }
                  label={ FIELD_LABELS[ OnboardingForm.Field.SOCIAL_ADD_ON ] }
                  onChange={ onChangeHandlers[ OnboardingForm.Field.SOCIAL_ADD_ON ] as unknown as ( value: boolean ) => void }
                  helpText={ "Note: Social add on is purchased to offer clients additional content on top of their baseline package. Choosing this option means that the delivery of their social offering will change and will include additional blog and/or social posts, depending on the package purchased" }
                />
              </GridItem>
            </Grid>
          </StyledFieldContainer>

          <StyledFormSectionTitle>Additional Tools</StyledFormSectionTitle>
          <StyledFieldContainer>
            {
              [ ...additionalProducts, ...( ! isEngagePackage ? [ localSiteAdditionalProduct ] : [] ) ].map( ( additionalProduct ) => (
                <div key={ additionalProduct.name }>
                  <OnboardingFormFieldCheckBox
                    fieldName={ additionalProduct.enabledField }
                    label={ additionalProduct.name }
                    onChange={ onChangeHandlers[ additionalProduct.enabledField ] as unknown as ( value: boolean ) => void }
                  />
                  {
                    watch( additionalProduct.enabledField ) && (
                      <StyledAdditionalProductNote>
                        <OnboardingFormFieldText
                          fieldName={ additionalProduct.noteField }
                          isRequired={ true }
                          label="Notes for Delivery"
                          onChange={ onChangeHandlers[ additionalProduct.noteField ] }
                          helperText={ additionalProduct.helpText }
                          multiline={ true }
                        />
                      </StyledAdditionalProductNote>
                    )
                  }
                  {
                    additionalProduct.enabledField === OnboardingForm.Field.ADDITIONAL_TOOL_BOOKING_ENABLED && ! watch( additionalProduct.enabledField ) && (
                      <StyledAdditionalProductNote>
                        <OnboardingFormFieldText
                          fieldName={ OnboardingForm.Field.EXTERNAL_BOOKING_SYSTEM }
                          helperText="Please enter url of client's external booking system"
                          isRequired={ false }
                          label="External Booking System"
                          onChange={ onChangeHandlers[ OnboardingForm.Field.EXTERNAL_BOOKING_SYSTEM ] }
                          validate={ ( value ) => {
                            if( ! validationHelpers.validateUrl( value ) ) {
                              return "The URL is invalid. Please enter URL in format: https://www.google.com"
                            }

                            return undefined
                          } }
                        />
                      </StyledAdditionalProductNote>
                    )
                  }
                  {
                    additionalProduct.enabledField === OnboardingForm.Field.ADDITIONAL_TOOL_REFERRALS_ENABLED && watch( additionalProduct.enabledField ) && (
                      <StyledAdditionalProductNote>
                        <OnboardingFormFieldSelect
                          fieldName={ OnboardingForm.Field.OFFERS }
                          isRequired={ true }
                          label={ FIELD_LABELS[ OnboardingForm.Field.OFFERS ] }
                          multiple={ true }
                          onChange={ onChangeHandlers[ OnboardingForm.Field.OFFERS ] }
                          options={ OFFERS_OPTIONS }
                        />
                      </StyledAdditionalProductNote>
                    )
                  }
                </div>
              ) )
            }
          </StyledFieldContainer>

          <OnboardingFormNotes
            step={ OnboardingForm.Step.PRODUCT }
            onChange={ onChangeHandlers[ OnboardingForm.Field.NOTES_PRODUCT ] }
          />

          <OnboardingFormDisclaimerLabel />
          <OnboardingFormButtons disabled={ isSaving } />
        </form>
      </FormProvider>
    </div>
  )
}

import { apiHelper } from "@onelocal/frontend/common"
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AnalyticHelper, mixpanelHelpers } from "@onelocal/frontend-dashboard/common"
import { ProductNames } from "@onelocal/shared/common"

export namespace SiteSettingsService {
  export interface UpdateModel {
    emailEnabled: boolean
    emailHtml?: string
    emailSubject?: string
    smsEnabled: boolean
    smsText?: string
  }
}

export const siteSettingsService = {
  async getSiteLoginUrl( merchantId: string ) {
    return apiHelper.request( {
      method: "POST",
      parsingData: ( loginUrl: string ) => loginUrl,
      url: `/merchants/${ merchantId }/first_impression/site/login`,
    } )
  },
  async openSiteLoginUrl( merchantId: string, location: string ) {
    const loginUrl = await siteSettingsService.getSiteLoginUrl( merchantId )
    mixpanelHelpers.trackEvent( AnalyticHelper.EventName.CTA_CLICKED, { Name: "Website", Location: location, Product: ProductNames.LOCAL_SITE } )
    window.open( loginUrl, "_blank" )
  },
  async updateById( merchantId: string, model: SiteSettingsService.UpdateModel ) {
    return apiHelper.request( {
      method: "PUT",
      url: `/merchants/${ merchantId }/first_impression/customer_notification_settings`,
      data: {
        email: {
          enabled: model.emailEnabled,
          html: model.emailHtml,
          subject: model.emailSubject,
        },
        sms: {
          enabled: model.smsEnabled,
          text: model.smsText,
        },
      },
    } )
  },
}

import ClearIcon from "@mui/icons-material/Clear"
import SearchIcon from "@mui/icons-material/Search"
// eslint-disable-next-line no-restricted-imports
import { IconButton, InputBase } from "@mui/material"
import React, { useCallback, useImperativeHandle, useRef } from "react"
import styled from "styled-components"

import { styleHelpers } from "../helpers"

export const enum SearchBarVariant {
  PLAIN = "plain",
}

const StyledContainer = styled.div<{ $variant?: SearchBarVariant }>`
   align-items: center;
   display: flex;

  ${ ( props ) => {
    switch( props.$variant ) {
      case SearchBarVariant.PLAIN:
        return ""
      default:
        return (
          `
          border-radius: 20px;
          border: 2px solid #eee;
          `
        )
    }
  } }


  font-size: 14px;
  height: 40px;
`

const StyledClearIcon = styled( ClearIcon )`
  color: ${ styleHelpers.colors.lighterDarkGray };
  font-size: 24px;
  padding: 0;
`

const StyledSearchIcon = styled( SearchIcon )`
  color: ${ styleHelpers.colors.lighterDarkGray };
  font-size: 24px;
  padding: 0;
`

export interface SearchBarProps {
  errorMessage?: string
  onChange( value: string ): void
  placeholder: string
  style?: React.CSSProperties
  value?: string
  variant?: SearchBarVariant
}

export interface SearchBarRef {
  focus(): void
}

export const SearchBar = React.forwardRef<SearchBarRef, SearchBarProps>( (
  {
    errorMessage,
    onChange,
    placeholder,
    style,
    variant,
    ...otherProps
  },
  ref,
) => {
  const inputRef = useRef<HTMLInputElement | null>( null )

  const focusInput = useCallback( () => {
    if( inputRef.current ) {
      inputRef.current.focus()
    }
  }, [ inputRef ] )

  useImperativeHandle( ref, () => ( {
    focus: focusInput,
  } ) )

  const onClear = useCallback( () => {
    if( inputRef.current ) {
      inputRef.current.value = ""
      inputRef.current.focus()
      onChange( "" )
    }
  }, [ inputRef, onChange ] )

  const handleChange = useCallback( ( event: React.ChangeEvent<HTMLInputElement> ) => {
    onChange( event.target.value )
  }, [ onChange ] )

  return (
    <StyledContainer $variant={ variant } style={ style }>
      <IconButton onClick={ focusInput }>
        <StyledSearchIcon />
      </IconButton>
      <InputBase
        error={ errorMessage !== undefined }
        inputRef={ inputRef }
        onChange={ handleChange }
        placeholder={ placeholder }
        sx={ {
          "&.MuiInputBase-root": {
            flex: 1,
          },
          ".MuiInputBase-input": {
            fontSize: "14px",
            margin: "0px",

            "&::placeholder": {
              fontSize: "14px",
            },
          },
        } }
        { ...otherProps }
      />
      {
        inputRef.current?.value && (
          <IconButton onClick={ onClear }>
            <StyledClearIcon />
          </IconButton>
        )
      }
    </StyledContainer>
  )
} )

SearchBar.displayName = "SearchBar"

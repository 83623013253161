import type { SnakeCasedPropertiesDeep } from "type-fest"

import { utilHelpers } from "@onelocal/shared/common"
import type { AuthInternalAccount } from "."

export type ApiAuthInternalAccount = SnakeCasedPropertiesDeep<AuthInternalAccount>

export function parseAuthInternalAccountFromApi( apiAuthInternalAccount: ApiAuthInternalAccount ) {
  const internalAccount: AuthInternalAccount = {
    deletedAt: apiAuthInternalAccount.deleted_at,
    disabledAt: apiAuthInternalAccount.disabled_at,
    email: apiAuthInternalAccount.email,
    id: apiAuthInternalAccount.id,
    lastActiveAt: apiAuthInternalAccount.last_active_at,
    name: apiAuthInternalAccount.name,
    permissions: apiAuthInternalAccount.permissions,
    query_index: apiAuthInternalAccount.query_index,
    role: apiAuthInternalAccount.role,
    text_index: apiAuthInternalAccount.text_index,
    username: apiAuthInternalAccount.username,
    salesforce_id: apiAuthInternalAccount.salesforce_id,
    sessions: utilHelpers.toCamelCase( apiAuthInternalAccount.sessions ),
  }

  return internalAccount
}

// eslint-disable-next-line no-restricted-imports
import { alpha, Button as MuiButton, darken } from "@mui/material"
import React, { useMemo } from "react"
import styled from "styled-components"

import { utilHelpers } from "@onelocal/shared/common"
import { styleHelpers } from "../helpers/styleHelpers"

interface StyledButtonProps extends Omit<React.ComponentProps<typeof MuiButton>, "color"> {
  readonly colors: StyledButtonColors
}

const StyledButton = styled( MuiButton )<StyledButtonProps>`
  background-color: ${ props => props.colors.background };
  border: ${ props => props.colors.border };
  color: ${ props => props.colors.color };
  padding: 6px 16px;
  text-transform: capitalize;

  &:hover {
    background-color: ${ props => props.colors.backgroundHover };
    border: ${ props => props.colors.borderHover };
  }

  &.MuiButton-outlined:disabled {
    border: 2px solid rgba(0, 0, 0, 0.12);
  }

  &:disabled {
    cursor: not-allowed;
    pointer-events: visible;
  }
`

export type ButtonProps = Pick<StyledButtonProps, "children"|"className"|"onClick"|"style"|"type"> & {
  color?: ButtonColor
  disabled?: boolean
  fullWidth?: boolean
  href?: string
  icon?: React.ReactNode
  size?: ButtonSize
  target?: string
  variant?: ButtonVariant
}

export type ButtonColor = "primary" | "default" | "danger" | "secondary" | "dismiss" | "orange"
export type ButtonSize = "small" | "medium" | "large"
export type ButtonVariant = "contained" | "outlined" | "text"

export const Button: React.FC<ButtonProps> = ( {
  color,
  icon,
  variant,
  ...otherProps
} ) => {
  const colors = useMemo( () => getColors( {
    color: color || "primary",
    variant: variant || "contained",
  } ), [ color, variant ] )

  return (
    <StyledButton
      disableTouchRipple
      colors={ colors }
      startIcon={ icon }
      variant={ variant }
      { ...otherProps }
    />
  )
}

interface StyledButtonColors {
  background?: string
  backgroundHover?: string
  border?: string
  borderHover?: string
  color?: string
  colorHover?: string
}

function getColors( { color, variant }: { color: ButtonColor, variant: ButtonVariant } ): StyledButtonColors {
  switch( variant ) {
    case "contained":
      switch( color ) {
        case "danger":
          return {
            background: styleHelpers.colors.danger,
            backgroundHover: darken( styleHelpers.colors.danger, 0.2 ),
            color: "#fff",
          }
        case "default":
          return {
            background: styleHelpers.colors.default,
            backgroundHover: darken( styleHelpers.colors.default, 0.2 ),
            color: "#fff",
          }
        case "dismiss":
          return {
            background: styleHelpers.colors.darkGray,
            backgroundHover: darken( styleHelpers.colors.darkGray, 0.2 ),
            color: "#fff",
          }
        case "primary":
          return {
            background: styleHelpers.colors.primary,
            backgroundHover: styleHelpers.colors.primaryLight,
            color: "#fff",
          }
        case "orange":
          return {
            background: styleHelpers.colors.orangeLight,
            backgroundHover: darken( styleHelpers.colors.orangeLight, 0.2 ),
            color: styleHelpers.colors.primary,
          }
        case "secondary":
          return {
            background: styleHelpers.colors.secondary,
            backgroundHover: darken( styleHelpers.colors.secondary, 0.2 ),
            color: "#fff",
          }
        default:
          utilHelpers.assertNever( color )
          throw new Error( `Unsupported color: ${ color }` )
      }
    case "outlined":
      switch( color ) {
        case "danger":
          return {
            backgroundHover: alpha( styleHelpers.colors.danger, 0.08 ),
            border: `2px solid ${ alpha( styleHelpers.colors.danger, 0.75 ) }`,
            borderHover: `2px solid ${ styleHelpers.colors.danger }`,
            color: styleHelpers.colors.danger,
          }
        case "default":
          return {
            backgroundHover: alpha( styleHelpers.colors.default, 0.08 ),
            border: `2px solid ${ alpha( styleHelpers.colors.default, 0.75 ) }`,
            borderHover: `2px solid ${ styleHelpers.colors.default }`,
            color: styleHelpers.colors.default,
          }
        case "orange":
          return {
            background: styleHelpers.colors.orangeLight,
            border: `2px solid ${ alpha( styleHelpers.colors.orangeLight, 0.75 ) }`,
            backgroundHover: darken( styleHelpers.colors.orangeLight, 0.2 ),
            color: styleHelpers.colors.primary,
          }
        case "dismiss":
          return {
            backgroundHover: alpha( styleHelpers.colors.darkGray, 0.08 ),
            border: `2px solid ${ alpha( styleHelpers.colors.darkGray, 0.75 ) }`,
            borderHover: `2px solid ${ styleHelpers.colors.darkGray }`,
            color: styleHelpers.colors.darkGray,
          }
        case "primary":
          return {
            border: `2px solid ${ alpha( styleHelpers.colors.primary, 0.75 ) }`,
            borderHover: `2px solid ${ styleHelpers.colors.primary }`,
            backgroundHover: alpha( styleHelpers.colors.primary, 0.08 ),
            color: styleHelpers.colors.primary,
          }
        case "secondary":
          return {
            backgroundHover: alpha( styleHelpers.colors.secondary, 0.08 ),
            border: `2px solid ${ alpha( styleHelpers.colors.secondary, 0.75 ) }`,
            borderHover: `2px solid ${ styleHelpers.colors.secondary }`,
            color: styleHelpers.colors.secondary,
          }
        default:
          utilHelpers.assertNever( color )
          throw new Error( `Unsupported color: ${ color }` )
      }
    case "text":
      switch( color ) {
        case "danger":
          return {
            backgroundHover: alpha( styleHelpers.colors.danger, 0.05 ),
            color: styleHelpers.colors.danger,
          }
        case "default":
          return {
            backgroundHover: alpha( styleHelpers.colors.default, 0.05 ),
            color: styleHelpers.colors.default,
          }
        case "dismiss":
          return {
            backgroundHover: alpha( styleHelpers.colors.darkGray, 0.05 ),
            color: styleHelpers.colors.darkGray,
          }
        case "primary":
          return {
            backgroundHover: alpha( styleHelpers.colors.primary, 0.05 ),
            color: styleHelpers.colors.primary,
          }
        case "orange":
          return {
            backgroundHover: darken( styleHelpers.colors.orangeLight, 0.2 ),
            color: styleHelpers.colors.primary,
          }
        case "secondary":
          return {
            backgroundHover: alpha( styleHelpers.colors.secondary, 0.05 ),
            color: styleHelpers.colors.secondary,
          }
        default:
          utilHelpers.assertNever( color )
          throw new Error( `Unsupported color: ${ color }` )
      }
    default:
      utilHelpers.assertNever( variant )
      throw new Error( `Unsupported variant: ${ variant }` )
  }
}

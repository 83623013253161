import EventEmitter from "eventemitter3"

export const eventEmitterHelpers = {
  eventEmitter: new EventEmitter(),

  async emit<T>( eventName: string, payload?: T ) {
    eventEmitterHelpers.eventEmitter.emit( eventName, payload )
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async addEventListener<T>( type: string, handler: ( eventDetails: T ) => void, context?: any ) {
    eventEmitterHelpers.eventEmitter.addListener( type, handler, context )
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async addEventListenerOnce<T>( type: string, handler: ( eventDetails: T ) => void, context?: any ) {
    eventEmitterHelpers.eventEmitter.once( type, handler, context )
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async removeListener<T>( type: string, handler: ( eventDetails: T ) => void, context?: any ) {
    eventEmitterHelpers.eventEmitter.removeListener( type, handler, context )
  },
}

import type { SnakeCasedPropertiesDeep } from "type-fest"

import { utilHelpers } from "@onelocal/shared/common"
import type { OnboardingForm, OnboardingOpportunity, OnboardingOpportunityOwner } from "."

export type ApiOnboardingForm = SnakeCasedPropertiesDeep<OnboardingForm>
export type ApiOnboardingFormStatus = SnakeCasedPropertiesDeep<OnboardingForm.FormStatus>

export function parseOnboardingFormFromApi( apiOnboardingForm: ApiOnboardingForm ) {
  const onboardingForm: OnboardingForm = {
    created: {
      at: apiOnboardingForm.created.at,
    },
    currentStep: apiOnboardingForm.current_step,
    // fields: apiOnboardingForm.fields,
    fields: ( Object.keys( apiOnboardingForm.fields ) as OnboardingForm.Field[] ).reduce(
      ( result, value ) => {
        if( apiOnboardingForm.fields[ value ] ) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          result[ value ] = utilHelpers.toCamelCase( apiOnboardingForm.fields[ value ]! as any )
        }
        return result
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {} as Record<OnboardingForm.Field, any>,
    ),
    generated: {
      fields: apiOnboardingForm.generated.fields,
      googlePlace: (
        apiOnboardingForm.generated.google_place
          ? utilHelpers.toCamelCase( apiOnboardingForm.generated.google_place )
          : undefined
      ),
      pageQuestions: apiOnboardingForm.generated.page_questions,
      serviceQuestions: apiOnboardingForm.generated.service_questions,
      services: utilHelpers.toCamelCase( apiOnboardingForm.generated.services ),
      siteItems: utilHelpers.toCamelCase( apiOnboardingForm.generated.site_items ),
    },
    id: apiOnboardingForm.id,
    merchantId: apiOnboardingForm.merchant_id,
    status: apiOnboardingForm.status,
    preventEditing: apiOnboardingForm.prevent_editing,
    validation: {
      fields: ( Object.keys( apiOnboardingForm.validation.fields ) as OnboardingForm.Field[] ).reduce(
        ( result, value ) => {
          if( apiOnboardingForm.validation.fields[ value ] ) {
            result[ value ] = utilHelpers.toCamelCase( apiOnboardingForm.validation.fields[ value ]! )
          }
          return result
        },
        {} as Record<OnboardingForm.Field, OnboardingForm.ValidationFieldResult>,
      ),
      steps: ( Object.keys( apiOnboardingForm.validation.steps || {} ) as OnboardingForm.Step[] ).reduce(
        ( result, value ) => {
          if( apiOnboardingForm.validation.steps[ value ] ) {
            result[ value ] = utilHelpers.toCamelCase( apiOnboardingForm.validation.steps[ value ]! )
          }
          return result
        },
        {} as Record<OnboardingForm.Step, OnboardingForm.ValidationStepResult>,
      ),
    },
  }

  return onboardingForm
}

export function parseOnboardingFormStatusFromApi( apiOnboardingFormStatus: ApiOnboardingFormStatus ) {
  return apiOnboardingFormStatus
}

export type ApiOnboardingFormValidationFieldResult = SnakeCasedPropertiesDeep<OnboardingForm.ValidationFieldResult>

export function parseOnboardingFormOnboardingFormValidationFieldResultFromApi( apiOnboardingFormValidationFieldResult: ApiOnboardingFormValidationFieldResult ) {
  const onboardingFormValidationFieldResult: OnboardingForm.ValidationFieldResult = {
    error: apiOnboardingFormValidationFieldResult.error,
    errorType: apiOnboardingFormValidationFieldResult.error_type,
    parsedValue: apiOnboardingFormValidationFieldResult.parsed_value,
    value: apiOnboardingFormValidationFieldResult.value,
  }

  return onboardingFormValidationFieldResult
}

export type ApiOnboardingOpportunity = SnakeCasedPropertiesDeep<OnboardingOpportunity>

export function parseOnboardingOpportunityFromApi( apiOnboardingOpportunity: ApiOnboardingOpportunity ) {
  const onboardingOpportunity: OnboardingOpportunity = {
    id: apiOnboardingOpportunity.id,
    merchantId: apiOnboardingOpportunity.merchant_id,
    salesforce: {
      accountId: apiOnboardingOpportunity.salesforce.account_id,
      accountName: apiOnboardingOpportunity.salesforce.account_name,
      address: ( apiOnboardingOpportunity.salesforce.address
        ? {
          city: apiOnboardingOpportunity.salesforce.address.city,
          countryCode: apiOnboardingOpportunity.salesforce.address.country_code,
          postalCode: apiOnboardingOpportunity.salesforce.address.postal_code,
          stateCode: apiOnboardingOpportunity.salesforce.address.state_code,
          street: apiOnboardingOpportunity.salesforce.address.street,
        }
        : undefined
      ),
      email: apiOnboardingOpportunity.salesforce.email,
      id: apiOnboardingOpportunity.salesforce.id,
      mobileNumber: apiOnboardingOpportunity.salesforce.mobile_number,
      name: apiOnboardingOpportunity.salesforce.name,
      ownerId: apiOnboardingOpportunity.salesforce.owner_id,
      ownerName: apiOnboardingOpportunity.salesforce.owner_name,
      primaryIndustry: apiOnboardingOpportunity.salesforce.primary_industry,
      secondaryIndustry: apiOnboardingOpportunity.salesforce.secondary_industry,
      stage: apiOnboardingOpportunity.salesforce.stage,
      website: apiOnboardingOpportunity.salesforce.website,
    },
    textIndex: apiOnboardingOpportunity.text_index,
  }

  return onboardingOpportunity
}

export type ApiOnboardingOpportunityOwner = SnakeCasedPropertiesDeep<OnboardingOpportunityOwner>

export function parseOnboardingOpportunityOwnerFromApi( apiOnboardingOpportunityOwner: ApiOnboardingOpportunityOwner ) {
  const onboardingOpportunityOwner: OnboardingOpportunityOwner = {
    id: apiOnboardingOpportunityOwner.id,
    name: apiOnboardingOpportunityOwner.name,
  }

  return onboardingOpportunityOwner
}

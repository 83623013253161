import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"
import { authSlice } from "libs/frontend-dashboard/auth/src/store/authStore"

import type { ModuleStoreConfig } from "@onelocal/frontend/common"
import type { RootThunk } from "@onelocal/frontend-dashboard-web/main"
import type { IntegrationCredentialService } from "../services/credentialService"
import { integrationCredentialService } from "../services/credentialService"
import type { IntegrationMerchantCredentialDashboard } from "../types"

export interface IntegrationsState {
  credentials: {
    byId: {
      [credentialId: string]: IntegrationMerchantCredentialDashboard
    }
  }
}

const integrationsStoreKey = "integrations"

export const integrationsInitialState: IntegrationsState = {
  credentials: {
    byId: {},
  },
}

const integrationsSlice = createSlice( {
  name: "integrations",
  initialState: integrationsInitialState,
  reducers: {
    updateCredentials: ( state, action: PayloadAction<IntegrationMerchantCredentialDashboard[]> ) => {
      const credentials = action.payload

      state.credentials.byId = {}

      for( const credential of credentials ) {
        state.credentials.byId[ credential.id ] = credential
      }

      return state
    },
  },
  extraReducers: builder => {
    builder.addCase( authSlice.actions.updateSession, ( state, action ) => {
      const session = action.payload

      if( session == null ) {
        return integrationsInitialState
      }

      return state
    } )
  },
} )

export const integrationsActions = {
  editCredential: ( merchantId: string, credentialId: string, model: IntegrationCredentialService.EditModel ): RootThunk<void> => {
    return async ( dispatch ) => {
      await integrationCredentialService.editCredential( merchantId, credentialId, model )
      const credentials = await integrationCredentialService.queryCredential( merchantId )
      dispatch( integrationsSlice.actions.updateCredentials( credentials ) )
    }
  },
  queryCredentials: ( merchantId: string ): RootThunk<IntegrationMerchantCredentialDashboard[] | null> => {
    return async ( dispatch ) => {
      const credentials = await integrationCredentialService.queryCredential( merchantId )
      dispatch( integrationsSlice.actions.updateCredentials( credentials ) )
      return credentials
    }
  },
}

export const integrationsStoreConfig: ModuleStoreConfig<IntegrationsState> = {
  key: integrationsStoreKey,
  initialState: integrationsInitialState,
  reducers: integrationsSlice.reducer,
}

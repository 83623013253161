export interface DudaTemplate {
  id: number
  is_custom: boolean
  is_onelocal: boolean
  name: string
}

export interface Site {
  contentGeneration?: {
    error?: {
      message: string
    }
    logs?: Array<{
      detail: string
      finishedAt?: string
      id: string
      startedAt: string
    }>
    siteDetailsLogs?: Array<{
      at: string
      details: string
    }>
    status: Site.ContentGenerationStatus
  }
  created: {
    at: string
  }
  dudaId: string
  indexed?: {
    at: string
    error?: {
      details: string
    }
    status: Site.IndexedStatus
  }
  id: string
  isPrimary?: boolean
  label?: string
  merchantId: string
  type: Site.Type
}

export namespace Site {
  export const enum ContentGenerationStatus {
    COMPLETED = "completed",
    FAILED = "failed",
    IN_PROGRESS = "in_progress",
  }

  export const enum IndexedStatus {
    COMPLETED = "completed",
    IN_PROGRESS = "in_progress",
    FAILED = "failed"
  }

  export const enum Type {
    LANDING_PAGE = "landing_page",
    WEBSITE = "website",
  }

  export const TypeLabelMap = {
    [ Type.LANDING_PAGE ]: "Landing Page",
    [ Type.WEBSITE ]: "Website",
  }

  export interface Page {
    id: string
    label: string
  }
}

export interface SiteGenerationDefaultValues {
  address: {
    city: string
    country: string
    postalCode: string
    state: string
    street: string
  }
  businessType: string
  email: string
  facebookId: string
  instagramId: string
  name: string
  phoneNumber: string
  primaryIndustry: string
  secondaryIndustry: string
  siteTemplateId: string | null
  siteTemplateName: string
}

export interface SiteTemplate {
  dudaId: string
  id: string
  isAvailable: boolean
  name: string
  types: Site.Type[]
}

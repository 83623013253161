import styled from "styled-components"

import { styleHelpers } from "../helpers"

export const StyledFormSectionTitle = styled.h3`
  color: ${ styleHelpers.colors.dark };
  font-size: ${ styleHelpers.fonts.sizes.medium };
  font-weight: ${ styleHelpers.fonts.weight.semiBold };
  margin: 0;
`

export const StyledText = styled.div`
  color: ${ styleHelpers.colors.dark };
  font-weight: ${ styleHelpers.fonts.default };
`

export const StyledSectionTitle = styled.h3`
  color: ${ styleHelpers.colors.dark };
  font-size: 20px;
  font-weight: ${ styleHelpers.fonts.weight.semiBold };
  margin: 0;
`

// eslint-disable-next-line no-restricted-imports
import type { SelectChangeEvent } from "@mui/material"
// eslint-disable-next-line no-restricted-imports
import { FormControl as MuiFormControl, FormHelperText as MuiFormHelperText, InputLabel as MuiInputLabel, MenuItem as MuiMenuItem, Select as MuiSelect } from "@mui/material"
import type { SxProps } from "@mui/system"
import { find } from "lodash"
import React, { useCallback, useMemo } from "react"
import styled from "styled-components"

import { styleHelpers } from "../helpers/styleHelpers"

const StyledFormControl = styled( MuiFormControl )`
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: ${ styleHelpers.colors.darkBlue };
    }
  }

  & .MuiInputLabel-outlined.Mui-focused {
    color: ${ styleHelpers.colors.darkBlue };
  }
`

export type SelectColor = "default" | "white"

export interface SelectPropsBase<TOption extends SelectOptionValueType> {
  className?: string
  color?: SelectColor
  disabled?: boolean
  error?: boolean
  fullWidth?: boolean
  helpText?: string | React.ReactNode
  id?: string
  keyByIndex?: boolean
  label?: string
  // multiple?: boolean
  // onChange( value: TOption ): void
  options: Array<SelectOption<TOption>>
  placeholder?: string
  required?: boolean
  style?: React.CSSProperties
  value: TOption
  variant?: SelectVariant
}

export interface SelectPropsSingle<TOption extends SelectOptionValueType> extends SelectPropsBase<TOption> {
  multiple?: undefined | false
  onChange( value: TOption ): void
}

export interface SelectPropsMultiple<TOption extends SelectOptionValueType> extends SelectPropsBase<TOption> {
  multiple: true
  onChange( value: TOption[] ): void
}

export type SelectProps<TOption extends SelectOptionValueType> = (
  SelectPropsSingle<TOption> | SelectPropsMultiple<TOption>
)

export interface SelectOption<T extends SelectOptionValueType> {
  disabled?: boolean
  label: string
  value: T
}

export type SelectOptionValueType = string | number
export type SelectVariant = "standard" | "outlined" | "filled"

function getStyles( color: SelectColor ): StyledSelectStyles {
  switch( color ) {
    case "white": {
      return {
        labelStyles: {
          color: styleHelpers.colors.white,
          "&.Mui-focused": {
            color: styleHelpers.colors.white,
          },
        },
        selectStyles: {
          color: styleHelpers.colors.white,
          "& .MuiSelect-icon": {
            color: styleHelpers.colors.white,
          },
        },
      }
    }
    case "default":
      return {}
  }
}

interface StyledSelectStyles {
  labelStyles?: SxProps
  selectStyles?: SxProps
}

export const Select = function<TOption extends SelectOptionValueType>( {
  className,
  color,
  disabled,
  error,
  fullWidth,
  helpText,
  id,
  keyByIndex,
  label,
  multiple,
  onChange,
  options,
  placeholder,
  required,
  style,
  value,
  variant,
}: SelectProps<TOption> ) {
  type TOptionCommon = TOption | TOption[]

  const handleChange = useCallback( ( event: SelectChangeEvent<SelectOptionValueType> ) => {
    onChange( event.target.value as ( TOption & TOption[] ) )
  }, [ onChange ] )

  const styles = useMemo( () => getStyles( color || "default" ), [ color ] )
  const validatedValue = useMemo<TOptionCommon>( () => {
    if( multiple ) {
      if( Array.isArray( value ) ) {
        return value as TOption[]
      }

      return [] as TOption[]
    }

    if( find( options, { value } ) ) {
      return value as TOption
    }

    return "" as unknown as TOption
  }, [ multiple, options, value ] )

  return (
    <StyledFormControl
      className={ className }
      error={ error }
      fullWidth={ fullWidth }
      required={ required }
      style={ style }
    >
      {
        label != null && (
          <MuiInputLabel
            id={ id ? `${ id }-label` : undefined }
            required={ required }
            variant={ variant }
            sx={ styles.labelStyles }
          >
            { label }
          </MuiInputLabel>
        )
      }
      <MuiSelect
        disabled={ disabled }
        id={ id }
        label={ label }
        labelId={ id ? `${ id }-label` : undefined }
        onChange={ handleChange }
        multiple={ multiple }
        placeholder={ placeholder }
        required={ required }
        sx={ styles.selectStyles }
        value={ validatedValue as SelectOptionValueType }
        variant={ variant || "outlined" }
      >
        {
          options.map( ( option, index ) => (
            <MuiMenuItem key={ keyByIndex ? index : option.value } value={ option.value }>{ option.label }</MuiMenuItem>
          ) )
        }
      </MuiSelect>
      {
        helpText != null && (
          <MuiFormHelperText
            sx={ { fontSize: styleHelpers.fonts.sizes.small } }
            variant={ variant }
          >
            { helpText }
          </MuiFormHelperText>
        )
      }
    </StyledFormControl>
  )
}

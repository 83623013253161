// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

import type { Environment } from "./types"

export const environment: Environment = {
  fontProjectId: process.env.NX_FONT_PROJECT_ID || "6711632",
  intercomConversationUrl: process.env.NX_INTERCOM_CONVERSATION_URL || "https://app.intercom.com/a/inbox/uh6io54o/inbox/admin/883644/conversation",
  jiraIssueBaseUrl: process.env.NX_JIRA_URL || "https://onelocal.atlassian.net/browse",
  salesforceUrl: process.env.NX_SALESFORCE_URL || "https://onelocal--dev.sandbox.lightning.force.com",
}

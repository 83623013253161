import { apiHelper } from "@onelocal/frontend/common"
import { eventHelper } from "@onelocal/frontend-web/common"
import type { MerchantSettings } from "../types"
import { parseMerchantSettingsFromApi } from "../types"

const enum Settings {
  LOCATION_CHANGED = "merchant_location_changed",
}

export const merchantSettingsService = {
  async getSettings( merchantId: string ) {
    return apiHelper.request( {
      method: "GET",
      parsingData: parseMerchantSettingsFromApi,
      url: `/merchants/${ merchantId }/settings`,
    } )
  },
  async emitSettings( merchantId: string, settings?: MerchantSettings ) {
    if( ! settings ) {
      settings = await merchantSettingsService.getSettings( merchantId )
    }

    eventHelper.emit( Settings.LOCATION_CHANGED, { settings } )
  },
}

export const addMerchantSettingsListener = ( handler: ( details: { settings: MerchantSettings } ) => void ) => {
  eventHelper.addEventListener( Settings.LOCATION_CHANGED, handler )
}

import { createSelector } from "@reduxjs/toolkit"

import type { OnboardingState } from "./onboardingStore"

type OnboardingStateSelector<TRootState> = [
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ( state: TRootState, ...params: any[] ) => OnboardingState
]

export const onboardingSelector = <TRootState extends { onboarding: OnboardingState }>( state: TRootState ) => state.onboarding

export const createOnboardingSelector = <TRootState extends { onboarding: OnboardingState }, TReturnValue>( combiner: ( onboardingState: OnboardingState ) => TReturnValue ) => {
  return createSelector<OnboardingStateSelector<TRootState>, TReturnValue>(
    onboardingSelector,
    combiner,
  )
}

export const onboardingSelectors = {
  opportunities: {
    byId: ( opportunityId: string ) => {
      return createOnboardingSelector(
        ( onboardingState ) => onboardingState.opportunities.byId[ opportunityId ],
      )
    },
    byIds: ( opportunityIds: string[] ) => {
      return createOnboardingSelector(
        ( onboardingState ) => opportunityIds.map( ( opportunityId ) => onboardingState.opportunities.byId[ opportunityId ] ).filter( ( opportunity ) => opportunity != null ),
      )
    },
  },
  opportunityOwners: {
    all: createOnboardingSelector(
      ( onboardingState ) => onboardingState.opportunityOwners.all,
    ),
  },
}

import React, { useCallback, useEffect, useState } from "react"
import styled from "styled-components"

import type { PaginatedQueryOptions } from "@onelocal/frontend/common"
import { useDebounce, usePaginatedResources } from "@onelocal/frontend/common"
import { useDashboardContext } from "@onelocal/frontend-dashboard/common"
import type { RootStateSite, SiteBlogPost, SiteBlogService } from "@onelocal/frontend-dashboard/site"
import { BlogPost, siteActions, siteSelectors } from "@onelocal/frontend-dashboard/site"
import type { SelectOption } from "@onelocal/frontend-web/common"
import { Divider, Flex, LoadingOverlay, Page, SearchBar, SelectInline, styleHelpers, TablePagination } from "@onelocal/frontend-web/common"
import { useSiteBlogPostCreateModal } from "../../components/SiteBlogPostCreateModal"
import { SiteBlogPostListItem } from "./SiteBlogPostListItem"

const MainContentContainer = styled.div`
  padding: 0 5px;
  width: 70%;
`

const StyledSiteBlogPostsList = styled.div`
  background-color: white;
  border: 1px solid ${ styleHelpers.colors.gray85 };
  border-radius: 10px;
  padding: 20px;
`

export interface SiteBlogPostsListPageProps {
}

const ALL_VALUE = "all"

const statusesOptions: Array<SelectOption<string>> = [
  {
    label: "All",
    value: ALL_VALUE,
  },
  ...[
    BlogPost.Status.APPROVED,
    BlogPost.Status.GENERATING,
    BlogPost.Status.PUBLISHED,
    BlogPost.Status.WAITING_APPROVAL,
  ].map<SelectOption<string>>( ( status ) => ( {
    label: BlogPost.STATUS_LABEL[ status ],
    value: status,
  } ) ),
]

export const SiteBlogPostsListPage: React.FC<SiteBlogPostsListPageProps> = () => {
  const { dashboardContext } = useDashboardContext()
  const { showSiteBlogPostCreateModal } = useSiteBlogPostCreateModal()

  const getQueryAction = useCallback( ( queryFilter: SiteBlogService.QueryFilter, queryOptions: PaginatedQueryOptions ) => {
    return siteActions.blogPosts.query( dashboardContext.merchantId, queryFilter, queryOptions )
  }, [ dashboardContext.merchantId ] )

  const [ searchText, setSearchText ] = useState<string>( "" )
  const [ selectedStatus, setSelectedStatus ] = useState<string>( ALL_VALUE )
  const { debouncedValue: debounceSearchText } = useDebounce( searchText, 300 )

  const {
    isLoading,
    items: posts,
    loadPage,
    queryOptions,
    setQueryFilter,
    totalItemsCount,
  } = usePaginatedResources<SiteBlogPost, SiteBlogService.QueryFilter, RootStateSite>( {
    defaultFilter: {
      status: ALL_VALUE,
    },
    getQueryAction,
    perPage: 25,
    selector: siteSelectors.blogPost.byIds,
  } )

  const onStatusOptionChanged = useCallback( ( value: BlogPost.Status ) => {
    setSelectedStatus( value )
    setQueryFilter( { status: value } )
  }, [ setQueryFilter ] )

  const onPageChanged = useCallback( ( page: number ) => {
    loadPage( page )
  }, [ loadPage ] )

  useEffect( () => {
    setQueryFilter( { q: debounceSearchText ?? null } )
  }, [ debounceSearchText, setQueryFilter ] )

  return (
    <Page
      buttons={ [
        {
          children: "New Blog Post",
          onClick: () => showSiteBlogPostCreateModal( { onCreated: () => loadPage( 1 ) } ),
        },
      ] }
      containerStyles={ { backgroundColor: styleHelpers.colors.grayishWhite } }
      customBoxComponent={ MainContentContainer }
      title="LocalContent"
    >
      <Flex
        alignItems="center"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        style={ {
          margin: "25px 0",
        } }
      >
        <SearchBar
          onChange={ setSearchText }
          placeholder="Search"
          style={ {
            flexGrow: 1,
            marginRight: "40px",
            maxWidth: "350px",
          } }
          value={ searchText }
        />

        <Flex
          alignItems="center"
          display="flex"
          flexDirection="row"
        >
          <SelectInline
            label="Status"
            onChange={ onStatusOptionChanged }
            options={ statusesOptions }
            value={ selectedStatus }
          />
        </Flex>
      </Flex>

      { isLoading
        ? <LoadingOverlay />
        : (
          posts && posts.length > 0
            ? (
              <StyledSiteBlogPostsList>
                { posts?.map( ( post, index ) => (
                  <div key={ post.id }>
                    <SiteBlogPostListItem { ...post } />
                    { index !== posts.length - 1 ? <Divider /> : null }
                  </div>
                ) ) }
              </StyledSiteBlogPostsList>
            )
            : <span>No Blog Posts To Display Yet</span>
        )
      }
      <TablePagination
        onPageChange={ onPageChanged }
        page={ queryOptions.page }
        perPage={ queryOptions.perPage }
        totalItems={ totalItemsCount || 0 }
      />
    </Page>
  )
}


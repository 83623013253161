import { apiHelper } from "@onelocal/frontend/common"
import { parseMerchantSettingsFromApi } from "../types/api"

export namespace MerchantSettingssService {

}

export const merchantSettingsService = {
  async getById( merchantId: string ) {
    return apiHelper.request( {
      method: "GET",
      parsingData: parseMerchantSettingsFromApi,
      url: `/merchants/${ merchantId }/settings`,
    } )
  },

}

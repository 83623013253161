// eslint-disable-next-line no-restricted-imports
import { Divider as MuiDivider } from "@mui/material"
import React from "react"

export interface DividerProps {
  style?: React.CSSProperties
}

export const Divider: React.FC<DividerProps> = ( props ) => {
  return (
    <MuiDivider { ...props } />
  )
}

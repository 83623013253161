import { isEmpty, isObject, isString } from "lodash"
import pluralize from "pluralize"

import { dateHelpers } from "./dateHelpers"

interface PersonBase {
  name?: {
    display?: string
    family?: string
    given?: string
  } | string
}

// eslint-disable-next-line no-useless-escape
const DYNAMIC_VALUE_REGEX = /\[([^\[\]]+)\]/gi

pluralize.addUncountableRule( "new" )

export const formattingHelpers = {
  getDisplayName<T extends PersonBase>( person: T ) {
    if( ! isObject( person ) || ! person.name ) {
      return null
    }

    if( isString( person.name ) ) {
      return person.name
    }

    if( ! isEmpty( person.name.display ) ) {
      return person.name.display as string
    }

    if( ! isEmpty( person.name.given ) && ! isEmpty( person.name.family ) ) {
      return `${ person.name.given } ${ person.name.family }`
    }
    else if( ! isEmpty( person.name.given ) ) {
      return person.name.given as string
    }
    else if( ! isEmpty( person.name.family ) ) {
      return person.name.family as string
    }

    return null
  },
  getFirstName<T extends PersonBase>( person: T ) {
    if( isObject( person ) && person.name && ! isString( person.name ) && ! isEmpty( person.name.given ) ) {
      return person.name.given
    }

    return null
  },
  getFirstNameLastInitial<T extends PersonBase>( person: T ) {
    if( ! isObject( person ) || ! person.name ) {
      return null
    }

    if( isString( person.name ) || ! isEmpty( person.name.display ) ) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const names = ( ( person.name as any ).display || person.name ).split( " " )
      if( names.length === 1 ) {
        return names[ 0 ]
      }

      return `${ names[ 0 ] } ${ names[ names.length - 1 ].charAt( 0 ) }`
    }

    if( ! isEmpty( person.name.given ) ) {
      const firstGivenName = person.name.given!.split( " " )[ 0 ]

      if( ! isEmpty( person.name.family ) ) {
        return `${ firstGivenName } ${ person.name.family?.charAt( 0 ) }`
      }

      return firstGivenName
    }

    return null
  },
  formatAmount( amount: number, decimal = 2 ) {
    return new Intl.NumberFormat( "en-US", {
      currency: "USD",
      currencyDisplay: "symbol",
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
      style: "currency",
    } ).format( amount )
  },
  formatDeltaDuration( duration: number ) {
    if( ! duration ) {
      return "0 seconds"
    }

    duration = Math.abs( duration )

    if( duration < 60 ) {
      return `${ formattingHelpers.roundDecimal( duration ) } ${ formattingHelpers.pluralize( "second", duration ) }`
    } else {
      const minutes = dateHelpers.convertSecondsToMinutes( duration )
      return `${ minutes } ${ formattingHelpers.pluralize( "minute", minutes ) }`
    }
  },
  formatNumber( value: number, decimal = 1 ) {
    return new Intl.NumberFormat( "en-US", { maximumFractionDigits: decimal } ).format( value )
  },
  formatShortNumber( value: number, decimal = 1, strictLimit = false ) {
    // Could use the notation: ""compact" but it's not available in the JS engine run on iOS and android
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat

    const factor = strictLimit ? 1 : 10

    if( value < 1000 * factor ) {
      return formattingHelpers.formatNumber( value, decimal )
    } else if( value < 1000000 * factor ) {
      return `${ formattingHelpers.formatNumber( ( value / 1000 ), decimal ) }K`
    } else {
      return `${ formattingHelpers.formatNumber( ( value / 1000000 ), decimal ) }M`
    }
  },
  formatPercent( value: number, decimal = 0 ) {
    return `${ formattingHelpers.roundDecimal( value * 100, decimal ) }%`
  },
  pluralize( word: string, count: number ) {
    if( isEmpty( word ) ) {
      return word
    }

    return pluralize( word, count || 0 )
  },
  renderBoolean( value: boolean ) {
    if( value === true ) {
      return "Yes"
    }
    if( value === false ) {
      return "No"
    }
    return "N/A"
  },
  replaceDynamicValues( string: string, values: { [field: string]: string } ) {
    if( ! string || ! isString( string ) ) {
      return string
    }

    const mapping: { [field: string]: string } = {}

    for( const field of Object.keys( values ) ) {
      mapping[ field.toLowerCase() ] = values[ field ]
    }

    return string.replace( DYNAMIC_VALUE_REGEX, function( match ) {
      const lowerCaseMatch = match.toLowerCase()
      if( mapping[ lowerCaseMatch ] != null ) {
        return mapping[ lowerCaseMatch ]
      }

      return match
    } )
  },
  roundDecimal( value: number, decimals = 0 ) {
    const p = Math.pow( 10, decimals )
    return Math.round( value * p ) / p
  },
}

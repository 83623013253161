import { isEmpty } from "lodash"

import { dateHelpers } from "@onelocal/shared/common"
import type { VisitsMerchantSettings } from "../types"
import { Booking, CalendarEvent, Day } from "../types"

const getBookingServiceGroup = ( booking: Booking ) => {
  if( ! booking ) {
    return null
  }

  return booking.service.group
}

const getBookingServiceGroupType = ( booking: Booking ) => {
  if( ! booking ) {
    return null
  }

  return booking.service.type
}

export const STATUS_OPTIONS = [
  {
    label: "Cancelled",
    value: CalendarEvent.StatusType.CANCELLED,
  },
  {
    label: "Completed",
    value: CalendarEvent.StatusType.COMPLETED,
  },
  {
    label: "Confirmed",
    value: CalendarEvent.StatusType.CONFIRMED,
  },
  {
    label: "Pending",
    value: CalendarEvent.StatusType.PENDING,
  },
]

export function getBookingCustomerLocalContactUrl( booking: Booking ) {
  return `/admin/local-contacts/customers/${ booking.customer.id }`
}

export function getBookingMultipleChoiceAnswerValue( field: VisitsMerchantSettings.BookingFieldMultipleChoice, answer: Booking.AnswerMultipleChoice ) {
  const values: string[] = []

  if( ! answer ) {
    return null
  }

  if( answer.optionIds?.length ) {
    answer.optionIds.forEach( ( optionId ) => {
      const option = field.options.find( ( fieldOption ) => fieldOption.value === optionId )
      if( option ) {
        values.push( option.label )
      }
    } )
  }

  if( ! isEmpty( answer.other ) ) {
    values.push( `Other - ${ answer.other }` )
  }

  return values.join( ", " )
}

export function getBookingServiceLabel( booking: Booking ) {
  const service_group = getBookingServiceGroup( booking )
  if( service_group ) {
    const service_group_type = getBookingServiceGroupType( booking )
    if( service_group_type ) {
      return `${ service_group.label } - ${ service_group_type.label }`
    }
  }

  return null
}

export function getBookingServiceTypeName( booking: Booking ) {
  const service_group_type = getBookingServiceGroupType( booking )
  if( service_group_type ) {
    return service_group_type.label
  }

  return null
}

export function getBookingServiceTypeDuration( booking: Booking ) {
  const serviceGroupType = getBookingServiceGroupType( booking )
  if( serviceGroupType && isBasicServiceType( serviceGroupType ) ) {
    return `${ dateHelpers.convertSecondsToMinutes( serviceGroupType.duration ) } min`
  }

  return null
}

export function getDayOptions() {
  return [
    {
      label: "Everyday",
      value: Day.EVERYDAY,
    },
    {
      label: "Weekends",
      value: Day.WEEKEND,
    },
    {
      label: "Weekdays",
      value: Day.WEEKDAYS,
    },
    {
      label: "Sunday",
      value: Day.SUNDAY,
    },
    {
      label: "Monday",
      value: Day.MONDAY,
    },
    {
      label: "Tuesday",
      value: Day.TUESDAY,
    },
    {
      label: "Wednesday",
      value: Day.WEDNESDAY,
    },
    {
      label: "Thursday",
      value: Day.THURSDAY,
    },
    {
      label: "Friday",
      value: Day.FRIDAY,
    },
    {
      label: "Saturday",
      value: Day.SATURDAY,
    },
  ]
}

export function getScheduleTypeLabel( calendarEvent: CalendarEvent ) {
  if( isEmpty( calendarEvent ) ) {
    return null
  }

  switch( calendarEvent.schedule.type ) {
    case CalendarEvent.ScheduleType.ANY: {
      return "No Preference"
    }
    case CalendarEvent.ScheduleType.ASAP: {
      return "ASAP"
    }
  }

  return null
}

export function getStatusLabel( calendarEvent: CalendarEvent ) {
  if( calendarEvent.type === CalendarEvent.Type.TIME_OFF
      && calendarEvent.status.type === CalendarEvent.StatusType.CONFIRMED
  ) {
    return "Blocked"
  }

  const status_option = STATUS_OPTIONS.find( ( option ) => calendarEvent.status.type === option.value )
  if( status_option ) {
    return status_option.label
  }

  return null
}

export function isBasicServiceType( serviceType: Partial<Booking.ServiceType> ): serviceType is Booking.BasicServiceType {
  return serviceType.type === Booking.BookingServiceType.BASIC
}

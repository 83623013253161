import React, { useCallback } from "react"

import { Link, styleHelpers } from "@onelocal/frontend-web/common"
import { LocalMessageLinkIcon } from "./Icons"

interface ConversationLinkProps {
  onClick?( event: React.MouseEvent<HTMLAnchorElement, MouseEvent> ): void
  phoneNumber: string
  text?: string
}

export const ConversationLink: React.FC<ConversationLinkProps> = ( { onClick, phoneNumber, text } ) => {
  const onClickHandler = useCallback( ( event: React.MouseEvent<HTMLAnchorElement, MouseEvent> ) => {
    event.preventDefault()
    if( onClick ) {
      onClick( event )
    }
    window.open( `/admin/local-messages/all/new?phone_number=${ encodeURIComponent( phoneNumber ) }`, "_self" )
  }, [ onClick, phoneNumber ] )

  return (
    <Link onClick={ onClickHandler } style={ { fontWeight: styleHelpers.fonts.weight.semiBold } }>
      <LocalMessageLinkIcon style={ {
        marginRight: "5px",
      } }/>
      { text || phoneNumber }
    </Link>
  )
}

import { onboardingFormService } from "libs/frontend-admin-web/onboarding/src/services/onboardingFormService"
import { OnboardingForm } from "libs/frontend-admin-web/onboarding/src/types"
import { useState } from "react"
import { useEffectOnce } from "react-use"

import { useResources } from "@onelocal/frontend/common"
import type { SiteService } from "../services/siteService"
import { siteSelectors } from "../store/siteSelectors"
import { siteActions } from "../store/siteStore"
import type { Site } from "../types"

export const useSites = ( merchantId: string, type: Site.Type ) => {
  const [ formStatus, setFormStatus ] = useState<OnboardingForm.FormStatus | null>( null )
  const { resources: sites, reloadResources: reloadSites } = useResources( {
    defaultFilter: {
      type: type,
    },
    getQueryAction: ( filter: SiteService.QueryFilter ) => siteActions.site.query( merchantId, filter ),
    selector: siteSelectors.site.byIds,
  } )

  useEffectOnce( () => {
    const getFormStatus = async () => {
      const status = await onboardingFormService.getFormStatus( {
        id: merchantId,
        type: OnboardingForm.IdentifierType.MERCHANT,
      } )

      setFormStatus( status )
    }

    getFormStatus()
  } )

  return {
    formStatus,
    reloadSites,
    sites,
  }
}

import { apiHelper } from "@onelocal/frontend/common"
import { Site } from "../types"
import type { ApiSite } from "../types/api"
import { parseSiteFromApi, parseSiteGenerationDefaultValuesFromApi, parseSitePageOptionsFromApi } from "../types/api"

export namespace SiteService {
  export interface CreateSiteContentModel {
    existingSiteUrl?: string
    pageId?: string
  }

  export interface CreateSiteModel {
    addressCity: string
    addressCountry: string
    addressPostalCode: string
    addressState: string
    addressStreet: string
    businessType: string
    email: string
    facebookId: string | undefined
    hasNoAddress: boolean
    instagramId: string | undefined
    label?: string
    messagesWidgetEnabled: boolean
    name: string
    phoneNumber: string
    reviewsWidgetEnabled: boolean
    siteTemplateId: string
    type: Site.Type
  }

  export interface QueryFilter {
    type: Site.Type
  }

  export interface LinkSiteModel {
    dudaSiteId: string
    label?: string
    type: Site.Type
  }
}

export const siteService = {
  async createSite( merchantId: string, model: SiteService.CreateSiteModel ) {
    return apiHelper.request( {
      method: "POST",
      url: `/merchants/${ merchantId }/local-site/site`,
      data: {
        address_city: model.addressCity,
        address_country: model.addressCountry,
        address_postal_code: model.addressPostalCode,
        address_state: model.addressState,
        address_street: model.addressStreet,
        business_type: model.businessType,
        email: model.email,
        facebook_id: model.facebookId,
        has_no_address: model.hasNoAddress,
        instagram_id: model.instagramId,
        label: model.label,
        messages_widget_enabled: model.messagesWidgetEnabled,
        name: model.name,
        phone_number: model.phoneNumber,
        reviews_widget_enabled: model.reviewsWidgetEnabled,
        site_template_id: model.siteTemplateId,
        type: model.type,
      },
      parsingData: parseSiteFromApi,
      timeout: 30000,
    } )
  },
  async createSiteContent( merchantId: string, siteId: string, model: SiteService.CreateSiteContentModel ) {
    return apiHelper.request( {
      method: "POST",
      url: `/merchants/${ merchantId }/local-site/sites/${ siteId }/generate-content`,
      data: {
        existing_site_url: model.existingSiteUrl,
        page_id: model.pageId,
      },
    } )
  },
  async getMainSite( merchantId: string ) {
    return apiHelper.request( {
      method: "GET",
      parsingData: ( apiSite: ApiSite | null ) => {
        if( ! apiSite ) {
          return null
        }
        return parseSiteFromApi( apiSite )
      },
      url: `/merchants/${ merchantId }/local-site/sites/main`,
    } )
  },
  getSiteContentGenerationLabel( status: Site.ContentGenerationStatus ) {
    switch( status ) {
      case Site.ContentGenerationStatus.COMPLETED:
        return "Completed"
      case Site.ContentGenerationStatus.FAILED:
        return "Failed"
      case Site.ContentGenerationStatus.IN_PROGRESS:
        return "In Progress"
    }
  },
  async getSiteGenerationValues( merchantId: string ) {
    return apiHelper.request( {
      method: "GET",
      parsingData: parseSiteGenerationDefaultValuesFromApi,
      timeout: 30000,
      url: `/merchants/${ merchantId }/local-site/site-generation-values`,
    } )
  },
  getSiteIndexStatusLabel( status?: Site.IndexedStatus ) {
    switch( status ) {
      case Site.IndexedStatus.COMPLETED:
        return "Completed"
      case Site.IndexedStatus.FAILED:
        return "Failed"
      case Site.IndexedStatus.IN_PROGRESS:
        return "In Progress"
      default:
        return "Not Started"
    }
  },
  async getSitePages( merchantId: string ) {
    return apiHelper.request( {
      method: "GET",
      parsingData: parseSitePageOptionsFromApi,
      url: `/merchants/${ merchantId }/local-site/site-pages`,
    } )
  },
  async indexSite( merchantId: string ) {
    return apiHelper.request( {
      method: "POST",
      url: `/merchants/${ merchantId }/local-site/sites/index`,
    } )
  },
  async linkSite( merchantId: string, model: SiteService.LinkSiteModel ) {
    return apiHelper.request( {
      method: "POST",
      url: `/merchants/${ merchantId }/local-site/site/link`,
      data: {
        duda_site_id: model.dudaSiteId,
        label: model.label,
        type: model.type,
      },
      parsingData: parseSiteFromApi,
    } )
  },
  async querySites( merchantId: string, query: SiteService.QueryFilter ) {
    return apiHelper.request( {
      method: "GET",
      params: { type: query.type },
      parsingData: ( apiSites: ApiSite[] ) => {
        return apiSites.map( parseSiteFromApi )
      },
      url: `/merchants/${ merchantId }/local-site/sites`,
    } )
  },
  async unlinkSite( merchantId: string, siteId: string ) {
    return apiHelper.request( {
      method: "DELETE",
      url: `/merchants/${ merchantId }/local-site/sites/${ siteId }`,
    } )
  },
  async updatePrimarySite( merchantId: string, siteId: string ) {
    return apiHelper.request( {
      method: "POST",
      url: `/merchants/${ merchantId }/local-site/sites/${ siteId }/primary`,
    } )
  },
}

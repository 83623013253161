import * as Sentry from "@sentry/react"
import { useEffect } from "react"
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom"

export namespace SentryHelper {
  export interface InitOptions {
    dsn: string
    environment: string
  }

  export interface LogOptions {
    extras?: Record<string, unknown>
  }
}

const setScopeFromOptions = (
  scope: Sentry.Scope,
  options?: SentryHelper.LogOptions,
) => {
  // scope.setTag( "env", configHelper.ENV )
  if( options?.extras ) {
    scope.setExtras( options.extras )
  }
}

export const sentryHelper = {
  init( options: SentryHelper.InitOptions ) {
    Sentry.init( {
      dsn: options.dsn,
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration( {
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        } ),
      ],

      environment: options.environment,
    
      tracesSampleRate: 1.0,
    
    } )
  },
  logError( error: Error, options?: SentryHelper.LogOptions ) {
    Sentry.withScope( ( scope ) => {
      setScopeFromOptions( scope, options )
      Sentry.captureException( error )
    } )
  },
  logWarning( message: string, options?: SentryHelper.LogOptions ) {
    const warningError = new Error( message )

    Sentry.withScope( ( scope ) => {
      setScopeFromOptions( scope, options )
      scope.setLevel( "warning" )
      Sentry.captureException( warningError )
    } )
  },
  // setSession( session: AuthSession | null ) {
  //   if( session ) {
  //     Sentry.setUser( {
  //       email: session.email,
  //       id: session.userId,
  //       username: session.username,
  //     } )
  //     Sentry.setTags( { merchant_id: session.employeeOrganizationId } )
  //   } else {
  //     Sentry.setUser( null )
  //     Sentry.setTags( { merchant_id: "" } )
  //   }
  // },
}

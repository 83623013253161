import { conversationsService as baseConversationsService, phoneService } from "@onelocal/frontend-dashboard/connect"
import { notificationService } from "@onelocal/frontend-web/common"
import { ProductNames } from "@onelocal/shared/common"

export const conversationsService = {
  ...baseConversationsService,
  addConversationNotificationEventHandler() {
    return conversationsService.onConversationNotification( ( data ) => {
      const { body, conversationId, notificationId } = data
      notificationService.showDesktopNotification( ProductNames.LOCAL_MESSAGES, {
        body,
        tag: `messenger_new_message:${ notificationId }`,
        icon: "/assets/shared/onelocal-icon.png",
        notifyClick: () => {
          window.focus()
          window.open( `/admin/local-messages/all/${ conversationId }`, phoneService.isOnCall() ? "_blank" : "_self" )
        },
      } )
    } )
  },
}

export enum DashboardRoutePath {
  ACTIVITY = "/activity",
  ADMIN = "/admin",
  CONNECT_CALL_HISTORY = "/connect/call",
  CONNECT_SETTINGS_ASSISTANT = "/settings/connect/assistant",
  CONNECT_SETTINGS_ASSISTANT_FLOW = "/settings/connect/assistant/flow",
  CONNECT_SETTINGS_ASSISTANT_GENERAL = "/settings/connect/assistant/general",
  CONNECT_SETTINGS_AUTORESPONDER = "/settings/connect/autoresponder",
  CONNECT_SETTINGS_BUSINESS_HOURS = "/settings/connect/business-hours",
  CONNECT_SETTINGS_EXPORT = "/settings/connect/messages-export",
  CONNECT_SETTINGS_INCOMING_CALL = "/settings/connect/incoming-call",
  CONNECT_SETTINGS_LOCAL_RESPONSE = "/settings/connect/local-response",
  CONTENT = "/content",
  HELP = "/help",
  INBOX = "/inbox",
  PERFORMANCE = "/performance",
  SITE_BLOG_POST_EDIT = "/site/blog-posts/:blogPostId",
  SITE_BLOG_POST_LIST = "/site/blog-posts",
  SITE_NOTIFICATION_SETTINGS = "/settings/site/notifications",
  SITE_SETTINGS = "/settings/site",

  // Existing dashboard urls
  MESSAGES_SETTINGS = "/admin/settings/local-messages",
  SETTINGS = "/admin/settings"
}

export enum OldDashboardRoutePath {
  ADS ="/admin/local-ads",
  CONTACTS = "/admin/local-contacts",
  CONTENT ="/admin/local-content",
  EMPLOYEE = "/employee",
  HOME = "/admin",
  REFERRAL_PROGRAM = "/admin/referral-program",
  REFERRALS ="/admin/local-referrals/referrals",
  REVIEWS ="/admin/local-reviews/dashboard",
  MESSAGES ="/admin/local-messages/you",
  MY_ACCOUNT = "/admin/user_settings/account",
  SEO ="/admin/local-seo",
  SETTINGS = "/admin/settings",
  SIGNIN = "/account/signin",
  SITE ="/admin/local-site",
  VISITS = "/admin/local-visits",
}

export enum ExternalLinks {
  INTERCOM_SUPPORT_CENTER = "https://intercom.help/onelocal/en/",
  STATUS_PAGE = "https://onelocalstatus.statuspage.io/",
}

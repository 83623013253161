import CheckIcon from "@mui/icons-material/Check"
import InfoOutlined from "@mui/icons-material/InfoOutlined"
import { isEmpty } from "lodash"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useEffectOnce } from "react-use"
import styled from "styled-components"

import type { DialogAction, DialogAlert } from "@onelocal/frontend-web/common"
import { Dialog, DialogAlertType, SearchBar, styleHelpers, useAlert, useModalWithParams } from "@onelocal/frontend-web/common"
import { siteService } from "../services/siteService"
import type { Site } from "../types"

const StyledInfoBanner = styled.div`
  align-items: center;
  color: ${ styleHelpers.colors.darkGray };
  display: flex;
  gap: 8px;
  padding: 8px 0px;
`

const StyledSitePageList = styled.ul`
  border-bottom: 1px solid ${ styleHelpers.colors.borderGray };
  border-top: 1px solid ${ styleHelpers.colors.borderGray };
  flex: 1;
  margin: 0;
  max-height: 500px;
  min-height: 300px;
  overflow-y: scroll;
  padding: 0;
`

const StyledSitePageItem = styled.li<{ isSelected?: boolean }>`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0 10px;

  svg {
    display: none;
  }

  & + & {
    border-top: 1px solid ${ styleHelpers.colors.borderGray };
  }

  &:hover {
    a {
      font-weight: 500;
    }

    svg {
      display: block;
    }
  }

  ${ ( { isSelected } ) => isSelected && `
    a {
      font-weight: 500;
    }

    svg {
      display: block;
    }
  ` }
`

const StyledSitePageTitle = styled.p`
  color: ${ styleHelpers.colors.darkGray };
  display: block;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 15px 0;
  text-decoration: none;
`

const StyledCheckIcon = styled( CheckIcon )`
  height: 18px;
  width: 18px;
`

const StyledSitePageCheckIcon = styled( StyledCheckIcon )`
  color: ${ styleHelpers.colors.primary };
`

export interface GenerateMerchantSitePageModalProps {
  isOpen: boolean
  merchantId: string
  onClose( reOpen?: boolean ): void
  onExited: () => void
  onGenerated?(): void
  siteId: string
}

export const GenerateMerchantSitePageModal: React.FC<GenerateMerchantSitePageModalProps> = ( {
  isOpen,
  merchantId,
  onClose,
  onExited,
  onGenerated,
  siteId,
} ) => {
  const [ alert, setAlert ] = useState<DialogAlert | undefined>( undefined )
  const [ isProcessing, setIsProcessing ] = useState( false )
  const { showSuccess } = useAlert()
  const [ selectedSitePageId, setSelectedSitePageId ] = useState< string | undefined>()
  const [ searchText, setSearchText ] = useState( "" )
  const [ sitePages, setSitePages ] = useState< Site.Page[] | undefined >()
  const [ filteredSitePages, setFilteredSitePages ] = useState< Site.Page[] | undefined >()

  useEffectOnce( () => {
    const run = async () => {
      const merchantSitePages = await siteService.getSitePages( merchantId )
      setSitePages( merchantSitePages )
      setFilteredSitePages( merchantSitePages )
    }

    run()
  } )

  useEffect( () => {
    if( isEmpty( sitePages ) ) {
      return
    }

    if( searchText === "" ) {
      setFilteredSitePages( sitePages )
      return
    }

    const filteredPages = sitePages!.filter( ( sitePage ) => sitePage.label.toLowerCase().includes( searchText.toLowerCase() ) )
    setFilteredSitePages( filteredPages )
  }, [ searchText, sitePages ] )

  const handleSelect = useCallback( ( selectedSitePage: Site.Page ) => {
    setSelectedSitePageId( selectedSitePage.id )
  }, [] )

  const onSubmit = useCallback( async () => {
    if( ! selectedSitePageId ) {
      setAlert( {
        message: "Please select a site page",
        type: DialogAlertType.ERROR,
      } )

      return
    }

    try {
      if( isProcessing ) {
        return
      }

      setIsProcessing( true )

      await siteService.createSiteContent( merchantId, siteId, { pageId: selectedSitePageId } )

      showSuccess( "Site Page Content generating" )

      if( onGenerated ) {
        onGenerated()
      }

      onClose()
    } catch( err ) {
      setAlert( {
        message: err,
        type: DialogAlertType.ERROR,
      } )
    } finally {
      setIsProcessing( false )
    }
  }, [ selectedSitePageId, isProcessing, merchantId, siteId, showSuccess, onGenerated, onClose ] )

  const modalActions: DialogAction[] = useMemo( () => [
    { title: "Cancel", type: "dismiss" },
    {
      disabled: setSelectedSitePageId == null,
      onClick: ()=> {
        onSubmit()
      },
      title: "Generate",
      type: "primary",
    },
  ], [ onSubmit ] )

  return (
    <Dialog
      actions={ modalActions }
      alert={ alert }
      contentContainerStyle={ {
        paddingLeft: "24px",
        paddingRight: "24px",
        width: "500px",
      } }
      isOpen={ isOpen }
      onClose={ onClose }
      onExited={ onExited }
      showDividers={ false }
      title="(Re)generate Site Page"
    >
      <StyledInfoBanner>
        <InfoOutlined /> Please select a that you want to generate or regenerate a current page.
      </StyledInfoBanner>

      <>
        <SearchBar
          style={ { margin: "20px 0px" } }
          onChange={ setSearchText }
          placeholder="Search by Page"
          value={ searchText }
        />

        {
          sitePages != null && (
            <StyledSitePageList>
              {
                filteredSitePages?.map( ( sitePage ) => (
                  <StyledSitePageItem key={ sitePage.id } isSelected={ selectedSitePageId === sitePage.id } onClick={ () => handleSelect( sitePage ) }>
                    <StyledSitePageTitle>
                      { sitePage.label }
                    </StyledSitePageTitle>

                    <StyledSitePageCheckIcon />
                  </StyledSitePageItem>
                ) )
              }
            </StyledSitePageList>
          )
        }
      </>
    </Dialog>
  )
}

export interface useGenerateMerchantSitePageModalParams {
  merchantId: string
  onGenerated?(): void
  siteId: string
}

export const useGenerateMerchantSitePageModal = () => {
  const { showModal, hideModal } = useModalWithParams<useGenerateMerchantSitePageModalParams>( ( { open, onExited, params } ) => {
    if( ! params ) {
      return null
    }

    return (
      <GenerateMerchantSitePageModal
        isOpen={ open }
        merchantId={ params.merchantId }
        onClose={ hideModal }
        onGenerated={ params.onGenerated }
        onExited={ onExited }
        siteId={ params.siteId }
      />
    )
  }, [] )

  return {
    hideGenerateMerchantSitePageModal: hideModal,
    showGenerateMerchantSitePageModal: showModal,
  }
}

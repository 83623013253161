import type { ReactNode } from "react"
import React, { createContext, useCallback, useContext, useState } from "react"
import { useSelector } from "react-redux"
import { useEffectOnce } from "react-use"

import { AnalyticHelper, mixpanelHelpers } from "@onelocal/frontend-dashboard/common"
import { mainActions, MainPerformanceInsight, mainSelectors } from "@onelocal/frontend-dashboard/main"
import { useMerchantSettings } from "@onelocal/frontend-dashboard/settings"
import { useAlert } from "@onelocal/frontend-web/common"
import { ProductNames } from "@onelocal/shared/common"
import { useRootDispatch } from "../../store"

export interface PerformanceContextType {
  digitalMarketing: PerformanceContextType.Section<Pick<MainPerformanceInsight, "googleBusinessProfileEvents" | "organicSearch" | "paidAd" | "site">>
  isRefreshing: boolean
  leadsReviews: PerformanceContextType.Section<Pick<MainPerformanceInsight, "lead" | "review">>
  refresh(): Promise<void>
  summary: MainPerformanceInsight[ "summary" ] | null

}

export namespace PerformanceContextType {
  export interface Section<T> {
    insight: T | null
    isLoading: boolean
    period: MainPerformanceInsight.Period
    updatePeriod( period: MainPerformanceInsight.Period ): Promise<void>
  }
}

const PerformanceContext = createContext<PerformanceContextType>( null! )

const DEFAULT_PERIOD = MainPerformanceInsight.Period.LAST_60_DAYS

const usePerformanceSection = () => {
  const [ isLoading, setIsLoading ] = useState( true )
  const [ period, setPeriod ] = useState( DEFAULT_PERIOD )
  const [ displayPeriod, setDisplayPeriod ] = useState( DEFAULT_PERIOD )
  const { merchantSettings } = useMerchantSettings()
  const dispatch = useRootDispatch()
  const insight = useSelector( mainSelectors.performance.insightByPeriod( displayPeriod ) )
  const { showError } = useAlert()

  const reload = useCallback( async ( updatedPeriod: MainPerformanceInsight.Period | null, silent: boolean ) => {
    if( updatedPeriod ) {
      setPeriod( updatedPeriod )
    }
    try {
      if( ! silent ) {
        setIsLoading( true )
      }
      await dispatch( mainActions.getPerformanceInsight( merchantSettings!.merchantId, updatedPeriod || period ) )
    } catch( err: unknown ) {
      showError( err )
    } finally {
      if( ! silent ) {
        setIsLoading( false )
      }
      if( updatedPeriod ) {
        setDisplayPeriod( updatedPeriod )
      }
    }
  }, [ dispatch, merchantSettings, period, showError ] )

  const refresh = useCallback( async () => {
    await reload( null, true )
  }, [ reload ] )

  const updatePeriod = useCallback( async ( updatedPeriod: MainPerformanceInsight.Period ) => {
    await reload( updatedPeriod, false )

    mixpanelHelpers.trackEvent( AnalyticHelper.EventName.TIME_PERIOD_PICKER_CLICKED, {
      Location: "Performance - Web Dashboard",
      Product: ProductNames.PERFORMANCE,
    } )
  }, [ reload ] )

  return {
    insight,
    isLoading,
    period,
    refresh,
    setIsLoading,
    updatePeriod,
  }
}

export const PerformanceContextProvider: React.FunctionComponent<{ children: ReactNode }> = ( { children } ) => {
  const dispatch = useRootDispatch()
  const [ isRefreshing, setIsRefreshing ] = useState( false )
  const { merchantSettings } = useMerchantSettings()
  const summary = useSelector( mainSelectors.performance.summary )
  const { showError } = useAlert()

  const digitalMarketingSection = usePerformanceSection()
  const leadsReviewSection = usePerformanceSection()

  const refresh = useCallback( async () => {
    setIsRefreshing( true )

    try {
      if( digitalMarketingSection.period === leadsReviewSection.period ) {
        await dispatch( mainActions.getPerformanceInsight( merchantSettings!.merchantId, digitalMarketingSection.period ) )
      } else {
        await Promise.all( [
          digitalMarketingSection.refresh(),
          leadsReviewSection.refresh(),
        ] )
      }
    } catch( err ) {
      showError( err )
    } finally {
      setIsRefreshing( false )
    }
  }, [ digitalMarketingSection, dispatch, leadsReviewSection, merchantSettings, showError ] )

  useEffectOnce( () => {
    async function run() {
      digitalMarketingSection.setIsLoading( true )
      leadsReviewSection.setIsLoading( true )
      try {
        await dispatch( mainActions.getPerformanceInsight( merchantSettings!.merchantId, DEFAULT_PERIOD ) )
      } catch( err: unknown ) {
        showError( err )
      } finally {
        digitalMarketingSection.setIsLoading( false )
        leadsReviewSection.setIsLoading( false )
      }
    }

    run()
  } )

  return (
    <PerformanceContext.Provider
      value={ {
        digitalMarketing: {
          insight: digitalMarketingSection.insight,
          isLoading: digitalMarketingSection.isLoading,
          period: digitalMarketingSection.period,
          updatePeriod: digitalMarketingSection.updatePeriod,
        },
        isRefreshing,
        leadsReviews: {
          insight: leadsReviewSection.insight,
          isLoading: leadsReviewSection.isLoading,
          period: leadsReviewSection.period,
          updatePeriod: leadsReviewSection.updatePeriod,
        },
        refresh,
        summary,
      } }
    >
      { children }
    </PerformanceContext.Provider>
  )
}

export function usePerformanceContext(): PerformanceContextType {
  return useContext( PerformanceContext )
}

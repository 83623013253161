import CallIcon from "@mui/icons-material/Call"
import CallEndIcon from "@mui/icons-material/CallEnd"
import DialpadIcon from "@mui/icons-material/Dialpad"
import MicOffIcon from "@mui/icons-material/MicOff"
import SettingsIcon from "@mui/icons-material/Settings"
// eslint-disable-next-line no-restricted-imports
import type { Theme } from "@mui/material"
// eslint-disable-next-line no-restricted-imports
import { createTheme, IconButton, ThemeProvider as MuiThemeProvider, Tooltip } from "@mui/material"
import type { SxProps } from "@mui/system"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"

import { eventEmitterHelpers, phoneHelpers, useAsyncDispatch } from "@onelocal/frontend/common"
import { AnalyticHelper, mixpanelHelpers } from "@onelocal/frontend-dashboard/common"
import {
  connectActions,
  ConnectCall,
  connectCallHelpers,
  connectSelectors,
  EventType,
  PhoneActiveCallState,
} from "@onelocal/frontend-dashboard/connect"
import { Flex, Select, styleHelpers, useAlert } from "@onelocal/frontend-web/common"
import { ProductNames } from "@onelocal/shared/common"
import { ReactComponent as MessageIcon } from "../assets/message-icon.svg"
import { useConnectActiveCallDurationText } from "../hooks/useConnectActiveCallDurationText"

export const CALL_DIALOG_ZINDEX = 2147483002

export interface CallDialogProps {
}

const BUTTON_DEFAULT_COLOR = "#56677a"

const ActiveCallEndIcon = styled( CallEndIcon )`
  color: ${ styleHelpers.colors.white };
  font-size: 28px;
`

const ActiveCallFlexRow = styled( Flex )`
  & + & {
    margin-top: 35px;
  }
`

const ActiveCallEmptyItem = styled.div`
  height: 50px;
  width: 50px;
`

const ActiveCallKeyPadContainer = styled.div`
  margin: 30px 0;
`

const ActiveCallKeyPadKeyContainer = styled( Flex )`
  background-color: ${ BUTTON_DEFAULT_COLOR };
  border-radius: 50%;
  height: 50px;
  width: 50px;

  &:hover {
    background-color: rgba( 255, 255, 255, 0.5 );
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px;
    cursor: pointer;
  }
`

const ActiveCallKeyPadKeySmallText = styled.div`
  color: ${ styleHelpers.colors.white };
  font-size: 10px;
  font-weight: ${ styleHelpers.fonts.weight.semiBold };
  height: 9px;
`

const ActiveCallKeyPadKeyText = styled.div`
  color: ${ styleHelpers.colors.white };
  font-size: 20px;
  font-weight: ${ styleHelpers.fonts.weight.semiBold };
  margin-top: 8px;
`

const ActiveCallKeyPadRow = styled( Flex )`
  & + & {
    margin-top: 8px;
  }
`

const ActiveCallSettingsContainer = styled( Flex )`
  margin-top: 45px;
`

const ActiveCallSettingsSelector = styled( Select )`
 & + & {
   margin-top: 20px;
 }
`

const CallDialogContainer = styled.div`
  background: ${ styleHelpers.colors.darkBlue };
  border-radius: 8px;
  box-sizing: border-box;
  color: #fff;
  height: auto;
  width: 274px;
  padding: 30px 30px 45px 30px;
`

const CallDialogInnerContainer = styled.div`
  & + & {
    border-top: 1px solid rgba( 255, 255, 255, 0.4 );
    margin-top: 45px;
    padding-top: 20px;
  }
`

const CallHeaderText = styled.div`
  font-size: 20px;
  font-weight: ${ styleHelpers.fonts.weight.semiBold };
  line-height: 24px;
  margin-bottom: 5px;
  text-align: center;
`

const CallStatusText = styled.div`
  font-size: 16px;
  line-height: 19.2px;
  margin-bottom: 20px;
  text-align: center;
`

const IncomingCallIcon = styled( CallIcon )`
  color: ${ styleHelpers.colors.white };
  font-size: 32px;
`

const IncomingCallEndIcon = styled( CallEndIcon )`
  color: ${ styleHelpers.colors.white };
  font-size: 24px;
`

// Active Call Button Styles
const basicActiveCallFontStyles: SxProps = {
  fontSize: "28px",
}

const basicActiveCallIconButtonStyles: SxProps = {
  color: styleHelpers.colors.white,
  height: "50px",
  width: "50px",
}

const activeCallActiveIconButtonStyles: SxProps = {
  ...basicActiveCallIconButtonStyles,
  backgroundColor: `${ styleHelpers.colors.white }`,
  "&:hover": {
    backgroundColor: `${ styleHelpers.colors.white }`,
    boxShadow: 3,
  },
}

const activeCallDeclineButtonStyles: SxProps = {
  ...basicActiveCallIconButtonStyles,
  backgroundColor: `${ styleHelpers.colors.red }`,
  "&:hover": {
    backgroundColor: `${ styleHelpers.colors.red }`,
    boxShadow: 3,
  },
}

const activeCallIconButtonStyles: SxProps = {
  ...basicActiveCallIconButtonStyles,
  backgroundColor: BUTTON_DEFAULT_COLOR,
  "&:hover": {
    backgroundColor: BUTTON_DEFAULT_COLOR,
    boxShadow: 3,
  },
}

// Incoming Call Button styles
const basicIncomingCallIconButtonStyles: SxProps = {
  color: styleHelpers.colors.white,
  height: "40px",
  width: "40px",
}

const incomingCallAnswerButtonStyles: SxProps = {
  backgroundColor: `${ styleHelpers.colors.green }`,
  height: "60px",
  width: "60px",
  "&:hover": {
    backgroundColor: `${ styleHelpers.colors.green }`,
    boxShadow: 3,
  },
}

const incomingCallDeclineButtonStyles: SxProps = {
  ...basicIncomingCallIconButtonStyles,
  backgroundColor: `${ styleHelpers.colors.red }`,
  "&:hover": {
    backgroundColor: `${ styleHelpers.colors.red }`,
    boxShadow: 3,
  },
}

const incomingCallIconButtonStyles: SxProps = {
  ...basicIncomingCallIconButtonStyles,
  backgroundColor: BUTTON_DEFAULT_COLOR,
  "&:hover": {
    backgroundColor: BUTTON_DEFAULT_COLOR,
    boxShadow: 3,
  },
}

const StyledDialpadIcon: React.FC<{ showKeypad: boolean }> = React.memo( function( { showKeypad } ) {
  return (
    <DialpadIcon sx={ { ...basicActiveCallFontStyles, color: showKeypad ? styleHelpers.colors.black : styleHelpers.colors.white } } />
  )
} )

StyledDialpadIcon.displayName = "StyledDialpadIcon"

const StyledMicOffIcon: React.FC<{ isMuted: boolean }> = React.memo( function( { isMuted } ) {
  return (
    <MicOffIcon sx={ { ...basicActiveCallFontStyles, color: isMuted ? styleHelpers.colors.black : styleHelpers.colors.white } } />
  )
} )

StyledMicOffIcon.displayName = "StyledMicOffIcon"

const StyledSettingsIcon: React.FC<{ showSettings: boolean }> = React.memo( function( { showSettings } ) {
  return (
    <SettingsIcon sx={ { ...basicActiveCallFontStyles, color: showSettings ? styleHelpers.colors.black : styleHelpers.colors.white } } />
  )
} )

StyledSettingsIcon.displayName = "StyledSettingsIcon"

export const CallDialog: React.FC<CallDialogProps> = () => {
  const { showError } = useAlert()

  const callDialogMuiTheme = useMemo( () => {
    return ( theme: Theme ) => {
      return createTheme( {
        ...theme,
        components: {
          ...theme.components,
          MuiButtonBase: {
            styleOverrides: {
              root: {
                "&.MuiMenuItem-root.Mui-selected": {
                  backgroundColor: styleHelpers.colors.darkBlue,
                  color: styleHelpers.colors.white,
                },
                "&.MuiMenuItem-root.Mui-selected:hover": {
                  backgroundColor: styleHelpers.colors.darkBlue,
                  color: styleHelpers.colors.white,
                },
              },
            },
          },
          MuiMenu: {
            styleOverrides: {
              root: {
                zIndex: CALL_DIALOG_ZINDEX,
              },
            },
          },
          MuiOutlinedInput: {
            styleOverrides: {
              notchedOutline: {
                borderColor: styleHelpers.colors.white,
              },
              root: {
                borderColor: styleHelpers.colors.white,
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: styleHelpers.colors.white,
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: styleHelpers.colors.white,
                },
              },
            },
          },
        },
        zIndex: {
          ...theme.zIndex,
          tooltip: CALL_DIALOG_ZINDEX,
        },
      } )
    }
  }, [] )

  useEffect( () => {
    const listener = ( errorMessage: string ) => {
      showError( errorMessage )
    }

    eventEmitterHelpers.addEventListener( EventType.CALL_ERROR, listener )

    return () => {
      // remove it in a timeout otherwise it will remove the event listener as soon as it closes and alert will not be displayed
      setTimeout( () => {
        eventEmitterHelpers.removeListener( EventType.CALL_ERROR, listener )
      }, 1000 )
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  const activeCall = useSelector( connectSelectors.calls.activeCall )
  const activeCallDigits = useSelector( connectSelectors.calls.activeCallDigits )
  const activeCallDurationText = useConnectActiveCallDurationText()
  const connectCall = useSelector( connectSelectors.calls.current )
  const currentInputDeviceId = useSelector( connectSelectors.audio.currentInputId )
  const currentOutputDeviceId = useSelector( connectSelectors.audio.currentOutputId )
  const dispatch = useAsyncDispatch()
  const incomingCalls = useSelector( connectSelectors.calls.incomingCalls )
  const inputAudioDevices = useSelector( connectSelectors.audio.inputDevices )
  const outputAudioDevices = useSelector( connectSelectors.audio.outputDevices )
  const [ showKeypad, setShowKeypad ] = useState<boolean>( false )
  const [ showSettings, setShowSettings ] = useState<boolean>( false )

  const acceptIncomingCall = useCallback( ( incomingCall: ConnectCall ) => {
    mixpanelHelpers.trackEvent(
      AnalyticHelper.EventName.CTA_CLICKED,
      {
        "Name": "Answer Call",
        "Location": AnalyticHelper.EventLocation.LOCALMESSAGES_CALL_MODAL,
        "Product": ProductNames.LOCAL_MESSAGES,
      },
    )
    dispatch( connectActions.activeCall.acceptIncomingCallById( incomingCall.id ) )
  }, [ dispatch ] )

  const activeCallStatus = useMemo( () => {
    if( ! activeCall ) {
      return
    }

    switch( activeCall.state ) {
      case PhoneActiveCallState.CONNECTING:
        return "Connecting"
      case PhoneActiveCallState.RINGING:
        return "Ringing"
      case PhoneActiveCallState.CONNECTED:
        return activeCallDurationText
      case PhoneActiveCallState.DISCONNECTED:
        return "Disconnected"
      case PhoneActiveCallState.RECONNECTING:
        return "Reconnecting..."
    }

    return null
  }, [ activeCall, activeCallDurationText ] )

  const declineIncomingCall = useCallback( ( incomingCall: ConnectCall ) => {
    dispatch( connectActions.activeCall.declineIncomingCallById( incomingCall.id ) )
  }, [ dispatch ] )

  const isAnonymousPhoneNumber = useCallback( ( phoneNumber: string ) => {
    return phoneHelpers.isAnonymousPhoneNumber( phoneNumber )
  }, [] )

  const formattedToNumber = useMemo( () => {
    if( ! connectCall ) {
      return
    }

    return connectCallHelpers.getContactText( connectCall )
  }, [ connectCall ] )

  const hangup = useCallback( async () => {
    if( ! activeCall ) {
      return
    }

    mixpanelHelpers.trackEvent(
      AnalyticHelper.EventName.CTA_CLICKED,
      {
        "Name": "End Call",
        "Location": AnalyticHelper.EventLocation.LOCALMESSAGES_CALL_MODAL,
        "Product": ProductNames.LOCAL_MESSAGES,
      },
    )

    dispatch( connectActions.activeCall.stopActiveCall() )
  }, [ activeCall, dispatch ] )

  const onInputAudioDeviceSelected = useCallback( async ( value: string ) => {
    dispatch( connectActions.audio.updateCurrentInputId( value ) )
  }, [ dispatch ] )

  const onKeyPressed = useCallback( ( key: string ) => {
    dispatch( connectActions.activeCall.addDigits( key ) )
  }, [ dispatch ] )

  const onMute = useCallback( async () => {
    if( ! activeCall ) {
      return
    }
    dispatch( connectActions.activeCall.setActiveCallMuted( ! activeCall.isMuted ) )
  }, [ activeCall, dispatch ] )

  const onOutputAudioDeviceSelected = useCallback( async ( value: string ) => {
    dispatch( connectActions.audio.updateCurrentOutputId( value ) )
  }, [ dispatch ] )

  const onToggleKeypad = useCallback( async () => {
    setShowKeypad( ! showKeypad )
  }, [ showKeypad ] )

  const onToggleSettings= useCallback( async () => {
    setShowSettings( ! showSettings )
  }, [ showSettings ] )

  const openLocalMessages = useCallback( async( phoneNumber: string ) => {
    mixpanelHelpers.trackEvent(
      AnalyticHelper.EventName.CTA_CLICKED,
      {
        "Name": "Message Caller",
        "Location": AnalyticHelper.EventLocation.LOCALMESSAGES_CALL_MODAL,
        "Product": ProductNames.LOCAL_MESSAGES,
      },
    )
    // Open in new tab to avoid call to be disconnected for now
    window.open( `/admin/local-messages/all/new?phone_number=${ phoneNumber }`, "_blank" )
  }, [] )

  if( ! activeCall && ! incomingCalls.length ) {
    return null
  }

  return (
    <CallDialogContainer>
      <MuiThemeProvider theme={ callDialogMuiTheme }>
        <Flex display="flex" flexDirection="column">
          {
            incomingCalls.map( ( incomingCall, index ) => (
              <CallDialogInnerContainer key={ index }>
                <CallHeaderText>{ connectCallHelpers.getContactText( incomingCall ) }</CallHeaderText>
                <CallStatusText>Incoming Call</CallStatusText>
                <Flex alignItems="center" display="flex" flexDirection="row" justifyContent="space-between">
                  <Tooltip title="Message">
                    <IconButton
                      disabled={ incomingCall && isAnonymousPhoneNumber( incomingCall.from ) }
                      onClick={ () => openLocalMessages( incomingCall.from ) }
                      sx={ incomingCallIconButtonStyles }
                    >
                      <MessageIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={ activeCall != null ? "Answer and End Current Call" : "Answer" }>
                    <IconButton onClick={ () => acceptIncomingCall( incomingCall ) } sx={ incomingCallAnswerButtonStyles }>
                      <IncomingCallIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Decline">
                    <IconButton onClick={ () => declineIncomingCall( incomingCall ) } sx={ incomingCallDeclineButtonStyles }>
                      <IncomingCallEndIcon />
                    </IconButton>
                  </Tooltip>
                </Flex>
              </CallDialogInnerContainer>
            ) )
          }
          {
            activeCall != null && (
              <div style={ incomingCalls.length ? { "borderTop": "1px solid rgba( 255, 255, 255, 0.4 )", "marginTop": "45px", "paddingTop": "20px" } : {} }>
                {
                  showKeypad && activeCallDigits.length > 0
                    ? (
                      <CallHeaderText>{ activeCallDigits }</CallHeaderText>
                    )
                    : (
                      <>
                        <CallHeaderText>{ formattedToNumber }</CallHeaderText>
                        {
                          activeCallStatus != null && (
                            <CallStatusText>{ activeCallStatus }</CallStatusText>
                          )
                        }
                      </>
                    )
                }
                {
                  showKeypad && (
                    <ActiveCallKeyPadContainer>
                      <ActiveCallKeyPadRow alignItems="center" display="flex" flexDirection="row" justifyContent="space-between">
                        <ActiveCallKeyPadKeyContainer alignItems="center" display="flex" flexDirection="column" onClick={ () => onKeyPressed( "1" ) }>
                          <ActiveCallKeyPadKeyText>1</ActiveCallKeyPadKeyText>
                          <ActiveCallKeyPadKeySmallText />
                        </ActiveCallKeyPadKeyContainer>
                        <ActiveCallKeyPadKeyContainer alignItems="center" display="flex" flexDirection="column" onClick={ () => onKeyPressed( "2" ) }>
                          <ActiveCallKeyPadKeyText>2</ActiveCallKeyPadKeyText>
                          <ActiveCallKeyPadKeySmallText>ABC</ActiveCallKeyPadKeySmallText>
                        </ActiveCallKeyPadKeyContainer>
                        <ActiveCallKeyPadKeyContainer alignItems="center" display="flex" flexDirection="column" onClick={ () => onKeyPressed( "3" ) }>
                          <ActiveCallKeyPadKeyText>3</ActiveCallKeyPadKeyText>
                          <ActiveCallKeyPadKeySmallText>DEF</ActiveCallKeyPadKeySmallText>
                        </ActiveCallKeyPadKeyContainer>
                      </ActiveCallKeyPadRow>
                      <ActiveCallKeyPadRow alignItems="center" display="flex" flexDirection="row" justifyContent="space-between">
                        <ActiveCallKeyPadKeyContainer alignItems="center" display="flex" flexDirection="column" onClick={ () => onKeyPressed( "4" ) }>
                          <ActiveCallKeyPadKeyText>4</ActiveCallKeyPadKeyText>
                          <ActiveCallKeyPadKeySmallText>GHI</ActiveCallKeyPadKeySmallText>
                        </ActiveCallKeyPadKeyContainer>
                        <ActiveCallKeyPadKeyContainer alignItems="center" display="flex" flexDirection="column" onClick={ () => onKeyPressed( "5" ) }>
                          <ActiveCallKeyPadKeyText>5</ActiveCallKeyPadKeyText>
                          <ActiveCallKeyPadKeySmallText>JKL</ActiveCallKeyPadKeySmallText>
                        </ActiveCallKeyPadKeyContainer>
                        <ActiveCallKeyPadKeyContainer alignItems="center" display="flex" flexDirection="column" onClick={ () => onKeyPressed( "6" ) }>
                          <ActiveCallKeyPadKeyText>6</ActiveCallKeyPadKeyText>
                          <ActiveCallKeyPadKeySmallText>MNO</ActiveCallKeyPadKeySmallText>
                        </ActiveCallKeyPadKeyContainer>
                      </ActiveCallKeyPadRow>
                      <ActiveCallKeyPadRow alignItems="center" display="flex" flexDirection="row" justifyContent="space-between">
                        <ActiveCallKeyPadKeyContainer alignItems="center" display="flex" flexDirection="column" onClick={ () => onKeyPressed( "7" ) }>
                          <ActiveCallKeyPadKeyText>7</ActiveCallKeyPadKeyText>
                          <ActiveCallKeyPadKeySmallText>PQRS</ActiveCallKeyPadKeySmallText>
                        </ActiveCallKeyPadKeyContainer>
                        <ActiveCallKeyPadKeyContainer alignItems="center" display="flex" flexDirection="column" onClick={ () => onKeyPressed( "8" ) }>
                          <ActiveCallKeyPadKeyText>8</ActiveCallKeyPadKeyText>
                          <ActiveCallKeyPadKeySmallText>TUV</ActiveCallKeyPadKeySmallText>
                        </ActiveCallKeyPadKeyContainer>
                        <ActiveCallKeyPadKeyContainer alignItems="center" display="flex" flexDirection="column" onClick={ () => onKeyPressed( "9" ) }>
                          <ActiveCallKeyPadKeyText>9</ActiveCallKeyPadKeyText>
                          <ActiveCallKeyPadKeySmallText>WXYZ</ActiveCallKeyPadKeySmallText>
                        </ActiveCallKeyPadKeyContainer>
                      </ActiveCallKeyPadRow>
                      <ActiveCallKeyPadRow alignItems="center" display="flex" flexDirection="row" justifyContent="space-between">
                        <ActiveCallKeyPadKeyContainer alignItems="center" display="flex" flexDirection="column" onClick={ () => onKeyPressed( "*" ) }>
                          <ActiveCallKeyPadKeyText>&#10033;</ActiveCallKeyPadKeyText>
                          <ActiveCallKeyPadKeySmallText />
                        </ActiveCallKeyPadKeyContainer>
                        <ActiveCallKeyPadKeyContainer alignItems="center" display="flex" flexDirection="column" onClick={ () => onKeyPressed( "0" ) }>
                          <ActiveCallKeyPadKeyText>0</ActiveCallKeyPadKeyText>
                          <ActiveCallKeyPadKeySmallText>+</ActiveCallKeyPadKeySmallText>
                        </ActiveCallKeyPadKeyContainer>
                        <ActiveCallKeyPadKeyContainer alignItems="center" display="flex" flexDirection="column" onClick={ () => onKeyPressed( "#" ) }>
                          <ActiveCallKeyPadKeyText>#</ActiveCallKeyPadKeyText>
                          <ActiveCallKeyPadKeySmallText />
                        </ActiveCallKeyPadKeyContainer>
                      </ActiveCallKeyPadRow>
                    </ActiveCallKeyPadContainer>
                  )
                }
                {
                  <>
                    <ActiveCallFlexRow alignItems="center" display="flex" flexDirection="row" justifyContent="space-between">
                      <Tooltip title="Mute">
                        <IconButton sx={ activeCall.isMuted ? activeCallActiveIconButtonStyles : activeCallIconButtonStyles } onClick={ onMute }>
                          <StyledMicOffIcon isMuted={ activeCall.isMuted } />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Keypad">
                        <IconButton sx={ showKeypad ? activeCallActiveIconButtonStyles : activeCallIconButtonStyles } onClick={ onToggleKeypad }>
                          <StyledDialpadIcon showKeypad={ showKeypad } />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Settings">
                        <IconButton sx={ showSettings ? activeCallActiveIconButtonStyles : activeCallIconButtonStyles } onClick={ onToggleSettings }>
                          <StyledSettingsIcon showSettings={ showSettings } />
                        </IconButton>
                      </Tooltip>
                    </ActiveCallFlexRow>
                    <ActiveCallFlexRow alignItems="center" display="flex" flexDirection="row" justifyContent="space-between">
                      <Tooltip title="Message">
                        <IconButton
                          disabled={ connectCall!.direction === ConnectCall.Direction.INBOUND && isAnonymousPhoneNumber( connectCall!.from ) }
                          onClick={ () => openLocalMessages( connectCall!.direction === ConnectCall.Direction.INBOUND ? connectCall!.from : connectCall!.to ) }
                          sx={ activeCallIconButtonStyles }
                        >
                          <MessageIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="End Call">
                        <IconButton sx={ activeCallDeclineButtonStyles } onClick={ hangup }>
                          <ActiveCallEndIcon />
                        </IconButton>
                      </Tooltip>
                      <ActiveCallEmptyItem />
                    </ActiveCallFlexRow>
                  </>
                }
                {
                  showSettings && (
                    <ActiveCallSettingsContainer display="flex" flexDirection="column" >
                      <ActiveCallSettingsSelector
                        color="white"
                        fullWidth
                        label="Microphone"
                        onChange={ ( value: string ) => onInputAudioDeviceSelected( value ) }
                        options={ inputAudioDevices }
                        value={ currentInputDeviceId }
                      />
                      <ActiveCallSettingsSelector
                        color="white"
                        fullWidth
                        label="Speaker"
                        onChange={ ( value: string ) => onOutputAudioDeviceSelected( value ) }
                        options={ outputAudioDevices }
                        value={ currentOutputDeviceId }
                      />
                    </ActiveCallSettingsContainer>
                  )
                }
              </div>
            )
          }
        </Flex>
      </MuiThemeProvider>
    </CallDialogContainer>
  )
}

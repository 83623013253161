import type { ApiConnectMerchantPhoneNumber, ApiConnectMerchantSettings, ApiConnectMerchantSummary } from "./api"

export interface ConnectMerchantPhoneNumber {
  countryCallingCode: number
  countryCode: string
  created: {
    at: string
	}
  deleted?: {
    at: string
	}
  id: string
  internationalFormat: string
  merchantId: string
  nationalFormat: string
  platform: {
    capabilities: ConnectMerchantPhoneNumber.CapabilityType[]
    twilioAccountId: string
    twilioMessagingServiceId?: string
    twilioPhoneId: string
    twilioVerificationInstance?: {
      error?: string
      id?: string
      status?: ConnectMerchantPhoneNumber.VerificationStatus
    }
    type: "twilio"
  }
  status: ConnectMerchantPhoneNumber.Status
  type: ConnectMerchantPhoneNumber.Type
  value: string
}

export namespace ConnectMerchantPhoneNumber {
  export const enum CapabilityType {
    FAX = "fax",
    MMS = "mms",
    SMS = "sms",
    VOICE = "voice",
  }

  export const enum Status {
    ACTIVE = "active",
    DISABLE = "disable",
  }

  export const enum Type {
    LOCAL = "local",
    TOLL_FREE = "toll_free",
  }

  export const enum VerificationStatus {
    IN_REVIEW = "in_review",
    PENDING_REVIEW = "pending_review",
    TWILIO_APPROVED = "twilio_approved",
    TWILIO_REJECTED = "twilio_rejected",
  }
}

export function parseConnectMerchantPhoneNumberFromApi( apiConnectMerchantPhoneNumber: ApiConnectMerchantPhoneNumber ) {
  const merchantPhoneNumber: ConnectMerchantPhoneNumber = {
    countryCallingCode: apiConnectMerchantPhoneNumber.country_calling_code,
    countryCode: apiConnectMerchantPhoneNumber.country_code,
    created: apiConnectMerchantPhoneNumber.created,
    id: apiConnectMerchantPhoneNumber.id,
    internationalFormat: apiConnectMerchantPhoneNumber.international_format,
    merchantId: apiConnectMerchantPhoneNumber.merchant_id,
    nationalFormat: apiConnectMerchantPhoneNumber.national_format,
    platform: {
      capabilities: apiConnectMerchantPhoneNumber.platform.capabilities,
      twilioAccountId: apiConnectMerchantPhoneNumber.platform.twilio_account_id,
      twilioMessagingServiceId: apiConnectMerchantPhoneNumber.platform.twilio_messaging_service_id,
      twilioPhoneId: apiConnectMerchantPhoneNumber.platform.twilio_phone_id,
      twilioVerificationInstance: apiConnectMerchantPhoneNumber.platform.twilio_verification_instance,
      type: apiConnectMerchantPhoneNumber.platform.type,
    },
    status: apiConnectMerchantPhoneNumber.status,
    type: apiConnectMerchantPhoneNumber.type,
    value: apiConnectMerchantPhoneNumber.value,
  }

  return merchantPhoneNumber
}

export interface ConnectMerchantSettings {
  features: Record<ConnectMerchantSettings.Features, boolean>
  id: string
  monthlyCallMinutesLimit?: number
  monthlyCallMinutesLimitReached?: {
    acknowledge: boolean
    date: string
    limit: number
  }
  queryIndex?: {
    merchantName?: string
  }
  twilio: {
    a2pError?: string
    a2pStatus: ConnectMerchantSettings.A2PStatus
    accountId: string
    messagingServiceId?: string
    messagingCampaignId?: string
    messagingBrand?: {
      failureReason?: string
      id: string
      status: ConnectMerchantSettings.A2PMessagingBrandStatus
    }
    secondaryCustomerProfile?: {
      addressDocumentEntityAssignmentId?: string
      addressDocumentId?: string
      addressId?: string
      authorizedRepresentativeEntityAssignmentId?: string
      bundleId?: string
      businessInformationEndUserEntityAssignmentId?: string
      businessInformationEndUserId?: string
      businessPrimaryAuthorizedRepresentativeEndUserId?: string
      evaluationErrors?: Array<{
        code: number
        message: string
      }>
      evaluationId?: string
      evaluationStatus?: ConnectMerchantSettings.A2PEvalutionStatus
      primaryProfileEntityAssignmentId?: string
      status?: ConnectMerchantSettings.A2PBundleStatus
    }
    starterCustomerProfile?: {
      addressId?: string
      addressDocumentId?: string
      addressDocumentEntityAssignmentId?: string
      bundleId?: string
      endUserId?: string
      endUserEntityAssignmentId?: string
      evaluationErrors?: Array<{
        code: number
        message: string
      }>
      evaluationId?: string
      evaluationStatus?: ConnectMerchantSettings.A2PEvalutionStatus
      primaryProfileEntityAssignmentId?: string
      status?: ConnectMerchantSettings.A2PBundleStatus
    }
    trustProduct?: {
      bundleId: string
      entityAssignmentId?: string
      evaluationErrors?: Array<{
        code: number
        message: string
      }>
      evaluationId?: string
      evaluationStatus?: ConnectMerchantSettings.A2PEvalutionStatus
      status?: ConnectMerchantSettings.A2PBundleStatus
    }
    voice: {
      apiKey: string
      apiSecret: string
      appSid: string
      pushCredentials: {
        apn: string
        apnVersion?: number
        fcm?: string
      }
    }
  }
}

export namespace ConnectMerchantSettings {
  export enum A2PBundleStatus {
    DRAFT = "draft",
    IN_REVIEW = "in-review",
    PENDING_REVIEW = "pending-review",
    TWILIO_APPROVED = "twilio-approved",
    TWILIO_REJECTED = "twilio-rejected",
  }

  export enum A2PEvalutionStatus {
    COMPLIANT = "compliant",
    NONCOMPLIANT = "noncompliant",
  }

  export enum A2PMessagingBrandStatus {
    APPROVED = "approved",
    FAILED = "failed",
    PENDING = "pending",
  }

  export const enum A2PStatus {
    IN_PROGRESS = "in_progress",
    IN_REVIEW = "in_review",
    INVALID = "invalid",
    NOT_REGISTER = "not_register",
    REGISTERED = "registered",
  }

  export enum Features {
    PHONE_CALL = "phone_call",
  }
}

export function parseConnectMerchantSettingsFromApi( apiConnectMerchantSettings: ApiConnectMerchantSettings ) {
  const merchant: ConnectMerchantSettings = {
    features: apiConnectMerchantSettings.features,
    id: apiConnectMerchantSettings.id,
    monthlyCallMinutesLimit: apiConnectMerchantSettings.monthly_call_minutes_limit,
    monthlyCallMinutesLimitReached: apiConnectMerchantSettings.monthly_call_minutes_limit_reached,
    queryIndex: ( apiConnectMerchantSettings.query_index
      ? {
        merchantName: apiConnectMerchantSettings.query_index.merchant_name,
      }
      : undefined
    ),
    twilio: {
      a2pError: apiConnectMerchantSettings.twilio.a2p_error,
      a2pStatus: apiConnectMerchantSettings.twilio.a2p_status,
      accountId: apiConnectMerchantSettings.twilio.account_id,
      voice: {
        apiKey: apiConnectMerchantSettings.twilio.voice.api_key,
        apiSecret: apiConnectMerchantSettings.twilio.voice.api_secret,
        appSid: apiConnectMerchantSettings.twilio.voice.app_sid,
        pushCredentials: apiConnectMerchantSettings.twilio.voice.push_credentials,
      },
      messagingCampaignId: apiConnectMerchantSettings.twilio.messaging_campaign_id,
      messagingServiceId: apiConnectMerchantSettings.twilio.messaging_service_id,
      messagingBrand: ( apiConnectMerchantSettings.twilio.messaging_brand
        ? {
          failureReason: apiConnectMerchantSettings.twilio.messaging_brand.failure_reason,
          id: apiConnectMerchantSettings.twilio.messaging_brand.id,
          status: apiConnectMerchantSettings.twilio.messaging_brand.status,
        }
        : undefined
      ),
      starterCustomerProfile: ( apiConnectMerchantSettings.twilio.starter_customer_profile
        ? {
          addressDocumentEntityAssignmentId: apiConnectMerchantSettings.twilio.starter_customer_profile.address_document_entity_assignment_id,
          addressDocumentId: apiConnectMerchantSettings.twilio.starter_customer_profile.address_document_id,
          addressId: apiConnectMerchantSettings.twilio.starter_customer_profile.address_id,
          bundleId: apiConnectMerchantSettings.twilio.starter_customer_profile.bundle_id,
          endUserEntityAssignmentId: apiConnectMerchantSettings.twilio.starter_customer_profile.end_user_entity_assignment_id,
          endUserId: apiConnectMerchantSettings.twilio.starter_customer_profile.end_user_id,
          evaluationErrors: apiConnectMerchantSettings.twilio.starter_customer_profile.evaluation_errors,
          evaluationId: apiConnectMerchantSettings.twilio.starter_customer_profile.evaluation_id,
          evaluationStatus: apiConnectMerchantSettings.twilio.starter_customer_profile.evaluation_status,
          primaryProfileEntityAssignmentId: apiConnectMerchantSettings.twilio.starter_customer_profile.primary_profile_entity_assignment_id,
          status: apiConnectMerchantSettings.twilio.starter_customer_profile.status,
        }
        : undefined
      ),
      secondaryCustomerProfile: ( apiConnectMerchantSettings.twilio.secondary_customer_profile
        ? {
          addressDocumentEntityAssignmentId: apiConnectMerchantSettings.twilio.secondary_customer_profile.address_document_entity_assignment_id,
          addressDocumentId: apiConnectMerchantSettings.twilio.secondary_customer_profile.address_document_id,
          addressId: apiConnectMerchantSettings.twilio.secondary_customer_profile.address_id,
          bundleId: apiConnectMerchantSettings.twilio.secondary_customer_profile.bundle_id,
          businessInformationEndUserEntityAssignmentId: apiConnectMerchantSettings.twilio.secondary_customer_profile.business_information_end_user_entity_assignment_id,
          businessInformationEndUserId: apiConnectMerchantSettings.twilio.secondary_customer_profile.business_information_end_user_id,
          evaluationErrors: apiConnectMerchantSettings.twilio.secondary_customer_profile.evaluation_errors,
          evaluationId: apiConnectMerchantSettings.twilio.secondary_customer_profile.evaluation_id,
          evaluationStatus: apiConnectMerchantSettings.twilio.secondary_customer_profile.evaluation_status,
          primaryProfileEntityAssignmentId: apiConnectMerchantSettings.twilio.secondary_customer_profile.primary_profile_entity_assignment_id,
          status: apiConnectMerchantSettings.twilio.secondary_customer_profile.status,
        }
        : undefined
      ),
    },
  }

  return merchant
}

export interface ConnectMerchantSummary {
  a2p: {
    errors?: string[]
    status?: ConnectMerchantSettings.A2PStatus
  }
  merchantId: string
  merchantName: string
  phoneNumbers: ConnectMerchantSummary.PhoneNumber[]
  smsOutboundBlocked: boolean
  status: {
    type: ConnectMerchantSummary.Status
    details?: string
  }
}

export namespace ConnectMerchantSummary {
  export interface PhoneNumber {
    id: string
    nationalFormat: string
    status: ConnectMerchantPhoneNumber.Status
    twilioVerificationInstance?: {
      error?: string
      id?: string
      status?: ConnectMerchantPhoneNumber.VerificationStatus
    }
    type: ConnectMerchantPhoneNumber.Type
    value: string
  }
  export const enum Status {
    COMPLETED = "completed",
    ERROR = "error",
    IN_PROGRESS = "in_progress",
    NOT_STARTED = "not_started",
  }
}

export function parseConnectMerchantSummaryFromApi( apiConnectMerchantSummary: ApiConnectMerchantSummary ) {
  const merchantSummary: ConnectMerchantSummary = {
    a2p: apiConnectMerchantSummary.a2p,
    merchantId: apiConnectMerchantSummary.merchant_id,
    merchantName: apiConnectMerchantSummary.merchant_name,
    phoneNumbers: apiConnectMerchantSummary.phone_numbers.map( ( apiPhoneNumber ) => ( {
      id: apiPhoneNumber.id,
      nationalFormat: apiPhoneNumber.national_format,
      status: apiPhoneNumber.status,
      type: apiPhoneNumber.type,
      value: apiPhoneNumber.value,
      twilioVerificationInstance: apiPhoneNumber.twilio_verification_instance,
    } ) ),
    smsOutboundBlocked: apiConnectMerchantSummary.merchant_name === "gotvitch" ? true : apiConnectMerchantSummary.sms_outbound_blocked,
    status: apiConnectMerchantSummary.status,
  }

  return merchantSummary
}

import styled from "styled-components"

import { styleHelpers } from "../helpers/styleHelpers"

export const StyledTable = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;

  thead {
    // Border radius cannot be apply to the tr element so add border radius
    // to first and last cell
    tr {
      &:first-child {
        th {
          &:first-child {
            border-top-left-radius: 5px;
          }

          &:last-child {
            border-top-right-radius: 5px;
          }
        }
      }

      &:last-child {
        border-bottom: 2px solid #ddd;
      }
    }
  }

  th {
    background-color: #f0f8ff;
    color: ${ styleHelpers.colors.dark };
    font-weight: ${ styleHelpers.fonts.weight.semiBold };
    padding: 15px;
    text-align: left;
  }

  tbody {
    tr {
      border-bottom: 1px solid #E0E0E0;
    }
  }
`

export const StyledTableRow = styled.tr`
  td {
    padding: 15px;
  }
`

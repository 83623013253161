import CheckIcon from "@mui/icons-material/Check"
import { sortBy } from "lodash"
import React, { useCallback, useMemo, useState } from "react"
import type { SubmitHandler } from "react-hook-form"
import { Controller, FormProvider } from "react-hook-form"
import { useSelector } from "react-redux"
import styled from "styled-components"

import { useAsyncDispatch, useForm } from "@onelocal/frontend/common"
import { isValidEmail } from "@onelocal/frontend-dashboard/common"
import type { DialogAction, DialogAlert, SelectOption } from "@onelocal/frontend-web/common"
import { CheckBox, Dialog, DialogAlertType, Grid, GridItem, Link, Select, StyledFormSectionTitle, TextField, useAlert, useConfirmationModal, useModalWithParams } from "@onelocal/frontend-web/common"
import { authActions } from ".."
import { authInternalAccountHelpers } from "../helpers/authInternalAccountHelpers"
import { useCurrentInternalAccount } from "../hooks"
import { authSelectors } from "../store/authSelectors"
import { AuthInternalAccount } from "../types"

const StyledSelect = styled( Select )`
  margin-top: 20px;
  width: 100%;
`

const StyledFieldContainer = styled.div`
  align-items: center;
  display: flex;
  margin: 20px 0;
`

const StyledActionLink = styled( Link )`
  & + & {
    margin-left: 15px;
  }
`

enum FormFieldKeys {
  EMAIL = "email",
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  ROLE = "role",
  SALESFORCE_ID = "salesforceId",
}

type FormValues = {
  [ FormFieldKeys.EMAIL ]: string
  [ FormFieldKeys.FIRST_NAME ]: string
  [ FormFieldKeys.LAST_NAME ]: string
  [ FormFieldKeys.ROLE ]: string
  [ FormFieldKeys.SALESFORCE_ID ]: string
} & Record<AuthInternalAccount.Permission, boolean>

const allowedPermissions = [
  AuthInternalAccount.Permission.AGENCY_ANALYTICS,
  AuthInternalAccount.Permission.API_KEYS,
  AuthInternalAccount.Permission.CREDENTIALS,
  AuthInternalAccount.Permission.DELETE_TESTIMONIAL,
  AuthInternalAccount.Permission.HIPAA,
  AuthInternalAccount.Permission.MERCHANT_DELETE,
  AuthInternalAccount.Permission.QA,
  AuthInternalAccount.Permission.USER_MANAGEMENT,
]

const techAllowedPermissions = [
  AuthInternalAccount.Permission.AGENCY_ANALYTICS,
  AuthInternalAccount.Permission.QA,
]

const roleOptions: Array<SelectOption<AuthInternalAccount.Role>> = sortBy(
  [
    AuthInternalAccount.Role.ADMIN,
    AuthInternalAccount.Role.CUSTOMER_SUCCESS,
    AuthInternalAccount.Role.DELIVERY,
    AuthInternalAccount.Role.SALES,
    AuthInternalAccount.Role.TECH,
  ].map( ( role ) => ( {
    label: authInternalAccountHelpers.getDisplayRole( role ),
    value: role,
  } ) ),
  ( option ) => option.label,
)

export interface EditInternalUserModalProps {
  internalAccountId?: string
  isOpen: boolean

  onClose( reOpen?: boolean ): void
  onCreated?( internalAccount: AuthInternalAccount ): void
  onExited: () => void
}

export const EditInternalUserModal: React.FC<EditInternalUserModalProps> = ( {
  internalAccountId,
  isOpen,
  onCreated,
  onClose,
  onExited,
} ) => {
  const { currentInternalAccount } = useCurrentInternalAccount()
  const dispatch = useAsyncDispatch()
  const internalAccount = useSelector( internalAccountId ? authSelectors.internalAccounts.byId( internalAccountId ) : () => null )

  const defaultPermission = useMemo( () => {
    const permissions: Record<AuthInternalAccount.Permission, boolean> = {} as Record<AuthInternalAccount.Permission, boolean>
    let accountPermissions: AuthInternalAccount.Permission[] = []

    if( internalAccountId ) {
      accountPermissions = internalAccount?.permissions || []
    } else if( currentInternalAccount.role === AuthInternalAccount.Role.ADMIN ) {
      accountPermissions = [ ...allowedPermissions ]
      if( ! currentInternalAccount.permissions.includes( AuthInternalAccount.Permission.HIPAA ) ) {
        const hipaa_permission_index = accountPermissions.indexOf( AuthInternalAccount.Permission.HIPAA )
        if( hipaa_permission_index !== -1 ) {
          accountPermissions.splice( hipaa_permission_index, 1 )
        }
      }
    } else if( currentInternalAccount.role === AuthInternalAccount.Role.TECH ) {
      accountPermissions = techAllowedPermissions
    }

    for( const permission of accountPermissions ) {
      permissions[ permission ] = true
    }

    return permissions
  }, [ currentInternalAccount.permissions, currentInternalAccount.role, internalAccount?.permissions, internalAccountId ] )

  const permissionOptions: Array<SelectOption<AuthInternalAccount.Permission>> = useMemo( () => {
    const isPermissionDisabled = ( permission: AuthInternalAccount.Permission ) => {
      switch( permission ) {
        case AuthInternalAccount.Permission.AGENCY_ANALYTICS: {
          if( [ AuthInternalAccount.Role.TECH, AuthInternalAccount.Role.TECH ].includes( currentInternalAccount.role )
              || currentInternalAccount.permissions.includes( permission )
          ) {
            return false
          }

          return true
        }
        case AuthInternalAccount.Permission.HIPAA: {
          return ! currentInternalAccount?.permissions.includes( permission )
        }
        default: {
          return false
        }
      }
    }

    return sortBy(
      allowedPermissions.map( ( permission ) => ( {
        disabled: isPermissionDisabled( permission ),
        label: authInternalAccountHelpers.getDisplayPermissions( permission )!,
        value: permission,
      } ) ),
      ( option ) => option.label,
    )
  }, [ currentInternalAccount.permissions, currentInternalAccount.role ] )

  const [ alert, setAlert ] = useState<DialogAlert | undefined>( undefined )
  const formMethods = useForm<FormValues>( {
    defaultValues: {
      [ FormFieldKeys.EMAIL ]: internalAccount?.email || "",
      [ FormFieldKeys.FIRST_NAME ]: internalAccount?.name?.given || "",
      [ FormFieldKeys.LAST_NAME ]: internalAccount?.name?.family || "",
      [ FormFieldKeys.ROLE ]: internalAccountId ? internalAccount?.role || "" : currentInternalAccount.role,
      [ FormFieldKeys.SALESFORCE_ID ]: internalAccount?.salesforce_id || "",
      ...defaultPermission,
    },
  } )
  const { control, formState, handleSubmit, setValue } = formMethods
  const { showConfirmationModal } = useConfirmationModal()
  const { showSuccess } = useAlert()

  const updatePermissions = useCallback( ( role: AuthInternalAccount.Role ) => {
    if( internalAccountId ) {
      return
    }

    for( const permission of allowedPermissions ) {
      if( ( role === AuthInternalAccount.Role.ADMIN && ( permission !== AuthInternalAccount.Permission.HIPAA || currentInternalAccount.permissions.includes( AuthInternalAccount.Permission.HIPAA ) ) )
          || ( role === AuthInternalAccount.Role.TECH && techAllowedPermissions.includes( permission ) )
      ) {
        setValue( permission, true )
      } else {
        setValue( permission, false )
      }
    }
  }, [ currentInternalAccount.permissions, internalAccountId, setValue ] )

  const onRoleSelect = useCallback( ( onChange: ( value: string ) => void ) => {
    return ( role: AuthInternalAccount.Role ) => {
      updatePermissions( role )
      onChange( role )
    }
  }, [ updatePermissions ] )

  const onSave: SubmitHandler<FormValues> = useCallback( async ( data ) => {
    try {
      const permissions: AuthInternalAccount.Permission[] = []

      for( const permission of Object.values( AuthInternalAccount.Permission ) ) {
        if( data[ permission ] ) {
          permissions.push( permission )
        }
      }

      if( internalAccount ) {
        await dispatch( authActions.internalAccounts.update( internalAccount.id, {
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          permissions,
          role: data.role as AuthInternalAccount.Role,
          salesforceId: data.salesforceId,
          username: data.email,
        } ) )
        showSuccess( "User has been updated" )
      } else {
        const createdInternalAccount = await dispatch( authActions.internalAccounts.create( {
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          permissions,
          role: data.role as AuthInternalAccount.Role,
          salesforceId: data.salesforceId,
          username: data.email,
        } ) )

        showSuccess( "User has been created" )

        if( onCreated ) {
          onCreated( createdInternalAccount )
        }
      }

      onClose()

    } catch( err ) {
      setAlert( {
        message: err,
        type: DialogAlertType.ERROR,
      } )
    }
  }, [ internalAccount, onClose, dispatch, onCreated, showSuccess ] )

  const resetPassword = useCallback( () => {
    if( ! internalAccount ) {
      return
    }
    showConfirmationModal( {
      confirmText: "Reset Password",
      message: "Are you sure you want to send this user a new password ?",
      onConfirm: async () => {
        try {
          await dispatch( authActions.internalAccounts.resetPassword( internalAccount.id ) )
          showSuccess( "Password has been reset" )
        } catch( err ) {
          setAlert( {
            message: err,
            type: DialogAlertType.ERROR,
          } )
        }
      },
      title: "Reset Password",
    } )
  }, [ dispatch, internalAccount, showConfirmationModal, showSuccess ] )

  const enabledUser = useCallback( async () => {
    if( ! internalAccount ) {
      return
    }

    try {
      await dispatch( authActions.internalAccounts.setEnable( internalAccount.id, true ) )
      showSuccess( "User has been enabled" )
    } catch( err ) {
      setAlert( {
        message: err,
        type: DialogAlertType.ERROR,
      } )
    }
  }, [ dispatch, internalAccount, showSuccess ] )

  const disableUser = useCallback( async () => {
    if( ! internalAccount ) {
      return
    }

    showConfirmationModal( {
      confirmText: "Disable User",
      message: `Are you sure you want to disable ${ internalAccount.username }? This user will loose access to the OneLocal dashboard immediately.`,
      onConfirm: async () => {
        try {
          await dispatch( authActions.internalAccounts.setEnable( internalAccount.id, false ) )
          showSuccess( "User has been disabled" )
          onClose()
        } catch( err ) {
          setAlert( {
            message: err,
            type: DialogAlertType.ERROR,
          } )
        }
      },
      title: "Disable User",
    } )
  }, [ dispatch, internalAccount, onClose, showConfirmationModal, showSuccess ] )

  const deleteInternalAccount = useCallback( () => {
    if( ! internalAccount ) {
      return
    }
    showConfirmationModal( {
      confirmText: "Delete User",
      message: "Are you sure you want to delete this user ?",
      onConfirm: async () => {
        try {
          await dispatch( authActions.internalAccounts.delete( internalAccount.id ) )
          showSuccess( "User has been deleted" )
          onClose()
        } catch( err ) {
          setAlert( {
            message: err,
            type: DialogAlertType.ERROR,
          } )
        }
      },
      title: "Delete User",
    } )
  }, [ dispatch, internalAccount, onClose, showConfirmationModal, showSuccess ] )

  const modalActions: DialogAction[] = useMemo( () => {
    const actions: DialogAction[] = [
      { title: "Cancel", type: "dismiss" },
    ]

    actions.push( {
      icon: ( <CheckIcon style={ { height: 18, width: 18 } }/> ),
      onClick: handleSubmit( onSave, () => {
        setAlert( {
          message: "Please make sure all required fields are filled.",
          type: DialogAlertType.ERROR,
        } )
      } ),
      title: "Confirm",
      type: "primary",
    } )

    return actions
  }, [ handleSubmit, onSave ] )

  return (
    <Dialog
      actions={ modalActions }
      alert={ alert }
      contentContainerStyle={ {
        paddingLeft: "24px",
        paddingRight: "24px",
      } }
      isOpen={ isOpen }
      onClose={ onClose }
      onExited={ onExited }
      showDividers={ false }
      title={ internalAccount ? `Edit User${ internalAccount.disabledAt ? " (Disabled)" : "" }` : "Add New User" }
    >
      <FormProvider { ...formMethods }>
        <StyledFormSectionTitle>User Information</StyledFormSectionTitle>
        <StyledFieldContainer>
          <Grid spacing={ 4 }>
            <GridItem xs={ 6 }>
              <Controller
                control={ control }
                name={ FormFieldKeys.FIRST_NAME }
                render={ ( { field: { onChange, value } } ) => (
                  <TextField
                    errorMessage={ formState.errors?.[ FormFieldKeys.FIRST_NAME ]?.message }
                    fullWidth
                    label="First Name"
                    onChange={ onChange }
                    required={ true }
                    value={ value || "" }
                  />
                ) }
                rules={ {
                  validate: ( value ) => {
                    value = value.trim()

                    if( ! value ) {
                      return "First Name is required"
                    }
                    return undefined
                  },
                } }
              />
            </GridItem>
            <GridItem xs={ 6 }>
              <Controller
                control={ control }
                name={ FormFieldKeys.LAST_NAME }
                render={ ( { field: { onChange, value } } ) => (
                  <TextField
                    errorMessage={ formState.errors?.[ FormFieldKeys.LAST_NAME ]?.message }
                    fullWidth
                    label="Last Name"
                    onChange={ onChange }
                    required={ true }
                    value={ value || "" }
                  />
                ) }
                rules={ {
                  validate: ( value ) => {
                    value = value.trim()

                    if( ! value ) {
                      return "Last Name is required"
                    }
                    return undefined
                  },
                } }
              />
            </GridItem>
            <GridItem xs={ 6 }>
              <Controller
                control={ control }
                name={ FormFieldKeys.EMAIL }
                render={ ( { field: { onChange, value } } ) => (
                  <TextField
                    errorMessage={ formState.errors?.[ FormFieldKeys.EMAIL ]?.message }
                    fullWidth
                    label="Email"
                    onChange={ onChange }
                    required={ true }
                    type="email"
                    value={ value || "" }
                  />
                ) }
                rules={ {
                  validate: ( value ) => {
                    if( ! value ) {
                      return "Email is required"
                    }
                    if( ! isValidEmail( value ) ) {
                      return "Email is invalid"
                    }
                    if( ! value.endsWith( "@onelocal.com" ) && ! value.endsWith( "@gatalabs.com" ) ) {
                      return "The email must end with @onelocal.com or @gatalabs.com"
                    }

                    return undefined
                  },
                } }
              />
            </GridItem>
            <GridItem xs={ 6 }>
              <Controller
                control={ control }
                name={ FormFieldKeys.SALESFORCE_ID }
                render={ ( { field: { onChange, value } } ) => (
                  <TextField
                    errorMessage={ formState.errors?.[ FormFieldKeys.SALESFORCE_ID ]?.message }
                    fullWidth
                    label="SalesForce ID"
                    onChange={ onChange }
                    value={ value || "" }
                  />
                ) }
              />
            </GridItem>
          </Grid>
        </StyledFieldContainer>
        <StyledFormSectionTitle>User Permissions</StyledFormSectionTitle>
        <Controller
          control={ control }
          name={ FormFieldKeys.ROLE }
          render={ ( { field: { onChange, value } } ) => {
            const error = formState.errors?.[ FormFieldKeys.ROLE ]?.message
            return (
              <StyledSelect
                disabled={ currentInternalAccount.role !== AuthInternalAccount.Role.ADMIN }
                error={ error != null }
                helpText={ error }
                onChange={ onRoleSelect( onChange ) }
                label="User Role"
                required={ true }
                value={ value || "" }
                options={ roleOptions }
              />
            )
          } }
          rules={ {
            validate: ( value ) => {
              if( ! value ) {
                return "Role is required"
              }
              return undefined
            },
          } }
        />
        <StyledFieldContainer>
          <Grid alignItems="center">
            {
              permissionOptions.map( ( permissionOption ) => (
                <GridItem key={ permissionOption.value } xs={ 6 }>
                  <Controller
                    control={ control }
                    name={ permissionOption.value }
                    render={ ( { field: { onChange, value } } ) => (
                      <CheckBox
                        checkboxColor="primary"
                        checked={ value || false }
                        disabled={ permissionOption.disabled }
                        label={ permissionOption.label }
                        onChange={ onChange }
                        name={ permissionOption.value }
                        noLabelWrap={ true }
                      />
                    ) }
                  />
                </GridItem>
              ) )
            }
          </Grid>
        </StyledFieldContainer>
        {
          internalAccount != null && (
            <div>
              <StyledActionLink onClick={ resetPassword }>Reset Password</StyledActionLink>
              {
                internalAccount.disabledAt != null
                  ? (
                    <StyledActionLink onClick={ enabledUser }>Enable User</StyledActionLink>
                  )
                  : (
                    <StyledActionLink onClick={ disableUser }>Disable User</StyledActionLink>
                  )
              }
              <StyledActionLink onClick={ deleteInternalAccount }>Delete</StyledActionLink>
            </div>
          )
        }
      </FormProvider>
    </Dialog>
  )
}

export interface useEditInternalUserModalParams {
  internalAccountId?: string
  onCreated?( internalAccount: AuthInternalAccount ): void
}

export const useEditInternalUserModal = () => {
  const { showModal, hideModal } = useModalWithParams<useEditInternalUserModalParams>( ( { open, onExited, params } ) => {
    if( ! params ) {
      return null
    }

    return (
      <EditInternalUserModal
        internalAccountId={ params.internalAccountId }
        isOpen={ open }
        onCreated={ params.onCreated }
        onClose={ hideModal }
        onExited={ onExited }
      />
    )
  }, [] )

  return {
    showEditInternalUserModal: showModal,
    hideEditInternalUserModal: hideModal,
  }
}

import { createSelector } from "@reduxjs/toolkit"

import type { MainPerformanceInsight } from "../types"
import type { MainState } from "./mainStore"

type MainStateSelector<TRootState> = [
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ( state: TRootState, ...params: any[] ) => MainState
]

export const mainSelector = <TRootState extends { main: MainState }>( state: TRootState ) => state.main

export const createMainSelector = <TRootState extends { main: MainState }, TReturnValue>( combiner: ( mainState: MainState ) => TReturnValue ) => {
  return createSelector<MainStateSelector<TRootState>, TReturnValue>(
    mainSelector,
    combiner,
  )
}

export const mainActivitiesSelector = ( activitiesIds: string[] ) => (
  createMainSelector(
    ( mainState ) => activitiesIds.map( ( activityId ) => mainState.activities.byId[ activityId ] ).filter( ( activity ) => activity != null ),
  )
)

export const mainSelectors = {
  activeActivityId: createMainSelector( ( mainState ) => mainState.activeActivityId ),
  activities: {
    stats: createMainSelector( ( mainState ) => mainState.activities.stats ),
    byId: ( activityId: string ) => (
      createMainSelector(
        ( mainState ) => mainState.activities.byId[ activityId ] || null,
      )
    ),
    hasNewActivity: createMainSelector( ( mainState ) => mainState.activities.hasNewActivity ),
  },
  isActivityExpanded: createMainSelector( ( mainState ) => mainState.isActivityExpanded ),
  performance: {
    insightByPeriod: ( period: MainPerformanceInsight.Period ) => (
      createMainSelector(
        ( mainState ) => mainState.performance.byPeriod[ period ] || null,
      )
    ),
    summary: createMainSelector( ( mainState ) => mainState.performance.summary ),
  },
  tasks: {
    byId: ( taskId: string ) => (
      createMainSelector(
        ( mainState ) => mainState.tasks.byId[ taskId ] || null,
      )
    ),
  },
}

import type { AxiosRequestConfig, AxiosResponse } from "axios"
import axios from "axios"
import { isArray, isEmpty, omit } from "lodash"
import type { SetRequired } from "type-fest"

import type { ApiRequestProgressEvent } from "@onelocal/shared/common"
import type { PaginatedItems } from "../types"
import { errorHelper, InternalError } from "./errorHelper"

export interface ApiErrorOptions {
  code?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
  parentError?: Error
  statusCode?: number
  type: string
}

export class ApiError extends Error {
  code?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
  message: string
  parentError?: Error
  statusCode?: number
  type: string

  constructor( message: string, options: ApiErrorOptions ) {
    super( message )
    this.code = options.code
    this.data = options.data
    this.message = message
    this.parentError = options.parentError
    this.statusCode = options.statusCode
    this.type = options.type
  }
}

export type ApiHttpMethod =
  | "GET"
  | "DELETE"
  | "HEAD"
  | "OPTIONS"
  | "POST"
  | "PUT"
  | "PATCH"
  | "LINK"
  | "UNLINK"

export interface ApiRequestOptionBase {
  authToken?: string
  data?: unknown
  headers?: { [key: string]: string | number }
  returnFullResponse?: boolean
  method: ApiHttpMethod
  params?: ApiRequestParams
  url: string
  timeout?: number
  onUploadProgress?: ( progressEvent: ApiRequestProgressEvent ) => void
}

export interface ApiRequestOption<TApiResult, TResult>
  extends ApiRequestOptionBase {
  authToken?: string
  data?: unknown
  headers?: { [key: string]: string | number }
  returnFullResponse?: boolean
  method: ApiHttpMethod
  params?: ApiRequestParams
  // @todo Handle promise
  parsingData?: ( apiResult: TApiResult ) => TResult
  url: string
  timeout?: number
}

export interface ApiRequestParams {
  [key: string]: string | number | boolean | undefined | null
}

async function sendRequest<TResult>(
  options: ApiRequestOptionBase,
): Promise<AxiosResponse<TResult>> {
  // async function sendRequest<TApiResult, TResult>( options: ApiRequestOptionWithParsing<TApiResult, TResult> ): Promise<AxiosResponse<TApiResult>> {
  // if( configHelper.ENV !== ConfigEnv.TEST ) {
  //   logHelper.info( `API Request: ${ options.method } ${ options.url }`, options )
  // }

  // const deviceInfo = deviceHelper.getDeviceInfo()

  const config: AxiosRequestConfig = {
    method: options.method,
    url: `${ baseUrl }${ options.url }`,
    data: options.data,
    headers: {
      // "application-id": `dashboard/mobile/${ configHelper.OS }`,
      // "bundle-id": deviceInfo.bundleId,
      // "version": deviceHelper.getVersion(),
    },
    params: options.params,
    timeout: options.timeout || 15 * 1000,
    onUploadProgress: ( progressEvent ) => {
      if( options.onUploadProgress && progressEvent.total !== undefined ) {
        options.onUploadProgress?.( { loaded: progressEvent.loaded, progress: Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ), total: progressEvent.total } )
      }
    },
  }

  const authToken = options.authToken || apiHelper.authToken

  if( authToken ) {
    config.headers!.Authorization = `Token ${ authToken }`
  }

  if( ! isEmpty( options.headers ) ) {
    Object.assign( config.headers!, options.headers )
  }

  const response = await axios.request<TResult>( config ).catch( ( error ) => {
    const apiError = parseApiError( error )

    errorHelper.logError( error, {
      extras: {
        requestMethod: options.method,
        requestUrl: options.url,
        requestParams: options.params,
        requestData: JSON.stringify( options.data ),
        responseData: JSON.stringify( apiError.data ),
        responseStatus: apiError.statusCode,
      },
    } )

    throw apiError
  } )

  return response
}

const parseApiError = (
  error: {
    code?: string
    response?: {
      data?: {
        errors?: Array<{
          code?: string
        }>
        message?: string
        type?: string
      }
      status: number
    }
  } | null,
  defaultErrorMessage?: string,
) => {
  let message
  let type

  if( ! error?.response ) {
    const apiErrorOptions: ApiErrorOptions = {
      type: "error",
    }

    if( error instanceof Error ) {
      apiErrorOptions.parentError = error
    }

    if( error?.code === "ECONNABORTED" ) {
      apiErrorOptions.code = "timeout"
      message = "Request Timeout"
    }

    // @todo Should improve network error
    // https://facebook.github.io/react-native/docs/netinfo.html
    return new ApiError(
      message || defaultErrorMessage || "Unknown Error",
      apiErrorOptions,
    )
  }

  const { response } = error

  if( response.data ) {
    if( response.data.message ) {
      message = response.data.message
    }
    if( response.data.type ) {
      type = response.data.type
    }
  }

  const apiErrorOptions: ApiErrorOptions = {
    statusCode: response.status,
    type: type || "error",
    data: response.data,
  }

  if( ! isEmpty( response.data?.errors ) ) {
    apiErrorOptions.code = response.data?.errors?.[ 0 ].code
  }

  return new ApiError(
    message || defaultErrorMessage || "Unknown Error",
    apiErrorOptions,
  )
}

let baseUrl = ""

export const apiHelper = {
  authToken: null as string | null,
  configure( options: { baseUrl: string } ) {
    baseUrl = options.baseUrl
  },
  async getPaginatedItems<TApiResult, TResult>( options: SetRequired<ApiRequestOption<TApiResult, TResult>, "parsingData"> ) {
    const response = await sendRequest<TApiResult[]>(
      omit( options, "parsingData" ),
    )

    const page = parseInt( response.headers.page, 10 )
    const perPage = parseInt( response.headers[ "per-page" ], 10 )
    const total = parseInt( response.headers[ "total-count" ], 10 )

    if( ! isArray( response.data ) ) {
      throw new InternalError( "Invalid data returned, not an array" )
    }

    let parsedItems: TResult[]

    try {
      parsedItems = response.data.map( options.parsingData )
    } catch( err ) {
      errorHelper.logError( err, {
        extras: {
          requestMethod: options.method,
          requestUrl: options.url,
          requestParams: options.params,
          requestData: options.data,
          responseData: response.data,
          responseStatus: response.status,
        },
      } )

      throw new InternalError( `${ err }`, { originalError: err } )
    }

    const paginatedItems: PaginatedItems<TResult> = {
      items: parsedItems,
      // Use hasMore in case there is a problem with the parsing of a item
      hasMore: ( page - 1 ) * perPage + response.data.length < total,
      page,
      perPage,
      total,
    }

    return paginatedItems
  },
  async request<TApiResult, TResult>(
    options: ApiRequestOption<TApiResult, TResult>,
  ): Promise<TResult> {
    const response = await sendRequest<TApiResult>(
      omit( options, "parsingData" ),
    )

    if( options.parsingData ) {
      let parsedData: TResult
      try {
        parsedData = options.parsingData( response.data )
      } catch( err ) {
        errorHelper.logError( err, {
          extras: {
            requestMethod: options.method,
            requestUrl: options.url,
            requestParams: options.params,
            requestData: options.data,
            responseData: response.data,
            responseStatus: response.status,
          },
        } )

        throw new InternalError( `${ err }`, { originalError: err } )
      }

      return parsedData
    }

    return ( response.data as unknown ) as TResult
  },
  async upload<T>( url: string, path: string, options?: { onUploadProgress?: ( ( progressEvent: ApiRequestProgressEvent ) => void ) } ) {
    const formData = new FormData()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    formData.append( "file", {
      uri: path,
      // @todo Pass as parameter
      name: "image.jpg",
      type: "image/jpg",
    } )

    return apiHelper.request<T, T>( {
      method: "POST",
      url,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
      timeout: 30 * 1000,
      ...( options || {} ),
    } )
  },
}

// declare const global: {
//   authToken?: string | null
// }

// Maintain the authToken with HMR
// if( __DEV__ ) {
//   if( module.hot ) {
//     module.hot.accept( () => {
//       if( global.authToken ) {
//         apiHelper.authToken = global.authToken
//       }
//     } )

//     module.hot.dispose( () => {
//       global.authToken = apiHelper.authToken
//     } )
//   }
// }

import { createSelector } from "@reduxjs/toolkit"

import type { SettingsState } from "./settingsStore"

export type SettingsStateSelector<TRootState> = [
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ( state: TRootState, ...params: any[] ) => SettingsState
]

const settingsSelector = <TRootState extends { settings: SettingsState }>( state: TRootState ) => state.settings

const createSettingsSelector = <TRootState extends { settings: SettingsState }, TReturnValue>( combiner: ( settingsState: SettingsState ) => TReturnValue ) => {
  return createSelector<SettingsStateSelector<TRootState>, TReturnValue>(
    settingsSelector,
    combiner,
  )
}

export const settingsSelectors = {
  merchantSettings: {
    byId: ( merchantId: string ) => {
      return createSettingsSelector(
        ( settingsState ) => {
          return settingsState.merchantSettings[ merchantId ] || null
        },
      )
    },
  },
}

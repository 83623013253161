import type { Name } from "@onelocal/shared/common"

export * from "./api"

export interface AuthAccount {
  email?: string
  hasAgreedBulkMessageTou: boolean
  id: string
  merchantSettings: AuthAccount.MerchantSetting[]
  name?: Name
  needsOnboarding: boolean
  phone?: string
  referral: {
    advocateId?: string
    openReferralProgramDate?: string
  }
  restrictLogin?: AuthAccount.RestrictLogin | null
  username?: string
}

export namespace AuthAccount {
  export interface MerchantSetting {
    merchantIds: string[]
    notifications: Notifications
  }

  export interface Notifications {
    admin?: Notifications.Admin
    ads?: Notifications.Ads
    emailDelay?: number
    messages?: Notifications.Messages
    reviews?: Notifications.Reviews
    seo?: Notifications.Seo
    site?: Notifications.Site
    specialistUpdate?: Notifications.SpecialistUpdate
    visits?: Notifications.Visits
  }

  export namespace Notifications {
    export interface Admin {
      [ Admin.NotificationType.INTEGRATIONS ]: EmailOnlyChannels
    }

    export namespace Admin {
      export enum NotificationType {
        INTEGRATIONS = "integrations",
      }
    }

    export interface Ads {
      [ Ads.NotificationType.MONTHLY_REPORT ]: EmailOnlyChannels
    }

    export namespace Ads {
      export enum NotificationType {
        MONTHLY_REPORT = "monthly_report",
      }
    }

    export enum ChannelType {
      EMAIL = "email",
      MOBILE = "mobile",
      WEB = "web",
    }

    export interface EmailOnlyChannels {
      [ ChannelType.EMAIL ]: {
        enabled: boolean
      }
    }

    export interface Messages {
      conversations: {
        [ Messages.AssigneeType.ME ]?: Messages.NotificationChannels
        [ Messages.AssigneeType.OTHERS ]?: Messages.NotificationChannels
        [ Messages.AssigneeType.NONE ]?: Messages.NotificationChannels
        [ Messages.AssigneeType.ALL ]?: Messages.NotificationChannels
      }
    }

    export namespace Messages {
      export enum AssigneeType {
        ALL = "assigned_all",
        ME = "assigned_me",
        NONE = "assigned_none",
        OTHERS = "assigned_others",
      }

      export enum NotificationType {
        ASSIGN = "assign",
        BLOCK_UNBLOCK = "block_unblock",
        CLOSE_OPEN = "close_open",
        MESSAGE_IN = "message_in",
        MESSAGE_OUT = "message_out",
        MUTE_UNMUTE = "mute_unmute",
        NOTE = "note",
        OPTIN_OPTOUT = "optin_optout",
      }

      export interface NotificationChannels {
        [ ChannelType.EMAIL ]: Messages.Conversations
        [ ChannelType.MOBILE ]: Messages.Conversations
        [ ChannelType.WEB ]: Messages.Conversations
      }

      export interface Conversations {
        enabled: boolean
        types?: Notifications.Messages.NotificationType[]
      }
    }

    export interface Reviews {
      [ Reviews.NotificationType.ONLINE_REVIEW ]: Reviews.OnlineReviewChannels
      [ Reviews.NotificationType.WEEKLY_HIGHLIGHT ]: EmailOnlyChannels
      [ Reviews.NotificationType.WEEKLY_REPORT ]: EmailOnlyChannels
      [ Reviews.NotificationType.MONTHLY_REPORT ]: EmailOnlyChannels
      [ Reviews.NotificationType.SURVEY_RESPONSE ]: EmailOnlyChannels
    }

    export namespace Reviews {
      export enum NotificationType {
        MONTHLY_REPORT = "monthly_report",
        ONLINE_REVIEW = "online_review",
        SURVEY_RESPONSE = "survey_response",
        WEEKLY_HIGHLIGHT = "weekly_highlight",
        WEEKLY_REPORT = "weekly_report",
      }

      export interface OnlineReviewChannels {
        [ ChannelType.EMAIL ]: {
          enabled: boolean
          value?: string[]
        }
      }
    }

    export interface Seo {
      [ Seo.NotificationType.MONTHLY_REPORT ]: EmailOnlyChannels
    }

    export namespace Seo {
      export enum NotificationType {
        MONTHLY_REPORT = "monthly_report",
      }
    }

    export interface Site {
      [ Site.NotificationType.MONTHLY_REPORT ]: EmailOnlyChannels
    }

    export namespace Site {
      export enum NotificationType {
        MONTHLY_REPORT = "monthly_report",
      }

    }

    export interface SpecialistUpdate {
      [ SpecialistUpdate.NotificationType.WEEKLY_HIGHLIGHTS ]: EmailOnlyChannels
      [ SpecialistUpdate.NotificationType.ACTIONABLE_UPDATES ]: EmailOnlyChannels
    }

    export namespace SpecialistUpdate {
      export enum NotificationType {
        ACTIONABLE_UPDATES = "actionable_updates",
        WEEKLY_HIGHLIGHTS = "weekly_highlights",
      }
    }

    export interface Visits {
      [ Visits.NotificationType.BOOKING_CREATIONS ]: EmailOnlyChannels
      [ Visits.NotificationType.BOOKING_UPDATES ]: EmailOnlyChannels
    }

    export namespace Visits {
      export enum NotificationType {
        BOOKING_CREATIONS = "booking_creations",
        BOOKING_UPDATES = "booking_updates",
      }
    }
  }

  export enum RestrictLogin {
    EMPLOYEE_APP = "employee_app",
  }
}

export interface AuthEmployee {
  email?: string
  id: string
  organization: {
    id: string
    ref: string
  }
  name: Name
}

export interface AuthMerchantPermissions {
  merchantIds: string[]
  type: AuthPermissionType
  roleIds: string[]
  overridePermissions: AuthPermissions
  permissions?: AuthPermissions
}

export enum AuthPermissionType {
  ADMIN = "admin",
  NORMAL = "normal",
  RESTRICTED = "restricted",
}

export enum AuthPermission {
  ADMIN_API_UPDATE = "admin.api.update",
  ADMIN_API_VIEW = "admin.api.read",
  ADMIN_AUDIT_VIEW = "admin.audit.read",
  ADMIN_AUTH_UPDATE = "admin.auth.update",
  ADMIN_AUTH_VIEW = "admin.auth.read",
  ADMIN_BILLING_UPDATE = "admin.billing.update",
  ADMIN_BILLING_VIEW = "admin.billing.read",
  ADMIN_SETTINGS_UPDATE = "admin.settings.update",
  ADMIN_SETTINGS_VIEW = "admin.settings.read",
  ANALYTICS_ACCESS_ANALYTICS_VIEW = "analytics.access_analytics.read",
  CONTACTS_CONTACTS_UPDATE = "contacts.contacts.update",
  CONTACTS_CONTACTS_VIEW = "contacts.contacts.read",
  LOCAL_SITE_EDIT_WEBSITE_UPDATE = "site.edit_website.update",
  MESSAGES_CONVERSATIONS_BLOCK = "messages.conversations.block_user",
  MESSAGES_CONVERSATIONS_SUBSCRIPTION_UPDATE = "messages.conversations.update_subscription",
  MESSAGES_CONVERSATIONS_UPDATE = "messages.conversations.update",
  MESSAGES_CONVERSATIONS_VIEW = "messages.conversations.read",
  MESSAGES_PHONE_MAKE_RECEIVE_CALLS = "messages.phone.make_receive_calls",
  MESSAGES_SETTINGS_UPDATE = "messages.settings.update",
  REFERRALS_REFERRALS_CREATE = "referrals.referrals.create",
  REFERRALS_REFERRALS_UPDATE = "referrals.referrals.update",
  REFERRALS_REFERRALS_VIEW = "referrals.referrals.read",
  REVIEWS_REQUESTS_SEND = "reviews.requests.send",
  REVIEWS_REQUESTS_SEND_AS = "reviews.requests.send_as",
  REVIEWS_REQUESTS_VIEW = "reviews.requests.read",
  REVIEWS_REVIEWS_REPLY = "reviews.reviews.reply",
  REVIEWS_REVIEWS_SET_ATTRIBUTION = "reviews.reviews.set_attribution",
  REVIEWS_REVIEWS_VIEW = "reviews.reviews.read",
  REVIEWS_SURVEYS_UPDATE = "reviews.surveys.update",
  REVIEWS_SURVEYS_VIEW = "reviews.surveys.read",
}

export interface AuthPermissions extends AuthPermissions.GroupPermissions {
  admin?: boolean | AuthPermissions.Admin
  analytics?: boolean | AuthPermissions.Analytics
  contacts?: boolean | AuthPermissions.Contacts
  messages?: boolean | AuthPermissions.Messages
  referrals?: boolean | AuthPermissions.Referrals
  reviews?: boolean | AuthPermissions.Reviews
  site?: boolean | AuthPermissions.Site
}

export namespace AuthPermissions {
  export interface GroupPermissions {
    [group_key: string]: boolean | undefined | ResourcePermissions
  }

  export interface ResourcePermissions {
    [resource_key: string]: boolean | undefined | KeyPermissions
  }

  export interface KeyPermissions {
    [permission_key: string]: boolean | undefined
  }

  export interface Admin extends ResourcePermissions {
    api?: boolean | undefined | {
      read?: boolean
      update?: boolean
    } & KeyPermissions
    auth?: boolean | {
      read?: boolean
      update?: boolean
    } & KeyPermissions
    billing?: boolean | {
      read?: boolean
      update?: boolean
    } & KeyPermissions
    settings?: boolean | {
      read?: boolean
      update?: boolean
    } & KeyPermissions
  }

  export interface Analytics extends ResourcePermissions {
    access_analytics?: boolean | undefined | {
      read?: boolean
    } & KeyPermissions
  }

  export interface Contacts extends ResourcePermissions {
    contacts?: boolean | {
      read?: boolean
      update?: boolean
    } & KeyPermissions
  }

  export interface Site extends ResourcePermissions {
    edit_website?: boolean | {
      update?: boolean
    } & KeyPermissions
  }

  export interface Referrals extends ResourcePermissions {
    referrals?: boolean | {
      read?: boolean
      update?: boolean
      create?: boolean
    } & KeyPermissions
  }

  export interface Reviews extends ResourcePermissions {
    requests?: boolean | Reviews.Requests
    reviews?: boolean | Reviews.Reviews
    surveys?: boolean | Reviews.Surveys
  }

  export namespace Reviews {
    export interface Requests extends KeyPermissions {
      read?: boolean
      send?: boolean
      send_as?: boolean
    }

    export interface Reviews extends KeyPermissions {
      read?: boolean
      reply?: boolean
      set_attribution?: boolean
    }

    export interface Surveys extends KeyPermissions {
      read?: boolean
      update?: boolean
    }
  }

  export interface Messages extends ResourcePermissions {
    conversations?: boolean | {
      read?: boolean
      update?: boolean
      block_users?: boolean
      update_subscription?: boolean
    } & KeyPermissions
  }
}

export interface AuthSession {
  accountId: string
  agencyId?: string
  email: string
  employeeId: string
  employeeOrganizationId: string
  hasAgreedReviewsTou: boolean
  isGataEmployee: boolean
  merchantPermissions: AuthMerchantPermissions[]
  name: Name
  passwordReset: boolean
  token: string
  userId: string
  username: string
}

export interface AuthTrackingProperties {
  eventProperties: AuthTrackingPropertiesValues
  userProperties: AuthTrackingPropertiesValues
}

export type AuthTrackingPropertiesValues = Record<string, string | number | boolean | null | Date>

import { isArray, isEmpty, isString } from "lodash"

import { AuthInternalAccount } from "../types"

export const authInternalAccountHelpers = {
  getDisplayPermissions( permissions: AuthInternalAccount.Permission[] | AuthInternalAccount.Permission ) {
    const displayPermissions: Record<AuthInternalAccount.Permission, string> = {
      [ AuthInternalAccount.Permission.AGENCY_ANALYTICS ]: "AgencyAnalytics",
      [ AuthInternalAccount.Permission.API_KEYS ]: "API Keys",
      [ AuthInternalAccount.Permission.CREDENTIALS ]: "Credentials",
      [ AuthInternalAccount.Permission.DELETE_TESTIMONIAL ]: "Delete LocalReview Testimonial",
      [ AuthInternalAccount.Permission.MERCHANT_DELETE ]: "Delete Merchant",
      [ AuthInternalAccount.Permission.HIPAA ]: "HIPAA",
      [ AuthInternalAccount.Permission.QA ]: "Testing",
      [ AuthInternalAccount.Permission.USER_MANAGEMENT ]: "User Management",
    }

    if( ! isArray( permissions ) && isString( permissions ) ) {
      permissions = [ permissions ]
    }

    if( isEmpty( permissions ) ) {
      return null
    }

    return permissions.map( ( permission ) => displayPermissions[ permission ] ).join( ", " )
  },
  getDisplayRole( internalAccountRole: AuthInternalAccount.Role ) {
    const displayRoles: Record<AuthInternalAccount.Role, string> = {
      [ AuthInternalAccount.Role.ADMIN ]: "Workspace Admin",
      [ AuthInternalAccount.Role.CUSTOMER_SUCCESS ]: "Customer Success",
      [ AuthInternalAccount.Role.DELIVERY ]: "Delivery",
      [ AuthInternalAccount.Role.SALES ]: "Sales",
      [ AuthInternalAccount.Role.TECH ]: "Tech",
    }

    return displayRoles[ internalAccountRole ]
  },
}

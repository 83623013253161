import React, { useCallback } from "react"

import { Link, styleHelpers } from "@onelocal/frontend-web/common"
import { LocalContactLinkIcon } from "./Icons"

interface ContactLinkProps {
  onClick?( event: React.MouseEvent<HTMLAnchorElement, MouseEvent> ): void
  link: string
  text: string
}

export const ContactLink: React.FC<ContactLinkProps> = ( { onClick, link, text } ) => {
  const onClickHandler = useCallback( ( event: React.MouseEvent<HTMLAnchorElement, MouseEvent> ) => {
    event.preventDefault()
    if( onClick ) {
      onClick( event )
    }
    window.open( link, "_self" )
  }, [ link, onClick ] )

  return (
    <Link onClick={ onClickHandler } style={ { fontWeight: styleHelpers.fonts.weight.semiBold } }>
      <LocalContactLinkIcon style={ {
        marginRight: "5px",
      } } />
      { text }
    </Link>
  )
}

import type { Name } from "@onelocal/shared/common"

export enum AdminRoutePath {
  A2P = "/a2p",
  ACCOUNTS = "/accounts",
  DELIVERY_AI_REVIEW = "/delivery/ai-review",
  // the full url is /merchants/:merchantId/analytics
  MERCHANT_DETAILS_ANALYTICS = "/analytics",
  // the full url is /merchants/:merchantId/content
  MERCHANT_DETAILS_CONTENT = "/content",
  // the full url is /merchants/:merchantId/onboarding
  MERCHANT_DETAILS_ONBOARDING = "/onboarding",
  MERCHANTS_LIST = "/merchants/*",
  MERCHANTS_PHONE_NUMBERS = "/merchants-phone-numbers",
  OPPORTUNITIES = "/opportunities",
  OPPORTUNITY_DETAILS = "/opportunities/:opportunityId",
  OPPORTUNITY_ONBOARDING = "/opportunities/:opportunityId/onboarding",
  SITE_TEMPLATES = "/site-templates",
  TASKS= "/tasks",
}

export enum AdminRoutePartialPath {
  MERCHANT_DETAILS = "/:merchantId/*",
}

export enum OldAdminRoutePath {
  AGENCY = "/agency",
  ANALYTICS = "/analytics",
  MERCHANTS ="/merchants",
  SETTINGS = "/settings",
  SIGNIN = "/account/signin",
  TESTING = "/testing",
}

export interface Session {
  email: string
  name: Name
  permissions: Session.Permission[]
  username: string
}

export namespace Session {
  export enum Permission {
    API_KEYS = "gata_admin_api_keys",
    BILLING = "gata_admin_billing",
    CREDENTIALS = "gata_admin_credentials",
    DELETE_TESTIMONIAL = "gata_admin_delete_testimonial",
    HIPAA = "gata_admin_hipaa_access",
    PROMOS = "gata_admin_promos",
    QA = "gata_admin_qa",
    WEBSITE = "gata_admin_fi_website",
  }
}

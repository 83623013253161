import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

import type { DefaultThunkAction, ModuleStoreConfig, PaginatedItems, PaginatedQueryOptions } from "@onelocal/frontend/common"
import type { ConnectMerchantSettingsService } from "../services/merchantSettingsService"
import { connectMerchantSettingsService } from "../services/merchantSettingsService"
import type { ConnectMerchantSettings } from "../types"
import { connectSaga } from "./connectSagas"

export interface ConnectState {
  merchantSettings: {
    byId: { [merchantId: string]: ConnectMerchantSettings }
  }
}

const connectStoreKey = "connect"

export interface RootStateConnect {
  [ connectStoreKey ]: ConnectState
}

type ConnectThunkAction<TReturnType> = DefaultThunkAction<RootStateConnect, TReturnType>

export const connectInitialState: ConnectState = {
  merchantSettings: {
    byId: {},
  },
}

export namespace ConnectState {

}

const connectSlice = createSlice( {
  name: connectStoreKey,
  initialState: connectInitialState,
  reducers: {
    updateMerchantSettings: ( state, action: PayloadAction<ConnectMerchantSettings[]> ) => {
      state.merchantSettings = {
        ...state,
        byId: action.payload.reduce( ( result, merchantSetting ) => ( {
          ...result,
          [ merchantSetting.id ]: merchantSetting,
        } ), {} ),
      }

      return state
    },
    updateSelectedMerchantSettings: ( state, action: PayloadAction<ConnectMerchantSettings> ) => {
      const merchantSettings = action.payload
      state.merchantSettings.byId[ merchantSettings.id ] = merchantSettings
      return state
    },
  },
} )

export const connectActions = {
  merchantSettings: {
    query( filter: ConnectMerchantSettingsService.QueryFilter, options: PaginatedQueryOptions ): ConnectThunkAction<PaginatedItems<ConnectMerchantSettings>> {
      return async ( dispatch ) => {
        const paginatedMerchantSettings = await connectMerchantSettingsService.queryMerchantSettings( filter, options )
        dispatch( connectSlice.actions.updateMerchantSettings( paginatedMerchantSettings.items ) )
        return paginatedMerchantSettings
      }
    },
    registerA2P( merchantId: string ): ConnectThunkAction<ConnectMerchantSettings> {
      return async ( dispatch ) => {
        await connectMerchantSettingsService.registerA2P( merchantId )
        const merchantSettings = await connectMerchantSettingsService.getMerchantSettings( merchantId )
        dispatch( connectSlice.actions.updateSelectedMerchantSettings( merchantSettings ) )
        return merchantSettings
      }
    },
  },
}

export const connectStoreConfig: ModuleStoreConfig<ConnectState> = {
  key: connectStoreKey,
  initialState: connectInitialState,
  reducers: connectSlice.reducer,
  saga: connectSaga,
}

import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

import type { DefaultThunkAction, ModuleStoreConfig, PaginatedItems, PaginatedQueryOptions } from "@onelocal/frontend/common"
import type { OnboardingOpportunityService } from "../services/opportunityService"
import { onboardingOpportunityService } from "../services/opportunityService"
import type { OnboardingOpportunity, OnboardingOpportunityOwner } from "../types"
import { onboardingSaga } from "./onboardingSagas"

export interface OnboardingState {
  opportunities: {
    byId: { [id: string]: OnboardingOpportunity }
  }
  opportunityOwners: {
    all: OnboardingOpportunityOwner[]
  }
}

const onboardingStoreKey = "onboarding"

export interface RootStateOnboarding {
  [ onboardingStoreKey ]: OnboardingState
}

type OnboardingThunkAction<TReturnType> = DefaultThunkAction<RootStateOnboarding, TReturnType>

export const onboardingInitialState: OnboardingState = {
  opportunities: {
    byId: {},
  },
  opportunityOwners: {
    all: [],
  },
}

const onboardingSlice = createSlice( {
  name: onboardingStoreKey,
  initialState: onboardingInitialState,
  reducers: {
    setOpportunityOwners: ( state, action: PayloadAction<OnboardingOpportunityOwner[]> ) => {
      state.opportunityOwners.all = action.payload
      return state
    },
    updateOpportunities: ( state, action: PayloadAction<OnboardingOpportunity[]> ) => {
      for( const opportunity of action.payload ) {
        state.opportunities.byId[ opportunity.id ] = opportunity
      }

      return state
    },
  },
} )

export const onboardingActions = {
  opportunities: {
    get( onboardingId: string ): OnboardingThunkAction<OnboardingOpportunity> {
      return async ( dispatch ) => {
        const opportunity = await onboardingOpportunityService.getOpportunityById( onboardingId )
        dispatch( onboardingSlice.actions.updateOpportunities( [ opportunity ] ) )
        return opportunity
      }
    },
    query( filter: OnboardingOpportunityService.QueryFilter, options: PaginatedQueryOptions ): OnboardingThunkAction<PaginatedItems<OnboardingOpportunity>> {
      return async ( dispatch ) => {
        const paginatedOpportunities = await onboardingOpportunityService.query( filter, options )
        dispatch( onboardingSlice.actions.updateOpportunities( paginatedOpportunities.items ) )
        return paginatedOpportunities
      }
    },
  },
  opportunityOwners: {
    query(): OnboardingThunkAction<OnboardingOpportunityOwner[]> {
      return async ( dispatch ) => {
        const opportunityOwners = await onboardingOpportunityService.queryOwners()
        dispatch( onboardingSlice.actions.setOpportunityOwners( opportunityOwners ) )
        return opportunityOwners
      }
    },
  },
}

export const onboardingStoreConfig: ModuleStoreConfig<OnboardingState> = {
  key: onboardingStoreKey,
  initialState: onboardingInitialState,
  reducers: onboardingSlice.reducer,
  saga: onboardingSaga,
}

// eslint-disable-next-line no-restricted-imports
import MaterialGrid from "@mui/material/Grid"
import type { CSSProperties } from "react"
import React from "react"

export type GridContentAlignment = "stretch" | "center" | "flex-start" | "flex-end" | "space-between" | "space-around";
export type GridDirection = "row" | "row-reverse" | "column" | "column-reverse";
export type GridItemsAlignment = "flex-start" | "center" | "flex-end" | "stretch" | "baseline";
export type GridJustification = "flex-start" | "center" | "flex-end" | "space-between" | "space-around" | "space-evenly";
export type GridSize = "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
export type GridSpacing = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
export type GridWrap = "nowrap" | "wrap" | "wrap-reverse";

interface GridBaseProps {
  alignContent?: GridContentAlignment
  alignItems?: GridItemsAlignment
  children?: React.ReactNode
  container?: boolean
  direction?: GridDirection
  item?: boolean
  justify?: GridJustification
  lg?: boolean | GridSize
  md?: boolean | GridSize
  sm?: boolean | GridSize
  spacing?: GridSpacing
  style?: CSSProperties
  wrap?: GridWrap
  xl?: boolean | GridSize
  xs?: boolean | GridSize
}

const GridBase: React.FC<GridBaseProps> = ( {
  ...props
} ) => {
  return (
    <MaterialGrid { ...props }/>
  )
}

export type GridProps = Omit<GridBaseProps, "container" | "item" >

export const Grid: React.FC<GridProps> = ( {
  ...props
} ) => {
  return (
    <GridBase container { ...props }/>
  )
}

export const GridItem: React.FC<GridProps> = ( {
  ...props
} ) => {
  return (
    <GridBase item { ...props }/>
  )
}

/* eslint-disable no-restricted-imports */
import Slide from "@mui/material/Slide"
import { uniqueId } from "lodash"
import type { OptionsObject } from "notistack"
import { useSnackbar as useSnackbarNotistack } from "notistack"
/* eslint-enable no-restricted-imports */
import React, { useMemo } from "react"

import { errorHelper } from "@onelocal/frontend/common"
import { Alert } from "../components/Alert"

const DEFAULT_OPTIONS: OptionsObject = {
  anchorOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  TransitionComponent: Slide,
}

export function useAlert() {
  const { enqueueSnackbar } = useSnackbarNotistack()

  return useMemo( () => {
    return {
      showError( error: string | { data?: { message?: string } } | Error | null | unknown ) {
        const errorMessage = errorHelper.getDisplayErrorMessage( error )
        enqueueSnackbar( errorMessage, {
          ...DEFAULT_OPTIONS,
          content: ( key ) => <Alert id={ key } message={ errorMessage } variant="error"/>,
          key: uniqueId( "alert-error-" ),
          variant: "error",
        } )

        console.log( error )
      },
      showInfo( message: string, renderAction?: ( () => React.ReactNode ) | null ) {
        enqueueSnackbar( message, {
          ...DEFAULT_OPTIONS,
          content: ( key ) => <Alert id={ key } message={ message } variant="info" renderAction={ renderAction }/>,
          key: uniqueId( "alert-info-" ),
          variant: "info",
        } )
      },
      showSuccess( message: string, renderAction?: ( () => React.ReactNode ) | null ) {
        enqueueSnackbar( message, {
          ...DEFAULT_OPTIONS,
          content: ( key ) => <Alert id={ key } message={ message } variant="success" renderAction={ renderAction }/>,
          key: uniqueId( "alert-success-" ),
          variant: "success",
        } )
      },
      showWarning( message: string, renderAction?: ( () => React.ReactNode ) | null ) {
        enqueueSnackbar( message, {
          ...DEFAULT_OPTIONS,
          content: ( key ) => <Alert id={ key } message={ message } variant="warning" renderAction={ renderAction }/>,
          key: uniqueId( "alert-warning-" ),
          variant: "warning",
        } )
      },
    }
  }, [ enqueueSnackbar ] )
}

import CloseIcon from "@mui/icons-material/Close"
// eslint-disable-next-line no-restricted-imports
import { Dialog as MuiDialog, DialogActions as MuiDialogActions, DialogContent as MuiDialogContent, DialogTitle as MuiDialogTitle, IconButton as MuiIconButton } from "@mui/material"
import React, { useCallback, useMemo } from "react"
import styled from "styled-components"

import { errorHelper } from "@onelocal/frontend/common"
import { ReactComponent as ErrorIcon } from "../assets/error-icon.svg"
import { styleHelpers } from "../helpers"
import { Button } from "./Button"
import { Flex } from "./Flex"
import { LoadingOverlay } from "./LoadingOverlay"
import { SaveButton } from "./SaveButton"

export interface DialogProps {
  actions?: DialogAction[]
  alert?: DialogAlert
  children?: React.ReactNode
  contentContainerStyle?: React.CSSProperties
  footerText?: string
  isOpen: boolean
  loadingText?: string | null
  noMaxWidth?: boolean
  noMinWidth?: boolean
  onClose: () => void
  onExited: () => void
  showDividers?: boolean
  subTitle?: string | React.ReactNode
  title: string | React.ReactNode
}

export interface DialogAction {
  disabled?: boolean
  icon?: React.ReactNode
  onClick?: ( event: React.MouseEvent<HTMLElement> ) => void
  style?: React.CSSProperties
  title: string
  type?: "primary" | "secondary" | "destructive" | "dismiss" | "save"
}

export interface DialogAlert {
  message: string | { data?: { message?: string } } | Error | null | unknown
  type: DialogAlertType
}

export const enum DialogAlertType {
  ERROR = "error",
  WARNING = "warning",
}

const DialogAlertContainer = styled( Flex )`
  padding: 15px 18px;
`

const DialogAlertMessage = styled.div`
  font-size: 14px;
`

const DialogFooterText = styled.div`
  color: ${ styleHelpers.colors.darkGray };
  font-size: 14px;
  margin-right: 15px;
`

const DialogSubTitleText = styled.div`
  color: ${ styleHelpers.colors.darkGray };
  font-size: 12px;
  font-weight: ${ styleHelpers.fonts.weight.default };
  line-height: 1.2;
`

const StyledErrorIcon = styled( ErrorIcon )`
  margin-right: 14px;
`

export const Dialog: React.FC<DialogProps> = ( {
  actions,
  alert,
  children,
  contentContainerStyle = {},
  footerText,
  isOpen,
  loadingText,
  noMaxWidth,
  noMinWidth,
  onClose,
  onExited,
  showDividers,
  subTitle,
  title,
} ) => {
  showDividers = showDividers ?? true

  const handleDialogAction = useCallback( ( event: React.MouseEvent<HTMLElement>, action: DialogAction ) => {
    action.onClick?.( event )
  }, [] )

  const alertMessage = useMemo( () => {
    if( ! alert?.message ) {
      return
    }

    return errorHelper.getDisplayErrorMessage( alert.message )
  }, [ alert ] )

  const onCloseHandler = useCallback( ( event: Event, reason: string ) => {
    if( reason !== "backdropClick" ) {
      onClose()
    }
  }, [ onClose ] )

  return (
    <MuiDialog
      // This is require to not inherit from the CSS rule of the old dashboard
      className="css-reset"
      onClose={ onCloseHandler }
      open={ isOpen }
      sx={ {
        "& .MuiPaper-root": {
          maxWidth: noMaxWidth ? "initial" : undefined,
          minWidth: noMinWidth ? 0 : undefined,
        },
      } }
      TransitionProps={ { onExited } }
    >
      <MuiDialogTitle sx={ { color: styleHelpers.colors.dark } } style={ { paddingRight: "65px" } }>
        { title }
        {
          subTitle && (
            <DialogSubTitleText>{ subTitle }</DialogSubTitleText>
          )
        }
        <MuiIconButton
          aria-label="close"
          onClick={ onClose }
          sx={ {
            position: "absolute",
            right: 8,
            top: 8,
          } }
        >
          <CloseIcon />
        </MuiIconButton>
      </MuiDialogTitle>
      <MuiDialogContent
        dividers={ showDividers }
        style={ { paddingLeft: 0, paddingRight: 0, position: "relative" } }
      >
        {
          alert != null && (
            <DialogAlertContainer
              alignItems="center"
              display="flex"
              flexDirection="row"
              style={ {
                background: alert.type === DialogAlertType.ERROR ? styleHelpers.colors.errorLight : styleHelpers.colors.warningLight,
              } }
            >
              {
                alert.type === DialogAlertType.ERROR && (
                  <StyledErrorIcon />
                )
              }
              <DialogAlertMessage style={ {
                color: alert.type === DialogAlertType.ERROR ? styleHelpers.colors.errorDark : styleHelpers.colors.warningDark,
              } }>{ alertMessage }</DialogAlertMessage>
            </DialogAlertContainer>

          )
        }
        <div
          style={ {
            paddingLeft: "20px",
            paddingRight: "20px",
            ...contentContainerStyle,
          } }
        >
          { children }
        </div>
      </MuiDialogContent>
      <MuiDialogActions>
        {
          footerText && (
            <DialogFooterText>{ footerText }</DialogFooterText>
          )
        }
        {
          actions && actions?.length > 0
            ? (
              actions.map( ( action ) => {
                switch( action.type ) {
                  case "dismiss": {
                    return (
                      <Button
                        color="dismiss"
                        disabled={ action.disabled }
                        key={ action.title }
                        onClick={ onClose }
                        style={ action.style }
                        variant="text"
                      >
                        { action.title }
                      </Button>
                    )
                  }
                  case "save": {
                    return (
                      <SaveButton
                        disabled={ action.disabled }
                        key={ action.title }
                        onClick={ ( event ) => handleDialogAction( event, action ) }
                        style={ action.style }
                      />
                    )
                  }
                  default: {
                    return (
                      <Button
                        disabled={ action.disabled }
                        icon={ action.icon }
                        key={ action.title }
                        onClick={ ( event ) => handleDialogAction( event, action ) }
                        style={ action.style }
                      >
                        { action.title }
                      </Button>
                    )
                  }
                }
              } )
            )
            : (
              <Button color="dismiss" onClick={ onClose } variant="text">Dismiss</Button>
            )
        }
      </MuiDialogActions>
      {
        loadingText && (
          <LoadingOverlay
            containerStyle={ {
              bottom: "50px",
              top: "60px",
            } }
            text={ loadingText }
          />
        )
      }
    </MuiDialog>
  )
}

import InfoOutlined from "@mui/icons-material/InfoOutlined"
import { uniqueId } from "lodash"
import { useCallback, useState } from "react"
import styled from "styled-components"

import { useAsyncDispatch } from "@onelocal/frontend/common"
import type { DialogAction, DialogAlert } from "@onelocal/frontend-web/common"
import { Button, Dialog, DialogAlertType, useAlert, useModalWithParams } from "@onelocal/frontend-web/common"
import { useFileUpload } from "../../hooks/useFileUpload"
import { mainActions } from "../../store/mainStore"
import { StyledInfoBanner } from "../components/StyledInfoBanner"
import { FileStatus, UploadFileItem } from "../components/UploadFileItem"
import { type UploadedFileWithStatus } from "../NewTaskModal/Attachments"
import { BulkTasksProcessing } from "./BulkTasksProcessing"

interface NewBulkTasksModalProps {
  isOpen: boolean
  onClose(): void
  onExited(): void
  onTaskCreated(): void
}

const StyledList = styled.ul`
  margin: 0;
  padding-bottom: 0;
  padding-left: 45px;
  padding-top: 0;
`

const StyledAttachment = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 16px;
`

enum Tabs {
  FILE_UPLOAD = "file-upload",
  PROCESSING = "processing",
}

const INITIAL_PROGRESS = 10

export const NewBulkTasksModal: React.FC<NewBulkTasksModalProps> = ( { isOpen, onClose, onExited } ) => {
  const dispatch = useAsyncDispatch()
  const [ alert, setAlert ] =useState<DialogAlert | undefined>()
  const [ currentTab, setCurrentTab ] = useState<Tabs>( Tabs.FILE_UPLOAD )
  const [ csvFile, setCsvFile ] = useState<File>()
  const [ uploadedFile, setUploadedFile ] = useState<UploadedFileWithStatus>()
  const { showError } = useAlert()

  const handleUploadFile = useFileUpload( {
    accept: "csv",
    onUploadFile: async ( uploadingFile: File ) => {
      const uniqFileId = uniqueId()
      const url = URL.createObjectURL( uploadingFile )

      setUploadedFile( {
        localFileUrl: url,
        progress: INITIAL_PROGRESS,
        size: uploadingFile.size,
        status: FileStatus.UPLOADING,
        extension: uploadingFile.name.split( "." ).pop()!,
        uniqKey: uniqFileId,
      } )

      try {
        setUploadedFile( ( file ) => file ? {
          ...file,
          asset: {
            assetId: uniqFileId,
            filename: uploadingFile.name,
            originalFilename: uploadingFile.name,
            url,
          },
          status: FileStatus.UPLOADED,
          progress: 0,
        } : file )
        setCsvFile( uploadingFile )
      } catch( err ) {
        showError( "There was an error when trying to create the tasks" )
      }
    },
  } )

  const deleteFile = useCallback( async ( uniqFileId: string ) => {
    setUploadedFile( undefined )
    setCsvFile( undefined )
  }, [] )

  const onSubmitHandler = useCallback( async () => {
    try {
      await dispatch( mainActions.tasks.bulkCreate( csvFile! ) )
      setCurrentTab( Tabs.PROCESSING )
    } catch( err ) {
      setAlert( {
        message: "There was an error when trying to create the tasks",
        type: DialogAlertType.ERROR,
      } )
    }
  }, [ csvFile, dispatch ] )

  const modalActions: DialogAction[] = [
    {
      onClick: () => onClose(),
      title: "Back",
      type: "dismiss",
    },
    {
      onClick: onSubmitHandler,
      title: "Next",
      disabled: ! csvFile,
      type: "primary",
    },
  ]

  return <Dialog
    actions={ currentTab === Tabs.FILE_UPLOAD ? modalActions : [] }
    alert={ alert }
    contentContainerStyle={ {
      paddingLeft: "24px",
      paddingRight: "24px",
      width: "500px",
    } }
    isOpen={ isOpen }
    onClose={ onClose }
    onExited={ onExited }
    showDividers={ false }
    title={ currentTab === Tabs.PROCESSING ? "" : "Create Multiple Tasks" }
  >
    {
      currentTab === Tabs.FILE_UPLOAD && (
        <>
          <StyledInfoBanner style={ { marginBottom: "10px" } }>
            <InfoOutlined /> Upload a CSV file following this format:
          </StyledInfoBanner>

          <StyledInfoBanner>
            <StyledList>
              <li>Merchant ID</li>
              <li>Channel (Site, SEO, Ads, Social, Client Management, Onboarding)</li>
              <li>Change Type</li>
              <li>Change Name</li>
              <li>Description</li>
            </StyledList>
          </StyledInfoBanner>

          {
            uploadedFile ? (
              <UploadFileItem
                deleteFile={ deleteFile }
                extension={ uploadedFile.extension }
                fileUrl={ uploadedFile.localFileUrl }
                id={ uploadedFile.asset?.assetId }
                key={ uploadedFile.uniqKey }
                name={ uploadedFile.asset?.originalFilename }
                progress={ uploadedFile.progress }
                status={ uploadedFile.status }
                uniqKey={ uploadedFile.uniqKey }
              />
            ) : (
              <StyledAttachment>
                <Button onClick={ handleUploadFile } style={ { width: 141 } } variant="outlined">Upload File</Button>
              </StyledAttachment>
            )
          }
        </>
      )
    }
    { currentTab === Tabs.PROCESSING && <BulkTasksProcessing fileName={ uploadedFile?.asset?.filename || "" } /> }
  </Dialog>
}

export const useNewBulkTasksModal = ( onTaskCreated: () => void ) => {
  const { showModal, hideModal } = useModalWithParams( ( { open, onExited, params } ) => {
    return ( <NewBulkTasksModal isOpen={ open } onClose={ hideModal } onExited={ onExited } onTaskCreated={ onTaskCreated }/> )
  }, [] )

  return {
    hideNewBulkTasksModal: hideModal,
    showNewBulkTasksModal: showModal,
  }
}

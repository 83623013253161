import { matchPath } from "react-router"

import { AdminRoutePath } from "../types/index"

const PATHS: AdminRoutePath[] = Object.values( AdminRoutePath )

export const doesRouteExist = ( path: string ): boolean => {
  for( const adminPath of PATHS ) {
    const match = matchPath( { path: adminPath, end: true, caseSensitive: false }, path )
    if( match ) {
      return true
    }
  }
  return false
}


import { apiHelper, errorHelper, InternalError, ValidationError } from "@onelocal/frontend/common"
import { parseSessionFromApi } from "../types/api"

export const sessionService = {
  async createSession( username: string, password: string, token = false ) {
    const session = await apiHelper.request( {
      method: "POST",
      url: "/session",
      data: {
        username,
        password,
      },
      params: {
        token,
      },
      parsingData: parseSessionFromApi,
    } )

    if( ! session ) {
      throw new ValidationError( "Sorry, this account is disabled. Please contact your admin." )
    }

    return session
  },
  async refreshSession() {
    const session = await apiHelper.request( {
      method: "GET",
      url: "/session",
      parsingData: parseSessionFromApi,
    } )

    if( ! session ) {
      throw new InternalError( "Unexpected null session" )
    }

    return session
  },
  async removeSession() {
    await apiHelper.request( { method: "DELETE", url: "/session" } )
      .catch( errorHelper.logError )
  },

}

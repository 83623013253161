import { MerchantSettings } from "../types"

export const settingsHelper = {
  getProductStatusLabel( productStatus: MerchantSettings.ProductStatus | null | undefined ) {
    switch( productStatus ) {
      case MerchantSettings.ProductStatus.LIVE:
        return "Live"
      case MerchantSettings.ProductStatus.TRIAL:
        return "Trial"
      default:
        return "Not Enabled"
    }
  },
}

import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import React, { Fragment, useCallback, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"
import { useCopyToClipboard } from "react-use"
import styled from "styled-components"

import { AuthInternalAccount, useCurrentInternalAccount } from "@onelocal/frontend-admin-web/auth"
import { AdminLink } from "@onelocal/frontend-admin-web/common"
import type { TabDetailsLink } from "@onelocal/frontend-web/common"
import { Button, styleHelpers, Tooltip } from "@onelocal/frontend-web/common"
import { useMerchantSettings } from "../hooks/useMerchantSettings"
import type { MerchantSettings } from "../types"

const StyledContainer = styled.div`
  background-color: white;
`

const StyledHeader = styled.div`
  align-items: center;
  background-color: white;
  display: flex;
  flex-direction: row;
  padding: 0 15px;
`

const StyledMerchantName = styled.h2`
  color: ${ styleHelpers.colors.primary };
`

const StyledMerchantIdContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
`

const StyledMerchantId = styled.div`
  color: ${ styleHelpers.colors.darkGray };
  font-weight: ${ styleHelpers.fonts.weight.semiBold };
  margin: 0 5px 0 15px;
`

const StyledTabs = styled.div`
  border-bottom: 2px solid ${ styleHelpers.colors.grayLight };
  border-top: 2px solid ${ styleHelpers.colors.grayLight };
  padding: 10px 15px;
`

const StyledTabSeparator = styled.span`
  display: inline-block;
  font-weight: ${ styleHelpers.fonts.weight.semiBold };
  margin: 0 8px;
`

const StyledTabLink = styled( AdminLink )`
  display: inline-block;
  margin: 5px 0;
`

export interface MerchantDetailsHeaderProps {
  merchantId: string
  merchantSettings?: MerchantSettings
}

export const MerchantDetailsHeader: React.FC<MerchantDetailsHeaderProps> = ( { merchantId, merchantSettings: merchantSettingsParam } ) => {
  const [ copied, setCopied ] = useState( false )
  const [ , copyToClipboard ] = useCopyToClipboard()
  const { pathname } = useLocation()
  const { currentInternalAccount } = useCurrentInternalAccount()
  const { merchantSettings } = useMerchantSettings( merchantId, merchantSettingsParam != null )

  const copyMerchantId = useCallback( () => {
    copyToClipboard( merchantId )
    setCopied( true )
  }, [ copyToClipboard, merchantId ] )

  const resetCopied = useCallback( () => {
    setCopied( false )
  }, [] )

  const hasQAPermission = useMemo( () => {
    return currentInternalAccount.permissions.includes( AuthInternalAccount.Permission.QA )
  }, [ currentInternalAccount.permissions ] )

  const tabs = useMemo( () => {
    const base_href = `/merchants/${ merchantId }`

    const resultTabs: Array<TabDetailsLink | null> = [
      { label: "Details", href: `${ base_href }` },
      { label: "Onboarding", href: `${ base_href }/onboarding` },
      { label: "Template", href: `${ base_href }/template` },
      { label: "Employees", href: `${ base_href }/employees` },
      { label: "LocalContent", href: `${ base_href }/content` },
      { label: "LocalReviews", href: `${ base_href }/local-reviews` },
      { label: "LocalReferrals", href: `${ base_href }/local-referrals` },
      { label: "LocalMessages", href: `${ base_href }/local-messages` },
      { label: "LocalAds", href: `${ base_href }/local-ads` },
      { label: "LocalSite", href: `${ base_href }/local-site` },
      { label: "LocalVisits", href: `${ base_href }/local-visits` },
      { label: "Phone", href: `${ base_href }/phone` },
      { label: "Hierarchy/Multi Location", href: `${ base_href }/hierarchy` },
      (
        merchantSettings?.flags.agencyAnalytics
          ? { label: "Agency Analytics", href: `${ base_href }/analytics` }
          : null
      ),
      (
        merchantSettings?.testing && hasQAPermission
          ? { label: "Testing", href: `${ base_href }/testing` }
          : null
      ),
    ]

    return resultTabs.filter( ( tab ) => tab != null ) as TabDetailsLink[]
  }, [ hasQAPermission, merchantSettings, merchantId ] )

  const disableOpenMerchantHIPAA = useMemo( () => {
    return (
      merchantSettings?.compliance.hipaaEnabled
      && ! currentInternalAccount.permissions.includes( AuthInternalAccount.Permission.HIPAA )
    )
  }, [ currentInternalAccount.permissions, merchantSettings?.compliance.hipaaEnabled ] )

  return (
    <StyledContainer>
      <StyledHeader>
        {
          merchantSettings != null && (
            <>
              <StyledMerchantName>{ merchantSettings.profile.name }</StyledMerchantName>
              <Tooltip onOpen={ resetCopied } placement="bottom" text={ copied ? "Copied" : "Click to copy" }>
                <StyledMerchantIdContainer
                  onClick={ copyMerchantId }
                >
                  <StyledMerchantId>{ merchantSettings.id }</StyledMerchantId>
                  <ContentCopyIcon htmlColor={ styleHelpers.colors.darkGray } fontSize="small"/>
                </StyledMerchantIdContainer>
              </Tooltip>
            </>
          )
        }

        <div style={ { flex: 1 } } />
        <Tooltip placement="bottom" text={ disableOpenMerchantHIPAA ? "Action is blocked for HIPAA merchants." : "" }>
          <Button
            disabled={ disableOpenMerchantHIPAA }
            href={ `/open_merchant?merchant_id=${ merchantId }` }
            target="_blank"
          >
            Open Merchant
          </Button>
        </Tooltip>
        <Tooltip placement="bottom" text={ disableOpenMerchantHIPAA ? "Action is blocked for HIPAA merchants." : "" }>
          <Button
            disabled={ disableOpenMerchantHIPAA }
            href={ `/open_merchant?merchant_id=${ merchantId }&mode=testing` }
            style={ { marginLeft: "10px" } }
            target="_blank"
          >
            Open Merchant In Test
          </Button>
        </Tooltip>
        <Tooltip placement="bottom" text={ disableOpenMerchantHIPAA ? "Action is blocked for HIPAA merchants." : "" }>
          <Button
            disabled={ disableOpenMerchantHIPAA }
            href={ `/open_merchant?merchant_id=${ merchantId }&mode=mobile` }
            style={ { marginLeft: "10px" } }
            target="_blank"
          >
            Open Merchant in app
          </Button>
        </Tooltip>
      </StyledHeader>
      <StyledTabs>
        {
          tabs.map( ( tab, index ) => {
            return (
              <Fragment key={ tab.href }>
                {
                  index > 0 && (
                    <StyledTabSeparator>/</StyledTabSeparator>
                  )
                }
                <StyledTabLink href={ tab.href } color={ pathname === tab.href ? "primary" : "gray" }>{ tab.label }</StyledTabLink>
              </Fragment>
            )
          } )
        }
      </StyledTabs>
    </StyledContainer>
  )
}

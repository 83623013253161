// eslint-disable-next-line @nx/enforce-module-boundaries
import type { ContentBlogPostSuggestion, ContentMerchantSettings, ContentPublishDateType, ContentScheduledItemBase, ContentScheduledItemBlogPost, ContentScheduledItemSocialPost, ContentSocialPost, ContentSocialPostSuggestionBase, ContentSocialPostSuggestionBeforeAfterPhotos, ContentSocialPostSuggestionBlogPost, ContentSocialPostSuggestionBulkMessage, ContentSocialPostSuggestionPhotos, ContentSocialPostSuggestionReview } from "@onelocal/frontend-dashboard/content"
import { ContentBlogPost, ContentScheduledItem, ContentSocialPostSuggestion } from "@onelocal/frontend-dashboard/content"
import type { MerchantSettings } from "@onelocal/frontend-dashboard/settings"
import { utilHelpers } from "@onelocal/shared/common"

export interface ApiContentMerchantSettings {
  status: MerchantSettings.ProductStatus.LIVE | null
}

export namespace ApiContentMerchantSettings {

}

export function parseContentMerchantSettingsFromApi( apiContentMerchantSettings: ApiContentMerchantSettings ) {
  const contentMerchantSettings: ContentMerchantSettings = {
    status: apiContentMerchantSettings.status as MerchantSettings.ProductStatus.LIVE,
  }

  return contentMerchantSettings
}

export interface ApiContentBlogPostBase {
  assistant: {
    thread_id: string
  }
  content_html: string
  id: string
  images: {
    duda: {
      image_id: string
      url: string
    } | null
    image_id: string | null
    keywords: string[] | null
    purchased: ApiContentBlogPost.ImagePurchased | null
    suggestions: ApiContentBlogPost.ImageSuggestion[]
    uploaded: ApiContentBlogPost.ImageUploaded | null
  }
  is_suggestion: boolean
  merchant_id: string
  origin: ContentBlogPost.Origin
  publish_date: string
  status: ContentBlogPost.Status
  summary: string
  title: string
}

export interface ApiContentBlogPostCommon extends ApiContentBlogPostBase {
  origin: ContentBlogPost.Origin.MANUAL | ContentBlogPost.Origin.SCHEDULED
}

export interface ApiContentBlogPostSuggestion extends ApiContentBlogPostBase {
  draft: ApiContentBlogPost.Content | null
  keyword?: string
  optimized_for_seo: boolean | null
  origin: ContentBlogPost.Origin.SUGGESTION
}

export type ApiContentBlogPost = ApiContentBlogPostSuggestion | ApiContentBlogPostCommon

export namespace ApiContentBlogPost {
  export interface Content {
    contentHtml: string
    optimized_for_seo: boolean | null
    title: string
  }
  export interface ImagePurchased {
    id: string
    shutterstock: {
      id: string
    }
    type: "shutterstock"
    url: string
  }

  export interface ImageSuggestion {
    id: string
    shutterstock: {
      id: string
    }
    type: "shutterstock"
    url: {
      small: string
      large: string
    }
  }

  export interface ImageUploaded {
    id: string
    filename: string
    original_url: string
  }
}

export function parseContentBlogPostFromApi( apiBlogPost: ApiContentBlogPost ) {
  const blogPostBase: ContentBlogPost = utilHelpers.toCamelCase<ContentBlogPost>( apiBlogPost )

  if( apiBlogPost.origin === ContentBlogPost.Origin.SUGGESTION ) {
    const blogPostSuggestion: ContentBlogPostSuggestion = {
      ...blogPostBase,
      draft: apiBlogPost.draft
        ? utilHelpers.toCamelCase<ContentBlogPost.Content>( apiBlogPost.draft )
        : null,
      optimizedForSeo: apiBlogPost.optimized_for_seo,
      origin: ContentBlogPost.Origin.SUGGESTION,
    }

    return blogPostSuggestion
  }

  return blogPostBase
}

export interface ApiContentScheduledItemBase {
  date: string
  type: ContentScheduledItem.Type
}

export interface ApiContentScheduledItemBlogPost extends ApiContentScheduledItemBase {
  blog_post: ApiContentBlogPost
  type: ContentScheduledItem.Type.BLOG_POST
}

export interface ApiContentScheduledItemSocialPost extends ApiContentScheduledItemBase {
  social_post: ApiContentSocialPost
  type: ContentScheduledItem.Type.SOCIAL_POST
}

export type ApiContentScheduledItem = (
  ApiContentScheduledItemBlogPost
  | ApiContentScheduledItemSocialPost
)

export function parseContentScheduledItemFromApi( apiScheduledItem: ApiContentScheduledItem ) {
  const scheduledItemBase: ContentScheduledItemBase = {
    date: apiScheduledItem.date,
    type: apiScheduledItem.type,
  }

  const apiScheduledItemType = apiScheduledItem.type

  switch( apiScheduledItem.type ) {
    case ContentScheduledItem.Type.BLOG_POST: {
      const scheduledItemBlogPost: ContentScheduledItemBlogPost = {
        ...scheduledItemBase,
        blogPost: parseContentBlogPostFromApi( apiScheduledItem.blog_post ),
        type: apiScheduledItem.type,
      }

      return scheduledItemBlogPost
    }
    case ContentScheduledItem.Type.SOCIAL_POST: {
      const scheduledItemBlogPost: ContentScheduledItemSocialPost = {
        ...scheduledItemBase,
        socialPost: parseContentSocialPostFromApi( apiScheduledItem.social_post ),
        type: apiScheduledItem.type,
      }

      return scheduledItemBlogPost
    }
    default:
      utilHelpers.assertNever( apiScheduledItem )
      throw new Error( `Unsupported type: ${ apiScheduledItemType }` )
  }
}

export interface ApiContentSocialPost {
  created: {
    at: string
  }
  id: string
  images: ApiContentSocialPost.Image[]
  merchant_id: string
  platform: {
    [ ContentSocialPost.PlatformType.FACEBOOK ]: ApiContentSocialPost.PlatformFacebook
    [ ContentSocialPost.PlatformType.GOOGLE ]: ApiContentSocialPost.PlatformGoogle
    [ ContentSocialPost.PlatformType.INSTAGRAM ]: ApiContentSocialPost.PlatformInstagram
  }
  publish_date: string
  social_post_suggestion_id: string | null
  status: {
    at: string
    details?: string
    logs: ApiContentSocialPost.StatusLog[]
    type: ContentSocialPost.StatusType
  }
  text: string
}

export namespace ApiContentSocialPost {
  export type Image = (
    ImageCustom
    | ImageShutterstock
    | ImageUploaded
  )

  export interface ImageBase {
    id: string
    tags?: string[]
    type: ContentSocialPost.Image.Type
    url: {
      small: string
      large: string
    }
  }

  export interface ImageCustom extends ImageBase {
    type: ContentSocialPost.Image.Type.CUSTOM
  }

  export interface ImageShutterstock extends ImageBase {
    shutterstock: {
      id: string
    }
    type: ContentSocialPost.Image.Type.SHUTTERSTOCK
  }

  export interface ImageUploaded extends ImageBase {
    filename: string
    type: ContentSocialPost.Image.Type.UPLOADED
  }

  export interface PlatformBase {
    enabled: boolean
    status: {
      type: ContentSocialPost.PlatformStatusType
      details: string | null
    }
  }

  export interface PlatformFacebook extends PlatformBase {
    post?: {
      facebook_post_id: string
    }
  }

  export interface PlatformGoogle extends PlatformBase {
    post?: {
      google_post_id: string
    }
  }

  export interface PlatformInstagram extends PlatformBase {
    post?: {
      instagram_post_id: string
    }
  }

  export interface StatusLog {
    date: string
    details?: string
    type: ContentSocialPost.StatusType
  }
}

export function parseContentSocialPostFromApi( apiSocialPost: ApiContentSocialPost ) {
  const socialPost: ContentSocialPost = {
    created: apiSocialPost.created,
    id: apiSocialPost.id,
    images: apiSocialPost.images,
    merchantId: apiSocialPost.merchant_id,
    platform: {
      facebook: {
        enabled: apiSocialPost.platform.facebook.enabled,
        post: (
          apiSocialPost.platform.facebook.post
            ? ( {
              facebookPostId: apiSocialPost.platform.facebook.post.facebook_post_id,
            } )
            : undefined
        ),
        status: apiSocialPost.platform.facebook.status,
      },
      google: {
        enabled: apiSocialPost.platform.google.enabled,
        post: (
          apiSocialPost.platform.google.post
            ? ( {
              googlePostId: apiSocialPost.platform.google.post.google_post_id,
            } )
            : undefined
        ),
        status: apiSocialPost.platform.google.status,
      },
      instagram: {
        enabled: apiSocialPost.platform.instagram.enabled,
        post: (
          apiSocialPost.platform.instagram.post
            ? ( {
              instagramPostId: apiSocialPost.platform.instagram.post.instagram_post_id,
            } )
            : undefined
        ),
        status: apiSocialPost.platform.instagram.status,
      },
    },
    publishDate: apiSocialPost.publish_date,
    socialPostSuggestionId: apiSocialPost.social_post_suggestion_id,
    status: apiSocialPost.status,
    text: apiSocialPost.text,
  }

  return socialPost
}

export interface ApiContentSocialPostSuggestionBase {
  id: string
  image: {
    ids: string[]
    suggestions: ApiContentSocialPostSuggestion.ImageSuggestion[]
    uploaded: ApiContentSocialPostSuggestion.ImageUploaded[]
  }
  merchant_id: string
  platform: Record<ContentSocialPost.PlatformType, ApiContentSocialPostSuggestion.Platform>
  publish_date: {
    at?: string
    optimal: string
    value: ContentPublishDateType.OPTIMAL | ContentPublishDateType.NOW | string
  }
  social_post_id: string | null
  status: {
    at: string
    details?: string
    logs: ApiContentSocialPostSuggestion.StatusLog[]
    type: ContentSocialPostSuggestion.StatusType
  }
  text: string
  text_original: string
  type: ContentSocialPostSuggestion.Type
}

export interface ApiContentSocialPostSuggestionBeforeAfterPhotos extends ApiContentSocialPostSuggestionBase {
  model: {
    after_image?: ApiContentSocialPostSuggestion.ImageUploaded
    before_image?: ApiContentSocialPostSuggestion.ImageUploaded
    description?: string
  }
  type: ContentSocialPostSuggestion.Type.BEFORE_AFTER_PHOTOS
}

export interface ApiContentSocialPostSuggestionBlogPost extends ApiContentSocialPostSuggestionBase {
  blog_post_id: string
  type: ContentSocialPostSuggestion.Type.BLOG_POST
}

export interface ApiContentSocialPostSuggestionBulkMessage extends ApiContentSocialPostSuggestionBase {
  bulk_message: {
    id: string
    date: string
    sender_name: string
    text: string
  }
  type: ContentSocialPostSuggestion.Type.BULK_MESSAGE
}

export interface ApiContentSocialPostSuggestionPhotos extends ApiContentSocialPostSuggestionBase {
  model: {
    description?: string
  }
  type: ContentSocialPostSuggestion.Type.PHOTOS
}

export interface ApiContentSocialPostSuggestionReview extends ApiContentSocialPostSuggestionBase {
  review: {
    id: string
    date: string
    name: string
    rating: number
    source: string
    text: string
  }
  type: ContentSocialPostSuggestion.Type.REVIEW
}

export type ApiContentSocialPostSuggestion = (
  ApiContentSocialPostSuggestionBeforeAfterPhotos
  | ApiContentSocialPostSuggestionBlogPost
  | ApiContentSocialPostSuggestionBulkMessage
  | ApiContentSocialPostSuggestionPhotos
  | ApiContentSocialPostSuggestionReview
)

export namespace ApiContentSocialPostSuggestion {
  export type ImageSuggestion = (
    ImageSuggestionCustom
    | ImageSuggestionShutterstock
  )

  export interface ImageSuggestionBase {
    id: string
    tags?: string[]
    type: ContentSocialPostSuggestion.ImageSuggestion.Type
    url: {
      small: string
      large: string
    }
  }

  export interface ImageSuggestionCustom extends ImageSuggestionBase {
    type: ContentSocialPostSuggestion.ImageSuggestion.Type.CUSTOM
  }

  export interface ImageSuggestionShutterstock extends ImageSuggestionBase {
    shutterstock: {
      id: string
    }
    type: ContentSocialPostSuggestion.ImageSuggestion.Type.SHUTTERSTOCK
  }

  export interface ImageUploaded {
    id: string
    filename: string
    original_url: string
    tags?: string[]
  }

  export interface Platform {
    enabled: boolean
    error?: string
  }

  export interface StatusLog {
    date: string
    details?: string
    type: ContentSocialPostSuggestion.StatusType
  }
}

export function parseContentSocialPostSuggestionImageUploadedFromApi( apiImageUploaded: ApiContentSocialPostSuggestion.ImageUploaded ) {
  const imageUploaded: ContentSocialPostSuggestion.ImageUploaded = {
    filename: apiImageUploaded.filename,
    id: apiImageUploaded.id,
    originalUrl: apiImageUploaded.original_url,
    tags: apiImageUploaded.tags,
  }

  return imageUploaded
}
export function parseContentSocialPostSuggestionFromApi( apiSocialPostSuggestion: ApiContentSocialPostSuggestion ) {
  const socialPostSuggestionBase: ContentSocialPostSuggestionBase = {
    id: apiSocialPostSuggestion.id,
    image: {
      ids: apiSocialPostSuggestion.image.ids,
      suggestions: apiSocialPostSuggestion.image.suggestions,
      uploaded: apiSocialPostSuggestion.image.uploaded.map( parseContentSocialPostSuggestionImageUploadedFromApi ),
    },
    merchantId: apiSocialPostSuggestion.merchant_id,
    platform: apiSocialPostSuggestion.platform,
    publishDate: apiSocialPostSuggestion.publish_date,
    socialPostId: apiSocialPostSuggestion.social_post_id,
    status: apiSocialPostSuggestion.status,
    text: apiSocialPostSuggestion.text,
    textOriginal: apiSocialPostSuggestion.text_original,
    type: apiSocialPostSuggestion.type,
  }

  const apiSocialPostSuggestionType = apiSocialPostSuggestion.type

  switch( apiSocialPostSuggestion.type ) {
    case ContentSocialPostSuggestion.Type.BEFORE_AFTER_PHOTOS: {
      const socialPostSuggestionBeforeAfterPhotos: ContentSocialPostSuggestionBeforeAfterPhotos = {
        ...socialPostSuggestionBase,
        model: {
          afterImage: apiSocialPostSuggestion.model.after_image ? parseContentSocialPostSuggestionImageUploadedFromApi( apiSocialPostSuggestion.model.after_image ) : undefined,
          beforeImage: apiSocialPostSuggestion.model.before_image ? parseContentSocialPostSuggestionImageUploadedFromApi( apiSocialPostSuggestion.model.before_image ) : undefined,
          description: apiSocialPostSuggestion.model.description,
        },
        type: apiSocialPostSuggestion.type,
      }

      return socialPostSuggestionBeforeAfterPhotos
    }
    case ContentSocialPostSuggestion.Type.BLOG_POST: {
      const socialPostSuggestionBlogPost: ContentSocialPostSuggestionBlogPost = {
        ...socialPostSuggestionBase,
        blogPostId: apiSocialPostSuggestion.blog_post_id,
        type: apiSocialPostSuggestion.type,
      }

      return socialPostSuggestionBlogPost
    }
    case ContentSocialPostSuggestion.Type.BULK_MESSAGE: {
      const socialPostSuggestionBulkMessage: ContentSocialPostSuggestionBulkMessage = {
        ...socialPostSuggestionBase,
        bulkMessage: {
          date: apiSocialPostSuggestion.bulk_message.date,
          id: apiSocialPostSuggestion.bulk_message.id,
          senderName: apiSocialPostSuggestion.bulk_message.sender_name,
          text: apiSocialPostSuggestion.bulk_message.text,
        },
        type: apiSocialPostSuggestion.type,
      }

      return socialPostSuggestionBulkMessage
    }
    case ContentSocialPostSuggestion.Type.PHOTOS: {
      const socialPostSuggestionPhotos: ContentSocialPostSuggestionPhotos = {
        ...socialPostSuggestionBase,
        model: apiSocialPostSuggestion.model,
        type: apiSocialPostSuggestion.type,
      }

      return socialPostSuggestionPhotos
    }
    case ContentSocialPostSuggestion.Type.REVIEW: {
      const socialPostSuggestionReview: ContentSocialPostSuggestionReview = {
        ...socialPostSuggestionBase,
        review: apiSocialPostSuggestion.review,
        type: apiSocialPostSuggestion.type,
      }

      return socialPostSuggestionReview
    }
    default:
      utilHelpers.assertNever( apiSocialPostSuggestion )
      throw new Error( `Unsupported type: ${ apiSocialPostSuggestionType }` )
  }
}

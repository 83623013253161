import type { Property } from "csstype"
import React from "react"

export interface FlexProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  alignContent?: Property.AlignContent
  alignItems?: Property.AlignItems
  alignSelf?: Property.AlignSelf
  display?: "flex"
  flex?: Property.Flex
  flexDirection?: Property.FlexDirection
  flexGrow?: Property.FlexGrow
  flexShrink?: Property.FlexShrink
  flexWrap?: Property.FlexWrap
  justifyContent?: Property.JustifyContent
  order?: Property.Order
}

export const Flex: React.FC<FlexProps> = ( {
  alignContent,
  alignItems,
  alignSelf,
  display,
  flex,
  flexDirection,
  flexGrow,
  flexShrink,
  flexWrap,
  justifyContent,
  order,
  style,
  ...otherProps
} ) => {
  return (
    <div
      style={ {
        alignContent,
        alignItems,
        alignSelf,
        display,
        flex,
        flexDirection,
        flexGrow,
        flexShrink,
        flexWrap,
        justifyContent,
        order,
        ...style,
      } }
      { ...otherProps }
    />
  )
}

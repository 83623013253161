// eslint-disable-next-line @nx/enforce-module-boundaries
import type { MerchantSettings } from "@onelocal/frontend-dashboard/settings"

export interface SiteBlogPost {
  contentHtml: string
  id: string
  images: {
    imageId: string
    suggestions: BlogPost.ImageSuggestion[]
    uploaded: BlogPost.ImageUploaded
  }
  merchantId: string
  publishDate: string
  status: BlogPost.Status
  summary: string
  title: string
}

export namespace BlogPost {
  export const enum Actions {
    APPROVE = "approve",
    GENERATE_IMAGES = "generate_images",
    PUBLISH = "publish",
    REGENERATE = "regenerate",
  }

  export const enum ImageTypes {
    SUGGESTED = "suggested",
    UPLOADED = "uploaded",
  }

  export interface ImageSuggestion {
    id: string
    shutterstock: {
      id: string
    }
    type: "shutterstock"
    url: ImageURL
  }

  export interface ImageURL {
    large: string
    small: string
  }

  export interface ImageUploaded {
    id: string
    thumbUrl?: string
    mediumUrl?: string
    originalUrl: string
  }

  export const enum Status {
    APPROVED = "approved",
    GENERATING = "generating",
    PUBLISHED = "published",
    WAITING_APPROVAL = "waiting_approval",
  }

  export const STATUS_LABEL: Record<Status, string> = {
    [ Status.APPROVED ]: "Approved",
    [ Status.GENERATING ]: "Generating",
    [ Status.PUBLISHED ]: "Published",
    [ Status.WAITING_APPROVAL ]: "Waiting for approval",
  }

  export enum TimeframeSelectorValueType {
    MONTHS_3 = "3 months",
    MONTHS_4 = "4 months",
  }

  export enum FrequencySelectorValueType {
    MONTHLY = "monthly",
    WEEKLY = "weekly",
  }

  export enum WritingPerspectiveSelectorValueType {
    FIRST_PERSON = "first person",
    THIRD_PERSON = "third person",
  }

  export enum WritingToneSelectorValueType {
    CONVERSATIONAL = "conversational",
    FRIENDLY = "friendly",
    INFORMATIVE = "informative",
    PROFESSIONAL = "professional",
    WELCOMING = "welcoming",
    WITTY = "witty",
  }
}

export interface ChatConversation {
  id: string
  created: {
    at: string
  }
  merchantId: string
  messages?: ChatMessage[]
  siteId: string
  synopsis?: string
}

export interface ChatMessage {
  chatConversationId: string
  created: {
    at: string
  }
  id: string
  merchantId: string
  text: string
  type: ChatMessage.ChatMessageType
}

export namespace ChatMessage {
  export const enum ChatMessageType {
    AGENT = "agent",
    AGENT_ANSWER_FOLLOWUP = "agent_answer_followup",
    USER = "user",
    USER_FEEDBACK = "user_feedback",
    USER_TRANSFER = "user_transfer",
  }
}

export interface SiteMerchantSettings {
  customerNotifications?: {
    email?: {
      enabled: boolean
      html: string
      subject: string
    }
    sms?: {
      enabled: boolean
      text: string
    }
  }
  status: MerchantSettings.ProductStatus.LIVE | null
  trackDate?: string
  v?: number
}

export namespace SiteMerchantSettings {

}

import type { FunctionComponent } from "react"
import { useCallback, useRef } from "react"
import { useModal } from "react-modal-hook"

export interface useModalWithParamsComponentProps<T> {
  open: boolean
  onExited: () => void
  params: T
}

export function useModalWithParams<T>( component: FunctionComponent<useModalWithParamsComponentProps<T>>, dependencies: unknown[] ) {
  const paramsRef = useRef<T>()

  const [ showModal, hideModal ] = useModal( ( { in: open, onExited } ) => {
    return component( { open, onExited, params: paramsRef.current! } )
  }, dependencies )

  const showModalWithParams = useCallback( ( params: T ) => {
    paramsRef.current = params
    showModal()
  }, [ showModal ] )

  return {
    showModal: showModalWithParams,
    hideModal,
  }
}

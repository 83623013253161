import ReplayIcon from "@mui/icons-material/Replay"
import React, { useCallback } from "react"
import styled from "styled-components"

import { MerchantSelector } from "@onelocal/frontend-admin-web/merchants"
import type { SelectOption } from "@onelocal/frontend-web/common"
import { Flex, SelectInline, styleHelpers } from "@onelocal/frontend-web/common"
import { Activity } from "../../types"

const ALL_VALUE = "all"

const ClearFilterButton = styled.button`
  background-color: ${ styleHelpers.colors.white };
  border-radius: 9px;
  border: 1px solid ${ styleHelpers.colors.lightGray };
  cursor: pointer;
  padding: 12px;
`

const ClearFilterButtonIcon = styled( ReplayIcon )`
  color ${ styleHelpers.colors.dark };
  height: 14px;
  width: 14px;
`

const FilterContainer = styled.div`
  align-items: stretch;
  display: flex;
  gap: 32px;
`

const approvalStatusOptions: Array<SelectOption<string>> = [
  {
    label: "All",
    value: ALL_VALUE,
  },
  {
    label: Activity.ApprovalStatusLabel[ Activity.ApprovalStatus.APPROVED ],
    value: Activity.ApprovalStatus.APPROVED,
  },
  {
    label: Activity.ApprovalStatusLabel[ Activity.ApprovalStatus.PENDING ],
    value: Activity.ApprovalStatus.PENDING,
  },
  {
    label: Activity.ApprovalStatusLabel[ Activity.ApprovalStatus.REJECTED ],
    value: Activity.ApprovalStatus.REJECTED,
  },
]

const categoriesOptions: Array<SelectOption<string>> = [
  {
    label: "All",
    value: ALL_VALUE,
  },
  {
    label: "Keyword Ranking Blog",
    value: Activity.Type.KEYWORD_RANKING_BLOG,
  },
  {
    label: "Share Before After Photos",
    value: Activity.Type.SHARE_BEFORE_AFTER_PHOTOS,
  },
  {
    label: "Share Bulk Message",
    value: Activity.Type.SHARE_BULK_MESSAGE,
  },
  {
    label: "Share Blog Post",
    value: Activity.Type.SHARE_BLOG_POST,
  },
  {
    label: "Share Review",
    value: Activity.Type.SHARE_REVIEW,
  },
  {
    label: "Share Photos",
    value: Activity.Type.SHARE_PHOTOS,
  },
]

export interface AIReviewListFilter {
  approvalStatus?: Activity.ApprovalStatus
  selectedMerchant?: {
    merchantId?: string
    merchantName?: string
  }
  type?: Activity.Type
}

export interface DeliveryActivityReviewTableFilterProps {
  onFilterChanged: ( filter: AIReviewListFilter ) => void
  selectedFilters: AIReviewListFilter
}

export const DeliveryActivityReviewTableFilter: React.FC<DeliveryActivityReviewTableFilterProps> = ( { selectedFilters, onFilterChanged } ) => {
  const onSelectMerchant = useCallback( ( selectedMerchant?: { merchantId?: string, merchantName?: string } ) => {
    onFilterChanged( {
      ...selectedFilters,
      selectedMerchant,
    } )
  }, [ onFilterChanged, selectedFilters ] )

  const onClearFilter = useCallback( () => {
    onFilterChanged( {
      approvalStatus: Activity.ApprovalStatus.PENDING,
      selectedMerchant: undefined,
      type: undefined,
    } )
  }, [ onFilterChanged ] )

  const onTypesChanged = useCallback( ( type: Activity.Type & typeof ALL_VALUE ) => {
    onFilterChanged( {
      type: type === ALL_VALUE ? undefined : type,
    } )
  }, [ onFilterChanged ] )

  const onStatusChanged = useCallback( ( approvalStatus: Activity.ApprovalStatus & typeof ALL_VALUE ) => {
    onFilterChanged( {
      approvalStatus: approvalStatus === ALL_VALUE ? undefined : approvalStatus,
    } )
  }, [ onFilterChanged ] )

  return (
    <Flex
      alignItems="center"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      style={ { margin: "0 0 25px 0" } }
    >

      <MerchantSelector
        initialValue={ selectedFilters.selectedMerchant }
        onSelect={ onSelectMerchant }
      />

      <FilterContainer>
        <SelectInline
          label="Categories"
          onChange={ onTypesChanged }
          options={ categoriesOptions }
          value={ selectedFilters.type || ALL_VALUE }
        />
        <SelectInline
          label="Status"
          onChange={ onStatusChanged }
          options={ approvalStatusOptions }
          value={ selectedFilters.approvalStatus || ALL_VALUE }
        />
        <ClearFilterButton onClick={ onClearFilter }>
          <ClearFilterButtonIcon />
        </ClearFilterButton>
      </FilterContainer>
    </Flex>
  )
}

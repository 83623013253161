// eslint-disable-next-line @nx/enforce-module-boundaries
import type { MerchantSettings } from "@onelocal/frontend-dashboard/settings"
import type { SeoMerchantSettings } from "."

export interface ApiSeoMerchantSettings {
  status: "live"
  v: number
  track_date: string
  projects: ApiSeoMerchantSettings.Project[]
  show_traffic_report: boolean
}

export namespace ApiSeoMerchantSettings {
  export interface Project {
    id: string
    type: string
    name: string
    external_identifier: string
    url: string
  }
}

export function parseSeoMerchantSettingsFromApi( apiSeoMerchantSettings: ApiSeoMerchantSettings ) {
  const seoMerchantSettings: SeoMerchantSettings = {
    status: apiSeoMerchantSettings.status as MerchantSettings.ProductStatus.LIVE,
    v: apiSeoMerchantSettings.v,
    trackDate: apiSeoMerchantSettings.track_date,
    projects: ( apiSeoMerchantSettings.projects || [] ).map( parseSeoMerchantSettingsProjectFromApi ),
    showTrafficReport: apiSeoMerchantSettings.show_traffic_report || false,
  }

  return seoMerchantSettings
}

export function parseSeoMerchantSettingsProjectFromApi( apiSeoMerchantSettingsProject: ApiSeoMerchantSettings.Project ) {
  const seoMerchantSettingsProject: SeoMerchantSettings.Project = {
    id: apiSeoMerchantSettingsProject.id,
    type: apiSeoMerchantSettingsProject.type,
    name: apiSeoMerchantSettingsProject.name,
    externalIdentifier: apiSeoMerchantSettingsProject.external_identifier,
    url: apiSeoMerchantSettingsProject.url,
  }

  return seoMerchantSettingsProject
}

import React, { useCallback, useMemo, useState } from "react"

import { dateHelpers, utilHelpers } from "@onelocal/shared/common"
import type { DayRange, SelectOption } from "."
import { SelectInline, useDayRangePickerModal } from "."

export interface PeriodSelectorProps {
  onChange( value: PeriodSelectorValue | null ): void
  value: PeriodSelectorValue | null
}

export type PeriodSelectorValue = PeriodSelectorValueBasic | PeriodSelectorValueCustom

export interface PeriodSelectorValueBasic {
  type: (
    PeriodSelectorValueType.ALL_TIME
    | PeriodSelectorValueType.LAST_MONTH
    | PeriodSelectorValueType.LAST_WEEK
    | PeriodSelectorValueType.TODAY
    | PeriodSelectorValueType.YESTERDAY
  )
  value: string
}

export interface PeriodSelectorValueCustom {
  type: PeriodSelectorValueType.CUSTOM
  endDate?: string
  startDate: string
  value: string
}

export enum PeriodSelectorValueType {
  ALL_TIME = "all_time",
  CUSTOM = "custom",
  LAST_MONTH = "1M",
  LAST_WEEK = "1w",
  TODAY = "0d",
  YESTERDAY = "1d",
}

export const PeriodSelector: React.FC<PeriodSelectorProps> = ( {
  onChange,
  value,
} ) => {
  const { showDayRangePickerModal } = useDayRangePickerModal()
  const [ dayRange, setDayRange ] = useState<DayRange | null>( null )

  const options = useMemo<Array<SelectOption<string>>>( () => {
    return [
      {
        label: "All Time",
        value: PeriodSelectorValueType.ALL_TIME,
      },
      {
        label: "Today",
        value: PeriodSelectorValueType.TODAY,
      },
      {
        label: "Yesterday",
        value: PeriodSelectorValueType.YESTERDAY,
      },
      {
        label: "Last Week",
        value: PeriodSelectorValueType.LAST_WEEK,
      },
      {
        label: "Last Month",
        value: PeriodSelectorValueType.LAST_MONTH,
      },
      {
        label: dayRange ? `${ dateHelpers.formatToShortDate( dayRange.from ) }${ dayRange.to ? ` - ${ dateHelpers.formatToShortDate( dayRange.to ) }` : "" }` : "Custom",
        value: PeriodSelectorValueType.CUSTOM,
      },
    ]
  }, [ dayRange ] )

  const onRangeSelected = useCallback( ( range: DayRange | null ) => {
    setDayRange( range )

    if( range ) {
      onChange( {
        type: PeriodSelectorValueType.CUSTOM,
        endDate: range.to || undefined,
        startDate: range.from,
        value: `custom_${ dateHelpers.formatToDateValue( range.from ) }_${ range.to ? dateHelpers.formatToDateValue( range.to ) : dateHelpers.formatToDateValue( range.from ) }`,
      } )
    } else {
      onChange( null )
    }
  }, [ onChange ] )

  const onValueChanged = useCallback( ( selectedValue: PeriodSelectorValueType ) => {
    switch( selectedValue ) {
      case PeriodSelectorValueType.CUSTOM:
        showDayRangePickerModal( {
          onRangeSelected,
          range: dayRange,
        } )
        break
      case PeriodSelectorValueType.ALL_TIME:
      case PeriodSelectorValueType.LAST_MONTH:
      case PeriodSelectorValueType.LAST_WEEK:
      case PeriodSelectorValueType.TODAY:
      case PeriodSelectorValueType.YESTERDAY:
        onChange( { type: selectedValue, value: selectedValue } )
        break
      default:
        utilHelpers.assertNever( selectedValue )
    }
  }, [ dayRange, onChange, onRangeSelected, showDayRangePickerModal ] )

  return (
    <SelectInline
      options={ options }
      onValueSelected={ onValueChanged }
      label="Date"
      value={ value?.type || PeriodSelectorValueType.ALL_TIME }
    />
  )
}

import CheckIcon from "@mui/icons-material/Check"
import React, { useCallback, useMemo, useState } from "react"

import type { DialogAction, DialogAlert } from "../components"
import { Dialog } from "../components"
import { useModalWithParams } from "."

export interface ConfirmationModalProps {
  cancelText?: string
  confirmText?: string
  isOpen: boolean
  message: string
  onClose(): void
  onConfirm(): void
  onExited(): void
  title: string
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ( {
  cancelText,
  confirmText,
  isOpen,
  message,
  onClose,
  onConfirm,
  onExited,
  title,
} ) => {
  const [ alert ] = useState<DialogAlert | undefined>( undefined )

  const onConfirmHandler = useCallback( () => {
    onConfirm()
    onClose()
  }, [ onClose, onConfirm ] )

  const modalActions: DialogAction[] = useMemo( () => {
    const actions: DialogAction[] = [
      {
        title: cancelText || "Cancel",
        type: "dismiss",
      },
    ]

    actions.push( {
      icon: ( <CheckIcon style={ { height: 18, width: 18 } }/> ),
      onClick: onConfirmHandler,
      title: confirmText || "Confirm",
      type: "primary",
    } )

    return actions
  }, [ cancelText, confirmText, onConfirmHandler ] )

  return (
    <Dialog
      actions={ modalActions }
      alert={ alert }
      isOpen={ isOpen }
      onClose={ onClose }
      onExited={ onExited }
      showDividers={ false }
      title={ title }
    >
      { message }
    </Dialog>
  )
}

export const useConfirmationModal = () => {
  const { showModal, hideModal } = useModalWithParams<{ cancelText?: string, confirmText?: string, message: string, onConfirm: () => void, title: string }>( ( { open, onExited, params } ) => {
    if( ! params ) {
      return null
    }

    return (
      <ConfirmationModal
        cancelText={ params.cancelText }
        confirmText={ params.confirmText }
        isOpen={ open }
        message={ params.message }
        onClose={ hideModal }
        onConfirm={ params.onConfirm }
        onExited={ onExited }
        title={ params.title }
      />
    )
  }, [] )

  return {
    hideConfirmationModal: hideModal,
    showConfirmationModal: showModal,
  }
}


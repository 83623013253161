import DeleteIcon from "@mui/icons-material/Delete"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import PauseIcon from "@mui/icons-material/Pause"
import React, { useCallback, useEffect, useRef, useState } from "react"
import type { FieldError } from "react-hook-form"
import { Controller, useForm } from "react-hook-form"
import { useSelector } from "react-redux"
import { useEffectOnce } from "react-use"
import styled, { css } from "styled-components"

import { useAsyncDispatch } from "@onelocal/frontend/common"
import { isValidEmail, isValidPhoneNumber, mixpanelHelpers, useDashboardContext } from "@onelocal/frontend-dashboard/common"
import {
  AssistantVoiceDemoUrl,
  connectActions,
  ConnectAssistant,
  connectSelectors,
  useAssistant,
} from "@onelocal/frontend-dashboard/connect"
import { DashboardRoutePath } from "@onelocal/frontend-dashboard-web/common"
import type { SelectOption } from "@onelocal/frontend-web/common"
import {
  Button,
  Flex,
  Link,
  Page,
  SaveButton,
  Select,
  styleHelpers,
  TextField,
  Tooltip,
  useAlert,
} from "@onelocal/frontend-web/common"
import { ProductNames } from "@onelocal/shared/common"
import { ReactComponent as ArrowRightIcon } from "../../assets/arrow-right.svg"

const DeleteItemIcon = styled( DeleteIcon )<{ $error: boolean }>`
  color: rgba(0, 0, 0, 0.54);
  margin-left: 15px;

  ${ ( { $error } ) => $error && `
    margin-bottom: 20px;
    transition: all 0.2s ease;
  ` }

  &:hover {
    cursor: pointer;
  }
`

const DemoAudioStatusIndicator = styled.span`
  margin-left: 12px;
`

const StyledFieldContainer = styled.div`
  align-items: center;
  display: flex;
  margin-top: 20px;
`

const StyledSelect = styled( Select )`
  width: 220px;
`

const StyledLink = styled( Link )`
  align-items: center;
  display: flex;
  width: 100%;

  @media (min-width: 960px) {
    white-space: nowrap;
    width: min-content;
  }
`

const textFieldBaseStyle = css`
  width: 220px;
`

const StyledTextField = styled( TextField )`
  margin: 20px 0 0 0;
  ${ textFieldBaseStyle };
`

const StyledDynamicTextField = styled( TextField )`
  ${ textFieldBaseStyle };
`

const Title = styled.h3`
  color: ${ styleHelpers.colors.dark };
  font-size: ${ styleHelpers.fonts.sizes.mediumLarge };
  font-weight: ${ styleHelpers.fonts.weight.semiBold };
  margin: 0 0 20px 0;
`

const TooltipTitleWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-top: 29px;
`

const voiceDemoMap = {
  [ ConnectAssistant.Voice.IVY ]: AssistantVoiceDemoUrl.IVY,
  [ ConnectAssistant.Voice.JOANNA ]: AssistantVoiceDemoUrl.JOANNA,
  [ ConnectAssistant.Voice.JOEY ]: AssistantVoiceDemoUrl.JOEY,
  [ ConnectAssistant.Voice.JUSTIN ]: AssistantVoiceDemoUrl.JUSTIN,
  [ ConnectAssistant.Voice.KENDRA ]: AssistantVoiceDemoUrl.KENDRA,
  [ ConnectAssistant.Voice.KIMBERLY ]: AssistantVoiceDemoUrl.KIMBERLY,
  [ ConnectAssistant.Voice.MATTHEW ]: AssistantVoiceDemoUrl.MATTHEW,
  [ ConnectAssistant.Voice.SALLI ]: AssistantVoiceDemoUrl.SALLI,
}

const voiceOptions: Array<SelectOption<ConnectAssistant.Voice>> = Object
  .values( ConnectAssistant.Voice )
  .map( ( value ) => ( {
    label: value.split( "." )[ 1 ].split( "-" )[ 0 ],
    value,
  } ) )

interface FormValues {
  businessNumbers: string[]
  emails: string[]
  voice: ConnectAssistant.Voice
}

export interface AssistantGeneralSettingsPageProps {
}

export const AssistantGeneralSettingsPage: React.FC<AssistantGeneralSettingsPageProps> = () => {
  const { showError, showSuccess } = useAlert()
  const dispatch = useAsyncDispatch()
  const audioRef = useRef<HTMLAudioElement | null>( null )
  const [ isAudioPlaying, setIsAudioPlaying ] = useState( false )
  const { dashboardContext } = useDashboardContext()
  const { assistant, isAssistantLoading } = useAssistant( dashboardContext?.merchantId )
  const channel = useSelector( connectSelectors.channels.current )
  const [ businessNumbers, setBusinessNumbers ] = useState<string[]>( [] )
  const [ emails, setEmails ] = useState<string[]>( [] )
  const { control, formState: { errors }, handleSubmit, setValue, watch } = useForm<FormValues>( {
    defaultValues: {
      // businessNumbers and emails from react-hook-form will always be default,
      // Please use the value from the state. this approach gives us an easier way of handling validation and errors
      businessNumbers: [],
      emails: [],
      voice: ConnectAssistant.Voice.JOANNA,
    },
    mode: "all",
    shouldUnregister: true,
  } )
  const demoUrl = watch( "voice" )

  const onSave = useCallback( async ( data: FormValues ) => {
    if( ! dashboardContext?.merchantId ) {
      showError( "Unknown error." )
    }

    const model: ConnectAssistant.UpdateModel = {
      emails,
      voice: data.voice,
      whitelist: businessNumbers,
    }

    await dispatch( connectActions.assistant.update( dashboardContext.merchantId, model ) )
      .then( () => { showSuccess( "Changes saved" ) } )
      .catch( ( err ) => { showError( err ) } )
  }, [ dashboardContext.merchantId, businessNumbers, emails, dispatch, showError, showSuccess ] )

  const onInvalid = useCallback( () => {
    showError( "Please make sure all required fields are filled." )
  }, [ showError ] )

  const onDemoAudioPlay = useCallback( () => {
    if( audioRef?.current == null ) {
      return
    }

    if( ! isAudioPlaying ) {
      setIsAudioPlaying( true )
      audioRef.current.play()
    } else {
      setIsAudioPlaying( false )
      audioRef.current.pause()
    }
  }, [ isAudioPlaying ] )

  const onBusinessNumberChange = useCallback( ( selectedIndex: number ) => {
    return ( value: string ) => {
      setBusinessNumbers( ( prevBusinessNumbers ) => {
        return prevBusinessNumbers.map( ( prevBusinessNumber, index ) => {
          if( index === selectedIndex ) {
            return value
          }

          return prevBusinessNumber
        } )
      } )
    }

  }, [] )

  const addBusinessNumber = useCallback( () => {
    setBusinessNumbers( ( prevBusinessNumbers ) => {
      return [ ...prevBusinessNumbers, "" ]
    } )
  }, [] )

  const removeBusinessNumber = useCallback( ( selectedIndex: number ) => {
    return () => {
      setBusinessNumbers( ( prevBusinessNumbers ) => {
        return prevBusinessNumbers.filter( ( prevBusinessNumber, index ) => index !== selectedIndex )
      } )
    }
  }, [ setBusinessNumbers ] )

  const onEmailChange = useCallback( ( selectedIndex: number ) => {
    return ( value: string ) => {
      setEmails( ( prevEmails ) => {
        return prevEmails.map( ( prevEmail, index ) => {
          if( index === selectedIndex ) {
            return value
          }

          return prevEmail
        } )
      } )
    }

  }, [] )

  const addEmail = useCallback( () => {
    setEmails( ( prevEmails ) => {
      return [ ...prevEmails, "" ]
    } )
  }, [] )

  const removeEmail = useCallback( ( selectedIndex: number ) => {
    return () => {
      setEmails( ( prevEmails ) => {
        return prevEmails.filter( ( prevEmail, index ) => index !== selectedIndex )
      } )
    }
  }, [ setEmails ] )

  const onVoiceSelect = useCallback( ( onChange: ( value: string ) => void ) => {
    return ( updatedValue: string ) => {
      if( isAudioPlaying ) {
        setIsAudioPlaying( false )
        audioRef.current?.pause()
      }
      onChange( updatedValue )
    }
  }, [ isAudioPlaying ] )

  const onAudioFinished = useCallback( () => {
    setIsAudioPlaying( false )
  }, [] )

  useEffectOnce( () => {
    mixpanelHelpers.trackPageOpened(
      "Settings/LocalMessages/LocalResponse/General",
      { "Product": ProductNames.LOCAL_RESPONSE },
    )
  } )

  useEffect( () => {
    if( ! assistant ) {
      return
    }

    setValue( "voice", assistant.voice )
    setBusinessNumbers( assistant.whitelist )
    setEmails( assistant.emails )
  }, [ assistant, setValue ] )

  return (
    <Page
      buttons={ [
        {
          renderButton: ( key ) => (
            <SaveButton
              key={ key }
              onClick={ handleSubmit( onSave, onInvalid ) }
            />
          ),
        },
      ] }
      isLoading={ isAssistantLoading }
      parentPaths={ [
        { name: "Settings", path: DashboardRoutePath.SETTINGS },
        { name: "LocalMessages", path: DashboardRoutePath.MESSAGES_SETTINGS },
        { name: "LocalResponse", path: DashboardRoutePath.CONNECT_SETTINGS_LOCAL_RESPONSE },
      ] }
      title="General"
    >
      <Title>General Settings</Title>
      <p style={ { margin: "0 0 20px 0" } }>You can select a voice for your LocalResponse assistant below.</p>
      <Flex alignItems="center" display="flex">
        <Controller
          name="voice"
          control={ control }
          render={ ( { field: { onChange, value } } ) => {
            const error = errors?.[ "voice" ]?.message
            return (
              <StyledSelect
                error={ error != null }
                helpText={ error }
                label="Bot Voice"
                onChange={ onVoiceSelect( onChange ) }
                options={ voiceOptions }
                required={ true }
                value={ value || "" }
              />
            ) }
          }
        />
        <Button onClick={ onDemoAudioPlay } style={ { marginLeft: 20 } }>
          <audio
            onEnded={ onAudioFinished }
            ref={ audioRef }
            src={ voiceDemoMap[ demoUrl ] }
            style={ { display: "none" } }
          />
          {
            isAudioPlaying
              ? (
                <>
                  <PauseIcon />
                  <DemoAudioStatusIndicator>Stop Voice</DemoAudioStatusIndicator>
                </>
              )
              : (
                <>
                  <ArrowRightIcon />
                  <DemoAudioStatusIndicator>Play Voice</DemoAudioStatusIndicator>
                </>
              )
          }
        </Button>
      </Flex>

      <TooltipTitleWrapper>
        <Title style={ { margin: 0 } }>Main Email(s)</Title>
        <Tooltip text="LocalResponse call emails will be sent to the email addresses added here."/>
      </TooltipTitleWrapper>
      {
        emails.map( ( email, index ) => {
          const fieldName = `emails.${ index }` as const
          return (
            <Controller
              control={ control }
              name={ fieldName }
              key={ fieldName }
              render={ () => {
                const error = ( errors.emails as FieldError[] )?.filter( ( fieldError ) => fieldError?.ref?.name === fieldName )
                return (
                  <StyledFieldContainer>
                    <StyledDynamicTextField
                      errorMessage={ error?.[ 0 ]?.message }
                      label="Email"
                      onChange={ onEmailChange( index ) }
                      required={ true }
                      value={ email }
                      variant="outlined"
                    />
                    <DeleteItemIcon
                      $error={ error?.length > 0 }
                      onClick={ removeEmail( index ) }
                    />
                  </StyledFieldContainer>
                )
              } }
              rules={ {
                validate: () => {
                  const value = emails[ index ]
                  if( ! value ) {
                    return "Email is required"
                  }
                  if( ! isValidEmail( value ) ) {
                    return "Email is invalid"
                  }
                  return undefined
                },
              } }
            />
          )
        } )
      }
      <AddButton onClick={ addEmail } title="+ Add Email" />
      <Title style={ { marginTop: 30 } }>Call Forwarding Settings</Title>
      <p style={ { margin: "0 0 20px 0" } }>
          In order to setup LocalResponse, you need to setup conditional call forwarding from your main business line(s) to your LocalResponse number.
      </p>
      <StyledLink
        href="https://intercom.help/onelocal/en/articles/5026905-how-to-add-conditional-call-forwarding-for-localresponse-from-your-business-line"
        target="_blank"
      >
        <div>Find out how to setup conditional call forwarding with your carrier here.</div>
        <HelpOutlineIcon fontSize="small" style={ { marginLeft: 5.5 } }/>
      </StyledLink>
      <StyledTextField
        disabled={ true }
        label="LocalResponse Number"
        value={ channel?.phoneNumber?.nationalFormat || "" }
      />
      <TooltipTitleWrapper>
        <Title style={ { margin: 0 } }>Main Business Phone Number(s)</Title>
        <Tooltip
          text="This is the number that you will setup conditional call forwarding from. If you're setting up conditional call forwarding from multiple numbers please add them below."
        />
      </TooltipTitleWrapper>
      {
        businessNumbers.map( ( businessNumber, index ) => {
          const fieldName = `businessNumbers.${ index }` as const
          return (
            <Controller
              control={ control }
              name={ fieldName }
              key={ fieldName }
              render={ () => {
                const error = ( errors.businessNumbers as FieldError[] )?.filter( ( fieldError ) => fieldError?.ref?.name === fieldName )
                return (
                  <StyledFieldContainer>
                    <StyledDynamicTextField
                      errorMessage={ error?.[ 0 ]?.message }
                      label="Business Number"
                      onChange={ onBusinessNumberChange( index ) }
                      required={ true }
                      value={ businessNumber }
                      variant="outlined"
                    />
                    <DeleteItemIcon
                      $error={ error?.length > 0 }
                      onClick={ removeBusinessNumber( index ) }
                    />
                  </StyledFieldContainer>
                )
              } }
              rules={ {
                validate: () => {
                  const value = businessNumbers[ index ]
                  if( ! value ) {
                    return "Phone number is required"
                  }
                  if( ! isValidPhoneNumber( value ) ) {
                    return "Phone number is invalid"
                  }
                  return undefined
                },
              } }
            />
          )
        } )
      }
      <AddButton onClick={ addBusinessNumber } title="+ Add Business Number"/>
    </Page>
  )
}

const AddItemContainer = styled( Link )`
  margin-top: 14px;
  transition: all 0.2s ease;
  white-space: nowrap;
  width: min-content;
`

interface AddButtonProps {
  title: string
  onClick(): void
}

export const AddButton: React.FC<AddButtonProps> = React.memo( ( { title, onClick } ) => (
  <AddItemContainer color="primary" onClick={ onClick }>
    { title }
  </AddItemContainer>
) )

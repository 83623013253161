import type { ReactNode } from "react"
import React, { createContext, useContext, useState } from "react"

export enum Steps {
  PREVIEW = "preview",
  SCHEDULING = "scheduling",
}

export interface ContentPreviewContextType {
  currentStep: Steps
  setStep( selectedContent: Steps ): void
}

const ContentPreviewContext = createContext<ContentPreviewContextType>( null! )

export const ContentPreviewContextProvider: React.FunctionComponent<{ children: ReactNode }> = ( { children } ) => {
  const [ currentStep, setStep ] = useState<Steps>( Steps.PREVIEW )

  return (
    <ContentPreviewContext.Provider
      value={ {
        currentStep,
        setStep,
      } }
    >
      { children }
    </ContentPreviewContext.Provider>
  )
}

export function useContentPreviewContext(): ContentPreviewContextType {
  return useContext( ContentPreviewContext )
}

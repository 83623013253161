
import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

import type { DefaultThunkAction, ModuleStoreConfig } from "@onelocal/frontend/common"
// eslint-disable-next-line @nx/enforce-module-boundaries
import type { SiteSettingsService } from "@onelocal/frontend-dashboard/site"
// eslint-disable-next-line @nx/enforce-module-boundaries
import { siteSettingsService } from "@onelocal/frontend-dashboard/site"
import { merchantSettingsService } from "../services/merchantSettings"
import type { MerchantSettings } from "../types"

export interface SettingsState {
  merchantSettings: Record<string, MerchantSettings>
}

type SettingsThunkAction<TReturnType> = DefaultThunkAction<{ settings: SettingsState}, TReturnType>

const settingsStoreKey = "settings"

export const settingsInitialState: SettingsState = {
  merchantSettings: {},
}

const settingsSlice = createSlice( {
  name: settingsStoreKey,
  initialState: settingsInitialState,
  reducers: {
    updateMerchantSettings: ( state, action: PayloadAction<MerchantSettings> ) => {
      state.merchantSettings[ action.payload.merchantId ] = action.payload
      return state
    },
  },
} )

export const settingsActions = {
  getMerchantSettings( merchantId: string ): SettingsThunkAction<MerchantSettings> {
    return async ( dispatch ) => {
      const merchantSettings = await merchantSettingsService.getSettings( merchantId )
      dispatch( settingsSlice.actions.updateMerchantSettings( merchantSettings ) )
      return merchantSettings
    }
  },
  updateSiteSettings( merchantId: string, model: SiteSettingsService.UpdateModel ): SettingsThunkAction<void> {
    return async ( dispatch ) => {
      await siteSettingsService.updateById( merchantId, model )
      dispatch( settingsActions.getMerchantSettings( merchantId ) )
    }
  },
}

export const settingsStoreConfig: ModuleStoreConfig<SettingsState> = {
  key: settingsStoreKey,
  initialState: settingsInitialState,
  reducers: settingsSlice.reducer,
}

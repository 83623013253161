import React, { useCallback, useMemo } from "react"
import type { SubmitHandler } from "react-hook-form"
import { Controller, FormProvider } from "react-hook-form"
import { useParams } from "react-router-dom"
import styled from "styled-components"

import { useForm, useResource } from "@onelocal/frontend/common"
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Button, Flex, InfoIcon, Loading, ReadOnlyField, StyledSectionTitle, styleHelpers, TextField, Tooltip, useAlert } from "@onelocal/frontend-web/common"
import { dateHelpers } from "@onelocal/shared/common"
import { merchantsActions, merchantsSelectors } from ".."
import { MerchantDetailsContainer } from "../components/MerchantDetailsContainer"
import { useMerchantSettings } from "../hooks/useMerchantSettings"
import { merchantsService } from "../services/merchantsService"

export interface MerchantDetailsAnatyticsPageProps {
}

enum FormFieldKeys {
  CAMPAIGN_ID = "campaign_id",
}

type FormValues = {
  [ FormFieldKeys.CAMPAIGN_ID ]: string
}

const StyledButton = styled( Button )`
  margin-left: 15px;
`

const StyledCampaignDetailsContainer = styled.div`
  margin-top: 30px;
`

export const MerchantDetailsAnalyticsPage: React.FC<MerchantDetailsAnatyticsPageProps> = () => {
  const { merchantId } = useParams<{ merchantId: string }>() as { merchantId: string }
  const { showError, showSuccess } = useAlert()

  const { resource: merchant } = useResource( {
    getAction: merchantsActions.merchants.get,
    id: merchantId,
    selector: merchantsSelectors.merchants.byId,
  } )

  const { merchantSettings, reloadMerchantSettings } = useMerchantSettings( merchantId )

  const formMethods = useForm<FormValues>( {
    defaultValues: {
      [ FormFieldKeys.CAMPAIGN_ID ]: merchantSettings?.agencyAnalytics?.campaignId || "",
    },
  } )

  const { control, formState, handleSubmit, watch } = formMethods

  const merchantCampaignCreatedDate = useMemo( () => {
    if( merchantSettings?.agencyAnalytics?.log?.length ) {
      return dateHelpers.formatToDateValue( merchantSettings.agencyAnalytics.log[ 0 ].at )
    }

    return "-"
  }, [ merchantSettings ] )

  const onSave: SubmitHandler<FormValues> = useCallback( async ( data ) => {
    try {
      await merchantsService.updateCampaignId( merchantId, data[ FormFieldKeys.CAMPAIGN_ID ] )
      await reloadMerchantSettings()
      showSuccess( "Campaign ID updated" )
    } catch( err ) {
      showError( err )
    }
  }, [ merchantId, reloadMerchantSettings, showError, showSuccess ] )

  if( ! merchant || ! merchantSettings ) {
    return (
      <Loading />
    )
  }

  return (
    <MerchantDetailsContainer>
      <StyledSectionTitle style={ { color: styleHelpers.colors.primary, textTransform: "uppercase" } }>Campaign Details</StyledSectionTitle>
      <StyledCampaignDetailsContainer>
        <FormProvider { ...formMethods }>
          <Flex
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Flex
              alignItems="center"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Controller
                control={ control }
                name={ FormFieldKeys.CAMPAIGN_ID }
                render={ ( { field: { onChange, value } } ) => (
                  <TextField
                    errorMessage={ formState.errors?.[ FormFieldKeys.CAMPAIGN_ID ]?.message }
                    fullWidth
                    label="Campaign ID"
                    onChange={ onChange }
                    required={ true }
                    value={ value || "" }
                  />
                ) }
              />
              <Tooltip placement="bottom" text={ "For the URL https://onelocal671.agencyanalytics.app/campaign/1002282/overview/dashboard/30827391?utm_campaign, the campaign ID is 1002282" }>
                <InfoIcon />
              </Tooltip>
              <StyledButton disabled={ watch( FormFieldKeys.CAMPAIGN_ID ) === ( merchantSettings?.agencyAnalytics?.campaignId || "" ) } onClick={ handleSubmit( onSave ) }>Save</StyledButton>
            </Flex>
            <Flex>
              <ReadOnlyField
                label={ "Campaign Created Date" }
                value={ merchantCampaignCreatedDate! }
              />
            </Flex>
            <Flex>
              <ReadOnlyField
                label={ "Username/Password" }
                value={ merchantId }
              />
            </Flex>
          </Flex>
        </FormProvider>
      </StyledCampaignDetailsContainer>
    </MerchantDetailsContainer>
  )
}

import { isEmpty } from "lodash"

// eslint-disable-next-line @nx/enforce-module-boundaries
import type { MerchantSettings } from "@onelocal/frontend-dashboard/settings"
import type { MessagesChannel, MessagesMerchantsSettings, MessagesPhoneNumber } from "."

export interface ApiMessagesChannel {
  id: string
  type: "phone"
  platform: ApiMessagesChannel.TwilioPlatform
  phone_number: ApiMessagesPhoneNumber
  call_forwarding?: {
    enabled?: boolean
    phone_number?: ApiMessagesPhoneNumber
  }
  block_voip: boolean
}

export namespace ApiMessagesChannel {
  export interface TwilioPlatform {
    type: "twilio"
    twilio_phone_id: string
    capabilities?: TwilioPlatformCapability[]
  }

  export enum TwilioPlatformCapability {
    FAX = "fax",
    MMS = "mms",
    SMS = "sms",
    VOICE = "voice",
  }
}

export function parseMessagesChannelFromApi( apiMessagesChannel: ApiMessagesChannel ) {
  const messagesChannel: MessagesChannel = {
    blockVoip: apiMessagesChannel.block_voip,
    id: apiMessagesChannel.id,
    phoneNumber: parseMessagesPhoneNumberFromApi( apiMessagesChannel.phone_number ),
    platform: {
      capabilities: apiMessagesChannel.platform.capabilities as MessagesChannel.TwilioPlatformCapability[],
      twilioPhoneId: apiMessagesChannel.platform.twilio_phone_id,
      type: apiMessagesChannel.platform.type,
    },
    type: apiMessagesChannel.type,
  }

  if( apiMessagesChannel.call_forwarding && ! isEmpty( apiMessagesChannel.call_forwarding ) ) {
    messagesChannel.callForwarding = {
      enabled: apiMessagesChannel.call_forwarding.enabled,
    }
    if( apiMessagesChannel.call_forwarding.phone_number && ! isEmpty( apiMessagesChannel.call_forwarding.phone_number ) ) {
      messagesChannel.callForwarding.phoneNumber = parseMessagesPhoneNumberFromApi( apiMessagesChannel.call_forwarding.phone_number )
    }
  }

  return messagesChannel
}

export interface ApiMessagesMerchantSettings {
  assignment: {
    enabled: boolean
    assign_on_reply: boolean
    unassign_closed: boolean
    default_assignee?: {
      ref: "employees"
      id: string
    }
  }
  autoresponder: {
    status: MessagesMerchantsSettings.AutoResponderStatusType
    message?: string
    periods?: MessagesMerchantsSettings.AutoResponderPeriod[]
  }
  enabled: boolean
  status: MerchantSettings.ProductStatus
  v?: number
  widget?: {
    chatbot?: {
      name?: string
    }
  }
}

export function parseMessagesMerchantSettings( apiMessagesMerchantSettings: ApiMessagesMerchantSettings, apiMessagesChannel?: ApiMessagesChannel | null ) {
  const messagesMerchantSettings: MessagesMerchantsSettings = {
    assignment: {
      assignOnReply: apiMessagesMerchantSettings.assignment.assign_on_reply,
      enabled: apiMessagesMerchantSettings.assignment.enabled,
      unassignClosed: apiMessagesMerchantSettings.assignment.unassign_closed,
      defaultAssignee: (
        apiMessagesMerchantSettings.assignment.default_assignee
          ? apiMessagesMerchantSettings.assignment.default_assignee
          : undefined
      ),
    },
    autoresponder: apiMessagesMerchantSettings.autoresponder,
    channel: (
      apiMessagesChannel
        ? parseMessagesChannelFromApi( apiMessagesChannel )
        : undefined
    ),
    enabled: apiMessagesMerchantSettings.enabled,
    status: apiMessagesMerchantSettings.status,
    v: apiMessagesMerchantSettings.v,
    widget: apiMessagesMerchantSettings.widget,
  }

  return messagesMerchantSettings
}

export interface ApiMessagesPhoneNumber {
  type: "mobile" | "landline" | "voip" | "unknown"
  value: string
  international_format: string
  national_format: string
  country_calling_code: number
  country_code: string
}

export function parseMessagesPhoneNumberFromApi( apiPhoneNumber: ApiMessagesPhoneNumber ) {
  const phoneNumber: MessagesPhoneNumber = {
    countryCallingCode: apiPhoneNumber.country_calling_code,
    countryCode: apiPhoneNumber.country_code,
    internationalFormat: apiPhoneNumber.international_format,
    nationalFormat: apiPhoneNumber.national_format,
    type: apiPhoneNumber.type,
    value: apiPhoneNumber.value,
  }

  return phoneNumber
}

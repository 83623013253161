import { createSelector } from "@reduxjs/toolkit"

import type { AuthState, AuthStateSelector } from "./authStore"

export const authSelector = <TRootState extends { auth: AuthState }>( state: TRootState ) => state.auth

export const createAuthSelector = <TRootState extends { auth: AuthState }, TReturnValue>( combiner: ( authState: AuthState ) => TReturnValue ) => {
  return createSelector<AuthStateSelector<TRootState>, TReturnValue>(
    authSelector,
    combiner,
  )
}

export const authSelectors = {
  internalAccounts: {
    current: createAuthSelector(
      ( authState ) => (
        authState.internalAccounts.currentId ? authState.internalAccounts.byId[ authState.internalAccounts.currentId ] : null
      ),
    ),
    byId: ( internalAccountId: string ) => {
      return createAuthSelector(
        ( authState ) => authState.internalAccounts.byId[ internalAccountId ],
      )
    },
    byIds: ( internalAccountIds: string[] ) => {
      return createAuthSelector(
        ( authState ) => internalAccountIds.map( ( internalAccountId ) => authState.internalAccounts.byId[ internalAccountId ] ).filter( ( internalAccount ) => internalAccount != null ),
      )
    },
  },
}

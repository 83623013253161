// eslint-disable-next-line no-restricted-imports
import { createTheme, StyledEngineProvider, ThemeProvider as MuiThemeProvider } from "@mui/material"
import { SnackbarProvider } from "notistack"
import React, { createContext, useContext, useMemo } from "react"
import { createGlobalStyle } from "styled-components"

import { styleHelpers } from "../helpers/styleHelpers"

export const GlobalStyle = createGlobalStyle`
  html, body {
    font-family: 'Gotham Rounded A', 'Gotham Rounded B', sans-serif;
    font-size: ${ styleHelpers.fonts.sizes.default };
    font-weight: ${ styleHelpers.fonts.weight.default };
    line-height: normal;
    margin: 0;
    min-height: 100%;
    padding: 0;
    -moz-osx-font-smoothing: grayscale;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    -webkit-touch-callout: none;
  }

  .intercom-1o77lls {
    left: 0px !important;
    top: 1px !important;
  }

  .intercom-launcher-frame {
    position: absolute !important;
  }

  .intercom-lightweight-app-launcher {
    right: 60px !important;
  }
`

interface ThemeContextType {

}

const ThemeContext = createContext<ThemeContextType>( null! )

export interface ThemeProviderProps {
  disableGlobalStyle?: boolean
}

export const ThemeProvider: React.FunctionComponent<React.PropsWithChildren<ThemeProviderProps>> = ( { children } ) => {
  const muiTheme = useMemo( () => {
    return createTheme( {
      palette: {
        background: {
          default: "#ffffff",
          paper: "#fefefe",
        },
        primary: {
          main: "#f15f4b",
        },
        secondary: {
          main: "#3b99fc",
        },
        mode: "light",
      },
      components: {
        MuiButtonBase: {
          defaultProps: {
            disableTouchRipple: true,
          },
        },
        MuiDialog: {
          styleOverrides: {
            paper: {
              "minWidth": "400px",
            },
          },
        },
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              "fontWeight": styleHelpers.fonts.weight.default,
              "backgroundColor": "#464646",
            },
            tooltipPlacementBottom: {
              // it doesn't apply the styles when important isn't used
              "marginTop": "8px !important",
            },
          },
        },
      },
      typography: {
        fontFamily: styleHelpers.fonts.default,
        fontSize: 14,
        h1: {
          fontSize: "2.5rem",
        },
      },
    } )
  }, [] )

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={ muiTheme }>
        <GlobalStyle />
        <ThemeContext.Provider value={ {} }>
          <SnackbarProvider maxSnack={ 3 }>
            { children }
          </SnackbarProvider>
        </ThemeContext.Provider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  )
}

export function useThemeContext(): ThemeContextType {
  return useContext( ThemeContext )
}

import type { Selector } from "@reduxjs/toolkit"
import { createSelector } from "@reduxjs/toolkit"
import type { RootState } from "libs/frontend-dashboard-web/main/src/store"

import type { IntegrationsState } from "./integrationsStore"

export const integrationSelector: Selector<RootState, IntegrationsState> = state => state.integrations

export const createIntegrationSelector = <T>( combiner: ( integrationState: IntegrationsState ) => T ) => {
  return createSelector(
    integrationSelector,
    combiner,
  )
}

export const integrationCredentialsSelector = createIntegrationSelector(
  ( integrationState ) => Object.values( integrationState.credentials.byId ),
)

export const integrationCredentialSelector = ( credentialId: string ) => (
  createIntegrationSelector(
    ( integrationState ) => integrationState.credentials.byId[ credentialId ] || null,
  )
)

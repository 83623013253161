import CheckIcon from "@mui/icons-material/Check"
import React, { useCallback, useState } from "react"
import { Controller } from "react-hook-form"
import styled from "styled-components"

import { useAsyncDispatch, useForm } from "@onelocal/frontend/common"
import { useDashboardContext } from "@onelocal/frontend-dashboard/common"
import { siteActions } from "@onelocal/frontend-dashboard/site"
import { DatePicker, Dialog, TextField, useAlert, useModalWithParams } from "@onelocal/frontend-web/common"
import { dateHelpers } from "@onelocal/shared/common"

const StyledSiteBlogPostPublishDatePicker = styled( DatePicker )`
  margin-top: 35px;
`

enum FormFieldKeys {
  PUBLISH_DATE = "publishDate",
  TOPIC = "topic",
}

type FormValues = {
  publishDate: string
  topic: string
}

export interface SiteBlogPostCreateModalProps {
  isOpen: boolean
  onClose(): void
  onCreated(): void
  onExited: () => void
}

export const SiteBlogPostCreateModal: React.FC<SiteBlogPostCreateModalProps> = ( {
  isOpen,
  onClose,
  onCreated,
  onExited,
} ) => {
  const dispatch = useAsyncDispatch()
  const [ isProcessing, setIsProcessing ] = useState<boolean>( false )
  const { showSuccess, showError } = useAlert()
  const { control, handleSubmit } = useForm<FormValues>( {
    defaultValues: {
      [ FormFieldKeys.PUBLISH_DATE ]: null,
      [ FormFieldKeys.TOPIC ]: "",
    },
  } )
  const { dashboardContext } = useDashboardContext()

  const onGenerate = useCallback( async ( data: FormValues ) => {
    if( isProcessing ) {
      return
    }

    setIsProcessing( true )
    try {
      await dispatch( siteActions.blogPosts.createBlogPost( dashboardContext.merchantId, {
        ...data,
        publishDate: dateHelpers.formatToDateValue( data.publishDate ) as string,
      } ) )
      showSuccess( "Blog Post is generating" )
      onClose()
      onCreated()
    } catch( err ) {
      showError( err ?? "There was an error when trying to generate the Blog Post" )
    } finally {
      setIsProcessing( false )
    }
  }, [ dashboardContext.merchantId, dispatch, isProcessing, onClose, onCreated, showError, showSuccess ] )

  return (
    <Dialog
      actions={ [ {
        title: "Cancel",
        type: "dismiss",
      }, {
        disabled: isProcessing,
        icon: <CheckIcon style={ { height: 18, width: 18 } }/>,
        title: "Generate",
        onClick: handleSubmit( onGenerate ),
      } ] }
      isOpen={ isOpen }
      onClose={ onClose }
      onExited={ onExited }
      noMaxWidth={ true }
      title="Generate Post"
    >
      <div>
        <Controller
          control={ control }
          name="topic"
          render={ ( { field: { onChange, value } } ) => (
            <TextField
              fullWidth={ true }
              label="Post Theme (Optional)"
              value={ value || "" }
              onChange={ onChange }
            />
          ) }
        />

        <Controller
          control={ control }
          name="publishDate"
          render={ ( { field: { onChange, value } } ) => (
            <StyledSiteBlogPostPublishDatePicker
              fullWidth={ false }
              label="Publish Date"
              onChange={ onChange }
              required={ true }
              value={ value }
            />
          ) }
        />
      </div>
    </Dialog>
  )
}

export interface useSiteBlogPostCreateModalParams {
  onCreated(): void
}
export const useSiteBlogPostCreateModal = () => {
  const { hideModal, showModal } = useModalWithParams<useSiteBlogPostCreateModalParams>( ( { open, onExited, params } ) => {
    return (
      <SiteBlogPostCreateModal
        isOpen={ open }
        onClose={ hideModal }
        onCreated={ params.onCreated }
        onExited={ onExited }
      />
    )
  }, [] )

  return {
    hideSiteBlogPostCreateModal: hideModal,
    showSiteBlogPostCreateModal: showModal,
  }
}

import React from "react"
import { Controller, FormProvider } from "react-hook-form"
import styled from "styled-components"

import { RadioGroup, StyledFormSectionTitle } from "@onelocal/frontend-web/common"
import { useOnboardingForm } from "../../hooks/useOnboardingForm"
import { OnboardingForm } from "../../types"
import { OnboardingFormButtons, OnboardingFormDisclaimerLabel, OnboardingFormFieldCheckBox, OnboardingFormSaving } from "./OnboardingFormFields"

const StyledFieldContainer = styled.div`
  align-items: center;
  display: flex;
  margin: 20px 0;
`

type FormValues = {
  [OnboardingForm.Field.SOCIAL_CONTENT_CURRENT_BLOG_MOVE]: string
  [OnboardingForm.Field.SOCIAL_CONTENT_FB_ENABLED]: boolean
  [OnboardingForm.Field.SOCIAL_CONTENT_GBP_ENABLED]: boolean
  [OnboardingForm.Field.SOCIAL_CONTENT_IG_ENABLED]: boolean
}

const socialNetworkOptions = [
  {
    name: "GBP",
    fieldName: OnboardingForm.Field.SOCIAL_CONTENT_GBP_ENABLED,
  },
  {
    name: "IG",
    fieldName: OnboardingForm.Field.SOCIAL_CONTENT_IG_ENABLED,
  },
  {
    name: "FB",
    fieldName: OnboardingForm.Field.SOCIAL_CONTENT_FB_ENABLED,
  },
] as Array<{
  name: string
  fieldName: keyof FormValues
}>

const itemTypeOptions = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
]

export interface OnboardingFormStepSocialContentProps {
}

export const OnboardingFormStepSocialContent: React.FC<OnboardingFormStepSocialContentProps> = () => {
  const {
    formMethods,
    isSaving,
    onChangeHandlers,
    onSubmit,
  } = useOnboardingForm<FormValues>( {
    defaultValues: {
      [ OnboardingForm.Field.SOCIAL_CONTENT_GBP_ENABLED ]: true,
    },
    fieldNames: [
      OnboardingForm.Field.SOCIAL_CONTENT_CURRENT_BLOG_MOVE,
      OnboardingForm.Field.SOCIAL_CONTENT_FB_ENABLED,
      OnboardingForm.Field.SOCIAL_CONTENT_GBP_ENABLED,
      OnboardingForm.Field.SOCIAL_CONTENT_IG_ENABLED,
    ],
    step: OnboardingForm.Step.SOCIAL_CONTENT,
  } )

  const { control } = formMethods

  return (
    <div>
      <OnboardingFormSaving isSaving={ isSaving } />
      <FormProvider { ...formMethods }>
        <form onSubmit={ onSubmit }>

          <StyledFormSectionTitle>What do you want your content posted to?</StyledFormSectionTitle>
          <StyledFieldContainer>
            {
              socialNetworkOptions.map( ( option ) => (
                <div key={ option.name }>
                  <OnboardingFormFieldCheckBox
                    fieldName={ option.fieldName }
                    label={ option.name }
                    onChange={ onChangeHandlers[ option.fieldName ] as unknown as ( value: boolean ) => void }
                  />
                </div>
              ) )
            }
          </StyledFieldContainer>

          <StyledFormSectionTitle>{ "Are there any current blogs on the client's existing site that need to be moved over?" }</StyledFormSectionTitle>
          <Controller
            control={ control }
            name={ OnboardingForm.Field.SOCIAL_CONTENT_CURRENT_BLOG_MOVE }
            render={ ( { field: { onChange, value } } ) => (
              <RadioGroup
                itemAlignment="row"
                options={ itemTypeOptions }
                onChange={ onChangeHandlers[ OnboardingForm.Field.SOCIAL_CONTENT_CURRENT_BLOG_MOVE ] }
                value={ value }
              />
            ) }
          />

          <OnboardingFormDisclaimerLabel />
          <OnboardingFormButtons disabled={ isSaving } />
        </form>
      </FormProvider>
    </div>
  )
}

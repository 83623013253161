import { useCallback } from "react"

import { useResourceSingleton } from "@onelocal/frontend/common"
import { commonActions } from ".."
import { commonSelectors } from "../store/commonSelectors"

export const useCurrentSession = () => {
  const getAction = useCallback( () => {
    return commonActions.session.getCurrentSession()
  }, [] )

  const {
    resource,
    reloadResource,
  } = useResourceSingleton( {
    getAction,
    selector: commonSelectors.session.current,
  } )

  return {
    currentSession: resource,
    reloadCurrentSession: reloadResource,
  }
}

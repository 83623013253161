import type React from "react"
import { useMemo, useState } from "react"
import styled from "styled-components"

import { useCurrentSession } from "@onelocal/frontend-dashboard/auth"
import { MainTask, useTaskWorkflow } from "@onelocal/frontend-dashboard/main"
import { useMerchantSettings } from "@onelocal/frontend-dashboard/settings"
import { DashboardRoutePath, IntercomHelpers, intercomHelpers } from "@onelocal/frontend-dashboard-web/common"
import { Flex, Link, Page, styleHelpers } from "@onelocal/frontend-web/common"
import { AdsPromotionInfo } from "../components/HelpCenter/AdsPromotionInfo"
import { Categories } from "../components/HelpCenter/Categories"
import * as HelpCenterIcons from "../components/HelpCenter/icons"
import { SubCategories } from "../components/HelpCenter/SubCategories"

const MainContentContainer = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1240px;
  padding: 0 32px;
  width: 100%;
`
const WelcomeTitle = styled.h1`
  color: ${ styleHelpers.colors.dark };
  font-size: 32px;
  line-height: 125%;
  margin: 0 0 16px;
`

const WelcomeByline = styled.p`
  color: ${ styleHelpers.colors.dark };
  font-size: ${ styleHelpers.fonts.sizes.medium };
  font-weight: ${ styleHelpers.fonts.weight.thin };
  line-height: 125%;
  margin: 0 0 24px;

  & a {
    color: ${ styleHelpers.colors.info };
    font-weight: ${ styleHelpers.fonts.weight.semiBold };
    text-decoration: none;
  }
`

export interface CategorySection {
  buttons: Array<{
    icon: React.ReactNode
    onClick: () => void
    subCategory?: HelpCenterCategories
    title: string
  }>
  promotionTitle?: string
  isPromotionActive?: boolean
  promotionClick?: () => void
  title: string
}

export enum HelpCenterCategories {
  ADS_PROMOTION = "Ads Promotion",
  EDIT_GOOGLE_BUSINESS_PROFILE = "Edit my Google",
  EDIT_SITE = "Edit my site",
}

export const HelpCenterPage: React.FC = () => {
  const session = useCurrentSession()
  const { merchantSettings } = useMerchantSettings()
  const [ subCatalog, setSubCatalog ] = useState<HelpCenterCategories | null>( null )
  const { startWorkflow } = useTaskWorkflow()

  const subSection = {
    [ HelpCenterCategories.ADS_PROMOTION ]: <AdsPromotionInfo/>,
    [ HelpCenterCategories.EDIT_SITE ]: [
      {
        title: "Add or edit pages",
        buttons: [
          {
            icon: <HelpCenterIcons.AddIcon/>,
            title: "Add Page",
            onClick: () => { startWorkflow( MainTask.IntercomWorkflowType.SITE_ADD_NEW_PAGE ) },
          },
          {
            icon: <HelpCenterIcons.SiteEditIcon/>,
            title: "Edit Page",
            onClick: () => { startWorkflow( MainTask.IntercomWorkflowType.SITE_EDIT_A_PAGE ) },
          },
          {
            icon: <HelpCenterIcons.RemovePageIcon/>,
            title: "Remove Page",
            onClick: () => { startWorkflow( MainTask.IntercomWorkflowType.SITE_REMOVE_A_PAGE ) },
          },
        ],
      },
      {
        title: "Add or edit sections",
        buttons: [
          {
            icon: <HelpCenterIcons.FAQIcon/>,
            title: "FAQs",
            onClick: () => { startWorkflow( MainTask.IntercomWorkflowType.SITE_MANAGE_FAQS ) },
          },
          {
            icon: <HelpCenterIcons.EmployeesIcon/>,
            title: "Employees",
            onClick: () => { startWorkflow( MainTask.IntercomWorkflowType.SITE_TEAM_MEMBERS ) },
          },
          {
            icon: <HelpCenterIcons.ImagesIcon/>,
            title: "Images",
            onClick: () => { startWorkflow( MainTask.IntercomWorkflowType.SITE_UPDATE_IMAGES ) },
          },
          {
            icon: <HelpCenterIcons.PromosIcon/>,
            title: "Promos",
            onClick: () => { startWorkflow( MainTask.IntercomWorkflowType.SITE_ADD_PROMOS ) },
          },
          {
            icon: <HelpCenterIcons.IframeIcon/>,
            title: "iFrame",
            onClick: () => { startWorkflow( MainTask.IntercomWorkflowType.SITE_ADD_NEW_IFRAME ) },
          },
          {
            icon: <HelpCenterIcons.JobIcon/>,
            title: "Job Posting",
            onClick: () => { startWorkflow( MainTask.IntercomWorkflowType.SITE_MANAGE_JOB_POSTINGS ) },
          },
          {
            icon: <HelpCenterIcons.FormsIcon/>,
            title: "Forms",
            onClick: () => { startWorkflow( MainTask.IntercomWorkflowType.SITE_UPDATE_FORMS ) },
          },
        ],
      },
    ],
    [ HelpCenterCategories.EDIT_GOOGLE_BUSINESS_PROFILE ]: [
      {
        title: "Edit Business Details",
        buttons: [
          {
            icon: <HelpCenterIcons.ServiceHoursIcon/>,
            title: "Service Hours",
            onClick: () => { startWorkflow( MainTask.IntercomWorkflowType.GBP_SERVICE_HOURS )},
          },
          {
            icon: <HelpCenterIcons.EditAddressIcon/>,
            title: "Edit Address",
            onClick: () => { startWorkflow( MainTask.IntercomWorkflowType.GBP_EDIT_ADDRESS ) },
          },
          {
            icon: <HelpCenterIcons.ServiceAreaIcon/>,
            title: "Service Area",
            onClick: () => { startWorkflow( MainTask.IntercomWorkflowType.GBP_SERVICE_AREA ) },
          },
          {
            icon: <HelpCenterIcons.SocialLinksIcon/>,
            title: "Edit Social Links",
            onClick: () => { startWorkflow( MainTask.IntercomWorkflowType.GBP_EDIT_SOCIAL_LINKS ) },
          },
          {
            icon: <HelpCenterIcons.EditPhoneNumberIcon/>,
            title: "Phone Number",
            onClick: () => { startWorkflow( MainTask.IntercomWorkflowType.GBP_PHONE_NUMBER ) },
          },
        ],
      },
    ],
  }

  const sections: CategorySection[] = useMemo( () => [
    {
      title: "Content Requests",
      buttons: [
        {
          icon: <HelpCenterIcons.GoogleIcon/>,
          onClick: () => { setSubCatalog( HelpCenterCategories.EDIT_GOOGLE_BUSINESS_PROFILE ) },
          subCategory: HelpCenterCategories.EDIT_GOOGLE_BUSINESS_PROFILE,
          title: "Edit my Google Business Profile",
        },
        {
          icon: <HelpCenterIcons.EditIcon/>,
          onClick: () => { setSubCatalog( HelpCenterCategories.EDIT_SITE ) },
          subCategory: HelpCenterCategories.EDIT_SITE,
          title: "Edit my site",
        },
      ],
    },
    {
      title: "Ads",
      promotionTitle: "Get More on Ads",
      isPromotionActive: ! merchantSettings?.ads.status,
      promotionClick: () => { setSubCatalog( HelpCenterCategories.ADS_PROMOTION ) },
      buttons: [
        {
          icon: <HelpCenterIcons.StartCampaignIcon/>,
          title: "Start New Campaign",
          onClick: () => { startWorkflow( MainTask.IntercomWorkflowType.ADS_START_NEW_CAMPAIGN ) },
        },
        {
          icon: <HelpCenterIcons.EditIcon/>,
          title: "Modify Campaign",
          onClick: () => { startWorkflow( MainTask.IntercomWorkflowType.ADS_MODIFY_CAMPAIGN ) },
        },
      ],
    },
    {
      title: "Other Requests",
      buttons: [
        {
          icon: <HelpCenterIcons.SupportIcon/>,
          title: "General Support",
          onClick: () => { startWorkflow( MainTask.IntercomWorkflowType.SEND_A_REQUEST ) },
        },
        {
          icon: <HelpCenterIcons.HelpCenterIcon/>,
          title: "Help Articles",
          onClick: () => { intercomHelpers.presentSpace( IntercomHelpers.Space.HELP_CENTER ) },
        },
      ],
    },
  ], [ merchantSettings?.ads.status, startWorkflow ] )

  return (
    <Page
      title="Help Center"
      customBoxComponent={ MainContentContainer }
    >
      <WelcomeTitle>
        Hi, { session?.name.given } { session?.name.family }👋 <br/>
        How can we help?
      </WelcomeTitle>
      <WelcomeByline>
        You can access your messages, send requests to change information about your business or access help articles. <br/> <br/>
        You can check the status of your tickets in your <Link href={ DashboardRoutePath.ACTIVITY }>Activity Feed</Link>.
      </WelcomeByline>
      <Flex display="flex" style={ { gap: "16px" } }>
        <Categories activeSubCategory={ subCatalog } sections={ sections }/>
        { subCatalog && ( <SubCategories title={ subCatalog } sections={ subSection[ subCatalog ] }/> ) }
      </Flex>
    </Page>
  )
}


import { Howl } from "howler"
import type { NotifyOption } from "notifyjs"
import Notify from "notifyjs"

export const notificationService = {
  isDesktopNotificationAllowed() {
    return ! Notify.needsPermission
  },
  playSound() {
    new Howl( {
      src: [ "/assets/shared/notification.mp3", "/assets/shared/notification.ogg", "/assets/shared/notification.aac" ],
      volume: 1,
    } ).play()
  },
  requestDesktopNotificationPermission() {
    return new Promise<void>( ( resolve, reject ) => {
      return Notify.requestPermission( resolve, reject )
    } )
  },
  showDesktopNotification( title: string, options: NotifyOption ) {
    if( ! options.notifyShow ) {
      options.notifyShow = () => {
        notificationService.playSound()
      }
    }
    const notification = new Notify( title, options )
    notification.show()
  },
}

import { useCallback } from "react"

import type { PaginatedQueryOptions } from "@onelocal/frontend/common"
import { usePaginatedResources } from "@onelocal/frontend/common"
import type { MerchantsService } from "../services/merchantsService"
import { merchantsSelectors } from "../store/merchantsSelectors"
import type { RootStateMerchants } from "../store/merchantsStore"
import { merchantsActions } from "../store/merchantsStore"
import type { Merchant } from "../types"

export const usePaginatedMerchants = () => {
  const getQueryAction = useCallback( ( queryFilter: MerchantsService.QueryFilter, queryOptions: PaginatedQueryOptions ) => {
    return merchantsActions.merchants.query( queryFilter, queryOptions )
  }, [] )

  const {
    isLoading,
    items,
    queryFilter,
    queryOptions,
    load,
    loadingError,
    loadPage,
    setQueryFilter,
    totalItemsCount,
  } = usePaginatedResources<Merchant, MerchantsService.QueryFilter, RootStateMerchants>( {
    getQueryAction,
    defaultFilter: {},
    perPage: 25,
    selector: merchantsSelectors.merchants.byIds,
  } )

  return {
    merchants: items,
    merchantsQueryFilter: queryFilter,
    merchantsQueryOptions: queryOptions,
    isLoading,
    load,
    loadingError,
    loadPage,
    setQueryFilter,
    totalMerchantsCount: totalItemsCount,
  }
}

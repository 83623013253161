import { createSelector } from "@reduxjs/toolkit"

import type { CommonState } from "./commonStore"

type CommonStateSelector<TRootState> = [
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ( state: TRootState, ...params: any[] ) => CommonState
]

export const commonSelector = <TRootState extends { common: CommonState }>( state: TRootState ) => state.common

export const createCommonSelector = <TRootState extends { common: CommonState }, TReturnValue>( combiner: ( commonState: CommonState ) => TReturnValue ) => {
  return createSelector<CommonStateSelector<TRootState>, TReturnValue>(
    commonSelector,
    combiner,
  )
}

const commonContextSelector = createCommonSelector(
  ( commonState ) => commonState.context,
)

export const commonSelectors = {
  children: createCommonSelector(
    ( commonState ) => commonState.children,
  ),
  context: {
    current: commonContextSelector,
  },
  selectedMerchantId: createCommonSelector(
    ( commonState ) => commonState.selectedMerchantId,
  ),
}

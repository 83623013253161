import { apiHelper } from "@onelocal/frontend/common"
import { ProductNames } from "@onelocal/shared/common"
import { AnalyticHelper, mixpanelHelpers } from "../helpers"
import type { ApiMerchant } from "../types/api"
import { parseMerchantFromApi } from "../types/api"

export const merchantService = {
  async getAgencyAnalyticsLoginUrl( merchantId: string ) {
    return apiHelper.request( {
      method: "GET",
      parsingData: ( loginUrl: string ) => loginUrl,
      url: `/merchants/${ merchantId }/merchant-analytics/agency-analytics/login`,
    } )
  },
  async getChildren( merchantId: string ) {
    return apiHelper.request( {
      method: "GET",
      parsingData: ( apiMerchants: ApiMerchant[] ) => apiMerchants.map( parseMerchantFromApi ),
      url: `/merchants/${ merchantId }/children`,
    } )
  },
  async openAgencyAnalyticsLoginUrl( merchantId: string, location: string ) {
    const loginUrl = await merchantService.getAgencyAnalyticsLoginUrl( merchantId )
    mixpanelHelpers.trackEvent( AnalyticHelper.EventName.CTA_CLICKED, { Name: "AgencyAnalytics", Location: location, Product: ProductNames.ANALYTICS } )
    window.open( loginUrl, "_blank" )
  },
}

import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from "@mui/icons-material/Delete"
import { isEmpty, upperFirst } from "lodash"
import React, { useCallback, useEffect, useImperativeHandle, useState } from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"

import { useAsyncDispatch } from "@onelocal/frontend/common"
import { AnalyticHelper, mixpanelHelpers, useDashboardContext } from "@onelocal/frontend-dashboard/common"
import { connectActions, ConnectChannel, connectSelectors } from "@onelocal/frontend-dashboard/connect"
import { DashboardRoutePath } from "@onelocal/frontend-dashboard-web/common"
import type { SelectOption } from "@onelocal/frontend-web/common"
import { Grid, GridItem, Link, Select, StyledText, TimeField, Tooltip, useAlert } from "@onelocal/frontend-web/common"
import { ProductNames } from "@onelocal/shared/common"

const BusinessHourContainer = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 20px 0 15px 0;
  padding: 0;
`

const BusinessHourItem = styled.li`
  list-style: none;
`

const BusinessHourWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  list-style: none;
  padding-top: 15px;
  width: 100%;
`

const DeleteButtonWrapper = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
`

const DeleteItemIcon = styled( DeleteIcon )`
  color: rgba(0, 0, 0, 0.54);

  &:hover {
    cursor: pointer;
  }
`

const ValidationErrorWrapper = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`

export interface BusinessHoursProps {
  onSubmit?(): void
}

export interface BusinessHoursRef {
  submit(): Promise<boolean>
}

interface OnSelectParams {
  index: number
  key: string
  value: string
}

export const enum ValidationErrorType {
  INCOMPLETE_BUSINESS_HOUR = "incomplete_business_hour",
  INVALID_BUSINESS_HOUR = "invalid_business_hour",
}

interface ValidationError {
  [ key: string ]: null | ValidationErrorType
}

const DEFAULT_PERIOD: ConnectChannel.BusinessHourPeriod = {
  day: ConnectChannel.BusinessHourPeriodDay.WEEKDAYS,
  from: "09:00",
  to: "17:00",
}

const VALIDATION_ERROR_MAP = {
  [ ValidationErrorType.INCOMPLETE_BUSINESS_HOUR ]: "Please enter a valid time slot",
  [ ValidationErrorType.INVALID_BUSINESS_HOUR ]: "To cannot be set before From",
}

const dayOptions: Array<SelectOption<string>> = Object.values( ConnectChannel.BusinessHourPeriodDay )
  .map( ( value ) => {
    if( value === "everyday" ) {
      return { label: "Every Day", value }
    }
    return { label: upperFirst( value ), value }
  } )

// eslint-disable-next-line react/display-name
export const BusinessHours = React.forwardRef<BusinessHoursRef, BusinessHoursProps>( ( props, ref ) => {
  const dispatch = useAsyncDispatch()
  const { dashboardContext } = useDashboardContext()
  const currentChannel = useSelector( connectSelectors.channels.current )
  const [ businessHours, setBusinessHours ] = useState<ConnectChannel.BusinessHourPeriod[]>( [] )
  const [ validationErrors, setValidationErrors ] = useState<ValidationError>( {} )
  const { showError, showSuccess } = useAlert()

  useEffect( () => {
    const loadCurrentChannel = async () => {
      if( ! dashboardContext?.merchantId ) {
        return
      }
      await dispatch( connectActions.channel.get( dashboardContext.merchantId ) )
    }
    loadCurrentChannel()
  }, [ dashboardContext?.merchantId, dispatch ] )

  useEffect( () => {
    if( currentChannel ) {
      // Set the default value here otherwise it flashes the default period
      setBusinessHours( currentChannel.businessHours || [ DEFAULT_PERIOD ] )
    }
  }, [ currentChannel ] )

  const submit = useCallback( async () => {
    switch( window.location.pathname ) {
      case DashboardRoutePath.CONNECT_SETTINGS_INCOMING_CALL: {
        mixpanelHelpers.trackEvent(
          AnalyticHelper.EventName.CTA_CLICKED,
          {
            "Name": "Save Business Hours",
            "Location": "Settings/LocalMessages/Incoming Calls",
            "Product": ProductNames.LOCAL_MESSAGES,
          },
        )
        break
      }
      case DashboardRoutePath.CONNECT_SETTINGS_AUTORESPONDER: {
        mixpanelHelpers.trackEvent(
          AnalyticHelper.EventName.CTA_CLICKED,
          {
            "Name": "Save Business Hours",
            "Location": "Settings/LocalMessages/Autoresponder",
            "Product": ProductNames.LOCAL_MESSAGES,
          },
        )
        break
      }
      default: {
        mixpanelHelpers.trackEvent(
          AnalyticHelper.EventName.CTA_CLICKED,
          {
            "Name": "Save",
            "Location": "Settings/LocalMessages/Business Hours",
            "Product": ProductNames.LOCAL_MESSAGES,
          },
        )
      }
    }

    if( isEmpty( businessHours ) ) {
      showError( "Please add at least one open time to your business hours." )
      return false
    }

    for( const error of Object.values( validationErrors ) ) {
      if( error !== null ) {
        showError( "Please update the highlighted business hour." )
        return false
      }
    }

    const invalidBusinessHourErrors: ValidationError = businessHours.reduce( ( result, { from, to }, index ) => {
      if( ! from || ! to ) {
        return { ...result, [ index ]: ValidationErrorType.INCOMPLETE_BUSINESS_HOUR }
      }
      return result
    }, {} )

    if( ! isEmpty( invalidBusinessHourErrors ) ) {
      setValidationErrors( ( previousValidationErrors ) => ( {
        ...previousValidationErrors,
        ...invalidBusinessHourErrors,
      } ) )

      showError( "Please update the highlighted business hour." )
      return false
    }

    return dispatch( connectActions.channel.update( dashboardContext.merchantId, { businessHours } ) )
      .then( () => {
        showSuccess( "Changes saved" )
        return true
      } )
      .catch( ( err ) => {
        showError( err )
        return false
      } )
  }, [ businessHours, dashboardContext.merchantId, dispatch, showError, showSuccess, validationErrors ] )

  const addPeriod = useCallback( () => {
    mixpanelHelpers.trackEvent(
      AnalyticHelper.EventName.CTA_CLICKED,
      {
        "Name": "Add Business Hours",
        "Location": "Settings/LocalMessages/Business Hours",
        "Product": ProductNames.LOCAL_MESSAGES,
      },
    )
    setBusinessHours( ( currentBusinessHours ) => [ ...currentBusinessHours, DEFAULT_PERIOD ] )
  }, [] )

  useImperativeHandle( ref, () => ( {
    submit,
  } ) )

  const onOptionSelect = useCallback( ( params: OnSelectParams ) => {
    setBusinessHours( ( currentBusinessHours ) => {
      const currentPeriod = {
        ...currentBusinessHours[ params.index ],
        [ params.key ]: params.value,
      }

      const [ fromHour, fromMinute ] = currentPeriod.from.split( ":" )
      const [ toHour, toMinute ] = currentPeriod.to.split( ":" )
      const currentFromHour = parseInt( fromHour, 10 )
      const currentToHour = parseInt( toHour, 10 )
      if( currentFromHour > currentToHour
          || ( currentFromHour === currentToHour && parseInt( fromMinute, 10 ) >= parseInt( toMinute, 10 ) )
      ) {
        setValidationErrors( {
          ...validationErrors,
          [ params.index ]: ValidationErrorType.INVALID_BUSINESS_HOUR,
        } )
      } else if( validationErrors[ params.index ] ) {
        setValidationErrors( { ...validationErrors, [ params.index ]: null } )
      }

      return currentBusinessHours.map( ( period, index ) => {
        if( index === params.index ) {
          return currentPeriod
        }
        return period
      } )
    } )
  }, [ validationErrors ] )

  const removeBusinessHour = useCallback( ( index: number ) => {
    mixpanelHelpers.trackEvent(
      AnalyticHelper.EventName.CTA_CLICKED,
      {
        "Name": "Delete Business Hours",
        "Location": "Settings/LocalMessages/Business Hours",
        "Product": ProductNames.LOCAL_MESSAGES,
      },
    )

    setBusinessHours( ( currentBusinessHours ) => {
      return currentBusinessHours.filter( ( businessHour, innerIndex ) => innerIndex !== index )
    } )

    if( isEmpty( validationErrors ) ) {
      return
    }

    setValidationErrors( ( previousValidationError ) => {
      return Object
        .entries( previousValidationError )
        .reduce( ( result, [ key, value ] ) => {
          const numberKey = parseInt( key, 10 )
          if( numberKey < index ) {
            return { ...result, [ key ]: value }
          }

          if( numberKey === index ) {
            return { ...result, [ key ]: null }
          }

          return { ...result, [ numberKey - 1 ]: value }
        }, {} )
    } )
  }, [ validationErrors ] )

  return (
    <div>
      <div
        style={ {
          alignItems: "center",
          display: "flex",
        } }
      >
        <StyledText>{ `Your timezone is ${ dashboardContext?.timezone }` }</StyledText>
        <Tooltip text="To update your timezone, please email support@onelocal.com"/>
      </div>
      <BusinessHourContainer>
        {
          businessHours?.map( ( period, index ) => {
            return (
              <BusinessHourItem key={ `${ index }` }>
                <BusinessHourWrapper>
                  <Grid alignItems="center" spacing={ 4 }>
                    <GridItem xs>
                      <Select
                        onChange={ ( value: string ) => {
                          onOptionSelect( { index, key: "day", value } )
                        } }
                        fullWidth
                        helpText="Select a day"
                        label="Day"
                        options={ dayOptions }
                        required={ true }
                        style={ { minWidth: 130 } }
                        value={ period.day }
                      />
                    </GridItem>

                    <GridItem xs>
                      <TimeField
                        fullWidth
                        value={ period.from }
                        onChange={ ( value ) => {
                          onOptionSelect( { index, key: "from", value } )
                        } }
                        label="From"
                        helperText="Select hours"
                        required={ true }
                      />
                    </GridItem>

                    <GridItem xs>
                      <TimeField
                        fullWidth
                        helperText="Select hours"
                        label="To"
                        onChange={ ( value ) => {
                          onOptionSelect( { index, key: "to", value } )
                        } }
                        required={ true }
                        value={ period.to }
                      />
                    </GridItem>

                    <GridItem xs="auto">
                      <DeleteButtonWrapper>
                        <DeleteItemIcon
                          onClick={ () => {
                            removeBusinessHour( index )
                          } }
                        />
                      </DeleteButtonWrapper>
                    </GridItem>
                  </Grid>
                </BusinessHourWrapper>
                {
                  validationErrors[ index ] != null && (
                    <ValidationErrorWrapper>{ VALIDATION_ERROR_MAP[ validationErrors[ index ]! ] }</ValidationErrorWrapper>
                  )
                }
              </BusinessHourItem>
            )
          } )
        }
      </BusinessHourContainer>
      <Link onClick={ addPeriod } style={ { alignItems: "center", display: "flex" } }>
        <AddIcon style={ { marginRight: 8 } }/> Add Time Slot
      </Link>
    </div>
  )
} )

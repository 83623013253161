import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"
import { authSlice } from "libs/frontend-dashboard/auth/src/store/authStore"
import type { RootThunk } from "libs/frontend-dashboard-web/main/src/store"

import type { ModuleStoreConfig } from "@onelocal/frontend/common"
import type { ApiRequestProgressEvent } from "@onelocal/shared/common"
import type { ContentBlogService } from "../services/blogService"
import { contentBlogService } from "../services/blogService"
import type { ContentSocialPostService } from "../services/socialPostService"
import { contentSocialPostService } from "../services/socialPostService"
import type { ContentBlogPost, ContentPublishDateValue, ContentScheduledItem, ContentSocialPostSuggestion } from "../types"

export interface ActiveContent {
  id: string
  type: ContentScheduledItem.Type
}

export interface ContentState {
  activeContent: ActiveContent | null
  blogPost: {
    byId: {
      [blogPostId: string]: ContentBlogPost
    }
  }
  socialPostSuggestion: {
    byId: {
      [socialPostSuggestionId: string]: ContentSocialPostSuggestion
    }
  }
}

const contentStoreKey = "content"

export const contentInitialState: ContentState = {
  activeContent: null,
  blogPost: {
    byId: {},
  },
  socialPostSuggestion: {
    byId: {},
  },
}

export const contentSlice = createSlice( {
  name: "content",
  initialState: contentInitialState,
  reducers: {
    deleteBlogPost: ( state, action: PayloadAction<{ blogPostId: string }> ) => {
      delete state.blogPost.byId[ action.payload.blogPostId ]
      return state
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deleteSocialPostSuggestion: ( state, action: PayloadAction<{ socialPostSuggestionId: string }> ) => {
      // Keep the post suggestion in the state otherwise it's breaking some of the screen that use it
      return state
    },
    setActiveContent: ( state, action: PayloadAction<ActiveContent | null> ) => {
      state.activeContent = action.payload
      return state
    },
    updateBlogPosts: ( state, action: PayloadAction<ContentBlogPost[]> ) => {
      const blogPosts = action.payload

      for( const blogPost of blogPosts ) {
        state.blogPost.byId[ blogPost.id ] = blogPost
      }

      return state
    },
    updateSocialPostSuggestion: ( state, action: PayloadAction<ContentSocialPostSuggestion[]> ) => {
      const socialPostSuggestions = action.payload
      for( const socialPostSuggestion of socialPostSuggestions ) {
        state.socialPostSuggestion.byId[ socialPostSuggestion.id ] = socialPostSuggestion
      }

      return state
    },
  },
  extraReducers: builder => {
    builder.addCase( authSlice.actions.updateSession, ( state, action ) => {
      const session = action.payload

      if( session == null ) {
        return contentInitialState
      }

      return state
    } )
  },
} )

export const contentActions = {
  common: {
    setActiveContentId: ( content: ActiveContent ): RootThunk<void> => {
      return async ( dispatch ) => {
        dispatch( contentSlice.actions.setActiveContent( content ) )
      }
    },
  },
  blogPost: {
    delete( merchantId: string, blogPostId: string ): RootThunk<void> {
      return async ( dispatch ) => {
        await contentBlogService.delete( merchantId, blogPostId )
        dispatch( contentSlice.actions.deleteBlogPost( { blogPostId } ) )
      }
    },
    getBlogPostById( merchantId: string, blogPostId: string ): RootThunk<ContentBlogPost> {
      return async ( dispatch ) => {
        const blogPost = await contentBlogService.getBlogPostById( merchantId, blogPostId )
        dispatch( contentSlice.actions.updateBlogPosts( [ blogPost ] ) )
        return blogPost
      }
    },
    getOptimalPublishDate( merchantId: string, blogPostId: string ): RootThunk<ContentBlogPost> {
      return async ( dispatch ) => {
        const blogPost = await contentBlogService.getBlogPostById( merchantId, blogPostId )
        dispatch( contentSlice.actions.updateBlogPosts( [ blogPost ] ) )
        return blogPost
      }
    },
    publishBlogPost( merchantId: string, blogPostId: string, publishDate?: string ): RootThunk<ContentBlogPost> {
      return async ( dispatch ) => {
        const blogPost = await contentBlogService.publishBlogPost( merchantId, blogPostId, publishDate )
        dispatch( contentSlice.actions.updateBlogPosts( [ blogPost ] ) )
        return blogPost
      }
    },
    restoreOriginalContent( merchantId: string, blogPostId: string ): RootThunk<ContentBlogPost> {
      return async ( dispatch ) => {
        const blogPost = await contentBlogService.restoreOriginalContent( merchantId, blogPostId )
        dispatch( contentSlice.actions.updateBlogPosts( [ blogPost ] ) )
        return blogPost
      }
    },
    updateBlogPost( merchantId: string, blogPostId: string, model: ContentBlogService.UpdateModel ): RootThunk<ContentBlogPost> {
      return async ( dispatch ) => {
        const blogPost = await contentBlogService.updateBlogPost( merchantId, blogPostId, model )
        dispatch( contentSlice.actions.updateBlogPosts( [ blogPost ] ) )
        return blogPost
      }
    },
    uploadImage( merchantId: string, blogPostId: string, path: string ): RootThunk<ContentBlogPost> {
      return async ( dispatch ) => {
        const blogPost = await contentBlogService.uploadImage( merchantId, blogPostId, path )
        dispatch( contentSlice.actions.updateBlogPosts( [ blogPost ] ) )
        return blogPost
      }
    },

  },
  socialPostSuggestion: {
    delete( merchantId: string, socialPostSuggestionId: string ): RootThunk<void> {
      return async ( dispatch ) => {
        await contentSocialPostService.deleteSuggestion( merchantId, socialPostSuggestionId )
        dispatch( contentSlice.actions.deleteSocialPostSuggestion( { socialPostSuggestionId } ) )
      }
    },
    deleteSuggestionImage( merchantId: string, socialPostSuggestionId: string, imageId: string ): RootThunk<ContentSocialPostSuggestion> {
      return async ( dispatch ) => {
        const socialPostSuggestion = await contentSocialPostService.deleteSuggestionImage( merchantId, socialPostSuggestionId, imageId )
        dispatch( contentSlice.actions.updateSocialPostSuggestion( [ socialPostSuggestion ] ) )
        return socialPostSuggestion
      }
    },
    generateSuggestion( merchantId: string, socialPostSuggestionId: string ): RootThunk<ContentSocialPostSuggestion> {
      return async ( dispatch ) => {
        const socialPostSuggestion = await contentSocialPostService.generateSuggestion( merchantId, socialPostSuggestionId )
        dispatch( contentSlice.actions.updateSocialPostSuggestion( [ socialPostSuggestion ] ) )
        return socialPostSuggestion
      }
    },
    getById( merchantId: string, socialPostSuggestionId: string ): RootThunk<ContentSocialPostSuggestion> {
      return async ( dispatch ) => {
        const socialPostSuggestion = await contentSocialPostService.getSuggestionById( merchantId, socialPostSuggestionId )
        dispatch( contentSlice.actions.updateSocialPostSuggestion( [ socialPostSuggestion ] ) )
        return socialPostSuggestion
      }
    },
    publishSuggestion( merchantId: string, socialPostSuggestionId: string, date: ContentPublishDateValue ): RootThunk<ContentSocialPostSuggestion> {
      return async ( dispatch ) => {
        const socialPostSuggestion = await contentSocialPostService.publishSuggestion( merchantId, socialPostSuggestionId, date )
        dispatch( contentSlice.actions.updateSocialPostSuggestion( [ socialPostSuggestion ] ) )
        return socialPostSuggestion
      }
    },
    updateSuggestion( merchantId: string, socialPostSuggestionId: string, model: ContentSocialPostService.UpdateSuggestion ): RootThunk<ContentSocialPostSuggestion> {
      return async ( dispatch ) => {
        const socialPostSuggestion = await contentSocialPostService.updateSuggestion( merchantId, socialPostSuggestionId, model )
        dispatch( contentSlice.actions.updateSocialPostSuggestion( [ socialPostSuggestion ] ) )
        return socialPostSuggestion
      }
    },
    updateSuggestionModel( merchantId: string, socialPostSuggestionId: string, model: ContentSocialPostService.UpdateSuggestionModel ): RootThunk<ContentSocialPostSuggestion> {
      return async ( dispatch ) => {
        const socialPostSuggestion = await contentSocialPostService.updateSuggestionModel( merchantId, socialPostSuggestionId, model )
        dispatch( contentSlice.actions.updateSocialPostSuggestion( [ socialPostSuggestion ] ) )
        return socialPostSuggestion
      }
    },
    uploadSuggestionImage( merchantId: string, socialPostSuggestionId: string, path: string, imageType: ContentSocialPostService.SuggestionUploadImageType | null, onUploadProgress?: ( progressEvent: ApiRequestProgressEvent ) => void ): RootThunk<ContentSocialPostSuggestion> {
      return async ( dispatch ) => {
        const socialPostSuggestion = await contentSocialPostService.uploadSuggestionImage( merchantId, socialPostSuggestionId, path, imageType, onUploadProgress )
        dispatch( contentSlice.actions.updateSocialPostSuggestion( [ socialPostSuggestion ] ) )
        return socialPostSuggestion
      }
    },
  },
}

export const contentStoreConfig: ModuleStoreConfig<ContentState> = {
  key: contentStoreKey,
  initialState: contentInitialState,
  reducers: contentSlice.reducer,
}

import React from "react"

import { useCurrentSession } from "@onelocal/frontend-admin-web/common"

export const ComponentWrapper = function<TProps>( Component: React.FC<TProps> ) {
  const WithComponent = ( props: TProps ) => {
    const currentSession = useCurrentSession()
    if( ! currentSession ) {
      return null
    }

    return <Component { ...props as TProps & JSX.IntrinsicAttributes } />
  }

  return WithComponent
}

export const ComponentWrapperRef = function<TProps>( Component: React.ForwardRefExoticComponent<TProps> ) {
  const WithComponent = React.forwardRef( ( props: TProps, ref ) => {
    const currentSession = useCurrentSession()
    if( ! currentSession ) {
      return null
    }

    return <Component ref={ ref } { ...props } />
  } )

  WithComponent.displayName = "WithComponent"
  return WithComponent
}

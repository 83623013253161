import CheckIcon from "@mui/icons-material/Check"
import React, { useCallback, useMemo, useState } from "react"
import type { SubmitHandler } from "react-hook-form"
import { Controller, FormProvider } from "react-hook-form"
import styled from "styled-components"

import { useForm } from "@onelocal/frontend/common"
import type { DialogAction, DialogAlert } from "@onelocal/frontend-web/common"
import { Dialog, DialogAlertType, Grid, GridItem, TextField, useModalWithParams } from "@onelocal/frontend-web/common"
import type { OnboardingForm } from "../../types"

const StyledFieldContainer = styled.div`
  align-items: center;
  display: flex;
  margin: 20px 0;
`

enum FormFieldKeys {
  LABEL = "label",
}

type FormValues = {
  [ FormFieldKeys.LABEL ]: string
}

export interface OnboardingFormStepServiceCreateServiceModalProps {
  form: OnboardingForm
  item?: OnboardingForm.SiteItem
  isOpen: boolean
  onClose( reOpen?: boolean ): void
  onExited: () => void
  onSaved: ( label: string ) => Promise<void>
}

export const OnboardingFormStepServiceCreateServiceModal: React.FC<OnboardingFormStepServiceCreateServiceModalProps> = ( {
  form,
  isOpen,
  item,
  onClose,
  onExited,
  onSaved,
} ) => {
  const [ alert, setAlert ] = useState<DialogAlert | undefined>( undefined )
  const [ isProcessing, setIsProcessing ] = useState( false )
  const formMethods = useForm<FormValues>( {
    defaultValues: {
      [ FormFieldKeys.LABEL ]: "",
    },
  } )
  const { control, formState, handleSubmit } = formMethods

  const onSave: SubmitHandler<FormValues> = useCallback( async ( data ) => {
    try {
      if( isProcessing ) {
        return
      }
      setIsProcessing( true )

      await onSaved( data[ FormFieldKeys.LABEL ] )
      
      onClose()
    } catch( err ) {
      setAlert( {
        message: err,
        type: DialogAlertType.ERROR,
      } )
    } finally {
      setIsProcessing( false )
    }
  }, [ isProcessing, onClose, onSaved ] )

  const modalActions: DialogAction[] = useMemo( () => {
    const actions: DialogAction[] = [
      { title: "Cancel", type: "dismiss" },
      {
        icon: ( <CheckIcon style={ { height: 18, width: 18 } }/> ),
        onClick: handleSubmit( onSave, () => {
          setAlert( {
            message: "Please make sure all required fields are filled.",
            type: DialogAlertType.ERROR,
          } )
        } ),
        title: item ? "Save" : "Add",
        type: "primary",
      },
    ]

    return actions
  }, [ handleSubmit, item, onSave ] )

  return (
    <Dialog
      actions={ modalActions }
      alert={ alert }
      contentContainerStyle={ {
        paddingLeft: "24px",
        paddingRight: "24px",
        width: "350px",
      } }
      isOpen={ isOpen }
      noMaxWidth={ true }
      onClose={ onClose }
      onExited={ onExited }
      showDividers={ false }
      title={ "Create Service" }
    >
      <FormProvider { ...formMethods }>
        <StyledFieldContainer>
          <Grid spacing={ 4 }>
            <GridItem xs={ 12 }>
              <Controller
                control={ control }
                name={ FormFieldKeys.LABEL }
                render={ ( { field: { onChange, value } } ) => (
                  <TextField
                    errorMessage={ formState.errors?.[ FormFieldKeys.LABEL ]?.message }
                    fullWidth
                    label={ "Label" }
                    onChange={ onChange }
                    required={ true }
                    value={ value || "" }
                  />
                ) }
                rules={ {
                  validate: ( value ) => {
                    value = value?.trim()

                    if( ! value ) {
                      return "Label is required"
                    }
                    return undefined
                  },
                } }
              />
            </GridItem>
          </Grid>
        </StyledFieldContainer>
      </FormProvider>
    </Dialog>
  )
}

export interface useOnboardingFormStepServiceCreateServiceModalParams {
  form: OnboardingForm
  onSaved( label: string ): Promise<void>
}

export const useOnboardingFormStepServiceCreateServiceModal = () => {
  const { showModal, hideModal } = useModalWithParams<useOnboardingFormStepServiceCreateServiceModalParams>( ( { open, onExited, params } ) => {
    if( ! params ) {
      return null
    }

    return (
      <OnboardingFormStepServiceCreateServiceModal
        form={ params.form }
        isOpen={ open }
        onClose={ hideModal }
        onExited={ onExited }
        onSaved={ params.onSaved }
      />
    )
  }, [] )

  return {
    showOnboardingFormStepServiceCreateServiceModal: showModal,
    hideOnboardingFormStepServiceCreateServiceModal: hideModal,
  }
}

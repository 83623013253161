import type { PaginatedQueryOptions } from "@onelocal/frontend/common"
import { apiHelper } from "@onelocal/frontend/common"
import { parseMerchantFromApi } from "../types/api"

export namespace MerchantsService {
  export interface QueryFilter {
    q?: string | null
    type?: string | null
  }
}

export const merchantsService = {
  async getById( merchantId: string ) {
    return apiHelper.request( {
      method: "GET",
      parsingData: parseMerchantFromApi,
      url: `/merchants/${ merchantId }`,
    } )
  },
  async query( filter: MerchantsService.QueryFilter, options: PaginatedQueryOptions ) {
    return apiHelper.getPaginatedItems( {
      method: "GET",
      params: {
        filter: filter.type,
        q: filter.q,
        page: options.page,
        per_page: options.perPage,
      },
      parsingData: parseMerchantFromApi,
      url: "/merchants",
    } )
  },
  async updateCampaignId( merchantId: string, campaignId: string ) {
    return apiHelper.request( {
      method: "PUT",
      data: {
        campaign_id: campaignId,
      },
      url: `/merchants/${ merchantId }/merchant-analytics/agency-analytics`,
    } )
  },
}

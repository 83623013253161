import type { AuthSession } from "@onelocal/frontend-dashboard/auth"
import type { DashboardWindow } from "@onelocal/frontend-dashboard-web/main"
import { formattingHelpers } from "@onelocal/shared/common"

export namespace IntercomHelpers {
  export interface InitOptions {
    intercomAppId: string
    session: AuthSession
  }

  export enum Space {
    HELP_CENTER = "help",
    HOME = "home",
    MESSAGES = "messages",
    TICKETS = "tickets"
  }
}

export const intercomHelpers = {
  displayConversation( conversationId: string ) {
    if( ( window as DashboardWindow & typeof globalThis ).Intercom == null ) {
      console.error( "Intercom is not loaded" )
      return
    }

    ( window as DashboardWindow & typeof globalThis ).Intercom( "show" )
    ;( window as DashboardWindow & typeof globalThis ).Intercom( "showConversation", conversationId )
  },
  presentSpace( space: IntercomHelpers.Space ) {
    ( window as DashboardWindow & typeof globalThis ).Intercom( "showSpace", space )
  },
  hide() {
    if( ( window as DashboardWindow & typeof globalThis ).Intercom == null ) {
      console.error( "Intercom is not loaded" )
      return
    }

    ( window as DashboardWindow & typeof globalThis ).Intercom( "hide" )
  },
  show() {
    if( ( window as DashboardWindow & typeof globalThis ).Intercom == null ) {
      console.error( "Intercom is not loaded" )
      return
    }

    ( window as DashboardWindow & typeof globalThis ).Intercom( "show" )
  },
  start( { intercomAppId, session }: IntercomHelpers.InitOptions ) {
    if( ( window as DashboardWindow & typeof globalThis ).Intercom == null ) {
      console.error( "Intercom is not loaded" )
      return
    }

    ( window as DashboardWindow & typeof globalThis ).Intercom( "boot", {
      app_id: intercomAppId,
      email: session.email,
      name: formattingHelpers.getDisplayName( session ) || "",
      user_id: session.userId,
    } )
  },
  stop() {
    if( ( window as DashboardWindow & typeof globalThis ).Intercom == null ) {
      console.error( "Intercom is not loaded" )
      return
    }

    ( window as DashboardWindow & typeof globalThis ).Intercom( "shutdown" )
  },
  subscribe( event: string, callback: () => void ) {
    if( ( window as DashboardWindow & typeof globalThis ).Intercom == null ) {
      console.error( "Intercom is not loaded" )
      return
    }

    ( window as DashboardWindow & typeof globalThis ).Intercom( event, callback )
  },
}

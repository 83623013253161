import React from "react"
import styled from "styled-components"

import { BlogPost } from "@onelocal/frontend-dashboard/site"
import { styleHelpers } from "@onelocal/frontend-web/common"

export interface SiteBlogPostStatusTagProps {
  status: BlogPost.Status
}

const COLORS_BY_STATUS: Record<BlogPost.Status, { primary: string, secondary: string }> = {
  [ BlogPost.Status.APPROVED ]: { primary: "#3B873E", secondary: "#EFF7EE" },
  [ BlogPost.Status.GENERATING ]: { primary: "#C77700", secondary: "#FDF4E7" },
  [ BlogPost.Status.PUBLISHED ]: { primary: "#2B78CA", secondary: "#EAF4FC" },
  [ BlogPost.Status.WAITING_APPROVAL ]: { primary: "#C77700", secondary: "#FDF4E7" },
}

const getStatusTagStyle = ( status: BlogPost.Status ) => ( `
  color: ${ COLORS_BY_STATUS[ status ].primary };
  border: 1px solid ${ COLORS_BY_STATUS[ status ].primary };
  background-color: ${ COLORS_BY_STATUS[ status ].secondary };
` )

const StyledSiteBlogPostStatusTag = styled.span< { $status: BlogPost.Status } >`
  border-radius: 5px;
  font-size: ${ styleHelpers.fonts.sizes.small };
  padding: 2px 5px;
  ${ props => getStatusTagStyle( props.$status ) }
`

export const SiteBlogPostStatusTag: React.FC<SiteBlogPostStatusTagProps> = ( { status } ) => (
  <StyledSiteBlogPostStatusTag $status={ status }>{ BlogPost.STATUS_LABEL[ status ] }</StyledSiteBlogPostStatusTag>
)

import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

import type { DefaultThunkAction, ModuleStoreConfig } from "@onelocal/frontend/common"
import type { SiteTemplateService } from ".."
import { dudaTemplateService, siteTemplateService } from ".."
import type { SiteService } from "../services/siteService"
import { siteService } from "../services/siteService"
import type { DudaTemplate, SiteTemplate } from "../types"
import { Site } from "../types"
import { siteSaga } from "./siteSagas"

export interface SiteState {
  dudaTemplates: {
    all: DudaTemplate[]
  }
  sites: {
    byId: { [id: string]: Site }
    // byMerchantId: { [id: string]: Site }
  }
  siteTemplates: {
    byId: { [id: string]: SiteTemplate }
  }
}

const siteStoreKey = "site"

export interface RootStateSite {
  [ siteStoreKey ]: SiteState
}

type SiteThunkAction<TReturnType> = DefaultThunkAction<RootStateSite, TReturnType>

export const siteInitialState: SiteState = {
  dudaTemplates: {
    all: [],
  },
  sites: {
    // byMerchantId: {},
    byId: {},
  },
  siteTemplates: {
    byId: {},
  },
}

const siteSlice = createSlice( {
  name: siteStoreKey,
  initialState: siteInitialState,
  reducers: {
    deleteSiteTemplate: ( state, action: PayloadAction<string> ) => {
      delete state.siteTemplates.byId[ action.payload ]
      return state
    },
    removeSite: ( state, action: PayloadAction<string> ) => {
      delete state.sites.byId[ action.payload ]
      return state
    },
    setDudaTemplates: ( state, action: PayloadAction<DudaTemplate[]> ) => {
      state.dudaTemplates.all = action.payload
      return state
    },
    updateSites: ( state, action: PayloadAction<Site[]> ) => {
      for( const site of action.payload ) {
        state.sites.byId[ site.id ] = site
      }

      return state
    },
    updateSiteTemplates: ( state, action: PayloadAction<SiteTemplate[]> ) => {
      for( const siteTemplate of action.payload ) {
        state.siteTemplates.byId[ siteTemplate.id ] = siteTemplate
      }

      return state
    },
  },
} )

export const siteActions = {
  site: {
    create( merchantId: string, model: SiteService.CreateSiteModel ): SiteThunkAction<Site> {
      return async ( dispatch ) => {
        const site = await siteService.createSite( merchantId, model )
        dispatch( siteSlice.actions.updateSites( [ site ] ) )
        return site
      }
    },
    indexSite( merchantId: string ): SiteThunkAction<void> {
      return async ( dispatch ) => {
        await siteService.indexSite( merchantId )
        const sites = await siteService.querySites( merchantId, { type: Site.Type.WEBSITE } )
        dispatch( siteSlice.actions.updateSites( sites ) )
      }
    },
    link( merchantId: string, model: SiteService.LinkSiteModel ): SiteThunkAction<Site> {
      return async ( dispatch ) => {
        const site = await siteService.linkSite( merchantId, model )
        dispatch( siteSlice.actions.updateSites( [ site ] ) )
        return site
      }
    },
    query( merchantId: string, filter: SiteService.QueryFilter ): SiteThunkAction<Site[]> {
      return async ( dispatch ) => {
        const sites = await siteService.querySites( merchantId, filter )
        dispatch( siteSlice.actions.updateSites( sites ) )
        return sites
      }
    },
    unlink( merchantId: string, siteId: string ): SiteThunkAction<void> {
      return async ( dispatch ) => {
        await siteService.unlinkSite( merchantId, siteId )
        dispatch( siteSlice.actions.removeSite( siteId ) )
      }
    },
    updatePrimarySite( merchantId: string, siteId: string ): SiteThunkAction<void> {
      return async ( dispatch ) => {
        await siteService.updatePrimarySite( merchantId, siteId )
        const sites = await siteService.querySites( merchantId, { type: Site.Type.WEBSITE } )
        dispatch( siteSlice.actions.updateSites( sites ) )
      }
    },
  },
  siteTemplates: {
    delete ( siteTemplateId: string ): SiteThunkAction<void> {
      return async ( dispatch ) => {
        await siteTemplateService.delete( siteTemplateId )
        dispatch ( siteSlice.actions.deleteSiteTemplate( siteTemplateId ) )
      }
    },

    create( siteTemplate: SiteTemplateService.CreateUpdateModel ): SiteThunkAction<SiteTemplate> {
      return async ( dispatch ) => {
        const createdSiteTemplate = await siteTemplateService.create( siteTemplate )
        dispatch ( siteSlice.actions.updateSiteTemplates( [ createdSiteTemplate ] ) )
        return createdSiteTemplate
      }
    },

    query( filter?: SiteTemplateService.QueryFilter ): SiteThunkAction<SiteTemplate[]> {
      return async ( dispatch ) => {
        const siteTemplates = await siteTemplateService.query( filter )
        dispatch( siteSlice.actions.updateSiteTemplates( siteTemplates ) )
        return siteTemplates
      }
    },

    update( siteTemplateId: string, siteTemplate: SiteTemplateService.CreateUpdateModel ): SiteThunkAction<SiteTemplate> {
      return async ( dispatch ) => {
        const updateSiteTemplate = await siteTemplateService.update( siteTemplateId, siteTemplate )
        dispatch ( siteSlice.actions.updateSiteTemplates( [ updateSiteTemplate ] ) )
        return updateSiteTemplate
      }
    },
  },
  dudaTemplates: {
    query(): SiteThunkAction<DudaTemplate[]> {
      return async ( dispatch ) => {
        const dudaTemplates = await dudaTemplateService.query()
        dispatch( siteSlice.actions.setDudaTemplates( dudaTemplates ) )
        return dudaTemplates
      }
    },
  },
}

export const siteStoreConfig: ModuleStoreConfig<SiteState> = {
  key: siteStoreKey,
  initialState: siteInitialState,
  reducers: siteSlice.reducer,
  saga: siteSaga,
}

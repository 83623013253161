import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

import type { DefaultThunkAction, ModuleStoreConfig, PaginatedItems, PaginatedQueryOptions } from "@onelocal/frontend/common"
import { merchantSettingsService } from "../services/merchantSettingsService"
import type { MerchantsService } from "../services/merchantsService"
import { merchantsService } from "../services/merchantsService"
import type { Merchant, MerchantSettings } from "../types"
import { merchantsSaga } from "./merchantsSagas"

export interface MerchantsState {
  merchants: {
    byId: { [id: string]: Merchant }
  }
  merchantSettings: {
    byId: { [id: string]: MerchantSettings }
  }
}

const merchantsStoreKey = "merchants"

export interface RootStateMerchants {
  [ merchantsStoreKey ]: MerchantsState
}

type MerchantsThunkAction<TReturnType> = DefaultThunkAction<RootStateMerchants, TReturnType>

export const merchantsInitialState: MerchantsState = {
  merchants: {
    byId: {},
  },
  merchantSettings: {
    byId: {},
  },
}

const merchantsSlice = createSlice( {
  name: merchantsStoreKey,
  initialState: merchantsInitialState,
  reducers: {
    updateMerchants: ( state, action: PayloadAction<Merchant[]> ) => {
      for( const merchant of action.payload ) {
        state.merchants.byId[ merchant.id ] = merchant
      }

      return state
    },
    updateMerchantSettings: ( state, action: PayloadAction<MerchantSettings[]> ) => {
      for( const merchantSettings of action.payload ) {
        state.merchantSettings.byId[ merchantSettings.id ] = merchantSettings
      }

      return state
    },
  },
} )

export const merchantsActions = {
  merchants: {
    get( merchantsId: string ): MerchantsThunkAction<Merchant> {
      return async ( dispatch ) => {
        const merchant = await merchantsService.getById( merchantsId )
        dispatch( merchantsSlice.actions.updateMerchants( [ merchant ] ) )
        return merchant
      }
    },
    query( filter: MerchantsService.QueryFilter, options: PaginatedQueryOptions ): MerchantsThunkAction<PaginatedItems<Merchant>> {
      return async ( dispatch ) => {
        const paginatedMerchants = await merchantsService.query( filter, options )
        dispatch( merchantsSlice.actions.updateMerchants( paginatedMerchants.items ) )
        return paginatedMerchants
      }
    },
  },
  merchantsSettings: {
    get( merchantsId: string ): MerchantsThunkAction<MerchantSettings> {
      return async ( dispatch ) => {
        const merchantSettings = await merchantSettingsService.getById( merchantsId )
        dispatch( merchantsSlice.actions.updateMerchantSettings( [ merchantSettings ] ) )
        return merchantSettings
      }
    },
  },
}

export const merchantsStoreConfig: ModuleStoreConfig<MerchantsState> = {
  key: merchantsStoreKey,
  initialState: merchantsInitialState,
  reducers: merchantsSlice.reducer,
  saga: merchantsSaga,
}

import CheckIcon from "@mui/icons-material/Check"
import React, { useCallback, useMemo, useState } from "react"
import type { SubmitHandler } from "react-hook-form"
import { Controller, FormProvider } from "react-hook-form"
import styled from "styled-components"

import { useForm } from "@onelocal/frontend/common"
import type { DialogAction, DialogAlert } from "@onelocal/frontend-web/common"
import { Dialog, DialogAlertType, Grid, GridItem, TextField, useAlert, useModalWithParams } from "@onelocal/frontend-web/common"
import { validationHelpers } from "@onelocal/shared/common"
import { siteService } from "../services/siteService"

const StyledFieldContainer = styled.div`
  align-items: center;
  display: flex;
  margin: 20px 0;
`

enum FormFieldKeys {
  EXISTING_SITE_URL = "existing_site_url",
}

type FormValues = {
  [ FormFieldKeys.EXISTING_SITE_URL ]: string
}

export interface GenerateMerchantSiteContentModalProps {
  isOpen: boolean
  merchantId: string
  onClose( reOpen?: boolean ): void
  onExited: () => void
  onGenerated?(): void
  siteId: string
}

export const GenerateMerchantSiteContentModal: React.FC<GenerateMerchantSiteContentModalProps> = ( {
  isOpen,
  merchantId,
  onClose,
  onExited,
  onGenerated,
  siteId,
} ) => {
  const [ alert, setAlert ] = useState<DialogAlert | undefined>( undefined )
  const formMethods = useForm<FormValues>( {
    defaultValues: {
      [ FormFieldKeys.EXISTING_SITE_URL ]: "",
    },
  } )
  const { control, formState, handleSubmit } = formMethods
  const { showSuccess } = useAlert()
  const [ isProcessing, setIsProcessing ] = useState( false )

  const onSave: SubmitHandler<FormValues> = useCallback( async ( data ) => {
    try {
      if( isProcessing ) {
        return
      }

      setIsProcessing( true )

      await siteService.createSiteContent( merchantId, siteId, { existingSiteUrl: data[ FormFieldKeys.EXISTING_SITE_URL ] } )
      showSuccess( "Site content generating" )

      if( onGenerated ) {
        onGenerated()
      }

      onClose()
    } catch( err ) {
      setAlert( {
        message: err,
        type: DialogAlertType.ERROR,
      } )
    } finally {
      setIsProcessing( false )
    }
  }, [ isProcessing, merchantId, siteId, showSuccess, onGenerated, onClose ] )

  const modalActions: DialogAction[] = useMemo( () => {
    const actions: DialogAction[] = [
      {
        title: "Cancel",
        type: "dismiss",
      },
      {
        disabled: isProcessing,
        icon: ( <CheckIcon style={ { height: 18, width: 18 } }/> ),
        onClick: handleSubmit( onSave ),
        title: "Generate",
        type: "primary",
      },
    ]

    return actions
  }, [ handleSubmit, isProcessing, onSave ] )

  return (
    <Dialog
      actions={ modalActions }
      alert={ alert }
      contentContainerStyle={ {
        paddingLeft: "24px",
        paddingRight: "24px",
        maxWidth: "600px",
        width: "600px",
      } }
      isOpen={ isOpen }
      noMaxWidth={ true }
      onClose={ onClose }
      onExited={ onExited }
      showDividers={ false }
      title={ "Generate Site Content" }
    >
      <FormProvider { ...formMethods }>
        <StyledFieldContainer>
          <Grid spacing={ 4 }>
            <GridItem xs={ 12 }>
              <Controller
                control={ control }
                name={ FormFieldKeys.EXISTING_SITE_URL }
                render={ ( { field: { onChange, value } } ) => (
                  <TextField
                    errorMessage={ formState.errors?.[ FormFieldKeys.EXISTING_SITE_URL ]?.message }
                    fullWidth
                    label="Existing Site URL"
                    onChange={ onChange }
                    type="url"
                    value={ value || "" }
                  />
                ) }
                rules={ {
                  validate: ( value ) => {
                    value = ( value || "" ).trim()

                    if( value && ! validationHelpers.validateUrl( value ) ) {
                      return "Please enter a valid url"
                    }

                    return undefined
                  },
                } }
              />
            </GridItem>
          </Grid>
        </StyledFieldContainer>
      </FormProvider>
    </Dialog>
  )
}

export interface useGenerateMerchantSiteContentModalParams {
  merchantId: string
  onGenerated?(): void
  siteId: string
}

export const useGenerateMerchantSiteContentModal = () => {
  const { showModal, hideModal } = useModalWithParams<useGenerateMerchantSiteContentModalParams>( ( { open, onExited, params } ) => {
    if( ! params ) {
      return null
    }

    return (
      <GenerateMerchantSiteContentModal
        isOpen={ open }
        onClose={ hideModal }
        onExited={ onExited }
        onGenerated={ params.onGenerated }
        merchantId={ params.merchantId }
        siteId={ params.siteId }
      />
    )
  }, [] )

  return {
    hideGenerateMerchantSiteContentModal: hideModal,
    showGenerateMerchantSiteContentModal: showModal,
  }
}

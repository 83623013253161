import React, { StrictMode, useMemo } from "react"
import Helmet from "react-helmet"
import { ModalProvider } from "react-modal-hook"
import { Provider } from "react-redux"
// eslint-disable-next-line no-restricted-imports
import { BrowserRouter } from "react-router-dom"
import { TransitionGroup } from "react-transition-group"

import type { AdminContextType } from "@onelocal/frontend-admin-web/common"
import { AdminContextProvider, ThemeProvider } from "@onelocal/frontend-admin-web/common"
import { environment } from "@onelocal/frontend-admin-web-env/environment"
import { createStore } from "../store"

export interface AdminContainerProps {
  children: React.ReactElement
  context: AdminContextType
  disableGlobalStyle: boolean
}

export const AdminContainer: React.FC<AdminContainerProps> = React.memo( function AdminContainer( {
  children,
  context,
  disableGlobalStyle,
} ) {
  const store = useMemo( () => createStore(), [] )

  return (
    <StrictMode>
      <Helmet>
        <link rel="stylesheet" type="text/css" href={ `https://cloud.typography.com/7311238/${ environment.fontProjectId }/css/fonts.css` } />
      </Helmet>
      <Provider store={ store }>
        <AdminContextProvider value={ context }>
          <BrowserRouter>
            <ThemeProvider disableGlobalStyle={ disableGlobalStyle }>
              <ModalProvider rootComponent={ TransitionGroup }>
                { children }
              </ModalProvider>
            </ThemeProvider>
          </BrowserRouter>
        </AdminContextProvider>
      </Provider>
    </StrictMode>
  )
} )


import type { ApiContentMerchantSettings } from "./api"

export interface ContentMerchantSettings {
  id: string
  frequency?: ContentMerchantSettings.Frequency
  status?: ContentMerchantSettings.Status
  timeframe?: ContentMerchantSettings.Timeframe
}

export namespace ContentMerchantSettings {
  export enum Frequency {
    MONTHLY = "monthly",
    WEEKLY = "weekly",
  }

  export enum Status {
    LIVE = "live",
    NOT_ENABLED = "not_enabled",
  }

  export enum Timeframe {
    MONTHS_3 = "3M",
    MONTHS_4 = "4M",
  }
}

export function parseContentMerchantSettingsFromApi( apiContentMerchantSettings: ApiContentMerchantSettings ) {
  const merchant: ContentMerchantSettings = {
    id: apiContentMerchantSettings.id,
    frequency: apiContentMerchantSettings.frequency,
    status: apiContentMerchantSettings.status,
    timeframe: apiContentMerchantSettings.timeframe,
  }

  return merchant
}

export namespace BlogPost {
  export interface BlogPostContent {
    contentHtml: string
    optimizedForSeo: boolean | null
    title: string
  }

  export interface ImagePurchased {
    id: string
    shutterstock: {
      id: string
    }
    type: "shutterstock"
    url: string
  }

  export interface ImageSuggestion {
    id: string
    shutterstock: {
      id: string
    }
    type: "shutterstock"
    url: {
      small: string
      large: string
    }
  }

  export const enum Origin {
    MANUAL = "manual",
    SCHEDULED = "scheduled",
    SUGGESTION = "suggestion",
  }

  export const enum Status {
    APPROVED = "approved",
    GENERATING = "generating",
    PUBLISHED = "published",
    SCHEDULED = "scheduled",
    WAITING_APPROVAL = "waiting_approval",
  }

  export interface ImageUploaded {
    id: string
    filename: string
    original_url: string
  }

  export const enum SuggestionType {
    KEYWORD = "keyword"
  }
}

export namespace SocialPostSuggestion {
  export type ImageSuggestion = (
    ImageSuggestionCustom
    | ImageSuggestionShutterstock
  )

  export namespace ImageSuggestion {
    export const enum Type {
      CUSTOM = "custom",
      SHUTTERSTOCK = "shutterstock",
    }
  }

  export interface ImageSuggestionBase {
    id: string
    tags?: string[]
    type: ImageSuggestion.Type
    url: {
      small: string
      large: string
    }
  }

  export interface ImageSuggestionCustom extends ImageSuggestionBase {
    type: ImageSuggestion.Type.CUSTOM
  }

  export interface ImageSuggestionShutterstock extends ImageSuggestionBase {
    shutterstock: {
      id: string
    }
    type: ImageSuggestion.Type.SHUTTERSTOCK
  }

  export interface ImageUploaded {
    id: string
    filename: string
    originalUrl: string
    tags?: string[]
  }

  export interface Platform {
    enabled: boolean
    error?: string
  }

  export const enum PublishDateType {
    OPTIMAL = "optimal",
    NOW = "now",
  }

  export interface StatusLog {
    date: string
    details?: string
    type: StatusType
  }

  export const enum StatusType {
    /** There is some information required under the model that need to be fill before the generation can happen */
    PENDING = "pending",
    /** The generation is processing */
    PROCESSING = "processing",
    /** The post is ready and need to be scheduled by the user */
    READY = "ready",
    /** The post has been scheduled for the user */
    SCHEDULED = "scheduled",
  }

  export const enum Type {
    BEFORE_AFTER_PHOTOS = "before_after_photos",
    BLOG_POST = "blog_post",
    BULK_MESSAGE = "bulk_message",
    PHOTOS = "photos",
    REVIEW = "review",
  }
}

export namespace SocialPost {
  export const enum PlatformType {
    FACEBOOK = "facebook",
    GOOGLE = "google",
    INSTAGRAM = "instagram",
  }
}

export interface BlogPostBase extends BlogPost.BlogPostContent {
  assistant: {
    threadId: string
  }
  id: string
  images: {
    duda: {
      imageId: string
      url: string
    } | null
    imageId: string | null
    keywords: string[] | null
    purchased: BlogPost.ImagePurchased | null
    suggestions: BlogPost.ImageSuggestion[]
    uploaded: BlogPost.ImageUploaded | null
  }
  merchantId: string
  origin: BlogPost.Origin
  originalGenerated: BlogPost.BlogPostContent
  publishDate: string | null
  status: BlogPost.Status
  summary: string
}

export interface BlogPostCommon extends BlogPostBase {
  origin: BlogPost.Origin.MANUAL | BlogPost.Origin.SCHEDULED
}

export interface BlogPostSuggestion extends BlogPostBase {
  keyword: string
  origin: BlogPost.Origin.SUGGESTION
  service: string
  suggestionType: BlogPost.SuggestionType
}

export type BlogPost = BlogPostCommon | BlogPostSuggestion

export interface SocialPostSuggestionBase {
  id: string
  image: {
    ids: string[]
    suggestions: SocialPostSuggestion.ImageSuggestion[]
    uploaded: SocialPostSuggestion.ImageUploaded[]
  }
  merchantId: string
  platform: Record<SocialPost.PlatformType, SocialPostSuggestion.Platform>
  publishDate: {
    at?: string
    value: SocialPostSuggestion.PublishDateType.OPTIMAL | SocialPostSuggestion.PublishDateType.NOW | string
  }
  socialPostId: string | null
  status: {
    at: string
    details?: string
    logs: SocialPostSuggestion.StatusLog[]
    type: SocialPostSuggestion.StatusType
  }
  text: string
  textOriginal: string
  type: SocialPostSuggestion.Type
}

export interface SocialPostSuggestionBeforeAfterPhotos extends SocialPostSuggestionBase {
  model: {
    afterImage?: SocialPostSuggestion.ImageUploaded
    beforeImage?: SocialPostSuggestion.ImageUploaded
    description?: string
  }
  type: SocialPostSuggestion.Type.BEFORE_AFTER_PHOTOS
}

export interface SocialPostSuggestionBlogPost extends SocialPostSuggestionBase {
  blogPostId: string
  type: SocialPostSuggestion.Type.BLOG_POST
}

export interface SocialPostSuggestionBulkMessage extends SocialPostSuggestionBase {
  bulkMessageId: string
  type: SocialPostSuggestion.Type.BULK_MESSAGE
}

export interface SocialPostSuggestionPhotos extends SocialPostSuggestionBase {
  model: {
    description?: string
  }
  type: SocialPostSuggestion.Type.PHOTOS
}

export interface SocialPostSuggestionReview extends SocialPostSuggestionBase {
  review: {
    date: string
    id: string
    name: string
    rating: number
    source: string
    text: string
  }
  type: SocialPostSuggestion.Type.REVIEW
}

export type SocialPostSuggestion = (
  SocialPostSuggestionBeforeAfterPhotos
  | SocialPostSuggestionBlogPost
  | SocialPostSuggestionBulkMessage
  | SocialPostSuggestionPhotos
  | SocialPostSuggestionReview
)

import type { PhoneNumber } from "@onelocal/shared/common"

export interface ApiPhoneNumber {
  type: "mobile" | "landline" | "voip" | "unknown"
  value: string
  international_format: string
  national_format: string
  country_calling_code: number
  country_code: string
}

export function parsePhoneNumberFromApi( apiPhoneNumber: ApiPhoneNumber ) {
  const phoneNumber: PhoneNumber = {
    countryCallingCode: apiPhoneNumber.country_calling_code,
    countryCode: apiPhoneNumber.country_code,
    internationalFormat: apiPhoneNumber.international_format,
    nationalFormat: apiPhoneNumber.national_format,
    type: apiPhoneNumber.type,
    value: apiPhoneNumber.value,
  }

  return phoneNumber
}

// eslint-disable-next-line no-restricted-imports
import { TextField as MuiTextField } from "@mui/material"
import React, { useCallback } from "react"
import styled from "styled-components"

import { styleHelpers } from "../helpers/styleHelpers"

type TextFieldVariants = "standard" | "filled" | "outlined"

export interface TextFieldProps {
  autoComplete?: string
  className?: string
  defaultValue?: string
  disabled?: boolean
  error?: boolean
  errorMessage?: string
  fullWidth?: boolean
  helperText?: string
  id?: string
  inputRef?: React.Ref<HTMLInputElement>
  label?: string
  max?: number
  min?: number
  minRows?: number
  multiline?: boolean
  name?: string
  /** @note When the field is disabled, there is no need to pass onChange Handler */
  onChange?( value: string ): void
  placeholder?: string
  required?: boolean
  rows?: number
  step?: number
  type?: React.InputHTMLAttributes<unknown>[ "type" ]
  value?: string | number
  variant?: TextFieldVariants
}

const StyledTextField = styled( MuiTextField )`
  & .MuiFormHelperText-root {
    font-size: ${ styleHelpers.fonts.sizes.small };
  }

  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: ${ styleHelpers.colors.darkBlue };
    }
  }

  & .MuiInputLabel-outlined.Mui-focused {
    color: ${ styleHelpers.colors.darkBlue };
  }

  & .MuiFormHelperText-root {
    line-height: 1.25;
  }
`

export const TextField: React.FC<TextFieldProps> = ( {
  onChange,
  errorMessage,
  helperText,
  minRows,
  variant,
  ...otherProps
} ) => {
  const handleChange = useCallback( ( event: React.ChangeEvent<HTMLInputElement> ) => {
    if( ! onChange ) {
      return
    }
    onChange( event.target.value )
  }, [ onChange ] )

  return (
    <StyledTextField
      error={ errorMessage !== undefined }
      helperText={ errorMessage ?? helperText }
      InputLabelProps={ { shrink: true } }
      minRows={ minRows || 3 }
      onChange={ handleChange }
      variant={ variant || "outlined" }
      { ...otherProps }
    />
  )
}

import React from "react"
import { FormProvider } from "react-hook-form"
import styled from "styled-components"

import { Grid, GridItem, StyledFormSectionTitle } from "@onelocal/frontend-web/common"
import { useOnboardingForm } from "../../hooks/useOnboardingForm"
import { OnboardingForm } from "../../types"
import { FIELD_LABELS } from "./constants"
import { OnboardingFormButtons, OnboardingFormDisclaimerLabel, OnboardingFormFieldText, OnboardingFormNotes, OnboardingFormSaving } from "./OnboardingFormFields"

const StyledFieldContainer = styled.div`
  align-items: center;
  display: flex;
  margin: 20px 0;
`

const currentYear = ( new Date() ).getFullYear()

type FormValues = {
  [ OnboardingForm.Field.BUSINESS_CREATION_YEAR ]: number
  [ OnboardingForm.Field.EMPLOYEE_COUNT ]: number
  [ OnboardingForm.Field.AREA_OF_OPERATION ]: string
  [ OnboardingForm.Field.BUSINESS_MESSAGING ]: string
  [ OnboardingForm.Field.BUSINESS_CREATION_HISTORY ]: string
  [ OnboardingForm.Field.EXCLUDED_KEYWORDS ]: string
  [ OnboardingForm.Field.NOTES_ABOUT_US ]: string
}

export interface OnboardingFormStepAboutUsProps {
}

export const OnboardingFormStepAboutUs: React.FC<OnboardingFormStepAboutUsProps> = () => {
  const {
    formMethods,
    isSaving,
    onChangeHandlers,
    onSubmit,
  } = useOnboardingForm<FormValues>( {
    defaultValues: {
      [ OnboardingForm.Field.BUSINESS_CREATION_YEAR ]: currentYear - 15,
    },
    fieldNames: [
      OnboardingForm.Field.AREA_OF_OPERATION,
      OnboardingForm.Field.BUSINESS_CREATION_HISTORY,
      OnboardingForm.Field.BUSINESS_CREATION_YEAR,
      OnboardingForm.Field.BUSINESS_MESSAGING,
      OnboardingForm.Field.COMPETITOR_1,
      OnboardingForm.Field.COMPETITOR_2,
      OnboardingForm.Field.COMPETITOR_3,
      OnboardingForm.Field.EMPLOYEE_COUNT,
      OnboardingForm.Field.EXCLUDED_KEYWORDS,
      OnboardingForm.Field.NOTES_ABOUT_US,
    ],
    step: OnboardingForm.Step.ABOUT_US,
  } )

  return (
    <div>
      <OnboardingFormSaving isSaving={ isSaving } />
      <FormProvider { ...formMethods }>
        <form onSubmit={ onSubmit } noValidate>
          <StyledFieldContainer>
            <Grid spacing={ 4 }>
              <GridItem xs={ 4 }>
                <OnboardingFormFieldText
                  fieldName={ OnboardingForm.Field.BUSINESS_CREATION_YEAR }
                  isRequired={ true }
                  label={ FIELD_LABELS[ OnboardingForm.Field.BUSINESS_CREATION_YEAR ] }
                  onChange={ onChangeHandlers[ OnboardingForm.Field.BUSINESS_CREATION_YEAR ] }
                  min={ 1800 }
                  max={ currentYear }
                  step={ 1 }
                  type="number"
                />
              </GridItem>
              <GridItem xs={ 4 }>
                <OnboardingFormFieldText
                  fieldName={ OnboardingForm.Field.EMPLOYEE_COUNT }
                  isRequired={ true }
                  label={ FIELD_LABELS[ OnboardingForm.Field.EMPLOYEE_COUNT ] }
                  onChange={ onChangeHandlers[ OnboardingForm.Field.EMPLOYEE_COUNT ] }
                  min={ 1 }
                  max={ 10000 }
                  step={ 1 }
                  type="number"
                />
              </GridItem>

              <GridItem xs={ 12 }>
                <OnboardingFormFieldText
                  fieldName={ OnboardingForm.Field.AREA_OF_OPERATION }
                  helperText="Where do most of your customers live and/or what areas do you service? Provide this in a comma separated list (example: Toronto Ontario, Calgary Alberta, Winnipeg Manitoba)"
                  isRequired={ false }
                  label={ FIELD_LABELS[ OnboardingForm.Field.AREA_OF_OPERATION ] }
                  onChange={ onChangeHandlers[ OnboardingForm.Field.AREA_OF_OPERATION ] }
                  multiline={ true }
                />
              </GridItem>

              <GridItem xs={ 12 }>
                <OnboardingFormFieldText
                  fieldName={ OnboardingForm.Field.BUSINESS_MESSAGING }
                  helperText="How does this business talk about itself publicly, how do they see them selves repested to their customers"
                  isRequired={ true }
                  label={ FIELD_LABELS[ OnboardingForm.Field.BUSINESS_MESSAGING ] }
                  onChange={ onChangeHandlers[ OnboardingForm.Field.BUSINESS_MESSAGING ] }
                  multiline={ true }
                />
              </GridItem>

              <GridItem xs={ 12 }>
                <OnboardingFormFieldText
                  fieldName={ OnboardingForm.Field.BUSINESS_CREATION_HISTORY }
                  helperText="Why did they (or their founder) create the business, what opportunity did they see they wanted to fill"
                  isRequired={ false }
                  label={ FIELD_LABELS[ OnboardingForm.Field.BUSINESS_CREATION_HISTORY ] }
                  onChange={ onChangeHandlers[ OnboardingForm.Field.BUSINESS_CREATION_HISTORY ] }
                  multiline={ true }
                />
              </GridItem>

              <GridItem xs={ 12 }>
                <OnboardingFormFieldText
                  fieldName={ OnboardingForm.Field.EXCLUDED_KEYWORDS }
                  helperText="This could be terms their competition use, or they feel are not relevant to their business"
                  isRequired={ false }
                  label={ FIELD_LABELS[ OnboardingForm.Field.EXCLUDED_KEYWORDS ] }
                  onChange={ onChangeHandlers[ OnboardingForm.Field.EXCLUDED_KEYWORDS ] }
                  multiline={ true }
                />
              </GridItem>
            </Grid>

          </StyledFieldContainer>

          <StyledFormSectionTitle>Competitors</StyledFormSectionTitle>
          <StyledFieldContainer>
            <Grid spacing={ 4 }>
              <GridItem xs={ 12 }>
                <OnboardingFormFieldText
                  fieldName={ OnboardingForm.Field.COMPETITOR_1 }
                  isRequired={ false }
                  label={ FIELD_LABELS[ OnboardingForm.Field.COMPETITOR_1 ] }
                  onChange={ onChangeHandlers[ OnboardingForm.Field.COMPETITOR_1 ] }
                  type="url"
                />
              </GridItem>
              <GridItem xs={ 12 }>
                <OnboardingFormFieldText
                  fieldName={ OnboardingForm.Field.COMPETITOR_2 }
                  isRequired={ false }
                  label={ FIELD_LABELS[ OnboardingForm.Field.COMPETITOR_2 ] }
                  onChange={ onChangeHandlers[ OnboardingForm.Field.COMPETITOR_2 ] }
                  type="url"
                />
              </GridItem>
              <GridItem xs={ 12 }>
                <OnboardingFormFieldText
                  fieldName={ OnboardingForm.Field.COMPETITOR_3 }
                  isRequired={ false }
                  label={ FIELD_LABELS[ OnboardingForm.Field.COMPETITOR_3 ] }
                  onChange={ onChangeHandlers[ OnboardingForm.Field.COMPETITOR_3 ] }
                  type="url"
                />
              </GridItem>
            </Grid>
          </StyledFieldContainer>
          <OnboardingFormNotes
            step={ OnboardingForm.Step.ABOUT_US }
            onChange={ onChangeHandlers[ OnboardingForm.Field.NOTES_ABOUT_US ] }
          />
          <OnboardingFormDisclaimerLabel />
          <OnboardingFormButtons disabled={ isSaving } />
        </form>
      </FormProvider>
    </div>
  )
}

import { AsYouTypeFormatter, PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber"
import { isEmpty, isString } from "lodash"

import { utilHelpers } from "@onelocal/shared/common"

const INVALID_PHONE_NUMBER_CHARACTER_REGEX = /[@]+/g

const phoneUtil = PhoneNumberUtil.getInstance()

// eslint-disable-next-line no-useless-escape
const GSM_7_CHARS_REGEX = /^[0-9a-zA-Z@Δ¡¿£_!Φ"¥Γ#èΛ¤éΩ%ùΠ&ìΨòΣçΘΞ:Ø;ÄäøÆ,<Ööæ=ÑñÅß>ÜüåÉ§à€~ \$\.\-\+\(\)\*\\\/\?\|\^\}\{\[\]\'\r\n]*$/
// Use /g to return the count of all the characters found
// eslint-disable-next-line no-useless-escape
const GSM_7_EXT_CHARS_REGEX = /[\\\^\{\}\[~\]|€]/g
const MESSAGE_LENGTH = {
  GSM_7: 160,
  UCS_2: 70,
}
const MULTI_MESSAGE_LENGTH = {
  GSM_7: 153,
  UCS_2: 67,
}

const parsePhoneNumber = ( phoneNumber: string, country = "CA" ) => {
  try {
    return phoneUtil.parse( phoneNumber, country )
  } catch( error ) {
    return null
  }
}

const getPhoneNumberFormatter = ( format: PhoneNumberFormat ) => ( phoneNumber: string, country = "CA" ): string => {
  const parsedPhoneNumber = parsePhoneNumber( phoneNumber, country )
  if( parsedPhoneNumber ) {
    return phoneUtil.format( parsedPhoneNumber, format )
  }
  return phoneNumber
}

export const phoneHelpers = {
  getAsYouTypeFormat: ( country = "CA" ) => {
    return new AsYouTypeFormatter( country )
  },
  getCountryCode: ( phoneNumber: string, country = "CA" ) => {
    const parsedPhoneNumber = parsePhoneNumber( phoneNumber, country )
    if( parsedPhoneNumber ) {
      return parsedPhoneNumber.getCountryCode()
    }
    return null
  },
  getPhoneNumberE164Format: getPhoneNumberFormatter( PhoneNumberFormat.E164 ),
  getPhoneNumberInternationalFormat: getPhoneNumberFormatter( PhoneNumberFormat.INTERNATIONAL ),
  getPhoneNumberNationalFormat: getPhoneNumberFormatter( PhoneNumberFormat.NATIONAL ),
  getPhoneNumberBaseValue( phoneNumber: string ): string {
    if( phoneNumber == null ) {
      return phoneNumber
    }
    if( typeof phoneNumber !== "string" ) {
      phoneNumber = `${ phoneNumber }`
    }
    const result = []

    if( phoneNumber.startsWith( "+" ) ) {
      result.push( "+" )
    }

    for( const character of phoneNumber ) {
      switch( character.toLowerCase() ) {
        case "0":
          result.push( "0" )
          break
        case "1":
          result.push( "1" )
          break
        case "2":
        case "a":
        case "b":
        case "c":
          result.push( "2" )
          break
        case "3":
        case "d":
        case "e":
        case "f":
          result.push( "3" )
          break
        case "4":
        case "g":
        case "h":
        case "i":
          result.push( "4" )
          break
        case "5":
        case "j":
        case "k":
        case "l":
          result.push( "5" )
          break
        case "6":
        case "m":
        case "n":
        case "o":
          result.push( "6" )
          break
        case "7":
        case "p":
        case "q":
        case "r":
        case "s":
          result.push( "7" )
          break
        case "8":
        case "t":
        case "u":
        case "v":
          result.push( "8" )
          break
        case "9":
        case "w":
        case "x":
        case "y":
        case "z":
          result.push( "9" )
      }
    }
    return result.join( "" )
  },
  getTextMessageInfo( text: string ) {
    if( ! isString( text ) || isEmpty( text ) ) {
      text = ""
    }

    let length: number = utilHelpers.getStringLength( text )
    const encoding = GSM_7_CHARS_REGEX.test( text ) ? "GSM_7" : "UCS_2"
    let perMessageLength: number = MESSAGE_LENGTH[ encoding ]

    if( encoding === "GSM_7" ) {
      const gsm7ExtMatch = text.match( GSM_7_EXT_CHARS_REGEX )
      if( gsm7ExtMatch && gsm7ExtMatch.length > 0 ) {
        length += gsm7ExtMatch.length
      }
    }

    if( length > perMessageLength ) {
      perMessageLength = MULTI_MESSAGE_LENGTH[ encoding ]
    }

    const messageCount = Math.ceil( length / perMessageLength )

    return {
      encoding,
      length,
      remainingCount: ( perMessageLength * messageCount ) - length,
      messageCount,
      perMessageLength,
    }
  },
  isAnonymousPhoneNumber( phoneNumber: string ) {
    // See https://shaun.net/notes/solving-the-mystery-of-calls-from-266696687/
    return phoneNumber === "+266696687"
  },
  isValidFormat( phoneNumber: string, country = "CA" ) {
    if( isEmpty( phoneNumber ) ) {
      return false
    }
    // Check for invalid characters before striping of valid special characters
    if( INVALID_PHONE_NUMBER_CHARACTER_REGEX.test( phoneNumber ) ) {
      return false
    }
    const phoneNumberBaseValue = phoneHelpers.getPhoneNumberBaseValue( phoneNumber )
    if( ! phoneNumberBaseValue ) {
      return false
    }
    const parsedPhoneNumber = parsePhoneNumber( phoneNumberBaseValue, country )
    if( ! parsedPhoneNumber ) {
      return false
    }

    return phoneUtil.isValidNumber( parsedPhoneNumber )
  },
}

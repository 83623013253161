import { isEmpty } from "lodash"

import { phoneHelpers } from "@onelocal/frontend/common"
import { dateHelpers, formattingHelpers, utilHelpers } from "@onelocal/shared/common"
import { ConnectAssistantConversation, ConnectCall, ConnectCallInbound } from "../types"

export const RING_DURATION_VALUE_DEFAULT = 15
export const RING_DURATION_VALUE_MAX = 30
export const RING_DURATION_VALUE_MIN = 10

export const INVALID_NUMBER_ERROR = "Please enter a number from 10 to 30."

export const connectCallHelpers = {
  isCallMissed( connectCall: ConnectCall ) {
    if( connectCall.direction === ConnectCall.Direction.OUTBOUND ) {
      return false
    }
    if( connectCall.stats.isMissedCall != null ) {
      return connectCall.stats.isMissedCall === true
    }
    return connectCallHelpers.getCallDuration( connectCall ) === 0
  },
  getAssistantFlowNames( connectCall: ConnectCall ) {
    if( connectCall.direction === ConnectCall.Direction.OUTBOUND || ! connectCall.assistantConversation ) {
      return null
    }

    const { assistantConversation } = connectCall

    const flowNames = assistantConversation.flows.map( ( flow ) => flow.name )

    if( connectCall.assistantConversation.voicemail?.status === ConnectAssistantConversation.VoicemailStatus.COMPLETED ) {
      flowNames.push( "Voicemail" )
    }

    return flowNames.sort( utilHelpers.localCompare )
  },
  getCallDetailText( connectCall: ConnectCall, currentUserId?: string ) {
    if( connectCall.direction === ConnectCall.Direction.INBOUND ) {
      if( connectCall.steps.length === 0 ) {
        return null
      }

      if( connectCall.assistantConversation?.voicemail?.status === ConnectAssistantConversation.VoicemailStatus.COMPLETED ) {
        return null
      }

      const lastStep = connectCall.steps[ connectCall.steps.length - 1 ]

      switch( lastStep.type ) {
        case ConnectCallInbound.StepType.FORWARD:
        case ConnectCallInbound.StepType.FORWARD_BUSINESS_HOUR: {
          if( ! lastStep.duration ) {
            return null
          }

          return `Forwarded to ${ lastStep.to } - ${ dateHelpers.formatDurationSecondsShort( lastStep.duration ) }`
        }
        case ConnectCallInbound.StepType.RING_MEMBERS: {
          if( ! lastStep.duration || ! lastStep.answeredAccount ) {
            return null
          }

          let answererName = lastStep.answeredAccount.name ? formattingHelpers.getFirstName( lastStep.answeredAccount ) : null

          if( currentUserId && currentUserId === lastStep.answeredAccount.id ) {
            answererName = "You"
          }

          return `Answered${ answererName ? ` by ${ answererName }` : "" } - ${ dateHelpers.formatDurationSecondsShort( lastStep.duration ) }`
        }
        case ConnectCallInbound.StepType.ASSISTANT:
        case ConnectCallInbound.StepType.ASSISTANT_BUSINESS_HOUR: {
          if( isEmpty( connectCall.assistantConversation?.assistant?.flows ) ){
            return null
          }

          const flows = connectCall.assistantConversation?.flows
          const flow = flows && flows.length > 0 ? flows[ 0 ].name : "No Answer"
          return `LocalResponse-${ flow }`
        }
      }

    } else {
      if( connectCall.dial?.duration && connectCall.dial.duration > 0 ) {
        return `Answered - ${ dateHelpers.formatDurationSecondsShort( connectCall.dial?.duration || 0 ) }`
      }

      return "No answer"
    }
  },
  getCallDuration( connectCall: ConnectCall ) {
    if( connectCall.direction === ConnectCall.Direction.INBOUND ) {
      for( const step of connectCall.steps ) {
        switch( step.type ) {
          case ConnectCallInbound.StepType.FORWARD:
          case ConnectCallInbound.StepType.FORWARD_BUSINESS_HOUR:
          case ConnectCallInbound.StepType.RING_MEMBERS:
            if( step.duration ) {
              return step.duration
            }
            break
        }
      }
    }

    return connectCall.duration
  },
  getContactText( connectCall: ConnectCall ) {
    if( ! connectCall ) {
      return null
    }

    if( connectCall.customer ) {
      const displayName = formattingHelpers.getDisplayName( connectCall.customer )

      if( displayName ) {
        return displayName
      }
    }

    return connectCallHelpers.getFormattedPhoneNumber( connectCall )
  },
  getFormattedPhoneNumber( connectCall: ConnectCall ) {
    if( connectCall.direction === ConnectCall.Direction.INBOUND && phoneHelpers.isAnonymousPhoneNumber( connectCall.from ) ) {
      return "Unknown Caller"
    }

    return phoneHelpers.getPhoneNumberNationalFormat( connectCall.direction === ConnectCall.Direction.INBOUND ? connectCall.from : connectCall.to )
  },
  validateRingDuration( value: string ) {
    const parsedValue = parseInt( value, 10 )
    if( value == null
        || Number.isNaN( parsedValue )
        || parsedValue < 10
        || parsedValue > 30
    ) {
      return INVALID_NUMBER_ERROR
    }

    return
  },
}

import "react-quill/dist/quill.snow.css"

import Quill from "quill"
import React from "react"
import ReactQuill from "react-quill"
import styled from "styled-components"

import { styleHelpers } from "../helpers"

const StyledReactQuill = styled( ReactQuill )< { $height?: string } >`
  .ql-editor {
    border: none;
    font-family: ${ styleHelpers.fonts.default };
    font-size: 14px;
    font-weight: 400;
    padding: 15px;

    &.ql-blank::before {
      color: @light;
      font-style: normal;
      font-weight: normal;
    }
  }

  .ql-container.ql-snow {
    height: ${ props => props.$height ?? "152px" };
  }
`

const StyledError = styled.span`
  color: ${ styleHelpers.colors.red };
  font-size: ${ styleHelpers.fonts.sizes.small };
`

export interface TextEditorProps {
  bounds?: string | HTMLElement
  disabled?: boolean
  errorMessage?: string
  height?: string
  onChange( value: string ): void
  value?: string
}

class CustomLink extends Quill.import( "formats/link" ) {
  static create( value: string ) {
    const node = super.create( value )
    if( value && ! value.startsWith( "https" ) ) {
      node.setAttribute( "href", `https://${ value }` )
    } else {
      node.setAttribute( "href", value )
    }
    return node
  }

  static value( node: HTMLElement ) {
    return node.getAttribute( "href" )
  }
}

Quill.register( "formats/link", CustomLink )

export const TextEditor: React.FC<TextEditorProps> = ( { bounds, disabled, errorMessage, height, value, onChange } ) => {
  return (
    <>
      <StyledReactQuill
        $height={ height }
        bounds={ bounds }
        onChange={ onChange }
        readOnly={ disabled }
        theme="snow"
        value={ value }
      />
      { errorMessage && <StyledError>{ errorMessage }</StyledError> }
    </>
  )
}

import type { Action, AnyAction, Reducer, ThunkAction, ThunkDispatch } from "@reduxjs/toolkit"
import { useDispatch } from "react-redux"
import type { Saga } from "redux-saga"
import { all } from "redux-saga/effects"

export interface ModuleStoreConfig<TState> {
  key: string
  initialState: TState
  reducers: Reducer<TState, AnyAction>
  saga?: Saga
}

export type DefaultThunkAction<RootState, ReturnType = void> = ThunkAction<
  Promise<ReturnType>,
  RootState,
  unknown,
  Action<string>
>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncDispatch<T> = ThunkDispatch<T, any, Action>

export function useAsyncDispatch() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useDispatch<AsyncDispatch<any>>()
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function *createRootSaga( storeConfigs: Array<ModuleStoreConfig<any>> ) {
  const allSagas: unknown[] = []

  for( const storeConfig of storeConfigs ) {
    if( storeConfig.saga ) {
      allSagas.push( storeConfig.saga() )
    }
  }

  yield all( allSagas )
}

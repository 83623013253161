import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

import type { DefaultThunkAction, ModuleStoreConfig } from "@onelocal/frontend/common"
import { blogPostsService } from "../services/blogPostsService"
import type { ContentMerchantSettingsService } from "../services/merchantSettingsService"
import { contentMerchantSettingsService } from "../services/merchantSettingsService"
import { socialPostSuggestionsService } from "../services/socialPostSuggestionsService"
import type { BlogPost, ContentMerchantSettings, SocialPostSuggestion } from "../types"
import { contentSaga } from "./contentSagas"

export interface ContentState {
  blogPosts: {
    byId: { [id: string]: BlogPost }
  }
  merchantSettings: {
    byId: { [merchantId: string]: ContentMerchantSettings }
  }
  socialPostSuggestions: {
    byId: { [id: string]: SocialPostSuggestion }
  }
}

const contentStoreKey = "content"

export interface RootStateContent {
  [ contentStoreKey ]: ContentState
}

type ContentThunkAction<TReturnType> = DefaultThunkAction<RootStateContent, TReturnType>

const contentInitialState: ContentState = {
  blogPosts: {
    byId: {},
  },
  merchantSettings: {
    byId: {},
  },
  socialPostSuggestions: {
    byId: {},
  },
}

export namespace ContentState {}

const contentSlice = createSlice( {
  name: contentStoreKey,
  initialState: contentInitialState,
  reducers: {
    updateBlogPosts: ( state, action: PayloadAction<BlogPost[]> ) => {
      for( const blogPost of action.payload ) {
        state.blogPosts.byId[ blogPost.id ] = blogPost
      }

      return state
    },
    updateMerchantSettings: ( state, action: PayloadAction<ContentMerchantSettings> ) => {
      const merchantSettings = action.payload
      state.merchantSettings.byId[ merchantSettings.id ] = merchantSettings
      return state
    },
    updateSocialPostSuggestions: ( state, action: PayloadAction<SocialPostSuggestion[]> ) => {
      for( const socialPostSuggestion of action.payload ) {
        state.socialPostSuggestions.byId[ socialPostSuggestion.id ] = socialPostSuggestion
      }

      return state
    },
  },
} )

export const contentActions = {
  blogPosts: {
    getById( merchantId: string, blogPostId: string ): ContentThunkAction<BlogPost> {
      return async ( dispatch ) => {
        const blogPost = await blogPostsService.getById( merchantId, blogPostId )
        dispatch( contentSlice.actions.updateBlogPosts( [ blogPost ] ) )
        return blogPost
      }
    },
  },
  merchantSettings: {
    get( merchantId: string ): ContentThunkAction<ContentMerchantSettings> {
      return async ( dispatch ) => {
        const merchantSettings = await contentMerchantSettingsService.getMerchantSettings( merchantId )
        dispatch( contentSlice.actions.updateMerchantSettings( merchantSettings ) )
        return merchantSettings
      }
    },
    update( merchantId: string, model: ContentMerchantSettingsService.UpdateModel ): ContentThunkAction<ContentMerchantSettings> {
      return async ( dispatch ) => {
        const merchantSettings = await contentMerchantSettingsService.update( merchantId, model )
        dispatch( contentSlice.actions.updateMerchantSettings( merchantSettings ) )
        return merchantSettings
      }
    },
  },
  socialPostSuggestions: {
    getById( merchantId: string, socialPostSuggestionId: string ): ContentThunkAction<SocialPostSuggestion> {
      return async ( dispatch ) => {
        const socialPostSuggestion = await socialPostSuggestionsService.getById( merchantId, socialPostSuggestionId )
        dispatch( contentSlice.actions.updateSocialPostSuggestions( [ socialPostSuggestion ] ) )
        return socialPostSuggestion
      }
    },
  },
}

export const contentStoreConfig: ModuleStoreConfig<ContentState> = {
  key: contentStoreKey,
  initialState: contentInitialState,
  reducers: contentSlice.reducer,
  saga: contentSaga,
}

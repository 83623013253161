import React, { useCallback, useEffect, useRef, useState } from "react"
import type { SubmitHandler } from "react-hook-form"
import { Controller, FormProvider } from "react-hook-form"
import { useParams } from "react-router-dom"
import styled from "styled-components"

import { useAsyncDispatch, useForm, useResource } from "@onelocal/frontend/common"
import { contentActions, ContentMerchantSettings, contentSelectors } from "@onelocal/frontend-admin-web/content"
import type { SelectOption } from "@onelocal/frontend-web/common"
import { Button, Grid, GridItem, Loading, LoadingOverlay, Select, StyledSectionTitle, styleHelpers, useAlert } from "@onelocal/frontend-web/common"
import { MerchantDetailsContainer } from "../components/MerchantDetailsContainer"

export interface MerchantDetailsContentPageProps {
}

const StyledSelect = styled( Select )`
  width: 100%;
`

const enum ContentFormFieldKeys {
  FREQUENCY = "frequency",
  STATUS = "status",
  TIMEFRAME = "timeframe",
}

interface ContentSettingsField {
  label: string
  name: ContentFormFieldKeys
  options: Array<SelectOption<string>>
}

const ContentSettingsFields: ContentSettingsField[] = [
  {
    label: "Status",
    name: ContentFormFieldKeys.STATUS,
    options: [
      { label: "Live", value: ContentMerchantSettings.Status.LIVE },
      { label: "Not Enabled", value: ContentMerchantSettings.Status.NOT_ENABLED },
    ],
  },
  {
    label: "Frequency",
    name: ContentFormFieldKeys.FREQUENCY,
    options: [
      { label: "Monthly", value: ContentMerchantSettings.Frequency.MONTHLY },
      { label: "Weekly", value: ContentMerchantSettings.Frequency.WEEKLY },
    ],
  },
  {
    label: "Timeframe",
    name: ContentFormFieldKeys.TIMEFRAME,
    options: [
      { label: "3 Months", value: ContentMerchantSettings.Timeframe.MONTHS_3 },
      { label: "4 Months", value: ContentMerchantSettings.Timeframe.MONTHS_4 },
    ],
  },
]

interface FormValues {
  [ ContentFormFieldKeys.FREQUENCY ]: ContentMerchantSettings.Frequency
  [ ContentFormFieldKeys.STATUS ]: ContentMerchantSettings.Status
  [ ContentFormFieldKeys.TIMEFRAME ]: ContentMerchantSettings.Timeframe
}

export const MerchantDetailsContentPage: React.FC<MerchantDetailsContentPageProps> = () => {
  const { merchantId } = useParams<{ merchantId: string }>() as { merchantId: string }
  const { showError, showSuccess } = useAlert()
  const formInitializedRef = useRef( false )
  const dispatch = useAsyncDispatch()
  const [ isSaving, setIsSaving ] = useState( false )

  const { resource: contentMerchantSettings } = useResource( {
    getAction: contentActions.merchantSettings.get,
    id: merchantId,
    selector: contentSelectors.merchantSettings.byId,
  } )

  const formMethods = useForm<FormValues>( {
    defaultValues: {
      [ ContentFormFieldKeys.FREQUENCY ]: contentMerchantSettings?.frequency ?? ContentMerchantSettings.Frequency.MONTHLY,
      [ ContentFormFieldKeys.STATUS ]: contentMerchantSettings?.status ?? ContentMerchantSettings.Status.NOT_ENABLED,
      [ ContentFormFieldKeys.TIMEFRAME ]: contentMerchantSettings?.timeframe ?? ContentMerchantSettings.Timeframe.MONTHS_3,
    },
  } )

  const { control, handleSubmit, setValue, watch } = formMethods

  const onSubmit: SubmitHandler<FormValues> = useCallback( async ( data ) => {
    try {
      setIsSaving( true )
      await dispatch( contentActions.merchantSettings.update( merchantId, data ) )
      showSuccess( "Setting updated" )
    } catch( err ) {
      showError( err )
    } finally {
      setIsSaving( false )
    }
  }, [ dispatch, merchantId, showError, showSuccess ] )

  useEffect( () => {
    if( contentMerchantSettings && ! formInitializedRef.current ) {
      setValue( ContentFormFieldKeys.FREQUENCY, contentMerchantSettings.frequency ?? ContentMerchantSettings.Frequency.MONTHLY )
      setValue( ContentFormFieldKeys.STATUS, contentMerchantSettings.status ?? ContentMerchantSettings.Status.NOT_ENABLED )
      setValue( ContentFormFieldKeys.TIMEFRAME, contentMerchantSettings.timeframe ?? ContentMerchantSettings.Timeframe.MONTHS_3 )
      formInitializedRef.current = true
    }
  }, [ contentMerchantSettings, setValue ] )

  if( ! contentMerchantSettings ) {
    return (
      <Loading />
    )
  }

  const selectedStatus = watch( ContentFormFieldKeys.STATUS )

  return (
    <MerchantDetailsContainer>
      {
        isSaving === true && (
          <LoadingOverlay text="Saving..." />
        )
      }
      <StyledSectionTitle style={ { color: styleHelpers.colors.primary, marginBottom: "20px", textTransform: "uppercase" } }>General</StyledSectionTitle>
      <FormProvider { ...formMethods }>
        <form onSubmit={ handleSubmit( onSubmit ) }>
          <Grid spacing={ 4 }>
            {
              ContentSettingsFields.map( ( { name, label, options } ) => (
                <GridItem key={ name } xs={ 4 }>
                  <Controller
                    key={ name }
                    control={ control }
                    name={ name }
                    render={ ( { field: { onChange, value } } ) => {
                      return (
                        <StyledSelect
                          label={ label }
                          options={ options }
                          onChange={ onChange }
                          placeholder={ label }
                          required={ name === ContentFormFieldKeys.STATUS || selectedStatus === ContentMerchantSettings.Status.LIVE }
                          value={ value }
                        />
                      )
                    } }
                  />
                </GridItem>
              ) )
            }
          </Grid>
          <div style={ { display: "flex", flexDirection: "row", marginTop: "20px" } }>
            <Button type="submit">Save</Button>
          </div>
        </form>
      </FormProvider>
    </MerchantDetailsContainer>
  )
}

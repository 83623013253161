import { isEmpty } from "lodash"
import type { SnakeCasedPropertiesDeep } from "type-fest"

import type { ApiPhoneNumber } from "@onelocal/frontend/common"
import { InternalError, parsePhoneNumberFromApi } from "@onelocal/frontend/common"
import { utilHelpers } from "@onelocal/shared/common"
import type {
  ConnectAssistantConversation,
  ConnectCallBase,
  ConnectCallOutbound,
  ConnectConversation,
  ConnectGenerateMessage,
  ConnectInbox,
  ConnectMerchantSettings,
  ConnectPhoneNumberDetails,
  ConnectUsageStats,
  ConnectUsageStatsSummary,
  ConnectVoiceToken,
  MessagesNotification,
  MessagesReferralInviteSummary,
  MessagesSurveyRequestSummary,
} from "."
import {
  ConnectAssistant,
  ConnectCall,
  ConnectCallInbound,
  ConnectChannel,
  MessagesMessage,
} from "."

// @todo this should be moved to contacts module
export interface ContactsContactFieldValue {
  fieldId: string
  value: string | boolean
}

export type ApiConnectAssistantAudioClip = SnakeCasedPropertiesDeep<ConnectAssistant.AudioClip>

export type ApiConnectAssistant = SnakeCasedPropertiesDeep<ConnectAssistant>

export type ApiConnectAssistantConversation = SnakeCasedPropertiesDeep<ConnectAssistantConversation>

export type ApiConnectAssistantFlow = SnakeCasedPropertiesDeep<ConnectAssistant.Flow>

export type ApiConnectAssistantFlowTemplate = SnakeCasedPropertiesDeep<ConnectAssistant.FlowTemplate>

export type ApiConnectAssistantFlowUpdateModel = SnakeCasedPropertiesDeep<ConnectAssistant.FlowUpdateModel>

export type ApiConnectAssistantUpdateModel = SnakeCasedPropertiesDeep<ConnectAssistant.UpdateModel>

export type ApiConnectChannel = SnakeCasedPropertiesDeep<ConnectChannel>

export type ApiConnectInbox = SnakeCasedPropertiesDeep<ConnectInbox>

export type ApiConnectPhoneToken = SnakeCasedPropertiesDeep<ConnectVoiceToken>

export type ApiMessagesAutoCompleteSuggestion = ApiMessagesAutoCompleteSuggestion.ConversationTagSuggestion | ApiMessagesAutoCompleteSuggestion.CustomerSuggestion

export type ApiMessagesReferralInviteSummary = SnakeCasedPropertiesDeep<MessagesReferralInviteSummary>

export type ApiMessagesSurveyRequestSummary = SnakeCasedPropertiesDeep<MessagesSurveyRequestSummary>

type ApiConnectConversationBase = SnakeCasedPropertiesDeep<ConnectConversation>
type ApiConnectConversationCustomer = ApiConnectConversationBase[ "customer" ]
export type ApiContactsContactFieldValue = SnakeCasedPropertiesDeep<ContactsContactFieldValue>

export type ApiConnectCall = ApiConnectCallInbound | ApiConnectCallOutbound

export namespace ApiConnectCall {
  export interface PlatformTwilio {
    account_id: string
    call_id: string
    status: ConnectCall.PlatformTwilio.StatusType
    type: ConnectCall.PlatformType.TWILIO
  }

  export interface Status {
    at: string
    error_type?: string
    type: ConnectCall.StatusType
  }
}

interface ApiConnectCallBase {
  call_id: string
  channel: ApiConnectChannel
  customer: {
    id: string | null
    name: {
      display: string
      family: string
      given: string
    }
  } | null
  direction: ConnectCall.Direction
  duration: number
  from: string
  id: string
  merchant_id: string
  to: string
  viewed_at?: string
}

export interface ApiConnectCallInbound extends ApiConnectCallBase {
  assistant_conversation?: ApiConnectAssistantConversation
  created: {
    at: string
  }
  direction: ConnectCall.Direction.INBOUND
  planned_steps: ApiConnectCallInbound.Step[]
  stats: {
    is_missed_call: boolean
  }
  steps: ApiConnectCallInbound.Step[]
}

export namespace ApiConnectCallInbound {
  export type Step = StepAssistant | StepForward | StepRing

  export interface StepBase {
    at: string
    id: string
    type: ConnectCallInbound.StepType
  }

  export interface StepAssistant extends StepBase {
    type: ConnectCallInbound.StepType.ASSISTANT | ConnectCallInbound.StepType.ASSISTANT_BUSINESS_HOUR
    assistant_conversation_id: string | null
  }

  export interface StepForward extends StepBase {
    duration?: number
    platform?: ApiConnectCall.PlatformTwilio
    ring_duration: number | null
    status: ApiConnectCall.Status
    type: ConnectCallInbound.StepType.FORWARD | ConnectCallInbound.StepType.FORWARD_BUSINESS_HOUR
    to: string
  }

  export interface StepRing extends StepBase {
    account_ids: string[]
    answered_account?: {
      id: string
      name?: {
        display?: string
        family?: string
        given?: string
      }
    }
    duration?: number
    platform?: ApiConnectCall.PlatformTwilio
    ring_duration: number | null
    status: ApiConnectCall.Status
    type: ConnectCallInbound.StepType.RING_MEMBERS
  }
}

export interface ApiConnectCallOutbound extends ApiConnectCallBase {
  created: {
    at: string
    by: {
      id: string
      ref: "accounts"
    }
  }
  direction: ConnectCall.Direction.OUTBOUND
  dial: {
    duration?: number
    platform?: ApiConnectCall.PlatformTwilio
    status: ApiConnectCall.Status
  } | null
}

export interface ApiConnectConversation extends Omit<ApiConnectConversationBase, "customer"> {
  customer: {
    id: ApiConnectConversationCustomer[ "id" ]
    email: ApiConnectConversationCustomer[ "email" ]
    name: ApiConnectConversationCustomer[ "name" ]
    phone_number: ApiPhoneNumber
  }
  sort_index: {
    last_message_at: string
  }
  text_index: string[]
  timestamp: number
}

export type ApiConnectUsageStats = SnakeCasedPropertiesDeep<ConnectUsageStats>

export function parseConnectUsageStatsFromApi( apiUsageStats: ApiConnectUsageStats ) {
  const usageStats: ConnectUsageStats = {
    date: apiUsageStats.date,
    inboundCallCount: apiUsageStats.inbound_call_count,
    inboundCallSecondsCount: apiUsageStats.inbound_call_seconds_count,
    outboundCallCount: apiUsageStats.outbound_call_count,
    outboundCallSecondsCount: apiUsageStats.outbound_call_seconds_count,
    period: apiUsageStats.period,
  }
  return usageStats
}

export type ApiConnectGenerateMessage = ConnectGenerateMessage

export type ApiConnectUsageStatsSummary = SnakeCasedPropertiesDeep<ConnectUsageStatsSummary>

export function parseConnectUsageStatsSummaryFromApi( apiUsageStatsSummary: ApiConnectUsageStatsSummary ) {
  const usageStatsSummary: ConnectUsageStatsSummary = {
    usageStats: apiUsageStatsSummary.usage_stats.map( parseConnectUsageStatsFromApi ),
  }
  return usageStatsSummary
}

export namespace ApiMessagesAutoCompleteSuggestion {
  export interface ConversationTagSuggestion {
    type: MessagesAutoCompleteSuggestion.Type.CONVERSATION_TAG
    conversation_tag: {
      id: string
      name: string
      color: string
    }
    recipients: ApiMessagesRecipient[]
  }

  export interface CustomerSuggestion extends ApiMessagesRecipient {
    type: MessagesAutoCompleteSuggestion.Type.CUSTOMER
  }
}

export type MessagesAutoCompleteSuggestion = MessagesAutoCompleteSuggestion.ConversationTagSuggestion | MessagesAutoCompleteSuggestion.CustomerSuggestion

export namespace MessagesAutoCompleteSuggestion {
  export enum Type {
    CUSTOMER = "customer",
    CONVERSATION_TAG = "conversation_tag",
  }

  export interface ConversationTagSuggestion {
    type: Type.CONVERSATION_TAG
    conversationTag: MessagesConversationTag
    recipients: MessagesRecipient[]
  }

  export interface CustomerSuggestion extends MessagesRecipient {
    type: Type.CUSTOMER
  }
}

export interface MessagesConversationTag {
  id: string
  color: string
  merchantId?: string
  name: string
}

export interface MessagesRecipient {
  customer?: {
    id: string
    name: {
      display?: string
      family?: string
      given?: string
    }
    contactFields?: ContactsContactFieldValue[]
  }
  phoneNumber: {
    text: string
    value: string
  }
  status?: {
    type: "skip" | "valid"
    detail?: string
  }
}

export type ApiMessagesRecipient = SnakeCasedPropertiesDeep<MessagesRecipient>

export type ApiPhoneNumberDetail = SnakeCasedPropertiesDeep<ConnectPhoneNumberDetails>

export function parseConnectAssistantConversationFromApi( apiConnectAssistantConversation: ApiConnectAssistantConversation ) {
  const connectAssistantConversation: ConnectAssistantConversation = {
    assistant: parseConnectAssistantFromApi( apiConnectAssistantConversation.assistant ),
    callId: apiConnectAssistantConversation.call_id,
    flows: apiConnectAssistantConversation.flows.map( ( apiFlow ) => {
      const flow: ConnectAssistantConversation.Flow = {
        date: apiFlow.date,
        flowId: apiFlow.flow_id,
        inputType: apiFlow.input_type,
        name: apiFlow.name,
        forward: apiFlow.forward,
      }

      return flow
    } ),
    fromPhoneNumber: parseConnectPhoneNumberFromApi( apiConnectAssistantConversation.from_phone_number ),
    id: apiConnectAssistantConversation.id,
    isTesting: apiConnectAssistantConversation.is_testing,
    merchantId: apiConnectAssistantConversation.merchant_id,
    smsMessages: apiConnectAssistantConversation.sms_messages.map( ( apiSmsMessage ) => {
      const smsMessage: ConnectAssistantConversation.SmsMessage = {
        date: apiSmsMessage.date,
        id: apiSmsMessage.id,
        text: apiSmsMessage.text,
        error: apiSmsMessage.error
          ? {
            message: apiSmsMessage.error.message,
            type: apiSmsMessage.error.type,
          }
          : undefined,
        flowId: apiSmsMessage.flow_id,
        messageId: apiSmsMessage.message_id,
      }

      return smsMessage
    } ),
    status: apiConnectAssistantConversation.status,
    voicemail: apiConnectAssistantConversation.voicemail
      ? {
        status: apiConnectAssistantConversation.voicemail.status,
        audioUrl: apiConnectAssistantConversation.voicemail.audio_url,
        transcript: apiConnectAssistantConversation.voicemail.transcript
          ? {
            errorMessage: apiConnectAssistantConversation.voicemail.transcript.error_message,
            result: apiConnectAssistantConversation.voicemail.transcript.result,
            status: apiConnectAssistantConversation.voicemail.transcript.status,
          }
          : undefined,
      }
      : undefined,
  }

  return connectAssistantConversation
}

export function parseConnectCallFromApi( apiConnectCall: ApiConnectCall ): ConnectCall {
  const connectCallBase: ConnectCallBase = {
    id: apiConnectCall.id,
    callId: apiConnectCall.call_id,
    channel: parseConnectChannelFromApi( apiConnectCall.channel ),
    customer: apiConnectCall.customer,
    direction: apiConnectCall.direction,
    duration: apiConnectCall.duration,
    from: apiConnectCall.from,
    merchantId: apiConnectCall.merchant_id,
    to: apiConnectCall.to,
    viewedAt: apiConnectCall.viewed_at ?? null,
  }

  switch( apiConnectCall.direction ) {
    case ConnectCall.Direction.INBOUND: {
      const inboundCall: ConnectCallInbound = {
        ...connectCallBase,
        created: apiConnectCall.created,
        direction: apiConnectCall.direction,
        plannedSteps: apiConnectCall.planned_steps.map( parseConnectCallStepFromApi ),
        stats: {
          isMissedCall: apiConnectCall.stats?.is_missed_call,
        },
        steps: apiConnectCall.steps.map( parseConnectCallStepFromApi ),
      }

      if( apiConnectCall.assistant_conversation ) {
        inboundCall.assistantConversation = parseConnectAssistantConversationFromApi( apiConnectCall.assistant_conversation )
      }

      return inboundCall
    }
    case ConnectCall.Direction.OUTBOUND: {
      const outboundCall: ConnectCallOutbound = {
        ...connectCallBase,
        created: {
          at: apiConnectCall.created.at,
          by: {
            id: apiConnectCall.created.by.id,
            ref: apiConnectCall.created.by.ref,
          },
        },
        dial: ( apiConnectCall.dial
          ? {
            duration: apiConnectCall.dial.duration,
            platform: apiConnectCall.dial.platform ? parseConnectCallPlatformFromApi( apiConnectCall.dial.platform ) : undefined,
            status: parseConnectCallStatusFromApi( apiConnectCall.dial.status ),
          }
          : null
        ),
        direction: apiConnectCall.direction,
      }

      return outboundCall
    }
    default:
      throw new InternalError( `unsupported call type: ${ apiConnectCall }` )
  }
}

function parseConnectCallPlatformFromApi( apiCallPlatform: ApiConnectCall.PlatformTwilio ) {
  const platform: ConnectCall.PlatformTwilio = {
    accountId: apiCallPlatform.account_id,
    callId: apiCallPlatform.call_id,
    status: apiCallPlatform.status,
    type: apiCallPlatform.type,
  }
  return platform
}

function parseConnectCallStepFromApi( apiCallStep: ApiConnectCallInbound.Step ) {
  const stepBase: ConnectCallInbound.StepBase = {
    id: apiCallStep.id,
    at: apiCallStep.at,
    type: apiCallStep.type as ConnectCallInbound.StepType,
  } as ConnectCallInbound.Step

  switch( apiCallStep.type ) {
    case ConnectCallInbound.StepType.ASSISTANT:
    case ConnectCallInbound.StepType.ASSISTANT_BUSINESS_HOUR: {
      const step: ConnectCallInbound.StepAssistant = {
        ...stepBase,
        assistantConversationId: apiCallStep.assistant_conversation_id,
        type: apiCallStep.type,
      }

      return step
    }
    case ConnectCallInbound.StepType.FORWARD:
    case ConnectCallInbound.StepType.FORWARD_BUSINESS_HOUR: {
      const step: ConnectCallInbound.StepForward = {
        ...stepBase,
        duration: apiCallStep.duration,
        platform: apiCallStep.platform ? parseConnectCallPlatformFromApi( apiCallStep.platform ) : undefined,
        ringDuration: apiCallStep.ring_duration || null,
        status: parseConnectCallStatusFromApi( apiCallStep.status ),
        to: apiCallStep.to!,
        type: apiCallStep.type,
      }

      return step
    }
    case ConnectCallInbound.StepType.RING_MEMBERS: {
      const step: ConnectCallInbound.StepRing = {
        ...stepBase,
        accountIds: apiCallStep.account_ids,
        answeredAccount: apiCallStep.answered_account,
        duration: apiCallStep.duration,
        platform: apiCallStep.platform ? parseConnectCallPlatformFromApi( apiCallStep.platform ) : undefined,
        ringDuration: apiCallStep.ring_duration || null,
        status: parseConnectCallStatusFromApi( apiCallStep.status ),
        type: apiCallStep.type,
      }

      return step
    }
    default:
      throw new InternalError( `unsupported call step: ${ apiCallStep }` )
  }
}

function parseConnectCallStatusFromApi( apiCallStatus: ApiConnectCall.Status ) {
  const status: ConnectCall.Status = {
    at: apiCallStatus.at,
    type: apiCallStatus.type,
    errorType: apiCallStatus.error_type,
  }
  return status
}

export const parseConnectAssistantFromApi = ( apiAssistant: ApiConnectAssistant ) => {
  const assistant: ConnectAssistant = {
    acknowledgeFlowChanges: apiAssistant.acknowledge_flow_changes,
    fallback: {
      maxRetries: apiAssistant.fallback.max_retries,
      prompt: {
        audioUrl: apiAssistant.fallback.prompt?.audio_url,
        text: apiAssistant.fallback.prompt?.text,
      },
    },
    flows: apiAssistant.flows.map( ( flow ) => {
      const flowBase: ConnectAssistant.FlowBase = {
        digit: flow.digit,
        enabled: flow.enabled,
        id: flow.id,
        name: flow.name,
        nextAction: {
          type: flow.next_action.type,
        },
        phrase: flow.phrase,
      }
      switch( flow.type ) {
        case ConnectAssistant.FlowType.ANSWER: {
          return {
            ...flowBase,
            answer: {
              audioUrl: flow.answer.audio_url,
              text: flow.answer.text,
            },
            type: flow.type,
          } as ConnectAssistant.FlowAnswer
        }
        case ConnectAssistant.FlowType.FORWARD: {
          return {
            ...flowBase,
            answer: {
              audioUrl: flow.answer?.audio_url,
              text: flow.answer?.text,
            },
            toNumber: flow.to_number,
            type: flow.type,
          } as ConnectAssistant.FlowForward
        }
        case ConnectAssistant.FlowType.SEND_SMS: {
          const sms_flow: ConnectAssistant.FlowSendSms = {
            ...flowBase,
            confirmation: {
              audioUrl: flow.confirmation?.audio_url,
              text: flow.confirmation?.text,
            },
            smsText: flow.sms_text,
            type: flow.type,
          }

          if( flow.prompt?.audio_url || flow.prompt?.text ) {
            sms_flow.prompt = {
              audioUrl: flow.prompt?.audio_url,
              text: flow.prompt?.text,
            }
          }

          return sms_flow
        }
        default: {
          throw new InternalError( "Unsupported flow type" )
        }
      }
    } ),
    emails: apiAssistant.emails,
    greeting: {
      audioUrl: apiAssistant.greeting.audio_url,
      text: apiAssistant.greeting.text,
    },
    id: apiAssistant.id,
    merchantId: apiAssistant.merchant_id,
    phoneNumberId: apiAssistant.phone_number_id,
    /** @todo - make it required when #9984 is merged */
    prompt: ( ! isEmpty( apiAssistant.prompt )
      ? {
        audioUrl: apiAssistant.prompt?.audio_url,
        text: apiAssistant.prompt?.text,
      }
      : undefined
    ),
    voice: apiAssistant.voice,
    voicemail: {
      audioUrl: apiAssistant.voicemail.audio_url,
      text: apiAssistant.voicemail.text,
    },
    voicemailPhrase: apiAssistant.voicemail_phrase,
    whitelist: apiAssistant.whitelist,
  }
  return assistant
}

export const parseConnectConversationFromApi = ( apiConversation: ApiConnectConversation ) => {
  const conversation: ConnectConversation = {
    id: apiConversation.id,
    assignee: apiConversation.assignee,
    blockInbound: apiConversation.block_inbound,
    blockOutbound: apiConversation.block_outbound,
    channelId: apiConversation.channel_id,
    channelType: apiConversation.channel_type,
    created: apiConversation.created,
    customer: {
      // contactFields: apiConversation.customer.contact_fields?.map( parseContactsFieldValueFromApi ) || [],
      email: apiConversation.customer.email,
      id: apiConversation.customer.id,
      name: apiConversation.customer.name,
      phoneNumber: {
        countryCallingCode: apiConversation.customer.phone_number.country_calling_code,
        countryCode: apiConversation.customer.phone_number.country_code,
        internationalFormat: apiConversation.customer.phone_number.international_format,
        nationalFormat: apiConversation.customer.phone_number.national_format,
        type: apiConversation.customer.phone_number.type,
        value: apiConversation.customer.phone_number.value,
      },
    },
    matchedCustomersCount: apiConversation.matched_customers_count,
    merchantId: apiConversation.merchant_id,
    muted: apiConversation.muted,
    readtime: apiConversation.readtime,
    stats: {
      customer: {
        lastMessageAt: apiConversation.stats.customer.last_message_at,
        unreadCount: apiConversation.stats.customer.unread_count,
        unrepliedCount: apiConversation.stats.customer.unreplied_count,
      },
      hasMessages: apiConversation.stats.has_messages,
      merchant: {
        lastMessageAt: apiConversation.stats.merchant.last_message_at,
        unreadCount: apiConversation.stats.merchant.unread_count,
        unrepliedCount: apiConversation.stats.merchant.unreplied_count,
      },
    },
    status: apiConversation.status,
    tagIds: apiConversation.tag_ids || [],
    type: apiConversation.type,
  }

  // if( apiConversation.last_message ) {
  //   conversation.lastMessage = parseMessagesMessageFromApi( apiConversation.last_message ) as MessagesMessage.MessageText
  // }

  return conversation
}

export const parseConnectChannelFromApi = ( apiConnectChannel: ApiConnectChannel ) => {
  const channel: ConnectChannel = {
    allowInternationalTexting: apiConnectChannel.allow_international_texting,
    autoresponder: {
      missedCall: apiConnectChannel.autoresponder?.missed_call,
      missedCallAfterHour: apiConnectChannel.autoresponder?.missed_call_after_hour,
      sms: apiConnectChannel.autoresponder?.sms,
      smsAfterHour: apiConnectChannel.autoresponder?.sms_after_hour,
      widget: apiConnectChannel.autoresponder?.widget,
      widgetAfterHour: apiConnectChannel.autoresponder?.widget_after_hour,
    },
    blockVoip: apiConnectChannel.block_voip,
    businessHours: apiConnectChannel.business_hours,
    id: apiConnectChannel.id,
    incomingCallSteps: apiConnectChannel.incoming_call_steps?.map( ( incomingStep ) => {
      switch( incomingStep.type ) {
        case ConnectChannel.IncomingCallStepType.BUSINESS_HOURS: {
          const step: ConnectChannel.IncomingCallStepBusinessHours = {
            enabled: incomingStep.enabled,
            id: incomingStep.id,
            type: incomingStep.type,
          }

          if( incomingStep.action?.type === ConnectChannel.BusinessHourActionType.ASSISTANT ) {
            step.action = {
              type: ConnectChannel.BusinessHourActionType.ASSISTANT,
            }
          } else if( incomingStep.action?.type === ConnectChannel.BusinessHourActionType.FORWARD ) {
            step.action = {
              type: ConnectChannel.BusinessHourActionType.FORWARD,
              phoneNumber: parseConnectPhoneNumberFromApi( incomingStep.action.phone_number as ApiPhoneNumberDetail ),
              ringDuration: incomingStep.action.ring_duration,
            }
          }

          return step
        }
        case ConnectChannel.IncomingCallStepType.CALL_FORWARDING: {
          const step: ConnectChannel.IncomingCallStepCallForwarding = {
            enabled: incomingStep.enabled || false,
            id: incomingStep.id,
            type: incomingStep.type,
          }

          if( ! isEmpty( incomingStep.phone_number ) ) {
            step.phoneNumber = parseConnectPhoneNumberFromApi( incomingStep.phone_number as ApiPhoneNumberDetail )
          }

          if( incomingStep.ring_duration !== undefined ) {
            step.ringDuration = incomingStep.ring_duration
          }

          return step
        }
        default:
          return {
            enabled: incomingStep.enabled,
            id: incomingStep.id,
            type: incomingStep.type,
            steps: incomingStep.steps?.map( ( ring_step_doc ) => ( {
              accountIds: ring_step_doc.account_ids,
              ringDuration: ring_step_doc.ring_duration,
            } ) ),
          } as ConnectChannel.IncomingCallStepRing
      }
    } ),
    merchantId: apiConnectChannel.merchant_id,
    phoneNumber: apiConnectChannel.phone_number ? parseConnectPhoneNumberFromApi( apiConnectChannel.phone_number ) : undefined,
    phoneNumberId: apiConnectChannel.phone_number_id,
    type: apiConnectChannel.type as ConnectChannel.Type,
  }
  return channel
}

export const parseConnectInboxFromApi = ( apiConnectInbox: ApiConnectInbox ) => {
  const inbox: ConnectInbox = {
    id: apiConnectInbox.id,
    closedCount: apiConnectInbox.closed_count,
    name: apiConnectInbox.name,
    openedCount: apiConnectInbox.opened_count,
    totalCount: apiConnectInbox.total_count,
    unreadCount: apiConnectInbox.unread_count,
    unrepliedCount: apiConnectInbox.unreplied_count,
  }

  return inbox
}
export interface ApiConnectMerchantSettings {
  features: Record<ConnectMerchantSettings.Features, boolean>
}

export function parseConnectMerchantSettingsFromApi( apiConnectMerchantSettings: ApiConnectMerchantSettings ) {
  const connectMerchantSettings: ConnectMerchantSettings = {
    features: apiConnectMerchantSettings.features,
  }

  return connectMerchantSettings
}

export interface ApiChannel {
  allow_international_texting?: boolean
  autoresponder: ApiChannel.AutoResponder
  block_voip?: boolean
  business_hours: ConnectChannel.BusinessHourPeriod[]
  id: string
  incoming_call_steps: Array<{
    action?: {
      type: string
      phone_number: ApiPhoneNumberDetail
      ring_duration?: number | null
    }
    enabled: boolean
    id: string
    type: "business_hours" | "call_forwarding" | "ring"
    phone_number?: ApiPhoneNumberDetail
    ring_duration?: number | null
    steps: Array<{
      account_ids: string[]
      ring_duration: number | null
    }>
  }>
  merchant_id: string
  phone_number_id: string
  type: string
}

export namespace ApiChannel {
  export interface AutoResponder {
    missed_call?: string
    missed_call_after_hour?: string
    sms?: string
    sms_after_hour?: string
    widget?: string
    widget_after_hour?: string
  }
}

const parseConnectPhoneNumberFromApi = ( phoneNumber: ApiPhoneNumberDetail ): ConnectPhoneNumberDetails => {
  return {
    carrierName: phoneNumber.carrier_name,
    countryCallingCode: phoneNumber.country_calling_code,
    countryCode: phoneNumber.country_code,
    internationalFormat: phoneNumber.international_format,
    mobileCountryCode: phoneNumber.mobile_country_code,
    mobileNetworkCode: phoneNumber.mobile_network_code,
    nationalFormat: phoneNumber.national_format,
    type: phoneNumber.type,
    value: phoneNumber.value,
  }
}

export const parseConnectVoiceTokenFromApi = ( apiConnectVoiceToken: ApiConnectPhoneToken ): ConnectVoiceToken => {
  const voiceToken: ConnectVoiceToken = {
    expireAt: apiConnectVoiceToken.expire_at,
    value: apiConnectVoiceToken.value,
  }

  return voiceToken
}

// @todo This should be moved to contacts module once it is setup
export function parseContactsFieldValueFromApi( apiContactFieldValue: ApiContactsContactFieldValue ) {
  const contactFieldValue: ContactsContactFieldValue = {
    fieldId: apiContactFieldValue.field_id,
    value: apiContactFieldValue.value,
  }

  return contactFieldValue
}

export function parseMessagesAutocompleteSuggestionFromApi( apiMessagesAutocompleteSuggestion: ApiMessagesAutoCompleteSuggestion ) {
  switch( apiMessagesAutocompleteSuggestion.type ) {
    case MessagesAutoCompleteSuggestion.Type.CONVERSATION_TAG: {
      const tagSuggestion: MessagesAutoCompleteSuggestion.ConversationTagSuggestion = {
        conversationTag: apiMessagesAutocompleteSuggestion.conversation_tag,
        recipients: apiMessagesAutocompleteSuggestion.recipients.map( parseMessagesRecipientFromApi ),
        type: MessagesAutoCompleteSuggestion.Type.CONVERSATION_TAG,
      }
      return tagSuggestion
    }
    case MessagesAutoCompleteSuggestion.Type.CUSTOMER: {
      const customerSuggestion: MessagesAutoCompleteSuggestion.CustomerSuggestion = {
        type: MessagesAutoCompleteSuggestion.Type.CUSTOMER,
        ...parseMessagesRecipientFromApi( apiMessagesAutocompleteSuggestion ),
      }
      return customerSuggestion
    }
  }
}

export function parseMessagesRecipientFromApi( apiMessagesRecipient: ApiMessagesRecipient ) {
  const recipient: MessagesRecipient = {
    phoneNumber: {
      text: apiMessagesRecipient.phone_number.text,
      value: apiMessagesRecipient.phone_number.value,
    },
  }

  if( apiMessagesRecipient.customer ) {
    recipient.customer = {
      id: apiMessagesRecipient.customer.id,
      name: apiMessagesRecipient.customer.name,
    }

    if( apiMessagesRecipient.customer.contact_fields ) {
      recipient.customer.contactFields = apiMessagesRecipient.customer.contact_fields.map( parseContactsFieldValueFromApi )
    }
  }

  if( apiMessagesRecipient.status ) {
    recipient.status = {
      type: apiMessagesRecipient.status.type,
      detail: apiMessagesRecipient.status.detail,
    }
  }

  return recipient
}

export function parseAssistantFlowTemplateFromApi( apiFlowTemplates: ApiConnectAssistantFlowTemplate[] ) {
  const flowTemplates = apiFlowTemplates.map( ( template ) => {
    return {
      answer: template.answer,
      confirmation: template.confirmation,
      enabled: template.enabled,
      id: template.id,
      name: template.name,
      nextAction: template.next_action,
      phrase: template.phrase,
      prompt: template.prompt,
      smsText: template.sms_text,
      type: template.type,
    } as ConnectAssistant.FlowTemplate
  } )

  return flowTemplates
}

export type ApiMessagesMessage = (
  ApiMessagesMessage.MessageAction
| ApiMessagesMessage.MessageAssign
| ApiMessagesMessage.MessageCall
| ApiMessagesMessage.MessageConnectCall
| ApiMessagesMessage.MessageCustomerChanged
| ApiMessagesMessage.MessageNote
| ApiMessagesMessage.MessagePayment
| ApiMessagesMessage.MessageReferralInviteSent
| ApiMessagesMessage.MessageSurveyRequestPreviewSent
| ApiMessagesMessage.MessageSurveyRequestSent
| ApiMessagesMessage.MessageSurveyRequestResent
| ApiMessagesMessage.MessageTag
| ApiMessagesMessage.MessageText
| ApiMessagesMessage.MessageTransferDestination
| ApiMessagesMessage.MessageTransferOrigin
| ApiMessagesMessage.MessageVisit
)

export namespace ApiMessagesMessage {
  export interface MessageAction extends MessageBase {
    type: (
        MessagesMessage.Type.BLOCK_INBOUND
      | MessagesMessage.Type.BLOCK_OUTBOUND
      | MessagesMessage.Type.CLOSE
      | MessagesMessage.Type.MUTE
      | MessagesMessage.Type.OPEN
      // | MessagesMessage.Type.OPT_IN
      // | MessagesMessage.Type.OPT_OUT
      | MessagesMessage.Type.UNBLOCK_INBOUND
      | MessagesMessage.Type.UNBLOCK_OUTBOUND
      | MessagesMessage.Type.UNMUTE
    )
  }

  export interface MessageAssign extends MessageBase {
    type: MessagesMessage.Type.ASSIGN
    new_assignee: {
      ref: "employees"
      id: string
      name: string
    } | null
    old_assignee: {
      ref: "employees"
      id: string
    } | null
  }
  export interface MessageBase {
    id: string
    merchant_id: string
    channel_id: string
    conversation_id: string
    origin_merchant?: {
      id: string
      name: string
    }
    conversation?: {
      block_inbound: boolean
      block_outbound: boolean
      customer: {
        email: string
        id: string
        name: {
          given: string
          family: string
        }
        phone_number: ApiPhoneNumber
      }
      muted: boolean
      status: ConnectConversation.Status
    }
    type: MessagesMessage.Type
    customer_ids: string[]
    // bulk_message_id?: string
    // bulk_message_recipient_id?: string
    created?: {
      at: string
      by?: {
        id: string
        ref: "employees" | "merchants" | "customers"
        name?: string
        api_v?: number
      }
      source?: "autoresponder" | "bulk" | "opt_in" | "opt_out"
    }
    date: string
    timestamp: number
  }

  export interface MessageCall extends MessageBase {
    type: MessagesMessage.Type.CALL
    duration?: number
  }

  export interface MessageConnectCall extends MessageBase {
    type: MessagesMessage.Type.CALL_IN | MessagesMessage.Type.CALL_OUT
    connect_call: ApiConnectCall
  }

  export interface MessageCustomerChanged extends MessageBase {
    type: MessagesMessage.Type.CUSTOMER_CHANGED
    old_customer?: {
      id: string
      name: {
        given: string
        family: string
      }
    }
    new_customer?: {
      id: string
      name: {
        given: string
        family: string
      }
    }
  }

  export interface MessageMedia {
    preview_data: string
    small: MessageMediaStyle
    medium: MessageMediaStyle
    original: MessageMediaStyle
  }

  export interface MessageMediaStyle {
    size: {
      height: number
      width: number
    }
    url: string
  }

  export interface MessageNote extends MessageBase {
    type: MessagesMessage.Type.NOTE
    text: string
  }

  export interface MessagePayment extends MessageBase {
    type: MessagesMessage.Type.PAYMENT_LINK_SENT | MessagesMessage.Type.PAYMENT_RECEIPT_LINK_SENT
    text: string
    payment_id: string
  }

  export interface MessageReferralInviteSent extends MessageBase {
    type: MessagesMessage.Type.REFERRAL_INVITE_SENT
    text?: string
    referral_invite: ApiMessagesReferralInviteSummary
  }

  export interface MessageSurveyRequestPreviewSent extends MessageBase {
    type: MessagesMessage.Type.SURVEY_REQUEST_PREVIEW_SENT
    is_reminder: boolean
    original_text?: string
    text?: string
  }

  export interface MessageSurveyRequestSent extends MessageBase {
    type: MessagesMessage.Type.SURVEY_REQUEST_SENT | MessagesMessage.Type.SURVEY_REQUEST_REMINDER_SENT
    medias?: MessageMedia[]
    text?: string
    survey_request: ApiMessagesSurveyRequestSummary
  }

  export interface MessageSurveyRequestResent extends MessageBase {
    type: MessagesMessage.Type.SURVEY_REQUEST_RESENT
    original_text?: string
    text?: string
  }

  export interface MessageTag extends MessageBase {
    type: MessagesMessage.Type.TAG_ADDED | MessagesMessage.Type.TAG_REMOVED
    conversation_tag_id: string
  }

  export interface MessageText extends MessageBase {
    type: MessagesMessage.Type.MESSAGE_OUT | MessagesMessage.Type.MESSAGE_OUT_AUTOMATED | MessagesMessage.Type.MESSAGE_IN | MessagesMessage.Type.SCHEDULED_MESSAGE
    error?: {
      code: string
      message: string
    }
    medias: MessageMedia[]
    platform?: MessageText.OneLocalPlatform | MessageText.TwilioPlatform
    status: {
      type: "queued" | "sent" | "delivered" | "error" | "unknown"
      error_type?: "invalid_number" | "unavailable" | "violation"
      detail?: string
    }
    text: string
  }

  export namespace MessageText {
    export interface TwilioPlatform {
      type: "twilio"
      segment_count: number
      status: "accepted" | "queued" | "sending" | "sent,failed" | "delivered" | "undelivered" | "receiving" | "received"
      message_id: string
    }

    export interface OneLocalPlatform {
      type: "onelocal"
      source: "widget"
      location?: {
        text: string
      }
    }
  }

  export interface MessageTransferDestination extends MessageBase {
    type: MessagesMessage.Type.TRANSFER_DESTINATION
    origin_conversation_id: string
    origin_merchant: {
      id: string
      name: string
    }
  }

  export interface MessageTransferOrigin extends MessageBase {
    type: MessagesMessage.Type.TRANSFER_ORIGIN
    destination_conversation_id: string
    destination_merchant: {
      id: string
      name: string
    }
  }

  export interface MessageVisit extends MessageBase {
    type: MessagesMessage.Type.VISIT_INVITE_SENT | MessagesMessage.Type.VISIT_NOTIFIED
    text: string
    visit_id: string
  }
}

export function parseMessagesSurveyRequestSummaryFromApi( apiMessagesSurveyRequestSummary: ApiMessagesSurveyRequestSummary ) {
  const messagesSurveyRequestSummary: MessagesSurveyRequestSummary = {
    completedAt: apiMessagesSurveyRequestSummary.completed_at,
    id: apiMessagesSurveyRequestSummary.id,
    responseId: apiMessagesSurveyRequestSummary.response_id,
    sentAt: apiMessagesSurveyRequestSummary.sent_at,
    sentiment: apiMessagesSurveyRequestSummary.sentiment,
    templateName: apiMessagesSurveyRequestSummary.template_name,
  }

  return messagesSurveyRequestSummary
}

export const parseMessagesMessageFromApi = ( apiMessage: ApiMessagesMessage ) => {
  const messageBase: MessagesMessage.MessageBase = {
    channelId: apiMessage.channel_id,
    conversationId: apiMessage.conversation_id,
    customerIds: apiMessage.customer_ids,
    date: apiMessage.date,
    id: apiMessage.id,
    merchantId: apiMessage.merchant_id,
    type: apiMessage.type as MessagesMessage.Type,
  // Optional
  // bulkMessageId: apiMessage.bulk_message_id,
  // bulkMessageRecipientId: apiMessage.bulk_message_recipient_id,
  // conversation: apiMessage
  // created: {
  //   at: apiMessage.created?.at,
  // },
  }

  if( apiMessage.created ) {
    messageBase.created = {
      at: apiMessage.created.at,
      source: apiMessage.created.source,
    }

    if( apiMessage.created.by ) {
      messageBase.created.by = {
        id: apiMessage.created.by.id,
        ref: apiMessage.created.by.ref,
        apiV: apiMessage.created.by.api_v,
        name: apiMessage.created.by.name,
      }
    }
  }

  if( apiMessage.conversation ) {
    messageBase.conversation = {
      blockInbound: apiMessage.conversation.block_inbound,
      blockOutbound: apiMessage.conversation.block_outbound,
      customer: {
        email: apiMessage.conversation.customer.email,
        id: apiMessage.conversation.customer.id,
        name: apiMessage.conversation.customer.name,
        phoneNumber: parsePhoneNumberFromApi( apiMessage.conversation.customer.phone_number ),
      },
      muted: apiMessage.conversation.muted,
      status: apiMessage.conversation.status,
    }
  }

  switch( apiMessage.type ) {
    case MessagesMessage.Type.BLOCK_INBOUND:
    case MessagesMessage.Type.BLOCK_OUTBOUND:
    case MessagesMessage.Type.CLOSE:
    case MessagesMessage.Type.MUTE:
    case MessagesMessage.Type.OPEN:
    case MessagesMessage.Type.UNBLOCK_INBOUND:
    case MessagesMessage.Type.UNBLOCK_OUTBOUND:
    case MessagesMessage.Type.UNMUTE:{
      const actionMessage: MessagesMessage.MessageAction = {
        ...messageBase,
        type: apiMessage.type,
      }

      return actionMessage
    }
    case MessagesMessage.Type.MESSAGE_IN:
    case MessagesMessage.Type.MESSAGE_OUT:
    case MessagesMessage.Type.MESSAGE_OUT_AUTOMATED:
    case MessagesMessage.Type.SCHEDULED_MESSAGE: {
      const textMessage: MessagesMessage.MessageText = {
        ...messageBase,
        medias: apiMessage.medias?.map( parseMessagesMediaFromApi ),
        status: ! isEmpty( apiMessage.status ) ? {
          type: apiMessage.status!.type,
          detail: apiMessage.status!.detail,
          errorType: apiMessage.status!.error_type,
        } : null,
        text: apiMessage.text,
        type: apiMessage.type,
        error: apiMessage.error,
      }

      switch( apiMessage.platform?.type ) {
        case "onelocal":
          textMessage.platform = {
            type: "onelocal",
            source: apiMessage.platform.source,
            location: apiMessage.platform.location,
          }
          break
        case "twilio":
          textMessage.platform = {
            type: "twilio",
            messageId: apiMessage.platform.message_id,
            segmentCount: apiMessage.platform.segment_count,
            status: apiMessage.platform.status,
          }
          break
      }

      return textMessage
    }
    case MessagesMessage.Type.PAYMENT_LINK_SENT:
    case MessagesMessage.Type.PAYMENT_RECEIPT_LINK_SENT: {
      const paymentMessage: MessagesMessage.MessagePayment = {
        ...messageBase,
        paymentId: apiMessage.payment_id,
        text: apiMessage.text,
        type: apiMessage.type,
      }

      return paymentMessage
    }
    case MessagesMessage.Type.VISIT_INVITE_SENT:
    case MessagesMessage.Type.VISIT_NOTIFIED: {
      const visitMessage: MessagesMessage.MessageVisit = {
        ...messageBase,
        visitId: apiMessage.visit_id,
        text: apiMessage.text,
        type: apiMessage.type,
      }

      return visitMessage
    }
    case MessagesMessage.Type.SURVEY_REQUEST_PREVIEW_SENT: {
      const surveyRequestPreviewSentMessage: MessagesMessage.MessageSurveyRequestPreviewSent = {
        ...messageBase,
        isReminder: apiMessage.is_reminder,
        originalText: apiMessage.original_text,
        text: apiMessage.text,
        type: apiMessage.type,
      }

      return surveyRequestPreviewSentMessage
    }
    case MessagesMessage.Type.SURVEY_REQUEST_SENT:
    case MessagesMessage.Type.SURVEY_REQUEST_REMINDER_SENT: {
      const surveyRequestSentMessage: MessagesMessage.MessageSurveyRequestSent = {
        ...messageBase,
        medias: apiMessage.medias?.map( parseMessagesMediaFromApi ),
        surveyRequest: parseMessagesSurveyRequestSummaryFromApi( apiMessage.survey_request ),
        text: apiMessage.text,
        type: apiMessage.type,
      }

      return surveyRequestSentMessage
    }
    case MessagesMessage.Type.SURVEY_REQUEST_RESENT: {
      const surveyRequestResentMessage: MessagesMessage.MessageSurveyRequestResent = {
        ...messageBase,
        originalText: apiMessage.original_text,
        text: apiMessage.text,
        type: apiMessage.type,
      }

      return surveyRequestResentMessage
    }
    case MessagesMessage.Type.NOTE: {
      const noteMessage: MessagesMessage.MessageNote = {
        ...messageBase,
        text: apiMessage.text,
        type: apiMessage.type,
      }

      return noteMessage
    }
    case MessagesMessage.Type.REFERRAL_INVITE_SENT: {
      const referralInviteMessage: MessagesMessage.MessageReferralInviteSent = {
        ...messageBase,
        referralInvite: apiMessage.referral_invite,
        text: apiMessage.text,
        type: apiMessage.type,
      }

      return referralInviteMessage
    }
    case MessagesMessage.Type.CALL: {
      const callMessage: MessagesMessage.MessageCall = {
        ...messageBase,
        duration: apiMessage.duration,
        type: apiMessage.type,
      }

      return callMessage
    }
    case MessagesMessage.Type.CUSTOMER_CHANGED: {
      const customerChangedMessage: MessagesMessage.MessageCustomerChanged = {
        ...messageBase,
        newCustomer: apiMessage.new_customer,
        oldCustomer: apiMessage.old_customer,
        type: apiMessage.type,
      }

      return customerChangedMessage
    }
    case MessagesMessage.Type.ASSIGN: {
      const assignMessage: MessagesMessage.MessageAssign = {
        ...messageBase,
        newAssignee: apiMessage.new_assignee,
        oldAssignee: apiMessage.old_assignee,
        type: apiMessage.type,
      }

      return assignMessage
    }
    case MessagesMessage.Type.CALL_IN:
    case MessagesMessage.Type.CALL_OUT: {
      const connectCallMessage: MessagesMessage.MessageConnectCall = {
        ...messageBase,
        connectCall: parseConnectCallFromApi( apiMessage.connect_call ),
        type: apiMessage.type,
      }

      return connectCallMessage
    }
    case MessagesMessage.Type.TRANSFER_DESTINATION: {
      const transferDestinationMessage: MessagesMessage.MessageTransferDestination = {
        ...messageBase,
        originConversationId: apiMessage.origin_conversation_id,
        originMerchant: apiMessage.origin_merchant,
        type: apiMessage.type,
      }

      return transferDestinationMessage
    }
    case MessagesMessage.Type.TRANSFER_ORIGIN: {
      const transferOriginMessage: MessagesMessage.MessageTransferOrigin = {
        ...messageBase,
        destinationConversationId: apiMessage.destination_conversation_id,
        destinationMerchant: apiMessage.destination_merchant,
        type: apiMessage.type,
      }

      return transferOriginMessage
    }
    case MessagesMessage.Type.TAG_ADDED:
    case MessagesMessage.Type.TAG_REMOVED: {
      const tagMessage: MessagesMessage.MessageTag = {
        ...messageBase,
        conversationTagId: apiMessage.conversation_tag_id,
        type: apiMessage.type,
      }

      return tagMessage
    }
    default:
      utilHelpers.assertNever( apiMessage )
      throw new Error( `Unsupported type ${ apiMessage }` )
  }
}

export function parseMessagesMediaFromApi( apiMessageMedia: ApiMessagesMessage.MessageMedia ) {
  const messageMedia: MessagesMessage.MessageMedia = {
    previewData: apiMessageMedia.preview_data,
    medium: apiMessageMedia.medium,
    original: apiMessageMedia.original,
    small: apiMessageMedia.small,
  }

  return messageMedia
}

export interface ApiMessagesNotification {
  conversation?: ApiConnectConversation
  conversation_id?: string
  messages?: ApiMessagesMessage[]
  id: string
  created: {
    at: string
  }
  employee_ids: string[]
  merchant_id: string
  mobile_account_ids?: string[]
  mobile?: {
    body: string
    title: string
  }
  new_message_ids: string[]
  removed_message_ids: string[]
  text: string
  type: "messenger_conversation_updated"
  updated_message_ids: string[]
}

export function parseMessagesNotificationFromApi( apiNotification: ApiMessagesNotification ) {
  const notification: MessagesNotification = {
    created: apiNotification.created,
    conversationId: apiNotification.conversation_id,
    employeeIds: apiNotification.employee_ids,
    id: apiNotification.id,
    merchantId: apiNotification.merchant_id,
    mobileAccountIds: apiNotification.mobile_account_ids || [],
    mobile: apiNotification.mobile,
    newMessageIds: apiNotification.new_message_ids,
    removedMessageIds: apiNotification.removed_message_ids,
    text: apiNotification.text,
    updatedMessageIds: apiNotification.updated_message_ids,
  }

  if( apiNotification.conversation ) {
    notification.conversation = parseConnectConversationFromApi( apiNotification.conversation )
  }

  if( apiNotification.messages ) {
    notification.messages = apiNotification.messages?.map( parseMessagesMessageFromApi ).filter( ( message ) => message != null ) as MessagesMessage[]
  }

  return notification
}

import { matchPath } from "react-router"

import { DashboardRoutePath } from "../types"

const PATHS: DashboardRoutePath[] = Object.values( DashboardRoutePath )

export const doesRouteExist = ( path: string ): boolean => {
  for( const adminPath of PATHS ) {
    const match = matchPath( { path: adminPath, end: true, caseSensitive: false }, path )
    if( match ) {
      return true
    }
  }
  return false
}

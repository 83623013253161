// eslint-disable-next-line @nx/enforce-module-boundaries
import type { AdsMerchantSettings } from "@onelocal/frontend-dashboard/ads"
// eslint-disable-next-line @nx/enforce-module-boundaries
import type { ConnectMerchantSettings } from "@onelocal/frontend-dashboard/connect"
// eslint-disable-next-line @nx/enforce-module-boundaries
import type { ContentMerchantSettings } from "@onelocal/frontend-dashboard/content"
// eslint-disable-next-line @nx/enforce-module-boundaries
import type { MessagesMerchantsSettings } from "@onelocal/frontend-dashboard/messages"
// eslint-disable-next-line @nx/enforce-module-boundaries
import type { ReferralsMerchantSettings } from "@onelocal/frontend-dashboard/referrals"
// eslint-disable-next-line @nx/enforce-module-boundaries
import type { ResponseMerchantSettings } from "@onelocal/frontend-dashboard/response"
// eslint-disable-next-line @nx/enforce-module-boundaries
import type { ReviewsMerchantSettings } from "@onelocal/frontend-dashboard/reviews"
// eslint-disable-next-line @nx/enforce-module-boundaries
import type { SeoMerchantSettings } from "@onelocal/frontend-dashboard/seo"
// eslint-disable-next-line @nx/enforce-module-boundaries
import type { SiteMerchantSettings } from "@onelocal/frontend-dashboard/site"
// eslint-disable-next-line @nx/enforce-module-boundaries
import type { VisitsMerchantSettings } from "@onelocal/frontend-dashboard/visits"

export * from "./api"

export interface MerchantContext {
  merchantSettings: MerchantSettings
  isReviewsEnabled: boolean
  isMessagesEnabled: boolean
}

export interface MerchantSettings {
  ads: AdsMerchantSettings
  connect: ConnectMerchantSettings
  connectedAccounts: SettingsConnectedAccount[]
  content?: ContentMerchantSettings
  country?: string
  created: {
    at: string | null
  }
  csOwner?: {
    id: string
    name: string
    avatarUrl: string
  }
  firstImpression?: SiteMerchantSettings
  flagBillingIssue: boolean
  flags: {
    agencyAnalytics: boolean
    churn: boolean
    demo: boolean
    onboarding: boolean
    partner: boolean
  }
  isAgency: boolean
  isAgencyLocation: boolean
  isParent: boolean
  linkedSite?: {
    created: {
      at: string
    }
    id: string
    dudaId: string
    merchantId: string
  }
  locationNickname?: string
  merchantId: string
  messages: MessagesMerchantsSettings
  name: string
  platformVersion: string
  referrals: ReferralsMerchantSettings
  response?: ResponseMerchantSettings
  reviews: ReviewsMerchantSettings
  rootMerchantId: string
  seo: SeoMerchantSettings
  site: SiteMerchantSettings
  slug: string
  testing: boolean
  timezone: string
  visits?: VisitsMerchantSettings
}

export namespace MerchantSettings {
  export enum ProductStatus {
    ENABLED = "enabled",
    LIVE = "live",
    TRIAL = "trial",
  }
}

export const enum SettingsConnectedAccountType {
  GOOGLE_ANALYTICS = "google_analytics",
  GOOGLE = "google",
  FACEBOOK = "facebook",
  FACEBOOK_ADS = "facebook_ads",
  FRESHBOOKS = "freshbooks",
  STRIPE_CONNECT = "stripe_connect",
  YELP = "yelp",
  YELLOW_PAGES = "yellow_pages",
  INSTAGRAM = "instagram",
}

export type SettingsConnectedAccount = (
  SettingsConnectedAccountFacebookAds
  | SettingsConnectedAccountFreshbooks
  | SettingsConnectedAccountGoogleAnalytics
  | SettingsConnectedAccountStripeConnect
  | SettingsConnectedAccountInstagram
)

interface SettingsConnectedAccountBase {
  id: string
  type: SettingsConnectedAccountType
  created: {
    at: string
    by: {
      ref: "employees" | "merchants"
      id: string
    }
  }
  externalAccountId: string
  externalAccount: {
    id: string
    errorCount?: number
    hasAuthError?: boolean
  }
}

export interface SettingsConnectedAccountFacebookAds extends SettingsConnectedAccountBase {
  type: SettingsConnectedAccountType.FACEBOOK_ADS
  name: string
  facebookAdsAccountId: string
}

export interface SettingsConnectedAccountFreshbooks extends SettingsConnectedAccountBase {
  type: SettingsConnectedAccountType.FRESHBOOKS
  employeeId: string
  surveyTemplateId: string
}

export interface SettingsConnectedAccountGoogleAnalytics extends SettingsConnectedAccountBase {
  type: SettingsConnectedAccountType.GOOGLE_ANALYTICS
  name: string
  googleAnalyticsAccountId: string
  googleAnalyticsViewProfileId: string
  googleAnalyticsWebPropertyId: string
  webProperty: SettingsGoogleAnalyticsAccountSummary.WebPropertySummary
  webProperties: SettingsGoogleAnalyticsAccountSummary.WebPropertySummary[]
  viewProfile: SettingsGoogleAnalyticsAccountSummary.ViewProfile
}

export interface SettingsConnectedAccountInstagram extends SettingsConnectedAccountBase {
  type: SettingsConnectedAccountType.INSTAGRAM
  name: string
  facebook_account_id: string
  external_account_id: string
  connected_account_id?: string
}

export interface SettingsConnectedAccountStripeConnect extends SettingsConnectedAccountBase {
  type: SettingsConnectedAccountType.STRIPE_CONNECT
}

export interface SettingsFacebookAdsAccount {
  id: string
  name: string
}

export interface SettingsFacebookLocation {
  address?: string
  id: string
  name: string
}

export interface SettingsGoogleBusinessLocation {
  id: string
  name: string
  address: string
  placeId?: string
  errorCode?: SettingsGoogleBusinessLocation.ErrorCode
}

export namespace SettingsGoogleBusinessLocation {
  export enum ErrorCode {
    NO_PLACE_ID = "no_place_id",
    NOT_VERIFIED = "not_verified",
  }
}

export interface SettingsGoogleAnalyticsAccountSummary {
  id: string
  name: string
  webProperties: SettingsGoogleAnalyticsAccountSummary.WebPropertySummary[]
}

export namespace SettingsGoogleAnalyticsAccountSummary {
  export interface ViewProfile {
    id: string
    name: string
    websiteUrl?: string
  }

  export interface WebProperty {
    id: string
    name: string
    websiteUrl: string
  }

  export interface WebPropertySummary extends WebProperty {
    viewProfiles: ViewProfile[]
  }
}

export interface SettingsGoogleAnalyticsGoal {
  id: string
  name: string
  type: string
}

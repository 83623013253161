import CloseIcon from "@mui/icons-material/Close"
import DoneIcon from "@mui/icons-material/Done"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { omit } from "lodash"
import { useCallback, useMemo, useState } from "react"
import styled from "styled-components"

import { AnalyticHelper, mixpanelHelpers, useDashboardContext } from "@onelocal/frontend-dashboard/common"
import { mainActions, MainActivity } from "@onelocal/frontend-dashboard/main"
import type { MenuItemProps } from "@onelocal/frontend-web/common"
import { IconButton, Menu, MenuItem, styleHelpers, useAlert } from "@onelocal/frontend-web/common"
import { ProductNames } from "@onelocal/shared/common"
import type { RootThunk } from "../../../store"
import { useRootDispatch } from "../../../store"

interface ActivityMenuProps {
  activityId?: string
  activityStatus?: MainActivity.Status
  activityType: MainActivity.Type
  anchorEl: HTMLElement | null
  extraMenuItems?: ExtraActivityMenuItems[]
  onClickActivityMenu: ( event: React.MouseEvent<HTMLButtonElement> ) => void
  open: boolean
  setAnchorEl: ( value: HTMLElement | null ) => void
}

export interface ExtraActivityMenuItems {
  icon?: React.ReactNode
  onClick: ( event: React.MouseEvent<HTMLElement> ) => void
  title: string
}

const StyledMenuItem = styled( ( props: MenuItemProps ) => <MenuItem { ...props } /> )`
  border-bottom: 1px solid ${ styleHelpers.colors.whiteSmoke };
  height: 64px;
`

const UndoAction = styled.span`
  cursor: pointer;
  text-decoration: underline;
`

const getPageNameByType = ( category: MainActivity.Type ) => {
  switch( category ) {
    case MainActivity.Type.CONVERSATION:
      return "LocalMessages"
    case MainActivity.Type.REVIEW:
      return "LocalReviews"
    case MainActivity.Type.KEYWORD_RANKING_BLOG:
    case MainActivity.Type.SHARE_BEFORE_AFTER_PHOTOS:
    case MainActivity.Type.SHARE_BLOG_POST:
    case MainActivity.Type.SHARE_BULK_MESSAGE:
    case MainActivity.Type.SHARE_PHOTOS:
    case MainActivity.Type.SHARE_REVIEW:
      return "LocalContent"
    default:
      return ""
  }
}

const TRACKING_PROPERTIES = {
  Location: "Activity - Web Dashboard",
  Product: ProductNames.HOME as ProductNames.HOME,
}

export const ActivityMenu: React.FC<ActivityMenuProps> = ( {
  activityId,
  activityStatus,
  activityType,
  anchorEl,
  extraMenuItems,
  onClickActivityMenu,
  open,
  setAnchorEl,
} ) => {
  const { showSuccess, showError } = useAlert()
  const dispatch = useRootDispatch()
  const { dashboardContext } = useDashboardContext()
  const [ isLoading, setIsLoading ] = useState( false )

  const onActionClicked = useCallback( async ( action: MainActivity.ActionType ) => {
    if( ! activityId || isLoading ) {
      return
    }

    let actionData: {
      createAction: ( merchantId: string, activity: string ) => RootThunk<MainActivity | void>
      toast: { title: string, body: string, buttonText?: string }
      trackEvent?: () => void
      undoAction?: MainActivity.ActionType
    } | null = null

    const commonTrackingParams = {
      ...TRACKING_PROPERTIES,
      "Card Type": MainActivity.TypeLabel[ activityType ],
      "Card Status": MainActivity.StatusLabel[ activityStatus! ],
    }

    const pageName = getPageNameByType( activityType )

    switch( action ) {
      case MainActivity.ActionType.ARCHIVE:
        actionData = {
          createAction: mainActions.archiveActivity,
          toast: {
            title: "Done!",
            body: pageName ? `You have completed it! You can still access it via Activity > Done or inside your ${ pageName }.` : "",
            buttonText: "Keep in Activity",
          },
          trackEvent: () => mixpanelHelpers.trackEvent( AnalyticHelper.EventName.CARD_DONE, commonTrackingParams ),
          undoAction: MainActivity.ActionType.RESTORE,
        }
        break
      case MainActivity.ActionType.DELETE:
        actionData = {
          createAction: mainActions.deleteActivity,
          toast: {
            title: "Dismissed!",
            body: pageName ? `You can still access it from your ${ pageName }.` : "",
          },
          trackEvent: () => mixpanelHelpers.trackEvent( AnalyticHelper.EventName.CARD_DISMISSED, commonTrackingParams ),
          undoAction: MainActivity.ActionType.RESTORE_DELETED,
        }
        break
      case MainActivity.ActionType.DISMISS:
        actionData = {
          createAction: mainActions.dismissActivity,
          toast: {
            title: "Dismissed!",
            body: pageName ? pageName ? `You can still access it from your ${ pageName }.` : "" : "",
          },
          trackEvent: () => mixpanelHelpers.trackEvent( AnalyticHelper.EventName.CARD_DISMISSED, commonTrackingParams ),
          undoAction: MainActivity.ActionType.RESTORE_FROM_DISMISS,
        }
        break
      case MainActivity.ActionType.RESTORE:
        actionData = {
          createAction: mainActions.restoreActivity,
          toast: {
            title: "Restored!",
            body: pageName ? "This item has been restored to your Activity." : "",
          },
          trackEvent: () => mixpanelHelpers.trackEvent( AnalyticHelper.EventName.CARD_RESTORED, omit( commonTrackingParams, "Card Status" ) ),
          undoAction: MainActivity.ActionType.ARCHIVE,
        }
        break
      case MainActivity.ActionType.RESTORE_DELETED:
        actionData = {
          createAction: mainActions.restoreDeletedActivity,
          toast: {
            title: "Restored!",
            body: pageName ? "This item has been restored to your Activity." : "",
          },
          trackEvent: () => mixpanelHelpers.trackEvent( AnalyticHelper.EventName.CARD_RESTORED, omit( commonTrackingParams, "Card Status" ) ),
          undoAction: MainActivity.ActionType.DELETE,
        }
        break
      case MainActivity.ActionType.RESTORE_FROM_DISMISS:
        actionData = {
          createAction: mainActions.restoreActivityFromDismiss,
          toast: {
            title: "Restored!",
            body: pageName ? "This item has been restored to your Activity." : "",
          },
          trackEvent: () => mixpanelHelpers.trackEvent( AnalyticHelper.EventName.CARD_RESTORED, omit( commonTrackingParams, "Card Status" ) ),
          undoAction: MainActivity.ActionType.DISMISS,
        }
        break
    }

    if( actionData ) {
      setIsLoading( true )

      try {
        await dispatch( actionData.createAction( dashboardContext.merchantId, activityId ) )
        if( actionData.trackEvent ) {
          actionData.trackEvent()
        }

        let message = actionData.toast.title
        if( actionData.toast.body ) {
          message += ` ${ actionData.toast.body }`
        }

        const actionButtonText = actionData.toast.buttonText ? actionData.toast.buttonText : "Undo"

        showSuccess( message, actionData.undoAction ? () => <UndoAction onClick={ () => actionData?.undoAction && onActionClicked( actionData.undoAction ) }>{ actionButtonText }</UndoAction> : undefined )
      } catch( err ) {
        showError( err )
      } finally {
        setIsLoading( false )
      }
    }
  }, [ activityId, activityType, activityStatus, dispatch, dashboardContext.merchantId, isLoading, showSuccess, showError ] )

  const onRestoreDoneActionClicked = useCallback( async ( event: React.MouseEvent<HTMLElement> ) => {
    event.stopPropagation()
    await onActionClicked(
      activityStatus === MainActivity.Status.ARCHIVE
        ? MainActivity.ActionType.RESTORE
        : MainActivity.ActionType.ARCHIVE,
    )
  }, [ activityStatus, onActionClicked ] )

  const onDismissActionClicked = useCallback( async ( event: React.MouseEvent<HTMLElement> ) => {
    event.stopPropagation()
    await onActionClicked(
      activityStatus === MainActivity.Status.ARCHIVE
        ? MainActivity.ActionType.DELETE
        : MainActivity.ActionType.DISMISS,
    )
  }, [ activityStatus, onActionClicked ] )

  const activitiesActions = useMemo( () => {
    const actions: Array<{
      icon: React.ReactNode
      onClick?: ( event: React.MouseEvent<HTMLElement> ) => Promise<void>
      text: string
    }> = []

    if( activityId ) {
      actions.push( {
        icon: <CloseIcon sx={ { fontSize: 24, marginRight: "12px", color: styleHelpers.colors.primary } }/>,
        onClick: onDismissActionClicked,
        text: "Dismiss",
      } )
    }

    if( activityStatus === MainActivity.Status.INBOX || activityStatus === MainActivity.Status.ARCHIVE ) {
      actions.push( {
        icon: <DoneIcon sx={ { fontSize: 24, marginRight: "12px", color: styleHelpers.colors.primary } }/>,
        onClick: onRestoreDoneActionClicked,
        text: activityStatus === MainActivity.Status.ARCHIVE ? "Restore" : "Mark as Done",
      } )
    }

    return actions
  }, [ activityId, activityStatus, onDismissActionClicked, onRestoreDoneActionClicked ] )

  const onCloseHandler = useCallback( ( event: React.MouseEvent<HTMLElement> ) => {
    event.stopPropagation()
    setAnchorEl( null )
  }, [ setAnchorEl ] )

  return ( activitiesActions.length || extraMenuItems?.length ) && (
    <>
      <IconButton onClick={ onClickActivityMenu }>
        <MoreVertIcon sx={ { color: styleHelpers.colors.dark } }/>
      </IconButton>
      <Menu
        anchorEl={ anchorEl }
        anchorOrigin={ { horizontal: "right", vertical: "bottom" } }
        id={ activityId }
        MenuListProps={ { disablePadding: true } }
        open={ open }
        onClose={ onCloseHandler }
        transformOrigin={ { horizontal: "right", vertical: "top" } }
      >
        {
          activitiesActions.map( ( ( action, index ) => (
            <StyledMenuItem key={ index } onClick={ action.onClick } >
              { action.icon }
              { action.text }
            </StyledMenuItem>
          ) ) )
        }
        { extraMenuItems?.map( ( item, index ) => <StyledMenuItem key={ index } onClick={ ( event ) => { event.stopPropagation(); setAnchorEl( null ); item.onClick( event ) } }>{ item.icon }{ item.title }</StyledMenuItem> ) }
      </Menu>
    </>
  )
}

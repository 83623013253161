import React, { useCallback, useEffect, useRef, useState } from "react"
import type { SubmitHandler } from "react-hook-form"
import { Controller, FormProvider, useForm } from "react-hook-form"
import styled from "styled-components"

import { AnalyticHelper, mixpanelHelpers, useDashboardContext } from "@onelocal/frontend-dashboard/common"
import { connectService } from "@onelocal/frontend-dashboard/connect"
import type { DialogAlert, SelectOption } from "@onelocal/frontend-web/common"
import { Button, Dialog, DialogAlertType, Flex, InfoIcon, Link, Select, StepProgress, styleHelpers, TextField, Tooltip, useModalWithParams } from "@onelocal/frontend-web/common"
import { ProductNames } from "@onelocal/shared/common"

export interface GenerateMessageModalProps {
  isOpen: boolean
  merchantId?: string
  onClose(): void
  onExited(): void
  onResult( result?: string ): void
}

const HelpText = styled.div`
  font-size: 12px;
  margin-bottom: 5px;
`

const StyledButton = styled( Button )`
  margin-top: 10px;

  & + & {
    margin-left: 10px;
  }
`

const StyledNote = styled.div`
  font-size: 12px;
  margin: 20px 30px;
  text-align: center;
`

const StyledSelect = styled( Select )`
  margin-top: 10px;
`

const StyledTextField = styled( TextField )`
  height: auto;
  margin-top: 10px;
`

const StyledInfoIcon = styled( InfoIcon )`
  margin-top: 15px;
`

const TitleText = styled.div`
  color: ${ styleHelpers.colors.darkGray };
  font-weight: 500;
  margin-bottom: 5px;
`

enum AgeGroupSelectorValueType {
  BETWEEN_18_TO_24 = "18 to 24 year olds",
  BETWEEN_25_TO_49 = "25 to 49 year olds",
  BETWEEN_50_TO_64 = "50 to 64 year olds",
  OVER_64 = "Over 64 years of age"
}

enum FormFieldKeys {
  AGE = "age",
  GENDER = "gender",
  PERSPECTIVE = "perspective",
  PROMPT = "prompt",
  SERVICES = "services",
  TONE = "tone",
  TYPE = "type",
  WORDS_TO_EXCLUDE = "words_to_exclude",
  WORDS_TO_INCLUDE = "words_to_include",
}

enum GenderSelectorValueType {
  ALL = "all",
  FEMALE = "female",
  MALE = "male",
}

enum MessageTypeSelectorValueType {
  EDUCATIONAL = "educational",
  HOLIDAY_GREETING = "holiday greeting",
  INVITATION = "invitation to an event",
  PRODUCT = "product announcement",
  PROMOTIONAL = "promotional",
  SERVICE = "service announcement",
  THANKYOU = "thank you",
}

enum PerspectiveSelectorValueType {
  FIRST_PERSON = "first person",
  THIRD_PERSON = "third person",
}

enum ToneSelectorValueType {
  CONVERSATIONAL = "conversational",
  FRIENDLY = "friendly",
  INFORMATIVE = "informative",
  PROFESSIONAL = "professional",
  WELCOMING = "welcoming",
  WITTY = "witty",
}

type PromptBuilderFormValues = {
  [ FormFieldKeys.AGE ]: string
  [ FormFieldKeys.PROMPT ]: string
  [ FormFieldKeys.GENDER ]: string
  [ FormFieldKeys.TYPE ]: string
  [ FormFieldKeys.PERSPECTIVE ]: string
  [ FormFieldKeys.SERVICES ]: string
  [ FormFieldKeys.TONE ]: string
  [ FormFieldKeys.WORDS_TO_EXCLUDE ]: string
  [ FormFieldKeys.WORDS_TO_INCLUDE ]: string
}
const ageOptions: Array<SelectOption<string>> = [
  {
    label: "18-24",
    value: AgeGroupSelectorValueType.BETWEEN_18_TO_24,
  },
  {
    label: "25-49",
    value: AgeGroupSelectorValueType.BETWEEN_25_TO_49,
  },
  {
    label: "50-64",
    value: AgeGroupSelectorValueType.BETWEEN_50_TO_64,
  },
  {
    label: "65+",
    value: AgeGroupSelectorValueType.OVER_64,
  },
]

const genderOptions: Array<SelectOption<string>> = [
  {
    label: "Gender Neutral",
    value: GenderSelectorValueType.ALL,
  },
  {
    label: "Male",
    value: GenderSelectorValueType.MALE,
  },
  {
    label: "Female",
    value: GenderSelectorValueType.FEMALE,
  },
]

const messageTypeOptions: Array<SelectOption<string>> = [
  {
    label: "Promotional",
    value: MessageTypeSelectorValueType.PROMOTIONAL,
  },
  {
    label: "Service Announcement",
    value: MessageTypeSelectorValueType.SERVICE,
  },
  {
    label: "Product Announcement",
    value: MessageTypeSelectorValueType.PRODUCT,
  },
  {
    label: "Holiday Greeting",
    value: MessageTypeSelectorValueType.HOLIDAY_GREETING,
  },
  {
    label: "Thank you",
    value: MessageTypeSelectorValueType.THANKYOU,
  },
  {
    label: "Invitation To An Event",
    value: MessageTypeSelectorValueType.INVITATION,
  },
  {
    label: "Educational",
    value: MessageTypeSelectorValueType.EDUCATIONAL,
  },
]

const perspectiveOptions: Array<SelectOption<string>> = [
  {
    label: "First Person",
    value: PerspectiveSelectorValueType.FIRST_PERSON,
  },
  {
    label: "Third Person",
    value: PerspectiveSelectorValueType.THIRD_PERSON,
  },
]

const toneOptions: Array<SelectOption<string>> = [
  {
    label: "Conversational",
    value: ToneSelectorValueType.CONVERSATIONAL,
  },
  {
    label: "Friendly",
    value: ToneSelectorValueType.FRIENDLY,
  },
  {
    label: "Informative",
    value: ToneSelectorValueType.INFORMATIVE,
  },
  {
    label: "Professional",
    value: ToneSelectorValueType.PROFESSIONAL,
  },
  {
    label: "Welcoming",
    value: ToneSelectorValueType.WELCOMING,
  },
  {
    label: "Witty",
    value: ToneSelectorValueType.WITTY,
  },
]

const mixpanelProperties = {
  "Location": "Message Details Modal",
  "Product": ProductNames.LOCAL_MESSAGES,
}

enum STEPS {
  BUILD = "build",
  GENERATE = "generate",
}

export const GenerateMessageModal: React.FC<GenerateMessageModalProps> = ( {
  isOpen,
  merchantId,
  onClose,
  onExited,
  onResult,
} ) => {
  const [ activeStep, setActiveStep ] = useState<string>( STEPS.BUILD )
  const { dashboardContext } = useDashboardContext()
  const [ alert, setAlert ] = useState<DialogAlert | undefined>( undefined )
  const [ existingGeneratedMessages, setExistingGeneratedMessages ] = useState<string[]>( [] )
  const [ generatedMessage, setGeneratedMessage ] = useState<string>( "" )
  const [ isProcessing, setIsProcessing ] = useState<boolean>( false )
  const [ message, setMessage ] = useState<string>( "" )
  const [ promptBuilderForm, setPromptBuilderForm ] = useState<Record<string, string> | undefined>( undefined )
  const [ promptBuilderData, setPromptBuilderData ] = useState<Record<string, string>>( { [ FormFieldKeys.GENDER ]: "all" } )
  const undoRef = useRef( false )

  const formMethods = useForm<PromptBuilderFormValues>( {
    defaultValues: {
      [ FormFieldKeys.AGE ]: "",
      [ FormFieldKeys.PROMPT ]: "",
      [ FormFieldKeys.GENDER ]: "all",
      [ FormFieldKeys.PERSPECTIVE ]: "",
      [ FormFieldKeys.SERVICES ]: "",
      [ FormFieldKeys.TYPE ]: "",
      [ FormFieldKeys.TONE ]: "",
      [ FormFieldKeys.WORDS_TO_EXCLUDE ]: "",
      [ FormFieldKeys.WORDS_TO_INCLUDE ]: "",
    },
  } )
  const { control: promptBuilderControl, formState: promptBuilderFormState, handleSubmit: promptBuilderHandleSubmit, reset: resetPromptBuilderForm } = formMethods

  useEffect( () => {
    const run = async () => {
      if( promptBuilderForm && ! undoRef.current && ! alert ) {
        try {
          setIsProcessing( true )
          const response = await connectService.generateMessage(
            merchantId || dashboardContext.merchantId,
            {
              existing_generated_messages: existingGeneratedMessages,
              form: promptBuilderForm,
            },
          )
          if( response.text ) {
            setGeneratedMessage( response.text )
            setMessage( response.text )
            setActiveStep( STEPS.GENERATE )
          }
        } catch( err ) {
          setAlert( {
            message: err,
            type: DialogAlertType.ERROR,
          } )
        } finally {
          setIsProcessing( false )
        }
      }
    }

    run()
  }, [ alert, dashboardContext.merchantId, existingGeneratedMessages, merchantId, promptBuilderForm, setActiveStep ] )

  useEffect( () => {
    resetPromptBuilderForm( promptBuilderData )
  }, [ promptBuilderData, resetPromptBuilderForm ] )

  const insert = useCallback( () => {
    mixpanelHelpers.trackEvent(
      AnalyticHelper.EventName.CTA_CLICKED,
      {
        "Name": "Insert",
        ...mixpanelProperties,
      },
    )
    onClose()
    onResult( message )
  }, [ message, onClose, onResult ] )

  const onCloseHandler = useCallback( () => {
    onClose()
    onResult()
  }, [ onClose, onResult ] )

  const onExitedHandler = useCallback( () => {
    onExited()
    onResult()
  }, [ onExited, onResult ] )

  const onBack = useCallback( async () => {
    mixpanelHelpers.trackEvent(
      AnalyticHelper.EventName.CTA_CLICKED,
      {
        "Name": "Back",
        ...mixpanelProperties,
      },
    )
    undoRef.current = false
    setActiveStep( STEPS.BUILD )
    setGeneratedMessage( "" )
    setMessage( "" )
    setPromptBuilderForm( undefined )
    setExistingGeneratedMessages( [] )
  }, [ setActiveStep ] )

  const onPromptBuilderSubmit: SubmitHandler<PromptBuilderFormValues> = useCallback( async ( data ) => {
    if( data[ FormFieldKeys.PROMPT ] ) {
      mixpanelHelpers.trackEvent(
        AnalyticHelper.EventName.CTA_CLICKED,
        {
          "Name": "Generate",
          "Type": "Prompt Builder",
          ...mixpanelProperties,
        },
      )
      setPromptBuilderForm( data )
      setPromptBuilderData( data )
      setAlert( undefined )
    }
  }, [] )

  const regenerate = useCallback( () => {
    mixpanelHelpers.trackEvent(
      AnalyticHelper.EventName.CTA_CLICKED,
      {
        "Name": "Regenerate",
        ...mixpanelProperties,
      },
    )
    undoRef.current = false
    setExistingGeneratedMessages( [ ...existingGeneratedMessages, generatedMessage ] )
    setAlert( undefined )
  }, [ existingGeneratedMessages, generatedMessage ] )

  const undo = useCallback( () => {
    if( existingGeneratedMessages.length ) {
      mixpanelHelpers.trackEvent(
        AnalyticHelper.EventName.CTA_CLICKED,
        {
          "Name": "Undo",
          ...mixpanelProperties,
        },
      )
      const generatedMessages = [ ...existingGeneratedMessages ]
      const lastGeneratedMessage = generatedMessages.pop()
      setGeneratedMessage( lastGeneratedMessage! )
      setMessage( lastGeneratedMessage! )
      setExistingGeneratedMessages( generatedMessages )
      undoRef.current = true
    }
  }, [ existingGeneratedMessages ] )

  return (
    <Dialog
      alert={ alert }
      actions={ [] }
      contentContainerStyle={ {
        width: "600px",
      } }
      isOpen={ isOpen }
      loadingText={
        isProcessing
          ? "Generating Message..."
          : null
      }
      onClose={ onCloseHandler }
      onExited={ onExitedHandler }
      noMaxWidth={ true }
      title="Generate Message"
    >
      <>
        {
          <StepProgress
            activeStepId={ activeStep }
            steps={ [
              {
                id: STEPS.BUILD,
                label: "Build",
                component: (
                  <>
                    <TitleText style={ { marginBottom: "10px" } }>Create an AI Prompt</TitleText>
                    <HelpText><Link href="https://intercom.help/onelocal/en/articles/7908702-building-effective-ai-prompts-for-localmessages" target="_blank">Click here</Link> to learn more about building effective AI prompts.</HelpText>
                    <FormProvider { ...formMethods }>
                      <Controller
                        control={ promptBuilderControl }
                        name={ FormFieldKeys.PROMPT }
                        render={ ( { field: { onChange, value } } ) => {
                          const error = promptBuilderFormState.errors?.[ FormFieldKeys.PROMPT ]?.message
                          return (
                            <StyledTextField
                              error={ error != null }
                              fullWidth={ true }
                              helperText={ error }
                              label="Prompt"
                              multiline={ true }
                              onChange={ onChange }
                              placeholder="Tell AI what you want to write, like 'Write me a promotional message about 20% discount offer during the holidays.' The more detail or context you provide, the better quality the suggested message will be."
                              required={ true }
                              rows={ 4 }
                              value={ value || "" }
                            />
                          ) } }
                        rules={ {
                          validate: ( value ) => {
                            value = ( value || "" ).trim()

                            if( ! value ) {
                              return "Prompt is required"
                            }
                            return undefined
                          },
                        } }
                      />
                      <TitleText style={ { margin: "25px 0 10px 0" } }>Need Help?</TitleText>
                      <HelpText>Select from or input the options below to enhance the prompt used to generate your message:</HelpText>
                      <Flex
                        alignItems="center"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <Controller
                          control={ promptBuilderControl }
                          name={ FormFieldKeys.TYPE }
                          render={ ( { field: { onChange, value } } ) => {
                            const error = promptBuilderFormState.errors?.[ FormFieldKeys.TYPE ]?.message
                            return (
                              <StyledSelect
                                error={ error != null }
                                fullWidth={ true }
                                helpText={ error }
                                label="Type of Message"
                                onChange={ onChange }
                                options={ messageTypeOptions }
                                value={ value || "" }
                              />
                            ) } }
                        />
                        <Tooltip placement="bottom" text="Indicate the type of message to be created">
                          <StyledInfoIcon />
                        </Tooltip>
                      </Flex>
                      <Flex
                        alignItems="center"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <Controller
                          control={ promptBuilderControl }
                          name={ FormFieldKeys.SERVICES }
                          render={ ( { field: { onChange, value } } ) => {
                            const error = promptBuilderFormState.errors?.[ FormFieldKeys.SERVICES ]?.message
                            return (
                              <StyledTextField
                                error={ error != null }
                                fullWidth={ true }
                                helperText={ error }
                                onChange={ onChange }
                                label="Service(s) to include (comma separated)"
                                value={ value || "" }
                              />
                            ) } }
                        />
                        <Tooltip placement="bottom" text="Indicate which services should be included in the message">
                          <StyledInfoIcon />
                        </Tooltip>
                      </Flex>
                      <Flex
                        alignItems="center"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <Controller
                          control={ promptBuilderControl }
                          name={ FormFieldKeys.WORDS_TO_INCLUDE }
                          render={ ( { field: { onChange, value } } ) => {
                            const error = promptBuilderFormState.errors?.[ FormFieldKeys.WORDS_TO_INCLUDE ]?.message
                            return (
                              <StyledTextField
                                error={ error != null }
                                fullWidth={ true }
                                helperText={ error }
                                onChange={ onChange }
                                label="Words to include (comma separated)"
                                value={ value || "" }
                              />
                            ) } }
                        />
                        <Tooltip placement="bottom" text="Provide a list of keywords e.g. specific offers, dates, times">
                          <StyledInfoIcon />
                        </Tooltip>
                      </Flex>
                      <Flex
                        alignItems="center"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <Controller
                          control={ promptBuilderControl }
                          name={ FormFieldKeys.WORDS_TO_EXCLUDE }
                          render={ ( { field: { onChange, value } } ) => {
                            const error = promptBuilderFormState.errors?.[ FormFieldKeys.WORDS_TO_EXCLUDE ]?.message
                            return (
                              <StyledTextField
                                error={ error != null }
                                fullWidth={ true }
                                helperText={ error }
                                onChange={ onChange }
                                label="Words to exclude (comma separated)"
                                value={ value || "" }
                              />
                            ) } }
                        />
                        <Tooltip placement="bottom" text="Provide a list of keywords, which should not be included in the message e.g. competitors, unwanted synonyms">
                          <StyledInfoIcon />
                        </Tooltip>
                      </Flex>
                      <Flex
                        alignItems="center"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <Controller
                          control={ promptBuilderControl }
                          name={ FormFieldKeys.GENDER }
                          render={ ( { field: { onChange, value } } ) => {
                            const error = promptBuilderFormState.errors?.[ FormFieldKeys.GENDER ]?.message
                            return (
                              <StyledSelect
                                error={ error != null }
                                fullWidth={ true }
                                helpText={ error }
                                label="Gender Target"
                                onChange={ onChange }
                                options={ genderOptions }
                                value={ value || "" }
                              />
                            ) } }
                        />
                        <Tooltip placement="bottom" text="Indicate which gender the message will be targeting">
                          <StyledInfoIcon />
                        </Tooltip>
                      </Flex>
                      <Flex
                        alignItems="center"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <Controller
                          control={ promptBuilderControl }
                          name={ FormFieldKeys.AGE }
                          render={ ( { field: { onChange, value } } ) => {
                            const error = promptBuilderFormState.errors?.[ FormFieldKeys.AGE ]?.message
                            return (
                              <StyledSelect
                                error={ error != null }
                                fullWidth={ true }
                                helpText={ error }
                                label="Age Target"
                                onChange={ onChange }
                                options={ ageOptions }
                                value={ value || "" }
                              />
                            ) } }
                        />
                        <Tooltip placement="bottom" text="Indicate which age range the message will be targeting">
                          <StyledInfoIcon />
                        </Tooltip>
                      </Flex>

                      <Flex
                        alignItems="center"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <Controller
                          control={ promptBuilderControl }
                          name={ FormFieldKeys.TONE }
                          render={ ( { field: { onChange, value } } ) => {
                            const error = promptBuilderFormState.errors?.[ FormFieldKeys.TONE ]?.message
                            return (
                              <StyledSelect
                                error={ error != null }
                                fullWidth={ true }
                                helpText={ error }
                                label="Writing Tone"
                                onChange={ onChange }
                                options={ toneOptions }
                                value={ value || "" }
                              />
                            ) } }
                        />
                        <Tooltip placement="bottom" text="Indicate which conversational tone the message should have">
                          <StyledInfoIcon />
                        </Tooltip>
                      </Flex>

                      <Flex
                        alignItems="center"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <Controller
                          control={ promptBuilderControl }
                          name={ FormFieldKeys.PERSPECTIVE }
                          render={ ( { field: { onChange, value } } ) => {
                            const error = promptBuilderFormState.errors?.[ FormFieldKeys.PERSPECTIVE ]?.message
                            return (
                              <StyledSelect
                                error={ error != null }
                                fullWidth={ true }
                                helpText={ error }
                                label="Writing Perspective"
                                onChange={ onChange }
                                options={ perspectiveOptions }
                                value={ value || "" }
                              />
                            ) } }
                        />
                        <Tooltip placement="bottom" text="Indicate whether the message should be written from a first or third person perspective">
                          <StyledInfoIcon />
                        </Tooltip>
                      </Flex>
                      <Flex alignItems="center" display="flex" flexDirection="row" justifyContent="flex-end" >
                        <StyledButton disabled={ isProcessing } onClick={ promptBuilderHandleSubmit( onPromptBuilderSubmit ) }>Generate</StyledButton>
                      </Flex>
                    </FormProvider>
                  </>
                ),
              },
              {
                id: STEPS.GENERATE,
                label: "Generated Message",
                component: (
                  <>
                    <TitleText>Generated Message</TitleText>
                    <StyledTextField
                      fullWidth={ true }
                      label="Here is your suggested message"
                      multiline={ true }
                      onChange={ setMessage }
                      rows={ 4 }
                      value={ message }
                    />
                    <Flex alignItems="center" display="flex" flexDirection="row" justifyContent="space-between">
                      <Flex alignItems="center" display="flex" flexDirection="row" justifyContent="flex-start">
                        <StyledButton color="default" disabled={ isProcessing } onClick={ onBack }>Back</StyledButton>
                        <StyledButton color="default" disabled={ isProcessing } onClick={ regenerate }>Regenerate</StyledButton>
                      </Flex>
                      <Flex alignItems="center" display="flex" flexDirection="row" justifyContent="flex-end">
                        <StyledButton disabled={ isProcessing || ! existingGeneratedMessages.length } onClick={ undo } variant="text">Undo</StyledButton>
                        <StyledButton disabled={ isProcessing } onClick={ insert }>Insert</StyledButton>
                      </Flex>
                    </Flex>
                  </>
                ),
              },
            ] }
          />
        }
        <StyledNote>Note: Recommendations may occasionally generate incorrect information, harmful instructions and/or biased content. Personal Identifiable Information (PII), Personal Health Information (PHI) and cannabis-related or other restricted content under OneLocal&apos;s <Link href="https://www.onelocal.com/terms/" target="_blank">Terms of Use</Link> should not be included in prompts, refinements, or content.</StyledNote>
      </>
    </Dialog>
  )
}

export interface useGenerateMessageModalParams {
  merchantId?: string
  onResult( result?: string ): void
}

export const useGenerateMessageModal = () => {
  const { showModal, hideModal } = useModalWithParams<useGenerateMessageModalParams>( ( { open, onExited, params } ) => {
    return (
      <GenerateMessageModal
        isOpen={ open }
        merchantId={ params.merchantId }
        onClose={ hideModal }
        onExited={ onExited }
        onResult={ params.onResult }
      />
    )
  }, [] )

  return {
    hideGenerateMessageModal: hideModal,
    showGenerateMessageModal: showModal,
  }
}

import { createSelector } from "@reduxjs/toolkit"

import type { MainState } from "./mainStore"

type MainStateSelector<TRootState> = [
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ( state: TRootState, ...params: any[] ) => MainState
]

export const mainSelector = <TRootState extends { main: MainState }>( state: TRootState ) => state.main

export const createMainSelector = <TRootState extends { main: MainState }, TReturnValue>( combiner: ( state: MainState ) => TReturnValue ) => {
  return createSelector<MainStateSelector<TRootState>, TReturnValue>(
    mainSelector,
    combiner,
  )
}

export const mainSelectors = {
  activities: {
    byId: ( activityId: string ) => {
      return createMainSelector(
        ( mainState ) => mainState.activities.byId[ activityId ],
      )
    },
    byIds: ( activityIds: string[] ) => {
      return createMainSelector(
        ( mainState ) => {
          return activityIds.map( ( activityId ) => mainState.activities.byId[ activityId ] )
        },
      )
    },
  },
  tasks: {
    byId: ( taskId: string ) => {
      return createMainSelector(
        ( mainState ) => mainState.tasks.byId[ taskId ],
      )
    },
    byIds: ( taskIds: string[] ) => {
      return createMainSelector(
        ( mainState ) => taskIds.map( ( taskId ) => mainState.tasks.byId[ taskId ] ).filter( ( task ) => task != null ),
      )
    },
    all: createMainSelector(
      ( mainState ) => Object.values( mainState.tasks.byId ).sort( ( a, b ) => b.created.at.localeCompare( a.created.at ) ),
    ),
  },
}

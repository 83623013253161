import { ConnectMerchantSettings } from "../types"

export const connectMerchantSettingsHelpers = {
  getA2PErrorMessages( merchantSettings: ConnectMerchantSettings ) {
    const errorMessages: string[] = []

    if( merchantSettings.twilio.a2pStatus === ConnectMerchantSettings.A2PStatus.REGISTERED ) {
      return errorMessages
    }

    if( merchantSettings.twilio.a2pError ) {
      errorMessages.push( merchantSettings.twilio.a2pError )
    }

    for( const error of ( merchantSettings.twilio.secondaryCustomerProfile?.evaluationErrors || [] ) ) {
      errorMessages.push( `${ error.message } (${ error.code })` )
    }

    for( const error of ( merchantSettings.twilio.starterCustomerProfile?.evaluationErrors || [] ) ) {
      errorMessages.push( `${ error.message } (${ error.code })` )
    }

    for( const error of ( merchantSettings.twilio.trustProduct?.evaluationErrors || [] ) ) {
      errorMessages.push( `${ error.message } (${ error.code })` )
    }

    if( merchantSettings.twilio.messagingBrand?.failureReason ) {
      errorMessages.push( merchantSettings.twilio.messagingBrand.failureReason )
    }

    return errorMessages
  },
  getA2PStatus( a2pStatus: ConnectMerchantSettings.A2PStatus ) {
    const displayStatus: Record<ConnectMerchantSettings.A2PStatus, string> = {
      [ ConnectMerchantSettings.A2PStatus.INVALID ]: "Invalid",
      [ ConnectMerchantSettings.A2PStatus.IN_PROGRESS ]: "In Progress",
      [ ConnectMerchantSettings.A2PStatus.IN_REVIEW ]: "In Review",
      [ ConnectMerchantSettings.A2PStatus.NOT_REGISTER ]: "Not Register",
      [ ConnectMerchantSettings.A2PStatus.REGISTERED ]: "Registered",
    }

    return displayStatus[ a2pStatus ]
  },
}

import { createSelector } from "@reduxjs/toolkit"

import type { SiteState } from "./siteStore"

type SiteStateSelector<TRootState> = [
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ( state: TRootState, ...params: any[] ) => SiteState
]

export const siteSelector = <TRootState extends { site: SiteState }>( state: TRootState ) => state.site

export const createSiteSelector = <TRootState extends { site: SiteState }, TReturnValue>( combiner: ( siteState: SiteState ) => TReturnValue ) => {
  return createSelector<SiteStateSelector<TRootState>, TReturnValue>(
    siteSelector,
    combiner,
  )
}

export const siteSelectors = {
  site: {
    byIds: ( siteIds: string[] ) => {
      return createSiteSelector(
        ( siteState ) => siteIds.map( ( siteId ) => siteState.sites.byId[ siteId ] ).filter( ( site ) => site != null ),
      )
    },
  },
  siteTemplates: {
    byId: ( siteTemplateId: string ) => {
      return createSiteSelector(
        ( siteState ) => siteState.siteTemplates.byId[ siteTemplateId ],
      )
    },
    byIds: ( siteTemplateIds: string[] ) => {
      return createSiteSelector(
        ( siteState ) => siteTemplateIds.map( ( siteTemplateId ) => siteState.siteTemplates.byId[ siteTemplateId ] ).filter( ( siteTemplate ) => siteTemplate != null ),
      )
    },
  },

  dudaTemplates: {
    all: createSiteSelector(
      ( siteState ) => siteState.dudaTemplates.all,
    ),
  },
}

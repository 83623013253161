// eslint-disable-next-line @nx/enforce-module-boundaries
import type { ApiAdsMerchantSettings } from "@onelocal/frontend-dashboard/ads"
// eslint-disable-next-line @nx/enforce-module-boundaries
import { parseAdsMerchantSettingsFromApi } from "@onelocal/frontend-dashboard/ads"
// eslint-disable-next-line @nx/enforce-module-boundaries
import type { ApiConnectMerchantSettings } from "@onelocal/frontend-dashboard/connect"
// eslint-disable-next-line @nx/enforce-module-boundaries
import { parseConnectMerchantSettingsFromApi } from "@onelocal/frontend-dashboard/connect"
import type { ApiContentMerchantSettings } from "@onelocal/frontend-dashboard/content"
import { parseContentMerchantSettingsFromApi } from "@onelocal/frontend-dashboard/content"
// eslint-disable-next-line @nx/enforce-module-boundaries
import type { ApiMessagesChannel, ApiMessagesMerchantSettings } from "@onelocal/frontend-dashboard/messages"
// eslint-disable-next-line @nx/enforce-module-boundaries
import { MessagesMerchantsSettings, parseMessagesMerchantSettings } from "@onelocal/frontend-dashboard/messages"
// eslint-disable-next-line @nx/enforce-module-boundaries
import type { ApiReferralsMerchantSettings } from "@onelocal/frontend-dashboard/referrals"
// eslint-disable-next-line @nx/enforce-module-boundaries
import { parseReferralsMerchantSettingsFromApi } from "@onelocal/frontend-dashboard/referrals"
// eslint-disable-next-line @nx/enforce-module-boundaries
import type { ApiResponseMerchantSettings } from "@onelocal/frontend-dashboard/response"
// eslint-disable-next-line @nx/enforce-module-boundaries
import { parseResponseMerchantSettingsFromApi } from "@onelocal/frontend-dashboard/response"
// eslint-disable-next-line @nx/enforce-module-boundaries
import type { ApiReviewsMerchantSettings } from "@onelocal/frontend-dashboard/reviews"
// eslint-disable-next-line @nx/enforce-module-boundaries
import { parseReviewsMerchantSettingsFromApi } from "@onelocal/frontend-dashboard/reviews"
// eslint-disable-next-line @nx/enforce-module-boundaries
import type { ApiSeoMerchantSettings } from "@onelocal/frontend-dashboard/seo"
// eslint-disable-next-line @nx/enforce-module-boundaries
import { parseSeoMerchantSettingsFromApi } from "@onelocal/frontend-dashboard/seo"
// eslint-disable-next-line @nx/enforce-module-boundaries
import type { ApiSiteMerchantSettings } from "@onelocal/frontend-dashboard/site"
// eslint-disable-next-line @nx/enforce-module-boundaries
import { parseSiteMerchantSettingsFromApi } from "@onelocal/frontend-dashboard/site"
// eslint-disable-next-line @nx/enforce-module-boundaries
import type { ApiVisitsMerchantSettings } from "@onelocal/frontend-dashboard/visits"
// eslint-disable-next-line @nx/enforce-module-boundaries
import { parseVisitsMerchantSettingsFromApi } from "@onelocal/frontend-dashboard/visits"
import { utilHelpers } from "@onelocal/shared/common"
import type { MerchantSettings, SettingsConnectedAccount, SettingsConnectedAccountType, SettingsGoogleAnalyticsAccountSummary, SettingsGoogleAnalyticsGoal, SettingsGoogleBusinessLocation } from "."

export type ApiSettingsConnectedAccount = (
  ApiSettingsConnectedAccountFacebook
  | ApiSettingsConnectedAccountFreshbooks
  | ApiSettingsConnectedAccountGoogleAnalytics
  | ApiSettingsConnectedAccountInstagram
  | ApiSettingsConnectedAccountStripeConnect
)

interface ApiSettingsConnectedAccountBase {
  id: string
  type: SettingsConnectedAccountType
  created: {
    at: string
    by: {
      ref: "employees" | "merchants"
      id: string
    }
  }
}

export interface ApiSettingsConnectedAccountFacebook extends ApiSettingsConnectedAccountBase {
  type: SettingsConnectedAccountType.FACEBOOK_ADS
}

export interface ApiSettingsConnectedAccountFreshbooks extends ApiSettingsConnectedAccountBase {
  type: SettingsConnectedAccountType.FRESHBOOKS
  employee_id: string
  survey_template_id: string
}

export interface ApiSettingsConnectedAccountGoogleAnalytics extends ApiSettingsConnectedAccountBase {
  type: SettingsConnectedAccountType.GOOGLE_ANALYTICS
  google_analytics_view_profile_id: string
  google_analytics_web_property_id: string
}

export interface ApiSettingsConnectedAccountInstagram extends ApiSettingsConnectedAccountBase {
  type: SettingsConnectedAccountType.INSTAGRAM
}

export interface ApiSettingsConnectedAccountStripeConnect extends ApiSettingsConnectedAccountBase {
  type: SettingsConnectedAccountType.STRIPE_CONNECT
}

export function parseSettingsConnectedAccountFromApi( apiSettingsConnectedAccount: ApiSettingsConnectedAccount ) {
  return utilHelpers.toCamelCase<SettingsConnectedAccount>( apiSettingsConnectedAccount )
}

export interface ApiSettingsGoogleAdsAccount {
  id: string
  name: string
  manager_account_id?: string
}

export interface ApiSettingsGoogleAnalyticsAccountSummary {
  id: string
  name: string
  web_properties: ApiSettingsGoogleAnalyticsAccountSummary.WebPropertySummary[]
}

export namespace ApiSettingsGoogleAnalyticsAccountSummary {
  export interface ViewProfile {
    id: string
    name: string
    website_url?: string
  }

  export interface WebProperty {
    id: string
    name: string
    website_url: string
  }

  export interface WebPropertySummary extends WebProperty {
    view_profiles: ViewProfile[]
  }
}

export interface ApiSettingsGoogleAnalyticsGoal {
  id: string
  name: string
  type: string
}

export function parseSettingsGoogleAnalyticsAccountSummaryFromApi( apiSettingsGoogleAnalyticsAccountSummary: ApiSettingsGoogleAnalyticsAccountSummary ) {
  return utilHelpers.toCamelCase<SettingsGoogleAnalyticsAccountSummary>( apiSettingsGoogleAnalyticsAccountSummary )
}

export function parseSettingsGoogleAnalyticsGoalFromApi( apiSettingsGoogleAnalyticsGoal: ApiSettingsGoogleAnalyticsGoal ) {
  return utilHelpers.toCamelCase<SettingsGoogleAnalyticsGoal>( apiSettingsGoogleAnalyticsGoal )
}

export interface ApiSettingsGoogleBusinessLocation {
  id: string
  name: string
  address: string
  place_id?: string
  error_code?: "no_place_id" | "not_verified"
}

export function parseSettingsGoogleBusinessLocationFromApi( apiGoogleBusinessLocation: ApiSettingsGoogleBusinessLocation ) {
  const location: SettingsGoogleBusinessLocation = {
    id: apiGoogleBusinessLocation.id,
    name: apiGoogleBusinessLocation.name,
    address: apiGoogleBusinessLocation.address,
  }

  if( apiGoogleBusinessLocation.place_id ) {
    location.placeId = apiGoogleBusinessLocation.place_id
  }

  if( apiGoogleBusinessLocation.error_code ) {
    location.errorCode = apiGoogleBusinessLocation.error_code as SettingsGoogleBusinessLocation.ErrorCode
  }

  return location
}

export interface ApiMerchantSettings {
  connect: ApiConnectMerchantSettings
  content?: ApiContentMerchantSettings
  clear_growth?: ApiAdsMerchantSettings
  connected_accounts: ApiSettingsConnectedAccount[]
  billing?: {
    country?: string
  }
  created?: {
    at?: string
  }
  cs_owner?: {
    id: string
    name: string
    avatar_url: string
  }
  first_impression?: ApiSiteMerchantSettings
  flag_billing?: boolean
  flags?: {
    agency_analytics?: boolean
    churn?: boolean
    demo?: boolean
    onboarding?: boolean
    partner?: boolean
  }
  instant_response?: ApiResponseMerchantSettings
  is_agency: boolean
  is_agency_location: boolean
  is_parent: boolean
  linked_site?: {
    created: {
      at: string
    }
    id: string
    duda_id: string
    merchant_id: string
  }
  location_nickname?: string
  merchant_id: string
  messenger_channel?: ApiMessagesChannel
  messenger: ApiMessagesMerchantSettings
  platform_version?: string
  profile: {
    name: string
    slug: string
  }
  referral_magic?: ApiReferralsMerchantSettings
  review_edge?: ApiReviewsMerchantSettings
  root_merchant_id: string
  seo_boost?: ApiSeoMerchantSettings
  testing?: boolean
  timezone: string
  visits?: ApiVisitsMerchantSettings
}

export function parseMerchantSettingsFromApi( apiMerchantSettings: ApiMerchantSettings ) {
  const merchantSettings: MerchantSettings = {
    // connectedAccounts: ( apiMerchantSettings.connected_accounts || [] ).map( parseSettingsConnectedAccountFromApi ),
    connect: parseConnectMerchantSettingsFromApi( apiMerchantSettings.connect ),
    connectedAccounts: ( apiMerchantSettings.connected_accounts || [] ).map( parseSettingsConnectedAccountFromApi ),
    content: apiMerchantSettings.content ? parseContentMerchantSettingsFromApi( apiMerchantSettings.content ) : undefined,
    country: apiMerchantSettings.billing?.country,
    created: {
      at: apiMerchantSettings.created?.at || null,
    },
    firstImpression: (
      apiMerchantSettings.first_impression
        ? parseSiteMerchantSettingsFromApi( apiMerchantSettings.first_impression )
        : undefined
    ),
    flagBillingIssue: apiMerchantSettings.flag_billing || false,
    flags: {
      agencyAnalytics: apiMerchantSettings.flags?.agency_analytics || false,
      churn: apiMerchantSettings.flags?.churn || false,
      demo: apiMerchantSettings.flags?.demo || false,
      onboarding: apiMerchantSettings.flags?.onboarding || false,
      partner: apiMerchantSettings.flags?.partner || false,
    },
    locationNickname: apiMerchantSettings.location_nickname,
    merchantId: apiMerchantSettings.merchant_id,
    name: apiMerchantSettings.profile.name,
    isAgency: apiMerchantSettings.is_agency,
    isAgencyLocation: apiMerchantSettings.is_agency_location,
    isParent: apiMerchantSettings.is_parent,
    rootMerchantId: apiMerchantSettings.root_merchant_id,
    timezone: apiMerchantSettings.timezone,
    platformVersion: apiMerchantSettings.platform_version || "2.0",
    ads: (
      apiMerchantSettings.clear_growth
        ? parseAdsMerchantSettingsFromApi( apiMerchantSettings.clear_growth )
        : {
          status: null,
        }
    ),
    csOwner: (
      apiMerchantSettings.cs_owner
        ? {
          avatarUrl: apiMerchantSettings.cs_owner.avatar_url,
          id: apiMerchantSettings.cs_owner.id,
          name: apiMerchantSettings.cs_owner.name,
        }
        : undefined
    ),
    messages: (
      apiMerchantSettings.messenger
        ? parseMessagesMerchantSettings( apiMerchantSettings.messenger, apiMerchantSettings.messenger_channel )
        : {
          assignment: {
            enabled: false,
          },
          autoresponder: {
            status: MessagesMerchantsSettings.AutoResponderStatusType.OFF,
          },
          enabled: false,
          status: null,
        }
    ),
    referrals: (
      apiMerchantSettings.referral_magic
        ? parseReferralsMerchantSettingsFromApi( apiMerchantSettings.referral_magic )
        : {
          status: null,
        }
    ),
    response: (
      apiMerchantSettings.instant_response
        ? parseResponseMerchantSettingsFromApi( apiMerchantSettings.instant_response )
        : undefined
    ),
    reviews: (
      apiMerchantSettings.review_edge
        ? parseReviewsMerchantSettingsFromApi( apiMerchantSettings.review_edge )
        : {
          attribution: {
            enabled: false,
          },
          bulk: {
            defaultResend: false,
            email: {
              enabled: false,
            },
            sms: {
              enabled: false,
            },
          },
          connectedAccounts: [],
          enabled: false,
          providers: [],
          status: null,
        }
    ),
    seo: (
      apiMerchantSettings.seo_boost
        ? parseSeoMerchantSettingsFromApi( apiMerchantSettings.seo_boost )
        : {
          projects: [],
          status: null,
        }
    ),
    site: (
      apiMerchantSettings.first_impression
        ? parseSiteMerchantSettingsFromApi( apiMerchantSettings.first_impression )
        : {
          status: null,
        }
    ),
    slug: apiMerchantSettings.profile.slug,
    testing: apiMerchantSettings.testing || false,
    visits: (
      apiMerchantSettings.visits
        ? parseVisitsMerchantSettingsFromApi( apiMerchantSettings.visits )
        : undefined
    ),
  }

  if( apiMerchantSettings.linked_site ) {
    merchantSettings.linkedSite = utilHelpers.toCamelCase( apiMerchantSettings.linked_site )
  }

  return merchantSettings
}

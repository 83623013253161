export type EmptyObject = Record<string, never>

export interface Merchant {
  agency?: {
    id: string
    groupIds: string[]
  }
  id: string
  locationNickname?: string
  members: Array<{
    id: string
    ref: "merchants"
  }>
  name: string
  parentId?: string
  referencePrefix?: string
  rootId: string
  slug: string
  testing: boolean
  timezone: string
}

export interface PhoneNumber {
  type: "mobile" | "landline" | "voip" | "unknown"
  value: string
  internationalFormat: string
  nationalFormat: string
  countryCallingCode: number
  countryCode: string
}

export enum ProductNames {
  ANALYTICS = "Analytics",
  HOME = "Home",
  LOCAL_ADS = "LocalAds",
  LOCAL_CONTACTS = "LocalContacts",
  LOCAL_CONTENT = "LocalContent",
  LOCAL_MESSAGES = "LocalMessages",
  LOCAL_REFERRALS = "LocalReferrals",
  LOCAL_RESPONSE = "LocalResponse",
  LOCAL_REVIEWS = "LocalReviews",
  LOCAL_SEO = "LocalSEO",
  LOCAL_SITE = "LocalSite",
  LOCAL_VISITS = "LocalVisits",
  PERFORMANCE = "Performance",
}

export enum ProductTypes {
  ADS = "ads",
  CONTACTS = "contacts",
  MESSAGES = "messages",
  REFERRALS = "referrals",
  RESPONSE = "response",
  REVIEWS = "reviews",
  SEO = "seo",
  SITE = "site",
  VISITS = "visits",
}

export interface Name {
  display?: string
  family?: string
  given?: string
}

export interface ApiRequestProgressEvent {
  loaded: number
  progress: number
  total: number
}

import type { SelectOption } from "@onelocal/frontend-web/common"
import { OnboardingForm } from "../../types"

export const A2P_BUSINESS_IDENTITY_OPTIONS: Array<SelectOption<string>> = [
  { "label": "Direct Customer", "value": "direct_customer" },
]

// These Business Industries should match with Twilio industries
export const A2P_BUSINESS_INDUSTRY_OPTIONS: Array<SelectOption<string>> = [
  { "label": "Agriculture", "value": "agriculture" },
  { "label": "Automotive", "value": "automotive" },
  { "label": "Banking", "value": "banking" },
  { "label": "Construction", "value": "construction" },
  { "label": "Consumer", "value": "consumer" },
  { "label": "Education", "value": "education" },
  { "label": "Electronics", "value": "electronics" },
  { "label": "Energy", "value": "energy" },
  { "label": "Engineering", "value": "engineering" },
  { "label": "Fast Moving Consumer Goods", "value": "fast_moving_consumer_goods" },
  { "label": "Financial", "value": "financial" },
  { "label": "Fintech", "value": "fintech" },
  { "label": "Food and Beverage", "value": "food_and_beverage" },
  { "label": "Government", "value": "government" },
  { "label": "Healthcare", "value": "healthcare" },
  { "label": "Hospitality", "value": "hospitality" },
  { "label": "Insurance", "value": "insurance" },
  { "label": "Jewelry", "value": "jewelry" },
  { "label": "Legal", "value": "legal" },
  { "label": "Manufacturing", "value": "manufacturing" },
  { "label": "Media", "value": "media" },
  { "label": "Not for Profit", "value": "not_for_profit" },
  { "label": "Oil and Gas", "value": "oil_and_gas" },
  { "label": "Online", "value": "online" },
  { "label": "Professional Services", "value": "professional_services" },
  { "label": "Raw Materials", "value": "raw_materials" },
  { "label": "Real Estate", "value": "real_estate" },
  { "label": "Religion", "value": "religion" },
  { "label": "Retail", "value": "retail" },
  { "label": "Technology", "value": "technology" },
  { "label": "Telecommunications", "value": "telecommunications" },
  { "label": "Transportation", "value": "transportation" },
  { "label": "Travel", "value": "travel" },
]

export const A2P_BUSINESS_REGION_OPERATION_OPTIONS: Array<SelectOption<string>> = [
  { "label": "Africa", "value": "africa" },
  { "label": "Asia", "value": "asia" },
  { "label": "Europe", "value": "europe" },
  { "label": "Latin America", "value": "latin_america" },
  { "label": "USA and Canada", "value": "usa_and_canada" },
]

export const A2P_BUSINESS_REGISTRATION_IDENTIFIER_OPTIONS: Array<SelectOption<string>> = [
  { "label": "EIN USA", "value": "EIN" },
  { "label": "DUNS USA", "value": "DUNS" },
  { "label": "CCN Canada", "value": "CBN" },
  { "label": "CN Great Britain", "value": "CN" },
  { "label": "ACN Australia", "value": "ACN" },
  { "label": "CIN India", "value": "CIN" },
  { "label": "VAT Estonia", "value": "VAT" },
  { "label": "VATRN Romania", "value": "VATRN" },
  { "label": "RN Israel", "value": "RN" },
  { "label": "Other", "value": "Other" },
]

export const A2P_BUSINESS_TYPE_OPTIONS: Array<SelectOption<string>> = [
  { "label": "Co-operative", "value": "co_operative" },
  { "label": "Corporation", "value": "corporation" },
  { "label": "Limited Liability Corporation", "value": "limited_liability_corporation" },
  { "label": "Partnership", "value": "partnership" },
]

export const ADS_CTA_OPTIONS: Array<SelectOption<string>> = [
  { label: "Booker", value: "booker" },
  { label: "Phone", value: "phone" },
  { label: "Text", value: "text" },
  { label: "Contact Us", value: "contact_us" },
]

export const ADS_EXPERIENCE_OPTIONS: Array<SelectOption<string>> = [
  { label: "No Experience", value: "no_experience" },
  { label: "Minor", value: "minor" },
  { label: "Medium", value: "medium" },
  { label: "Expert", value: "expert" },
]

export const ADS_MARKETING_GOAL_OPTIONS: Array<SelectOption<string>> = [
  { label: "Brand Awareness", value: "brand_awareness" },
  { label: "Product Promotion", value: "product_promotion" },
  { label: "Remarketing", value: "remarketing" },
  { label: "Local Awareness", value: "local_awareness" },
  { label: "Competitor Targeting", value: "competitor_targeting" },
]

export const ADS_PLATFORM_OPTIONS: Array<SelectOption<string>> = [
  { label: "Google", value: "google" },
  { label: "Meta", value: "meta" },
  { label: "OneLocal Recommandation", value: "onelocal" },
]

export const ADDITIONAL_PAGE_NAMES: Record<OnboardingForm.SitePageType, string> = {
  [ OnboardingForm.SitePageType.ABOUT ]: "About Us",
  [ OnboardingForm.SitePageType.BEFORE_AFTER ]: "Before & After",
  [ OnboardingForm.SitePageType.BLOG ]: "Blog",
  [ OnboardingForm.SitePageType.BRANDS_PARTNERS ]: "Brands / Partners",
  [ OnboardingForm.SitePageType.CAREERS ]: "Careers",
  [ OnboardingForm.SitePageType.CONTACT ]: "Contact",
  [ OnboardingForm.SitePageType.CUSTOM ]: "Custom",
  [ OnboardingForm.SitePageType.FAQ ]: "FAQs",
  [ OnboardingForm.SitePageType.FEATURED_PRODUCTS ]: "Featured Products",
  [ OnboardingForm.SitePageType.GALLERY ]: "Gallery",
  [ OnboardingForm.SitePageType.GENERAL_CONTENT ]: "General Content",
  [ OnboardingForm.SitePageType.HOME ]: "Home",
  [ OnboardingForm.SitePageType.LOCATION ]: "Location",
  [ OnboardingForm.SitePageType.MEDIA ]: "Media",
  [ OnboardingForm.SitePageType.PORTFOLIO ]: "Portfolio",
  [ OnboardingForm.SitePageType.PRICING ]: "Pricing and Promotions",
  [ OnboardingForm.SitePageType.PROCESS ]: "Process",
  [ OnboardingForm.SitePageType.RESOURCES ]: "Resources",
  [ OnboardingForm.SitePageType.SERVICE ]: "Service",
  [ OnboardingForm.SitePageType.SERVICE_AREA ]: "Service Area",
  [ OnboardingForm.SitePageType.SERVICE_CATEGORY ]: "Service Category",
  [ OnboardingForm.SitePageType.TEAM ]: "Our Team",
  [ OnboardingForm.SitePageType.THANKYOU ]: "Thank You",
}

export const ASSET_SOURCING_OPTIONS: Array<SelectOption<string>> = [
  { label: "Client Supplied & Stock Photos", value: "client_supplied_stock_photos" },
  { label: "Stock Photos", value: "stock_photos" },
  { label: "Client Supplied", value: "client_supplied" },
]

export const CONTENT_SOURCING_OPTIONS: Array<SelectOption<string>> = [
  { label: "Client Supplied - Website or Other", value: "client_supplied_website_other" },
  { label: "OneLocal Supplied", value: "onelocal_supplied" },
]

export const COUNTRIES_OPTIONS = [
  { value: "AF", label: "Afghanistan" },
  { value: "AX", label: "Åland Islands" },
  { value: "AL", label: "Albania" },
  { value: "DZ", label: "Algeria" },
  { value: "AS", label: "American Samoa" },
  { value: "AD", label: "Andorra" },
  { value: "AO", label: "Angola" },
  { value: "AI", label: "Anguilla" },
  { value: "AQ", label: "Antarctica" },
  { value: "AG", label: "Antigua & Barbuda" },
  { value: "AR", label: "Argentina" },
  { value: "AM", label: "Armenia" },
  { value: "AW", label: "Aruba" },
  { value: "AC", label: "Ascension Island" },
  { value: "AU", label: "Australia" },
  { value: "AT", label: "Austria" },
  { value: "AZ", label: "Azerbaijan" },
  { value: "BS", label: "Bahamas" },
  { value: "BH", label: "Bahrain" },
  { value: "BD", label: "Bangladesh" },
  { value: "BB", label: "Barbados" },
  { value: "BY", label: "Belarus" },
  { value: "BE", label: "Belgium" },
  { value: "BZ", label: "Belize" },
  { value: "BJ", label: "Benin" },
  { value: "BM", label: "Bermuda" },
  { value: "BT", label: "Bhutan" },
  { value: "BO", label: "Bolivia" },
  { value: "BA", label: "Bosnia & Herzegovina" },
  { value: "BW", label: "Botswana" },
  { value: "BR", label: "Brazil" },
  { value: "IO", label: "British Indian Ocean Territory" },
  { value: "VG", label: "British Virgin Islands" },
  { value: "BN", label: "Brunei" },
  { value: "BG", label: "Bulgaria" },
  { value: "BF", label: "Burkina Faso" },
  { value: "BI", label: "Burundi" },
  { value: "KH", label: "Cambodia" },
  { value: "CM", label: "Cameroon" },
  { value: "CA", label: "Canada" },
  { value: "IC", label: "Canary Islands" },
  { value: "CV", label: "Cape Verde" },
  { value: "BQ", label: "Caribbean Netherlands" },
  { value: "KY", label: "Cayman Islands" },
  { value: "CF", label: "Central African Republic" },
  { value: "EA", label: "Ceuta & Melilla" },
  { value: "TD", label: "Chad" },
  { value: "CL", label: "Chile" },
  { value: "CN", label: "China" },
  { value: "CX", label: "Christmas Island" },
  { value: "CC", label: "Cocos (Keeling) Islands" },
  { value: "CO", label: "Colombia" },
  { value: "KM", label: "Comoros" },
  { value: "CG", label: "Congo - Brazzaville" },
  { value: "CD", label: "Congo - Kinshasa" },
  { value: "CK", label: "Cook Islands" },
  { value: "CR", label: "Costa Rica" },
  { value: "CI", label: "Côte d’Ivoire" },
  { value: "HR", label: "Croatia" },
  { value: "CU", label: "Cuba" },
  { value: "CW", label: "Curaçao" },
  { value: "CY", label: "Cyprus" },
  { value: "CZ", label: "Czech Republic" },
  { value: "DK", label: "Denmark" },
  { value: "DG", label: "Diego Garcia" },
  { value: "DJ", label: "Djibouti" },
  { value: "DM", label: "Dominica" },
  { value: "DO", label: "Dominican Republic" },
  { value: "EC", label: "Ecuador" },
  { value: "EG", label: "Egypt" },
  { value: "SV", label: "El Salvador" },
  { value: "GQ", label: "Equatorial Guinea" },
  { value: "ER", label: "Eritrea" },
  { value: "EE", label: "Estonia" },
  { value: "ET", label: "Ethiopia" },
  { value: "FK", label: "Falkland Islands" },
  { value: "FO", label: "Faroe Islands" },
  { value: "FJ", label: "Fiji" },
  { value: "FI", label: "Finland" },
  { value: "FR", label: "France" },
  { value: "GF", label: "French Guiana" },
  { value: "PF", label: "French Polynesia" },
  { value: "TF", label: "French Southern Territories" },
  { value: "GA", label: "Gabon" },
  { value: "GM", label: "Gambia" },
  { value: "GE", label: "Georgia" },
  { value: "DE", label: "Germany" },
  { value: "GH", label: "Ghana" },
  { value: "GI", label: "Gibraltar" },
  { value: "GR", label: "Greece" },
  { value: "GL", label: "Greenland" },
  { value: "GD", label: "Grenada" },
  { value: "GP", label: "Guadeloupe" },
  { value: "GU", label: "Guam" },
  { value: "GT", label: "Guatemala" },
  { value: "GG", label: "Guernsey" },
  { value: "GN", label: "Guinea" },
  { value: "GW", label: "Guinea-Bissau" },
  { value: "GY", label: "Guyana" },
  { value: "HT", label: "Haiti" },
  { value: "HN", label: "Honduras" },
  { value: "HK", label: "Hong Kong SAR China" },
  { value: "HU", label: "Hungary" },
  { value: "IS", label: "Iceland" },
  { value: "IN", label: "India" },
  { value: "ID", label: "Indonesia" },
  { value: "IR", label: "Iran" },
  { value: "IQ", label: "Iraq" },
  { value: "IE", label: "Ireland" },
  { value: "IM", label: "Isle of Man" },
  { value: "IL", label: "Israel" },
  { value: "IT", label: "Italy" },
  { value: "JM", label: "Jamaica" },
  { value: "JP", label: "Japan" },
  { value: "JE", label: "Jersey" },
  { value: "JO", label: "Jordan" },
  { value: "KZ", label: "Kazakhstan" },
  { value: "KE", label: "Kenya" },
  { value: "KI", label: "Kiribati" },
  { value: "XK", label: "Kosovo" },
  { value: "KW", label: "Kuwait" },
  { value: "KG", label: "Kyrgyzstan" },
  { value: "LA", label: "Laos" },
  { value: "LV", label: "Latvia" },
  { value: "LB", label: "Lebanon" },
  { value: "LS", label: "Lesotho" },
  { value: "LR", label: "Liberia" },
  { value: "LY", label: "Libya" },
  { value: "LI", label: "Liechtenstein" },
  { value: "LT", label: "Lithuania" },
  { value: "LU", label: "Luxembourg" },
  { value: "MO", label: "Macau SAR China" },
  { value: "MK", label: "Macedonia" },
  { value: "MG", label: "Madagascar" },
  { value: "MW", label: "Malawi" },
  { value: "MY", label: "Malaysia" },
  { value: "MV", label: "Maldives" },
  { value: "ML", label: "Mali" },
  { value: "MT", label: "Malta" },
  { value: "MH", label: "Marshall Islands" },
  { value: "MQ", label: "Martinique" },
  { value: "MR", label: "Mauritania" },
  { value: "MU", label: "Mauritius" },
  { value: "YT", label: "Mayotte" },
  { value: "MX", label: "Mexico" },
  { value: "FM", label: "Micronesia" },
  { value: "MD", label: "Moldova" },
  { value: "MC", label: "Monaco" },
  { value: "MN", label: "Mongolia" },
  { value: "ME", label: "Montenegro" },
  { value: "MS", label: "Montserrat" },
  { value: "MA", label: "Morocco" },
  { value: "MZ", label: "Mozambique" },
  { value: "MM", label: "Myanmar (Burma)" },
  { value: "NA", label: "Namibia" },
  { value: "NR", label: "Nauru" },
  { value: "NP", label: "Nepal" },
  { value: "NL", label: "Netherlands" },
  { value: "NC", label: "New Caledonia" },
  { value: "NZ", label: "New Zealand" },
  { value: "NI", label: "Nicaragua" },
  { value: "NE", label: "Niger" },
  { value: "NG", label: "Nigeria" },
  { value: "NU", label: "Niue" },
  { value: "NF", label: "Norfolk Island" },
  { value: "KP", label: "North Korea" },
  { value: "MP", label: "Northern Mariana Islands" },
  { value: "NO", label: "Norway" },
  { value: "OM", label: "Oman" },
  { value: "PK", label: "Pakistan" },
  { value: "PW", label: "Palau" },
  { value: "PS", label: "Palestinian Territories" },
  { value: "PA", label: "Panama" },
  { value: "PG", label: "Papua New Guinea" },
  { value: "PY", label: "Paraguay" },
  { value: "PE", label: "Peru" },
  { value: "PH", label: "Philippines" },
  { value: "PN", label: "Pitcairn Islands" },
  { value: "PL", label: "Poland" },
  { value: "PT", label: "Portugal" },
  { value: "PR", label: "Puerto Rico" },
  { value: "QA", label: "Qatar" },
  { value: "RE", label: "Réunion" },
  { value: "RO", label: "Romania" },
  { value: "RU", label: "Russia" },
  { value: "RW", label: "Rwanda" },
  { value: "WS", label: "Samoa" },
  { value: "SM", label: "San Marino" },
  { value: "ST", label: "São Tomé & Príncipe" },
  { value: "SA", label: "Saudi Arabia" },
  { value: "SN", label: "Senegal" },
  { value: "RS", label: "Serbia" },
  { value: "SC", label: "Seychelles" },
  { value: "SL", label: "Sierra Leone" },
  { value: "SG", label: "Singapore" },
  { value: "SX", label: "Sint Maarten" },
  { value: "SK", label: "Slovakia" },
  { value: "SI", label: "Slovenia" },
  { value: "SB", label: "Solomon Islands" },
  { value: "SO", label: "Somalia" },
  { value: "ZA", label: "South Africa" },
  { value: "GS", label: "South Georgia & South Sandwich Islands" },
  { value: "KR", label: "South Korea" },
  { value: "SS", label: "South Sudan" },
  { value: "ES", label: "Spain" },
  { value: "LK", label: "Sri Lanka" },
  { value: "BL", label: "St. Barthélemy" },
  { value: "SH", label: "St. Helena" },
  { value: "KN", label: "St. Kitts & Nevis" },
  { value: "LC", label: "St. Lucia" },
  { value: "MF", label: "St. Martin" },
  { value: "PM", label: "St. Pierre & Miquelon" },
  { value: "VC", label: "St. Vincent & Grenadines" },
  { value: "SD", label: "Sudan" },
  { value: "SR", label: "Suriname" },
  { value: "SJ", label: "Svalbard & Jan Mayen" },
  { value: "SZ", label: "Swaziland" },
  { value: "SE", label: "Sweden" },
  { value: "CH", label: "Switzerland" },
  { value: "SY", label: "Syria" },
  { value: "TW", label: "Taiwan" },
  { value: "TJ", label: "Tajikistan" },
  { value: "TZ", label: "Tanzania" },
  { value: "TH", label: "Thailand" },
  { value: "TL", label: "Timor-Leste" },
  { value: "TG", label: "Togo" },
  { value: "TK", label: "Tokelau" },
  { value: "TO", label: "Tonga" },
  { value: "TT", label: "Trinidad & Tobago" },
  { value: "TA", label: "Tristan da Cunha" },
  { value: "TN", label: "Tunisia" },
  { value: "TR", label: "Turkey" },
  { value: "TM", label: "Turkmenistan" },
  { value: "TC", label: "Turks & Caicos Islands" },
  { value: "TV", label: "Tuvalu" },
  { value: "UM", label: "U.S. Outlying Islands" },
  { value: "VI", label: "U.S. Virgin Islands" },
  { value: "UG", label: "Uganda" },
  { value: "UA", label: "Ukraine" },
  { value: "AE", label: "United Arab Emirates" },
  { value: "GB", label: "United Kingdom" },
  { value: "US", label: "USA" },
  { value: "UY", label: "Uruguay" },
  { value: "UZ", label: "Uzbekistan" },
  { value: "VU", label: "Vanuatu" },
  { value: "VA", label: "Vatican City" },
  { value: "VE", label: "Venezuela" },
  { value: "VN", label: "Vietnam" },
  { value: "WF", label: "Wallis & Futuna" },
  { value: "EH", label: "Western Sahara" },
  { value: "YE", label: "Yemen" },
  { value: "ZM", label: "Zambia" },
  { value: "ZW", label: "Zimbabwe" },
]

export const CREATIVE_TONES_OPTIONS: Array<SelectOption<string>> = [
  { label: "Dark", value: "dark" },
  { label: "Light", value: "light" },
  { label: "Colourfull", value: "colourfull" },
  { label: "Monocromatic Tone", value: "monocromatic_tone" },
  { label: "Grey Tone", value: "grey_tone" },
  { label: "Use Client Site For Art Direction", value: "use_client_site_for_art_direction" },
]

export const DASHBOARD_TYPE_OPTIONS: Array<SelectOption<string>> = [
  { label: "Multi-Location Parent Child Account", value: OnboardingForm.DashboardType.MULTI_LOCATION_PARENT_CHILD },
  { label: "Multi-Location Single Dashboard", value: OnboardingForm.DashboardType.MULTI_LOCATION_SINGLE },
  { label: "Single Location", value: OnboardingForm.DashboardType.SINGLE_LOCATION },
]

export const FIELD_LABELS: Record<OnboardingForm.Field, string> = {
  [ OnboardingForm.Field.A2P_BUSINESS_EIN ]: "10DLC - Business EIN",
  [ OnboardingForm.Field.A2P_BUSINESS_IDENTITY ]: "Business Identity",
  [ OnboardingForm.Field.A2P_BUSINESS_INDUSTRY ]: "Business Industry ",
  [ OnboardingForm.Field.A2P_BUSINESS_NAME ]: "Legal Business Name",
  [ OnboardingForm.Field.A2P_BUSINESS_REGION_OPERATION ]: "Business Region of Operation",
  [ OnboardingForm.Field.A2P_BUSINESS_REGISTRATION_IDENTIFIER ]: "Business Registration Identifier",
  [ OnboardingForm.Field.A2P_BUSINESS_TYPE ]: "10DLC - Business Type",
  [ OnboardingForm.Field.ADS_ACTION ]: "What action would you like your customers to take",
  [ OnboardingForm.Field.ADS_CLIENT_EXPERIENCE ]: "Client's Experience With ADs",
  [ OnboardingForm.Field.ADS_DISCOUNT_PROMO_DETAILS ]: "Are there any discounts or promotions the client would like to include in the ad?",
  [ OnboardingForm.Field.ADS_GOOGLE_ACCOUNT_ACCESS ]: "Do they have access to their Google Ads account?",
  [ OnboardingForm.Field.ADS_GOOGLE_CID ]: "Google CUSTOMER ID number",
  [ OnboardingForm.Field.ADS_MARKETING_GOAL ]: "What are your main marketing goals?",
  [ OnboardingForm.Field.ADS_PLATFORMS ]: "What Platforms does the client want us to use",
  [ OnboardingForm.Field.ADS_SERVICES ]: "What services would you like our teams to focus on",
  [ OnboardingForm.Field.ADS_SPEND ]: "Ads Spend",
  [ OnboardingForm.Field.AREA_OF_OPERATION ]: "Describe area of operation or service area",
  [ OnboardingForm.Field.ASSET_SOURCING ]: "Asset Sourcing",
  [ OnboardingForm.Field.ASSET_SOURCING_URL ]: "Asset Sourcing URL",
  [ OnboardingForm.Field.BILLING_ADDRESS_CITY ]: "Billing City",
  [ OnboardingForm.Field.BILLING_ADDRESS_COUNTRY ]: "Billing Country",
  [ OnboardingForm.Field.BILLING_ADDRESS_POSTAL_CODE ]: "Billing Zip/Postal Code",
  [ OnboardingForm.Field.BILLING_ADDRESS_SAME_AS_BUSINESS ]: "Is Billing address same as business address?",
  [ OnboardingForm.Field.BILLING_ADDRESS_STATE ]: "Billing State/Province",
  [ OnboardingForm.Field.BILLING_ADDRESS_STREET ]: "Billing Street",
  [ OnboardingForm.Field.BUSINESS_CREATION_HISTORY ]: "Why was the business founded",
  [ OnboardingForm.Field.BUSINESS_CREATION_YEAR ]: "Founded Year",
  [ OnboardingForm.Field.BUSINESS_MESSAGING ]: "Business messaging",
  [ OnboardingForm.Field.BUSINESS_REGISTRATION_NUMBER ]: "What is the clients Business Registration Number",
  [ OnboardingForm.Field.COMPANY_ADDRESS_CITY ]: "City",
  [ OnboardingForm.Field.COMPANY_ADDRESS_COUNTRY ]: "Country",
  [ OnboardingForm.Field.COMPANY_ADDRESS_POSTAL_CODE ]: "Postal Code",
  [ OnboardingForm.Field.COMPANY_ADDRESS_STATE ]: "State",
  [ OnboardingForm.Field.COMPANY_ADDRESS_STREET ]: "Street Address",
  [ OnboardingForm.Field.COMPANY_DASHBOARD_TYPE ]: "Is this merchant a single location or multi-location?",
  [ OnboardingForm.Field.COMPANY_EMAIL ]: "Company Email",
  [ OnboardingForm.Field.COMPANY_NAME ]: "Company Name",
  [ OnboardingForm.Field.COMPANY_PHONE_NUMBER ]: "Company Phone Number",
  [ OnboardingForm.Field.COMPETITOR_1 ]: "Competitor 1",
  [ OnboardingForm.Field.COMPETITOR_2 ]: "Competitor 2",
  [ OnboardingForm.Field.COMPETITOR_3 ]: "Competitor 3",
  [ OnboardingForm.Field.CONTACT_EMAIL ]: "Contact Email",
  [ OnboardingForm.Field.CONTACT_FIRST_NAME ]: "Contact First Name",
  [ OnboardingForm.Field.CONTACT_LAST_NAME ]: "Contact Last Name",
  [ OnboardingForm.Field.CONTACT_PHONE_NUMBER ]: "Contact Phone Number",
  [ OnboardingForm.Field.CONTENT_SOURCING ]: "Content Sourcing",
  [ OnboardingForm.Field.CREATIVE_TONE ]: "Overall creative tone",
  [ OnboardingForm.Field.CURRENT_SITE_URL ]: "Current Site URL",
  [ OnboardingForm.Field.CURRENT_SITE_URL_MISSING ]: "Current Site URL does not exists",
  [ OnboardingForm.Field.EMPLOYEE_COUNT ]: "Number of employee",
  [ OnboardingForm.Field.EXCLUDED_KEYWORDS ]: "Any terms or keywords they don't want to be used about their business?",
  [ OnboardingForm.Field.FACEBOOK_ID ]: "Facebook ID",
  [ OnboardingForm.Field.GOOGLE_DRIVE_FOLDER_URL ]: "Google Drive Folder URL",
  [ OnboardingForm.Field.IFRAME_CODE_PAGES ]: "Iframe Code",
  [ OnboardingForm.Field.IFRAME_CODE_SERVICES ]: "Iframe Code",
  [ OnboardingForm.Field.INSTAGRAM_ID ]: "Instagram ID",
  [ OnboardingForm.Field.KICKOFF_DATE ]: "Kick-Off Date",
  [ OnboardingForm.Field.NEW_DOMAIN ]: "New Domain",
  [ OnboardingForm.Field.NOTES_ABOUT_US ]: "Notes",
  [ OnboardingForm.Field.NOTES_ADDITIONAL_PAGES ]: "Notes",
  [ OnboardingForm.Field.NOTES_ADS ]: "Notes",
  [ OnboardingForm.Field.NOTES_COMPANY_DETAILS ]: "Notes",
  [ OnboardingForm.Field.NOTES_CREATIVE_DIRECTION ]: "Notes",
  [ OnboardingForm.Field.NOTES_PRODUCT ]: "Notes",
  [ OnboardingForm.Field.NOTES_SERVICES ]: "Notes",
  [ OnboardingForm.Field.NOTES_SITE ]: "Notes",
  [ OnboardingForm.Field.NOTES_SOCIAL_CONTENT ]: "Notes",
  [ OnboardingForm.Field.OFFERS ]: "What do they offer?",
  [ OnboardingForm.Field.PACKAGE_PURCHASED ]: "Package Purchased",
  [ OnboardingForm.Field.PRIMARY_INDUSTRY ]: "Primary Industry",
  [ OnboardingForm.Field.SITE_EXAMPLES ]: "Example sites shown to the client",
  [ OnboardingForm.Field.SITE_PAGE_COUNT ]: "Number Of Site Pages Purchased",
  [ OnboardingForm.Field.SITE_TEMPLATE ]: "Site Template",
  [ OnboardingForm.Field.SOCIAL_ADD_ON ]: "Social Add-On",
  [ OnboardingForm.Field.SOCIAL_CONTENT_CURRENT_BLOG_MOVE ]: "Are there any current blogs on the client's existing site that need to be moved over?",
  [ OnboardingForm.Field.SOCIAL_CONTENT_FB_ENABLED ]: "Facebook",
  [ OnboardingForm.Field.SOCIAL_CONTENT_GBP_ENABLED ]: "GBP",
  [ OnboardingForm.Field.SOCIAL_CONTENT_IG_ENABLED ]: "Instagram",
  [ OnboardingForm.Field.SUB_INDUSTRY_1 ]: "Sub Industry 1",
  [ OnboardingForm.Field.SUB_INDUSTRY_2 ]: "Sub Industry 2",
  [ OnboardingForm.Field.SUB_INDUSTRY_3 ]: "Sub Industry 3",
  [ OnboardingForm.Field.SUB_INDUSTRY_4 ]: "Sub Industry 4",
  [ OnboardingForm.Field.SUB_INDUSTRY_5 ]: "Sub Industry 5",
  [ OnboardingForm.Field.TAX_ADDRESS_CITY ]: "Tax City",
  [ OnboardingForm.Field.TAX_ADDRESS_COUNTRY ]: "Tax Country",
  [ OnboardingForm.Field.TAX_ADDRESS_POSTAL_CODE ]: "Tax Zip/Postal Code",
  [ OnboardingForm.Field.TAX_ADDRESS_SAME_AS_BILLING ]: "Is Tax address same as Billing address?",
  [ OnboardingForm.Field.TAX_ADDRESS_STATE ]: "Tax State/Province",
  [ OnboardingForm.Field.TAX_ADDRESS_STREET ]: "Tax Street",
  [ OnboardingForm.Field.WRITTING_PERSPECTIVE ]: "Writing perspective",
  [ OnboardingForm.Field.WRITTING_TONE ]: "Writing tone of voice",
} as Record<OnboardingForm.Field, string>

export const GBP_INDUSTRIES = [
  "Aadhaar center",
  "Abarth dealer",
  "Abbey",
  "Aboriginal and Torres Strait Islander organisation",
  "Aboriginal art gallery",
  "Abortion clinic",
  "Abrasives supplier",
  "Abundant Life church",
  "Açaí shop",
  "Acaraje restaurant",
  "Accountant",
  "Accounting firm",
  "Accounting school",
  "Accounting software company",
  "Acoustical consultant",
  "Acrobatic diving pool",
  "Acrylic store",
  "Acupuncture clinic",
  "Acupuncture school",
  "Acupuncturist",
  "Acura dealer",
  "Addiction treatment center",
  "Administrative attorney",
  "Adoption agency",
  "Adult day care center",
  "Adult DVD store",
  "Adult education school",
  "Adult entertainment club",
  "Adult entertainment store",
  "Adult foster care service",
  "Adventure sports",
  "Adventure sports center",
  "Advertising agency",
  "Commercial photographer",
  "Aerated drinks supplier",
  "Antenna service",
  "Aerial photographer",
  "Aerial sports center",
  "Aero dance class",
  "Aerobics instructor",
  "Aeroclub",
  "Aeromodel shop",
  "Aeronautical engineer",
  "Aerospace company",
  "Afghan restaurant",
  "African goods store",
  "African restaurant",
  "After school program",
  "Agenzia Entrate",
  "Aggregate supplier",
  "Agistment service",
  "Agricultural association",
  "Agricultural cooperative",
  "Agricultural engineer",
  "Agricultural high school",
  "Agricultural machinery manufacturer",
  "Agricultural organization",
  "Agricultural product wholesaler",
  "Agricultural production",
  "Agricultural service",
  "Agrochemicals supplier",
  "Aikido club",
  "Aikido school",
  "Air compressor repair service",
  "Air compressor supplier",
  "Air conditioning contractor",
  "Air conditioning repair service",
  "Air conditioning store",
  "Air conditioning system supplier",
  "Air duct cleaning service",
  "Air filter supplier",
  "Air force base",
  "Air taxi",
  "Airbrushing service",
  "Airbrushing supply store",
  "Aircraft dealer",
  "Aircraft maintenance company",
  "Aircraft manufacturer",
  "Aircraft rental service",
  "Aircraft supply store",
  "Airline",
  "Airline ticket agency",
  "Airplane",
  "Airport",
  "Airport shuttle service",
  "Airsoft supply store",
  "Airstrip",
  "Alcohol manufacturer",
  "Alcohol retail monopoly",
  "Alcoholic beverage wholesaler",
  "Alcoholism treatment program",
  "Alfa Romeo dealer",
  "Allergist",
  "Alliance church",
  "Alsace restaurant",
  "Alternative fuel station",
  "Alternative medicine practitioner",
  "Alternator supplier",
  "Aluminum supplier",
  "Aluminum frames supplier",
  "Aluminum welder",
  "Aluminum window",
  "Amateur theater",
  "Ambulance service",
  "American grocery store",
  "American restaurant",
  "Amish furniture store",
  "Ammunition supplier",
  "Amphitheater",
  "Amusement center",
  "Amusement machine supplier",
  "Amusement park",
  "Amusement park ride",
  "Amusement ride supplier",
  "Anhui restaurant",
  "Anago restaurant",
  "Andalusian restaurant",
  "Andhra restaurant",
  "Anesthesiologist",
  "Anganwadi center",
  "Angler fish restaurant",
  "Anglican church",
  "Animal control service",
  "Animal hospital",
  "Animal park",
  "Animal protection organization",
  "Animal rescue service",
  "Animal shelter",
  "Animal watering hole",
  "Animation studio",
  "Anime club",
  "Anodizing service",
  "Antique furniture restoration service",
  "Antique furniture store",
  "Antique store",
  "Apartment building",
  "Apartment complex",
  "Apartment rental agency",
  "Apostolic church",
  "Appliance parts supplier",
  "Appliance rental service",
  "Appliance repair service",
  "Appliance store",
  "Appliances customer service",
  "Appraiser",
  "Apprenticeship center",
  "Aquaculture farm",
  "Aquarium",
  "Aquarium shop",
  "Aquatic centre",
  "Arab restaurant",
  "Arboretum",
  "Arborist and tree surgeon",
  "Archaeological museum",
  "Archery club",
  "Archery range",
  "Archery store",
  "Architect",
  "Architects association",
  "Architectural and engineering model maker",
  "Architectural designer",
  "Architectural salvage store",
  "Architecture firm",
  "Architecture school",
  "Archive",
  "Arena",
  "Argentinian restaurant",
  "Armed forces association",
  "Armenian church",
  "Armenian restaurant",
  "Army & navy surplus shop",
  "Army barracks",
  "Army facility",
  "Army museum",
  "Aromatherapy class",
  "Aromatherapy service",
  "Aromatherapy supply store",
  "Art cafe",
  "Art center",
  "Art dealer",
  "Art gallery",
  "Art museum",
  "Art restoration service",
  "Art school",
  "Art studio",
  "Art supply store",
  "Artificial plant supplier",
  "Artist",
  "Arts organization",
  "Grill",
  "Asbestos testing service",
  "Ashram",
  "Asian fusion restaurant",
  "Asian grocery store",
  "Asian household goods store",
  "Asian restaurant",
  "Asphalt contractor",
  "Asphalt mixing plant",
  "Assamese restaurant",
  "Assemblies of God church",
  "Assistante maternelle",
  "Assisted living facility",
  "Association or organization",
  "Aston Martin dealer",
  "Astrologer",
  "Asturian restaurant",
  "Athletic club",
  "Athletic field",
  "Athletic park",
  "Athletic track",
  "ATM",
  "Attorney referral service",
  "ATV dealer",
  "ATV rental service",
  "ATV repair shop",
  "Auction house",
  "Audi dealer",
  "Audio visual consultant",
  "Audiovisual equipment rental service",
  "Audio visual equipment repair service",
  "Audio visual equipment supplier",
  "Audiologist",
  "Auditor",
  "Auditorium",
  "Australian goods store",
  "Australian restaurant",
  "Austrian restaurant",
  "Auto accessories wholesaler",
  "Auto air conditioning service",
  "Auto auction",
  "Auto body parts supplier",
  "Auto body shop",
  "Auto bodywork mechanic",
  "Auto broker",
  "Auto chemistry shop",
  "Auto dent removal service",
  "Auto electrical service",
  "Auto glass repair service",
  "Auto glass shop",
  "Auto insurance agency",
  "Auto machine shop",
  "Auto market",
  "Auto painting",
  "Auto parts manufacturer",
  "Auto parts market",
  "Auto parts store",
  "Auto radiator repair service",
  "Auto repair shop",
  "Auto restoration service",
  "Auto rickshaw stand",
  "Auto spring shop",
  "Auto sunroof shop",
  "Auto tag agency",
  "Auto tune up service",
  "Auto upholsterer",
  "Auto window tinting service",
  "Auto wrecker",
  "Automation company",
  "Automobile storage facility",
  "Aviation consultant",
  "Aviation training institute",
  "Awadhi restaurant",
  "Awning supplier",
  "Ayam penyet restaurant",
  "Ayurvedic clinic",
  "Azerbaijani restaurant",
  "Baby clothing store",
  "Baby store",
  "Baby swimming school",
  "Childminder",
  "Baden restaurant",
  "Badminton club",
  "Badminton complex",
  "Badminton court",
  "Bag shop",
  "Bagel shop",
  "Bahá'í house of worship",
  "Bail bonds service",
  "Bait shop",
  "Bakery",
  "Bakery equipment",
  "Baking supply store",
  "Bakso restaurant",
  "Balinese restaurant",
  "Ballet school",
  "Ballet theater",
  "Balloon artist",
  "Balloon ride tour agency",
  "Balloon store",
  "Ballroom dance instructor",
  "Band",
  "Bangladeshi restaurant",
  "Bangle Shop",
  "Bank",
  "Bankruptcy attorney",
  "Bankruptcy service",
  "Banner store",
  "Banquet hall",
  "Baptist church",
  "Bar",
  "Bar & grill",
  "Bar PMU",
  "Bar restaurant furniture store",
  "Bar stool supplier",
  "Bar tabac",
  "Barbecue restaurant",
  "Barbecue area",
  "Barber school",
  "Barber shop",
  "Barber supply store",
  "Bariatric surgeon",
  "Bark supplier",
  "Barrel supplier",
  "Barrister",
  "Bartending school",
  "Baseball club",
  "Baseball field",
  "Baseball goods store",
  "Basilica",
  "Basket supplier",
  "Basketball club",
  "Basketball court",
  "Basketball court contractor",
  "Basque restaurant",
  "Batak restaurant",
  "Bathroom remodeler",
  "Bathroom supply store",
  "Batik clothing store",
  "Battery manufacturer",
  "Battery store",
  "Battery wholesaler",
  "Batting cage center",
  "Bavarian restaurant",
  "Bazar",
  "BBQ area",
  "Beach cleaning service",
  "Beach clothing store",
  "Beach entertainment shop",
  "Beach pavillion",
  "Beach volleyball club",
  "Beach volleyball court",
  "Bead store",
  "Bead wholesaler",
  "Bearing supplier",
  "Beautician",
  "Beauty product supplier",
  "Beauty products vending machine",
  "Beauty products wholesaler",
  "Beauty salon",
  "Beauty school",
  "Beauty supply store",
  "Bed & breakfast",
  "Bed shop",
  "Bedding store",
  "Bedroom furniture store",
  "Gyudon restaurant",
  "Beer distributor",
  "Beer garden",
  "Beer hall",
  "Beer store",
  "Belgian restaurant",
  "Belt shop",
  "Bengali restaurant",
  "Bentley dealer",
  "Berry restaurant",
  "Berufsfachschule (vocational school with apprenticeship)",
  "Betawi restaurant",
  "Betting agency",
  "Beverage distributor",
  "Bicycle club",
  "Bicycle rack",
  "Bicycle rental service",
  "Bicycle repair shop",
  "Bicycle Shop",
  "Bicycle wholesaler",
  "Bike wash",
  "Bikram yoga studio",
  "Bilingual school",
  "Billiards supply store",
  "Bingo hall",
  "Biochemical supplier",
  "Biochemistry lab",
  "Biofeedback therapist",
  "Biotechnology company",
  "Biotechnology engineer",
  "Bird control service",
  "Bird shop",
  "Bird watching area",
  "Birth center",
  "Birth certificate service",
  "Birth control center",
  "Biryani restaurant",
  "Bistro",
  "Blacksmith",
  "Blast cleaning service",
  "Blind school",
  "Blinds shop",
  "Blood bank",
  "Blood donation center",
  "Blood testing service",
  "Blueprint service",
  "Blues club",
  "BMW dealer",
  "BMW motorcycle dealer",
  "BMX club",
  "BMX park",
  "BMX track",
  "Board game club",
  "Board of education",
  "Boarding house",
  "Boarding school",
  "Boat accessories supplier",
  "Boat builders",
  "Boat club",
  "Boat cover supplier",
  "Boat dealer",
  "Boat ramp",
  "Boat rental service",
  "Boat repair shop",
  "Boat storage facility",
  "Boat tour agency",
  "Boat trailer dealer",
  "Boating instructor",
  "Bocce ball court",
  "Body piercing shop",
  "Body shaping class",
  "Boiler manufacturer",
  "Boiler supplier",
  "Bonesetting house",
  "Bonsai plant supplier",
  "Book publisher",
  "Book store",
  "Bookbinder",
  "Bookkeeping service",
  "Bookmaker",
  "Books wholesaler",
  "Boot camp",
  "Boot repair shop",
  "Boot store",
  "Border crossing station",
  "Border guard",
  "Botanical garden",
  "Bottle & can redemption center",
  "Bottled water supplier",
  "Bouncy castle hire",
  "Boutique",
  "Bowling alley",
  "Bowling club",
  "Bowling supply shop",
  "Box lunch supplier",
  "Boxing club",
  "Boxing gym",
  "Boxing ring",
  "Boys' high school",
  "BPO company",
  "BPO placement agency",
  "Brake shop",
  "Brazilian pastelaria",
  "Brazilian restaurant",
  "Breakfast restaurant",
  "Brewery",
  "Brewing supply store",
  "Brewpub",
  "Brick manufacturer",
  "Bricklayer",
  "Bridal shop",
  "Bridge",
  "Bridge club",
  "British restaurant",
  "Brunch restaurant",
  "Bubble tea store",
  "Buddhist supplies store",
  "Buddhist temple",
  "Buffet restaurant",
  "Bugatti dealer",
  "Buick dealer",
  "Building consultant",
  "Building design company",
  "Building designer",
  "Building equipment hire service",
  "Building firm",
  "Building inspector",
  "Building materials market",
  "Building materials store",
  "Building materials supplier",
  "Building restoration service",
  "Building society",
  "Chartered surveyor",
  "Bulgarian restaurant",
  "Bullring",
  "Bungee jumping center",
  "Burglar alarm store",
  "Burmese restaurant",
  "Burrito restaurant",
  "Bus and coach company",
  "Bus charter",
  "Bus company",
  "Bus depot",
  "Bus ticket agency",
  "Bus tour agency",
  "Business administration service",
  "Business banking service",
  "Business broker",
  "Business center",
  "Business development service",
  "Business management consultant",
  "Business networking company",
  "Business park",
  "Business school",
  "Business to business service",
  "Butane gas supplier",
  "Butcher shop",
  "Butcher shop deli",
  "Butsudan store",
  "Cabaret club",
  "Cabin rental agency",
  "Cabinet maker",
  "Cabinet store",
  "Cable company",
  "Cadillac dealer",
  "Cafe",
  "Cafeteria",
  "Cajun restaurant",
  "Cake decorating equipment shop",
  "Cake shop",
  "Californian restaurant",
  "Call center",
  "Call shop",
  "Calligraphy lesson",
  "Calvary Chapel church",
  "Cambodian restaurant",
  "Camera repair shop",
  "Camera store",
  "Children's camp",
  "Camper shell supplier",
  "Campground",
  "Camping cabin",
  "Camping farm",
  "Camping store",
  "Pacific Northwest restaurant (Canada)",
  "Canadian restaurant",
  "Cancer treatment center",
  "Candle store",
  "Candy store",
  "Cane furniture store",
  "Cannabis club",
  "Cannabis store",
  "Cannery",
  "Canoe and kayak club",
  "Canoe & kayak rental service",
  "Canoe & kayak store",
  "Canoe & kayak tour agency",
  "Canoeing area",
  "Cantabrian restaurant",
  "Cantonese restaurant",
  "Cape Verdean restaurant",
  "Capoeira school",
  "Capsule hotel",
  "Car accessories store",
  "Car alarm supplier",
  "Car battery store",
  "Car dealer",
  "Car detailing service",
  "Car factory",
  "Car finance and loan company",
  "Car inspection station",
  "Car leasing service",
  "Car manufacturer",
  "Car racing track",
  "Car rental agency",
  "Car repair and maintenance service",
  "Car security system installer",
  "Chauffeur service",
  "Car sharing location",
  "Car stereo store",
  "Car wash",
  "Carabinieri police",
  "Cardiologist",
  "Cardiovascular and thoracic surgeon",
  "Career guidance service",
  "Caribbean restaurant",
  "Carnival club",
  "Carpenter",
  "Carpet cleaning service",
  "Carpet installer",
  "Carpet manufacturer",
  "Carpet store",
  "Carpet wholesaler",
  "Carpool",
  "Carport and pergola builder",
  "Carriage ride service",
  "Carvery",
  "Cash and carry wholesaler",
  "Casino",
  "Casket service",
  "Castilian restaurant",
  "Castle",
  "Syokudo and Teishoku restaurant",
  "Conveyor belt sushi restaurant",
  "Cat boarding service",
  "Cat breeder",
  "Cat trainer",
  "Catalonian restaurant",
  "Catering food and drink supplier",
  "Caterer",
  "Cathedral",
  "Catholic cathedral",
  "Catholic church",
  "Catholic school",
  "Cattery",
  "Cattle farm",
  "Cattle market",
  "Caucasian restaurant",
  "CBSE school",
  "CD store",
  "Ceiling supplier",
  "Cell phone accessory store",
  "Cell phone charging station",
  "Cell phone store",
  "Cement manufacturer",
  "Cement supplier",
  "Cemetery",
  "Cendol restaurant",
  "Central American restaurant",
  "Central authority",
  "Central bank",
  "Central European restaurant",
  "Central Javanese restaurant",
  "Ceramic manufacturer",
  "Ceramics wholesaler",
  "Certification agency",
  "Certified public accountant",
  "Chalet",
  "Chamber of agriculture",
  "Chamber of Commerce",
  "Chamber of handicrafts",
  "Champon noodle restaurant",
  "Chanko restaurant",
  "Chapel",
  "Deli Shop",
  "Charity",
  "Charter school",
  "Chartered accountant",
  "Check cashing service",
  "Cheese manufacturer",
  "Cheese shop",
  "Cheesesteak restaurant",
  "Chemical engineer",
  "Chemical exporter",
  "Chemical manufacturer",
  "Chemical plant",
  "Chemical wholesaler",
  "Chemistry faculty",
  "Chemistry lab",
  "Chesapeake restaurant",
  "Chess and card club",
  "Chess club",
  "Chess instructor",
  "Chettinad restaurant",
  "Chevrolet dealer",
  "Chicken hatchery",
  "Chicken restaurant",
  "Chicken shop",
  "Chicken wings restaurant",
  "Child care agency",
  "Child health care centre",
  "Child psychiatrist",
  "Child psychologist",
  "Childbirth class",
  "Children's amusement center",
  "Children hall",
  "Children Policlinic",
  "Childrens book store",
  "Childrens cafe",
  "Children's clothing store",
  "Childrens club",
  "Children's farm",
  "Children's furniture store",
  "Childrens home",
  "Children's hospital",
  "Childrens library",
  "Children's museum",
  "Childrens party buffet",
  "Children's party service",
  "Childrens store",
  "Childrens theater",
  "Chilean restaurant",
  "Chimney services",
  "Chimney sweep",
  "Chinatown",
  "Chinaware store",
  "Chinese language instructor",
  "Chinese language school",
  "Chinese medicine clinic",
  "Chinese medicine store",
  "Chinese noodle restaurant",
  "Chinese bakery",
  "Chinese restaurant",
  "Chinese supermarket",
  "Chinese takeaway",
  "Chinese tea house",
  "Chiropractor",
  "Chocolate artisan",
  "Chocolate cafe",
  "Chocolate factory",
  "Chocolate shop",
  "Choir",
  "Chop bar",
  "Chophouse restaurant",
  "Christian book store",
  "Christian church",
  "Christian college",
  "Christmas market",
  "Christmas store",
  "Christmas tree farm",
  "Chrysler dealer",
  "Church",
  "Church of Christ",
  "Church of Jesus Christ of Latter-day Saints",
  "Church of the Nazarene",
  "Church supply store",
  "Churreria",
  "Cider bar",
  "Cider mill",
  "Cig kofte restaurant",
  "Cigar shop",
  "Cinema equipment supplier",
  "Circus",
  "Citizen information bureau",
  "Citizens advice bureau",
  "Citroen dealer",
  "City administration",
  "City clerk's office",
  "City courthouse",
  "City Department of Environment",
  "City Department of Public Safety",
  "City department of transportation",
  "City district office",
  "City employment department",
  "City government office",
  "City Hall",
  "City park",
  "City pillar shrine",
  "City tax office",
  "Civic center",
  "Civil defense",
  "Civil engineer",
  "Civil engineering company",
  "Civil examinations academy",
  "Civil law attorney",
  "Civil police",
  "Civil registry",
  "Class",
  "Classified ads newspaper publisher",
  "Cleaners",
  "Cleaning products supplier",
  "Clergyman",
  "Clock repair service",
  "Clock watch maker",
  "Closed circuit television",
  "Clothes and fabric manufacturer",
  "Clothes and fabric wholesaler",
  "Clothes market",
  "Clothing alteration service",
  "Clothing store",
  "Clothing supplier",
  "Clothing wholesale market place",
  "Clothing wholesaler",
  "Club",
  "CNG fitment center",
  "Compressed natural gas station",
  "Coaching center",
  "Coal exporter",
  "Coal supplier",
  "Coalfield",
  "Coast guard station",
  "Coat wholesaler",
  "Cocktail bar",
  "Co-ed school",
  "Coffee machine supplier",
  "Coffee roasters",
  "Coffee shop",
  "Coffee stand",
  "Coffee store",
  "Coffee vending machine",
  "Coffee wholesaler",
  "Coffin supplier",
  "Coin dealer",
  "Coin operated laundry equipment supplier",
  "Coin operated locker",
  "Cold cut store",
  "Cold noodle restaurant",
  "Cold storage facility",
  "Collectibles store",
  "College",
  "College of agriculture",
  "Colombian restaurant",
  "Combined primary and secondary school",
  "Comedy club",
  "Comic book store",
  "Comic cafe",
  "Commercial agent",
  "Commercial cleaning service",
  "Commercial printer",
  "Commercial real estate agency",
  "Commercial real estate inspector",
  "Commercial refrigeration",
  "Commercial refrigerator supplier",
  "Commissioner for Oaths",
  "Community center",
  "Community college",
  "Community garden",
  "Community health centre",
  "Community school",
  "Company registry",
  "Comprehensive secondary school",
  "Computer accessories store",
  "Computer club",
  "Computer consultant",
  "Computer desk store",
  "Computer hardware manufacturer",
  "Computer networking service",
  "Computer repair service",
  "Computer security service",
  "Computer service",
  "Computer software store",
  "Computer store",
  "Computer support and services",
  "Computer training school",
  "Computer wholesaler",
  "Concert hall",
  "Concrete contractor",
  "Concrete factory",
  "Concrete metal framework supplier",
  "Concrete product supplier",
  "Condiments supplier",
  "Condominium complex",
  "Condominium rental agency",
  "Confectionery",
  "Confectionery wholesaler",
  "Conference center",
  "Congregation",
  "Conservation department",
  "Conservative club",
  "Conservative synagogue",
  "Conservatory construction contractor",
  "Conservatory of music",
  "Conservatory supply & installation",
  "Consignment shop",
  "Construction and maintenance office",
  "Construction company",
  "Construction equipment supplier",
  "Construction machine dealer",
  "Construction machine rental service",
  "Construction material wholesaler",
  "Consultant",
  "Consumer advice center",
  "Contact lenses supplier",
  "Container service",
  "Container supplier",
  "Container terminal",
  "Containers supplier",
  "Contemporary Louisiana restaurant",
  "Continental restaurant",
  "Contractor",
  "Convenience store",
  "Convention center",
  "Convention information bureau",
  "Conveyancer",
  "Cookie shop",
  "Cooking class",
  "Cooking school",
  "Cooling plant",
  "Cooperative bank",
  "Copier repair service",
  "Copper supplier",
  "Coppersmith",
  "Copy shop",
  "Copying supply store",
  "Corporate campus",
  "Corporate entertainment service",
  "Corporate gift supplier",
  "Corporate office",
  "Correctional services department",
  "Cosmetic dentist",
  "Cosmetic products manufacturer",
  "Cosmetic surgeon",
  "Cosmetics and perfumes supplier",
  "Cosmetics industry",
  "Cosmetics store",
  "Cosmetics wholesaler",
  "Cosplay cafe",
  "Costa Rican restaurant",
  "Costume jewelry shop",
  "Costume rental service",
  "Costume store",
  "Cottage",
  "Cottage Rental",
  "Cotton exporter",
  "Cotton mill",
  "Cotton supplier",
  "Council",
  "Counselor",
  "Countertop contractor",
  "Countertop store",
  "Country club",
  "Country food restaurant",
  "Country house",
  "Country park",
  "County government office",
  "Courier service",
  "Court executive officer",
  "Court reporter",
  "Couscous restaurant",
  "Couture store",
  "Coworking space",
  "Crab house",
  "Craft store",
  "Cramming school",
  "Crane dealer",
  "Crane rental agency",
  "Crane service",
  "Craniosacral therapy",
  "Creche",
  "Credit counseling service",
  "Credit reporting agency",
  "Credit union",
  "Cremation service",
  "Creole restaurant",
  "Creperie",
  "Cricket club",
  "Cricket ground",
  "Cricket shop",
  "Crime victim service",
  "Criminal justice attorney",
  "Croatian restaurant",
  "Crop grower",
  "Croquet club",
  "Cruise agency",
  "Cruise line company",
  "Cruise terminal",
  "Crushed stone supplier",
  "Crypto ATM",
  "Cuban restaurant",
  "Culinary school",
  "Cultural association",
  "Cultural center",
  "Cultural landmark",
  "Cupcake shop",
  "Cupra dealer",
  "Cured ham bar",
  "Cured ham store",
  "Cured ham warehouse",
  "Curling club",
  "Curling hall",
  "Currency exchange service",
  "Curtain and upholstery cleaning service",
  "Curtain store",
  "Curtain supplier and maker",
  "Custom confiscated goods store",
  "Custom home builder",
  "Custom label printer",
  "Custom t-shirt store",
  "Custom tailor",
  "Customs broker",
  "Customs consultant",
  "Customs department",
  "Customs office",
  "Customs warehouse",
  "Cutlery store",
  "Cycle rickshaw stand",
  "Cycling park",
  "Czech restaurant",
  "Dacia dealer",
  "Daihatsu dealer",
  "Dairy",
  "Dairy farm",
  "Dairy farm equipment supplier",
  "Dairy store",
  "Dairy supplier",
  "Dan Dan noodle restaurant",
  "Dance club",
  "Dance company",
  "Dance conservatory",
  "Dance hall",
  "Dance pavillion",
  "Dance restaurant",
  "Dance school",
  "Dance store",
  "Danish restaurant",
  "Dart bar",
  "Dart supply store",
  "Data entry service",
  "Data recovery service",
  "Database management company",
  "Dating service",
  "Day care center",
  "Day spa",
  "Deaf church",
  "Deaf service",
  "Dealer of Fiat Professional",
  "Debris removal service",
  "Debt collecting",
  "Debt collection agency",
  "Decal supplier",
  "Deck builder",
  "Deli",
  "Delivery Chinese restaurant",
  "Delivery Service",
  "Demolition contractor",
  "Jeans shop",
  "Dental clinic",
  "Dental hygienist",
  "Dental implants periodontist",
  "Dental implants provider",
  "Dental insurance agency",
  "Dental laboratory",
  "Dental radiology",
  "Dental school",
  "Dental supply store",
  "Dentist",
  "Denture care center",
  "Department for Regional Development",
  "Department of education",
  "Department of finance",
  "Department of housing",
  "Department of motor vehicles",
  "Department of Public Safety",
  "Department of Social Services",
  "Department of Transportation",
  "Department store",
  "Dept of City Treasure",
  "Department of State Treasure",
  "Dermatologist",
  "Desalination plant",
  "Design agency",
  "Design engineer",
  "Design institute",
  "Desktop publishing service",
  "Sweets and dessert buffet",
  "Dessert restaurant",
  "Dessert shop",
  "Detention center",
  "Dhaba",
  "Diabetes center",
  "Diabetes equipment supplier",
  "Diabetologist",
  "Diagnostic center",
  "Dialysis center",
  "Diamond buyer",
  "Diamond dealer",
  "Diaper service",
  "Diesel engine dealer",
  "Diesel engine repair service",
  "Diesel fuel supplier",
  "Dietitian",
  "Digital printer",
  "Digital printing service",
  "Dim sum restaurant",
  "Diner",
  "Dinner theater",
  "Direct mail advertising",
  "Dirt supplier",
  "Disability equipment supplier",
  "Disability services and support organization",
  "Disabled sports center",
  "Disc golf course",
  "Disciples of Christ Church",
  "Disco club",
  "Discount store",
  "Discount supermarket",
  "Display home centre",
  "Display stand manufacturer",
  "Disposable tableware supplier",
  "Distance learning center",
  "Distillery",
  "Distribution service",
  "District attorney",
  "District council",
  "District government office",
  "District Justice",
  "District office",
  "Dive club",
  "Dive shop",
  "Diving center",
  "Diving contractor",
  "Divorce lawyer",
  "Divorce service",
  "DJ service",
  "DJ supply store",
  "Do-it-yourself shop",
  "Dock builder",
  "Doctor",
  "Dodge dealer",
  "Dog breeder",
  "Dog cafe",
  "Dog day care center",
  "Dog park",
  "Dog trainer",
  "Dog walker",
  "Dogsled Ride Service",
  "Dojo restaurant",
  "Doll restoration service",
  "Doll store",
  "Dollar store",
  "Domestic abuse treatment center",
  "Domestic airport",
  "Dominican restaurant",
  "Donations center",
  "Doner kebab restaurant",
  "Donut shop",
  "Door manufacturer",
  "Door shop",
  "Door supplier",
  "Door warehouse",
  "Double glazing installer",
  "Doula",
  "Down home cooking restaurant",
  "Drafting equipment supplier",
  "Drafting service",
  "Drainage service",
  "Drama school",
  "Drama theater",
  "Drawing lessons",
  "Dress and tuxedo rental service",
  "Dress store",
  "Dressmaker",
  "Dried flower shop",
  "Dried seafood store",
  "Drilling contractor",
  "Drilling equipment supplier",
  "Drinking water fountain",
  "Drive-in movie theater",
  "Driver and vehicle licensing agency",
  "Driver's license office",
  "Drivers license training school",
  "Driveshaft shop",
  "Driving school",
  "Driving test center",
  "Drone shop",
  "Drug testing service",
  "Drug store",
  "Drum school",
  "Drum store",
  "Dry cleaner",
  "Dry fruit store",
  "Dry ice supplier",
  "Dry wall contractor",
  "Dry wall supply store",
  "DS Automobiles dealer",
  "Dump truck dealer",
  "Dumpling restaurant",
  "Dumpster rental service",
  "Durum restaurant",
  "Dutch restaurant",
  "Duty free store",
  "DVD store",
  "Dye store",
  "Dyeworks",
  "Dynamometer supplier",
  "E commerce agency",
  "E-commerce service",
  "Ear piercing service",
  "Earth works company",
  "East African restaurant",
  "East Javanese restaurant",
  "Eastern European restaurant",
  "Eastern Orthodox Church",
  "Eating disorder treatment center",
  "Eclectic restaurant",
  "Ecological park",
  "Ecologists association",
  "Economic consultant",
  "Economic development agency",
  "Ecuadorian restaurant",
  "Education center",
  "Educational consultant",
  "Educational institution",
  "Educational supply store",
  "Educational testing service",
  "EFTPOS equipment supplier",
  "Egg supplier",
  "Egyptian restaurant",
  "Elder law attorney",
  "Electric motor repair shop",
  "Electric motor scooter dealer",
  "Electric motor store",
  "Electric motor vehicle dealer",
  "Electric motorcycle dealer",
  "Electric utility company",
  "Electrical equipment manufacturer",
  "Electric vehicle charging station",
  "Electric vehicle charging station contractor",
  "Electrical appliance wholesaler",
  "Electrical engineer",
  "Electrical equipment supplier",
  "Electrical installation service",
  "Electrical repair shop",
  "Electrical substation",
  "Electrical supply store",
  "Electrical wholesaler",
  "Electrician",
  "Electrolysis hair removal service",
  "Electronic engineer",
  "Electronic parts supplier",
  "Electronics accessories wholesaler",
  "Electronics company",
  "Electronics engineer",
  "Electronics exporter",
  "Electronics hire shop",
  "Electronics manufacturer",
  "Electronics repair shop",
  "Electronics store",
  "Electronics vending machine",
  "Electronics wholesaler",
  "Elementary school",
  "Elevator manufacturer",
  "Elevator service",
  "Embassy",
  "Embossing service",
  "Embroidery service",
  "Embroidery shop",
  "Emergency call booth",
  "Emergency care physician",
  "Emergency care service",
  "Emergency dental service",
  "Emergency locksmith service",
  "Emergency management ministry",
  "Emergency room",
  "Emergency training",
  "Emergency training school",
  "Emergency veterinarian service",
  "Employment agency",
  "Employment attorney",
  "Employment center",
  "Employment consultant",
  "Endocrinologist",
  "Endodontist",
  "Endoscopist",
  "Energy equipment and solutions",
  "Energy supplier",
  "Engine rebuilding service",
  "Engineer",
  "Engineering consultant",
  "Engineering school",
  "English language camp",
  "English language instructor",
  "English language school",
  "English restaurant",
  "Engraver",
  "Entertainer",
  "Entertainment agency",
  "Envelope supplier",
  "Environment office",
  "Environment renewable natural resources",
  "Environmental consultant",
  "Environmental engineer",
  "Environmental health service",
  "Environmental organization",
  "Environmental protection organization",
  "Episcopal church",
  "Equestrian club",
  "Equestrian facility",
  "Equestrian store",
  "Equipment exporter",
  "Equipment importer",
  "Equipment rental agency",
  "Equipment supplier",
  "Eritrean restaurant",
  "Erotic massage",
  "Escape room center",
  "Escrow service",
  "Espresso bar",
  "Estate appraiser",
  "Estate liquidator",
  "Estate planning attorney",
  "Ethiopian restaurant",
  "Ethnographic museum",
  "European restaurant",
  "Evangelical church",
  "Evening dress rental service",
  "Evening school",
  "Event management company",
  "Event planner",
  "Event technology service",
  "Event ticket seller",
  "Event venue",
  "Excavating contractor",
  "Executive search firm",
  "Executive suite rental agency",
  "Executor",
  "Exhibit",
  "Exhibition and trade centre",
  "Exhibition planner",
  "Exporter",
  "Extended stay hotel",
  "Extremaduran restaurant",
  "Eye care center",
  "Eyebrow bar",
  "Eyelash salon",
  "Fabric product manufacturer",
  "Fabric store",
  "Fabric wholesaler",
  "Fabrication engineer",
  "Facial spa",
  "Factory equipment supplier",
  "Faculty of arts",
  "Faculty of law",
  "Faculty of pharmacy",
  "Faculty of psychology",
  "Faculty of science",
  "Faculty of sports",
  "Office of a fair trade organization",
  "Fairground",
  "Falafel restaurant",
  "Family counselor",
  "Family day care service",
  "Family law attorney",
  "Family planning center",
  "Family planning counselor",
  "Family practice physician",
  "Family restaurant",
  "Family service center",
  "Farm",
  "Farm bureau",
  "Farm equipment repair service",
  "Farm equipment supplier",
  "Farm household tour",
  "Farm school",
  "Farm shop",
  "Farmers' market",
  "Farmstay",
  "Farrier service",
  "Fashion accessories store",
  "Fashion design school",
  "Fashion designer",
  "Fast food restaurant",
  "Fastener supplier",
  "Favela",
  "Fax service",
  "Federal Agency for Technical Relief",
  "Federal credit union",
  "Federal government office",
  "Federal police",
  "Federal reserve bank",
  "Feed manufacturer",
  "Animal feed store",
  "Felt boots store",
  "Fence contractor",
  "Fence supply store",
  "Fencing salon",
  "Fencing school",
  "Feng shui consultant",
  "Feng shui shop",
  "Ferrari dealer",
  "Ferris wheel",
  "Ferry service",
  "Fertility clinic",
  "Fertility physician",
  "Fertilizer supplier",
  "Festival",
  "Festival hall",
  "Fiat dealer",
  "Fiber optic products supplier",
  "Fiberglass repair service",
  "Fiberglass supplier",
  "Figurine shop",
  "Filipino grocery store",
  "Filipino restaurant",
  "Film and photograph library",
  "Film production company",
  "Filtration plant",
  "Finance broker",
  "Financial advisor",
  "Financial audit",
  "Financial consultant",
  "Financial institution",
  "Financial planner",
  "Fine dining restaurant",
  "Fingerprinting service",
  "Finishing materials supplier",
  "Finnish restaurant",
  "Fire alarm supplier",
  "Fire damage restoration service",
  "Fire department equipment supplier",
  "Fire fighters academy",
  "Fire protection consultant",
  "Fire protection equipment supplier",
  "Fire protection service",
  "Fire protection system supplier",
  "Fire station",
  "Firearms academy",
  "Fireplace manufacturer",
  "Fireplace store",
  "Firewood supplier",
  "Fireworks store",
  "Fireworks supplier",
  "First aid station",
  "Fish & chips restaurant",
  "Fish and chips takeaway",
  "Fish farm",
  "Fish processing",
  "Fish restaurant",
  "Fish spa",
  "Fish store",
  "Fishing camp",
  "Fishing charter",
  "Fishing club",
  "Fishing pier",
  "Fishing pond",
  "Fishing store",
  "Fitness center",
  "Exercise equipment store",
  "Fitness equipment wholesaler",
  "Fitted furniture supplier",
  "Fixed-base operator",
  "Flag store",
  "Flamenco dance store",
  "Flamenco school",
  "Flamenco theater",
  "Flavours fragrances and aroma supplier",
  "Flea market",
  "Flight school",
  "Floating market",
  "Floor refinishing service",
  "Floor sanding and polishing service",
  "Flooring contractor",
  "Flooring store",
  "Floridian restaurant",
  "Florist",
  "Flour mill",
  "Flower delivery",
  "Flower designer",
  "Flower market",
  "FMCG goods wholesaler",
  "FMCG manufacturer",
  "Foam rubber producer",
  "Foam rubber supplier",
  "Folk high school",
  "Fondue restaurant",
  "Food and beverage consultant",
  "Food and beverage exporter",
  "Food bank",
  "Food broker",
  "Food court",
  "Food machinery supplier",
  "Food manufacturer",
  "Food manufacturing supply",
  "Food processing company",
  "Food processing equipment",
  "Food producer",
  "Food products supplier",
  "Food seasoning manufacturer",
  "Foot bath",
  "Foot care",
  "Foot massage parlor",
  "Football club",
  "American football field",
  "Ford dealer",
  "Foreclosure service",
  "Foreign consulate",
  "Foreign exchange students organization",
  "Foreign languages program school",
  "Foreign trade consultant",
  "Foreman builders association",
  "Forensic consultant",
  "Forestry service",
  "Forklift dealer",
  "Forklift rental service",
  "Formal wear store",
  "Fortress",
  "Fortune telling services",
  "Foster care service",
  "Foundation",
  "Foundry",
  "Fountain contractor",
  "Foursquare church",
  "Franconian restaurant",
  "Fraternal organization",
  "Free clinic",
  "Free parking lot",
  "Freestyle wrestling",
  "Freight forwarding service",
  "French language school",
  "French restaurant",
  "French steakhouse restaurant",
  "Fresh food market",
  "Fried chicken takeaway",
  "Friends church",
  "Frituur",
  "Frozen dessert supplier",
  "Frozen food manufacturer",
  "Frozen food store",
  "Frozen yogurt shop",
  "Fruit and vegetable processing",
  "Fruit and vegetable store",
  "Fruit and vegetable wholesaler",
  "Fruit parlor",
  "Fruit wholesaler",
  "Fruits wholesaler",
  "Fujian restaurant",
  "Fuel supplier",
  "Fugu restaurant",
  "Full dress rental service",
  "Full Gospel church",
  "Function room facility",
  "Funeral celebrant service",
  "Funeral director",
  "Funeral home",
  "Fur coat shop",
  "Fur manufacturer",
  "Fur service",
  "Furnace parts supplier",
  "Furnace repair service",
  "Furnace store",
  "Furnished apartment building",
  "Furniture accessories",
  "Furniture accessories supplier",
  "Furniture maker",
  "Furniture manufacturer",
  "Furniture rental service",
  "Furniture repair shop",
  "Furniture store",
  "Furniture wholesaler",
  "Fusion restaurant",
  "Futon store",
  "Futsal court",
  "Galician restaurant",
  "Gambling house",
  "Gambling instructor",
  "Game store",
  "Garage builder",
  "Garage door supplier",
  "Garbage collection service",
  "Garbage dump",
  "Garbage dump service",
  "Garden",
  "Garden building supplier",
  "Garden center",
  "Garden furniture shop",
  "Garden machinery supplier",
  "Gardener",
  "Garment exporter",
  "Gas company",
  "Gas cylinders supplier",
  "Gas engineer",
  "Gas installation service",
  "Gas logs supplier",
  "Gas shop",
  "Gas station",
  "Gasfitter",
  "Gasket manufacturer",
  "Gastroenterologist",
  "Gastrointestinal surgeon",
  "Gastropub",
  "Gated community",
  "Gay & lesbian organization",
  "Gay bar",
  "Gay night club",
  "Gay sauna",
  "Gazebo builder",
  "Gemologist",
  "Genealogist",
  "General contractor",
  "General education school",
  "General hospital",
  "General practice attorney",
  "General practitioner",
  "General register office",
  "General store",
  "Generator shop",
  "Genesis dealer",
  "Geography and history faculty",
  "Geological research company",
  "Geological service",
  "Georgian restaurant",
  "Geotechnical engineer",
  "Geriatrician",
  "German language school",
  "German restaurant",
  "Ghost town",
  "Gift basket store",
  "Gift shop",
  "Gift wrap store",
  "Girl bar",
  "Girls' high school",
  "Glass & mirror shop",
  "Glass block supplier",
  "Glass blower",
  "Glass cutting service",
  "Glass engraver",
  "Glass etching service",
  "Glass industry",
  "Glass manufacturer",
  "Glass merchant",
  "Glass repair service",
  "Glass shop",
  "Glasses repair service",
  "Glassware manufacturer",
  "Glassware store",
  "Glassware wholesaler",
  "Glazier",
  "Gluten-free restaurant",
  "GMC dealer",
  "Go-kart track",
  "Goan restaurant",
  "Gold dealer",
  "Gold mining company",
  "Goldfish store",
  "Goldsmith",
  "Golf cart dealer",
  "Golf club",
  "Golf course",
  "Golf course builder",
  "Golf driving range",
  "Golf instructor",
  "Golf shop",
  "Gospel church",
  "Gourmet grocery store",
  "Government college",
  "Government economic program",
  "Government hospital",
  "Government office",
  "Government ration shop",
  "Government school",
  "GPS supplier",
  "Graduate school",
  "Graffiti removal service",
  "Grain elevator",
  "Grammar school",
  "Granite supplier",
  "Graphic designer",
  "Gravel pit",
  "Gravel plant",
  "Greco-Roman wrestling",
  "Greek Orthodox church",
  "Greek restaurant",
  "Green energy supplier",
  "Greengrocer",
  "Greenhouse",
  "Greeting card shop",
  "Greyhound stadium",
  "Grill store",
  "Grocery delivery service",
  "Grocery store",
  "Ground self defense force",
  "Group home",
  "Guardia Civil",
  "Guardia Di Finanza Police",
  "Guatemalan restaurant",
  "Guest house",
  "Guizhou restaurant",
  "Guitar instructor",
  "Guitar store",
  "Gujarati restaurant",
  "Gun club",
  "Gun shop",
  "Offal barbecue restaurant",
  "Gutter Cleaning Service",
  "Gym",
  "Gymnasium school",
  "Gymnastics center",
  "Gymnastics club",
  "Obstetrician-gynecologist",
  "Gynecologist",
  "Gypsum product supplier",
  "Gyro restaurant",
  "Haberdashery",
  "Hair extension technician",
  "Hair extensions supplier",
  "Hair removal service",
  "Hair replacement service",
  "Hair salon",
  "Hair transplantation clinic",
  "Haitian restaurant",
  "Hakka restaurant",
  "Halal restaurant",
  "Haleem restaurant",
  "Halfway house",
  "Ham shop",
  "Hamburger restaurant",
  "Hammam",
  "Hand surgeon",
  "Handbags shop",
  "Handball club",
  "Handball court",
  "Handicapped transportation service",
  "Handicraft",
  "Handicraft exporter",
  "Handicraft fair",
  "Handicraft museum",
  "Handicraft school",
  "Handicrafts wholesaler",
  "Handyman/Handywoman/Handyperson",
  "Hang gliding center",
  "Hardware store",
  "Hardware training institute",
  "Harley-Davidson dealer",
  "Hat shop",
  "Haunted house",
  "Hauptschule (lower-tier secondary school)",
  "Haute couture fashion house",
  "Haute French restaurant",
  "Hawaiian goods store",
  "Hawaiian restaurant",
  "Hawker centre",
  "Hawker stall",
  "Hay supplier",
  "Head start center",
  "Health and beauty shop",
  "Health consultant",
  "Health counselor",
  "Health food restaurant",
  "Health food store",
  "Health insurance agency",
  "Health resort",
  "Health spa",
  "Hearing aid repair service",
  "Hearing aid store",
  "Heart hospital",
  "Heating contractor",
  "Heating equipment supplier",
  "Heating oil supplier",
  "Height works",
  "Helicopter charter",
  "Helicopter tour agency",
  "Heliport",
  "Helium gas supplier",
  "Helpline",
  "Hematologist",
  "Hepatologist",
  "Herb shop",
  "Herbal medicine store",
  "Herbalist",
  "Heritage building",
  "Heritage museum",
  "Heritage preservation",
  "Heritage railroad",
  "High ropes course",
  "High school",
  "Higher secondary school",
  "Highway patrol",
  "Hiking area",
  "Hindu priest",
  "Hindu temple",
  "Hip hop dance class",
  "Hispanic church",
  "Historical landmark",
  "Historical place museum",
  "Historical society",
  "History museum",
  "HIV testing center",
  "Hoagie restaurant",
  "Hobby store",
  "Hockey club",
  "Hockey field",
  "Hockey rink",
  "Hockey supply store",
  "Holding company",
  "Holiday apartment rental",
  "Holiday home",
  "Holistic medicine practitioner",
  "Home automation company",
  "Home builder",
  "Home cinema installation",
  "Home goods store",
  "Home hairdresser",
  "Home health care service",
  "Home help",
  "Home help service agency",
  "Home improvement store",
  "Home inspector",
  "Home insurance agency",
  "Home theater store",
  "Homekill service",
  "Homeless service",
  "Homeless shelter",
  "Homeopath",
  "Homeopathic pharmacy",
  "Homeowners' association",
  "Honda dealer",
  "Honduran restaurant",
  "Honey farm",
  "Cha chaan teng (Hong Kong-style cafe)",
  "Hong Kong style fast food restaurant",
  "Hookah bar",
  "Hookah store",
  "Horse boarding stable",
  "Horse breeder",
  "Horse rental service",
  "Horse riding field",
  "Horse riding school",
  "Horse trailer dealer",
  "Horse trainer",
  "Horseback riding service",
  "Horse transport supplier",
  "Horseshoe smith",
  "Horsestable studfarm",
  "Hose supplier",
  "Hospice",
  "Hospital",
  "Hospital equipment and supplies",
  "Hospital department",
  "Hospitality and tourism school",
  "Hospitality high school",
  "Host club",
  "Hostel",
  "Hot bedstone spa",
  "Hot dog restaurant",
  "Hot dog stand",
  "Hot pot restaurant",
  "Hot tub repair service",
  "Hot tub store",
  "Hot water system supplier",
  "Hotel",
  "Hotel management school",
  "Hotel supply store",
  "House cleaning service",
  "House clearance service",
  "House sitter",
  "House sitter agency",
  "Houseboat rental service",
  "Household chemicals supplier",
  "Household goods wholesaler",
  "Housing association",
  "Housing authority",
  "Housing complex",
  "Housing cooperative",
  "Housing development",
  "Housing society",
  "Housing utility company",
  "Hua gong shop",
  "Hua niao market place",
  "Hub cap supplier",
  "Human resource consulting",
  "Hunan restaurant",
  "Hungarian restaurant",
  "Hunting & Fishing Store",
  "Hunting area",
  "Hunting club",
  "Hunting preserve",
  "Hunting store",
  "HVAC contractor",
  "Hyderabadi restaurant",
  "Hydraulic engineer",
  "Hydraulic equipment supplier",
  "Hydraulic repair service",
  "Hydroelectric power plant",
  "Hydroponics equipment supplier",
  "Hygiene articles wholesaler",
  "Hyperbaric medicine physician",
  "Hypermarket",
  "Hypnotherapy service",
  "Hyundai dealer",
  "Ice cream equipment supplier",
  "Ice cream shop",
  "Ice hockey club",
  "Ice skating club",
  "Ice skating instructor",
  "Ice skating rink",
  "Ice supplier",
  "Icelandic restaurant",
  "ICSE school",
  "Idol manufacturer",
  "Ikan bakar restaurant",
  "Image consultant",
  "IMAX theater",
  "Immigration & naturalization service",
  "Immigration attorney",
  "Immigration detention centre",
  "Immunologist",
  "Impermeabilization service",
  "Import export company",
  "Importer",
  "Incense supplier",
  "Incineration plant",
  "Income protection insurance agency",
  "Income tax help association",
  "Indian grocery store",
  "Indian Motorcycle dealer",
  "Indian Muslim restaurant",
  "Indian restaurant",
  "Indian sizzler restaurant",
  "Indian sweets shop",
  "Indian takeaway",
  "Indonesian restaurant",
  "Indoor cycling",
  "Indoor golf course",
  "Indoor playground",
  "Indoor snowcenter",
  "Indoor swimming pool",
  "Industrial chemicals wholesaler",
  "Industrial consultant",
  "Industrial design company",
  "Industrial door supplier",
  "Industrial engineer",
  "Industrial engineers association",
  "Industrial equipment supplier",
  "Industrial framework supplier",
  "Industrial gas supplier",
  "Industrial real estate agency",
  "Industrial supermarket",
  "Industrial technical engineers association",
  "Industrial vacuum equipment supplier",
  "Infectious disease physician",
  "Infiniti dealer",
  "Information services",
  "Inn",
  "Insolvency service",
  "Institute of Geography and Statistics",
  "Instrumentation engineer",
  "Insulation contractor",
  "Insulation materials store",
  "Insulator supplier",
  "Insurance agency",
  "Insurance attorney",
  "Insurance broker",
  "Insurance company",
  "Insurance school",
  "Intellectual property registry",
  "Intensivist",
  "Interior architect office",
  "Interior construction contractor",
  "Interior Decorator",
  "Interior designer",
  "Interior door shop",
  "Interior fitting contractor",
  "Interior plant service",
  "Internal medicine ward",
  "International airport",
  "International school",
  "International trade consultant",
  "Internet cafe",
  "Internet marketing service",
  "Internet service provider",
  "Internet shop",
  "Internist",
  "Investment bank",
  "Investment company",
  "Investment service",
  "Invitation printing service",
  "Irish goods store",
  "Irish pub",
  "Irish restaurant",
  "Iron steel contractor",
  "Iron ware dealer",
  "Iron works",
  "Irrigation equipment supplier",
  "Israeli restaurant",
  "Isuzu dealer",
  "Italian grocery store",
  "Italian restaurant",
  "Institute of technology",
  "Jaguar dealer",
  "Jain temple",
  "Jamaican restaurant",
  "Janitorial equipment supplier",
  "Janitorial service",
  "Authentic Japanese restaurant",
  "Japanese cheap sweets shop",
  "Japanese confectionery shop",
  "Japanese curry restaurant",
  "Japanese delicatessen",
  "Japanese grocery store",
  "Ryotei restaurant",
  "Izakaya restaurant",
  "Japanese language instructor",
  "Japanese regional restaurant",
  "Japanese restaurant",
  "Japanese steakhouse",
  "Japanese sweets restaurant",
  "Japanized western restaurant",
  "Javanese restaurant",
  "Jazz club",
  "Jeep dealer",
  "Jehovah's Witness Kingdom Hall",
  "Jeweler",
  "Jewelry appraiser",
  "Jewelry buyer",
  "Jewelry designer",
  "Jewelry engraver",
  "Jewelry equipment supplier",
  "Jewelry exporter",
  "Jewelry manufacturer",
  "Jewelry repair service",
  "Jewelry store",
  "Jewish restaurant",
  "Jiangsu restaurant",
  "Joiner",
  "Judicial auction",
  "Judicial scrivener",
  "Judo club",
  "Judo school",
  "Juice shop",
  "Jujitsu school",
  "Junior college",
  "Junk dealer",
  "Junk store",
  "Junkyard",
  "Justice department",
  "Jute exporter",
  "Jute mill",
  "Juvenile detention center",
  "Kabaddi club",
  "Kaiseki restaurant",
  "Kalle pache restaurant",
  "Karaoke bar",
  "Karaoke Equipment Rental Service",
  "Karate club",
  "Karate school",
  "Karma dealer",
  "Karnataka restaurant",
  "Kashmiri restaurant",
  "Kazakhstani restaurant",
  "Kebab shop",
  "Kennel",
  "Kerala restaurant",
  "Kerosene supplier",
  "Key duplication service",
  "Kia dealer",
  "Kickboxing school",
  "Kilt shop and hire",
  "Kimono store",
  "Kindergarten",
  "Kinesiologist",
  "Kinesiotherapist",
  "Kiosk",
  "Kitchen furniture store",
  "Kitchen remodeler",
  "Kitchen supply store",
  "Kite shop",
  "Knife manufacturing",
  "Knife store",
  "Knit shop",
  "Knitting instructor",
  "Knitwear manufacturer",
  "Kofta restaurant",
  "Konkani restaurant",
  "Korean barbecue restaurant",
  "Korean beef restaurant",
  "Korean church",
  "Korean grocery store",
  "Korean restaurant",
  "Korean rib restaurant",
  "Koshari restaurant",
  "Kosher grocery store",
  "Kosher restaurant",
  "Kung fu school",
  "Kushiyaki restaurant",
  "Kyoto style Japanese restaurant",
  "Labor relations attorney",
  "Labor union",
  "Laboratory",
  "Laboratory equipment supplier",
  "Ladder supplier",
  "Lamborghini dealer",
  "Laminating equipment supplier",
  "Lamination service",
  "Lamp repair service",
  "Lamp shade supplier",
  "Lancia dealer",
  "Land allotment",
  "Land planning authority",
  "Land reform institute",
  "Land Rover dealer",
  "Land surveying office",
  "Land surveyor",
  "Landscape architect",
  "Landscape designer",
  "Landscape lighting designer",
  "Landscaper",
  "Landscaping supply store",
  "Language school",
  "Laotian restaurant",
  "Lapidary",
  "Laser cutting service",
  "Laser equipment supplier",
  "Laser hair removal service",
  "Laser tag center",
  "Lasik surgeon",
  "Latin American restaurant",
  "Laundromat",
  "Laundry",
  "Laundry service",
  "Law book store",
  "Law firm",
  "Law library",
  "Law school",
  "Lawn bowls club",
  "Lawn care service",
  "Lawn equipment rental service",
  "Lawn irrigation equipment supplier",
  "Lawn mower repair service",
  "Lawn mower store",
  "Lawn sprinkler system contractor",
  "Attorney",
  "Lawyers association",
  "Leagues club",
  "Learner driver training area",
  "Learning center",
  "Leasing service",
  "Leather cleaning service",
  "Leather coats store",
  "Leather exporter",
  "Leather goods manufacturer",
  "Leather goods store",
  "Leather goods supplier",
  "Leather goods wholesaler",
  "Leather repair service",
  "Leather wholesaler",
  "Lebanese restaurant",
  "Lechon restaurant",
  "Legal affairs bureau",
  "Legal aid office",
  "Legal services",
  "Leisure centre",
  "Lexus dealer",
  "Library",
  "License bureau",
  "License plate frames supplier",
  "Lido",
  "Life coach",
  "Life insurance agency",
  "Light bulb supplier",
  "Lighting consultant",
  "Lighting contractor",
  "Lighting manufacturer",
  "Lighting store",
  "Lighting wholesaler",
  "Ligurian restaurant",
  "Limousine service",
  "Lincoln dealer",
  "Line marking service",
  "Linens store",
  "Lingerie manufacturer",
  "Lingerie store",
  "Lingerie wholesaler",
  "Linoleum store",
  "Liquidator",
  "Liquor store",
  "Literacy program",
  "Lithuanian restaurant",
  "Little league club",
  "Little league field",
  "Live music bar",
  "Live music venue",
  "Livery company",
  "Livestock auction house",
  "Livestock breeder",
  "Livestock dealer",
  "Livestock producer",
  "Loan agency",
  "Local government office",
  "Local history museum",
  "Local medical services",
  "Lock Store",
  "Locks supplier",
  "Locksmith",
  "Lodge",
  "Log cabins",
  "Log home builder",
  "Logging contractor",
  "Logistics service",
  "Lombardian restaurant",
  "Loss adjuster",
  "Lost property office",
  "Lottery retailer",
  "Lottery shop",
  "Lounge",
  "Love hotel",
  "Low income housing program",
  "LPG conversion",
  "Luggage repair service",
  "Luggage storage facility",
  "Luggage store",
  "Luggage wholesaler",
  "Lumber store",
  "Lunch restaurant",
  "Lutheran church",
  "Lyceum",
  "Lymph drainage therapist",
  "Machine construction",
  "Machine knife supplier",
  "Machine maintenance",
  "Machine repair service",
  "Machine shop",
  "Machine workshop",
  "Machinery parts manufacturer",
  "Machining manufacturer",
  "Macrobiotic restaurant",
  "Madrilian restaurant",
  "Magazine store",
  "Magic store",
  "Magician",
  "Mahjong house",
  "Mailbox rental service",
  "Mailbox supplier",
  "Mailing machine supplier",
  "Mailing service",
  "Main customs office",
  "Majorcan restaurant",
  "Makerspace",
  "Make-up artist",
  "Malaysian restaurant",
  "Maltese restaurant",
  "Mammography service",
  "Manado restaurant",
  "Management school",
  "Mandarin restaurant",
  "Manor house",
  "Manufactured home transporter",
  "Manufacturer",
  "Maori organization",
  "Map store",
  "Mapping service",
  "Marae",
  "Marathi restaurant",
  "Marble contractor",
  "Marble supplier",
  "Marche restaurant",
  "Marina",
  "Marine engineer",
  "Marine self defense force",
  "Marine supply store",
  "Marine surveyor",
  "Maritime museum",
  "Market",
  "Market researcher",
  "Marketing agency",
  "Marketing consultant",
  "Markmens clubhouse",
  "Marquee hire service",
  "Marriage celebrant",
  "Marriage or relationship counselor",
  "Marriage license bureau",
  "Martial arts club",
  "Martial arts school",
  "Martial arts supply store",
  "Maserati dealer",
  "Masonic center",
  "Masonry contractor",
  "Masonry supply store",
  "Massage school",
  "Massage spa",
  "Massage supply store",
  "Massage therapist",
  "Match box manufacturer",
  "Material handling equipment supplier",
  "Maternity hospital",
  "Maternity store",
  "Mathematics school",
  "Mattress store",
  "Mausoleum builder",
  "Maybach dealer",
  "Mazda dealer",
  "McLaren dealer",
  "Delivery Restaurant",
  "Takeout Restaurant",
  "Measuring instruments supplier",
  "Meat packer",
  "Meat processor",
  "Meat products store",
  "Meat dish restaurant",
  "Meat wholesaler",
  "Mechanic",
  "Mechanical contractor",
  "Mechanical engineer",
  "Mechanical plant",
  "Media and information sciences faculty",
  "Media company",
  "Media consultant",
  "Media house",
  "Mediation service",
  "Medical billing service",
  "Medical book store",
  "Medical Center",
  "Medical certificate service",
  "Medical clinic",
  "Medical diagnostic imaging center",
  "Medical equipment manufacturer",
  "Medical equipment supplier",
  "Medical examiner",
  "Medical group",
  "Medical laboratory",
  "Medical lawyer",
  "Medical office",
  "Medical school",
  "Medical spa",
  "Medical supply store",
  "Medical technology manufacturer",
  "Medical transcription service",
  "Medicine exporter",
  "Meditation center",
  "Meditation instructor",
  "Mediterranean restaurant",
  "Meeting planning service",
  "Mehandi class",
  "Mehndi designer",
  "Memorial",
  "Memorial estate",
  "Memorial park",
  "Mennonite church",
  "Men's clothing store",
  "Men's health physician",
  "Mens tailor",
  "Mental health clinic",
  "Mental health service",
  "Mercantile development",
  "Mercedes-Benz dealer",
  "Messianic synagogue",
  "Metal construction company",
  "Metal detecting equipment supplier",
  "Metal fabricator",
  "Metal finisher",
  "Metal heat treating service",
  "Metal industry suppliers",
  "Metal machinery supplier",
  "Metal polishing service",
  "Metal processing company",
  "Metal stamping service",
  "Metal supplier",
  "Metal working shop",
  "Metal workshop",
  "Metallurgy company",
  "Metalware dealer",
  "Metalware producer",
  "Metaphysical supply store",
  "Methodist church",
  "Metropolitan train company",
  "Mexican goods store",
  "Mexican grocery store",
  "Mexican restaurant",
  "Mexican torta restaurant",
  "Meyhane",
  "MG dealer",
  "Microwave oven repair service",
  "Mid-Atlantic restaurant (US)",
  "Middle Eastern restaurant",
  "Middle school",
  "Midwife",
  "Military archive",
  "Military residence",
  "Military barracks",
  "Military base",
  "Military board",
  "Military cemetery",
  "Military hospital",
  "Military recruiting office",
  "Military school",
  "Military town",
  "Milk delivery service",
  "Mill",
  "Millwork shop",
  "Mine",
  "Mineral water company",
  "Mineral water wholesaler",
  "MINI dealer",
  "Miniature golf course",
  "Miniatures store",
  "Minibus taxi service",
  "Mining company",
  "Mining Consultant",
  "Mining engineer",
  "Mining equipment",
  "Ministry of Education",
  "Mirror shop",
  "Miso cutlet restaurant",
  "Missing persons organization",
  "Mission",
  "Mitsubishi dealer",
  "Mobile caterer",
  "Mobile disco",
  "Mobile hairdresser",
  "Mobile home dealer",
  "Mobile home park",
  "Mobile home rental agency",
  "Mobile home supply store",
  "Mobile money agent",
  "Mobile network operator",
  "Mobile phone repair shop",
  "Mobility equipment supplier",
  "Model car play area",
  "Model design company",
  "Model portfolio studio",
  "Model train store",
  "Modeling agency",
  "Modeling school",
  "Modern art museum",
  "Modern British restaurant",
  "Modern European restaurant",
  "Modern French restaurant",
  "Modern Indian restaurant",
  "Modular home builder",
  "Modular home dealer",
  "Mohel",
  "Mold maker",
  "Molding supplier",
  "Momo restaurant",
  "Monastery",
  "Money order service",
  "Money transfer service",
  "Mongolian barbecue restaurant",
  "Monjayaki restaurant",
  "Monogramming service",
  "Montessori school",
  "Monument maker",
  "Moped dealer",
  "Moravian church",
  "Modern izakaya restaurant",
  "Moroccan restaurant",
  "Mortgage broker",
  "Mortgage lender",
  "Mortuary",
  "Mosque",
  "Motel",
  "Motor scooter dealer",
  "Motor scooter repair shop",
  "Motor vehicle dealer",
  "Motorcycle dealer",
  "Motorcycle driving school",
  "Motorcycle insurance agency",
  "Motorcycle parts store",
  "Motorcycle rental agency",
  "Motorcycle repair shop",
  "Motorcycle shop",
  "Motoring club",
  "Motorsports store",
  "Mountain cable car",
  "Mountain cabin",
  "Mountaineering class",
  "Movie rental kiosk",
  "Movie rental store",
  "Movie studio",
  "Movie theater",
  "Moving and storage service",
  "Mover",
  "Moving supply store",
  "MRI center",
  "Muay Thai boxing gym",
  "Muffler shop",
  "Mughlai restaurant",
  "Mulch supplier",
  "Multimedia and electronic book publisher",
  "Municipal administration office",
  "Municipal corporation",
  "Municipal Department Agricultural Development",
  "Municipal department of agriculture food supply",
  "Municipal Department Civil Defense",
  "Municipal Department Communication",
  "Municipal Department Finance",
  "Municipal Department Housing and Urban Development",
  "Municipal Department of Culture",
  "Municipal Department of Sports",
  "Municipal Department of Tourism",
  "Municipal Department Science Technology",
  "Municipal Department Social Defense",
  "Municipal Guard",
  "Municipal health department",
  "Municipal office education",
  "Municipal Social Development",
  "Murtabak restaurant",
  "Musalla",
  "Museum",
  "Museum of space history",
  "Museum of zoology",
  "Music box store",
  "Music college",
  "Music conservatory",
  "Music instructor",
  "Music management and promotion",
  "Music producer",
  "Music publisher",
  "Music school",
  "Music store",
  "Musical club",
  "Musical instrument manufacturer",
  "Musical instrument rental service",
  "Musical instrument repair shop",
  "Musical instrument store",
  "Musician",
  "Musician and composer",
  "Mutton barbecue restaurant",
  "Nail salon",
  "Nanotechnology engineer",
  "Nasi goreng restaurant",
  "Nasi restaurant",
  "Nasi uduk restaurant",
  "National forest",
  "National health foundation",
  "National library",
  "National museum",
  "National park",
  "National reserve",
  "Native american goods store",
  "Native American restaurant",
  "Natural goods store",
  "Natural history museum",
  "Natural rock climbing area",
  "Natural stone exporter",
  "Natural stone supplier",
  "Natural stone wholesaler",
  "Nature preserve",
  "Naturopathic practitioner",
  "Naval base",
  "Navarraise restaurant",
  "Neapolitan restaurant",
  "Needlework shop",
  "Neon sign shop",
  "Neonatal physician",
  "Nepalese restaurant",
  "Nephrologist",
  "Netball club",
  "Neurologist",
  "Neurophysiologist",
  "Neurosurgeon",
  "New Age church",
  "New England restaurant",
  "New American restaurant",
  "New Zealand restaurant",
  "News service",
  "Newspaper advertising department",
  "Newspaper distribution",
  "Newspaper publisher",
  "Newsstand",
  "Nicaraguan restaurant",
  "Night club",
  "Night market",
  "Nissan dealer",
  "Non-denominational church",
  "Non-governmental organization",
  "Non-profit organization",
  "Non smoking holiday home",
  "Non vegetarian restaurant",
  "Noodle shop",
  "North African restaurant",
  "North Eastern Indian restaurant",
  "North Indian restaurant",
  "Northern Italian restaurant",
  "Norwegian restaurant",
  "Notaries association",
  "Notary public",
  "Notions store",
  "Novelties wholesaler",
  "Novelty store",
  "Nuclear engineer",
  "Nuclear power company",
  "Nuclear power plant",
  "Nudist club",
  "Nudist park",
  "Nuevo Latino restaurant",
  "Numerologist",
  "Convent",
  "Nurse practitioner",
  "Nursery school",
  "Nursing agency",
  "Nursing association",
  "Nursing home",
  "Nursing school",
  "Nut store",
  "Nutritionist",
  "Nyonya restaurant",
  "Oaxacan restaurant",
  "Obanzai restaurant",
  "Observation deck",
  "Observatory",
  "Women's health clinic",
  "Occupational health service",
  "Occupational medical physician",
  "Occupational safety and health",
  "Occupational therapist",
  "Oden restaurant",
  "Odia restaurant",
  "Off-road race track",
  "Off roading area",
  "Off track betting shop",
  "Offal pot cooking restaurant",
  "Office accessories wholesaler",
  "Office equipment rental service",
  "Office equipment repair service",
  "Office equipment supplier",
  "Office furniture store",
  "Office of Vital Records",
  "Office refurbishment service",
  "Office space rental agency",
  "Office supply store",
  "Office supply wholesaler",
  "Oil and gas exploration service",
  "Oil change service",
  "Oil & natural gas company",
  "Oil field equipment supplier",
  "Oil refinery",
  "Oil store",
  "Oil wholesaler",
  "Oilfield",
  "Okonomiyaki restaurant",
  "Oldsmobile dealer",
  "Olive oil bottling company",
  "Olive oil cooperative",
  "Olive oil manufacturer",
  "Oncologist",
  "Onsen",
  "Opel dealer",
  "Open air museum",
  "Open university",
  "Opera company",
  "Opera house",
  "Ophthalmologist",
  "Ophthalmology clinic",
  "Optical instrument repair service",
  "Optical products manufacturer",
  "Optical wholesaler",
  "Optician",
  "Optometrist",
  "Oral and maxillofacial surgeon",
  "Oral surgeon",
  "Orchard",
  "Orchestra",
  "Orchid farm",
  "Orchid grower",
  "Organ donation and tissue bank",
  "Organic drug store",
  "Organic farm",
  "Organic food store",
  "Organic restaurant",
  "Organic shop",
  "Oriental goods store",
  "Oriental medicine clinic",
  "Oriental medicine store",
  "Oriental rug store",
  "Orphan asylum",
  "Orphanage",
  "Orthodontist",
  "Orthodox church",
  "Orthodox synagogue",
  "Orthopedic clinic",
  "Orthopedic shoe store",
  "Orthopedic supplies store",
  "Orthopedic surgeon",
  "Orthoptist",
  "Orthotics & prosthetics service",
  "Osteopath",
  "Otolaryngologist",
  "Otolaryngology clinic",
  "Outboard motor store",
  "Outdoor activity organiser",
  "Outdoor bath",
  "Outdoor clothing and equipment shop",
  "Outdoor equestrian facility",
  "Outdoor furniture store",
  "Outdoor movie theater",
  "Outdoor sports store",
  "Outdoor swimming pool",
  "Outerwear store",
  "Outlet mall",
  "Outlet store",
  "Oxygen cocktail spot",
  "Oxygen equipment supplier",
  "Oyster bar restaurant",
  "Oyster supplier",
  "Paan shop",
  "Pachinko parlor",
  "Pacific Rim restaurant",
  "Package locker",
  "Packaging company",
  "Packaging machinery",
  "Packaging supply store",
  "Padang restaurant",
  "Padel club",
  "Padel court",
  "Pagoda",
  "Pain control clinic",
  "Pain management physician",
  "Paint manufacturer",
  "Paint store",
  "Paint stripping service",
  "Paintball center",
  "Paintball store",
  "Painter",
  "Painting",
  "Painting lessons",
  "Painting studio",
  "Paintings store",
  "Paisa restaurant",
  "Pakistani restaurant",
  "Palatine restaurant",
  "Pallet supplier",
  "Pan-Asian restaurant",
  "Pan-Latin restaurant",
  "Pancake restaurant",
  "Panipuri shop",
  "Paper bag supplier",
  "Paper distributor",
  "Paper exporter",
  "Paper mill",
  "Paper shredding machine supplier",
  "Paper store",
  "Paraguayan restaurant",
  "Paralegal services provider",
  "Parapharmacy",
  "Parasailing ride service",
  "Parish",
  "Park",
  "Park & ride",
  "Parking garage",
  "Parking lot",
  "Parking lot for bicycles",
  "Parking lot for motorcycles",
  "Parkour spot",
  "Parochial school",
  "Parsi restaurant",
  "Parsi temple",
  "Part time daycare",
  "Party equipment rental service",
  "Party planner",
  "Party store",
  "Passport agent",
  "Passport office",
  "Passport photo processor",
  "Pasta shop",
  "Pastry shop",
  "Patent attorney",
  "Patent office",
  "Paternity testing service",
  "Pathologist",
  "Patients support association",
  "Patio enclosure supplier",
  "Patisserie",
  "Paving contractor",
  "Paving materials supplier",
  "Pawn shop",
  "Pay by weight restaurant",
  "Payroll service",
  "Pecel lele restaurant",
  "Pedestrian zone",
  "Pediatric cardiologist",
  "Pediatric clinic",
  "Pediatric dentist",
  "Pediatric dermatologist",
  "Pediatric endocrinologist",
  "Pediatric gastroenterologist",
  "Pediatric hematologist",
  "Pediatric nephrologist",
  "Pediatric neurologist",
  "Pediatric oncologist",
  "Pediatric ophthalmologist",
  "Pediatric orthopedic surgeon",
  "Pediatric pulmonologist",
  "Pediatric rheumatologist",
  "Pediatric surgeon",
  "Pediatric urologist",
  "Pediatrician",
  "Pedorthist",
  "Pempek restaurant",
  "Pen store",
  "Pennsylvania Dutch restaurant",
  "Pension office",
  "Pentecostal church",
  "Performing arts group",
  "Performing arts theater",
  "Perfume store",
  "Perinatal center",
  "Periodontist",
  "Permanent make-up clinic",
  "Persian restaurant",
  "Personal chef service",
  "Personal injury attorney",
  "Personal trainer",
  "Personal watercraft dealer",
  "Peruvian restaurant",
  "Pest control service",
  "Pet adoption service",
  "Pet boarding service",
  "Pet cemetery",
  "Pet funeral service",
  "Pet groomer",
  "Pet moving service",
  "Pet sitter",
  "Pet store",
  "Pet supply store",
  "Pet trainer",
  "Petrochemical engineer",
  "Petroleum products company",
  "Peugeot dealer",
  "Pharmaceutical company",
  "Pharmaceutical lab",
  "Pharmaceutical products wholesaler",
  "Pharmacy",
  "Philharmonic hall",
  "Pho restaurant",
  "Phone repair service",
  "Photo agency",
  "Photo booth",
  "Photo lab",
  "Photo restoration service",
  "Photo shop",
  "Photocopiers supplier",
  "Photographer",
  "Photography class",
  "Photography school",
  "Photography Service",
  "Photography studio",
  "Physiatrist",
  "Physical examination center",
  "Physical fitness program",
  "Physician assistant",
  "Physician referral service",
  "Physiotherapist",
  "Physiotherapy Center",
  "Physiotherapy equipment supplier",
  "Piadina restaurant",
  "Piano bar",
  "Piano instructor",
  "Piano maker",
  "Piano moving service",
  "Piano repair service",
  "Piano store",
  "Piano tuning service",
  "Pick your own farm produce",
  "Pickleball court",
  "Picnic ground",
  "Picture frame shop",
  "Pie shop",
  "Piedmontese restaurant",
  "Pig farm",
  "Pilaf restaurant",
  "Pilates studio",
  "Pile driving service",
  "Pilgrim hostel",
  "Pilgrimage place",
  "Piñatas supplier",
  "Pinball machine supplier",
  "Pine furniture shop",
  "Pipe supplier",
  "Pizza delivery",
  "Pizza restaurant",
  "Pizza takeaway",
  "Place of worship",
  "Planetarium",
  "Plant and machinery hire",
  "Plant nursery",
  "Plast window store",
  "Plasterer",
  "Plastic bag supplier",
  "Plastic bags wholesaler",
  "Plastic fabrication company",
  "Plastic injection molding service",
  "Plastic products supplier",
  "Plastic resin manufacturer",
  "Plastic surgeon",
  "Plastic surgery clinic",
  "Plastic wholesaler",
  "Plating service",
  "Play school",
  "Playground",
  "Playground equipment supplier",
  "Playgroup",
  "Plumber",
  "Plumbing supply store",
  "Plus size clothing store",
  "Plywood supplier",
  "Pneumatic tools supplier",
  "Po’ boys restaurant",
  "Podiatrist",
  "Poke bar",
  "Police academy",
  "Police officers' housing",
  "Police supply store",
  "Polish restaurant",
  "Political party office",
  "Polo club",
  "Polygraph service",
  "Polymer supplier",
  "Polynesian restaurant",
  "Polytechnic college",
  "Polythene and plastic sheeting supplier",
  "Pond contractor",
  "Pond fish supplier",
  "Pond supply store",
  "Pontiac dealer",
  "Pony club",
  "Pony ride service",
  "Cue sports school",
  "Pool billard club",
  "Pool cleaning service",
  "Pool hall",
  "Popcorn store",
  "Katsudon restaurant",
  "Porridge restaurant",
  "Porsche dealer",
  "Port authority",
  "Port operating company",
  "Portable building manufacturer",
  "Portable toilet supplier",
  "Portrait studio",
  "Portuguese restaurant",
  "Post office",
  "Poster store",
  "Pottery classes",
  "Pottery manufacturer",
  "Pottery store",
  "Poultry farm",
  "Poultry store",
  "Powder coating service",
  "Power station",
  "Power plant consultant",
  "Power plant equipment supplier",
  "Powersports vehicle dealer",
  "Pozole restaurant",
  "Prawn fishing",
  "Pre gymnasium school",
  "Precision engineer",
  "Prefabricated house companies",
  "Prefecture",
  "Japanese prefecture government office",
  "Pregnancy care center",
  "Preparatory school",
  "Presbyterian church",
  "Preschool",
  "Press advisory",
  "Pressure washing service",
  "Pretzel store",
  "Priest",
  "Primary school",
  "Print shop",
  "Printed music publisher",
  "Printer ink refill store",
  "Printer repair service",
  "Printing equipment and supplies",
  "Printing equipment supplier",
  "Prison",
  "Private college",
  "Private equity firm",
  "Private golf course",
  "Private hospital",
  "Private investigator",
  "Private educational institution",
  "Private sector bank",
  "Private tutor",
  "Private university",
  "Probation office",
  "Process server",
  "Proctologist",
  "Produce market",
  "Produce wholesaler",
  "Foie gras producer",
  "Professional and hobby associations",
  "Professional organizer",
  "Promenade",
  "Promotional products supplier",
  "Propane supplier",
  "Propeller shop",
  "Property administrator",
  "Property investment",
  "Property maintenance",
  "Property management company",
  "Land registry office",
  "Prosthetics",
  "Prosthodontist",
  "Protective clothing supplier",
  "Protestant church",
  "Provence restaurant",
  "Provincial council",
  "Psychiatric hospital",
  "Psychiatrist",
  "Psychic",
  "Psychoanalyst",
  "Psychologist",
  "Psychomotor therapist",
  "Psychoneurological specialized clinic",
  "Psychopedagogy clinic",
  "Psychosomatic medical practitioner",
  "Psychotherapist",
  "Pub",
  "Public bath",
  "Public bathroom",
  "Public defender's office",
  "Public female bathroom",
  "Public golf course",
  "Public health department",
  "Public housing",
  "Public library",
  "Public male bathroom",
  "Public medical center",
  "Public parking space",
  "Public prosecutors office",
  "Public relations firm",
  "Public safety office",
  "Public sauna",
  "Public educational institution",
  "Public sector bank",
  "Public swimming pool",
  "Public university",
  "Public wheelchair-accessible bathroom",
  "Public works department",
  "Publisher",
  "Pueblan restaurant",
  "Puerto Rican restaurant",
  "Pulmonologist",
  "Pump supplier",
  "Pumpkin patch",
  "Punjabi restaurant",
  "Puppet theater",
  "PVC industry",
  "PVC windows supplier",
  "Pyrotechnician",
  "Qing fang market place",
  "Quaker church",
  "Quantity surveyor",
  "Quarry",
  "Québécois restaurant",
  "Quilt shop",
  "Race car dealer",
  "Racecourse",
  "Racing car parts store",
  "Raclette restaurant",
  "Racquetball club",
  "Radiator repair service",
  "Radiator shop",
  "Radio broadcaster",
  "Radiologist",
  "Radiotherapist",
  "Raft trip outfitter",
  "Rafting",
  "Rail museum",
  "Railing contractor",
  "Railroad company",
  "Railroad contractor",
  "Railroad equipment supplier",
  "Railroad ties supplier",
  "Railway services",
  "Rainwater tank supplier",
  "Rajasthani restaurant",
  "Ram dealer",
  "Ramen restaurant",
  "Ranch",
  "Rare book store",
  "Raw food restaurant",
  "Ready mix concrete supplier",
  "Real Estate Agency",
  "Real estate agent",
  "Real estate appraiser",
  "Real estate attorney",
  "Real estate auctioneer",
  "Real estate consultant",
  "Real estate developer",
  "Real estate rental agency",
  "Real estate school",
  "Real estate surveyor",
  "Realschule (middle-tier secondary school)",
  "Reclamation centre",
  "Record company",
  "Records storage facility",
  "Record store",
  "Recording studio",
  "Recreation center",
  "Recruiter",
  "Rectory",
  "Recycling center",
  "Recycling drop-off location",
  "Reenactment site",
  "Reflexologist",
  "Reform synagogue",
  "Reformed church",
  "Refrigerated transport service",
  "Refrigerator repair service",
  "Refrigerator store",
  "Refugee camp",
  "Regional airport",
  "Regional council",
  "Regional government office",
  "Registered general nurse",
  "Registration office",
  "Registry office",
  "Rehabilitation center",
  "Rehearsal studio",
  "Reiki therapist",
  "Religious book store",
  "Religious destination",
  "Religious goods store",
  "Religious institution",
  "Religious lodging",
  "Religious organization",
  "Religious school",
  "Remodeler",
  "Renault dealer",
  "Renter's insurance agency",
  "Repair Service",
  "Reproductive health clinic",
  "Reptile store",
  "Research and product development",
  "Research engineer",
  "Research foundation",
  "Research institute",
  "Resident registration office",
  "Residential college",
  "Residents association",
  "Resort hotel",
  "Rest stop",
  "Restaurant",
  "Brasserie",
  "Restaurant supply store",
  "Resume service",
  "Retail space rental agency",
  "Retaining wall supplier",
  "Retirement community",
  "Retirement home",
  "Retreat center",
  "Rheumatologist",
  "Rice cake shop",
  "Rice cracker shop",
  "Rice mill",
  "Rice restaurant",
  "Rice shop",
  "Rice wholesaler",
  "River port",
  "Road construction company",
  "Road construction machine repair service",
  "Road cycling",
  "Road safety town",
  "Roads ports and canals engineers association",
  "Rock climbing",
  "Rock climbing gym",
  "Rock climbing instructor",
  "Rock music club",
  "Rock shop",
  "Rodeo",
  "Rolled metal products supplier",
  "Roller coaster",
  "Roller skating club",
  "Roller skating rink",
  "Rolls-Royce dealer",
  "Roman restaurant",
  "Romanian restaurant",
  "Roofing contractor",
  "Roofing supply store",
  "Roommate referral service",
  "Rowing area",
  "Rowing club",
  "Rsl club",
  "Rubber products supplier",
  "Rubber stamp store",
  "Rug store",
  "Rugby",
  "Rugby club",
  "Rugby field",
  "Rugby league club",
  "Rugby store",
  "Running store",
  "Russian grocery store",
  "Russian Orthodox church",
  "Russian restaurant",
  "Rustic furniture store",
  "RV dealer",
  "RV park",
  "Recreational vehicle rental agency",
  "RV repair shop",
  "RV storage facility",
  "RV supply store",
  "Saab dealer",
  "Sacem",
  "Saddlery",
  "Safe & vault shop",
  "Safety equipment supplier",
  "Sailing club",
  "Sailing event area",
  "Sailing school",
  "Sailmaker",
  "Sake brewery",
  "Salad shop",
  "Salsa bar",
  "Salsa classes",
  "Salvadoran restaurant",
  "Salvage dealer",
  "Salvage yard",
  "Samba school",
  "Sambo school",
  "Sambodrome",
  "Sand & gravel supplier",
  "Sand plant",
  "Sandblasting service",
  "Sandwich shop",
  "Sanitary inspection",
  "Sanitation service",
  "Sardinian restaurant",
  "Saree Shop",
  "Satay restaurant",
  "Satellite communication service",
  "Saturn dealer",
  "Sauna",
  "Sauna club",
  "Sauna store",
  "Savings bank",
  "Saw mill",
  "Saw sharpening service",
  "Scaffolder",
  "Scaffolding rental service",
  "Scale model club",
  "Model shop",
  "Scale repair service",
  "Scale supplier",
  "Scandinavian restaurant",
  "Scenic spot",
  "Scenography company",
  "School",
  "School administrator",
  "School bus service",
  "School center",
  "School district office",
  "School for the deaf",
  "School house",
  "School lunch center",
  "School supply store",
  "Science museum",
  "Scientific equipment supplier",
  "Scooter rental service",
  "Scooter repair shop",
  "Scottish restaurant",
  "Scout hall",
  "Scout home",
  "Scouting",
  "Scrap metal dealer",
  "Scrapbooking store",
  "Screen printer",
  "Screen printing shop",
  "Screen printing supply store",
  "Screen repair service",
  "Screen store",
  "Screw supplier",
  "SCUBA instructor",
  "SCUBA tour agency",
  "Sculptor",
  "Sculpture",
  "Sculpture museum",
  "Seafood donburi restaurant",
  "Seafood farm",
  "Seafood market",
  "Seafood restaurant",
  "Seafood wholesaler",
  "Seal shop",
  "Seaplane base",
  "Seasonal goods store",
  "Seat dealer",
  "Seblak restaurant",
  "Second hand store",
  "Secondary school",
  "Secondary school (Germany)",
  "Security guard service",
  "Security service",
  "Security system installer",
  "Security system supplier",
  "Seed supplier",
  "Seitai",
  "Self defense school",
  "Self service car wash",
  "Self service health station",
  "Self service restaurant",
  "Self-storage facility",
  "Semi conductor supplier",
  "Seminary",
  "Senior citizen center",
  "Aged care",
  "Senior high school",
  "Septic system service",
  "Serbian restaurant",
  "Serviced accommodation",
  "Serviced apartment",
  "Seventh-day Adventist church",
  "Sewage disposal service",
  "Sewage treatment plant",
  "Sewing company",
  "Sewing machine repair service",
  "Sewing machine store",
  "Sewing shop",
  "Sexologist",
  "Seychelles restaurant",
  "Sfiha restaurant",
  "Sukiyaki and Shabu Shabu restaurant",
  "Shabu-shabu restaurant",
  "Shandong restaurant",
  "Shanghainese restaurant",
  "Shared-use commercial kitchen",
  "Sharpening service",
  "Shawarma restaurant",
  "Shed builder",
  "Sheep shearer",
  "Sheepskin and wool products supplier",
  "Sheepskin coat store",
  "Sheet metal contractor",
  "Sheet music store",
  "Shelter",
  "Sheltered housing",
  "Shelving store",
  "Sheriff's department",
  "Shinkin bank",
  "Shinto shrine",
  "Shipbuilding and repair company",
  "Shipping and mailing service",
  "Shipping company",
  "Shipping equipment industry",
  "Shipping service",
  "Shipyard",
  "Shochu brewery",
  "Shoe factory",
  "Shoe repair shop",
  "Shoe shining service",
  "Shoe store",
  "Footwear wholesaler",
  "Shogi lesson",
  "Shooting event area",
  "Shooting range",
  "Shop supermarket furniture store",
  "Shopfitter",
  "Shopping mall",
  "Short term apartment rental agency",
  "Shower door shop",
  "Showroom",
  "Shredding service",
  "Shrimp farm",
  "Shrine",
  "Sichuan restaurant",
  "Sicilian restaurant",
  "Siding contractor",
  "Sightseeing tour agency",
  "Sign shop",
  "Gurudwara",
  "Silk plant shop",
  "Silk store",
  "Silversmith",
  "Singaporean restaurant",
  "Singing telegram service",
  "Single sex secondary school",
  "Singles organization",
  "Sixth form college",
  "Skate sharpening service",
  "Skate shop",
  "Skateboard park",
  "Skateboard shop",
  "Skating instructor",
  "Skeet shooting range",
  "Kushiage and kushikatsu restaurant",
  "Ski club",
  "Ski rental service",
  "Ski repair service",
  "Ski resort",
  "Ski school",
  "Ski shop",
  "Skin care clinic",
  "Skin care products vending machine",
  "Skittle club",
  "Skoda dealer",
  "Skydiving center",
  "Skylight contractor",
  "Slaughterhouse",
  "Sleep clinic",
  "Small appliance repair service",
  "Small claims assistance service",
  "Small engine repair service",
  "Small plates restaurant",
  "Smart Car dealer",
  "Smart dealer",
  "Smart shop",
  "Smog inspection station",
  "Smoke shop",
  "Snack bar",
  "Snow removal service",
  "Snowboard rental service",
  "Snowboard shop",
  "Snowmobile dealer",
  "Snowmobile rental service",
  "Soapland",
  "Soba noodle shop",
  "Soccer club",
  "Soccer field",
  "Soccer practice",
  "Soccer store",
  "Social club",
  "Social security attorney",
  "Social security financial department",
  "Social security office",
  "Social services organization",
  "Social welfare center",
  "Social worker",
  "Societe de Flocage",
  "Sod supplier",
  "Sofa store",
  "Soft drinks shop",
  "Suppon restaurant",
  "Softball club",
  "Softball field",
  "Software company",
  "Software training institute",
  "Soil testing service",
  "Sokol house",
  "Solar energy company",
  "Solar energy contractor",
  "Solar energy equipment supplier",
  "Solar hot water system supplier",
  "Solar photovoltaic power plant",
  "Solid fuel company",
  "Solid waste engineer",
  "Soondae restaurant",
  "Soto ayam restaurant",
  "Soto restaurant",
  "Soul food restaurant",
  "Soup kitchen",
  "Soup restaurant",
  "Soup shop",
  "South African restaurant",
  "South American restaurant",
  "South Asian restaurant",
  "Southeast Asian restaurant",
  "South Indian restaurant",
  "South Sulawesi restaurant",
  "Southern Italian restaurant",
  "Southern restaurant (US)",
  "Southwest France restaurant",
  "Southwestern restaurant (US)",
  "Souvenir manufacturer",
  "Souvenir store",
  "Soy sauce maker",
  "Spa",
  "Spa and health club",
  "Spa garden",
  "Space of remembrance",
  "Spanish restaurant",
  "Special education school",
  "Specialized clinic",
  "Specialized hospital",
  "Speech pathologist",
  "Sperm bank",
  "Spice store",
  "Spice exporter",
  "Spice wholesaler",
  "Spiritist center",
  "Sport tour agency",
  "Sporting goods store",
  "Sports accessories wholesaler",
  "Sports bar",
  "Sports card store",
  "Sports club",
  "Sports complex",
  "Sports equipment rental service",
  "Sports massage therapist",
  "Sports medicine clinic",
  "Sports medicine physician",
  "Sports memorabilia store",
  "Sports nutrition store",
  "Sports school",
  "Sportswear store",
  "Sportwear manufacturer",
  "Spring supplier",
  "Squash club",
  "Squash court",
  "Sri Lankan restaurant",
  "Stable",
  "Stadium",
  "Stage",
  "Stage lighting equipment supplier",
  "Stained glass studio",
  "Stainless steel plant",
  "Stair contractor",
  "Stall installation service",
  "Stamp collectors club",
  "Stamp shop",
  "Stand bar",
  "Staple food package",
  "State archive",
  "State department of agricultural development",
  "State Department Agriculture Food Supply",
  "State Department Communication",
  "State Department Finance",
  "State Department for Social Development",
  "State Department Housing and Urban Development",
  "State Department of Environment",
  "State Department of Tourism",
  "State Department of Transportation",
  "State Department Science Technology",
  "State department of social defense",
  "State Dept of Culture",
  "State Dept of Sports",
  "State employment department",
  "State government office",
  "State liquor store",
  "State office of education",
  "State owned farm",
  "State park",
  "State parliament",
  "State police",
  "Stationery manufacturer",
  "Stationery store",
  "Stationery wholesaler",
  "Statuary",
  "STD clinic",
  "STD testing service",
  "Steak house",
  "Steamboat restaurant",
  "Steamed bun shop",
  "Steel construction company",
  "Steel distributor",
  "Steel drum supplier",
  "Steel erector",
  "Steel fabricator",
  "Steel framework contractor",
  "Steelwork design company",
  "Stereo rental store",
  "Stereo repair service",
  "Home audio store",
  "Sticker manufacturer",
  "Stitching class",
  "Stock broker",
  "Stock exchange building",
  "Stone carving",
  "Stone cutter",
  "Stone supplier",
  "Storage facility",
  "Store",
  "Store equipment supplier",
  "Stove builder",
  "Stringed instrument maker",
  "Structural engineer",
  "Stucco contractor",
  "Student career counseling office",
  "Student dormitory",
  "Student housing center",
  "Student union",
  "Students parents association",
  "Students support association",
  "Study at home school",
  "Studying center",
  "Stylist",
  "Subaru dealer",
  "Suburban train line",
  "Sugar factory",
  "Sugar Shack",
  "Sukiyaki restaurant",
  "Summer camp",
  "Summer toboggan run",
  "Sundae restaurant",
  "Sundanese restaurant",
  "Sunglasses store",
  "Sunroom contractor",
  "Super public bath",
  "Superannuation consultant",
  "Superfund site",
  "Supermarket",
  "Support group",
  "Surf lifesaving club",
  "Surf school",
  "Surf shop",
  "Surgeon",
  "Surgical center",
  "Surgical oncologist",
  "Surgical products wholesaler",
  "Surgical supply store",
  "Surinamese restaurant",
  "Surplus store",
  "Surveyor",
  "Sushi restaurant",
  "Sushi takeaway",
  "Suzuki dealer",
  "Swabian restaurant",
  "Swedish restaurant",
  "Swim club",
  "Swimming basin",
  "Swimming competition",
  "Swimming facility",
  "Swimming instructor",
  "Swimming lake",
  "Swimming pool",
  "Swimming pool contractor",
  "Swimming pool repair service",
  "Swimming pool supply store",
  "Swimming school",
  "Swimwear store",
  "Swiss restaurant",
  "Synagogue",
  "Syrian restaurant",
  "T-shirt company",
  "T-shirt store",
  "Tabascan restaurant",
  "Table tennis club",
  "Table tennis facility",
  "Table tennis supply store",
  "Tacaca restaurant",
  "Tack shop",
  "Taco restaurant",
  "Taekwondo competition area",
  "Taekwondo school",
  "Tai chi school",
  "Tailor",
  "Taiwanese restaurant",
  "Takoyaki restaurant",
  "Talent agency",
  "Tamale shop",
  "Tannery",
  "Tanning salon",
  "Taoist temple",
  "Tapas bar",
  "Tapas restaurant",
  "Tata Motors dealer",
  "Tatami store",
  "Tattoo and piercing shop",
  "Tattoo artist",
  "Tattoo removal service",
  "Tattoo shop",
  "Tax assessor",
  "Tax attorney",
  "Tax collector's office",
  "Tax consultant",
  "Tax department",
  "Tax preparation",
  "Tax preparation service",
  "Taxi service",
  "Taxicab Stand",
  "Taxidermist",
  "TB clinic",
  "Tea exporter",
  "Tea house",
  "Tea manufacturer",
  "Tea market place",
  "Tea store",
  "Tea wholesaler",
  "Teacher college",
  "Teachers' housing",
  "Technical school",
  "Technical university",
  "Technology museum",
  "Technology park",
  "Teeth whitening service",
  "Tegal restaurant",
  "Telecommunication school",
  "Telecommunications contractor",
  "Telecommunications engineer",
  "Telecommunications equipment supplier",
  "Telecommunications service provider",
  "Telemarketing service",
  "Telephone answering service",
  "Telephone company",
  "Telephone exchange",
  "Telescope store",
  "Television repair service",
  "Television station",
  "Temaki restaurant",
  "Temp agency",
  "Tempura donburi restaurant",
  "Tempura restaurant",
  "Tenant ownership",
  "Tenant's union",
  "Tennis club",
  "Tennis court",
  "Tennis court construction company",
  "Tennis instructor",
  "Tennis store",
  "Tent rental service",
  "Teppanyaki restaurant",
  "Tesla showroom",
  "Tex-Mex restaurant",
  "Textile engineer",
  "Textile exporter",
  "Textile mill",
  "Thai massage therapist",
  "Thai restaurant",
  "Theater company",
  "Theater production",
  "Theater supply store",
  "Theatrical costume supplier",
  "Theme park",
  "Thermal baths",
  "Thermal power plant",
  "Thread supplier",
  "Threads and yarns wholesaler",
  "3D printing service",
  "Thrift store",
  "Thuringian restaurant",
  "Tibetan restaurant",
  "Tiffin center",
  "Tiki bar",
  "Tile contractor",
  "Tile manufacturer",
  "Tile store",
  "Timeshare agency",
  "Tyre manufacturer",
  "Tire repair shop",
  "Tire shop",
  "Title company",
  "Toast restaurant",
  "Tobacco exporter",
  "Tobacco shop",
  "Tobacco supplier",
  "Tofu restaurant",
  "Tofu shop",
  "Toiletries store",
  "Toll road rest stop",
  "Toll booth",
  "Toner cartridge supplier",
  "Tongue restaurant",
  "Tonkatsu restaurant",
  "Tool & die shop",
  "Tool grinding service",
  "Tool manufacturer",
  "Tool rental service",
  "Tool repair shop",
  "Tool store",
  "Tool wholesaler",
  "Toolroom",
  "Topography company",
  "Topsoil supplier",
  "Tortilla shop",
  "Tour agency",
  "Tour operator",
  "Tourist attraction",
  "Tourist information center",
  "Communications tower",
  "Towing equipment provider",
  "Towing service",
  "Townhouse complex",
  "Toy and game manufacturer",
  "Toy library",
  "Toy manufacturer",
  "Toy museum",
  "Toy store",
  "Toyota dealer",
  "Tractor dealer",
  "Tractor repair shop",
  "Trade fair construction company",
  "Trade school",
  "Trading card store",
  "Traditional costume club",
  "Traditional Kostume store",
  "Traditional market",
  "Traditional restaurant",
  "Traditional teahouse",
  "Traditional American restaurant",
  "Traffic officer",
  "Trailer dealer",
  "Trailer manufacturer",
  "Trailer rental service",
  "Trailer repair shop",
  "Trailer supply store",
  "Train depot",
  "Train repairing center",
  "Train ticket agency",
  "Train ticket office",
  "Train yard",
  "Training centre",
  "Training school",
  "Transcription service",
  "Transit depot",
  "Translator",
  "Transmission shop",
  "Transplant surgeon",
  "Transportation escort service",
  "Transportation service",
  "Travel agency",
  "Travel clinic",
  "Travel lounge",
  "Tree farm",
  "Tree service",
  "Trial attorney",
  "Tribal headquarters",
  "Trophy shop",
  "Tropical fish store",
  "Truck accessories store",
  "Truck dealer",
  "Truck farmer",
  "Truck parts supplier",
  "Truck rental agency",
  "Truck repair shop",
  "Truck stop",
  "Truck topper supplier",
  "Trucking company",
  "Trucking school",
  "Truss manufacturer",
  "Trust bank",
  "Tsukigime parking lot",
  "Tune up supplier",
  "Tuning automobile",
  "Tunisian restaurant",
  "Turf supplier",
  "Turkish restaurant",
  "Turkmen restaurant",
  "Turnery",
  "Tuscan restaurant",
  "Tutoring service",
  "Tuxedo shop",
  "Typewriter repair service",
  "Typewriter supplier",
  "Typing service",
  "Udon noodle restaurant",
  "Ukrainian restaurant",
  "Unagi restaurant",
  "Underwear store",
  "Unemployment office",
  "Unfinished furniture store",
  "Uniform store",
  "Hairdresser",
  "Unitarian Universalist Church",
  "United Church of Canada",
  "United Church of Christ",
  "United Methodist church",
  "United States Armed Forces Base",
  "Unity church",
  "University",
  "University department",
  "University hospital",
  "University library",
  "Upholstery cleaning service",
  "Upholstery shop",
  "Urban planning department",
  "Urgent care center",
  "Urologist",
  "Urology clinic",
  "Uruguayan restaurant",
  "Pacific Northwest restaurant (US)",
  "Used appliance store",
  "Used auto parts store",
  "Used bicycle shop",
  "Used book store",
  "Used car dealer",
  "Used CD store",
  "Used clothing store",
  "Used computer store",
  "Used furniture store",
  "Used game store",
  "Used motorcycle dealer",
  "Used musical instrument store",
  "Used office furniture store",
  "Used store fixture supplier",
  "Used tire shop",
  "Used truck dealer",
  "Utility contractor",
  "Utility trailer dealer",
  "Uyghur cuisine restaurant",
  "Uzbeki restaurant",
  "Holiday apartment",
  "Vacation home rental agency",
  "Vacuum cleaner repair shop",
  "Vacuum cleaner store",
  "Vacuum cleaning system supplier",
  "Valencian restaurant",
  "Valet parking service",
  "Van rental agency",
  "Vaporizer store",
  "Variety store",
  "Vascular surgeon",
  "Vastu consultant",
  "VCR repair service",
  "Vegan restaurant",
  "Vegetable wholesale market",
  "Vegetable wholesaler",
  "Vegetarian cafe and deli",
  "Vegetarian restaurant",
  "Vehicle exporter",
  "Vehicle inspection",
  "Vehicle shipping agent",
  "Vehicle wrapping service",
  "Velodrome",
  "Vending machine supplier",
  "Venereologist",
  "Venetian restaurant",
  "Venezuelan restaurant",
  "Ventilating equipment manufacturer",
  "Venture capital company",
  "Veterans affairs department",
  "Veterans center",
  "Veterans hospital",
  "Veterans' Organization",
  "Veterinarian",
  "Veterinary pharmacy",
  "Video arcade",
  "Video camera repair service",
  "Video conferencing equipment supplier",
  "Video conferencing service",
  "Video duplication service",
  "Video editing service",
  "Video equipment repair service",
  "Video game rental kiosk",
  "Video game rental service",
  "Video game rental store",
  "Video game store",
  "Video karaoke",
  "Video production service",
  "Video store",
  "Vietnamese restaurant",
  "Villa",
  "Village hall",
  "Vineyard",
  "Vineyard church",
  "Vintage clothing store",
  "Vinyl sign shop",
  "Violin shop",
  "Virtual office rental",
  "Visa and passport office",
  "Visa consultant",
  "Visitor center",
  "Vitamin & supplements store",
  "Vocal instructor",
  "Vocational college",
  "Vocational gymnasium school",
  "Vocational school one",
  "Vocational secondary school",
  "Vocational school",
  "Volkswagen dealer",
  "Volleyball club",
  "Volleyball court",
  "Volleyball instructor",
  "Volunteer organization",
  "Volvo dealer",
  "Voter registration office",
  "Waldorf kindergarten",
  "Waldorf school",
  "Walk-in clinic",
  "Wallpaper installer",
  "Wallpaper store",
  "War museum",
  "Warehouse",
  "Warehouse club",
  "Warehouse store",
  "Washer & dryer repair service",
  "Washer & dryer store",
  "Waste management service",
  "Watch manufacturer",
  "Watch repair service",
  "Watch store",
  "Water cooler supplier",
  "Water damage restoration service",
  "Water filter supplier",
  "Water jet cutting service",
  "Water mill",
  "Water park",
  "Water polo pool",
  "Water pump supplier",
  "Water purification company",
  "Water ski shop",
  "Water skiing club",
  "Water skiing instructor",
  "Water skiing service",
  "Water softening equipment supplier",
  "Water sports equipment rental service",
  "Water tank cleaning service",
  "Water testing service",
  "Water treatment plant",
  "Water treatment supplier",
  "Water utility company",
  "Water works",
  "Water works equipment supplier",
  "Waterbed repair service",
  "Waterbed store",
  "Waterproofing company",
  "Wax museum",
  "Wax supplier",
  "Waxing hair removal service",
  "Weather forecast service",
  "Weaving mill",
  "Web hosting company",
  "Website designer",
  "Wedding bakery",
  "Wedding buffet",
  "Wedding chapel",
  "Wedding dress rental service",
  "Wedding photographer",
  "Wedding planner",
  "Wedding service",
  "Wedding souvenir shop",
  "Wedding store",
  "Wedding venue",
  "Weigh station",
  "Weight loss service",
  "Weightlifting area",
  "Weir",
  "Welder",
  "Welding gas supplier",
  "Welding supply store",
  "Well drilling contractor",
  "Wellness center",
  "Wellness hotel",
  "Wellness program",
  "Welsh restaurant",
  "Wesleyan church",
  "West African restaurant",
  "Western apparel store",
  "Western restaurant",
  "Whale watching tour agency",
  "Wheel alignment service",
  "Wheel store",
  "Wheelchair rental service",
  "Wheelchair repair service",
  "Wheelchair store",
  "Wholesale bakery",
  "Wholesale drugstore",
  "Wholesale florist",
  "Wholesale food store",
  "Wholesale grocer",
  "Wholesale jeweler",
  "Wholesale market",
  "Wholesale plant nursery",
  "Wholesaler",
  "Wholesaler household appliances",
  "Wi-Fi spot",
  "Wicker store",
  "Wig shop",
  "Wildlife and safari park",
  "Wildlife park",
  "Wildlife refuge",
  "Wildlife rescue service",
  "Willow basket manufacturer",
  "Wind farm",
  "Wind turbine builder",
  "Window cleaning service",
  "Window installation service",
  "Window supplier",
  "Window tinting service",
  "Window treatment store",
  "Windsurfing store",
  "Wine bar",
  "Wine cellar",
  "Wine club",
  "Wine storage facility",
  "Wine store",
  "Wine wholesaler and importer",
  "Winemaking supply store",
  "Winery",
  "Wing chun school",
  "Wok restaurant",
  "Women's clothing store",
  "Women's college",
  "Women's organization",
  "Women's personal trainer",
  "Womens protection service",
  "Women's shelter",
  "Wood and laminate flooring supplier",
  "Wood floor installation service",
  "Wood floor refinishing service",
  "Wood frame supplier",
  "Wood stove shop",
  "Wood supplier",
  "Wood working class",
  "Woodworker",
  "Woodworking supply store",
  "Wool store",
  "Work clothes store",
  "Workers' club",
  "Working womens hostel",
  "Wrestling school",
  "X-ray equipment supplier",
  "X-ray lab",
  "Yacht broker",
  "Yacht club",
  "Yakatabune",
  "Yakiniku restaurant",
  "Yakisoba Restaurant",
  "Yakitori restaurant",
  "Yarn store",
  "Yemenite restaurant",
  "Yeshiva",
  "Yoga instructor",
  "Yoga retreat center",
  "Yoga studio",
  "Youth care",
  "Youth center",
  "Youth clothing store",
  "Youth club",
  "Youth group",
  "Youth hostel",
  "Youth organization",
  "Youth social services organization",
  "Yucatan restaurant",
  "Zhejiang restaurant",
  "Zoo",
]

export const OFFERS_OPTIONS: Array<SelectOption<string>> = [
  { label: "Services", value: "services" },
  { label: "Products", value: "products" },
  { label: "Appointments", value: "appointments" },
]

export const PACKAGE_NAMES: Record<OnboardingForm.Package, string> = {
  [ OnboardingForm.Package.COMPETE ]: "Compete",
  [ OnboardingForm.Package.COMPETE_ADS ]: "Compete + Ads",
  [ OnboardingForm.Package.DOMINATE ]: "Dominate",
  [ OnboardingForm.Package.DOMINATE_ADS ]: "Dominate + Ads",
  [ OnboardingForm.Package.ENGAGE ]: "Engage",
  [ OnboardingForm.Package.ENGAGE_ADS ]: "Engage + Ads",
  [ OnboardingForm.Package.SUMMIT ]: "Summit",
  [ OnboardingForm.Package.SUMMIT_ADS ]: "Summit + Ads",
}

export const SITE_TEMPLATES_OPTIONS: Array<SelectOption<string>> = [
  { label: "Brooklyn", value: "brooklyn" },
  { label: "Devon", value: "devon" },
  { label: "Princeton", value: "princeton" },
  { label: "Hudson", value: "hudson" },
  { label: "Savannah", value: "savannah" },
  { label: "Kai", value: "kai" },
  { label: "Valencia", value: "valencia" },
]

export const SITE_TREE_MAX_HIERACHY = 2

export const CANADA_PROVINCES = [
  { label: "Alberta", value: "AB" },
  { label: "British Columbia", value: "BC" },
  { label: "Manitoba", value: "MB" },
  { label: "New Brunswick", value: "NB" },
  { label: "Newfoundland and Labrador", value: "NL" },
  { label: "Northwest Territories", value: "NT" },
  { label: "Nova Scotia", value: "NS" },
  { label: "Nunavut", value: "NU" },
  { label: "Ontario", value: "ON" },
  { label: "Prince Edward Island", value: "PE" },
  { label: "Quebec", value: "QC" },
  { label: "Saskatchewan", value: "SK" },
  { label: "Yukon", value: "YT" },
]

export const US_STATES = [
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "District of Columbia", value: "DC" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Maryland", value: "MD" },
  { label: "Massachusetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregon", value: "OR" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virginia", value: "VA" },
  { label: "Washington", value: "WA" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" },
]

export const WRITTING_TONES_OPTIONS: Array<SelectOption<string>> = [
  { label: "Professional", value: "professional" },
  { label: "Conversational", value: "conversational" },
  { label: "Informative", value: "informative" },
  { label: "Friendly", value: "friendly" },
  { label: "Witty", value: "witty" },
  { label: "Welcoming", value: "welcoming" },
]

export const WRITTING_PERSPECTIVE_OPTIONS: Array<SelectOption<string>> = [
  { label: "First", value: "first" },
  { label: "Fourth", value: "fourth" },
]

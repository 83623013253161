import { Flex, Loading } from "@onelocal/frontend-web/common"

interface LoadingOverlayProps {
  isLoading: boolean
}

export const LoadingOverlay: React.FC<LoadingOverlayProps> = ( { isLoading } ) => {
  if( ! isLoading ) return null

  return (
    <Flex
      alignItems="center"
      display="flex"
      justifyContent="center"
      style={ { height: "50vh" } }
    >
      <Loading />
    </Flex>
  )
}

import mixpanel from "mixpanel-browser"

import { errorHelper } from "@onelocal/frontend/common"
import type { AuthAccount, AuthSession } from "@onelocal/frontend-dashboard/auth"
// eslint-disable-next-line @nx/enforce-module-boundaries
import { MerchantSettings, settingsHelper } from "@onelocal/frontend-dashboard/settings"
import { formattingHelpers, ProductNames } from "@onelocal/shared/common"

export namespace AnalyticHelper {
  export enum EventLocation {
    DASHBOARD_LOGIN = "Dashboard Login",
    LOCALVISITS_BOOKING_DETAILS_MODAL = "LocalVisits Booking Details Modal",
    LOCALMESSAGES_CALL_MODAL = "LocalMessages Call Modal",
  }

  export enum EventName {
    ACCOUNT_CONNECTED = "Account Connected",
    ACCOUNT_DISCONNECTED = "Account Disconnected",
    ACCOUNT_EDITED = "Account Edited",
    ACCOUNT_INFO_SAVED = "Account Info Saved",
    ADD_REPLY_TO_GOOGLE_REVIEW = "Add Reply to Google Review",
    ALL_CONTACTS_CLEARED = "All Contacts Cleared",
    ALL_CONTACTS_SELECTED = "All Contacts Selected",
    ASSIGNEE_CHANGED = "Assignee Changed",
    ASSISTANT_FLOW_CREATED = "Flow Created",
    ASSISTANT_FLOW_DELETE = "Flow Deleted",
    ASSISTANT_FLOW_EDITED = "Flow Edited",
    AUTORESPONDER_SETTINGS_SAVED = "Autoresponder Settings Saved",
    BULK_MESSAGE_SENT = "Bulk Message Sent",
    CARD_ACCESSED = "Card Accessed",
    CARD_CLICKED = "Card Clicked",
    CARD_DISMISSED = "Card Dismissed",
    CARD_DONE = "Card Done",
    CARD_RESTORED = "Card Restored",
    CONTACT_DELETED = "Contact Deleted",
    CONTACT_DETAILS_EDITED = "Contact Details Edited",
    CONTACT_EDITED = "Contact Edited",
    CONTACTS_EXPORTED = "Contacts Exported",
    CONTACTS_IMPORTED = "Contacts Imported",
    CONTACTS_SELECTION = "Contacts Selection",
    CONVERSATION_ASSIGNMENT_DISABLED = "Conversation Assignment Disabled",
    CONVERSATION_ASSIGNMENT_ENABLED = "Conversation Assignment Enabled",
    CONVERSATION_BLOCKED = "Conversation Blocked",
    CONVERSATION_CLOSED = "Conversation Closed",
    CONVERSATION_INBOX_CHANGED = "Conversation Inbox Changed",
    CONVERSATION_MUTED = "Conversation Muted",
    CONVERSATION_OPENED = "Conversation Opened",
    CONVERSATION_OPTED_IN = "Conversation Opted-in",
    CONVERSATION_OPTED_OUT = "Conversation Opted-Out",
    CONVERSATION_TAG_ADDED = "Conversation Tag Added",
    CONVERSATION_TAG_CREATED = "Conversation Tag Created",
    CONVERSATION_TAG_DELETED = "Conversation Tag Deleted",
    CONVERSATION_TAG_EDITED = "Conversation Tag Edited",
    CONVERSATION_TAG_FILTER = "Conversation Tag Filter",
    CONVERSATION_TAG_REMOVED = "Conversation Tag Removed",
    CONVERSATION_UNBLOCKED = "Conversation Unblocked",
    CONVERSATION_UNMUTED = "Conversation Unmuted",
    CREDENTIALS_SUBMITTED = "Credentials Submitted",
    CREDENTIALS_UPDATED = "Credentials Updated",
    CTA_CLICKED = "CTA Clicked",
    DUPLICATE_CONTACT_REMOVED = "Duplicate Contact Removed",
    DUPLICATE_CONTACT_SELECTED = "Duplicate Contact Selected",
    EDIT_REPLY_TO_GOOGLE_REVIEW = "Edit Reply to Google Review",
    EMAIL_CLICKED = "Email Clicked",
    FEED_CLICKED = "Feed Clicked",
    FEEDBACK_REQUEST_SCHEDULED = "Feedback Request Scheduled",
    FEEDBACK_REQUEST_SENT = "Feedback Request Sent",
    FEEDBACK_REQUEST_STARTED = "Feedback Request Started",
    FEEDBACK_REQUESTED = "Feedback Requested",
    FILTER_CLICKED = "Filter Clicked",
    GENERAL_LOCAL_MESSAGES_SETTINGS_EDITED = "General LocalMessages Settings Edited",
    KEYWORD_ENABLED = "Keyword Enabled",
    KEYWORDS_SELECTED_CHANGED = "Keywords Selected Changed",
    LOCAL_RESPONSE_CALL_RECORDING_PLAYED = "LocalResponse Call Recording Played",
    LOCAL_RESPONSE_VOICEMAIL_PLAYED = "LocalResponse Voicemail Played",
    LOGIN = "Login",
    LOGIN_FAILED = "Login Failed",
    LOGOUT = "Logout",
    MENU_OPEN = "Menu Opened",
    MESSAGE_PICTURE_SELECTED = "Message Picture Selected",
    MESSAGE_RESCHEDULED = "Message Rescheduled",
    MESSAGE_SCHEDULED = "Message Scheduled",
    MESSAGE_SENT = "Message Sent",
    MESSAGE_TEMPLATE_CREATED = "Message Template Created",
    MESSAGE_TEMPLATE_DELETED = "Message Template Deleted",
    MESSAGE_TEMPLATE_EDITED = "Message Template Edited",
    MESSAGE_TEMPLATE_SELECTED = "Message Template Selected",
    NEW_CONTACT_CREATED = "New Contact Created",
    NEW_MESSAGE_ADD_NEW_CONTACT = "New Message - Add New Contact",
    NOTE_ADDED = "Note Added",
    NOTIFICATION_CHANGED = "Notification Changed",
    NOTIFICATION_SAVED = "Notification Saved",
    OUTGOING_CALL = "Outgoing Call",
    PAGE_OPENED = "Page Opened",
    PASSWORD_CHANGED = "Password Changed",
    PASSWORD_CHOSEN = "Password Chosen",
    PASSWORD_RESET = "Password Reset",
    PERIOD_CHANGED = "Period Changed",
    PHONE_NUMBER_CLICKED = "Phone Number Clicked",
    PUSH_NOTIFICATION_OPENED = "Push Notification Opened",
    PUSH_NOTIFICATION_SENT = "Push Notification Sent",
    REMOVE_REPLY_TO_GOOGLE_REVIEW = "Remove Reply to Google Review",
    REQUEST_RESCHEDULED = "Request Rescheduled",
    REVIEW_ATTRIBUTION_CHANGED = "Review Attribution Changed",
    SCHEDULED_MESSAGE_CANCELLED = "Scheduled Message Cancelled",
    SCHEDULED_REQUEST_CANCELLED = "Scheduled Request Cancelled",
    SEARCH = "Search",
    SECTION_ACCESSED = "Section Accessed",
    SURVEY_PREVIEW_SENT = "Survey Preview Sent",
    SURVEY_REMOVED = "Survey Removed",
    SURVEY_SETTINGS_EDITED = "Survey Settings Edited",
    SURVEY_SHARING_LINK_COPIED = "Survey Sharing Link Copied",
    SURVEY_SHARING_LINK_OPENED = "Survey Sharing Link Opened",
    SURVEY_SHARING_LINK_SHARED_ON_FACEBOOK = "Survey Sharing Link Shared on Facebook",
    TEMPLATE_FILTER_CHANGED = "Template Filter Changed",
    TIME_PERIOD_PICKER_CLICKED = "Time Period Accessed",
  }

  export type EventParams = {
    [ EventName.ACCOUNT_CONNECTED ]: {
      "Account": string
    }
    [ EventName.ACCOUNT_DISCONNECTED ]: {
      "Account": string
    }
    [ EventName.ACCOUNT_EDITED ]: {
      "Account": string
    }
    [ EventName.ACCOUNT_INFO_SAVED ]: undefined
    [ EventName.ALL_CONTACTS_CLEARED ]: undefined
    [ EventName.ALL_CONTACTS_SELECTED ]: undefined
    [ EventName.NEW_MESSAGE_ADD_NEW_CONTACT ]: undefined
    [ EventName.ADD_REPLY_TO_GOOGLE_REVIEW ]: undefined
    [ EventName.ASSIGNEE_CHANGED ]: {
      "Conversation ID": string
      "Location": string
    }
    [ EventName.ASSISTANT_FLOW_CREATED ]: {
      "Flow Name": string
      "Flow Status": "Activated" | "Deactivated"
      "Flow Type": string
      "Product": ProductNames.LOCAL_RESPONSE
      /** if any of field has user recording, it's "User Recording" */
      "Voice": "Bot" | "User Recording"
    }
    [ EventName.ASSISTANT_FLOW_CREATED ]: {
      "Flow Name": string
      "Flow Status": "Activated" | "Deactivated"
      "Flow Type": string
      "Product": ProductNames.LOCAL_RESPONSE
      /** if any of field has user recording, it's "User Recording" */
      "Voice": "Bot" | "User Recording"
    }
    [ EventName.ASSISTANT_FLOW_DELETE ]: {
      "Flow Name": string
      "Flow Type": string
      "Product": ProductNames.LOCAL_RESPONSE
    }
    [ EventName.ASSISTANT_FLOW_EDITED ]: {
      "Flow Name": string
      "Flow Status"?: "Activated" | "Deactivated"
      "Flow Type": string
      "Product": ProductNames.LOCAL_RESPONSE
      /** if any of field has user recording, it's "User Recording" */
      "Voice": "Bot" | "User Recording"
    }
    [ EventName.AUTORESPONDER_SETTINGS_SAVED ]: {
      "Product": ProductNames.LOCAL_MESSAGES
      "Status Changed To": string
    }
    [ EventName.BULK_MESSAGE_SENT ]: {
      "Image Attached": boolean
      "Keyword Enabled": boolean
      "Scheduled": boolean
    }
    [ EventName.CARD_ACCESSED ]: {
      CardType: string
      Location: string
      Product: string
    }
    [ EventName.CONTACT_DETAILS_EDITED ]: {
      "Contact ID": string
    }
    [ EventName.CONTACT_DELETED ]: {
      "Contact ID"?: string
    }
    [ EventName.CONTACTS_EXPORTED ]: {
      "Location": string
      "Product": ProductNames.LOCAL_CONTACTS
    }
    [ EventName.CONTACTS_IMPORTED ]: undefined
    [ EventName.CONTACTS_SELECTION ]: undefined
    [ EventName.CONVERSATION_ASSIGNMENT_DISABLED ]: undefined
    [ EventName.CONVERSATION_ASSIGNMENT_ENABLED ]: undefined
    [ EventName.CONVERSATION_BLOCKED ]: { "Conversation ID": string }
    [ EventName.CONVERSATION_CLOSED ]: { "Conversation ID": string }
    [ EventName.CONVERSATION_INBOX_CHANGED ]: undefined
    [ EventName.CONVERSATION_MUTED ]: { "Conversation ID": string }
    [ EventName.CONVERSATION_OPENED ]: { "Conversation ID": string }
    [ EventName.CONVERSATION_OPTED_IN ]: { "Conversation ID": string }
    [ EventName.CONVERSATION_OPTED_OUT ]: { "Conversation ID": string }
    [ EventName.CONVERSATION_TAG_ADDED ]: { "Conversation ID": string }
    [ EventName.CONVERSATION_TAG_CREATED ]: undefined
    [ EventName.CONVERSATION_TAG_DELETED ]: undefined
    [ EventName.CONVERSATION_TAG_EDITED ]: undefined
    [ EventName.CONVERSATION_TAG_FILTER ]: { Location: string }
    [ EventName.CONVERSATION_TAG_REMOVED ]: { "Conversation ID": string }
    [ EventName.CONVERSATION_UNBLOCKED ]: { "Conversation ID": string }
    [ EventName.CONVERSATION_UNMUTED ]: { "Conversation ID": string }
    [ EventName.CREDENTIALS_SUBMITTED ]: undefined
    [ EventName.CREDENTIALS_UPDATED ]: undefined
    [ EventName.CTA_CLICKED ]: {
      "Business Hours Enabled"?: "Y" | "N"
      "Call Forwarding Enabled"?: "Y" | "N"
      "Conversation ID"?: string
      "Incoming SMS Auto-Response"?: "None" | "During" | "Outside" | "Both"
      "Missed Call Auto-Response"?: "None" | "During" | "Outside" | "Both"
      "Ring Members Enabled"?: "Y" | "N"
      "Survey ID"?: string
      "Survey Name"?: string
      "Widget Auto-Response"?: "None" | "During" | "Outside" | "Both"
      CardType?: string
      Location?: string
      Name: string
      Product?: string
      Type?: string
    }
    [ EventName.DUPLICATE_CONTACT_REMOVED ]: undefined
    [ EventName.DUPLICATE_CONTACT_SELECTED ]: undefined
    [ EventName.EDIT_REPLY_TO_GOOGLE_REVIEW ]: undefined
    [ EventName.EMAIL_CLICKED ]: {
      "Location": string
      "Product": ProductNames
    }
    [ EventName.CARD_DISMISSED ]: {
      "Card Status"?: string
      "Card Type": string
      Location: string
      Product: string
    }
    [ EventName.CARD_DONE ]: {
      "Card Status": string
      "Card Type": string
      Location: string
      Product: string
    }
    [ EventName.CARD_RESTORED ]: {
      "Card Status"?: string
      "Card Type": string
      Location: string
      Product: string
    }
    [ EventName.FEED_CLICKED ]: { Name: string }
    [ EventName.FEEDBACK_REQUEST_SENT ]: {
      "Scheduled": boolean
    }
    [ EventName.FEEDBACK_REQUEST_STARTED ]: undefined
    [ EventName.FEEDBACK_REQUESTED ]: undefined
    [ EventName.GENERAL_LOCAL_MESSAGES_SETTINGS_EDITED ]: undefined
    [ EventName.KEYWORD_ENABLED ]: undefined
    [ EventName.KEYWORDS_SELECTED_CHANGED ]: {
      "Graph": string
      "Product"?: string
    }
    [ EventName.LOCAL_RESPONSE_CALL_RECORDING_PLAYED ]: {
      "Conversation ID": string
      "Product": ProductNames.LOCAL_RESPONSE
    }
    [ EventName.LOCAL_RESPONSE_VOICEMAIL_PLAYED ]: {
      "Conversation ID": string
      "Product": ProductNames.LOCAL_RESPONSE
    }
    [ EventName.LOGIN ]: undefined
    [ EventName.LOGIN_FAILED ]: {
      "Detail": string
      "Username": string
    }
    [ EventName.LOGOUT ]: {
      "Location": string
    }
    [ EventName.MENU_OPEN ]: {
      "Name": string
    }
    [ EventName.MESSAGE_PICTURE_SELECTED ]: undefined
    [ EventName.MESSAGE_RESCHEDULED ]: { "Conversation ID"?: string }
    [ EventName.MESSAGE_SCHEDULED ]: { "Conversation ID"?: string, "Image Attached": boolean }
    [ EventName.MESSAGE_SENT ]: {
      "Conversation ID"?: string
      "Image Attached": boolean
      "Location": string
      "Scheduled": boolean
    }
    [ EventName.MESSAGE_TEMPLATE_CREATED ]: {
      "Product"?: string
    }
    [ EventName.MESSAGE_TEMPLATE_DELETED ]: undefined
    [ EventName.MESSAGE_TEMPLATE_EDITED ]: {
      "Product"?: string
    }
    [ EventName.MESSAGE_TEMPLATE_SELECTED ]: {
      "Name": string
    }
    [ EventName.NEW_CONTACT_CREATED ]: undefined
    [ EventName.NOTE_ADDED ]: { "Conversation ID": string }
    [ EventName.NOTIFICATION_CHANGED ]: { Channel: string, Name: string }
    [ EventName.NOTIFICATION_SAVED ]: undefined
    [ EventName.OUTGOING_CALL ]: {
      "Location": string
      "Product": string
    }
    [ EventName.PAGE_OPENED ]: {
      "Contact ID"?: string
      "Conversation ID"?: string
      "Name": string
      "Product"?: string
      "Location"?: string
      "Survey ID"?: string
      "Survey Name"?: string
    }
    [ EventName.PASSWORD_CHANGED ]: {
      "Merchant ID": string
    }
    [ EventName.PASSWORD_CHOSEN ]: undefined
    [ EventName.PASSWORD_RESET ]: undefined
    [ EventName.PERIOD_CHANGED ]: {
      "Graph": string
      "Product"?: string
    }
    [ EventName.PHONE_NUMBER_CLICKED ]: {
      "Location": string
      "Product": ProductNames
    }
    [ EventName.PUSH_NOTIFICATION_OPENED ]: {
      "Product": string
      "Type": string | null
    }
    [ EventName.PUSH_NOTIFICATION_SENT ]: undefined
    [ EventName.REMOVE_REPLY_TO_GOOGLE_REVIEW ]: undefined
    [ EventName.REQUEST_RESCHEDULED ]: {
      "Survey ID": string
      "Survey Name": string
    }
    [ EventName.REVIEW_ATTRIBUTION_CHANGED ]: undefined
    [ EventName.SCHEDULED_MESSAGE_CANCELLED ]: { "Conversation ID"?: string }
    [ EventName.SCHEDULED_REQUEST_CANCELLED ]: {
      "Survey ID": string
      "Survey Name": string
    }
    [ EventName.SEARCH ]: {
      Location: string
      Product: string
    }
    [ EventName.SECTION_ACCESSED ]: {
      Location: string
      Product: string
      SectionType: string
    }
    [ EventName.SURVEY_SHARING_LINK_COPIED ]: {
      "Survey ID"?: string
      "Survey Name"?: string
    }
    [EventName.CARD_CLICKED]: {
      "Card Type": string
      "Card Status": string
      Location: string
      Product: string
    }
    [ EventName.FILTER_CLICKED ]: {
      "Filter Type": string
      Location: string
      Product: string
    }
    [ EventName.CONVERSATION_TAG_FILTER ]: {
      Location: string
    }
    [ EventName.SURVEY_SHARING_LINK_OPENED ]: {
      "Survey ID"?: string
      "Survey Name"?: string
    }
    [ EventName.SURVEY_SHARING_LINK_SHARED_ON_FACEBOOK ]: {
      "Survey ID"?: string
      "Survey Name"?: string
    }
    [ EventName.SURVEY_PREVIEW_SENT ]: {
      "Method": string
      "Survey ID"?: string
      "Survey Name"?: string
    }
    [ EventName.SURVEY_REMOVED ]: {
      "Survey ID": string
      "Survey Name": string
    }
    [ EventName.SURVEY_SETTINGS_EDITED ]: {
      "Survey ID": string
      "Survey Name": string
    }
    [ EventName.TEMPLATE_FILTER_CHANGED ]: undefined
    [ EventName.TIME_PERIOD_PICKER_CLICKED ]: {
      "Location": string
      "Product": string
    }
  }

  export type Properties = Record<string, string | number | boolean | null | Date | undefined>
}

export interface IdentifyOptions extends TrackOptions {
  account: AuthAccount | null
}

export interface TrackOptions {
  merchantSettings: MerchantSettings
  session: AuthSession
}

let shouldTrack = true
let isInitialized = false

export const mixpanelHelpers = {
  identify( options: IdentifyOptions ) {
    shouldTrack = mixpanelHelpers.shouldTrack( options )

    if( ! shouldTrack ) {
      return
    }

    if( mixpanel ) {
      const { account, merchantSettings, session } = options

      let local_response_status: "Not Enabled" | "Live" = "Not Enabled"
      if( merchantSettings.messages.v === 2 || merchantSettings.response?.status === MerchantSettings.ProductStatus.LIVE ) {
        local_response_status = "Live"
      }

      const properties: { [field: string]: string } = {
        "Account Email": account?.email || "",
        "Account ID": account?.id || "",
        "Agency Analytics Status": merchantSettings.flags.agencyAnalytics ? "Live" : "Not Enabled",
        "Churn": merchantSettings.flags.churn ? "Yes" : "No",
        "Demo": merchantSettings.flags.demo ? "Yes" : "No",
        "Employee ID": session?.employeeId,
        "Flag Billing Issue": merchantSettings.flagBillingIssue ? "Yes" : "No",
        "User Identifier": account?.email || account?.username || "",
        "Merchant ID": merchantSettings.merchantId,
        "Merchant Name": merchantSettings.name,
        "Partner": merchantSettings.flags.partner ? "Yes" : "No",
        "Testing Flag": merchantSettings.testing ? "Yes" : "No",
        [ `${ ProductNames.LOCAL_ADS } Status` ]: settingsHelper.getProductStatusLabel( merchantSettings.ads?.status ),
        [ `${ ProductNames.LOCAL_MESSAGES } Status` ]: settingsHelper.getProductStatusLabel( merchantSettings.messages?.status ),
        [ `${ ProductNames.LOCAL_REFERRALS } Status` ]: settingsHelper.getProductStatusLabel( merchantSettings.referrals?.status ),
        [ `${ ProductNames.LOCAL_RESPONSE } Status` ]: local_response_status,
        [ `${ ProductNames.LOCAL_REVIEWS } Status` ]: settingsHelper.getProductStatusLabel( merchantSettings.reviews?.status ),
        [ `${ ProductNames.LOCAL_SEO } Status` ]: settingsHelper.getProductStatusLabel( merchantSettings.seo?.status ),
        [ `${ ProductNames.LOCAL_SITE } Status` ]: settingsHelper.getProductStatusLabel( merchantSettings.site?.status ),
        // [ `${ ProductNames.LOCAL_VISITS } Status` ]: settingsHelper.getProductStatusLabel( merchantSettings.visits?.status ),
      }

      if( merchantSettings.created.at ) {
        properties[ "Created At" ] = merchantSettings.created.at
      }

      const accountName = account ? formattingHelpers.getDisplayName( account ) : null

      if( accountName ) {
        properties[ "Account Name" ] = accountName
      }

      mixpanel.identify( merchantSettings.merchantId )
      mixpanel.register( properties )
    }
  },
  async init( token: string ) {
    if( isInitialized ) {
      return
    }

    isInitialized = true
    mixpanel.init( token )
  },
  async reset() {
    if( ! mixpanel ) {
      return
    }

    shouldTrack = true
    mixpanel.reset()
  },
  shouldTrack( options: TrackOptions ) {
    const { merchantSettings, session } = options

    if( merchantSettings?.testing ) {
      return false
    }

    if( session?.isGataEmployee ) {
      return false
    }

    return true
  },
  trackEvent<T extends keyof AnalyticHelper.EventParams>( ...args: undefined extends AnalyticHelper.EventParams[T] ? [T] | [T, AnalyticHelper.EventParams[T]] : [T, AnalyticHelper.EventParams[T]] ) {
    if( ! shouldTrack ) {
      return
    }

    const [ name, properties ] = args

    if( mixpanel ) {
      try {
        mixpanel.track( name, properties )
      } catch( ex ) {
        errorHelper.logError( ex )
      }
    }
  },
  trackPageOpened( pageName: string, properties?: AnalyticHelper.Properties ) {
    mixpanelHelpers.trackEvent( AnalyticHelper.EventName.PAGE_OPENED, { ...( properties || {} ), Name: pageName } )
  },
}

import React, { useMemo } from "react"
import styled from "styled-components"

import { Flex, MetricDecreaseIcon, MetricIncreaseIcon, styleHelpers } from "@onelocal/frontend-web/common"

export enum PerformanceTileTheme {
  DECREASE = "decrease",
  DEFAULT = "default",
  INCREASE = "increase",
}

export interface PerformanceTileProps {
  description: string
  theme?: PerformanceTileTheme
  title: string
}

const StyledPerformanceTile = styled.div`
  align-content: center;
  background-color: #FAFAFA;
  border-radius: 8px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
`

const StyledPerformanceTileDescription = styled.span`
  color: #777777;
  font-size: 10px;
  margin-top: 8px;
`

const StyledPerformanceTileValue = styled.span`
  font-size: 32px;
  font-weight: ${ styleHelpers.fonts.weight.bold };
`

export const PerformanceTile: React.FC<PerformanceTileProps> = ( {
  description, title, theme = PerformanceTileTheme.DEFAULT,
} ) => {

  const { icon, textColor } = useMemo( () => {
    switch( theme ) {
      case PerformanceTileTheme.DECREASE:
        return {
          icon: <MetricDecreaseIcon width={ 20 } height={ 20 }/>,
          textColor: "#E31B0C",
        }
      case PerformanceTileTheme.INCREASE:
        return {
          icon: <MetricIncreaseIcon width={ 20 } height={ 20 }/>,
          textColor: "#3B873E",
        }
      case PerformanceTileTheme.DEFAULT:
        return {
          icon: null,
          textColor: styleHelpers.colors.dark,
        }
    }
  }, [ theme ] )

  return (
    <StyledPerformanceTile>
      <Flex alignItems="center" display="flex" flexDirection="row">
        <StyledPerformanceTileValue style={ { color: textColor } }>
          { title }
        </StyledPerformanceTileValue>
        { icon }
      </Flex>
      <StyledPerformanceTileDescription>
        { description }
      </StyledPerformanceTileDescription>
    </StyledPerformanceTile>
  )
}

import { createSelector } from "@reduxjs/toolkit"

import type { MerchantsState } from "./merchantsStore"

type MerchantsStateSelector<TRootState> = [
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ( state: TRootState, ...params: any[] ) => MerchantsState
]

export const merchantsSelector = <TRootState extends { merchants: MerchantsState }>( state: TRootState ) => state.merchants

export const createMerchantsSelector = <TRootState extends { merchants: MerchantsState }, TReturnValue>( combiner: ( merchantsState: MerchantsState ) => TReturnValue ) => {
  return createSelector<MerchantsStateSelector<TRootState>, TReturnValue>(
    merchantsSelector,
    combiner,
  )
}

export const merchantsSelectors = {
  merchants: {
    byId: ( merchantId: string ) => {
      return createMerchantsSelector(
        ( merchantsState ) => merchantsState.merchants.byId[ merchantId ] || null,
      )
    },
    byIds: ( merchantIds: string[] ) => {
      return createMerchantsSelector(
        ( merchantsState ) => merchantIds.map( ( merchantId ) => merchantsState.merchants.byId[ merchantId ] ).filter( ( merchant ) => merchant != null ),
      )
    },
  },
  merchantSettings: {
    byId: ( merchantId: string ) => {
      return createMerchantsSelector(
        ( merchantsState ) => merchantsState.merchantSettings.byId[ merchantId ] || null,
      )
    },
  },
}

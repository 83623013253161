import CheckIcon from "@mui/icons-material/Check"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useEffectOnce } from "react-use"
import styled from "styled-components"

import { eventEmitterHelpers } from "@onelocal/frontend/common"
import { AnalyticHelper, mixpanelHelpers, useDashboardContext } from "@onelocal/frontend-dashboard/common"
import { useMerchantSettings } from "@onelocal/frontend-dashboard/settings"
import type { Booking } from "@onelocal/frontend-dashboard/visits"
import { Assignee, BookingEventType, bookingService, CalendarEvent, getBookingCustomerLocalContactUrl, getBookingMultipleChoiceAnswerValue, getBookingServiceLabel, getBookingServiceTypeDuration, getStatusLabel, VisitsMerchantSettings } from "@onelocal/frontend-dashboard/visits"
import { ConversationLink } from "@onelocal/frontend-dashboard-web/connect"
import { ContactLink } from "@onelocal/frontend-dashboard-web/contacts"
import type { DialogAction, DialogAlert } from "@onelocal/frontend-web/common"
import { Dialog, DialogAlertType, Flex, styleHelpers, useAlert, useModalWithParams } from "@onelocal/frontend-web/common"
import { dateHelpers, formattingHelpers, ProductNames } from "@onelocal/shared/common"
import { CancelledIcon, CompletedIcon, ConfirmedIcon, EditIcon, PendingIcon } from "../components/Icons"

export interface BookingDetailsModalProps {
  calendarEventId: string
  isOpen: boolean

  onClose( reOpen?: boolean ): void
  onExited: () => void
}

const StyledModalCustomerFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  margin-top: 20px;
  width: 50%;

  &.address,
  &.boolean,
  &.full-width,
  &.multiple_choice,
  &.note,
  &.number {
    width: 100%;
  }
`

const StyledModalServiceContainer = styled.div`
  font-size: ${ styleHelpers.fonts.sizes.default };
  font-weight: ${ styleHelpers.fonts.weight.semiBold };
  margin-top: 16px;
`

const StyledModalServiceDateContainer = styled( Flex )`
  border-bottom: 1px solid #E0E0E0;
  margin-top: 20px;
  padding-bottom: 20px;
`

const StyledModalServiceDateTextContainer = styled.div`
`

const StyledModalStatusContainer = styled( Flex )`
  border-radius: 6px;
  margin-top: 20px;
  padding: 9px 15px;
  width: min-content;
`

const StyledModalStatusIconContainer = styled.div`
  height: 16px;
`

const StyledModalStatusText = styled.div`
  display: inline;
  font-size: ${ styleHelpers.fonts.sizes.small };
  font-weight: ${ styleHelpers.fonts.weight.semiBold };
  margin-left: 4px;
`

const StyledModalLabelText = styled.div`
  color: ${ styleHelpers.colors.dark };
  font-size: ${ styleHelpers.fonts.sizes.small };
  font-weight: ${ styleHelpers.fonts.weight.semiBold };
  margin-bottom: 10px;
`

interface BookingFieldProps {
  calendarEvent: CalendarEvent
  field: VisitsMerchantSettings.BookingField
  onClose( reOpen?: boolean ): void
}

const BookingField: React.FC<BookingFieldProps> = React.memo( ( { calendarEvent, field, onClose } ) => {
  const trackClick = useCallback( ( name: string ) => {
    mixpanelHelpers.trackEvent(
      AnalyticHelper.EventName.CTA_CLICKED,
      {
        "Name": name,
        "Location": AnalyticHelper.EventLocation.LOCALVISITS_BOOKING_DETAILS_MODAL,
        "Product": ProductNames.LOCAL_MESSAGES,
      },
    )
  }, [] )

  const contactLinkClickHandler = useCallback( () => {
    onClose()
    trackClick( "Name" )
  }, [] )

  const conversationLinkClickHandler = useCallback( () => {
    onClose()
    trackClick( "Phone Number" )
  }, [] )

  const renderTextInputField = useCallback( ( textField: VisitsMerchantSettings.BookingFieldText ) => {
    if( ! calendarEvent?.booking ) {
      return null
    }
    switch( textField.inputType ) {
      case VisitsMerchantSettings.BookingFieldText.InputType.NAME: {
        return <ContactLink link={ getBookingCustomerLocalContactUrl( calendarEvent.booking ) } onClick={ contactLinkClickHandler } text={ calendarEvent.booking.answers[ textField.id ] as string } />
      }
      case VisitsMerchantSettings.BookingFieldText.InputType.PHONE_NUMBER: {
        return <ConversationLink onClick={ conversationLinkClickHandler } phoneNumber={ calendarEvent.booking.answers[ textField.id ] as string } />
      }
      default: {
        return (
          <div>{ `${ calendarEvent.booking.answers[ textField.id ] }` }</div>
        )
      }
    }
  }, [ calendarEvent ] )

  const bookingField = useMemo( () => {
    switch( field.type ) {
      case VisitsMerchantSettings.BookingFieldType.BOOLEAN: {
        return (
          <StyledModalCustomerFieldContainer className={ field.type }>
            <StyledModalLabelText>{ field.label }</StyledModalLabelText>
            <div>{ formattingHelpers.renderBoolean( calendarEvent.booking!.answers[ field.id ] as Booking.AnswerBoolean ) }</div>
          </StyledModalCustomerFieldContainer>
        )
      }
      case VisitsMerchantSettings.BookingFieldType.MULTIPLE_CHOICE: {
        return (
          <StyledModalCustomerFieldContainer className={ field.type }>
            <StyledModalLabelText>{ field.label }</StyledModalLabelText>
            <div>
              {
                getBookingMultipleChoiceAnswerValue( field, calendarEvent.booking!.answers[ field.id ] as Booking.AnswerMultipleChoice )
              }
            </div>
          </StyledModalCustomerFieldContainer>
        )
      }
      case VisitsMerchantSettings.BookingFieldType.TEXT: {
        return (
          <StyledModalCustomerFieldContainer className={ field.inputType }>
            <StyledModalLabelText>{ field.label }</StyledModalLabelText>
            { renderTextInputField( field ) }
          </StyledModalCustomerFieldContainer>
        )
      }
      default:
        return null
    }
  }, [ calendarEvent, field ] )

  return (
    <>
      { bookingField }
    </>
  )
} )

export const BookingDetailsModal: React.FC<BookingDetailsModalProps> = ( {
  calendarEventId,
  isOpen,
  onClose,
  onExited,
} ) => {
  const [ fields, setFields ] = useState<VisitsMerchantSettings.BookingField[]>( [] )
  const [ calendarEvent, setCalendarEvent ] = useState<CalendarEvent | undefined>( undefined )
  const { dashboardContext } = useDashboardContext()
  const { merchantSettings } = useMerchantSettings()
  const [ alert, setAlert ] = useState<DialogAlert | undefined>( undefined )
  const { showSuccess } = useAlert()

  useEffect( () => {
    const run = async () => {
      if( ! dashboardContext?.merchantId || ! merchantSettings ) {
        return
      }

      const currentCalendarEvent = await bookingService.getEvent( dashboardContext.merchantId, calendarEventId )
      const bookingFields: VisitsMerchantSettings.BookingField[] = []

      setCalendarEvent( currentCalendarEvent )

      if( currentCalendarEvent.type === CalendarEvent.Type.BOOKING && currentCalendarEvent.booking ) {
        bookingFields.push( ...currentCalendarEvent.booking.fields )

        if( merchantSettings.visits?.bookings?.fields?.length ) {
          merchantSettings.visits.bookings.fields.forEach( ( bookingField ) => {
            if( bookingFields.findIndex( ( field ) => field.id === bookingField.id ) === -1 ) {
              bookingFields.push( bookingField )
            }
          } )
        }
      } else if( merchantSettings.visits?.bookings?.fields.length ) {
        bookingFields.push( ...merchantSettings.visits.bookings.fields )
      }
      setFields( bookingFields )
    }

    run()
  }, [ dashboardContext?.merchantId, merchantSettings, setCalendarEvent, setFields ] )

  useEffectOnce( () => {
    mixpanelHelpers.trackPageOpened(
      AnalyticHelper.EventLocation.LOCALVISITS_BOOKING_DETAILS_MODAL,
      { "Product": ProductNames.LOCAL_MESSAGES },
    )
  } )

  const assigneeText = useMemo( () => {
    if( ! calendarEvent ) {
      return null
    }

    if( calendarEvent.assignee ) {
      if( calendarEvent.assignee.type === Assignee.Type.ALL ) {
        return "All Staff"
      } else if( calendarEvent.assignees?.length ) {
        return formattingHelpers.getDisplayName( calendarEvent.assignees[ 0 ] )!
      }
    }

    return "Not Assigned"
  }, [ calendarEvent ] )

  const scheduleText = useMemo( () => {
    if( ! calendarEvent || ! dashboardContext ) {
      return null
    }

    const timezone = dashboardContext.timezone || "America/Toronto"

    switch( calendarEvent.schedule.type ) {
      case CalendarEvent.ScheduleType.ANY: {
        if( calendarEvent.schedule.date ) {
          return (
            <div>
              <div>{ dateHelpers.formatTZ( calendarEvent.schedule.date, dateHelpers.LONG_DATE_FORMAT, timezone ) }</div>
              <div>No Preference</div>
            </div>
          )
        }
        return (
          <div>Any</div>
        )
      }
      case CalendarEvent.ScheduleType.ASAP: {
        if( calendarEvent.status.type !== CalendarEvent.StatusType.COMPLETED ) {
          return (
            <div>ASAP</div>
          )
        }
        return (
          <div>
            ASAP - Completed at:
            <div>{ dateHelpers.formatTZ( calendarEvent.status.at, dateHelpers.LONG_DATE_FORMAT, timezone ) }</div>
            <div>{ dateHelpers.formatTZ( calendarEvent.status.at, dateHelpers.TIME_12HR_FORMAT, timezone ) }</div>
          </div>
        )
      }
      case CalendarEvent.ScheduleType.TIME: {
        return (
          <div>
            <div>{ dateHelpers.formatTZ( calendarEvent.schedule.times[ 0 ].date, dateHelpers.LONG_DATE_FORMAT, timezone ) }</div>
            <div>{ dateHelpers.formatTimeAs12HrFormatTimeString( calendarEvent.schedule.times[ 0 ].start ) } - { dateHelpers.formatTimeAs12HrFormatTimeString( calendarEvent.schedule.times[ 0 ].end ) }</div>
            {
              calendarEvent.booking && (
                <div>{ getBookingServiceTypeDuration( calendarEvent.booking ) }</div>
              )
            }
          </div>
        )
      }
      default: {
        return null
      }
    }
  }, [ calendarEvent, dashboardContext ] )

  const statusIcon = useMemo( () => {
    if( ! calendarEvent ) {
      return null
    }

    switch( calendarEvent.status.type ) {
      case CalendarEvent.StatusType.CANCELLED: {
        return <CancelledIcon />
      }
      case CalendarEvent.StatusType.COMPLETED: {
        return <CompletedIcon />
      }
      case CalendarEvent.StatusType.CONFIRMED: {
        return <ConfirmedIcon />
      }
      case CalendarEvent.StatusType.PENDING: {
        return <PendingIcon />
      }
      default: {
        return null
      }
    }
  }, [ calendarEvent ] )

  const serviceLabel = useMemo( () => {
    if( ! calendarEvent?.booking ) {
      return null
    }
    return getBookingServiceLabel( calendarEvent.booking )
  }, [ calendarEvent ] )

  const statusLabel = useMemo( () => {
    if( ! calendarEvent?.booking ) {
      return null
    }
    return getStatusLabel( calendarEvent )
  }, [ calendarEvent ] )

  const statusStyles = useMemo( () => {
    if( ! calendarEvent ) {
      return null
    }

    switch( calendarEvent.status.type ) {
      case CalendarEvent.StatusType.CANCELLED: {
        return {
          backgroundColor: "rgba( 178, 30, 45, 0.2 )",
          color: "#EC3939",
        }
      }
      case CalendarEvent.StatusType.COMPLETED: {
        return {
          backgroundColor: "rgba( 100, 198, 190, 0.2 )",
          color: "rgb( 100, 198, 190 )",
        }
      }
      case CalendarEvent.StatusType.CONFIRMED: {
        return {
          backgroundColor: "rgba( 59, 153, 252, 0.25 )",
          color: "rgba( 59, 153, 252 )",
        }
      }
      case CalendarEvent.StatusType.PENDING: {
        return {
          backgroundColor: "rgba( 255, 147, 47, 0.25 )",
          color: "rgba( 255, 147, 47 )",
        }
      }
      default: {
        return null
      }
    }
  }, [ calendarEvent ] )

  const modalActions: DialogAction[] = useMemo( () => {
    const actions: DialogAction[] = []
    const cancelAction: DialogAction = {
      onClick: () => {
        mixpanelHelpers.trackEvent(
          AnalyticHelper.EventName.CTA_CLICKED,
          {
            "Name": "Cancel Booking",
            "Location": AnalyticHelper.EventLocation.LOCALVISITS_BOOKING_DETAILS_MODAL,
            "Product": ProductNames.LOCAL_MESSAGES,
          },
        )
        onClose()
        eventEmitterHelpers.emit( BookingEventType.OPEN_CANCEL_MODAL, calendarEvent )
      },
      style: {
        backgroundColor: styleHelpers.colors.white,
        color: styleHelpers.colors.darkGray,
      },
      title: "Cancel Booking",
      type: "destructive",
    }

    if( calendarEvent ) {
      switch( calendarEvent.status.type ) {
        case CalendarEvent.StatusType.CANCELLED:
        case CalendarEvent.StatusType.COMPLETED: {
          actions.push( { title: "Dismiss", type: "dismiss" } )
          break
        }
        case CalendarEvent.StatusType.CONFIRMED: {
          actions.push(
            cancelAction,
            {
              icon: ( <EditIcon style={ { height: 18, width: 18 } }/> ),
              onClick: () => {
                mixpanelHelpers.trackEvent(
                  AnalyticHelper.EventName.CTA_CLICKED,
                  {
                    "Name": "Edit Booking",
                    "Location": AnalyticHelper.EventLocation.LOCALVISITS_BOOKING_DETAILS_MODAL,
                    "Product": ProductNames.LOCAL_MESSAGES,
                  },
                )
                onClose()
                eventEmitterHelpers.emit( BookingEventType.OPEN_EDIT_MODAL, calendarEvent )
              },
              style: {
                backgroundColor: styleHelpers.colors.darkBlue,
              },
              title: "Edit",
              type: "secondary",
            },
            {
              icon: ( <CheckIcon style={ { height: 18, width: 18 } }/> ),
              onClick: () => {
                mixpanelHelpers.trackEvent(
                  AnalyticHelper.EventName.CTA_CLICKED,
                  {
                    "Name": "Complete Booking",
                    "Location": AnalyticHelper.EventLocation.LOCALVISITS_BOOKING_DETAILS_MODAL,
                    "Product": ProductNames.LOCAL_MESSAGES,
                  },
                )
                onClose()
                eventEmitterHelpers.emit( BookingEventType.OPEN_COMPLETE_MODAL, calendarEvent )
              },
              title: "Complete",
              type: "primary",
            },
          )
          break
        }
        case CalendarEvent.StatusType.PENDING: {
          actions.push(
            cancelAction,
            {
              icon: ( <EditIcon style={ { height: 18, width: 18 } }/> ),
              onClick: () => {
                mixpanelHelpers.trackEvent(
                  AnalyticHelper.EventName.CTA_CLICKED,
                  {
                    "Name": "Edit Booking",
                    "Location": AnalyticHelper.EventLocation.LOCALVISITS_BOOKING_DETAILS_MODAL,
                    "Product": ProductNames.LOCAL_MESSAGES,
                  },
                )
                onClose()
                eventEmitterHelpers.emit( BookingEventType.OPEN_EDIT_MODAL, calendarEvent )
              },
              style: {
                backgroundColor: styleHelpers.colors.darkBlue,
              },
              title: "Edit",
              type: "secondary",
            },
            {
              icon: ( <CheckIcon style={ { height: 18, width: 18 } }/> ),
              onClick: async () => {
                mixpanelHelpers.trackEvent(
                  AnalyticHelper.EventName.CTA_CLICKED,
                  {
                    "Name": "Confirm Booking",
                    "Location": AnalyticHelper.EventLocation.LOCALVISITS_BOOKING_DETAILS_MODAL,
                    "Product": ProductNames.LOCAL_MESSAGES,
                  },
                )
                try {
                  if( calendarEvent.schedule.type === CalendarEvent.ScheduleType.ANY || calendarEvent.schedule.type === CalendarEvent.ScheduleType.ASAP ) {
                    onClose()
                    eventEmitterHelpers.emit( BookingEventType.OPEN_CHOOSE_TIME_SLOT_MODAL, calendarEvent )
                    return
                  }
                  await bookingService.confirmEvent( dashboardContext.merchantId, calendarEventId )
                  onClose()
                  showSuccess( "Booking Confirmed" )
                } catch( err ) {
                  setAlert( {
                    message: err,
                    type: DialogAlertType.ERROR,
                  } )
                }
              },
              title: "Confirm",
              type: "primary",
            },
          )
          break
        }
      }
    }
    return actions
  }, [ calendarEvent ] )

  if( ! calendarEvent?.booking ) {
    return null
  }

  return (
    <Dialog
      actions={ modalActions }
      alert={ alert }
      contentContainerStyle={ {
        paddingLeft: "24px",
        paddingRight: "24px",
        width: "600px",
      } }
      isOpen={ isOpen }
      onClose={ onClose }
      onExited={ onExited }
      showDividers={ false }
      title="Booking Details"
    >
      <StyledModalServiceContainer>{ serviceLabel }</StyledModalServiceContainer>
      <StyledModalStatusContainer alignItems="center" display="flex" style={ { backgroundColor: statusStyles!.backgroundColor } }>
        <StyledModalStatusIconContainer>{ statusIcon }</StyledModalStatusIconContainer>
        <StyledModalStatusText style={ { color: statusStyles!.color } }>{ statusLabel }</StyledModalStatusText>
      </StyledModalStatusContainer>
      <StyledModalServiceDateContainer display="flex" flexDirection="column">
        <StyledModalLabelText>Service Date</StyledModalLabelText>
        <StyledModalServiceDateTextContainer>{ scheduleText }</StyledModalServiceDateTextContainer>
        {
          merchantSettings?.visits?.bookings?.assignee?.dashboardEnabled && (
            <div style={ { marginTop: "20px" } }>
              <StyledModalLabelText>Assigned To</StyledModalLabelText>
              <div>{ assigneeText }</div>
            </div>
          )
        }
      </StyledModalServiceDateContainer>
      <Flex display="flex" flexWrap="wrap" >
        {
          fields.map( ( field ) => {
            if( calendarEvent.booking?.answers[ field.id ] == null ) {
              return null
            }

            return <BookingField key={ field.id } calendarEvent={ calendarEvent } field={ field } onClose={ onClose } />
          } )
        }
        {
          calendarEvent.description && (
            <StyledModalCustomerFieldContainer key="note" className="note">
              <StyledModalLabelText>Notes</StyledModalLabelText>
              <div>{ calendarEvent.description }</div>
            </StyledModalCustomerFieldContainer>
          )
        }
      </Flex>
    </Dialog>
  )
}

export const useBookingDetailsModal = () => {
  const { showModal, hideModal } = useModalWithParams<string>( ( { open, onExited, params } ) => {
    if( ! params ) {
      return null
    }

    return (
      <BookingDetailsModal
        calendarEventId={ params }
        isOpen={ open }
        onClose={ hideModal }
        onExited={ onExited }
      />
    )
  }, [] )

  return {
    hideBookingDetailsModal: hideModal,
    showBookingDetailsModal: showModal,
  }
}


// eslint-disable-next-line no-restricted-imports
import { FormHelperText as MuiFormHelperText, Grid, LinearProgress } from "@mui/material"
import { find, intersection, map } from "lodash"
import { useCallback } from "react"
import { Controller, useFormContext } from "react-hook-form"
import styled from "styled-components"

import type { SelectOption } from "@onelocal/frontend-web/common"
import { Autocomplete, Button, CheckBox, DatePicker, GridItem, Select, StyledFormSectionTitle, styleHelpers, TextField, useStepProgressState } from "@onelocal/frontend-web/common"
import { dateHelpers } from "@onelocal/shared/common"
import { useOnboardingFormContext } from "../../hooks/useOnboardingFormContext"
import { OnboardingForm } from "../../types"
import { FIELD_LABELS } from "./constants"

export interface OnboardingFormButtonsProps {
  disabled: boolean
}

export const OnboardingFormButtons: React.FC<OnboardingFormButtonsProps> = ( { disabled } ) => {
  const { isFirstStep, isLastStep, previousStep } = useStepProgressState<OnboardingForm.Step>()

  const { updateForm } = useOnboardingFormContext()

  const onPreviousHandler = useCallback( async () => {
    await updateForm( { step: previousStep?.id } )
  }, [ previousStep?.id, updateForm ] )

  return (
    <div style={ { display: "flex", flexDirection: "row", marginTop: "20px" } }>
      {
        ! isFirstStep && (
          <Button onClick={ onPreviousHandler } disabled={ disabled }>Previous</Button>
        )
      }
      <div style={ { flex: 1 } }/>
      <Button disabled={ disabled } type="submit">{ isLastStep ? "Complete" : "Next" }</Button>
    </div>
  )
}

export interface OnboardingFormDisclaimerLabelProps {
}

export const OnboardingFormDisclaimerLabel: React.FC<OnboardingFormDisclaimerLabelProps> = () => (
  <p>Note: Any changes to Onboarding field data <b><u>will not be reflected</u></b> in the merchant&apos;s associated SalesForce account.</p>
)

export interface OnboardingFormFieldAutocompleteProps<TFormFieldKeys extends string> {
  fieldName: TFormFieldKeys
  helpText?: string | React.ReactNode
  isRequired?: boolean
  label: string
  onChange( value: string ): void
  options: Array<SelectOption<string>>
}

export const OnboardingFormFieldAutocomplete = function <TFormFieldKeys extends string>( {
  fieldName,
  helpText,
  isRequired,
  label,
  onChange,
  options,
}: OnboardingFormFieldAutocompleteProps<TFormFieldKeys> ) {
  const { control } = useFormContext()
  const { form } = useOnboardingFormContext()

  return (
    <Controller
      control={ control }
      name={ fieldName }
      render={ ( { field: { name, value } } ) => {
        const error = form?.validation?.fields[ fieldName as OnboardingForm.Field ]?.error || ""

        return (
          <Autocomplete
            disabled={ form?.preventEditing }
            error={ error || undefined }
            helpText={ error ?? helpText }
            fullWidth
            onChange={ onChange }
            label={ label }
            name={ name }
            options={ options }
            required={ isRequired }
            value={ value || "" }
          />
        )
      } }
      rules={ {
        validate: ( value: string | number | Array<string | number> ) => {
          if( ! value ) {
            if( isRequired ) {
              return `${ label } is required`
            } else {
              return undefined
            }
          }

          if( ! find( options, { value } ) ) {
            return "The selected option is incorrect"
          }

          return undefined
        },
      } }
    />
  )
}

export interface OnboardingFormFieldCheckBoxProps<TFormFieldKeys extends string> {
  fieldName: TFormFieldKeys
  label: string
  onChange( value: boolean ): void
  helpText?: string
}

export const OnboardingFormFieldCheckBox = function <TFormFieldKeys extends string>( { fieldName, label, onChange, helpText }: OnboardingFormFieldCheckBoxProps<TFormFieldKeys> ) {
  const { control } = useFormContext()
  const { form } = useOnboardingFormContext()

  return (
    <>
      <Controller
        control={ control }
        name={ fieldName }
        render={ ( { field: { name, value } } ) => (
          <CheckBox
            checkboxColor="primary"
            checked={ value || false }
            disabled={ form?.preventEditing }
            label={ label }
            onChange={ onChange }
            name={ name }
            noLabelWrap={ true }
          />
        ) }
      />
      {
        helpText != null && (
          <MuiFormHelperText
            sx={ { fontSize: styleHelpers.fonts.sizes.small } }
          >
            { helpText }
          </MuiFormHelperText>
        )
      }
    </>
  )
}

export interface OnboardingFormFieldDateProps {
  fieldName: string
  helperText?: string
  isRequired: boolean
  label: string
  onChange( value: string | null ): void
  validate?: ( value: string ) => string | undefined
}

export const OnboardingFormFieldDate: React.FC<OnboardingFormFieldDateProps> = ( {
  fieldName,
  helperText,
  isRequired,
  label,
  onChange,
  validate,
} ) => {
  const { control } = useFormContext()
  const { form } = useOnboardingFormContext()
  const placeholder = form?.generated.fields[ fieldName as keyof OnboardingForm["generated"]["fields"] ] as string

  const onChangeHandler = useCallback( ( value: Date | null ) => {
    if( ! value ) {
      onChange( value )
    } else {
      onChange( dateHelpers.formatToDateValue( value ) )
    }
  }, [ onChange ] )

  const error = form?.validation?.fields[ fieldName as OnboardingForm.Field ]?.error || ""

  return (
    <Controller
      control={ control }
      name={ fieldName }
      render={ ( { field: { value } } ) => (
        <DatePicker
          disabled={ form?.preventEditing }
          errorMessage={ error }
          fullWidth={ true }
          isRequired={ isRequired }
          label={ label }
          onChange={ onChangeHandler }
          value={ value }
        />
      ) }
      rules={ {
        validate: ( value: string ) => {
          value = ( `${ value }` || "" ).trim() || placeholder

          if( isRequired && ! value ) {
            return `${ label } is required`
          }

          const validateResult = value && validate ? validate( value ) : undefined

          return validateResult
        },
      } }
    />
  )
}

const StyledSelect = styled( Select )`
  width: 100%;
`

export interface OnboardingFormFieldSelectProps<TFormFieldKeys extends string> {
  disabled?: boolean
  fieldName: TFormFieldKeys
  helpText?: string | React.ReactNode
  isRequired: boolean
  label: string
  multiple?: boolean
  onChange( value: string | number | string[] | number[] ): void
  options: Array<SelectOption<string|number>>
}

export const OnboardingFormFieldSelect = function <TFormFieldKeys extends string>( {
  disabled,
  fieldName,
  helpText,
  isRequired,
  label,
  multiple,
  onChange,
  options,
}: OnboardingFormFieldSelectProps<TFormFieldKeys> ) {
  const { form } = useOnboardingFormContext()
  const { control } = useFormContext()
  const placeholder = form?.generated.fields[ fieldName as keyof OnboardingForm["generated"]["fields"] ] as string

  const onChangeHandler = useCallback( ( value: string | number | string[] | number[] ) => {
    let validValue: string | number | string[] | number[] = value || ( multiple ? [] : "" )

    if( multiple ) {
      validValue = intersection( validValue as string[] | number[], map( options, "value" ) ) as string[] | number[]
    }

    onChange( validValue )
  }, [ multiple, onChange, options ] )

  return (
    <Controller
      control={ control }
      name={ fieldName }
      render={ ( { field: { value } } ) => {
        const error = form?.validation?.fields[ fieldName as OnboardingForm.Field ]?.error

        return (
          <StyledSelect
            disabled={ form?.preventEditing ?? disabled }
            error={ error != null }
            keyByIndex={ true }
            helpText={ error || helpText }
            onChange={ onChangeHandler }
            label={ label }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            multiple={ ( multiple || false ) as any }
            options={ options }
            required={ isRequired }
            placeholder={ placeholder }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            value={ ( value || ( multiple ? [] : "" ) ) as any }
          />
        )
      } }
      rules={ {
        validate: ( value: string | number | Array<string | number> ) => {
          value = value || placeholder
          if( ! value ) {
            if( isRequired ) {
              return `${ label } is required`
            } else {
              return undefined
            }
          }

          if( multiple ) {
            if( ! Array.isArray( value ) ) {
              return "The selected option is incorrect"
            }

            const validValues = intersection( value, map( options, "value" ) )

            if( validValues.length !== value.length ) {
              return "The selected option is incorrect"
            }
          } else {
            if( ! find( options, { value } ) ) {
              return "The selected option is incorrect"
            }
          }

          return undefined
        },
      } }
    />
  )
}

export interface OnboardingFormFieldTextProps {
  disabled?: boolean
  fieldName: string
  helperText?: string
  isRequired: boolean
  label: string
  max?: number
  min?: number
  multiline?: boolean
  onChange( value: string ): void
  step?: number
  type?: string
  validate?: ( value: string ) => string | undefined
}

export const OnboardingFormFieldText: React.FC<OnboardingFormFieldTextProps> = ( {
  disabled,
  fieldName,
  helperText,
  isRequired,
  label,
  max,
  min,
  multiline,
  onChange,
  step,
  type,
  validate,
} ) => {
  const { control } = useFormContext()
  const { form } = useOnboardingFormContext()
  const placeholder = form?.generated.fields[ fieldName as keyof OnboardingForm["generated"]["fields"] ] as string
  const fieldError = form?.validation?.fields[ fieldName as OnboardingForm.Field ]

  return (
    <Controller
      control={ control }
      name={ fieldName }
      render={ ( { field: { value } } ) => (
        <TextField
          disabled={ form?.preventEditing ?? disabled }
          errorMessage={ fieldError?.error || undefined }
          fullWidth
          helperText={ helperText }
          label={ label }
          max={ max }
          min={ min }
          multiline={ multiline }
          onChange={ onChange }
          placeholder={ placeholder }
          required={ isRequired ? placeholder == null : false }
          step={ step }
          type={ type }
          value={ value || "" }
        />
      ) }
      rules={ {
        validate: ( value: string ) => {
          console.log( "validate" )
          value = ( `${ value || "" }` ).trim() || placeholder

          if( isRequired && ! value ) {
            return `${ label } is required`
          }

          const validateResult = value && validate ? validate( value ) : undefined

          return validateResult
        },
      } }
    />
  )
}

export interface OnboardingFormSavingProps {
  isSaving: boolean
}

export const OnboardingFormSaving: React.FC<OnboardingFormSavingProps> = ( { isSaving } ) => {
  return (
    <div style={ {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      height: "26px",
      justifyContent: "stretch",
      position: "sticky",
      top: "-20px",
      zIndex: 10,
    } }>
      {
        isSaving === true && (
          <>
            <div style={ { flex: 1 } }>
              <LinearProgress />
            </div>
            <div style={ {
              backgroundColor: "white",
              border: "1px solid rgb(249, 194, 186)",
              borderRadius: "24px",
              left: "50%",
              marginRight: "-50%",
              padding: "4px 15px",
              position: "absolute",
              top: "50%",
              transform: "translate(-50%, -50%)",
            } }>
              Saving...
            </div>
          </>
        )
      }
    </div>
  )
}

const NotesByStep: Record<OnboardingForm.Step, OnboardingForm.Field> = {
  [ OnboardingForm.Step.ABOUT_US ]: OnboardingForm.Field.NOTES_ABOUT_US,
  [ OnboardingForm.Step.ADS ]: OnboardingForm.Field.NOTES_ADS,
  [ OnboardingForm.Step.COMPANY_DETAIL ]: OnboardingForm.Field.NOTES_COMPANY_DETAILS,
  [ OnboardingForm.Step.CREATIVE_DIRECTION ]: OnboardingForm.Field.NOTES_CREATIVE_DIRECTION,
  [ OnboardingForm.Step.PAGES ]: OnboardingForm.Field.NOTES_ADDITIONAL_PAGES,
  [ OnboardingForm.Step.PRODUCT ]: OnboardingForm.Field.NOTES_PRODUCT,
  [ OnboardingForm.Step.SERVICES ]: OnboardingForm.Field.NOTES_SERVICES,
  [ OnboardingForm.Step.SITE ]: OnboardingForm.Field.NOTES_SITE,
  [ OnboardingForm.Step.SOCIAL_CONTENT ]: OnboardingForm.Field.NOTES_SOCIAL_CONTENT,
}

const StyledNoteField = styled.div`
  margin-top: 10px;
`

export interface OnboardingFormNotesProps {
  step: OnboardingForm.Step
  onChange( value: string ): void
}

export const OnboardingFormNotes: React.FC<OnboardingFormNotesProps> = ( { step, onChange } ) => {
  const fieldName = NotesByStep[ step ]

  return (
    <>
      <StyledFormSectionTitle>Additional Information</StyledFormSectionTitle>
      <StyledNoteField>
        <Grid>
          <GridItem xs={ 12 }>
            <OnboardingFormFieldText
              fieldName={ fieldName }
              isRequired={ false }
              label={ FIELD_LABELS[ fieldName ] }
              onChange={ onChange }
              multiline={ true }
            />
          </GridItem>
        </Grid>
      </StyledNoteField>
    </>
  )
}

import type { Name } from "@onelocal/shared/common"

export interface AuthInternalAccount {
  id: string
  deletedAt?: string
  disabledAt?: string
  email: string
  lastActiveAt: string
  name: Name
  permissions: AuthInternalAccount.Permission[]
  query_index: {
    name: string
  }
  role: AuthInternalAccount.Role
  salesforce_id?: string | null
  sessions: AuthInternalAccount.Session[]
  username: string
  text_index: string[]
}

export namespace AuthInternalAccount {
  export enum Permission {
    AGENCY_ANALYTICS = "agency_analytics",
    API_KEYS = "api_keys",
    CREDENTIALS = "credentials",
    DELETE_TESTIMONIAL = "delete_testimonial",
    HIPAA = "hipaa",
    MERCHANT_DELETE = "merchant_delete",
    QA = "qa",
    USER_MANAGEMENT = "user_management",
  }

  export enum Role {
    ADMIN = "admin",
    CUSTOMER_SUCCESS = "customer_success",
    DELIVERY = "delivery",
    SALES = "sales",
    TECH = "tech",
  }

  export interface Session {
    created: {
      at: string
    }
    idHash: string
    type: {
      label: string
      value: string
    }
    userType: "gata_employee" | "gata_user"
  }
}

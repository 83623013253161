import type { SnakeCasedPropertiesDeep } from "type-fest"

import { utilHelpers } from "@onelocal/shared/common"
import type { Merchant, MerchantSettings } from "."

export type ApiMerchant = SnakeCasedPropertiesDeep<Merchant>

export function parseMerchantFromApi( apiMerchant: ApiMerchant ) {
  const merchant: Merchant = {
    agency: ( apiMerchant.agency
      ? {
        groupIds: apiMerchant.agency.group_ids,
        id: apiMerchant.agency.id,
      }
      : undefined
    ),
    id: apiMerchant.id,
    locationNickname: apiMerchant.location_nickname,
    members: apiMerchant.members,
    name: apiMerchant.name,
    parentId: apiMerchant.parent_id,
    referencePrefix: apiMerchant.reference_prefix,
    rootId: apiMerchant.root_id,
    slug: apiMerchant.slug,
    testing: apiMerchant.testing,
    timezone: apiMerchant.timezone,
  }

  return merchant
}

export type ApiMerchantSettings = Omit<SnakeCasedPropertiesDeep<MerchantSettings>, "reviews"> & {
  review_edge: SnakeCasedPropertiesDeep<MerchantSettings[ "reviews" ]>
}

export function parseMerchantSettingsFromApi( apiMerchantSettings: ApiMerchantSettings ) {
  const merchantSettings: MerchantSettings = {
    compliance: {
      hipaaEnabled: apiMerchantSettings.compliance.hipaa_enabled,
    },
    flags: {
      agencyAnalytics: apiMerchantSettings.flags?.agency_analytics || false,
      churn: apiMerchantSettings.flags?.churn || false,
      demo: apiMerchantSettings.flags?.demo || false,
    },
    id: apiMerchantSettings.id,
    messenger: {
      status: apiMerchantSettings.messenger.status,
    },
    profile: {
      name: apiMerchantSettings.profile.name,
    },
    reviews: {
      status: apiMerchantSettings.review_edge.status,
    },
    testing: apiMerchantSettings.testing,
  }

  if( apiMerchantSettings.agency_analytics ) {
    merchantSettings.agencyAnalytics = utilHelpers.toCamelCase( apiMerchantSettings.agency_analytics )
  }

  return merchantSettings
}


import { apiHelper } from "@onelocal/frontend/common"
import { ContentSocialPost } from "../types"
import type { ApiContentScheduledItem } from "../types/api"
import { parseContentScheduledItemFromApi } from "../types/api"

export namespace ContentService {
}

export const contentService = {
  platformNames: {
    [ ContentSocialPost.PlatformType.FACEBOOK ]: "Facebook",
    [ ContentSocialPost.PlatformType.GOOGLE ]: "Google",
    [ ContentSocialPost.PlatformType.INSTAGRAM ]: "Instagram",
  } as Record<ContentSocialPost.PlatformType, string>,
  async queryScheduledItems( merchantId: string, dateMonth?: string ) {
    return apiHelper.request( {
      method: "GET",
      url: `/merchants/${ merchantId }/content/scheduled-items`,
      params: {
        date_month: dateMonth,
      },
      parsingData: ( apiResult: ApiContentScheduledItem[] ) => apiResult.map( parseContentScheduledItemFromApi ),
    } )
  },
}


import type { Name } from "@onelocal/shared/common"
import type { Session } from "."

export interface ApiSession {
  email: string
  name: Name
  permissions: string[]
  username: string
}

export function parseSessionFromApi( apiSession: ApiSession ): Session {
  const session: Session = {
    email: apiSession.email,
    name: apiSession.name,
    permissions: apiSession.permissions as Session.Permission[],
    username: apiSession.username,
  }

  return session
}

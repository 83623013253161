import React, { useCallback } from "react"
import { useEffectOnce } from "react-use"
import styled from "styled-components"

import { useAsyncDispatch } from "@onelocal/frontend/common"
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useMerchantSettings } from "@onelocal/frontend-admin-web/merchants"
import { OnboardingForm } from "@onelocal/frontend-admin-web/onboarding"
import { AccordionGroup, Button, Divider, Flex, Link, ReadOnlyField, StyledTable, StyledTableRow, styleHelpers, useAlert, useConfirmationModal } from "@onelocal/frontend-web/common"
import { dateHelpers } from "@onelocal/shared/common"
import { useSites } from "../hooks/useSites"
import { useGenerateMerchantSiteContentModal } from "../modals/GenerateMerchantSiteContentModal"
import { useGenerateMerchantSiteModal } from "../modals/GenerateMerchantSiteModal"
import { useGenerateMerchantSitePageModal } from "../modals/GenerateMerchantSitePageModal"
import { useLinkMerchantSiteModal } from "../modals/LinkMerchantSiteModal"
import { siteService } from "../services/siteService"
import { siteActions } from "../store/siteStore"
import { Site } from "../types"

const siteLabels = {
  [ Site.Type.LANDING_PAGE ]: "Landing Page",
  [ Site.Type.WEBSITE ]: "Website",
}

const StyledContainer = styled.div`
  background-color: white;
`

const StyledSiteItem = styled.div`
  margin: 10px 0;
`

const StyledInfoBanner = styled.div`
  align-items: center;
  background-color: ${ styleHelpers.colors.warningLight };
  color: ${ styleHelpers.colors.darkGray };
  display: flex;
  gap: 8px;
  padding: 8px;
`

export interface MerchantSiteDetailsProps {
  merchantId: string
  type: Site.Type
}

export const MerchantSiteDetails: React.FC<MerchantSiteDetailsProps> = ( { merchantId, type } ) => {
  const { formStatus, reloadSites, sites } = useSites( merchantId, type )
  const dispatch = useAsyncDispatch()
  const { showGenerateMerchantSiteContentModal } = useGenerateMerchantSiteContentModal()
  const { showGenerateMerchantSiteModal } = useGenerateMerchantSiteModal()
  const { showGenerateMerchantSitePageModal } = useGenerateMerchantSitePageModal()
  const { showLinkMerchantSiteModal } = useLinkMerchantSiteModal()
  const { merchantSettings } = useMerchantSettings( merchantId )
  const { showConfirmationModal } = useConfirmationModal()
  const { showError, showSuccess } = useAlert()

  const generateWebsite = useCallback( () => {
    if( ! merchantSettings ) {
      return
    }
    showGenerateMerchantSiteModal( {
      merchantId,
      merchantSettings,
      onGenerated: reloadSites,
      type,
    } )
  }, [ merchantId, merchantSettings, reloadSites, showGenerateMerchantSiteModal, type ] )

  const generateWebsiteContent = useCallback( ( siteId: string ) => {
    if( ! merchantSettings ) {
      return
    }
    showGenerateMerchantSiteContentModal( {
      merchantId,
      onGenerated: reloadSites,
      siteId,
    } )
  }, [ merchantId, merchantSettings, reloadSites, showGenerateMerchantSiteContentModal ] )

  const generateWebsitePageContent = useCallback( ( siteId: string ) => {
    if( ! merchantSettings ) {
      return
    }

    showGenerateMerchantSitePageModal( {
      merchantId,
      onGenerated: reloadSites,
      siteId,
    } )
  }, [ merchantId, merchantSettings, reloadSites, showGenerateMerchantSitePageModal ] )

  const linkWebsite = useCallback( async () => {
    showLinkMerchantSiteModal( {
      merchantId: merchantId,
      onLinked: reloadSites,
      type: type,
    } )
  }, [ merchantId, reloadSites, showLinkMerchantSiteModal, type ] )

  const unlinkWebsite = useCallback( async ( siteId: string ) => {
    showConfirmationModal( {
      confirmText: `Unlink ${ siteLabels[ type ] }`,
      message: `Are you sure you want to unlink the ${ siteLabels[ type ] } from Duda (The ${ siteLabels[ type ] } will not be removed in Duda) ?`,
      onConfirm: async () => {
        try {
          await dispatch( siteActions.site.unlink( merchantId, siteId ) )
          showSuccess( `${ siteLabels[ type ] } has been successfully unlinked` )
        } catch( err ) {
          showError( err )
        }
      },
      title: `Unlink ${ siteLabels[ type ] } from Duda`,
    } )
  }, [ dispatch, merchantId, showConfirmationModal, showError, showSuccess, type ] )

  const updatePrimarySite = useCallback( async ( siteId: string ) => {
    if( type !== Site.Type.WEBSITE ) {
      return
    }

    try {
      await dispatch( siteActions.site.updatePrimarySite( merchantId, siteId ) )
      showSuccess( `${ siteLabels[ type ] } has been successfully set as primary` )
    } catch( err ) {
      showError( err )
    }
  }, [ dispatch, merchantId, showError, showSuccess, type ] )

  useEffectOnce( () => {
    reloadSites()
  } )

  const indexSite = useCallback( async () => {
    if( type !== Site.Type.WEBSITE ) {
      return
    }

    try {
      await dispatch( siteActions.site.indexSite( merchantId ) )
      showSuccess( `${ siteLabels[ type ] } has started indexing successfully` )
    } catch( err ) {
      showError( err )
    }
  }, [ dispatch, merchantId, showError, showSuccess, type ] )

  const downloadSiteLogs = useCallback( ( title: string, data: string ) => {
    const blob = new Blob( [ data ], { type: "application/json" } )
    const link = document.createElement( "a" )
    link.href = URL.createObjectURL( blob )
    link.download = `${ title }.json`
    link.click()
  }, [] )

  useEffectOnce( () => {
    reloadSites()
  } )

  const canGenerateContentAndIndexSite = ( site: Site ) => {
    return type === Site.Type.WEBSITE
    && site.contentGeneration?.status !== Site.ContentGenerationStatus.IN_PROGRESS
    && site.indexed?.status !== Site.IndexedStatus.IN_PROGRESS
  }

  return (
    <StyledContainer>
      {
        formStatus?.error || formStatus?.status === OnboardingForm.Status.IN_PROGRESS
          ? <StyledInfoBanner>
            Content Generation is disabled because:&nbsp;
            {
              formStatus.error
                ? ` there are some errors with onboarding form. ${ formStatus.error }`
                : " the Onboarding form has not completed yet."
            }
          </StyledInfoBanner>
          : null
      }
      {
        sites?.map( ( site ) => (
          <StyledSiteItem key={ site.id }>
            {
              ! canGenerateContentAndIndexSite( site ) && (
                <StyledInfoBanner>
                  Content generation and Index site are disable because&nbsp;
                  {
                    site.contentGeneration?.status === Site.ContentGenerationStatus.IN_PROGRESS
                      ? "Site content is being generated"
                      : "The site is being indexed"
                  }
                </StyledInfoBanner>
              )
            }
            <Flex display="flex" flexDirection="row">
              <ReadOnlyField label="Duda Site ID" value={
                <>
                  <span>{ site.dudaId }</span>
                  {
                    type === Site.Type.WEBSITE && ( site.isPrimary || sites.length === 1 ) && (
                      <i>&nbsp;&#8211;&nbsp;Primary</i>
                    )
                  }
                </>
              } />
              {
                type === Site.Type.LANDING_PAGE && (
                  <ReadOnlyField label="Landing Page Label" value={ site.label || "" } containerStyle={ { marginLeft: "50px" } }/>
                )
              }

            </Flex>
            <Link href={ `https://my.duda.co/home/site/${ site.dudaId }` } target="_blank">Open in Duda</Link>
            {
              ( formStatus && ! formStatus.error && formStatus.status === OnboardingForm.Status.COMPLETED )
              && canGenerateContentAndIndexSite( site ) && (
                <>
                  <Link onClick={ () => generateWebsiteContent( site.id ) } style={ { marginLeft: "10px" } }>Generate Content</Link>
                  <Link onClick={ () => generateWebsitePageContent( site.id ) } style={ { marginLeft: "10px" } }>Generate Page Content</Link>
                </>
              )
            }
            <Link onClick={ () => unlinkWebsite( site.id ) } style={ { marginLeft: "10px" } }>Unlink { siteLabels[ type ] } from Duda</Link>
            {
              type === Site.Type.WEBSITE && ! site.isPrimary && sites.length > 1 && (
                <Button onClick={ () => updatePrimarySite( site.id ) } style={ { marginLeft: "10px" } }>Make Primary</Button>
              )
            }
            {
              site.isPrimary && (
                <Flex flexDirection="column" style={ { marginTop: "20px" } }>
                  <Flex>
                    <strong>Indexing Status:</strong>&nbsp;{ siteService.getSiteIndexStatusLabel( site.indexed?.status ) }
                    {
                      site.indexed?.at != null && (
                        <span>&nbsp;&#8212;&nbsp;{ dateHelpers.formatToShortDateTime( site.indexed.at ) }</span>
                      )
                    }
                    {
                      canGenerateContentAndIndexSite( site ) && (
                        <Button onClick={ () => indexSite() } style={ { marginLeft: "10px" } }>
                          {
                            site.indexed?.status ? "Re-Index" : "Index"
                          }
                        </Button>
                      )
                    }
                  </Flex>
                  {
                    site.indexed?.status === Site.IndexedStatus.FAILED && site.indexed?.error?.details && (
                      <div><strong>Error:</strong>&nbsp;{ site.indexed.error.details }</div>
                    )
                  }
                </Flex>
              )
            }
            <Flex display="flex" flexDirection="column" style={ { marginTop: "20px" } }>
              {
                site.contentGeneration && (
                  <>
                    <div><strong>Content Generation Status:</strong>&nbsp;{ siteService.getSiteContentGenerationLabel( site.contentGeneration.status ) }</div>
                    {
                      site.contentGeneration.error && (
                        <div style={ { marginTop: "20px" } }><strong>Error:</strong>&nbsp;{ site.contentGeneration.error.message }</div>
                      )
                    }
                    {
                      site.contentGeneration.siteDetailsLogs && (
                        <div style={ { marginTop: "20px" } }>
                          <strong>Site Details</strong>

                          <Button
                            onClick={ () => {
                              const parseData = site.contentGeneration!.siteDetailsLogs!.map( ( siteDetailsLog ) => ( {
                                at: siteDetailsLog.at,
                                details: JSON.parse( siteDetailsLog.details ),
                              } ) )
                              downloadSiteLogs( `Site Details Log - ${ site.dudaId }`, JSON.stringify( parseData, null, 2 ) )
                            } }
                            style={ { marginLeft: "20px" } }
                          >
                            Download all Site Logs
                          </Button>
                          <div style={ { marginTop: "10px" } }>
                            <AccordionGroup
                              items={
                                site.contentGeneration.siteDetailsLogs.map( ( siteDetailsLog, index ) => {
                                  const logDate = dateHelpers.formatToShortDateTime( siteDetailsLog.at )
                                  const indexTitle = `Site Details Log ${ index + 1 }`
                                  return {
                                    content: <pre style={ { whiteSpace: "pre-wrap", wordBreak: "break-all" } }>{ siteDetailsLog.details }</pre>,
                                    title: <div style={ { fontWeight: styleHelpers.fonts.weight.semiBold } } >
                                      { `${ indexTitle } - ${ logDate }` }
                                      <Button
                                        onClick={ ( event: React.MouseEvent<HTMLElement> ) => {
                                          event.preventDefault()
                                          event.stopPropagation()
                                          downloadSiteLogs( `${ indexTitle } - ${ site.dudaId } - ${ logDate }`, siteDetailsLog.details )
                                        } }
                                        style={ { marginLeft: "20px" } }
                                      >
                                        Download Site Log
                                      </Button>
                                    </div>,
                                  }
                                } )
                              }
                            />
                          </div>
                        </div>
                      )
                    }
                    {
                      site.contentGeneration.logs && (
                        <div style={ { marginTop: "20px" } }>
                          <div ><strong>Logs</strong></div>
                          <StyledTable style={ { marginTop: "15px" } }>
                            <thead>
                              <tr>
                                <th>Detail</th>
                                <th>Started At</th>
                                <th>Finished At</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                site.contentGeneration.logs.map( ( log ) => (
                                  <StyledTableRow key={ log.id }>
                                    <td>{ log.detail }</td>
                                    <td>{ dateHelpers.formatToShortDateTime( log.startedAt ) }</td>
                                    <td>{ log.finishedAt ? dateHelpers.formatToShortDateTime( log.finishedAt ) : "" }</td>
                                  </StyledTableRow>
                                ) )
                              }
                            </tbody>
                          </StyledTable>
                        </div>
                      )
                    }
                  </>
                )
              }
            </Flex>
            <Divider style={ { marginTop: "20px" } } />
          </StyledSiteItem>
        ) )
      }
      {
        sites != null && (
          <div>
            <Button onClick={ generateWebsite }>Generate { siteLabels[ type ] }</Button>
            <Link onClick={ linkWebsite } style={ { marginLeft: "10px" } }>Link { siteLabels[ type ] } from Duda</Link>
          </div>
        )
      }
    </StyledContainer>
  )
}


export interface OnboardingForm {
  id: string
  merchantId?: string
  created: {
    at: string
  }

  currentStep: OnboardingForm.Step

  fields: {
    a2p_business_ein?: string
    a2p_business_identity?: string
    a2p_business_industry?: string
    a2p_business_name?: string
    a2p_business_region_operation?: string
    a2p_business_registration_identifier?: string
    a2p_business_type?: string
    additional_pages: OnboardingForm.Page[]
    additional_tool_booking_enabled: unknown
    additional_tool_booking_notes: unknown
    additional_tool_checkin_enabled: unknown
    additional_tool_checkin_notes: unknown
    additional_tool_contacts_enabled: unknown
    additional_tool_contacts_notes: unknown
    additional_tool_local_site_enabled: unknown
    additional_tool_local_site_notes: unknown
    additional_tool_messages_enabled: unknown
    additional_tool_messages_notes: unknown
    additional_tool_payment_enabled: unknown
    additional_tool_payment_notes: unknown
    additional_tool_phone_enabled: unknown
    additional_tool_phone_notes: unknown
    additional_tool_referrals_enabled: unknown
    additional_tool_referrals_notes: unknown
    additional_tool_reviews_enabled: unknown
    additional_tool_reviews_notes: unknown
    ads_action: unknown
    ads_client_experience: unknown
    ads_discount_promo_details: unknown
    ads_google_account_access: unknown
    ads_google_cid: unknown
    ads_marketing_goal: unknown
    ads_platforms: unknown
    ads_services: unknown
    ads_spend?: number
    area_of_operation?: string
    asset_sourcing_url?: string
    asset_sourcing?: string
    billing_address_city?: string
    billing_address_country?: string
    billing_address_postal_code?: string
    billing_address_same_as_business?: boolean
    billing_address_state?: string
    billing_address_street?: string
    business_creation_history?: string
    business_creation_year?: number
    business_messaging?: string
    business_registration_number: unknown
    company_address_city?: string
    company_address_country?: string
    company_address_postal_code?: string
    company_address_state?: string
    company_address_street?: string
    company_dashboard_type?: OnboardingForm.DashboardType
    company_email?: string
    company_name?: string
    company_phone_number?: string
    competitor_1?: string
    competitor_2?: string
    competitor_3?: string
    contact_email?: string
    contact_first_name?: string
    contact_last_name?: string
    contact_phone_number?: string
    content_sourcing?: string
    creative_tone?: string
    current_site_url_missing?: boolean
    current_site_url?: string
    employee_count?: number
    excluded_keywords?: string
    external_booking_system?: string
    facebook_id?: string
    gbp_url?: string
    google_drive_folder_url?: string
    iframe_code_pages?: string
    iframe_code_services?: string
    instagram_id?: string
    kickoff_date?: string
    new_domain?: string
    notes_about_us: string
    notes_additional_pages: string
    notes_ads: string
    notes_company_details: string
    notes_creative_direction: string
    notes_product: string
    notes_services: string
    notes_site: string
    notes_social_content?: string
    offers?: string[]
    package_purchased?: string
    place_id?: string
    primary_industry?: string
    services: OnboardingForm.ServiceValues[]
    site_examples?: string[]
    site_items: OnboardingForm.SiteItem[]
    site_page_count?: number
    site_template?: string
    social_add_on?: boolean
    social_content_current_blog_move?: unknown
    social_content_fb_enabled?: unknown
    social_content_gbp_enabled?: unknown
    social_content_ig_enabled?: unknown
    sub_industry_1?: string
    sub_industry_2?: string
    sub_industry_3?: string
    sub_industry_4?: string
    sub_industry_5?: string
    tax_address_city?: string
    tax_address_country?: string
    tax_address_postal_code?: string
    tax_address_same_as_billing?: boolean
    tax_address_state?: string
    tax_address_street?: string
    writting_perspective?: string
    writting_tone?: string
  }

  generated: {
    fields: {
      company_address_city?: string
      company_address_country?: string
      company_address_postal_code?: string
      company_address_state?: string
      company_address_street?: string
      company_name?: string
      company_phone_number?: string
      facebook_id?: string
      instagram_id?: string
      primary_industry?: string
    }
    googlePlace?: {
      address: {
        city: string | null
        country: string | null
        postal_code: string | null
        state: string | null
        street: string | null
      }
      coordinates: {
        lat: number
        lng: number
      }
      formattedAddress: string
      name: string
      openingHours: Array<{
        close?: {
          day: number
          time: string
        }
        open: {
          day: number
          time: string
        }
      }>
      phoneNumber: string | null
      placeId: string
      website: string | null
    }
    pageQuestions: { [serviceName: string]: string }
    services: OnboardingForm.Service[]
    serviceQuestions: { [serviceName: string]: string }
    siteItems: OnboardingForm.SiteItem[]
  }

  preventEditing?: boolean

  validation: {
    fields: Partial<Record<OnboardingForm.Field, OnboardingForm.ValidationFieldResult>>
    steps: Partial<Record<OnboardingForm.Step, OnboardingForm.ValidationStepResult>>
  }

  status: {
    opportunity: OnboardingForm.Status
    merchant?: OnboardingForm.Status
  }
}

export namespace OnboardingForm {
  export enum DashboardType {
    MULTI_LOCATION_PARENT_CHILD = "multi_location_parent_child",
    MULTI_LOCATION_SINGLE = "multi_location_single",
    SINGLE_LOCATION = "single_location",
  }

  export enum Field {
    A2P_BUSINESS_EIN = "a2p_business_ein",
    A2P_BUSINESS_IDENTITY = "a2p_business_identity",
    A2P_BUSINESS_INDUSTRY = "a2p_business_industry",
    A2P_BUSINESS_NAME = "a2p_business_name",
    A2P_BUSINESS_REGION_OPERATION = "a2p_business_region_operation",
    A2P_BUSINESS_REGISTRATION_IDENTIFIER = "a2p_business_registration_identifier",
    A2P_BUSINESS_TYPE = "a2p_business_type",
    ADDITIONAL_PAGES = "additional_pages",
    ADDITIONAL_TOOL_BOOKING_ENABLED = "additional_tool_booking_enabled",
    ADDITIONAL_TOOL_BOOKING_NOTES = "additional_tool_booking_notes",
    ADDITIONAL_TOOL_CHECKIN_ENABLED = "additional_tool_checkin_enabled",
    ADDITIONAL_TOOL_CHECKIN_NOTES = "additional_tool_checkin_notes",
    ADDITIONAL_TOOL_CONTACTS_ENABLED = "additional_tool_contacts_enabled",
    ADDITIONAL_TOOL_CONTACTS_NOTES = "additional_tool_contacts_notes",
    ADDITIONAL_TOOL_LOCAL_SITE_ENABLED = "additional_tool_local_site_enabled",
    ADDITIONAL_TOOL_LOCAL_SITE_NOTES = "additional_tool_local_site_notes",
    ADDITIONAL_TOOL_MESSAGES_ENABLED = "additional_tool_messages_enabled",
    ADDITIONAL_TOOL_MESSAGES_NOTES = "additional_tool_messages_notes",
    ADDITIONAL_TOOL_PAYMENT_ENABLED = "additional_tool_payment_enabled",
    ADDITIONAL_TOOL_PAYMENT_NOTES = "additional_tool_payment_notes",
    ADDITIONAL_TOOL_PHONE_ENABLED = "additional_tool_phone_enabled",
    ADDITIONAL_TOOL_PHONE_NOTES = "additional_tool_phone_notes",
    ADDITIONAL_TOOL_REFERRALS_ENABLED = "additional_tool_referrals_enabled",
    ADDITIONAL_TOOL_REFERRALS_NOTES = "additional_tool_referrals_notes",
    ADDITIONAL_TOOL_REVIEWS_ENABLED = "additional_tool_reviews_enabled",
    ADDITIONAL_TOOL_REVIEWS_NOTES = "additional_tool_reviews_notes",
    ADS_ACTION = "ads_action",
    ADS_CLIENT_EXPERIENCE = "ads_client_experience",
    ADS_DISCOUNT_PROMO_DETAILS = "ads_discount_promo_details",
    ADS_GOOGLE_ACCOUNT_ACCESS = "ads_google_account_access",
    ADS_GOOGLE_CID = "ads_google_cid",
    ADS_MARKETING_GOAL = "ads_marketing_goal",
    ADS_PLATFORMS = "ads_platforms",
    ADS_SERVICES = "ads_services",
    ADS_SPEND = "ads_spend",
    AREA_OF_OPERATION = "area_of_operation",
    ASSET_SOURCING = "asset_sourcing",
    ASSET_SOURCING_URL = "asset_sourcing_url",
    BILLING_ADDRESS_CITY = "billing_address_city",
    BILLING_ADDRESS_COUNTRY = "billing_address_country",
    BILLING_ADDRESS_POSTAL_CODE = "billing_address_postal_code",
    BILLING_ADDRESS_SAME_AS_BUSINESS = "billing_address_same_as_business",
    BILLING_ADDRESS_STATE = "billing_address_state",
    BILLING_ADDRESS_STREET = "billing_address_street",
    BUSINESS_CREATION_HISTORY = "business_creation_history",
    BUSINESS_CREATION_YEAR = "business_creation_year",
    BUSINESS_MESSAGING = "business_messaging",
    BUSINESS_REGISTRATION_NUMBER = "business_registration_number",
    COMPANY_ADDRESS_CITY = "company_address_city",
    COMPANY_ADDRESS_COUNTRY = "company_address_country",
    COMPANY_ADDRESS_POSTAL_CODE = "company_address_postal_code",
    COMPANY_ADDRESS_STATE = "company_address_state",
    COMPANY_ADDRESS_STREET = "company_address_street",
    COMPANY_DASHBOARD_TYPE = "company_dashboard_type",
    COMPANY_EMAIL = "company_email",
    COMPANY_NAME = "company_name",
    COMPANY_PHONE_NUMBER = "company_phone_number",
    COMPETITOR_1 = "competitor_1",
    COMPETITOR_2 = "competitor_2",
    COMPETITOR_3 = "competitor_3",
    CONTACT_EMAIL = "contact_email",
    CONTACT_FIRST_NAME = "contact_first_name",
    CONTACT_LAST_NAME = "contact_last_name",
    CONTACT_PHONE_NUMBER = "contact_phone_number",
    CONTENT_SOURCING = "content_sourcing",
    CREATIVE_TONE = "creative_tone",
    CURRENT_SITE_URL = "current_site_url",
    CURRENT_SITE_URL_MISSING = "current_site_url_missing",
    EMPLOYEE_COUNT = "employee_count",
    EXCLUDED_KEYWORDS = "excluded_keywords",
    EXTERNAL_BOOKING_SYSTEM = "external_booking_system",
    FACEBOOK_ID = "facebook_id",
    GBP_URL = "gbp_url",
    GOOGLE_DRIVE_FOLDER_URL = "google_drive_folder_url",
    IFRAME_CODE_PAGES = "iframe_code_pages",
    IFRAME_CODE_SERVICES = "iframe_code_services",
    INSTAGRAM_ID = "instagram_id",
    KICKOFF_DATE = "kickoff_date",
    NEW_DOMAIN = "new_domain",
    NOTES_ABOUT_US = "notes_about_us",
    NOTES_ADDITIONAL_PAGES = "notes_additional_pages",
    NOTES_ADS = "notes_ads",
    NOTES_COMPANY_DETAILS = "notes_company_details",
    NOTES_CREATIVE_DIRECTION = "notes_creative_direction",
    NOTES_PRODUCT = "notes_product",
    NOTES_SERVICES = "notes_services",
    NOTES_SITE = "notes_site",
    NOTES_SOCIAL_CONTENT = "notes_social_content",
    OFFERS = "offers",
    PACKAGE_PURCHASED = "package_purchased",
    PRIMARY_INDUSTRY = "primary_industry",
    SERVICES = "services",
    SITE_EXAMPLES = "site_examples",
    SITE_ITEMS = "site_items",
    SITE_PAGE_COUNT = "site_page_count",
    SITE_TEMPLATE = "site_template",
    SOCIAL_ADD_ON = "social_add_on",
    SOCIAL_CONTENT_CURRENT_BLOG_MOVE = "social_content_current_blog_move",
    SOCIAL_CONTENT_FB_ENABLED = "social_content_fb_enabled",
    SOCIAL_CONTENT_GBP_ENABLED = "social_content_gbp_enabled",
    SOCIAL_CONTENT_IG_ENABLED = "social_content_ig_enabled",
    SUB_INDUSTRY_1 = "sub_industry_1",
    SUB_INDUSTRY_2 = "sub_industry_2",
    SUB_INDUSTRY_3 = "sub_industry_3",
    SUB_INDUSTRY_4 = "sub_industry_4",
    SUB_INDUSTRY_5 = "sub_industry_5",
    TAX_ADDRESS_CITY = "tax_address_city",
    TAX_ADDRESS_COUNTRY = "tax_address_country",
    TAX_ADDRESS_POSTAL_CODE = "tax_address_postal_code",
    TAX_ADDRESS_SAME_AS_BILLING = "tax_address_same_as_billing",
    TAX_ADDRESS_STATE = "tax_address_state",
    TAX_ADDRESS_STREET = "tax_address_street",
    WRITTING_PERSPECTIVE = "writting_perspective",
    WRITTING_TONE = "writting_tone",
  }

  export interface AddressType {
    city?: string
    countryCode?: string
    postalCode?: string
    stateCode?: string
    street?: string
  }

  export type FieldValue = string | number | boolean | string[] | unknown

  export interface FormStatus {
    error?: string
    status?: OnboardingForm.Status
  }

  export enum IdentifierType {
    MERCHANT = "merchant",
    OPPORTUNITY = "opportunity",
  }

  export interface Identifier {
    id: string
    type: IdentifierType
  }

  export enum Package {
    COMPETE = "compete",
    COMPETE_ADS = "compete_ads",
    DOMINATE = "dominate",
    DOMINATE_ADS = "dominate_ads",
    ENGAGE = "engage",
    ENGAGE_ADS = "engage_ads",
    SUMMIT = "summit",
    SUMMIT_ADS = "summit_ads",
  }

  export interface Page {
    answer?: string
    id: string
    label?: string
    type: OnboardingForm.SitePageType
  }

  export interface Service {
    id: string
    name: string
    primaryIndustry: string
    questions: string[]
  }

  export interface ServiceValues {
    id: string
    name: string
    value?: number
    answer?: string
  }

  export enum SiteItemType {
    MENU = "menu",
    PAGE = "page",
    PAGE_GROUP = "page_group",
  }

  export interface SiteItemBase {
    id: string
    label: string
    parentId?: string
    type: SiteItemType
  }

  export interface SiteItemMenu extends SiteItemBase {
    items: SiteItem[]
    type: SiteItemType.MENU
  }

  export interface SiteItemPage extends SiteItemBase {
    pageType: SitePageType
    pageId?: string
    serviceId?: string
    type: SiteItemType.PAGE
  }

  export interface SiteItemPageGroup extends SiteItemBase {
    items: SiteItem[]
    type: SiteItemType.PAGE_GROUP
  }

  export type SiteItem = SiteItemMenu | SiteItemPage | SiteItemPageGroup

  export enum SitePageType {
    ABOUT = "about",
    BEFORE_AFTER = "before_after",
    BLOG = "blog",
    BRANDS_PARTNERS = "brands_partners",
    CAREERS = "careers",
    CONTACT = "contact",
    CUSTOM = "custom",
    FAQ = "faq",
    FEATURED_PRODUCTS = "featured_products",
    GALLERY = "gallery",
    GENERAL_CONTENT = "general_content",
    HOME = "home",
    LOCATION = "location",
    MEDIA = "media",
    PORTFOLIO = "portfolio",
    PRICING = "pricing",
    PROCESS = "process",
    RESOURCES = "resources",
    SERVICE = "service",
    SERVICE_AREA = "service_area",
    SERVICE_CATEGORY = "service_category",
    TEAM = "team",
    THANKYOU = "thank_you",
  }

  export enum Status {
    COMPLETED = "completed",
    IN_PROGRESS = "in_progress",
  }

  export enum Step {
    COMPANY_DETAIL = "company_detail",
    PRODUCT = "product",
    ABOUT_US = "about_us",
    SOCIAL_CONTENT = "social_content",
    CREATIVE_DIRECTION = "creative_direction",
    SERVICES = "services",
    PAGES = "pages",
    SITE = "site",
    ADS = "ads",
  }

  export interface ValidationFieldResult {
    error: string | null
    errorType?: ValidationFieldType
    parsedValue?: unknown
    value: FieldValue
  }

  export enum ValidationFieldType {
    REQUIRED = "required",
    VALIDATION = "validation",
  }

  export const ValidationRequiredError = "This field is required"

  export interface ValidationStepResult {
    error?: {
      canSkip?: boolean
      message?: string
    }
  }
}

export interface OnboardingOpportunity {
  id: string
  merchantId?: string
  salesforce: {
    accountId: string
    accountName: string
    address?: {
      city: string
      countryCode: string
      postalCode: string
      stateCode: string
      street: string
    }
    email: string
    id: string
    mobileNumber: string
    name: string
    ownerId: string
    ownerName: string
    primaryIndustry: string
    secondaryIndustry: string
    stage: OnboardingOpportunity.StageName
    website: string
  }
  textIndex: string[]
}

export namespace OnboardingOpportunity {
  export enum StageName {
    CHURNED = "churned",
    CLOSED_LOST = "closed_lost",
    CLOSED_WON = "closed_won",
    DEMO_COMPLETED = "demo_completed",
    DEMO_SET = "demo_set",
    DISQUALIFIED = "disqualified",
    DISQUALIFIED_SALES = "disqualified_sales",
    DISQUALIFIED_SUCCESS = "disqualified_success",
    FREE_TRIAL = "free_trial",
    NEGOTIATION = "negotiation",
    NO_SHOW = "no_show",
    PROPOSAL = "proposal",
    QUALIFICATION = "qualification",
    SQO = "sqo",
  }
}

export interface OnboardingOpportunityOwner {
  id: string
  name: string
}

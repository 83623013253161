export const eventHelper = {
  async emit( event_name: string, payload?: Record<string, unknown> ) {
    const evt = new CustomEvent( event_name, { detail: payload, bubbles: true } )
    document.dispatchEvent( evt )
  },
  async addEventListener<T>( type: string, handler: ( event_details: T ) => void, options?: AddEventListenerOptions ) {
    window.addEventListener( type, ( event ) => handler( ( ( event as CustomEvent ).detail ) ), options )
  },
  async removeListener<T>( type: string, handler: ( event_details: T ) => void, options?: AddEventListenerOptions ) {
    window.removeEventListener( type, ( event ) => handler( ( ( event as CustomEvent ).detail ) ), options )
  },
}

import type { AnyAction } from "@reduxjs/toolkit"
import { reactToAngular } from "angulareact"
import { createRoot } from "react-dom/client"
import type { SnakeCasedPropertiesDeep } from "type-fest"

import type { AuthInternalAccount } from "@onelocal/frontend-admin-web/auth"
import { authSelectors } from "@onelocal/frontend-admin-web/auth"
import type { doesRouteExist } from "@onelocal/frontend-admin-web/common"
import { currentAdminStore, SideMenu } from "@onelocal/frontend-admin-web/main"
import { MerchantDetailsHeader, merchantsActions, MerchantsListMenu } from "@onelocal/frontend-admin-web/merchants"
import { MerchantSiteDetails } from "@onelocal/frontend-admin-web/site"
import { utilHelpers } from "@onelocal/shared/common"
import { AdminContainerLib } from "./components/AdminContainerLib"
import { ComponentWrapper } from "./components/ComponentWrapper"

export { doesRouteExist } from "@onelocal/frontend-admin-web/common"

export function getCurrentInternalAccount() {
  const currentInternalAccount = authSelectors.internalAccounts.current( currentAdminStore!.getState() )
  return currentInternalAccount ? utilHelpers.toSnakeCase<SnakeCasedPropertiesDeep<AuthInternalAccount>>( currentInternalAccount ) : null
}

export function reloadMerchantSettings( merchantId: string ) {
  currentAdminStore!.dispatch( merchantsActions.merchantsSettings.get( merchantId ) as unknown as AnyAction )
}

export function renderSideMenu() {
  const SideMenuComponent = ComponentWrapper( SideMenu )
  // We have to return as any because when we add @types/angular project it fails the build for the old frontend due to error `Subsequent property declarations must have the same type.  Property '$inject' must be of type 'string[]', but here has type 'readonly string[]'`
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return reactToAngular( SideMenuComponent ) as any
}

export function renderMerchantDetailsHeader() {
  const MerchantDetailsHeaderComponent = ComponentWrapper( MerchantDetailsHeader )
  // We have to return as any because when we add @types/angular project it fails the build for the old frontend due to error `Subsequent property declarations must have the same type.  Property '$inject' must be of type 'string[]', but here has type 'readonly string[]'`
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return reactToAngular( MerchantDetailsHeaderComponent, [ "merchantId" ] ) as any
}

export function renderMerchantsListMenu() {
  const MerchantsListMenuComponent = ComponentWrapper( MerchantsListMenu )
  // We have to return as any because when we add @types/angular project it fails the build for the old frontend due to error `Subsequent property declarations must have the same type.  Property '$inject' must be of type 'string[]', but here has type 'readonly string[]'`
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return reactToAngular( MerchantsListMenuComponent ) as any
}

export function renderMerchantSiteDetails() {
  const MerchantSiteDetailsComponent = ComponentWrapper( MerchantSiteDetails )
  // We have to return as any because when we add @types/angular project it fails the build for the old frontend due to error `Subsequent property declarations must have the same type.  Property '$inject' must be of type 'string[]', but here has type 'readonly string[]'`
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return reactToAngular( MerchantSiteDetailsComponent, [ "merchantId", "type" ] ) as any
}

export function renderRoot( el: HTMLElement ) {
  const root = createRoot( el )
  root.render( <AdminContainerLib /> )
}

// Types below will be exposed in legacy dashboard under the NX global var ( i.e NX.renderSideMenu )
declare global {
  export interface FrontendAdminLib {
    doesRouteExist: typeof doesRouteExist
    getCurrentInternalAccount: typeof getCurrentInternalAccount
    reloadMerchantSettings: typeof reloadMerchantSettings
    renderMerchantDetailsHeader: typeof renderMerchantDetailsHeader
    renderMerchantsListMenu: typeof renderMerchantsListMenu
    renderMerchantSiteDetails: typeof renderMerchantSiteDetails
    renderRoot: typeof renderRoot
    renderSideMenu: typeof renderSideMenu
  }
}


import { every, isEqual, uniq } from "lodash"
import { useCallback, useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"

import type { DefaultThunkAction, PaginatedItems, PaginatedQueryOptions } from ".."
import { errorHelper, useAsyncDispatch } from ".."

export interface usePaginatedResourcesOptions<TItem, TFilter, TRootState> {
  defaultFilter: TFilter
  getQueryAction: ( filter: TFilter, options: PaginatedQueryOptions ) => DefaultThunkAction<TRootState, PaginatedItems<TItem>>
  perPage: number
  selector: ( itemIds: string[] ) => ( ( state: TRootState ) => ( TItem[] | null ) )
  filterSelector?: ( filter: TFilter ) => ( ( state: TRootState ) => ( TItem[] | null ) )
}
// @todo Add error handling and retry
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const usePaginatedResources = <TItem extends { id: string }, TFilter extends { [key: string]: any }, TRootState>( options: usePaginatedResourcesOptions<TItem, TFilter, TRootState> ) => {
  const { defaultFilter, filterSelector, getQueryAction, perPage, selector } = options
  const dispatch = useAsyncDispatch()
  const [ hasMoreItem, setHasMoreItem ] = useState( false )
  const [ currentPage, setCurrentPage ] = useState( 1 )
  const [ isInitialLoad, setIsInitialLoad ] = useState( true )
  const [ isLoading, setIsLoading ] = useState( false )
  const [ isRefreshing, setIsRefreshing ] = useState( false )
  const [ loadingError, setLoadingError ] = useState<Error | null>( null )
  const [ totalItemsCount, setTotalItemsCount ] = useState<number>()
  const [ itemIds, setItemIds ] = useState<string[]>( [] )
  const queryOptionsRef = useRef<PaginatedQueryOptions>( {
    page: 1,
    perPage,
  } )
  const queryFilterRef = useRef<TFilter>( defaultFilter )
  const items = useSelector( filterSelector ? filterSelector( queryFilterRef.current ) : selector( itemIds ) )
  const itemsRef = useRef<TItem[] | null>( items )
  const updateItems = useCallback( ( paginatedItems: PaginatedItems<TItem>, reset?: boolean ) => {
    setTotalItemsCount( paginatedItems.total )
    setItemIds( ( currentItemIds ) => {
      const updatedItemIds = reset ? [] : [ ...currentItemIds ]
      let updated = false
      for( const item of paginatedItems.items ) {
        if( ! updatedItemIds.includes( item.id ) ) {
          updatedItemIds.push( item.id )
          updated = true
        }
      }
      if( updated ) {
        return updatedItemIds
      }
      return currentItemIds
    } )
  }, [] )

  const loadPage = useCallback( async ( page: number, reset = true ) => {
    try {
      setIsLoading( true )

      queryOptionsRef.current = {
        ...queryOptionsRef.current,
        page,
      }

      const queryFilter = queryFilterRef.current
      const queryOptions = queryOptionsRef.current

      const paginatedItems = await dispatch( getQueryAction( queryFilter, queryOptions ) )

      // If a new query has been started while waiting from the API response
      if( ! isEqual( queryFilterRef.current, queryFilter ) || ! isEqual( queryOptionsRef.current, queryOptions ) ) {
        return
      }

      updateItems( paginatedItems, reset )

      setCurrentPage( page )

      setHasMoreItem( paginatedItems.hasMore )

      setIsLoading( false )
    } catch( err ) {
      setIsLoading( false )
      setLoadingError( err )
    }
  }, [ dispatch, getQueryAction, updateItems ] )

  const loadNextPage = useCallback( ( reset = true ) => {
    loadPage( currentPage + 1, reset )
  }, [ currentPage, loadPage ] )
  const refresh = useCallback( async ( silent?: boolean ) => {
    if( ! silent ) {
      setIsRefreshing( true )
    }
    try {
      const paginatedItems = await dispatch( getQueryAction( queryFilterRef.current, {
        ...queryOptionsRef.current,
        page: 1,
      } ) )
      updateItems( paginatedItems, true )
    } catch( err: unknown ) { /* empty */ }
  }, [ dispatch, getQueryAction, updateItems ] )

  const setQueryFilter = useCallback( async ( filter: Partial<TFilter>, maintainPreviousFilter = true ) => {
    let updatedFilter: TFilter
    if( maintainPreviousFilter ) {
      updatedFilter = {
        ...queryFilterRef.current,
        ...filter,
      }
    } else {
      updatedFilter = filter as TFilter
    }

    // Compare all the field in the filter and consider null and undefined as equal
    // This is needed to avoid issue where the initial filter is different that the default filter
    // where on has undefined and the other as null
    const isAllFieldEqual = every(
      uniq( [
        ...Object.keys( updatedFilter ),
        ...Object.keys( queryFilterRef.current ),
      ] ),
      ( field ) => {
        const currentValue = queryFilterRef.current[ field ] || null
        const updatedValue = updatedFilter[ field ] || null
        if( currentValue == null && updatedValue == null ) {
          return true
        }

        return isEqual( currentValue, updatedValue )
      },
    )

    if( isAllFieldEqual ) {
      return
    }

    queryFilterRef.current = updatedFilter

    queryOptionsRef.current = {
      ...queryOptionsRef.current,
      page: 1,
    }
    setIsInitialLoad( true )
    setItemIds( [] )
    await loadPage( 1 )
    setIsInitialLoad( false )
  }, [ loadPage ] )

  const load = useCallback( async () => {
    setLoadingError( null )

    setIsInitialLoad( true )

    try {
      await loadPage( 1 )
    } catch( err: unknown ) {
      setLoadingError( errorHelper.getDisplayErrorMessage( err ) )
    } finally {
      setIsInitialLoad( false )
    }
  }, [ loadPage ] )

  const validateItemIds = useCallback( async ( ids: string[], fn: ( ids: string[], filter: TFilter ) => Promise<string[]> ) => {
    const resultIds = await fn( ids, queryFilterRef.current )
    setItemIds( ( currentItemIds ) => {
      let updatedItemIds = currentItemIds
      for( const id of ids ) {
        const index = currentItemIds.indexOf( id )
        if( resultIds.includes( id ) ) {
          if( index === -1 ) {
            updatedItemIds = [ ...updatedItemIds, id ]
          }
        } else {
          if( index !== -1 ) {
            updatedItemIds = [ ...updatedItemIds ]
            updatedItemIds.splice( index, 1 )
          }
        }
      }
      return updatedItemIds
    } )
  }, [] )

  useEffect( () => {
    load()
  }, [ load ] )

  // Use a ref so the ref doesn't change on re-render
  if( ! isEqual( items, itemsRef.current ) ) {
    itemsRef.current = items
  }

  return {
    hasMoreItem,
    isInitialLoad,
    isLoading,
    isRefreshing,
    items: isInitialLoad ? null : itemsRef.current,
    loadingError,
    queryFilter: queryFilterRef.current,
    queryOptions: queryOptionsRef.current,
    load,
    loadPage,
    loadNextPage,
    refresh,
    setQueryFilter,
    totalItemsCount,
    validateItemIds,
  }
}

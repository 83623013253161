import { apiHelper } from "@onelocal/frontend/common"
import { parseSettingsFromApi } from "../types/api"

export const mainSettingsService = {
  async getSettings() {
    return apiHelper.request( {
      method: "GET",
      parsingData: parseSettingsFromApi,
      url: "/main/settings",
    } )
  },
}

import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

import type { DefaultThunkAction, ModuleStoreConfig } from "@onelocal/frontend/common"
import { sessionService } from "../services/sessionService"
import type { Session } from "../types"
import { commonSaga } from "./commonSagas"

export interface CommonState {
  currentSession: Session | null
}

const commonStoreKey = "common"

export interface RootStateCommon {
  [ commonStoreKey ]: CommonState
}

type CommonThunkAction<TReturnType> = DefaultThunkAction<RootStateCommon, TReturnType>

export const commonInitialState: CommonState = {
  currentSession: null,
}

const commonSlice = createSlice( {
  name: commonStoreKey,
  initialState: commonInitialState,
  reducers: {
    updateSession: ( state, action: PayloadAction<Session | null> ) => {
      state.currentSession = action.payload
    },
  },
} )

export const commonActions = {
  session: {
    getCurrentSession: (): CommonThunkAction<Session | null> => {
      return async ( dispatch ) => {
        const session = await sessionService.refreshSession()
        dispatch( commonSlice.actions.updateSession( session ) )
        return session
      }
    },
    removeSession: (): CommonThunkAction<void> => {
      return async ( dispatch ) => {
        await sessionService.removeSession()
        dispatch( commonSlice.actions.updateSession( null ) )
      }
    },
  },
}

export const commonStoreConfig: ModuleStoreConfig<CommonState> = {
  key: commonStoreKey,
  initialState: commonInitialState,
  reducers: commonSlice.reducer,
  saga: commonSaga,
}

import { isString } from "lodash"

import type { SentryHelper } from "./sentryHelper"
import { sentryHelper } from "./sentryHelper"

const DEFAULT_ERROR_MESSAGE = "Unknown error"

export interface ErrorOptions {
  /** Added to extra on error in Sentry  */
  extras?: { [key: string]: unknown }
  originalError?: Error
}

export enum ErrorType {
  INTERNAL = "internal",
  VALIDATION = "validation",
}

export class ErrorBase extends Error {
  public options: ErrorOptions
  public type: ErrorType

  constructor( type: ErrorType, message: string, options?: ErrorOptions ) {
    super( message )
    this.type = type
    this.options = options || {}
  }
}

export interface ApiErrorOptions {
  code?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
  parentError?: Error
  statusCode?: number
  type: string
}

export class ApiError extends Error {
  code?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
  message: string
  parentError?: Error
  statusCode?: number
  type: string

  constructor( message: string, options: ApiErrorOptions ) {
    super( message )
    this.code = options.code
    this.data = options.data
    this.message = message
    this.parentError = options.parentError
    this.statusCode = options.statusCode
    this.type = options.type
  }
}

export class InternalError extends ErrorBase {
  constructor( message?: string, options?: ErrorOptions ) {
    super( ErrorType.INTERNAL, message || DEFAULT_ERROR_MESSAGE, options )
  }
}

export class ValidationError extends ErrorBase {
  constructor( message: string, options?: ErrorOptions ) {
    super( ErrorType.VALIDATION, message, options )
  }
}

export namespace ErrorHelper {
  export type LogOptions = SentryHelper.LogOptions
}

export const errorHelper = {
  getDisplayErrorMessage(
    error: string | { data?: { message?: string } } | Error | null | unknown,
  ) {
    if( isString( error ) ) {
      return error
    } else if(
      error instanceof ApiError
      && error?.data?.message
      && isString( error.data.message )
    ) {
      return error.data.message
    } else if( error instanceof Error && error?.message ) {
      return error.message
    }

    return DEFAULT_ERROR_MESSAGE
  },
  logError( error: Error, options?: ErrorHelper.LogOptions ) {
    // @todo Only in development ?
    console.error( error )

    // Api error are already logged by the API helper
    if( error instanceof ApiError ) {
      return
    }

    sentryHelper.logError( error, options )
  },
  logWarning( message: string, options?: ErrorHelper.LogOptions ) {
    sentryHelper.logWarning( message, options )
  },
}

import type { SnakeCasedPropertiesDeep } from "type-fest"

import type { FileAsset, MainSettings, Task } from "."
import { Activity } from "./index"

export type ApiActivity = SnakeCasedPropertiesDeep<Activity>
export type ApiMainSettings = SnakeCasedPropertiesDeep<MainSettings>

export function parseActivityFromApi( apiReviewActivity: ApiActivity ): Activity {
  const activity: Activity.ActivityBase = {
    approval: apiReviewActivity.approval,
    archived: apiReviewActivity?.archived ? {
      accountId: apiReviewActivity?.archived?.account_id,
      date: apiReviewActivity?.archived.date,
    }: null,
    assignee: apiReviewActivity?.assignee ? {
      accountId: apiReviewActivity?.assignee?.account_id,
      date: apiReviewActivity?.assignee.date,
    }: null,
    date: apiReviewActivity.date,
    id: apiReviewActivity.id,
    merchantId: apiReviewActivity.merchant_id,
    merchantName: apiReviewActivity.merchant_name,
    status: apiReviewActivity.status,
    type: apiReviewActivity.type,
    dismissedBy: apiReviewActivity?.dismissed_by,
    categories: apiReviewActivity.categories,
    readBy: apiReviewActivity.read_by,
  }

  switch( apiReviewActivity.type ) {
    case Activity.Type.CONVERSATION:
      return {
        ...activity,
        callIds: apiReviewActivity.call_ids,
        conversationId: apiReviewActivity.conversation_id,
        messageIds: apiReviewActivity.message_ids,
        stats: {
          missedCalls: apiReviewActivity.stats.missed_calls,
          unreadMessages: apiReviewActivity.stats.unread_messages,
        },
        type: Activity.Type.CONVERSATION,
      }
    case Activity.Type.KEYWORD_RANKING_BLOG:
      return {
        ...activity,
        blogPostId: apiReviewActivity.blog_post_id,
        type: Activity.Type.KEYWORD_RANKING_BLOG,
      }
    case Activity.Type.REVIEW:
      return {
        ...activity,
        connectedAccountId: apiReviewActivity.connected_account_id,
        reviewId: apiReviewActivity.review_id,
        type: Activity.Type.REVIEW,
      }
    case Activity.Type.SHARE_BEFORE_AFTER_PHOTOS:
      return {
        ...activity,
        socialPostSuggestionId: apiReviewActivity.social_post_suggestion_id,
        type: Activity.Type.SHARE_BEFORE_AFTER_PHOTOS,
      }
    case Activity.Type.SHARE_BLOG_POST:
      return {
        ...activity,
        blogPostId: apiReviewActivity.blog_post_id,
        socialPostSuggestionIds: apiReviewActivity.social_post_suggestion_ids,
        type: Activity.Type.SHARE_BLOG_POST,
      }
    case Activity.Type.SHARE_BULK_MESSAGE:
      return {
        ...activity,
        bulkMessageId: apiReviewActivity.bulk_message_id,
        socialPostSuggestionId: apiReviewActivity.social_post_suggestion_id,
        type: Activity.Type.SHARE_BULK_MESSAGE,
      }
    case Activity.Type.SHARE_REVIEW:
      return {
        ...activity,
        reviewId: apiReviewActivity.review_id,
        socialPostSuggestionId: apiReviewActivity.social_post_suggestion_id,
        type: Activity.Type.SHARE_REVIEW,
      }
    case Activity.Type.SHARE_PHOTOS:
      return {
        ...activity,
        socialPostSuggestionId: apiReviewActivity.social_post_suggestion_id,
        type: Activity.Type.SHARE_PHOTOS,
      }
    default:
      return activity as Activity
  }
}

export type ApiTasks = SnakeCasedPropertiesDeep<Task>

export function parseTaskFromApi( apiTask: ApiTasks ) {
  const task: Task = {
    attachments: apiTask.attachments?.map( attachment => ( {
      filename: attachment.filename,
      originalFilename: attachment.original_filename,
      url: attachment.url,
    } ) ),
    category: apiTask.category,
    changeName: apiTask.change_name,
    changeType: apiTask.change_type,
    created: apiTask.created,
    description: apiTask.description,
    id: apiTask.id,
    initialMessage: apiTask.initial_message,
    intercom: {
      contactId: apiTask.intercom?.contact_id,
      conversationId: apiTask.intercom?.conversation_id,
    },
    jira: apiTask.jira,
    merchantId: apiTask.merchant_id,
    queryIndex: {
      merchantName: apiTask.query_index?.merchant_name,
      salesforceAccountId: apiTask.query_index?.salesforce_account_id,
    },
    status: apiTask.status,
    title: apiTask.title,
    tags: [],
    type: apiTask.type,
  }

  return task
}

export type ApiAsset = SnakeCasedPropertiesDeep<FileAsset>

export function parseAssetFromApi( apiAsset: ApiAsset ): FileAsset {
  return {
    assetId: apiAsset.asset_id,
    filename: apiAsset.filename,
    originalFilename: apiAsset.original_filename,
    url: apiAsset.url,
  }
}

export function parseSettingsFromApi( settings: ApiMainSettings ): MainSettings {
  return {
    disableBlogSocial: settings.disable_blog_social,
  }
}

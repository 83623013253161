import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback"
import { get, isEmpty } from "lodash"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useSelector } from "react-redux"
import styled from "styled-components"

import { useAsyncDispatch } from "@onelocal/frontend/common"
import { AnalyticHelper, commonSelectors, mixpanelHelpers, useDashboardContext } from "@onelocal/frontend-dashboard/common"
import { connectActions, ConnectChannel, connectSelectors, RING_DURATION_VALUE_DEFAULT, useConnectIsPhoneEnabled } from "@onelocal/frontend-dashboard/connect"
import { DashboardRoutePath } from "@onelocal/frontend-dashboard-web/common"
import type { BoxProps } from "@onelocal/frontend-web/common"
import { Box, Page, SaveButton, styleHelpers, useAlert } from "@onelocal/frontend-web/common"
import { ProductNames } from "@onelocal/shared/common"
import { ReactComponent as DownArrow } from "../../../assets/arrow-down.svg"
import { ReactComponent as AssistantIcon } from "../../../assets/virtual-assistant.svg"
import { ReactComponent as VoicemailIcon } from "../../../assets/voicemail-icon.svg"
import { ReactComponent as WarningIcon } from "../../../assets/warning.svg"
import { IncomingCallSettingsFormFieldKeys } from "../../../types"
import { IncomingCallBusinessHours } from "./components/IncomingCallBusinessHours"
import { IncomingCallCallForwarding } from "./components/IncomingCallCallForwarding"
import { IncomingCallRingMember } from "./components/IncomingCallRingMember"

const Container = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const FlowDownArrow = styled( DownArrow )`
  height: 24px;
  margin: 2px auto;
  width: 24px;
`

const PhoneIcon = styled( PhoneCallbackIcon )`
  color: ${ styleHelpers.colors.active };
  height: 20px;
  margin-right: 10px;
  width: 20px;
`

const SmallContainerTitle = styled.div`
  font-weight: ${ styleHelpers.fonts.weight.semiBold };
  font-size: 16px;
`

const VirtualAssistantIcon = styled( AssistantIcon )`
  height: 20px;
  margin-right: 10px;
  width: 20px;
`

const StyledSmallContainer: React.FC<BoxProps> = ( { children, containerStyle } ) => (
  <Box
    containerStyle={ {
      alignItems: "center",
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: "4px",
      display: "flex",
      flexDirection: "row",
      margin: "auto auto",
      padding: "15px 20px",
      width: "min-content",
      whiteSpace: "nowrap",
      ...containerStyle,
    } }
  >
    { children }
  </Box>
)

const BUSINESS_HOUR_DEFAULT_VALUES: ConnectChannel.IncomingCallStepBusinessHours = {
  action: {
    type: ConnectChannel.BusinessHourActionType.ASSISTANT,
  },
  enabled: false,
  type: ConnectChannel.IncomingCallStepType.BUSINESS_HOURS,
}

const CALL_FORWARDING_DEFAULT_VALUES: ConnectChannel.IncomingCallStepCallForwarding = {
  enabled: false,
  type: ConnectChannel.IncomingCallStepType.CALL_FORWARDING,
  ringDuration: RING_DURATION_VALUE_DEFAULT,
}

const RING_MEMBER_DEFAULT_VALUES: ConnectChannel.IncomingCallStepRing = {
  enabled: false,
  steps: [ {
    accountIds: [],
    ringDuration: RING_DURATION_VALUE_DEFAULT,
  } ],
  type: ConnectChannel.IncomingCallStepType.RING,
}

interface FormValues {
  [ IncomingCallSettingsFormFieldKeys.BUSINESS_HOURS_ACTION_TYPE ]: ConnectChannel.BusinessHourActionType
  [ IncomingCallSettingsFormFieldKeys.BUSINESS_HOURS_ENABLED ]: boolean
  [ IncomingCallSettingsFormFieldKeys.BUSINESS_HOURS_FORWARD_PHONE_NUMBER ]?: string
  [ IncomingCallSettingsFormFieldKeys.BUSINESS_HOURS_FORWARD_RING_DURATION ]?: string
  [ IncomingCallSettingsFormFieldKeys.CALL_FORWARDING_ENABLED ]: boolean
  [ IncomingCallSettingsFormFieldKeys.CALL_FORWARDING_RING_DURATION ]?: string
  [ IncomingCallSettingsFormFieldKeys.CALL_FORWARDING_PHONE_NUMBER ]?: string
  [ IncomingCallSettingsFormFieldKeys.RING_DURATION ]?: string
  [ IncomingCallSettingsFormFieldKeys.RING_ENABLED ]: boolean
}

export interface IncomingCallPageProps {

}

export const IncomingCallPage: React.FC<IncomingCallPageProps> = ( props ) => {
  const dispatch = useAsyncDispatch()
  const { dashboardContext } = useDashboardContext()
  const featurePhoneCallEnabled = useConnectIsPhoneEnabled()
  const [ currentChannel, setCurrentChannel ] = useState<ConnectChannel | null>( null )
  const [ selectedAccountIds, setSelectedAccountIds ] = useState<string[]>( [] )
  const connectAssistant = useSelector( connectSelectors.assistant.current )
  const selectedMerchantId = useSelector( commonSelectors.selectedMerchantId )
  const { showSuccess, showError } = useAlert()
  const shouldDisplayWhitelistWarning = useMemo( () => {
    if( isEmpty( connectAssistant?.whitelist ) ) {
      return false
    }

    return true
  }, [ connectAssistant ] )

  const parseIncomingCallStepByType = useCallback( <T extends ConnectChannel.IncomingCallStep>( stepType: string ): T => {
    return currentChannel?.incomingCallSteps?.find( ( { type } ) => type === stepType ) as T
  }, [ currentChannel?.incomingCallSteps ] )

  const getBusinessHourStepValue = useCallback( ( fieldPath: string, defaultValue?: number ) => {
    const businessHoursStep = parseIncomingCallStepByType<ConnectChannel.IncomingCallStepBusinessHours>(
      ConnectChannel.IncomingCallStepType.BUSINESS_HOURS,
    )
    return get( businessHoursStep, fieldPath ) ?? get( BUSINESS_HOUR_DEFAULT_VALUES, fieldPath, defaultValue )
  }, [ parseIncomingCallStepByType ] )

  const getCallForwardingStepValue = useCallback( ( fieldPath: string ) => {
    const callForwardingStep = parseIncomingCallStepByType<ConnectChannel.IncomingCallStepCallForwarding>(
      ConnectChannel.IncomingCallStepType.CALL_FORWARDING,
    )
    return get( callForwardingStep, fieldPath ) ?? get( CALL_FORWARDING_DEFAULT_VALUES, fieldPath )
  }, [ parseIncomingCallStepByType ] )

  const getRingStepValue = useCallback( ( fieldPath: string, defaultValue?: number ) => {
    const ringStep = parseIncomingCallStepByType<ConnectChannel.IncomingCallStepRing>(
      ConnectChannel.IncomingCallStepType.RING,
    )
    return get( ringStep, fieldPath ) ?? get( RING_MEMBER_DEFAULT_VALUES, fieldPath, defaultValue )
  }, [ parseIncomingCallStepByType ] )

  const formMethods = useForm<FormValues>( {
    mode: "all",
    defaultValues: {
      [ IncomingCallSettingsFormFieldKeys.BUSINESS_HOURS_FORWARD_RING_DURATION ]: getBusinessHourStepValue( "action.ringDuration", RING_DURATION_VALUE_DEFAULT ),
      [ IncomingCallSettingsFormFieldKeys.BUSINESS_HOURS_ENABLED ]: getBusinessHourStepValue( "enabled" ),
      [ IncomingCallSettingsFormFieldKeys.CALL_FORWARDING_ENABLED ]: getCallForwardingStepValue( "enabled" ),
      [ IncomingCallSettingsFormFieldKeys.CALL_FORWARDING_PHONE_NUMBER ]: getCallForwardingStepValue( "phoneNumber.value" ),
      [ IncomingCallSettingsFormFieldKeys.CALL_FORWARDING_RING_DURATION ]: getCallForwardingStepValue( "ringDuration" ),
      [ IncomingCallSettingsFormFieldKeys.RING_DURATION ]: getRingStepValue( "steps[ 0 ].ringDuration", RING_DURATION_VALUE_DEFAULT ),
      [ IncomingCallSettingsFormFieldKeys.RING_ENABLED ]: getRingStepValue( "enabled" ),
    },
    shouldUnregister: true,
  } )
  const { handleSubmit, setValue } = formMethods

  useEffect( () => {
    if( currentChannel?.incomingCallSteps?.length !== undefined ) {
      setValue( IncomingCallSettingsFormFieldKeys.BUSINESS_HOURS_ACTION_TYPE, getBusinessHourStepValue( "action.type" ) )
      setValue( IncomingCallSettingsFormFieldKeys.BUSINESS_HOURS_ENABLED, getBusinessHourStepValue( "enabled" ) )
      setValue( IncomingCallSettingsFormFieldKeys.BUSINESS_HOURS_FORWARD_PHONE_NUMBER, getBusinessHourStepValue( "action.phoneNumber.value" ) )
      setValue( IncomingCallSettingsFormFieldKeys.BUSINESS_HOURS_FORWARD_RING_DURATION, getBusinessHourStepValue( "action.ringDuration", RING_DURATION_VALUE_DEFAULT ) )
      setValue( IncomingCallSettingsFormFieldKeys.CALL_FORWARDING_ENABLED, getCallForwardingStepValue( "enabled" ) )
      setValue( IncomingCallSettingsFormFieldKeys.CALL_FORWARDING_PHONE_NUMBER, getCallForwardingStepValue( "phoneNumber.value" ) )
      setValue( IncomingCallSettingsFormFieldKeys.CALL_FORWARDING_RING_DURATION, getCallForwardingStepValue( "ringDuration" ) )
      setValue( IncomingCallSettingsFormFieldKeys.RING_ENABLED, getRingStepValue( "enabled" ) )

      const ringSteps: ConnectChannel.RingStep[] = getRingStepValue( "steps" )

      if( ringSteps?.length > 0 ) {
        setSelectedAccountIds( ringSteps[ 0 ].accountIds || [] )
        setValue( IncomingCallSettingsFormFieldKeys.RING_DURATION, `${ ringSteps[ 0 ].ringDuration || RING_DURATION_VALUE_DEFAULT }` )
      }
    }
  }, [ currentChannel, getBusinessHourStepValue, getCallForwardingStepValue, getRingStepValue, setValue ] )

  const onSave = useCallback( async ( data: FormValues ) => {
    const callForwardingEnabled = data[ IncomingCallSettingsFormFieldKeys.CALL_FORWARDING_ENABLED ]
    const businessHourEnabled = data[ IncomingCallSettingsFormFieldKeys.BUSINESS_HOURS_ENABLED ]
    const ringMemberEnabled = data[ IncomingCallSettingsFormFieldKeys.RING_ENABLED ]

    mixpanelHelpers.trackEvent(
      AnalyticHelper.EventName.CTA_CLICKED,
      {
        "Name": "Save",
        "Location": "Settings/LocalMessages/Incoming Calls",
        "Call Forwarding Enabled": callForwardingEnabled ? "Y" : "N",
        "Business Hours Enabled": businessHourEnabled ? "Y" : "N",
        "Ring Members Enabled": ringMemberEnabled ? "Y" : "N",
        "Product": ProductNames.LOCAL_MESSAGES,
      },
    )

    if( ringMemberEnabled && isEmpty( selectedAccountIds ) ) {
      showError( "At least one member should be selected to enable Ring Member." )
      return
    }

    try {
      const model: ConnectChannel.UpdateModel = {
        incomingCallSteps: [
          {
            enabled: callForwardingEnabled,
            id: getCallForwardingStepValue( "id" ),
            phoneNumber: data[ IncomingCallSettingsFormFieldKeys.CALL_FORWARDING_PHONE_NUMBER ],
            ringDuration: (
              data[ IncomingCallSettingsFormFieldKeys.CALL_FORWARDING_RING_DURATION ]
                ? parseInt( data[ IncomingCallSettingsFormFieldKeys.CALL_FORWARDING_RING_DURATION ]!, 10 )
                : null
            ),
            type: ConnectChannel.IncomingCallStepType.CALL_FORWARDING,
          },
          {
            enabled: ringMemberEnabled,
            id: getRingStepValue( "id" ),
            steps: [
              {
                accountIds: selectedAccountIds,
                ringDuration: (
                  data[ IncomingCallSettingsFormFieldKeys.RING_DURATION ]
                    ? parseInt( data[ IncomingCallSettingsFormFieldKeys.RING_DURATION ]!, 10 )
                    : null
                ),
              },
            ],
            type: ConnectChannel.IncomingCallStepType.RING,
          },
        ],
      }

      const businessHourStep: ConnectChannel.UpdateModel.IncomingCallStepBusinessHoursModel = {
        enabled: businessHourEnabled,
        id: getBusinessHourStepValue( "id" ),
        type: ConnectChannel.IncomingCallStepType.BUSINESS_HOURS,
      }

      if( businessHourStep.enabled ) {
        if( data[ IncomingCallSettingsFormFieldKeys.BUSINESS_HOURS_ACTION_TYPE ] === ConnectChannel.BusinessHourActionType.FORWARD ) {
          businessHourStep.action = {
            phoneNumber: data[ IncomingCallSettingsFormFieldKeys.BUSINESS_HOURS_FORWARD_PHONE_NUMBER ] || "",
            ringDuration: (
              data[ IncomingCallSettingsFormFieldKeys.BUSINESS_HOURS_FORWARD_RING_DURATION ]
                ? parseInt( data[ IncomingCallSettingsFormFieldKeys.BUSINESS_HOURS_FORWARD_RING_DURATION ]!, 10 )
                : null
            ),
            type: ConnectChannel.BusinessHourActionType.FORWARD,
          }
        } else {
          businessHourStep.action = {
            type: ConnectChannel.BusinessHourActionType.ASSISTANT,
          }
        }
      }

      model.incomingCallSteps?.push( businessHourStep )
      const updatedChannel = await dispatch( connectActions.channel.update( dashboardContext.merchantId, model ) )
      setCurrentChannel( updatedChannel )
      showSuccess( "Changes saved" )
    } catch( err ) {
      showError( err )
    }
  }, [ dashboardContext.merchantId, dispatch, getBusinessHourStepValue, getCallForwardingStepValue, getRingStepValue, selectedAccountIds, showError, showSuccess ] )

  const onInvalid = useCallback( () => {
    showError( "Please make sure all required fields are filled." )
  }, [ showError ] )

  useEffect( () => {
    const run = async () => {
      try {
        const channel = await dispatch( connectActions.channel.get( dashboardContext.merchantId ) )
        setCurrentChannel( channel )
        await dispatch( connectActions.assistant.get( dashboardContext.merchantId, channel.phoneNumberId ) )
      } catch( err ) {
        showError( err )
      }
    }
    run()
  }, [ dashboardContext.merchantId, dispatch, showError ] )

  useEffect( () => {
    mixpanelHelpers.trackEvent(
      AnalyticHelper.EventName.PAGE_OPENED,
      {
        "Name": "Settings/LocalMessages/Incoming Calls",
        "Product": ProductNames.LOCAL_MESSAGES,
      },
    )
  }, [] )

  return (
    <FormProvider { ...formMethods }>
      <Page
        buttons={ [
          {
            renderButton: ( key ) => (
              <SaveButton
                key={ key }
                onClick={ handleSubmit( onSave, onInvalid ) }
              />
            ),
          },
        ] }
        parentPaths={ [
          { name: "Settings", path: `${ DashboardRoutePath.SETTINGS }?merchant_id=${ selectedMerchantId }` },
          { name: "LocalMessages", path: `${ DashboardRoutePath.MESSAGES_SETTINGS }?merchant_id=${ selectedMerchantId }` },
        ] }
        title="Incoming Call"
      >
        <Container>
          <StyledSmallContainer>
            <PhoneIcon />
            <SmallContainerTitle>Incoming Call</SmallContainerTitle>
          </StyledSmallContainer>
          <FlowDownArrow />
          {
            shouldDisplayWhitelistWarning && (
              <>
                <Box
                  containerStyle={ {
                    backgroundColor: styleHelpers.colors.warningLight,
                    color: styleHelpers.colors.warningDark,
                    display: "flex",
                    flexDirection: "row",
                    padding: "14px 16px",
                  } }
                >
                  <WarningIcon />
                  <div style={ { marginLeft: 12 } }>
                    { `Incoming calls will only go through the following steps if they are not forwarded from ${ connectAssistant?.whitelist?.join( ", " ) }.` }
                  </div>
                </Box>
                <FlowDownArrow />
              </>
            )
          }
          <IncomingCallCallForwarding getFieldData={ getCallForwardingStepValue }/>
          <FlowDownArrow />
          <IncomingCallBusinessHours getFieldData={ getBusinessHourStepValue } />
          <FlowDownArrow />
          {
            featurePhoneCallEnabled && (
              <>
                <IncomingCallRingMember selectedAccountIds={ selectedAccountIds } setSelectedAccountIds={ setSelectedAccountIds }/>
                <FlowDownArrow />
              </>
            )
          }
          <StyledSmallContainer>
            {
              isEmpty( connectAssistant?.flows )
                ? (
                  <>
                    <VoicemailIcon style={ { marginRight: 5, marginTop: 3 } }/>
                    <SmallContainerTitle>Voicemail</SmallContainerTitle>
                  </>
                )
                : (
                  <>
                    <VirtualAssistantIcon />
                    <SmallContainerTitle>LocalResponse</SmallContainerTitle>
                  </>
                )
            }
          </StyledSmallContainer>
        </Container>
      </Page>
    </FormProvider>
  )
}

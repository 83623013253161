// eslint-disable-next-line @nx/enforce-module-boundaries
import type { MerchantSettings } from "@onelocal/frontend-dashboard/settings"

export interface ContentMerchantSettings {
  status: MerchantSettings.ProductStatus.LIVE | null
}

export const enum ContentPublishDateType {
  CUSTOM = "custom",
  OPTIMAL = "optimal",
  NOW = "now",
}

export type ContentPublishDateValue = ContentPublishDateType.OPTIMAL | ContentPublishDateType.NOW | string

export interface ContentBlogPostBase {
  assistant: {
    threadId: string
  }
  contentHtml: string
  id: string
  images: {
    duda: {
      imageId: string
      url: string
    } | null
    imageId: string | null
    keywords: string[] | null
    purchased: ContentBlogPost.ImagePurchased | null
    suggestions: ContentBlogPost.ImageSuggestion[]
    uploaded: ContentBlogPost.ImageUploaded | null
  }
  isSuggestion: boolean
  merchantId: string
  origin: ContentBlogPost.Origin
  publishDate: string
  status: ContentBlogPost.Status
  summary: string
  title: string
}

export interface ContentBlogPostCommon extends ContentBlogPostBase {
  origin: ContentBlogPost.Origin.SCHEDULED | ContentBlogPost.Origin.MANUAL
}
export interface ContentBlogPostSuggestion extends ContentBlogPostBase {
  draft: ContentBlogPost.Content | null
  keyword?: string
  optimizedForSeo?: boolean | null
  origin: ContentBlogPost.Origin.SUGGESTION
}

export type ContentBlogPost = ContentBlogPostCommon | ContentBlogPostSuggestion

export namespace ContentBlogPost {
  export interface Content {
    contentHtml: string
    optimizedForSeo: boolean | null
    title: string
  }
  export interface ImagePurchased {
    id: string
    shutterstock: {
      id: string
    }
    type: "shutterstock"
    url: string
  }

  export interface ImageSuggestion {
    id: string
    shutterstock: {
      id: string
    }
    type: "shutterstock"
    url: {
      small: string
      large: string
    }
  }

  export interface ImageUploaded {
    id: string
    filename: string
    originalUrl: string
  }

  export const enum Origin {
    MANUAL = "manual",
    SCHEDULED = "scheduled",
    SUGGESTION = "suggestion",
  }

  export const enum Status {
    APPROVED = "approved",
    GENERATING = "generating",
    PUBLISHED = "published",
    WAITING_APPROVAL = "waiting_approval",
  }
}

export interface ContentItemBlogPost {
  blogPost: ContentBlogPost
  type: ContentItem.Type.BLOG_POST
}

export interface ContentItemSocialPostSuggestion {
  socialPostSuggestion: ContentSocialPostSuggestion
  type: ContentItem.Type.SOCIAL_POST_SUGGESTION
}

export type ContentItem = (
  ContentItemBlogPost
  | ContentItemSocialPostSuggestion
)

export namespace ContentItem {
  export const enum Type {
    BLOG_POST = "blog_post",
    SOCIAL_POST_SUGGESTION = "social_post_suggestion",
  }
}
export interface ContentScheduledItemBase {
  date: string
  type: ContentScheduledItem.Type
}

export interface ContentScheduledItemBlogPost extends ContentScheduledItemBase {
  blogPost: ContentBlogPost
  type: ContentScheduledItem.Type.BLOG_POST
}

export interface ContentScheduledItemSocialPost extends ContentScheduledItemBase {
  socialPost: ContentSocialPost
  type: ContentScheduledItem.Type.SOCIAL_POST
}

export type ContentScheduledItem = (
  ContentScheduledItemBlogPost
  | ContentScheduledItemSocialPost
)

export namespace ContentScheduledItem {
  export enum Type {
    BLOG_POST = "blog_post",
    SOCIAL_POST = "social_post",
  }
}

export interface ContentSocialPost {
  created: {
    at: string
  }
  id: string
  images: ContentSocialPost.Image[]
  merchantId: string
  platform: {
    [ ContentSocialPost.PlatformType.FACEBOOK ]: ContentSocialPost.PlatformFacebook
    [ ContentSocialPost.PlatformType.GOOGLE ]: ContentSocialPost.PlatformGoogle
    [ ContentSocialPost.PlatformType.INSTAGRAM ]: ContentSocialPost.PlatformInstagram
  }
  publishDate: string
  socialPostSuggestionId: string | null
  status: {
    at: string
    details?: string
    logs: ContentSocialPost.StatusLog[]
    type: ContentSocialPost.StatusType
  }
  text: string
}

export namespace ContentSocialPost {
  export type Image = (
    ImageCustom
    | ImageShutterstock
    | ImageUploaded
  )

  export namespace Image {
    export const enum Type {
      CUSTOM = "custom",
      SHUTTERSTOCK = "shutterstock",
      UPLOADED = "uploaded",
    }
  }

  export interface ImageBase {
    id: string
    tags?: string[]
    type: Image.Type
    url: {
      small: string
      large: string
    }
  }

  export interface ImageCustom extends ImageBase {
    type: Image.Type.CUSTOM
  }

  export interface ImageShutterstock extends ImageBase {
    shutterstock: {
      id: string
    }
    type: Image.Type.SHUTTERSTOCK
  }

  export interface ImageUploaded extends ImageBase {
    filename: string
    type: Image.Type.UPLOADED
  }

  export const enum PlatformStatusType {
    FAILED = "failed",
    POSTED = "posted",
    SCHEDULED = "scheduled",
  }

  export const enum PlatformType {
    FACEBOOK = "facebook",
    GOOGLE = "google",
    INSTAGRAM = "instagram",
  }

  export interface PlatformBase {
    enabled: boolean
    status: {
      type: PlatformStatusType
      details: string | null
    }
  }

  export interface PlatformFacebook extends PlatformBase {
    post?: {
      facebookPostId: string
    }
  }

  export interface PlatformGoogle extends PlatformBase {
    post?: {
      googlePostId: string
    }
  }

  export interface PlatformInstagram extends PlatformBase {
    post?: {
      instagramPostId: string
    }
  }

  export interface StatusLog {
    date: string
    details?: string
    type: StatusType
  }

  export const enum StatusType {
    PUBLISHED = "published",
    SCHEDULED = "scheduled",
    // APPROVED = "approved",
    // GENERATING = "generating",
    // /** Preview blog post are use for blog post that a generated for a suggestion in the activity feed */
    // PREVIEW = "preview",
    // PUBLISHED = "published",
    // WAITING_APPROVAL = "waiting_approval",
  }
}

export interface ContentSocialPostSuggestionBase {
  id: string
  image: {
    ids: string[]
    suggestions: ContentSocialPostSuggestion.ImageSuggestion[]
    uploaded: ContentSocialPostSuggestion.ImageUploaded[]
  }
  merchantId: string
  platform: Record<ContentSocialPost.PlatformType, ContentSocialPostSuggestion.Platform>
  publishDate: {
    optimal: string
    at?: string
    value: ContentPublishDateType.OPTIMAL | ContentPublishDateType.NOW | string
  }
  socialPostId: string | null
  status: {
    at: string
    details?: string
    logs: ContentSocialPostSuggestion.StatusLog[]
    type: ContentSocialPostSuggestion.StatusType
  }
  text: string
  textOriginal: string
  type: ContentSocialPostSuggestion.Type
}

export interface ContentSocialPostSuggestionBeforeAfterPhotos extends ContentSocialPostSuggestionBase {
  model: {
    afterImage?: ContentSocialPostSuggestion.ImageUploaded
    beforeImage?: ContentSocialPostSuggestion.ImageUploaded
    description?: string
  }
  type: ContentSocialPostSuggestion.Type.BEFORE_AFTER_PHOTOS
}

export interface ContentSocialPostSuggestionBlogPost extends ContentSocialPostSuggestionBase {
  blogPostId: string
  type: ContentSocialPostSuggestion.Type.BLOG_POST
}

export interface ContentSocialPostSuggestionBulkMessage extends ContentSocialPostSuggestionBase {
  bulkMessage: {
    id: string
    date: string
    senderName: string
    text: string
  }
  type: ContentSocialPostSuggestion.Type.BULK_MESSAGE
}

export interface ContentSocialPostSuggestionPhotos extends ContentSocialPostSuggestionBase {
  model: {
    description?: string
  }
  type: ContentSocialPostSuggestion.Type.PHOTOS
}

export interface ContentSocialPostSuggestionReview extends ContentSocialPostSuggestionBase {
  review: {
    id: string
    date: string
    name: string
    rating: number
    source: string
    text: string
  }
  type: ContentSocialPostSuggestion.Type.REVIEW
}

export type ContentSocialPostSuggestion = (
  ContentSocialPostSuggestionBeforeAfterPhotos
  | ContentSocialPostSuggestionBlogPost
  | ContentSocialPostSuggestionBulkMessage
  | ContentSocialPostSuggestionPhotos
  | ContentSocialPostSuggestionReview
)

export namespace ContentSocialPostSuggestion {
  export type ImageSuggestion = (
    ImageSuggestionCustom
    | ImageSuggestionShutterstock
  )

  export namespace ImageSuggestion {
    export const enum Type {
      CUSTOM = "custom",
      SHUTTERSTOCK = "shutterstock",
    }
  }

  export interface ImageSuggestionBase {
    id: string
    tags?: string[]
    type: ImageSuggestion.Type
    url: {
      small: string
      large: string
    }
  }

  export interface ImageSuggestionCustom extends ImageSuggestionBase {
    type: ImageSuggestion.Type.CUSTOM
  }

  export interface ImageSuggestionShutterstock extends ImageSuggestionBase {
    shutterstock: {
      id: string
    }
    type: ImageSuggestion.Type.SHUTTERSTOCK
  }

  export interface ImageUploaded {
    id: string
    filename: string
    originalUrl: string
    tags?: string[]
  }

  export interface Platform {
    enabled: boolean
    error?: string
  }

  export type PlatformType = ContentSocialPost.PlatformType

  export interface StatusLog {
    date: string
    details?: string
    type: StatusType
  }

  export const enum StatusType {
    ERROR = "error",
    /** There is some information required under the model that need to be fill before the generation can happen */
    PENDING = "pending",
    /** The generation is processing */
    PROCESSING = "processing",
    /** The post is ready and need to be scheduled by the user */
    READY = "ready",
    /** The post has been scheduled for the user */
    SCHEDULED = "scheduled",
  }

  export const enum Type {
    BEFORE_AFTER_PHOTOS = "before_after_photos",
    BLOG_POST = "blog_post",
    BULK_MESSAGE = "bulk_message",
    PHOTOS = "photos",
    REVIEW = "review",
  }
}


import { useCallback } from "react"

import { useAsyncDispatch } from "@onelocal/frontend/common"
import { useDashboardContext } from "@onelocal/frontend-dashboard/common"
import type { SiteBlogPost } from "@onelocal/frontend-dashboard/site"
import { BlogPost, siteActions } from "@onelocal/frontend-dashboard/site"
import { useAlert } from "@onelocal/frontend-web/common"

const PAST_VERB_BY_ACTION: Record<BlogPost.Actions, string> = {
  [ BlogPost.Actions.APPROVE ]: "approved",
  [ BlogPost.Actions.GENERATE_IMAGES ]: "generated",
  [ BlogPost.Actions.PUBLISH ]: "published",
  [ BlogPost.Actions.REGENERATE ]: "regenerated",
}

export const useBlogPostAction = ( blogPost: SiteBlogPost | null ) => {
  const dispatch = useAsyncDispatch()
  const { showError, showSuccess } = useAlert()
  const { dashboardContext } = useDashboardContext()

  const onAction = useCallback( async ( actionType: BlogPost.Actions ) => {
    if( ! blogPost ) {
      return
    }

    try {
      switch( actionType ) {
        case BlogPost.Actions.APPROVE:
          await dispatch( siteActions.blogPosts.approveBlogPost( dashboardContext.merchantId, blogPost.id ) )
          break
        case BlogPost.Actions.GENERATE_IMAGES:
          await dispatch( siteActions.blogPosts.generateSuggestedImages( dashboardContext.merchantId, blogPost.id ) )
          showSuccess( "Images are generating" )
          return
        case BlogPost.Actions.PUBLISH:
          await dispatch( siteActions.blogPosts.publishBlogPost( dashboardContext.merchantId, blogPost.id ) )
          break
        case BlogPost.Actions.REGENERATE:
          await dispatch( siteActions.blogPosts.generateContent( dashboardContext.merchantId, blogPost.id ) )
          showSuccess( "Blog Post is generating" )
          return
      }

      showSuccess( `Blog Post was ${ PAST_VERB_BY_ACTION[ actionType ] } successfully` )
    } catch( err ) {
      showError( err ?? `There was an error when trying to ${ actionType } the Blog Post` )
    }
  }, [ dashboardContext.merchantId, dispatch, blogPost, showError, showSuccess ] )

  const onApprove = useCallback( async () => {
    await onAction( BlogPost.Actions.APPROVE )
  }, [ onAction ] )

  const onGenerateImages = useCallback( async () => {
    await onAction( BlogPost.Actions.GENERATE_IMAGES )
  }, [ onAction ] )

  const onPublish = useCallback( async () => {
    await onAction( BlogPost.Actions.PUBLISH )
  }, [ onAction ] )

  const onRegenerate = useCallback( async () => {
    await onAction( BlogPost.Actions.REGENERATE )
  }, [ onAction ] )

  return {
    onApprove,
    onGenerateImages,
    onPublish,
    onRegenerate,
  }
}

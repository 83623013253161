// eslint-disable-next-line @nx/enforce-module-boundaries
import type { MerchantSettings } from "@onelocal/frontend-dashboard/settings"
import type { ReferralsMerchantSettings } from "."

export interface ApiReferralsMerchantSettings {
  status: MerchantSettings.ProductStatus
}

export function parseReferralsMerchantSettingsFromApi( apiMerchantSettings: ApiReferralsMerchantSettings ) {
  const merchantSettings: ReferralsMerchantSettings = {
    status: apiMerchantSettings.status || null,
  }

  return merchantSettings
}

/* eslint-disable no-restricted-imports */
import CheckIcon from "@mui/icons-material/Check"
import Chip from "@mui/material/Chip"
import { indexOf } from "lodash"
import React, { useCallback, useMemo, useState } from "react"
import { JSONTree } from "react-json-tree"
import styled from "styled-components"

import { useBlogPost, useSocialPostSuggestion } from "@onelocal/frontend-admin-web/content"
import type { DialogAction } from "@onelocal/frontend-web/common"
import { Dialog, styleHelpers, useModalWithParams } from "@onelocal/frontend-web/common"
import { dateHelpers } from "@onelocal/shared/common"
import { Activity } from "../types"

const ContentHtmlContainer = styled.div`
  margin-top: 20px;
  overflow-x: hidden;
`

const ContentCoverImage = styled.img`
  display: flex;
  margin-bottom: 20px;
  margin: auto;
  max-height: 300px;
  width: auto;
`

const SubTitle = styled.p`
  color: ${ styleHelpers.colors.darkGray };
  font-size: 12px;
  margin: 0;
  text-align: center;
`

const Title = styled.p`
  margin-bottom: 16px;
  margin-top: 0;
  text-align: center;
`

const StyledInfoBanner = styled.div`
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${ styleHelpers.colors.borderGray };
  color: ${ styleHelpers.colors.darkGray };
  display: flex;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`

const StyledCheckIcon = styled( CheckIcon )`
  height: 18px;
  width: 18px;
`

const StyledInfoBannerHeader = styled.div`
  align-items: center;
  background-color: #EEF7FE;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  width: 100%;
`

const StyledInfoBannerColumn = styled.div`
  align-items: center;
  background-color: transparent;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  width: 100%;
`

const StyledInfoBannerHeaderTitle = styled.span`
  color: ${ styleHelpers.colors.dark };
  font-size: 14px;
  font-weight: 400;
  padding: 16px;
  width: 25%;
`

const StyledInfoBannerColumnValue = styled.span`
  color: ${ styleHelpers.colors.dark };
  font-size: 12px;
  font-weight: 400;
  padding: 16px;
  width: 25%;
`

const ViewModeSelector = styled.button<{ isActive: boolean }>`
  background-color: transparent;
  border: none;
  color: ${ ( { isActive } ) => isActive ? styleHelpers.colors.primary : styleHelpers.colors.darkGray };
  cursor: pointer;
  text-decoration: underline;
  font-weight: 500;
`

const ViewModeSelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`

const StyledChip = styled( Chip )`
  margin-right: 5px;
`

export interface ReviewActivityModalProps {
  isOpen: boolean
  onClose( reOpen?: boolean ): void
  onExited: () => void
  onSave( { activityId, approvalStatus }: { activityId: string, approvalStatus: Activity.ApprovalStatus } ): void
  activities: Activity[]
  selectedActivity: Activity
}

enum CONTENT_VIEW_MODE {
  CONTENT = "Content",
  JSON = "JSON",
}

const infoBannerHeaders = [
  "Merchant",
  "Category",
  "Last Update",
  "Status",
]

interface DeliveryActivityReviewModalHeaderProps {
  activity: Activity
}

const DeliveryActivityReviewModalHeader = ( { activity }: DeliveryActivityReviewModalHeaderProps ) => {
  const infoBannerValues = useMemo( () => {
    return {
      merchantName: activity.merchantName,
      type: Activity.TypeLabelMap[ activity.type ],
      date: dateHelpers.formatToDateValue( activity.date ),
      approval: Activity.ApprovalStatusLabel[ activity.approval ],
    }
  }, [ activity ] )

  return (
    <StyledInfoBanner>
      <StyledInfoBannerHeader>
        {
          infoBannerHeaders.map( ( header ) => (
            <StyledInfoBannerHeaderTitle key={ header }>
              { header }
            </StyledInfoBannerHeaderTitle>
          ) )
        }
      </StyledInfoBannerHeader>

      <StyledInfoBannerColumn>
        <StyledInfoBannerColumnValue>
          { infoBannerValues.merchantName }
        </StyledInfoBannerColumnValue>
        <StyledInfoBannerColumnValue>
          { infoBannerValues.type }
        </StyledInfoBannerColumnValue>
        <StyledInfoBannerColumnValue>
          { infoBannerValues.date }
        </StyledInfoBannerColumnValue>
        <StyledInfoBannerColumnValue>
          <StyledChip label={ infoBannerValues.approval } />
        </StyledInfoBannerColumnValue>
      </StyledInfoBannerColumn>
    </StyledInfoBanner>
  )
}

interface ActivityBlogPostPreviewProps {
  blogPostId: string
  merchantId: string
  viewMode: CONTENT_VIEW_MODE
}

const ActivityBlogPostPreview = ( { merchantId, blogPostId, viewMode }: ActivityBlogPostPreviewProps ) => {
  const { blogPost } = useBlogPost( merchantId, blogPostId )

  const imageUrl = useMemo( () => {
    return blogPost?.images.suggestions.find( i => i.url )?.url.small
  }, [ blogPost ] )

  if( ! blogPost ) {
    return null
  }

  if( viewMode === CONTENT_VIEW_MODE.JSON && blogPost ) {
    return (
      <JSONTree
        data={ blogPost }
        invertTheme={ false }
        theme={ { tree: { padding: "12px 36px 24px 12px" } } }
      />
    )
  }

  return (
    <div>
      {
        imageUrl && (
          <ContentCoverImage alt="" src={ imageUrl } />
        )
      }
      {
        blogPost.contentHtml && <ContentHtmlContainer dangerouslySetInnerHTML={ { __html: blogPost.contentHtml } }></ContentHtmlContainer>
      }
    </div>
  )
}

interface ActivitySocialPostSuggestionPreviewProps {
  merchantId: string
  socialPostSuggestionId: string
  viewMode: CONTENT_VIEW_MODE
}

const ActivitySocialPostSuggestionPreview = ( { merchantId, socialPostSuggestionId, viewMode }: ActivitySocialPostSuggestionPreviewProps ) => {
  const { socialPostSuggestion } = useSocialPostSuggestion( merchantId, socialPostSuggestionId )
  const imageUrl = useMemo( () => {
    return socialPostSuggestion?.image.suggestions.find( image => image.url )?.url.small
  }, [ socialPostSuggestion ] )

  if( viewMode === CONTENT_VIEW_MODE.JSON && socialPostSuggestion ) {
    return (
      <JSONTree
        data={ socialPostSuggestion }
        invertTheme={ false }
        theme={ { tree: { padding: "12px 36px 24px 12px" } } }
      />
    )
  }

  return (
    <div>
      {
        imageUrl && (
          <ContentCoverImage alt="" src={ imageUrl } />
        )
      }

      <div>{ socialPostSuggestion?.text }</div>
    </div>
  )
}

const ActivityReviewContent = ( { activity, viewMode }: { activity: Activity, viewMode: CONTENT_VIEW_MODE } ) => {
  switch( activity.type ) {
    case Activity.Type.KEYWORD_RANKING_BLOG:
    case Activity.Type.SHARE_BLOG_POST:
      return (
        <ActivityBlogPostPreview
          merchantId={ activity.merchantId }
          blogPostId={ activity.blogPostId }
          viewMode={ viewMode }
        />
      )
    case Activity.Type.SHARE_BEFORE_AFTER_PHOTOS:
    case Activity.Type.SHARE_BULK_MESSAGE:
    case Activity.Type.SHARE_PHOTOS:
    case Activity.Type.SHARE_REVIEW:
      return (
        activity.socialPostSuggestionId ? (
          <ActivitySocialPostSuggestionPreview
            merchantId={ activity.merchantId }
            socialPostSuggestionId={ activity.socialPostSuggestionId }
            viewMode={ viewMode }
          />
        ) : null
      )
    default:
      return null
  }
}

export const ReviewActivityModal: React.FC<ReviewActivityModalProps> = ( {
  isOpen,
  onClose,
  onExited,
  onSave,
  activities,
  selectedActivity,
} ) => {
  const [ viewMode, setViewMode ] = useState<CONTENT_VIEW_MODE>( CONTENT_VIEW_MODE.CONTENT )
  const [ activeItemIndex, setActiveItemIndex ] = useState( indexOf( activities, selectedActivity ) )

  const activity = useMemo( () =>
    activities[ activeItemIndex ], [ activeItemIndex, activities ],
  )

  const modalActions: DialogAction[] = useMemo( () => [
    {
      onClick: ()=> {
        onSave( {
          activityId: activity?.id,
          approvalStatus: Activity.ApprovalStatus.REJECTED,
        } )

        if( activeItemIndex === activities.length - 1 ) {
          onClose()
        } else {
          setActiveItemIndex( ( prev ) => prev + 1 )
        }
      },
      title: "Reject",
      type: "destructive",
    },
    {
      icon: ( <StyledCheckIcon /> ),
      onClick: ()=> {
        onSave( {
          activityId: activity?.id,
          approvalStatus: Activity.ApprovalStatus.APPROVED,
        } )

        if( activeItemIndex === activities.length - 1 ) {
          onClose()
        } else {
          setActiveItemIndex( ( prev ) => prev + 1 )
        }
      },
      title: "Approve",
      type: "primary",
    },
  ], [ activeItemIndex, activities.length, onClose, onSave, activity ] )

  const subTitle = useMemo( () => {
    return activities.length > 1 ? `${ activeItemIndex + 1 }/${ activities.length }` : ""
  }, [ activeItemIndex, activities.length ] )

  const onChangeViewMode = useCallback( ( mode: CONTENT_VIEW_MODE ) => {
    setViewMode( mode )
  }, [] )

  return (
    <Dialog
      actions={ modalActions }
      contentContainerStyle={ {
        paddingLeft: "24px",
        paddingRight: "24px",
        width: "550px",
      } }
      isOpen={ isOpen }
      onClose={ onClose }
      onExited={ onExited }
      showDividers={ false }
      subTitle={ <SubTitle>{ subTitle }</SubTitle> }
      title={ <Title>Content Review</Title> }
    >

      {
        activity && (
          <DeliveryActivityReviewModalHeader activity={ activity } />
        )
      }

      {
        activity && (
          <ViewModeSelectorContainer>
            <ViewModeSelector
              onClick={ () => onChangeViewMode( CONTENT_VIEW_MODE.CONTENT ) }
              isActive={ viewMode === CONTENT_VIEW_MODE.CONTENT }
            >
                Content
            </ViewModeSelector>
            &nbsp;&#47;&nbsp;
            <ViewModeSelector
              onClick={ () => onChangeViewMode( CONTENT_VIEW_MODE.JSON ) }
              isActive={ viewMode === CONTENT_VIEW_MODE.JSON }
            >
                JSON
            </ViewModeSelector>
          </ViewModeSelectorContainer>
        )
      }

      {
        activity && (
          <ActivityReviewContent
            activity={ activity }
            viewMode={ viewMode }
          />
        )
      }
    </Dialog>
  )
}

export interface useReviewActivityModalParams {
  onSave( { activityId, approvalStatus }: { activityId: string, approvalStatus: Activity.ApprovalStatus } ): void
  activities: Activity[]
  selectedActivity: Activity
}

export const useReviewActivityModal = () => {
  const { showModal, hideModal } = useModalWithParams<useReviewActivityModalParams>( ( { open, onExited, params } ) => {
    if( ! params ) {
      return null
    }

    return (
      <ReviewActivityModal
        isOpen={ open }
        onClose={ hideModal }
        onExited={ onExited }
        onSave={ params.onSave }
        activities={ params.activities }
        selectedActivity={ params.selectedActivity }
      />
    )
  }, [] )

  return {
    hideReviewActivityModal: hideModal,
    showReviewActivityModal: showModal,
  }
}


import { apiHelper } from "@onelocal/frontend/common"
import type { ApiMerchant } from "../types/api"
import { parseMerchantFromApi } from "../types/api"

export const agencyService = {
  async getMerchants( agencyId: string ) {
    return apiHelper.request( {
      method: "GET",
      parsingData: ( apiMerchants: ApiMerchant[] ) => apiMerchants.map( parseMerchantFromApi ),
      url: `/agency/${ agencyId }/merchants`,
    } )
  },
}

import { useEffect, useState } from "react"

import { useAsyncDispatch } from "@onelocal/frontend/common"
import { mainActions } from "../store/mainStore"
import type { MainSettings } from "../types"

export const useMainSettings = () => {
  const dispatch = useAsyncDispatch()
  const [ mainSettings, setMainSettings ] = useState<MainSettings | null>( null )

  useEffect( () => {
    const run = async () => {
      const settings = await dispatch( mainActions.settings.get() )

      setMainSettings( settings )
    }
    run()

  }, [ dispatch ] )

  return mainSettings
}

import { isObject } from "lodash"

import { apiHelper } from "@onelocal/frontend/common"
import { utilHelpers } from "@onelocal/shared/common"
import type { OnboardingForm } from "../types"
import { parseOnboardingFormFromApi, parseOnboardingFormStatusFromApi } from "../types/api"

export namespace OnboardingFormService {
  export interface UpdateModel {
    identifier: OnboardingForm.Identifier
    status?: OnboardingForm.Status
    step?: OnboardingForm.Step
  }
}

export const onboardingFormService = {
  async createService( identifier: OnboardingForm.Identifier, label: string ) {
    return apiHelper.request( {
      data: {
        label,
      },
      method: "POST",
      parsingData: parseOnboardingFormFromApi,
      url: `/onboarding/form/${ identifier.id }/services`,
    } )
  },
  async generateMerchant( identifier: OnboardingForm.Identifier ) {
    return apiHelper.request( {
      data: {},
      method: "POST",
      parsingData: parseOnboardingFormFromApi,
      url: `/onboarding/form/${ identifier.id }/merchant`,
      timeout: 60 * 1000,
    } )
  },
  async getForm( identifier: OnboardingForm.Identifier ) {
    return apiHelper.request( {
      method: "GET",
      parsingData: parseOnboardingFormFromApi,
      url: `/onboarding/form/${ identifier.id }`,
    } )
  },
  async getFormStatus( identifier: OnboardingForm.Identifier ) {
    return apiHelper.request( {
      method: "GET",
      parsingData: parseOnboardingFormStatusFromApi,
      url: `/onboarding/form-status/${ identifier.id }`,
    } )
  },
  async saveField( { identifier, fieldName, value }: { identifier: OnboardingForm.Identifier, fieldName: string, value: unknown } ) {
    return apiHelper.request( {
      data: {
        field_name: fieldName,
        value,
      },
      method: "POST",
      parsingData: parseOnboardingFormFromApi,
      url: `/onboarding/form/${ identifier.id }/save-field`,
    } )
  },
  async syncValuesFromGbp( identifier: OnboardingForm.Identifier ) {
    return apiHelper.request( {
      method: "POST",
      parsingData: parseOnboardingFormFromApi,
      url: `/onboarding/form/${ identifier.id }/sync-gbp-fields`,
      timeout: 30 * 1000,
    } )
  },
  async syncValuesFromSalesforce( identifier: OnboardingForm.Identifier, step: string ) {
    return apiHelper.request( {
      method: "POST",
      parsingData: parseOnboardingFormFromApi,
      url: `/onboarding/form/${ identifier.id }/sync-salesforce/${ step }`,
    } )
  },
  async updateForm( { identifier, status, step }: OnboardingFormService.UpdateModel ) {
    return apiHelper.request( {
      data: {
        merchant_status: status,
        step,
      },
      method: "POST",
      parsingData: parseOnboardingFormFromApi,
      url: `/onboarding/form/${ identifier.id }`,
    } )
  },
  async validateField( { identifier, fieldName, value }: { identifier: OnboardingForm.Identifier, fieldName: string, value: unknown } ) {
    return apiHelper.request( {
      data: {
        field_name: fieldName,
        value: isObject( value ) ? utilHelpers.toSnakeCase( value as Record<string, unknown> ) : value,
      },
      method: "POST",
      parsingData: parseOnboardingFormFromApi,
      url: `/onboarding/form/${ identifier.id }/validate-field`,
      timeout: 30 * 1000,
    } )
  },
  async validateStep( { identifier, moveToStep, step }: { identifier: OnboardingForm.Identifier, moveToStep?: OnboardingForm.Step, step: OnboardingForm.Step } ) {
    return apiHelper.request( {
      data: {
        move_to_step: moveToStep,
        step,
      },
      method: "POST",
      parsingData: parseOnboardingFormFromApi,
      url: `/onboarding/form/${ identifier.id }/validate-step`,
      timeout: 30 * 1000,
    } )
  },

}

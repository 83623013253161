import Pusher from "pusher-js"
import PusherBatchAuthorizer from "pusher-js-auth"

import { apiHelper, eventEmitterHelpers } from "../helpers"

export namespace PubSubService {}

interface PusherChannelsResponse {
  channels: string[]
  presence_channels: string[]
}

const getChannels = ( merchantId: string ): Promise<PusherChannelsResponse> => {
  return apiHelper.request( {
    method: "GET",
    url: `/merchants/${ merchantId }/pubsub/channels`,
  } )
}

let pusher: Pusher | null = null

export const pubSubService = {
  initialize( pusherKey: string, merchantId: string ) {
    getChannels( merchantId )
      .then(
        ( response ) => {
          const { channels } = response

          if( pusher ) {
            pusher.disconnect()
          }

          pusher = new Pusher(
            pusherKey,
            {
              authorizer: PusherBatchAuthorizer,
              authEndpoint: `merchants/${ merchantId }/pubsub/authenticate`,
            },
          )

          for( const channel of channels ) {
            pusher.subscribe( channel )
          }

          pusher.bind_global( ( event_type: string, data: { [ key: string ]: string } ) => {
            eventEmitterHelpers.emit( event_type, data )
          } )
        },
      )
  },
  on<T>( event: string, handler: ( data: T ) => void ) {
    eventEmitterHelpers.addEventListener( event, handler )

    return () => {
      eventEmitterHelpers.removeListener( event, handler )
    }
  },
  stop() {
    if( pusher ) {
      pusher.disconnect()
    }
  },
}

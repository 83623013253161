import type { PaginatedQueryOptions } from "@onelocal/frontend/common"
import { apiHelper } from "@onelocal/frontend/common"
import type { AuthInternalAccount } from ".."
import { parseAuthInternalAccountFromApi } from "../types/api"

export namespace AuthInternalAccountService {
  export interface CreateUpdateModel {
    email: string
    firstName: string
    lastName: string
    permissions: AuthInternalAccount.Permission[]
    role: AuthInternalAccount.Role
    salesforceId: string | null
    username: string
  }
  export interface QueryFilter {
    q?: string
  }
}

export const authInternalAccountService = {
  async create( model: AuthInternalAccountService.CreateUpdateModel ) {
    return apiHelper.request( {
      method: "POST",
      url: "/auth/internal-accounts",
      data: {
        email: model.email,
        first_name: model.firstName,
        last_name: model.lastName,
        permissions: model.permissions,
        role: model.role,
        salesforce_id: model.salesforceId,
        username: model.username,
      },
      parsingData: parseAuthInternalAccountFromApi,
    } )
  },
  async delete( internalAccountId: string ) {
    await apiHelper.request( {
      method: "DELETE",
      url: `/auth/internal-accounts/${ internalAccountId }`,
    } )
  },
  async getCurrentInternalAccount() {
    return apiHelper.request( {
      method: "GET",
      url: "/auth/internal-accounts/current",
      parsingData: parseAuthInternalAccountFromApi,
    } )
  },
  async query( query: AuthInternalAccountService.QueryFilter, options: PaginatedQueryOptions ) {
    return apiHelper.getPaginatedItems( {
      method: "GET",
      params: {
        page: options.page,
        per_page: options.perPage,
        q: query.q,
      },
      parsingData: parseAuthInternalAccountFromApi,
      url: "/auth/internal-accounts",
    } )
  },
  async resetPassword( internalAccountId: string ) {
    return apiHelper.request( {
      method: "POST",
      url: `/auth/internal-accounts/${ internalAccountId }/reset-password`,
      parsingData: parseAuthInternalAccountFromApi,
    } )
  },
  async setEnable( internalAccountId: string, enable: boolean ) {
    return apiHelper.request( {
      method: "POST",
      url: `/auth/internal-accounts/${ internalAccountId }/enable`,
      data: {
        enable,
      },
      parsingData: parseAuthInternalAccountFromApi,
    } )
  },
  async update( internalAccountId: string, model: AuthInternalAccountService.CreateUpdateModel ) {
    return apiHelper.request( {
      method: "PUT",
      url: `/auth/internal-accounts/${ internalAccountId }`,
      data: {
        email: model.email,
        first_name: model.firstName,
        last_name: model.lastName,
        permissions: model.permissions,
        role: model.role,
        salesforce_id: model.salesforceId,
        username: model.username,
      },
      parsingData: parseAuthInternalAccountFromApi,
    } )
  },
  async updateLastActiveDate( internalAccountId: string ) {
    return apiHelper.request( {
      method: "POST",
      url: `/auth/internal-accounts/${ internalAccountId }/last-active-date`,
    } )
  },
}

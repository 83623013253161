import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

import type { DefaultThunkAction, ModuleStoreConfig, PaginatedItems, PaginatedQueryOptions } from "@onelocal/frontend/common"
import type { SiteBlogService } from "../services/siteBlogService"
import { siteBlogService } from "../services/siteBlogService"
import type { SiteBlogPost } from "../types"
import { siteSaga } from "./siteSagas"

export interface SiteState {
  blogPost: {
    byId: { [ blogPostId: string ]: SiteBlogPost }
  }
}

const siteStoreKey = "site"

export interface RootStateSite {
  [ siteStoreKey ]: SiteState
}

type SiteThunkAction<TReturnType> = DefaultThunkAction<RootStateSite, TReturnType>

export const siteInitialState: SiteState = {
  blogPost: {
    byId: {},
  },
}

export namespace SiteState {

}

const siteSlice = createSlice( {
  name: siteStoreKey,
  initialState: siteInitialState,
  reducers: {
    deleteBlogPost: ( state, action: PayloadAction<{ siteBlogPostId: string }> ) => {
      delete state.blogPost.byId[ action.payload.siteBlogPostId ]
      return state
    },
    updateSiteBlogPosts: ( state, action: PayloadAction<SiteBlogPost[]> ) => {
      for( const blogPost of action.payload ) {
        state.blogPost.byId[ blogPost.id ] = blogPost
      }

      return state
    },
  },
} )

export const siteActions = {
  blogPosts: {
    approveBlogPost( merchantId: string, blogPostId: string ): SiteThunkAction<SiteBlogPost> {
      return async ( dispatch ) => {
        const blogPost = await siteBlogService.approveBlogPost( merchantId, blogPostId )
        dispatch( siteSlice.actions.updateSiteBlogPosts( [ blogPost ] ) )
        return blogPost
      }
    },
    createBlogPost( merchantId: string, model: SiteBlogService.CreateModel ): SiteThunkAction<SiteBlogPost> {
      return async ( dispatch ) => {
        const blogPost = await siteBlogService.createBlogPost( merchantId, model )
        dispatch( siteSlice.actions.updateSiteBlogPosts( [ blogPost ] ) )
        return blogPost
      }
    },
    deleteBlogPost( merchantId: string, blogPostId: string ): SiteThunkAction<SiteBlogPost> {
      return async ( dispatch ) => {
        const blogPost = await siteBlogService.deleteBlogPost( merchantId, blogPostId )
        dispatch( siteSlice.actions.deleteBlogPost( { siteBlogPostId: blogPostId } ) )
        return blogPost
      }
    },
    deleteImage( merchantId: string, blogPostId: string ): SiteThunkAction<SiteBlogPost> {
      return async ( dispatch ) => {
        const blogPost = await siteBlogService.deleteImage( merchantId, blogPostId )
        dispatch( siteSlice.actions.updateSiteBlogPosts( [ blogPost ] ) )
        return blogPost
      }
    },
    generateContent( merchantId: string, blogPostId: string ): SiteThunkAction<SiteBlogPost> {
      return async ( dispatch ) => {
        const blogPost = await siteBlogService.generateContent( merchantId, blogPostId )
        dispatch( siteSlice.actions.updateSiteBlogPosts( [ blogPost ] ) )
        return blogPost
      }
    },
    generateSuggestedImages( merchantId: string, blogPostId: string ): SiteThunkAction<SiteBlogPost> {
      return async ( dispatch ) => {
        const blogPost = await siteBlogService.generateSuggestedImages( merchantId, blogPostId )
        dispatch( siteSlice.actions.updateSiteBlogPosts( [ blogPost ] ) )
        return blogPost
      }
    },
    getById( merchantId: string, blogPostId: string ): SiteThunkAction<SiteBlogPost> {
      return async ( dispatch ) => {
        const blogPost = await siteBlogService.getBlogPostById( merchantId, blogPostId )
        dispatch( siteSlice.actions.updateSiteBlogPosts( [ blogPost ] ) )
        return blogPost
      }
    },
    publishBlogPost( merchantId: string, blogPostId: string ): SiteThunkAction<SiteBlogPost> {
      return async ( dispatch ) => {
        const blogPost = await siteBlogService.publishBlogPost( merchantId, blogPostId )
        dispatch( siteSlice.actions.updateSiteBlogPosts( [ blogPost ] ) )
        return blogPost
      }
    },
    query( merchantId: string, filter: SiteBlogService.QueryFilter, options: PaginatedQueryOptions ): SiteThunkAction<PaginatedItems<SiteBlogPost>> {
      return async ( dispatch ) => {
        const paginatedBlogPosts = await siteBlogService.queryBlogPosts( merchantId, filter, options )
        dispatch( siteSlice.actions.updateSiteBlogPosts( paginatedBlogPosts.items ) )
        return paginatedBlogPosts
      }
    },
    updateBlogPost( merchantId: string, blogPostId: string, model: SiteBlogService.UpdateModel ): SiteThunkAction<SiteBlogPost> {
      return async ( dispatch ) => {
        const blogPost = await siteBlogService.updateBlogPost( merchantId, blogPostId, model )
        dispatch( siteSlice.actions.updateSiteBlogPosts( [ blogPost ] ) )
        return blogPost
      }
    },
    uploadImage( merchantId: string, blogPostId: string, model: FormData ): SiteThunkAction<SiteBlogPost> {
      return async ( dispatch ) => {
        const blogPost = await siteBlogService.uploadImage( merchantId, blogPostId, model )
        dispatch( siteSlice.actions.updateSiteBlogPosts( [ blogPost ] ) )
        return blogPost
      }
    },
  },
}

export const siteStoreConfig: ModuleStoreConfig<SiteState> = {
  key: siteStoreKey,
  initialState: siteInitialState,
  reducers: siteSlice.reducer,
  saga: siteSaga,
}

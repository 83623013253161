import { ReactToAngularPortals } from "angulareact"
import React, { useEffect } from "react"

import { AdminApplicationType } from "@onelocal/frontend-admin-web/common"
import { AdminContainer, useAdmin } from "@onelocal/frontend-admin-web/main"

export const AdminContainerLib: React.FC = () => (
  <AdminContainer
    context={ { applicationType: AdminApplicationType.LIB } }
    disableGlobalStyle={ true }
  >
    <AdminContainerLibInner />
  </AdminContainer>
)

const AdminContainerLibInner: React.FC = () => {
  const { isLoading, loadAdmin } = useAdmin()

  useEffect( () => {
    loadAdmin()
  }, [ loadAdmin ] )

  if( isLoading ) {
    return null
  }

  return (
    <ReactToAngularPortals />
  )
}

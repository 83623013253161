import type { TwilioError } from "@twilio/voice-sdk"
import { Call, Device } from "@twilio/voice-sdk"

import type { Option } from "@onelocal/frontend/common"
import { apiHelper, eventEmitterHelpers } from "@onelocal/frontend/common"
import type { ConnectVoiceToken, OutboundCallListenerParams, PhoneActiveCall, UpdatedAudioDevices } from "../types"
import { PhoneActiveCallState } from "../types"
import { parseConnectVoiceTokenFromApi } from "../types/api"

export interface PhoneService {
  activeTwilioCall: Call | null
  incomingCalls: Call[]
  twilioDevice: Device | null

  acceptIncomingCallById( id: string ): void
  addAudioDevicesUpdatedListener( listener: ( updatedAudioDevices: UpdatedAudioDevices ) => void ): void
  addCallUpdatedListener( listener: ( activeCall: PhoneActiveCall ) => void ): void
  addClearIncomingCallsListener( listener: ( value: boolean ) => void ): void
  addIncomingCallListener( listener: ( incomingCall: PhoneActiveCall ) => void ): void
  addIncomingCallCancelledListener( listener: ( incomingCall: PhoneActiveCall ) => void ): void
  addOutboundCallListener( listener: ( params: OutboundCallListenerParams ) => void ): void
  declineIncomingCallById( id: string ): void
  getVoiceToken( merchantId: string ): Promise<ConnectVoiceToken>
  isOnCall(): boolean
  registerDevice( merchantId: string ): Promise<ConnectVoiceToken>
  removeAudioDevicesUpdatedListener( listener: ( updatedAudioDevices: UpdatedAudioDevices ) => void ): void
  removeCallUpdatedListener( listener: ( activeCall: PhoneActiveCall ) => void ): void
  removeClearIncomingCallsListener( listener: ( value: boolean ) => void ): void
  removeIncomingCallListener( listener: ( activeCall: PhoneActiveCall ) => void ): void
  removeIncomingCallCancelledListener( listener: ( activeCall: PhoneActiveCall ) => void ): void
  removeOutboundCallListener( listener: ( params: OutboundCallListenerParams ) => void ): void
  sendDigitsToActiveCall( digits: string ): void
  setActiveCallMuted( muted: boolean ): void
  startOutboundCall( { connectCallId, to }: { connectCallId: string, to: string } ): Promise<Call | undefined>
  stopCall(): void
  unregisterDevice(): Promise<void>
  updateInputDevice( deviceId: string ): void
  updateOutputDevice( deviceId: string ): void
}

export namespace PhoneService {}

function getCallState( call: Call ): PhoneActiveCallState {
  const status = call.status()

  switch( status ) {
    case Call.State.Closed: {
      return PhoneActiveCallState.DISCONNECTED
    }
    case Call.State.Connecting: {
      return PhoneActiveCallState.CONNECTING
    }
    case Call.State.Open: {
      return PhoneActiveCallState.CONNECTED
    }
    case Call.State.Pending:
    case Call.State.Ringing: {
      return PhoneActiveCallState.RINGING
    }
    case Call.State.Reconnecting: {
      return PhoneActiveCallState.RECONNECTING
    }
  }
}

function parseIncomingTwilioCallFromApi( call: Call ): PhoneActiveCall {
  return {
    connectCallId: call.customParameters.get( "connect_call_id" ) as string,
    digits: "",
    from: call.customParameters.get( "name" ) || call.parameters[ "From" ] as string,
    isMuted: call.isMuted(),
    state: getCallState( call ),
    sid: call.parameters[ "CallSid" ],
  }
}

export const enum EventType {
  AUDIO_DEVICES_UPDATED = "audio_devices_updated",
  CALL_ERROR = "call_error",
  CALL_INCOMING = "call_incoming",
  CALL_INCOMING_CANCELLED = "call_incoming_cancelled",
  CALL_OUTBOUND = "call_outbound",
  CALL_UPDATED = "call_updated",
  CLEAR_INCOMING_CALLS = "clear_incoming_calls",
  OPEN_NEW_CALL_MODAL = "open_new_call_modal",
}

const twilioCallErrorHandler = ( error: TwilioError.TwilioError ) => {
  let message = "Error Connecting"

  if( error.code === 31401 ) {
    message = "Need microphone access to start a call"
  }

  return eventEmitterHelpers.emit( EventType.CALL_ERROR, message )
}

export const phoneService: PhoneService = {
  activeTwilioCall: null,
  incomingCalls: [],
  twilioDevice: null,

  acceptIncomingCallById( id ) {
    const incomingCall = phoneService.incomingCalls.find( ( call ) =>
      parseIncomingTwilioCallFromApi( call ).connectCallId === id,
    )

    if( incomingCall ) {
      incomingCall.accept()
    }
  },
  addAudioDevicesUpdatedListener( listener ) {
    return eventEmitterHelpers.addEventListener( EventType.AUDIO_DEVICES_UPDATED, listener )
  },
  addCallUpdatedListener( listener ) {
    return eventEmitterHelpers.addEventListener( EventType.CALL_UPDATED, listener )
  },
  addClearIncomingCallsListener( listener ) {
    return eventEmitterHelpers.addEventListener( EventType.CLEAR_INCOMING_CALLS, listener )
  },
  addIncomingCallCancelledListener( listener ) {
    return eventEmitterHelpers.addEventListener( EventType.CALL_INCOMING_CANCELLED, listener )
  },
  addIncomingCallListener( listener ) {
    return eventEmitterHelpers.addEventListener( EventType.CALL_INCOMING, listener )
  },
  addOutboundCallListener( listener ) {
    return eventEmitterHelpers.addEventListener( EventType.CALL_OUTBOUND, listener )
  },
  declineIncomingCallById( id ) {
    const incomingCall = phoneService.incomingCalls.find( ( call ) =>
      parseIncomingTwilioCallFromApi( call ).connectCallId === id,
    )

    if( incomingCall ) {
      incomingCall.reject()
    }
  },
  async getVoiceToken( merchantId ) {
    return apiHelper.request( {
      method: "POST",
      url: `/merchants/${ merchantId }/connect/phone_token`,
      parsingData: parseConnectVoiceTokenFromApi,
    } )
  },
  isOnCall() {
    if( ! phoneService.twilioDevice ) {
      return false
    }
    return phoneService.twilioDevice.isBusy
  },
  async registerDevice( merchantId ) {
    const connectVoiceToken = await phoneService.getVoiceToken( merchantId )

    phoneService.twilioDevice = new Device( connectVoiceToken.value, {
      allowIncomingWhileBusy: true,
      codecPreferences: [ Call.Codec.Opus, Call.Codec.PCMU ],
      // logLevel: Logger.levels.DEBUG,
    } )

    await phoneService.twilioDevice.register()

    const populateAudioDevices = () => {
      if( ! phoneService.twilioDevice ) {
        return
      }

      let currentInputDeviceId: string | null = "default"
      let currentOutputDeviceId: string | null = "default"

      const inputDevices: Array<Option<string>> = []
      const outputDevices: Array<Option<string>> = []

      if( phoneService.twilioDevice.audio ) {
        if( phoneService.twilioDevice.audio.inputDevice ) {
          currentInputDeviceId = phoneService.twilioDevice.audio.inputDevice.deviceId
        }

        phoneService.twilioDevice.audio.availableInputDevices.forEach( ( audioDevice, id ) => {
          inputDevices.push( {
            label: audioDevice.label,
            value: id,
          } )
        } )

        phoneService.twilioDevice.audio.availableOutputDevices.forEach( ( audioDevice, id ) => {
          const speakerDevices = phoneService.twilioDevice!.audio!.speakerDevices.get()

          for( const speakerDevice of speakerDevices ) {
            if( speakerDevice.deviceId === id ) {
              currentOutputDeviceId = id
              break
            }
          }

          outputDevices.push( {
            label: audioDevice.label,
            value: id,
          } )
        } )
      }

      return eventEmitterHelpers.emit( EventType.AUDIO_DEVICES_UPDATED, { currentInputDeviceId, currentOutputDeviceId, inputDevices, outputDevices } )
    }

    phoneService.twilioDevice.on( "error", ( error: TwilioError.TwilioError ) => {
      if( error.code === 31005 ) {
        phoneService.incomingCalls = []
        eventEmitterHelpers.emit( EventType.CLEAR_INCOMING_CALLS, true )
      }
    } )

    phoneService.twilioDevice.on( "incoming", ( call: Call ) => {
      const parsedCall = parseIncomingTwilioCallFromApi( call )

      const removeIncomingCall = () => {
        phoneService.incomingCalls = phoneService.incomingCalls.filter( ( incomingCall: Call ) =>
          parseIncomingTwilioCallFromApi( incomingCall ).connectCallId !== parsedCall.connectCallId,
        )
      }

      call.on( "accept", ( acceptedCall: Call ) => {
        phoneService.activeTwilioCall = acceptedCall
        removeIncomingCall()
        return eventEmitterHelpers.emit( EventType.CALL_UPDATED, parseIncomingTwilioCallFromApi( acceptedCall ) )
      } )

      call.on( "error", twilioCallErrorHandler )

      call.on( "reconnected", () => {
        return eventEmitterHelpers.emit( EventType.CALL_UPDATED, parseIncomingTwilioCallFromApi( phoneService.activeTwilioCall! ) )
      } )

      call.on( "reconnecting", () => {
        return eventEmitterHelpers.emit( EventType.CALL_UPDATED, parseIncomingTwilioCallFromApi( phoneService.activeTwilioCall! ) )
      } )

      call.on( "cancel", () => {
        removeIncomingCall()
        return eventEmitterHelpers.emit( EventType.CALL_INCOMING_CANCELLED, parsedCall )
      } )

      call.on( "disconnect", ( disconnectedCall: Call ) => {
        phoneService.activeTwilioCall = null
        return eventEmitterHelpers.emit( EventType.CALL_UPDATED, parseIncomingTwilioCallFromApi( disconnectedCall ) )
      } )

      call.on( "reject", () => {
        removeIncomingCall()
        return eventEmitterHelpers.emit( EventType.CALL_INCOMING_CANCELLED, parsedCall )
      } )

      phoneService.incomingCalls.push( call )
      eventEmitterHelpers.emit( EventType.CALL_INCOMING, parsedCall )
    } )

    if( phoneService.twilioDevice.audio ) {
      phoneService.twilioDevice.audio.on( "deviceChange", populateAudioDevices )
    }

    populateAudioDevices()

    return connectVoiceToken
  },
  removeAudioDevicesUpdatedListener( listener ) {
    return eventEmitterHelpers.removeListener( EventType.CALL_UPDATED, listener )
  },
  removeCallUpdatedListener( listener ) {
    return eventEmitterHelpers.removeListener( EventType.CALL_UPDATED, listener )
  },
  removeClearIncomingCallsListener( listener ) {
    return eventEmitterHelpers.removeListener( EventType.CLEAR_INCOMING_CALLS, listener )
  },
  removeIncomingCallCancelledListener( listener ) {
    return eventEmitterHelpers.removeListener( EventType.CALL_INCOMING_CANCELLED, listener )
  },
  removeIncomingCallListener( listener ) {
    return eventEmitterHelpers.removeListener( EventType.CALL_INCOMING, listener )
  },
  removeOutboundCallListener( listener ) {
    return eventEmitterHelpers.removeListener( EventType.CALL_OUTBOUND, listener )
  },
  sendDigitsToActiveCall( digits ) {
    if( ! phoneService.activeTwilioCall ) {
      return
    }

    phoneService.activeTwilioCall.sendDigits( digits )
  },
  setActiveCallMuted( muted ) {
    if( ! phoneService.activeTwilioCall ) {
      return
    }

    phoneService.activeTwilioCall.mute( muted )
  },
  async startOutboundCall( { connectCallId, to } ) {
    if( ! phoneService.twilioDevice ) {
      return
    }

    phoneService.activeTwilioCall = await phoneService.twilioDevice.connect( {
      params: {
        To: to,
        connect_call_id: connectCallId,
      },
    } )

    phoneService.activeTwilioCall.on( "accept", ( acceptedCall: Call ) => {
      return eventEmitterHelpers.emit( EventType.CALL_UPDATED, parseIncomingTwilioCallFromApi( acceptedCall ) )
    } )

    phoneService.activeTwilioCall.on( "error", twilioCallErrorHandler )

    phoneService.activeTwilioCall.on( "reconnected", () => {
      return eventEmitterHelpers.emit( EventType.CALL_UPDATED, parseIncomingTwilioCallFromApi( phoneService.activeTwilioCall! ) )
    } )

    phoneService.activeTwilioCall.on( "reconnecting", () => {
      return eventEmitterHelpers.emit( EventType.CALL_UPDATED, parseIncomingTwilioCallFromApi( phoneService.activeTwilioCall! ) )
    } )

    phoneService.activeTwilioCall.on( "ringing", ( isRinging: boolean ) => {
      if( isRinging ) {
        return eventEmitterHelpers.emit( EventType.CALL_UPDATED, parseIncomingTwilioCallFromApi( phoneService.activeTwilioCall! ) )
      }

      return
    } )

    phoneService.activeTwilioCall.on( "cancel", () => {
      return eventEmitterHelpers.emit( EventType.CALL_INCOMING_CANCELLED, parseIncomingTwilioCallFromApi( phoneService.activeTwilioCall! ) )
    } )

    phoneService.activeTwilioCall.on( "disconnect", ( disconnectedCall: Call ) => {
      phoneService.activeTwilioCall = null
      return eventEmitterHelpers.emit( EventType.CALL_UPDATED, parseIncomingTwilioCallFromApi( disconnectedCall ) )
    } )

    return phoneService.activeTwilioCall
  },
  stopCall() {
    if( ! phoneService.activeTwilioCall ) {
      return
    }

    phoneService.activeTwilioCall.disconnect()
    phoneService.activeTwilioCall = null
  },
  async unregisterDevice() {
    if( ! phoneService.twilioDevice ) {
      return
    }

    await phoneService.twilioDevice.unregister()
    phoneService.twilioDevice = null
  },
  updateInputDevice( deviceId ) {
    if( ! phoneService.twilioDevice?.audio ) {
      return
    }

    phoneService.twilioDevice.audio.setInputDevice( deviceId )
  },
  updateOutputDevice( deviceId ) {
    if( ! phoneService.twilioDevice?.audio ) {
      return
    }

    phoneService.twilioDevice.audio.speakerDevices.set( deviceId )
  },
}

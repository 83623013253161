import type { SnakeCasedPropertiesDeep } from "type-fest"

import type { Site, SiteGenerationDefaultValues, SiteTemplate } from "."

export type ApiSite = SnakeCasedPropertiesDeep<Site>

export type ApiSitePageOption = Site.Page

export function parseSiteFromApi( apiSite: ApiSite ) {
  const site: Site = {
    created: {
      at: apiSite.created.at,
    },
    dudaId: apiSite.duda_id,
    id: apiSite.id,
    indexed: apiSite.indexed,
    isPrimary: apiSite.is_primary,
    label: apiSite.label,
    merchantId: apiSite.merchant_id,
    type: apiSite.type,
  }

  if( apiSite.content_generation ) {
    site.contentGeneration = {
      error: apiSite.content_generation.error,
      logs: apiSite.content_generation.logs?.map( log => ( {
        detail: log.detail,
        finishedAt: log.finished_at,
        id: log.id,
        startedAt: log.started_at,
      } ) ),
      siteDetailsLogs: apiSite.content_generation.site_details_logs?.map( ( siteDetailsLog ) => ( {
        at: siteDetailsLog.at,
        details: JSON.stringify( siteDetailsLog.details, null, 2 ),
      } ) ),
      status: apiSite.content_generation.status,
    }
  }

  return site
}

export function parseSitePageOptionsFromApi( apiSitePages: ApiSitePageOption[] ) {
  const sitePages = apiSitePages.map( ( apiSitePage ) => {
    const sitePage: Site.Page = {
      id: apiSitePage.id,
      label: apiSitePage.label,
    }

    return sitePage
  } )

  return sitePages
}

export type ApiSiteTemplate = SnakeCasedPropertiesDeep<SiteTemplate>

export function parseSiteTemplateFromApi( apiSiteTemplate: ApiSiteTemplate ) {
  const siteTemplate: SiteTemplate = {
    dudaId: apiSiteTemplate.duda_id,
    id: apiSiteTemplate.id,
    isAvailable: apiSiteTemplate.is_available,
    name: apiSiteTemplate.name,
    types: apiSiteTemplate.types,
  }

  return siteTemplate
}

export type ApiSiteGenerationDefaultValues = SnakeCasedPropertiesDeep<SiteGenerationDefaultValues>

export function parseSiteGenerationDefaultValuesFromApi( apiSiteGenerationDefaultValues: ApiSiteGenerationDefaultValues ) {
  const siteGenerationDefaultValues: SiteGenerationDefaultValues = {
    address: {
      city: apiSiteGenerationDefaultValues.address.city,
      country: apiSiteGenerationDefaultValues.address.country,
      postalCode: apiSiteGenerationDefaultValues.address.postal_code,
      state: apiSiteGenerationDefaultValues.address.state,
      street: apiSiteGenerationDefaultValues.address.street,
    },
    businessType: apiSiteGenerationDefaultValues.business_type,
    email: apiSiteGenerationDefaultValues.email,
    facebookId: apiSiteGenerationDefaultValues.facebook_id,
    instagramId: apiSiteGenerationDefaultValues.instagram_id,
    name: apiSiteGenerationDefaultValues.name,
    phoneNumber: apiSiteGenerationDefaultValues.phone_number,
    primaryIndustry: apiSiteGenerationDefaultValues.primary_industry,
    secondaryIndustry: apiSiteGenerationDefaultValues.secondary_industry,
    siteTemplateId: apiSiteGenerationDefaultValues.site_template_id,
    siteTemplateName: apiSiteGenerationDefaultValues.site_template_name,
  }

  return siteGenerationDefaultValues
}

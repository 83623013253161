import CheckIcon from "@mui/icons-material/Check"
import React from "react"

import { styleHelpers } from "../helpers"
import type { ButtonProps } from "."
import { Button, Flex } from "."

export interface SaveButtonProps extends ButtonProps {
}

export const SaveButton: React.FC<SaveButtonProps> = ( { style, ...props } ) => {
  return (
    <Button 
      { ...props } 
      style={ { 
        fontSize: styleHelpers.fonts.sizes.default,
        lineHeight: "1.2",
        padding: "6px 16px",
        textTransform: "none", 
        ...style, 
      } }
    >
      <Flex 
        alignItems="center" 
        display="flex" 
      >
        <CheckIcon style={ { height: 18, marginRight: 8, width: 18 } }/> Save
      </Flex>
    </Button>
  )
}

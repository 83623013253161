export enum Period {
  LAST_7_DAYS = "last_7_days",
  LAST_30_DAYS = "last_30_days",
  LAST_60_DAYS = "last_60_days",
  LAST_90_DAYS = "last_90_days",
}

export const PERIOD_LABELS: Record<Period, string> = {
  [ Period.LAST_7_DAYS ]: "Last 7 days",
  [ Period.LAST_30_DAYS ]: "Last 30 days",
  [ Period.LAST_60_DAYS ]: "Last 60 days",
  [ Period.LAST_90_DAYS ]: "Last 90 days",
}

export const PERIOD_OPTIONS: Array<{ label: string, value: Period }> = Object.keys( PERIOD_LABELS ).map( ( key ) => ( {
  label: PERIOD_LABELS[ key as Period ],
  value: key as Period,
} ) )

export enum PerformanceSectionCardType {
  GBP = "GBP",
  LEADS= "Leads",
  ORGANIC_SEARCH = "Organic Search",
  PAID_ADS = "Paid Ads",
  REVIEWS = "Reviews",
  SITE = "Site",
}

export enum PerformanceSectionType {
  DIGITAL_MARKETING = "Digital Marketing",
  LEADS_AND_REVIEWS = "Leads and Reviews",
}

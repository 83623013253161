import { useCallback, useRef } from "react"
import { useSelector } from "react-redux"

import { useRootDispatch } from "@onelocal/frontend-dashboard-web/main"
import { commonSelectors } from "../store/commonSelector"
import { commonActions } from "../store/commonStore"

export const useDashboardContext = function() {
  const dispatch = useRootDispatch()
  const merchantIdRef = useRef<string | null>( null )

  const dashboardContext = useSelector( commonSelectors.context.current )

  merchantIdRef.current = dashboardContext?.merchantId || null

  const reloadDashboardContext = useCallback( async () => {
    if( ! merchantIdRef.current ) {
      return
    }

    return dispatch( commonActions.loadContext( merchantIdRef.current ) )
  }, [ dispatch ] )

  return {
    dashboardContext: dashboardContext!,
    reloadDashboardContext,
  }
}

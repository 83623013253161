import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

import type { DefaultThunkAction, ModuleStoreConfig, PaginatedItems, PaginatedQueryOptions } from "@onelocal/frontend/common"
import type { AuthInternalAccountService } from ".."
import { authInternalAccountService } from ".."
import type { AuthInternalAccount } from "../types"
import { authSaga } from "./authSagas"

export interface AuthState {
  internalAccounts: {
    byId: { [id: string]: AuthInternalAccount }
    currentId: string | null
  }
}

const authStoreKey = "auth"

export interface RootStateAuth {
  [ authStoreKey ]: AuthState
}

type AuthThunkAction<TReturnType> = DefaultThunkAction<RootStateAuth, TReturnType>

export const authInitialState: AuthState = {
  internalAccounts: {
    currentId: null,
    byId: {},
  },
}

const authSlice = createSlice( {
  name: authStoreKey,
  initialState: authInitialState,
  reducers: {
    deleteInternalAccount: ( state, action: PayloadAction<string> ) => {
      delete state.internalAccounts.byId[ action.payload ]
      return state
    },
    setCurrentInternalAccount: ( state, action: PayloadAction<AuthInternalAccount> ) => {
      const internalAccount = action.payload
      state.internalAccounts.byId[ internalAccount.id ] = internalAccount
      state.internalAccounts.currentId = internalAccount.id
      return state
    },
    updateInternalAccounts: ( state, action: PayloadAction<AuthInternalAccount[]> ) => {
      for( const internalAccount of action.payload ) {
        state.internalAccounts.byId[ internalAccount.id ] = internalAccount
      }

      return state
    },
  },
} )

export const authActions = {
  internalAccounts: {
    create( model: AuthInternalAccountService.CreateUpdateModel ): AuthThunkAction<AuthInternalAccount> {
      return async ( dispatch ) => {
        const internalAccount = await authInternalAccountService.create( model )
        dispatch( authSlice.actions.updateInternalAccounts( [ internalAccount ] ) )
        return internalAccount
      }
    },
    delete( internalAccountId: string ): AuthThunkAction<void> {
      return async ( dispatch ) => {
        await authInternalAccountService.delete( internalAccountId )
        dispatch( authSlice.actions.deleteInternalAccount( internalAccountId ) )
      }
    },
    getCurrent(): AuthThunkAction<AuthInternalAccount> {
      return async ( dispatch ) => {
        const internalAccount = await authInternalAccountService.getCurrentInternalAccount()
        dispatch( authSlice.actions.setCurrentInternalAccount( internalAccount ) )
        return internalAccount
      }
    },
    query( filter: AuthInternalAccountService.QueryFilter, options: PaginatedQueryOptions ): AuthThunkAction<PaginatedItems<AuthInternalAccount>> {
      return async ( dispatch ) => {
        const paginatedInternalAccounts = await authInternalAccountService.query( filter, options )
        dispatch( authSlice.actions.updateInternalAccounts( paginatedInternalAccounts.items ) )
        return paginatedInternalAccounts
      }
    },
    resetPassword( internalAccountId: string ): AuthThunkAction<AuthInternalAccount> {
      return async ( dispatch ) => {
        const internalAccount = await authInternalAccountService.resetPassword( internalAccountId )
        dispatch( authSlice.actions.updateInternalAccounts( [ internalAccount ] ) )
        return internalAccount
      }
    },
    setEnable( internalAccountId: string, enable: boolean ): AuthThunkAction<AuthInternalAccount> {
      return async ( dispatch ) => {
        const internalAccount = await authInternalAccountService.setEnable( internalAccountId, enable )
        dispatch( authSlice.actions.updateInternalAccounts( [ internalAccount ] ) )
        return internalAccount
      }
    },
    update( internalAccountId: string, model: AuthInternalAccountService.CreateUpdateModel ): AuthThunkAction<AuthInternalAccount> {
      return async ( dispatch ) => {
        const internalAccount = await authInternalAccountService.update( internalAccountId, model )
        dispatch( authSlice.actions.updateInternalAccounts( [ internalAccount ] ) )
        return internalAccount
      }
    },
  },
}

export type AuthStateSelector<TRootState> = [
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ( state: TRootState, ...params: any[] ) => AuthState
];

export const authStoreConfig: ModuleStoreConfig<AuthState> = {
  key: authStoreKey,
  initialState: authInitialState,
  reducers: authSlice.reducer,
  saga: authSaga,
}

import DeleteIcon from "@mui/icons-material/Delete"
// eslint-disable-next-line no-restricted-imports
import IconButton from "@mui/material/IconButton"
import AwesomeDebouncePromise from "awesome-debounce-promise"
import { ADDITIONAL_PAGE_NAMES } from "libs/frontend-admin-web/onboarding/src/components/OnboardingForm/constants"
import { findIndex, remove } from "lodash"
import React, { useCallback, useMemo, useState } from "react"
import { FormProvider, useFormContext } from "react-hook-form"
import { useEffectOnce } from "react-use"
import styled from "styled-components"

import type { SelectOption } from "@onelocal/frontend-web/common"
import { Button, Flex, Grid, GridItem, styleHelpers } from "@onelocal/frontend-web/common"
import { utilHelpers } from "@onelocal/shared/common"
import { useOnboardingForm } from "../../hooks/useOnboardingForm"
import { useOnboardingFormContext } from "../../hooks/useOnboardingFormContext"
import { OnboardingForm } from "../../types"
import { OnboardingFormButtons, OnboardingFormDisclaimerLabel, OnboardingFormFieldSelect, OnboardingFormFieldText, OnboardingFormNotes, OnboardingFormSaving } from "./OnboardingFormFields"

const pageOptions: Array<SelectOption<OnboardingForm.SitePageType>> = [
  OnboardingForm.SitePageType.CAREERS,
  OnboardingForm.SitePageType.FAQ,
  OnboardingForm.SitePageType.FEATURED_PRODUCTS,
  OnboardingForm.SitePageType.GALLERY,
  OnboardingForm.SitePageType.GENERAL_CONTENT,
  OnboardingForm.SitePageType.LOCATION,
  OnboardingForm.SitePageType.MEDIA,
  OnboardingForm.SitePageType.PORTFOLIO,
  OnboardingForm.SitePageType.PRICING,
  OnboardingForm.SitePageType.PROCESS,
  OnboardingForm.SitePageType.TEAM,
].map( ( pageType ) => ( { label: ADDITIONAL_PAGE_NAMES[ pageType ], value: pageType } ) )

type FormValues = Record<string, number | string>

interface PageItemModel extends Partial<OnboardingForm.Page> {
  id: string
}

export interface OnboardingFormStepPagesProps {
}

export const OnboardingFormStepPages: React.FC<OnboardingFormStepPagesProps> = () => {
  const {
    formMethods,
    form,
    isSaving,
    onChangeHandlers,
    onSubmit,
  } = useOnboardingForm<FormValues>( {
    defaultValues: {

    },
    fieldNames: [
      OnboardingForm.Field.NOTES_ADDITIONAL_PAGES,
      OnboardingForm.Field.IFRAME_CODE_PAGES,
    ],
    step: OnboardingForm.Step.PAGES,
  } )
  const { saveField } = useOnboardingFormContext()
  const { setValue } = formMethods

  const [ pageItems, setPageItems ] = useState<PageItemModel[]>( () => {
    return ( form?.fields.additional_pages || [] ).map( ( page ) => ( {
      ...page,
      id: page.id || utilHelpers.generateUniqueId(),
    } ) )
  } )

  const addPage = useCallback( () => {
    setPageItems( ( currentPages ) => (
      [
        ...currentPages,
        {
          id: utilHelpers.generateUniqueId(),
          name: "",
        },
      ]
    ) )
  }, [] )

  const savePages = useCallback( async ( updatePageItems: PageItemModel[] ) => {
    await saveField( OnboardingForm.Field.ADDITIONAL_PAGES, updatePageItems )
  }, [ saveField ] )

  const savePagesDebounced = useMemo( () => {
    return AwesomeDebouncePromise(
      savePages,
      500,
      {},
    )
  }, [ savePages ] )

  const onPageChange = useCallback( async ( pageItem: PageItemModel ) => {
    const pageItemIndex = findIndex( pageItems, { id: pageItem.id } )

    if( pageItemIndex === -1 ) {
      return
    }

    const updatePageItems = [ ...pageItems ]
    updatePageItems[ pageItemIndex ] = pageItem
    setPageItems( updatePageItems )

    await savePagesDebounced( updatePageItems )

  }, [ pageItems, savePagesDebounced ] )

  const setPageValues = useCallback( () => {
    pageItems.forEach( ( pageItem, i ) => {
      setValue( `page_type_${ i }`, pageItem.type! )
      if( pageItem.answer ) {
        setValue( `page_answer_${ i }`, pageItem.answer )
      }
      if( pageItem.label ) {
        setValue( `page_label_${ i }`, pageItem.label )
      }
    } )
  }, [ pageItems, setValue ] )

  const onPageDelete = useCallback( async ( pageItem: PageItemModel ) => {
    const pageItemIndex = findIndex( pageItems, { id: pageItem.id } )

    if( pageItemIndex === -1 ) {
      return
    }

    remove( pageItems, { id: pageItem.id } )

    await savePagesDebounced( pageItems )
    setPageValues()
  }, [ savePagesDebounced, pageItems, setPageValues ] )

  useEffectOnce( () => {
    setPageValues()
  } )

  return (
    <div>
      <OnboardingFormSaving isSaving={ isSaving } />
      <FormProvider { ...formMethods }>
        <form onSubmit={ onSubmit } noValidate>
          {
            pageItems.map( ( page, index ) => (
              <PageItem
                key={ page.id }
                index={ index }
                onChange={ onPageChange }
                onDelete={ onPageDelete }
                page={ page }
              />
            ) )
          }
          <Button
            onClick={ addPage }
            style={ { marginBottom: "20px" } }
          >
            Add Additional Page
          </Button>

          <OnboardingFormNotes
            step={ OnboardingForm.Step.PAGES }
            onChange={ onChangeHandlers[ OnboardingForm.Field.NOTES_ADDITIONAL_PAGES ] }
          />

          <Flex style={ { marginTop: "25px" } }>
            <OnboardingFormFieldText
              fieldName={ OnboardingForm.Field.IFRAME_CODE_PAGES }
              isRequired={ false }
              label="IFrame Code"
              onChange={ onChangeHandlers[ OnboardingForm.Field.IFRAME_CODE_PAGES ] }
              multiline={ true }
            />
          </Flex>
          <OnboardingFormDisclaimerLabel />
          <OnboardingFormButtons disabled={ isSaving } />
        </form>
      </FormProvider>
    </div>
  )
}

const StyledPageItem = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 0;
  align-items: start;

  & + & {
    border-top: 1px solid ${ styleHelpers.colors.borderGray };
  }
`

interface PageItemProps {
  index: number
  onChange: ( page: PageItemModel ) => void
  onDelete: ( page: PageItemModel ) => void
  page: PageItemModel
}

const ADDITIONAL_PAGES_WITH_REQUIRED_LABEL = [
  OnboardingForm.SitePageType.CUSTOM,
  OnboardingForm.SitePageType.LOCATION,
  OnboardingForm.SitePageType.SERVICE_CATEGORY,
]

const ADDITIONAL_PAGES_WITH_LABELS = [
  ...ADDITIONAL_PAGES_WITH_REQUIRED_LABEL,
  OnboardingForm.SitePageType.FEATURED_PRODUCTS,
  OnboardingForm.SitePageType.GALLERY,
  OnboardingForm.SitePageType.GENERAL_CONTENT,
  OnboardingForm.SitePageType.MEDIA,
  OnboardingForm.SitePageType.PORTFOLIO,
  OnboardingForm.SitePageType.PRICING,
  OnboardingForm.SitePageType.PROCESS,
]

const PageItem: React.FC<PageItemProps> = ( { index, onChange, onDelete, page } ) => {
  // const { form } = useOnboardingFormContext()
  const { getValues, setValue } = useFormContext()

  const triggerOnChange = useCallback( () => {
    const values = getValues()

    const updatedPage: PageItemModel = {
      id: page.id,
      answer: values[ `page_answer_${ index }` ] as string,
      label: values[ `page_label_${ index }` ] as string,
      type: values[ `page_type_${ index }` ] as PageItemModel[ "type" ],
    }

    onChange( updatedPage )
  }, [ getValues, index, onChange, page.id ] )

  const onDeleteHandler = useCallback( () => {
    onDelete( page )
  }, [ onDelete, page ] )

  const onChangeHandlers = useMemo( () => {
    return {
      answer: ( value: string ) => {
        setValue( `page_answer_${ index }`, value )
        triggerOnChange()
      },
      label: ( value: string ) => {
        setValue( `page_label_${ index }`, value )
        triggerOnChange()
      },
      type: ( value: string ) => {
        setValue( `page_type_${ index }`, value )
        triggerOnChange()
      },
    }

  }, [ index, setValue, triggerOnChange ] )

  // Commenting out for https://onelocal.atlassian.net/browse/DEV-2141
  // const questions = useMemo( () => {
  //   if( ! page?.type || ! form?.generated.pageQuestions[ page.type ] ) {
  //     return
  //   }
  //   return form.generated.pageQuestions[ page.type ].split( /\n/g )
  // }, [ form?.generated.pageQuestions, page?.type ] )

  return (
    <StyledPageItem>
      <Grid spacing={ 4 } style={ { flex: 1 } }>
        <GridItem xs={ 6 }>
          <OnboardingFormFieldSelect
            fieldName={ `page_type_${ index }` }
            isRequired={ true }
            label="Page"
            onChange={ onChangeHandlers.type }
            options={ pageOptions }
          />
        </GridItem>
        {
          page.type && ADDITIONAL_PAGES_WITH_LABELS.includes( page.type ) && (
            <GridItem xs={ 6 }>
              <OnboardingFormFieldText
                fieldName={ `page_label_${ index }` }
                isRequired={ ADDITIONAL_PAGES_WITH_REQUIRED_LABEL.includes( page.type ) }
                label="Label"
                onChange={ onChangeHandlers.label }
              />
            </GridItem>
          )
        }
        {
        // Commenting out for https://onelocal.atlassian.net/browse/DEV-2141
        /* <GridItem xs={ 12 }>
          {
            questions != null && (
              <>
                <div style={ { fontSize: 14, fontWeight: styleHelpers.fonts.weight.semiBold } }>Questions to ask:</div>
                <ul>
                  {
                    questions?.map( ( question ) => (
                      <li key={ question }>{ question }</li>
                    ) )
                  }
                </ul>
              </>
            )

          }
        </GridItem> */
        }
        <GridItem xs={ 12 }>
          <OnboardingFormFieldText
            fieldName={ `page_answer_${ index }` }
            isRequired={ true }
            label="Answer"
            onChange={ onChangeHandlers.answer }
            multiline={ true }
          />
        </GridItem>
      </Grid>
      <IconButton onClick={ onDeleteHandler }>
        <DeleteIcon />
      </IconButton>
    </StyledPageItem>
  )
}

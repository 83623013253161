// eslint-disable-next-line @nx/enforce-module-boundaries
import type { MerchantSettings } from "@onelocal/frontend-dashboard/settings"
import type { AdsMerchantSettings } from "."

export interface ApiAdsMerchantSettings {
  adwords_conversion_tracked?: boolean
  start_date?: string | null
  status: "live" | null
  v?: number
}

export namespace ApiAdsMerchantSettings {

}

export function parseAdsMerchantSettingsFromApi( apiAdsMerchantSettings: ApiAdsMerchantSettings ) {
  const adsMerchantSettings: AdsMerchantSettings = {
    adwordsConversionTracked: apiAdsMerchantSettings.adwords_conversion_tracked || false,
    startDate: apiAdsMerchantSettings.start_date,
    status: apiAdsMerchantSettings.status as MerchantSettings.ProductStatus.LIVE || null,
    v: apiAdsMerchantSettings.v,
  }

  return adsMerchantSettings
}

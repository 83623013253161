import type { FieldValues } from "react-hook-form"
import { useForm as useReactHookForm } from "react-hook-form"

export function useForm<T extends FieldValues>( values: FieldValues ) {
  return useReactHookForm<T>( {
    ...values,
    mode: "all",
    shouldUnregister: true,
  } )
}

import { createSelector } from "@reduxjs/toolkit"

import type { ConnectState } from "./connectStore"

type ConnectStateSelector<TRootState> = [
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ( state: TRootState, ...params: any[] ) => ConnectState
]

export const connectSelector = <TRootState extends { connect: ConnectState }>( state: TRootState ) => state.connect

export const createConnectSelector = <TRootState extends { connect: ConnectState }, TReturnValue>( combiner: ( connectState: ConnectState ) => TReturnValue ) => {
  return createSelector<ConnectStateSelector<TRootState>, TReturnValue>(
    connectSelector,
    combiner,
  )
}

const activeCallSelector = createConnectSelector(
  ( connectState ) => connectState.calls.activeCall,
)

export const connectSelectors = {
  assistant: {
    current: createConnectSelector( ( connectState ) => connectState.assistant.current ),
  },
  assistant_flow_template: {
    all: createConnectSelector( ( connectState ) => Object.values( connectState.assistant_flow_template.byId ) ),
    byId: ( templateId: string | null ) => {
      return createConnectSelector(
        ( connectState ) => {
          if( templateId == null ) {
            return null
          }
          return connectState.assistant_flow_template.byId[ templateId ] || null
        },
      )
    },
  },
  audio: {
    currentInputId: createConnectSelector(
      ( connectState ) => connectState.audio.currentInputId,
    ),
    currentOutputId: createConnectSelector(
      ( connectState ) => connectState.audio.currentOutputId,
    ),
    inputDevices: createConnectSelector(
      ( connectState ) => connectState.audio.inputDevices,
    ),
    outputDevices: createConnectSelector(
      ( connectState ) => connectState.audio.outputDevices,
    ),
  },
  calls: {
    activeCall: activeCallSelector,
    activeCallDigits: createSelector(
      activeCallSelector,
      ( activeCall ) => {
        if( ! activeCall?.digits ) {
          return ""
        }

        return activeCall.digits
      },
    ),
    activeCallStartedAt: createSelector(
      activeCallSelector,
      ( activeCall ) => {
        if( ! activeCall ) {
          return null
        }

        return activeCall.startedAt
      },
    ),
    current: createSelector(
      connectSelector,
      activeCallSelector,
      ( connectState, activeCall ) => {
        if( ! activeCall ) {
          return null
        }

        return connectState.calls.byId[ activeCall.connectCallId ]
      },
    ),
    incomingCalls: createSelector(
      connectSelector,
      createConnectSelector( ( connectState ) =>
        connectState.calls.incomingCalls,
      ),
      ( connectState, incomingCalls ) =>
        incomingCalls.map( ( incomingCall ) => connectState.calls.byId[ incomingCall.connectCallId ] ),
    ),
    byIds: ( callIds: string[] ) => {
      return createConnectSelector(
        ( connectState ) => callIds.map( ( callId ) => connectState.calls.byId[ callId ] ).filter( ( call ) => call != null ),
      )
    },
  },
  channels: {
    current: createConnectSelector( ( connectState ) => connectState.channels.current ),
  },
  inboxes: {
    all: createConnectSelector(
      ( connectState ) => connectState.inboxes.all,
    ),
  },
  voice: createConnectSelector(
    ( connectState ) => connectState.voice,
  ),
}

import type { Action, AnyAction, ConfigureStoreOptions, EnhancedStore, Middleware, ThunkDispatch } from "@reduxjs/toolkit"
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"
import { useDispatch } from "react-redux"
import createSagaMiddleware from "redux-saga"

import { createRootSaga } from "@onelocal/frontend/common"
// eslint-disable-next-line @nx/enforce-module-boundaries
import type { AuthState } from "@onelocal/frontend-admin-web/auth"
// eslint-disable-next-line @nx/enforce-module-boundaries
import { authStoreConfig } from "@onelocal/frontend-admin-web/auth"
import type { CommonState } from "@onelocal/frontend-admin-web/common"
import { commonStoreConfig } from "@onelocal/frontend-admin-web/common"
import type { ConnectState } from "@onelocal/frontend-admin-web/connect"
import { connectStoreConfig } from "@onelocal/frontend-admin-web/connect"
import type { ContentState } from "@onelocal/frontend-admin-web/content"
import { contentStoreConfig } from "@onelocal/frontend-admin-web/content"
import type { MerchantsState } from "@onelocal/frontend-admin-web/merchants"
import { merchantsStoreConfig } from "@onelocal/frontend-admin-web/merchants"
import type { OnboardingState } from "@onelocal/frontend-admin-web/onboarding"
import { onboardingStoreConfig } from "@onelocal/frontend-admin-web/onboarding"
import type { SiteState } from "@onelocal/frontend-admin-web/site"
import { siteStoreConfig } from "@onelocal/frontend-admin-web/site"
import { type MainState, mainStoreConfig } from "./mainStore"

export interface RootState {
  auth: AuthState
  common: CommonState
  connect: ConnectState
  content: ContentState
  main: MainState
  merchants: MerchantsState
  onboarding: OnboardingState
  site: SiteState
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RootDispatch = ThunkDispatch<RootState, any, Action>

export function useRootDispatch(): RootDispatch {
  return useDispatch<RootDispatch>()
}

export let currentAdminStore: EnhancedStore<RootState, AnyAction, ReadonlyArray<Middleware<unknown, unknown>>> | null = null

export function createStore() {
  const sagaMiddleware = createSagaMiddleware()

  const middlewares = [
    ...getDefaultMiddleware( {
      immutableCheck: false,
    } ),
    sagaMiddleware,
  ]

  const storeOptions: ConfigureStoreOptions = {
    reducer: {
      [ authStoreConfig.key ]: authStoreConfig.reducers,
      [ commonStoreConfig.key ]: commonStoreConfig.reducers,
      [ connectStoreConfig.key ]: connectStoreConfig.reducers,
      [ contentStoreConfig.key ]: contentStoreConfig.reducers,
      [ mainStoreConfig.key ]: mainStoreConfig.reducers,
      [ merchantsStoreConfig.key ]: merchantsStoreConfig.reducers,
      [ onboardingStoreConfig.key ]: onboardingStoreConfig.reducers,
      [ siteStoreConfig.key ]: siteStoreConfig.reducers,
    },
    middleware: middlewares,
  }

  const store = configureStore( storeOptions )

  sagaMiddleware.run( createRootSaga, [
    authStoreConfig,
    commonStoreConfig,
    connectStoreConfig,
    contentStoreConfig,
    mainStoreConfig,
    merchantsStoreConfig,
    onboardingStoreConfig,
  ] )

  currentAdminStore = store

  return store
}

import React, { useCallback, useMemo, useState } from "react"
import { useEffectOnce } from "react-use"
import styled from "styled-components"

import { useDebounce } from "@onelocal/frontend/common"
import { LoadingOverlay } from "@onelocal/frontend-admin-web/common"
import type { SelectOption } from "@onelocal/frontend-web/common"
import { Flex, Grid, GridItem, Page, ReadOnlyField, SearchBar, SelectInline, StyledTable, styleHelpers, useAlert } from "@onelocal/frontend-web/common"
import { searchHelpers, utilHelpers } from "@onelocal/shared/common"
import { connectMerchantSettingsHelpers } from "../helpers/merchantSettingsHelpers"
import { connectMerchantSettingsService } from "../services/merchantSettingsService"
import { ConnectMerchantPhoneNumber, ConnectMerchantSettings, ConnectMerchantSummary } from "../types"

const displayStatus: Record<ConnectMerchantSummary.Status, string> = {
  [ ConnectMerchantSummary.Status.COMPLETED ]: "Completed",
  [ ConnectMerchantSummary.Status.ERROR ]: "Error",
  [ ConnectMerchantSummary.Status.IN_PROGRESS ]: "In Progress",
  [ ConnectMerchantSummary.Status.NOT_STARTED ]: "Not Started",
}

const ALL_VALUE = "all"

const statusesOptions: Array<SelectOption<string>> = [
  {
    label: "All",
    value: ALL_VALUE,
  },
  ...[
    ConnectMerchantSummary.Status.ERROR,
    ConnectMerchantSummary.Status.NOT_STARTED,
    ConnectMerchantSummary.Status.IN_PROGRESS,
    ConnectMerchantSummary.Status.COMPLETED,
  ].map<SelectOption<string>>( ( status ) => ( {
    label: displayStatus[ status ],
    value: status,
  } ) ),
]

const smsBlockedOptions: Array<SelectOption<string>> = [
  {
    label: "All",
    value: ALL_VALUE,
  },
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
]

const sortedStatus: ConnectMerchantSummary.Status[] = [
  ConnectMerchantSummary.Status.ERROR,
  ConnectMerchantSummary.Status.NOT_STARTED,
  ConnectMerchantSummary.Status.IN_PROGRESS,
  ConnectMerchantSummary.Status.COMPLETED,
]

const sortSummaries = ( merchantSummaryA: ConnectMerchantSummary, merchantSummaryB: ConnectMerchantSummary ) => {
  if( merchantSummaryA.smsOutboundBlocked !== merchantSummaryB.smsOutboundBlocked ) {
    if( merchantSummaryA.smsOutboundBlocked === false ) {
      return 1
    } else {
      return -1
    }
  } else if( merchantSummaryA.status !== merchantSummaryB.status ) {
    if( sortedStatus.indexOf( merchantSummaryA.status.type ) > sortedStatus.indexOf( merchantSummaryB.status.type ) ) {
      return 1
    } else {
      return -1
    }
  } else {
    return utilHelpers.localCompare( merchantSummaryA.merchantName, merchantSummaryB.merchantName )
  }
}

export interface MerchantPhoneNumbersPageProps {
}

export const MerchantPhoneNumbersPage: React.FC<MerchantPhoneNumbersPageProps> = () => {
  const { showError } = useAlert()
  const [ merchantSummaries, setMerchantSummaries ] = useState<ConnectMerchantSummary[] | null>( null )
  const [ isLoading, setIsLoading ] = useState( true )
  const [ searchText, setSearchText ] = useState<string>( "" )
  const [ selectedStatus, setSelectedStatus ] = useState<string>( ALL_VALUE )
  const [ selectedSmsBlocked, setSelectedSmsBlocked ] = useState<string>( ALL_VALUE )
  const { debouncedValue: debounceSearchText } = useDebounce( searchText, 300 )

  const onStatusOptionChanged = useCallback( ( value: ConnectMerchantSettings.A2PStatus ) => {
    setSelectedStatus( value )
  }, [] )

  const onSmsBlockedOptionChanged = useCallback( ( value: string ) => {
    setSelectedSmsBlocked( value )
  }, [] )

  const filteredMerchantSummaries = useMemo( () => {
    if( ! merchantSummaries ) {
      return merchantSummaries
    }

    const searchRegexes = debounceSearchText ? searchHelpers.generateSearchRegexes( debounceSearchText ) : null

    return merchantSummaries.filter( ( merchantSummary ) => {
      if( selectedStatus !== ALL_VALUE ) {
        if( merchantSummary.status.type !== selectedStatus ) {
          return false
        }
      }

      if( selectedSmsBlocked !== ALL_VALUE ) {
        if( ( selectedSmsBlocked === "yes" && ! merchantSummary.smsOutboundBlocked ) || ( selectedSmsBlocked === "no" && merchantSummary.smsOutboundBlocked ) ) {
          return false
        }
      }

      if( searchRegexes ) {
        const tokens = searchHelpers.generateTokenIndex( merchantSummary.merchantName )
        let textMatch = false

        for( const searchRegex of searchRegexes ) {
          for( const token of tokens ) {
            if( searchRegex.test( token ) ) {
              textMatch = true
              break
            }
          }

          if( textMatch ) {
            break
          }
        }

        if( ! textMatch ) {
          return false
        }
      }

      return true
    } )

  }, [ debounceSearchText, merchantSummaries, selectedSmsBlocked, selectedStatus ] )

  const stats = useMemo( () => {
    const result = {
      merchantA2pCompletedCount: 0,
      merchantA2pErrorCount: 0,
      merchantA2pInProgressCount: 0,
      merchantErrorCount: 0,
      merchantTollFreeCompletedCount: 0,
      merchantTollFreeErrorCount: 0,
      merchantTollFreeInProgressCount: 0,
      smsBlockedCount: 0,
    }

    if( ! merchantSummaries ) {
      return result
    }

    for( const merchantSummary of merchantSummaries ) {
      if( merchantSummary.smsOutboundBlocked ) {
        result.smsBlockedCount += 1
      }

      if( merchantSummary.a2p.status ) {
        switch( merchantSummary.a2p.status ) {
          case ConnectMerchantSettings.A2PStatus.INVALID:
            result.merchantA2pErrorCount += 1
            break
          case ConnectMerchantSettings.A2PStatus.IN_PROGRESS:
          case ConnectMerchantSettings.A2PStatus.IN_REVIEW:
            result.merchantA2pInProgressCount += 1
            break
          case ConnectMerchantSettings.A2PStatus.REGISTERED:
            result.merchantA2pCompletedCount += 1
            break
        }
      }

      for( const phoneNumber of merchantSummary.phoneNumbers ) {
        if( phoneNumber.type === ConnectMerchantPhoneNumber.Type.TOLL_FREE ) {
          if( phoneNumber.twilioVerificationInstance?.status ) {
            switch( phoneNumber.twilioVerificationInstance?.status ) {
              case ConnectMerchantPhoneNumber.VerificationStatus.IN_REVIEW:
              case ConnectMerchantPhoneNumber.VerificationStatus.PENDING_REVIEW:
                result.merchantTollFreeInProgressCount += 1
                break
              case ConnectMerchantPhoneNumber.VerificationStatus.TWILIO_REJECTED:
                result.merchantTollFreeErrorCount += 1
                break
              case ConnectMerchantPhoneNumber.VerificationStatus.TWILIO_APPROVED:
                result.merchantTollFreeCompletedCount += 1
                break
            }
          } else {
            result.merchantTollFreeErrorCount += 1
          }
        }
      }
    }

    return result
  }, [ merchantSummaries ] )

  useEffectOnce( () => {
    async function run() {
      try {
        let updatedSummaries = await connectMerchantSettingsService.queryMerchantSummaries()
        updatedSummaries = updatedSummaries.sort( sortSummaries )
        setMerchantSummaries( updatedSummaries )
      } catch( err ) {
        showError( err )
      } finally {
        setIsLoading( false )
      }
    }

    run()
  } )

  return (
    <Page
      buttons={ [] }
      title="Merchant Phone Numbers"
    >
      <Grid>
        <GridItem xs={ 3 }>
          <ReadOnlyField label="Merchant with SMS Blocked" containerStyle={ { marginRight: "50px" } } value={ stats.smsBlockedCount } />
        </GridItem>
        <GridItem xs={ 3 }>
          <ReadOnlyField label="Merchant with Error" value={ stats.merchantErrorCount } />
        </GridItem>
        <GridItem xs={ 3 }>
          <ReadOnlyField label="Merchant with A2P Error" value={ stats.merchantA2pErrorCount } />
        </GridItem>
        <GridItem xs={ 3 }>
          <ReadOnlyField label="Merchant with A2P In Progress" value={ stats.merchantA2pInProgressCount } />
        </GridItem>
        <GridItem xs={ 3 }>
          <ReadOnlyField label="Merchant with A2P Completed" value={ stats.merchantA2pCompletedCount } />
        </GridItem>
        <GridItem xs={ 3 }>
          <ReadOnlyField label="Merchant with Toll Free Error" value={ stats.merchantTollFreeErrorCount } />
        </GridItem>
        <GridItem xs={ 3 }>
          <ReadOnlyField label="Merchant with Toll In Progress" value={ stats.merchantTollFreeInProgressCount } />
        </GridItem>
        <GridItem xs={ 3 }>
          <ReadOnlyField label="Merchant with Toll Completed" value={ stats.merchantTollFreeCompletedCount } />
        </GridItem>
      </Grid>
      <Flex
        alignItems="center"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        style={ {
          margin: "25px 0",
        } }
      >
        <SearchBar
          onChange={ setSearchText }
          placeholder="Search by Merchant Name"
          style={ {
            flexGrow: 1,
            marginRight: "40px",
            maxWidth: "350px",
          } }
          value={ searchText }
        />
        <Flex
          alignItems="center"
          display="flex"
          flexDirection="row"
        >
          <SelectInline
            label="SMS Blocked"
            onChange={ onSmsBlockedOptionChanged }
            options={ smsBlockedOptions }
            style={ { marginRight: "10px" } }
            value={ selectedSmsBlocked }
          />
          <SelectInline
            label="Status"
            onChange={ onStatusOptionChanged }
            options={ statusesOptions }
            value={ selectedStatus }
          />
        </Flex>
      </Flex>
      { <LoadingOverlay isLoading={ isLoading } /> }
      {
        filteredMerchantSummaries != null && isLoading === false && (
          <StyledTable>
            <thead>
              <tr>
                <th>Merchant Name</th>
                <th>Status</th>
                <th>SMS Blocked</th>
                <th>Merchant Details</th>
                <th>Phone Number</th>
                <th>Phone Number Status</th>
                <th>Phone Number Details</th>
              </tr>
            </thead>
            <tbody>
              {
                filteredMerchantSummaries.length > 0
                  ? (
                    filteredMerchantSummaries.map( ( merchantSummary ) => (
                      <MerchantSumarryRows key={ merchantSummary.merchantId } merchantSummary={ merchantSummary } />
                    ) )
                  )
                  : (
                    <tr>
                      <td colSpan={ 8 }>
                        <Flex
                          alignItems="center"
                          display="flex"
                          justifyContent="center"
                          style={ { height: "300px" } }
                        >
                          No merchant found. Please try to adjust your search or filters.
                        </Flex>
                      </td>
                    </tr>
                  )
              }
              {

              }
            </tbody>
          </StyledTable>
        )
      }
    </Page>
  )
}

const MerchantSummaryRow = styled.tr<{ $smsBlocked: boolean }>`
  td {
    background-color: ${ ( props ) => props.$smsBlocked ? "#FFF0EF" : "none" };
    padding: 15px;
  }
`

const PhoneNumberActiveLabel = styled.span`
  color: ${ styleHelpers.colors.darkGray };
  font-size: ${ styleHelpers.fonts.sizes.small };
  margin-left: 5px;
`

const getPhoneNumberStatusLabel = ( merchantSummary: ConnectMerchantSummary, phoneNumber: ConnectMerchantSummary.PhoneNumber ) => {
  if( phoneNumber.type === ConnectMerchantPhoneNumber.Type.TOLL_FREE ) {
    if( ! phoneNumber.twilioVerificationInstance?.status ) {
      return "Not register"
    }

    const tollFreeDisplayStatuses: Record<ConnectMerchantPhoneNumber.VerificationStatus, string> = {
      [ ConnectMerchantPhoneNumber.VerificationStatus.IN_REVIEW ]: "In Review",
      [ ConnectMerchantPhoneNumber.VerificationStatus.PENDING_REVIEW ]: "Pending Review",
      [ ConnectMerchantPhoneNumber.VerificationStatus.TWILIO_APPROVED ]: "Twilio Approved",
      [ ConnectMerchantPhoneNumber.VerificationStatus.TWILIO_REJECTED ]: "Twilio Rejected",
    }

    return tollFreeDisplayStatuses[ phoneNumber.twilioVerificationInstance.status ]
  }

  if( ! merchantSummary.a2p.status ) {
    return "Not register"
  }

  return connectMerchantSettingsHelpers.getA2PStatus( merchantSummary.a2p.status )
}

const getPhoneNumberDetailsHtml = ( merchantSummary: ConnectMerchantSummary, phoneNumber: ConnectMerchantSummary.PhoneNumber ) => {
  if( phoneNumber.type === ConnectMerchantPhoneNumber.Type.TOLL_FREE ) {
    return phoneNumber.twilioVerificationInstance?.error || ""
  }

  if( ! merchantSummary.a2p.errors ) {
    return ""
  }

  return merchantSummary.a2p.errors.join( "<br />" )
}

interface MerchantSummaryRowsProps {
  merchantSummary: ConnectMerchantSummary
}

const MerchantSumarryRows: React.FC<MerchantSummaryRowsProps> = React.memo( ( { merchantSummary } ) => {
  const phoneNumbers = [ ...merchantSummary.phoneNumbers ].sort( ( phoneNumberA, phoneNumberB ) => {
    if( phoneNumberA.status !== phoneNumberB.status ) {
      if( phoneNumberA.status === ConnectMerchantPhoneNumber.Status.ACTIVE ) {
        return -1
      } else {
        return 1
      }
    }

    return utilHelpers.localCompare( phoneNumberA.value, phoneNumberB.value )
  } )

  return (
    <>
      {
        phoneNumbers.map( ( phoneNumber ) => (
          <MerchantSummaryRow key={ phoneNumber.id } $smsBlocked={ merchantSummary.smsOutboundBlocked }>
            <td style={ { maxWidth: "250px", wordBreak: "break-all" } }>{ merchantSummary.merchantName || "-" }  </td>
            <td>{ merchantSummary.status.type || "-" }  </td>
            <td>{ merchantSummary.smsOutboundBlocked ? "Yes" : "No" }  </td>
            <td>{ merchantSummary.status.details || "-" }</td>
            <td>
              { phoneNumber.nationalFormat }
              { phoneNumber.status === ConnectMerchantPhoneNumber.Status.ACTIVE && (
                <PhoneNumberActiveLabel>(Active)</PhoneNumberActiveLabel>
              ) }
            </td>
            <td>{ getPhoneNumberStatusLabel( merchantSummary, phoneNumber ) }</td>
            <td dangerouslySetInnerHTML={ { __html: getPhoneNumberDetailsHtml( merchantSummary, phoneNumber ) } }/>
          </MerchantSummaryRow>
        ) )
      }
    </>
  )
} )

import { isEmpty } from "lodash"
import React, { useCallback, useMemo } from "react"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { useSelector } from "react-redux"
import { useUpdateEffect } from "react-use"
import styled from "styled-components"

import { AnalyticHelper, isValidPhoneNumber, mixpanelHelpers } from "@onelocal/frontend-dashboard/common"
import {
  connectCallHelpers,
  ConnectChannel,
  connectSelectors,
  RING_DURATION_VALUE_MAX,
  RING_DURATION_VALUE_MIN,
} from "@onelocal/frontend-dashboard/connect"
import {
  Box,
  CheckBox,
  Flex,
  InfoIcon,
  RadioGroup,
  SectionTooltip,
  StyledSectionTitle,
  styleHelpers,
  TextField,
  useSectionTooltipState,
} from "@onelocal/frontend-web/common"
import { ProductNames } from "@onelocal/shared/common"
import { BusinessHoursList } from "../../../../components/BusinessHoursList"
import { useBusinessHourEditModal } from "../../../../modals"
import { IncomingCallSettingsFormFieldKeys } from "../../../../types"

const BusinessHoursActionSection = styled.div`
  flex: 1;
  padding: 0 40px;
`

const BusinessHoursContentSection = styled.div`
  border-right: 1px solid ${ styleHelpers.colors.default };
  flex: 1;
  display: flex;
  flex-direction: column;
`

const BusinessHourEditButton = styled.a`
  color: ${ styleHelpers.colors.primary };
  cursor: pointer;
  font-size: 14px;
  font-weight: ${ styleHelpers.fonts.weight.semiBold };
  margin-top: 10px;
  text-decoration: none;
`

const BusinessHoursSectionContainer = styled.div`
  display: flex;
  margin-top: 20px;
`

const StyledTextField = styled( TextField )`
  max-width: 220px;
`

export enum FormBusinessHoursKeys {
  BUSINESS_HOURS_ACTION_TYPE = "businessHoursActionType",
  BUSINESS_HOURS_ENABLED = "businessHoursEnabled",
  BUSINESS_HOURS_FORWARD_PHONE_NUMBER = "businessHoursForwardPhoneNumber",
  BUSINESS_HOURS_FORWARD_RING_DURATION = "businessHoursForwardRingDuration",
}

export interface IncomingCallBusinessHoursProps {
  getFieldData( fieldPath: string, defaultValue?: number ): string
}

export const IncomingCallBusinessHours: React.FC<IncomingCallBusinessHoursProps> = ( { getFieldData } ) => {
  const { showBusinessHourEditModal } = useBusinessHourEditModal()
  const { control, formState, setValue, getValues } = useFormContext()
  const connectChannel = useSelector( connectSelectors.channels.current )
  const connectAssistant = useSelector( connectSelectors.assistant.current )
  const { displayTooltip, closeTooltip, toggleTooltip } = useSectionTooltipState()
  const businessHoursActionTypeWatcher = useWatch( {
    name: IncomingCallSettingsFormFieldKeys.BUSINESS_HOURS_ACTION_TYPE,
    defaultValue: getFieldData( "action.type" ),
  } )
  const businessHoursEnabledWatcher = useWatch( {
    name: IncomingCallSettingsFormFieldKeys.BUSINESS_HOURS_ENABLED,
    defaultValue: getFieldData( "enabled" ),
  } )

  useUpdateEffect( () => {
    if( businessHoursEnabledWatcher ) {
      const existingActionType = getFieldData( "action.type" )
      if( ! businessHoursActionTypeWatcher ) {
        if( existingActionType
          && getValues( IncomingCallSettingsFormFieldKeys.BUSINESS_HOURS_ACTION_TYPE ) !== existingActionType
        ) {
          setValue(
            IncomingCallSettingsFormFieldKeys.BUSINESS_HOURS_ACTION_TYPE,
            existingActionType,
          )
        }
      }

      if( existingActionType === ConnectChannel.BusinessHourActionType.FORWARD ) {
        const existingPhoneNumber = getFieldData( "action.phoneNumber.value" )
        if( ! getValues( IncomingCallSettingsFormFieldKeys.BUSINESS_HOURS_FORWARD_PHONE_NUMBER ) && existingPhoneNumber ) {
          setValue(
            IncomingCallSettingsFormFieldKeys.BUSINESS_HOURS_FORWARD_PHONE_NUMBER,
            existingPhoneNumber,
          )
        }
      }
    }
  }, [ businessHoursActionTypeWatcher, businessHoursEnabledWatcher, getFieldData, setValue ] )

  const onEditModalOpen = useCallback( () => {
    mixpanelHelpers.trackEvent(
      AnalyticHelper.EventName.CTA_CLICKED,
      {
        "Name": "Add Business Hours OR Edit Business Hours",
        "Location": "Settings/LocalMessages/Incoming Calls",
        "Product": ProductNames.LOCAL_MESSAGES,
      },
    )
    showBusinessHourEditModal()
  }, [ showBusinessHourEditModal ] )

  const businessHoursActionTypeOptions = useMemo( () => {
    return [
      {
        label: `Send to ${ isEmpty( connectAssistant?.flows ) ? "Voicemail" : "LocalResponse" }`,
        value: ConnectChannel.BusinessHourActionType.ASSISTANT,
      },
      {
        label: "Forward to a number",
        value: ConnectChannel.BusinessHourActionType.FORWARD,
      },
    ]
  }, [ connectAssistant ] )

  return (
    <Box containerStyle={ { padding: "20px 40px" } }>
      <Flex
        alignItems="center"
        display="flex"
        flex={ 1 }
        justifyContent="space-between"
      >
        <Flex alignItems="center" display="flex">
          <StyledSectionTitle>Business Hours</StyledSectionTitle>
          <InfoIcon onClick={ toggleTooltip } />
        </Flex>
        <Controller
          control={ control }
          name={ IncomingCallSettingsFormFieldKeys.BUSINESS_HOURS_ENABLED }
          render={ ( { field: { onChange, value } } ) => (
            <CheckBox
              checked={ value }
              checkboxColor="primary"
              onChange={ onChange }
              label="Enable Business Hours"
              name="business-hours-checkbox"
              noLabelWrap={ true }
            />
          ) }
        />
      </Flex>
      <SectionTooltip
        bulletItems={ [
          { title: "Enable Business Hours", description: "allows you to define how incoming calls are handled outside of your business hours." },
          { title: "Edit Business Hours", description: "allows you to edit or add to your existing business hours." },
          { title: "Send to LocalResponse", description: "selecting this option means all incoming calls received outside business hours will be sent to your virtual assistant." },
          { title: "Forward to a number", description: "selecting this option means all incoming calls received outside business hours will be forwarded to the specified \"Forwarding Number\" and will ring for the amount of seconds specified in the \"Ring Duration\" field." },
        ] }
        containerMarginBottom={ businessHoursEnabledWatcher ? 8 : undefined }
        onClose={ closeTooltip }
        showSection={ displayTooltip }
      />
      {
        businessHoursEnabledWatcher && (
          <BusinessHoursSectionContainer>
            <BusinessHoursContentSection>
              <Flex alignItems="center" display="flex" style={ { marginBottom: 23 } }>
                <StyledSectionTitle >Business Hours</StyledSectionTitle>
              </Flex>
              {
                ! isEmpty( connectChannel?.businessHours ) && (
                  <BusinessHoursList businessHours={ connectChannel?.businessHours || [] } />
                )
              }
              <BusinessHourEditButton onClick={ onEditModalOpen }>
                { isEmpty( connectChannel?.businessHours ) ? "+ Add" : "Edit" } Business Hours
              </BusinessHourEditButton>
            </BusinessHoursContentSection>
            <BusinessHoursActionSection>
              <Flex alignItems="center" display="flex" style={ { marginBottom: 15 } }>
                <StyledSectionTitle>Outside Business Hours</StyledSectionTitle>
              </Flex>
              <div style={ { paddingLeft: 10 } }>
                <Controller
                  control={ control }
                  name={ IncomingCallSettingsFormFieldKeys.BUSINESS_HOURS_ACTION_TYPE }
                  render={ ( { field: { onChange, value } } ) => (
                    <RadioGroup
                      options={ businessHoursActionTypeOptions }
                      onChange={ onChange }
                      value={ value || getFieldData( "action.type" ) }
                    />
                  ) }
                />
              </div>
              {
                businessHoursEnabledWatcher && businessHoursActionTypeWatcher === ConnectChannel.BusinessHourActionType.FORWARD && (
                  <Flex
                    display="flex"
                    flexDirection="column"
                    style={ {
                      fontSize: 14,
                      marginTop: 25,
                      gap: "25px 0px",
                    } }
                  >
                    <Controller
                      control={ control }
                      name={ IncomingCallSettingsFormFieldKeys.BUSINESS_HOURS_FORWARD_PHONE_NUMBER }
                      render={ ( { field: { onChange, value } } ) => (
                        <StyledTextField
                          errorMessage={ formState.errors?.[ IncomingCallSettingsFormFieldKeys.BUSINESS_HOURS_FORWARD_PHONE_NUMBER ]?.message as string }
                          helperText="Enter a phone number"
                          label="Forwarding Number"
                          onChange={ onChange }
                          required={ businessHoursActionTypeWatcher === ConnectChannel.BusinessHourActionType.FORWARD }
                          value={ value || "" }
                        />
                      ) }
                      rules={ {
                        validate: ( value ) => {
                          if( ! businessHoursEnabledWatcher || businessHoursActionTypeWatcher !== ConnectChannel.BusinessHourActionType.FORWARD ) {
                            return undefined
                          }
                          if( ! value ) {
                            return "Phone number is required"
                          }
                          if( ! isValidPhoneNumber( value ) ) {
                            return "Phone number is invalid"
                          }
                          return undefined
                        },
                      } }
                    />
                    <Controller
                      control={ control }
                      name={ IncomingCallSettingsFormFieldKeys.BUSINESS_HOURS_FORWARD_RING_DURATION }
                      render={ ( { field: { onChange, value } } ) => (
                        <StyledTextField
                          errorMessage={ formState?.errors?.[ IncomingCallSettingsFormFieldKeys.BUSINESS_HOURS_FORWARD_RING_DURATION ]?.message as string }
                          onChange={ onChange }
                          label="Ring Duration (Seconds)"
                          max={ RING_DURATION_VALUE_MAX }
                          min={ RING_DURATION_VALUE_MIN }
                          required={ businessHoursActionTypeWatcher === ConnectChannel.BusinessHourActionType.FORWARD }
                          type="number"
                          value={ value }
                        />
                      ) }
                      rules={ { validate: connectCallHelpers.validateRingDuration } }
                    />
                  </Flex>
                )
              }
            </BusinessHoursActionSection>
          </BusinessHoursSectionContainer>
        )
      }
    </Box>
  )
}

// @todo Updating this file when on the home screen it break the HMR

// import { ConfigEnv, configHelper } from "./configHelper"
import { utilHelpers } from "@onelocal/shared/common"
import { InternalError } from "./errorHelper"

export enum LogType {
  ERROR = "error",
  INFO = "info",
  WARN = "warn",
}

export const logHelpers = {
  error( message: string, ...params: unknown[] ) {
    logHelpers.log( LogType.ERROR, message, params )
  },
  info( message: string, ...params: unknown[] ) {
    logHelpers.log( LogType.INFO, message, params )
  },
  log( type: LogType, message: string, ...params: unknown[] ) {
    // if( configHelper.ENV === ConfigEnv.TEST ) {
    //   return
    // }

    switch( type ) {
      case LogType.ERROR:
        console.error( message, ...params )
        break
      case LogType.INFO:
        console.info( message, ...params )
        break
      case LogType.WARN:
        console.warn( message, ...params )
        break
      default:
        utilHelpers.assertNever( type )
        throw new InternalError( `Unsupported type ${ type }` )
    }
  },
  warn( message: string, ...params: unknown[] ) {
    logHelpers.log( LogType.WARN, message, params )
  },
}

import type { Action, AnyAction, ConfigureStoreOptions, EnhancedStore, Middleware, ThunkAction, ThunkDispatch } from "@reduxjs/toolkit"
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"
import { useDispatch } from "react-redux"
import createSagaMiddleware from "redux-saga"

import { createRootSaga } from "@onelocal/frontend/common"
import type { AuthState } from "@onelocal/frontend-dashboard/auth"
import { authStoreConfig } from "@onelocal/frontend-dashboard/auth"
import type { CommonState } from "@onelocal/frontend-dashboard/common"
import { commonStoreConfig } from "@onelocal/frontend-dashboard/common"
import type { ConnectState } from "@onelocal/frontend-dashboard/connect"
import { connectStoreConfig } from "@onelocal/frontend-dashboard/connect"
import type { ContentState } from "@onelocal/frontend-dashboard/content"
import { contentStoreConfig } from "@onelocal/frontend-dashboard/content"
import type { IntegrationsState } from "@onelocal/frontend-dashboard/integrations"
import { integrationsStoreConfig } from "@onelocal/frontend-dashboard/integrations"
import type { MainState } from "@onelocal/frontend-dashboard/main"
import { mainStoreConfig } from "@onelocal/frontend-dashboard/main"
import type { ReviewsState } from "@onelocal/frontend-dashboard/reviews"
import { reviewsStoreConfig } from "@onelocal/frontend-dashboard/reviews"
import type { SettingsState } from "@onelocal/frontend-dashboard/settings"
import { settingsStoreConfig } from "@onelocal/frontend-dashboard/settings"
import type { SiteState } from "@onelocal/frontend-dashboard/site"
import { siteStoreConfig } from "@onelocal/frontend-dashboard/site"

export interface RootState {
  auth: AuthState
  common: CommonState
  connect: ConnectState
  content: ContentState
  main: MainState
  reviews: ReviewsState
  settings: SettingsState
  site: SiteState
  integrations: IntegrationsState
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RootDispatch = ThunkDispatch<RootState, any, Action>
export type RootThunk<ReturnType = void> = ThunkAction<
  Promise<ReturnType>,
  RootState,
  unknown,
  Action<string>
>

export function useRootDispatch(): RootDispatch {
  return useDispatch<RootDispatch>()
}

export let currentDashboardStore: EnhancedStore<unknown, AnyAction, ReadonlyArray<Middleware<unknown, unknown>>> | null = null

export function createStore() {
  const sagaMiddleware = createSagaMiddleware()

  const middlewares = [
    ...getDefaultMiddleware( {
      immutableCheck: false,
    } ),
    sagaMiddleware,
  ]

  const storeOptions: ConfigureStoreOptions = {
    reducer: {
      [ authStoreConfig.key ]: authStoreConfig.reducers,
      [ commonStoreConfig.key ]: commonStoreConfig.reducers,
      [ connectStoreConfig.key ]: connectStoreConfig.reducers,
      [ contentStoreConfig.key ]: contentStoreConfig.reducers,
      [ mainStoreConfig.key ]: mainStoreConfig.reducers,
      [ reviewsStoreConfig.key ]: reviewsStoreConfig.reducers,
      [ settingsStoreConfig.key ]: settingsStoreConfig.reducers,
      [ siteStoreConfig.key ]: siteStoreConfig.reducers,
      [ integrationsStoreConfig.key ]: integrationsStoreConfig.reducers,
    },
    middleware: middlewares,
  }

  const store = configureStore( storeOptions )

  sagaMiddleware.run( createRootSaga, [
    authStoreConfig,
    commonStoreConfig,
    connectStoreConfig,
    reviewsStoreConfig,
    settingsStoreConfig,
    siteStoreConfig,
  ] )

  currentDashboardStore = store

  return store
}

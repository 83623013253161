import CheckIcon from "@mui/icons-material/Check"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import type { SubmitHandler } from "react-hook-form"
import { Controller, FormProvider } from "react-hook-form"
import { useEffectOnce } from "react-use"
import styled from "styled-components"

import { useAsyncDispatch, useForm } from "@onelocal/frontend/common"
// eslint-disable-next-line @nx/enforce-module-boundaries
import type { MerchantSettings } from "@onelocal/frontend-admin-web/merchants"
import { isValidEmail, isValidPhoneNumber } from "@onelocal/frontend-dashboard/common"
import type { DialogAction, DialogAlert, SelectOption } from "@onelocal/frontend-web/common"
import { CheckBox, Dialog, DialogAlertType, Grid, GridItem, Select, StyledFormSectionTitle, StyledText, TextField, useAlert, useModalWithParams } from "@onelocal/frontend-web/common"
import { useSiteTemplates } from "../hooks/useSiteTemplates"
import { siteService } from "../services/siteService"
import { siteActions } from "../store/siteStore"
import { Site } from "../types"

const siteLabels = {
  [ Site.Type.LANDING_PAGE ]: "Landing Page",
  [ Site.Type.WEBSITE ]: "Website",
}

const FACEBOOK_INSTAGRAM_ID_REGEX = /^[a-zA-Z0-9._-]+$/i

const businessTypeOptions: Array<SelectOption<string>> = [
  { label: "AnimalShelter", value: "AnimalShelter" },
  { label: "ArchiveOrganization", value: "ArchiveOrganization" },
  { label: "AutomotiveBusiness", value: "AutomotiveBusiness" },
  { label: "AutomotiveBusiness - AutoBodyShop", value: "AutoBodyShop" },
  { label: "AutomotiveBusiness - AutoDealer", value: "AutoDealer" },
  { label: "AutomotiveBusiness - AutoPartsStore", value: "AutoPartsStore" },
  { label: "AutomotiveBusiness - AutoRental", value: "AutoRental" },
  { label: "AutomotiveBusiness - AutoRepair", value: "AutoRepair" },
  { label: "AutomotiveBusiness - AutoWash", value: "AutoWash" },
  { label: "AutomotiveBusiness - GasStation", value: "GasStation" },
  { label: "AutomotiveBusiness - MotorcycleDealer", value: "MotorcycleDealer" },
  { label: "AutomotiveBusiness - MotorcycleRepair", value: "MotorcycleRepair" },
  { label: "ChildCare", value: "ChildCare" },
  { label: "Dentist", value: "Dentist" },
  { label: "DryCleaningOrLaundry", value: "DryCleaningOrLaundry" },
  { label: "EmergencyService", value: "EmergencyService" },
  { label: "EmergencyService - FireStation", value: "FireStation" },
  { label: "EmergencyService - Hospital", value: "Hospital" },
  { label: "EmergencyService - PoliceStation", value: "PoliceStation" },
  { label: "EmploymentAgency", value: "EmploymentAgency" },
  { label: "EntertainmentBusiness", value: "EntertainmentBusiness" },
  { label: "EntertainmentBusiness - AdultEntertainment", value: "AdultEntertainment" },
  { label: "EntertainmentBusiness - AmusementPark", value: "AmusementPark" },
  { label: "EntertainmentBusiness - ArtGallery", value: "ArtGallery" },
  { label: "EntertainmentBusiness - Casino", value: "Casino" },
  { label: "EntertainmentBusiness - ComedyClub", value: "ComedyClub" },
  { label: "EntertainmentBusiness - MovieTheater", value: "MovieTheater" },
  { label: "EntertainmentBusiness - NightClub", value: "NightClub" },
  { label: "FinancialService", value: "FinancialService" },
  { label: "FinancialService - AccountingService", value: "AccountingService" },
  { label: "FinancialService - AutomatedTeller", value: "AutomatedTeller" },
  { label: "FinancialService - BankOrCreditUnion", value: "BankOrCreditUnion" },
  { label: "FinancialService - InsuranceAgency", value: "InsuranceAgency" },
  { label: "FoodEstablishment", value: "FoodEstablishment" },
  { label: "FoodEstablishment - Bakery", value: "Bakery" },
  { label: "FoodEstablishment - BarOrPub", value: "BarOrPub" },
  { label: "FoodEstablishment - Brewery", value: "Brewery" },
  { label: "FoodEstablishment - CafeOrCoffeeShop", value: "CafeOrCoffeeShop" },
  { label: "FoodEstablishment - Distillery", value: "Distillery" },
  { label: "FoodEstablishment - FastFoodRestaurant", value: "FastFoodRestaurant" },
  { label: "FoodEstablishment - IceCreamShop", value: "IceCreamShop" },
  { label: "FoodEstablishment - Restaurant", value: "Restaurant" },
  { label: "FoodEstablishment - Winery", value: "Winery" },
  { label: "GovernmentOffice", value: "GovernmentOffice" },
  { label: "GovernmentOffice - PostOffice", value: "PostOffice" },
  { label: "HealthAndBeautyBusiness", value: "HealthAndBeautyBusiness" },
  { label: "HealthAndBeautyBusiness - BeautySalon", value: "BeautySalon" },
  { label: "HealthAndBeautyBusiness - DaySpa", value: "DaySpa" },
  { label: "HealthAndBeautyBusiness - HairSalon", value: "HairSalon" },
  { label: "HealthAndBeautyBusiness - HealthClub", value: "HealthClub" },
  { label: "HealthAndBeautyBusiness - NailSalon", value: "NailSalon" },
  { label: "HealthAndBeautyBusiness - TattooParlor", value: "TattooParlor" },
  { label: "HomeAndConstructionBusiness - Electrician", value: "Electrician" },
  { label: "HomeAndConstructionBusiness - GeneralContractor", value: "GeneralContractor" },
  { label: "HomeAndConstructionBusiness - HVACBusiness", value: "HVACBusiness" },
  { label: "HomeAndConstructionBusiness - HousePainter", value: "HousePainter" },
  { label: "HomeAndConstructionBusiness - Locksmith", value: "Locksmith" },
  { label: "HomeAndConstructionBusiness - MovingCompany", value: "MovingCompany" },
  { label: "HomeAndConstructionBusiness - Plumber", value: "Plumber" },
  { label: "HomeAndConstructionBusiness - RoofingContractor", value: "RoofingContractor" },
  { label: "InternetCafe", value: "InternetCafe" },
  { label: "LegalService", value: "LegalService" },
  { label: "LegalService - Attorney", value: "Attorney" },
  { label: "LegalService - Notary", value: "Notary" },
  { label: "Library", value: "Library" },
  { label: "LodgingBusiness", value: "LodgingBusiness" },
  { label: "LodgingBusiness - BedAndBreakfast", value: "BedAndBreakfast" },
  { label: "LodgingBusiness - Campground", value: "Campground" },
  { label: "LodgingBusiness - Hostel", value: "Hostel" },
  { label: "LodgingBusiness - Hotel", value: "Hotel" },
  { label: "LodgingBusiness - Motel", value: "Motel" },
  { label: "LodgingBusiness - Resort", value: "Resort" },
  { label: "LodgingBusiness - Resort - SkiResort", value: "SkiResort" },
  { label: "MedicalBusiness", value: "MedicalBusiness" },
  { label: "MedicalBusiness - CommunityHealth", value: "CommunityHealth" },
  { label: "MedicalBusiness - Dentist", value: "Dentist" },
  { label: "MedicalBusiness - Dermatology", value: "Dermatology" },
  { label: "MedicalBusiness - DietNutrition", value: "DietNutrition" },
  { label: "MedicalBusiness - Emergency", value: "Emergency" },
  { label: "MedicalBusiness - Geriatric", value: "Geriatric" },
  { label: "MedicalBusiness - Gynecologic", value: "Gynecologic" },
  { label: "MedicalBusiness - MedicalClinic", value: "MedicalClinic" },
  { label: "MedicalBusiness - MedicalClinic - CovidTestingFacility", value: "CovidTestingFacility" },
  { label: "MedicalBusiness - Midwifery", value: "Midwifery" },
  { label: "MedicalBusiness - Nursing", value: "Nursing" },
  { label: "MedicalBusiness - Obstetric", value: "Obstetric" },
  { label: "MedicalBusiness - Oncologic", value: "Oncologic" },
  { label: "MedicalBusiness - Optician", value: "Optician" },
  { label: "MedicalBusiness - Optometric", value: "Optometric" },
  { label: "MedicalBusiness - Otolaryngologic", value: "Otolaryngologic" },
  { label: "MedicalBusiness - Pediatric", value: "Pediatric" },
  { label: "MedicalBusiness - Pharmacy", value: "Pharmacy" },
  { label: "MedicalBusiness - Physician", value: "Physician" },
  { label: "MedicalBusiness - Physiotherapy", value: "Physiotherapy" },
  { label: "MedicalBusiness - PlasticSurgery", value: "PlasticSurgery" },
  { label: "MedicalBusiness - Podiatric", value: "Podiatric" },
  { label: "MedicalBusiness - PrimaryCare", value: "PrimaryCare" },
  { label: "MedicalBusiness - Psychiatric", value: "Psychiatric" },
  { label: "MedicalBusiness - PublicHealth", value: "PublicHealth" },
  { label: "ProfessionalService", value: "ProfessionalService" },
  { label: "RadioStation", value: "RadioStation" },
  { label: "RealEstateAgent", value: "RealEstateAgent" },
  { label: "RecyclingCenter", value: "RecyclingCenter" },
  { label: "SelfStorage", value: "SelfStorage" },
  { label: "ShoppingCenter", value: "ShoppingCenter" },
  { label: "SportsActivityLocation", value: "SportsActivityLocation" },
  { label: "SportsActivityLocation - BowlingAlley", value: "BowlingAlley" },
  { label: "SportsActivityLocation - ExerciseGym", value: "ExerciseGym" },
  { label: "SportsActivityLocation - GolfCourse", value: "GolfCourse" },
  { label: "SportsActivityLocation - HealthClub", value: "HealthClub" },
  { label: "SportsActivityLocation - PublicSwimmingPool", value: "PublicSwimmingPool" },
  { label: "SportsActivityLocation - SkiResort", value: "SkiResort" },
  { label: "SportsActivityLocation - SportsClub", value: "SportsClub" },
  { label: "SportsActivityLocation - StadiumOrArena", value: "StadiumOrArena" },
  { label: "SportsActivityLocation - TennisComplex", value: "TennisComplex" },
  { label: "Store", value: "Store" },
  { label: "Store - AutoPartsStore", value: "AutoPartsStore" },
  { label: "Store - BikeStore", value: "BikeStore" },
  { label: "Store - BookStore", value: "BookStore" },
  { label: "Store - ClothingStore", value: "ClothingStore" },
  { label: "Store - ComputerStore", value: "ComputerStore" },
  { label: "Store - ConvenienceStore", value: "ConvenienceStore" },
  { label: "Store - DepartmentStore", value: "DepartmentStore" },
  { label: "Store - ElectronicsStore", value: "ElectronicsStore" },
  { label: "Store - Florist", value: "Florist" },
  { label: "Store - FurnitureStore", value: "FurnitureStore" },
  { label: "Store - GardenStore", value: "GardenStore" },
  { label: "Store - GroceryStore", value: "GroceryStore" },
  { label: "Store - HardwareStore", value: "HardwareStore" },
  { label: "Store - HobbyShop", value: "HobbyShop" },
  { label: "Store - HomeGoodsStore", value: "HomeGoodsStore" },
  { label: "Store - JewelryStore", value: "JewelryStore" },
  { label: "Store - LiquorStore", value: "LiquorStore" },
  { label: "Store - MensClothingStore", value: "MensClothingStore" },
  { label: "Store - MobilePhoneStore", value: "MobilePhoneStore" },
  { label: "Store - MovieRentalStore", value: "MovieRentalStore" },
  { label: "Store - MusicStore", value: "MusicStore" },
  { label: "Store - OfficeEquipmentStore", value: "OfficeEquipmentStore" },
  { label: "Store - OutletStore", value: "OutletStore" },
  { label: "Store - PawnShop", value: "PawnShop" },
  { label: "Store - PetStore", value: "PetStore" },
  { label: "Store - ShoeStore", value: "ShoeStore" },
  { label: "Store - SportingGoodsStore", value: "SportingGoodsStore" },
  { label: "Store - TireShop", value: "TireShop" },
  { label: "Store - ToyStore", value: "ToyStore" },
  { label: "Store - WholesaleStore", value: "WholesaleStore" },
  { label: "TelevisionStation", value: "TelevisionStation" },
  { label: "TouristInformationCenter", value: "TouristInformationCenter" },
  { label: "TravelAgency", value: "TravelAgency" },
]

const StyledSelect = styled( Select )`
  width: 100%;
`

const StyledFieldContainer = styled.div`
  align-items: center;
  display: flex;
  margin: 20px 0;
`

enum FormFieldKeys {
  ADDRESS_CITY = "address_city",
  ADDRESS_COUNTRY = "address_country",
  ADDRESS_POSTAL_CODE = "address_postal_code",
  ADDRESS_STATE = "address_state",
  ADDRESS_STREET = "address_street",
  BUSINESS_TYPE = "business_type",
  EMAIL = "email",
  FACEBOOK_ID = "facebook_id",
  HAS_NO_ADDRESS = "has_no_address",
  INSTAGRAM_ID = "instagram_id",
  LABEL = "label",
  MESSAGES_WIDGET_ENABLED = "messages_widget_enabled",
  NAME = "name",
  PHONE_NUMBER = "phone_number",
  REVIEWS_WIDGET_ENABLED = "reviews_widget_enabled",
  TEMPLATE_ID = "template_id"
}

type FormValues = {
  [ FormFieldKeys.ADDRESS_CITY ]: string
  [ FormFieldKeys.ADDRESS_COUNTRY ]: string
  [ FormFieldKeys.ADDRESS_POSTAL_CODE ]: string
  [ FormFieldKeys.ADDRESS_STATE ]: string
  [ FormFieldKeys.ADDRESS_STREET ]: string
  [ FormFieldKeys.BUSINESS_TYPE ]: string
  [ FormFieldKeys.EMAIL ]: string
  [ FormFieldKeys.FACEBOOK_ID ]: string
  [ FormFieldKeys.HAS_NO_ADDRESS ]: boolean
  [ FormFieldKeys.INSTAGRAM_ID ]: string
  [ FormFieldKeys.LABEL ]: string
  [ FormFieldKeys.MESSAGES_WIDGET_ENABLED ]: boolean
  [ FormFieldKeys.NAME ]: string
  [ FormFieldKeys.PHONE_NUMBER ]: string
  [ FormFieldKeys.REVIEWS_WIDGET_ENABLED ]: boolean
  [ FormFieldKeys.TEMPLATE_ID ]: string
}

export interface GenerateMerchantSiteModalProps {
  isOpen: boolean
  merchantId: string
  merchantSettings: MerchantSettings
  onClose( reOpen?: boolean ): void
  onExited: () => void
  onGenerated?(): void
  type: Site.Type
}

export const GenerateMerchantSiteModal: React.FC<GenerateMerchantSiteModalProps> = ( {
  isOpen,
  merchantId,
  merchantSettings,
  onGenerated,
  onClose,
  onExited,
  type,
} ) => {
  const [ alert, setAlert ] = useState<DialogAlert | undefined>( undefined )
  const dispatch = useAsyncDispatch()
  const formMethods = useForm<FormValues>( {
    defaultValues: {
      [ FormFieldKeys.ADDRESS_CITY ]: "",
      [ FormFieldKeys.ADDRESS_COUNTRY ]: "",
      [ FormFieldKeys.ADDRESS_POSTAL_CODE ]: "",
      [ FormFieldKeys.ADDRESS_STATE ]: "",
      [ FormFieldKeys.ADDRESS_STREET ]: "",
      [ FormFieldKeys.BUSINESS_TYPE ]: "",
      [ FormFieldKeys.EMAIL ]: "",
      [ FormFieldKeys.FACEBOOK_ID ]: "",
      [ FormFieldKeys.HAS_NO_ADDRESS ]: false,
      [ FormFieldKeys.INSTAGRAM_ID ]: "",
      [ FormFieldKeys.LABEL ]: "",
      [ FormFieldKeys.MESSAGES_WIDGET_ENABLED ]: false,
      [ FormFieldKeys.PHONE_NUMBER ]: "",
      [ FormFieldKeys.REVIEWS_WIDGET_ENABLED ]: false,
      [ FormFieldKeys.TEMPLATE_ID ]: "",
    },
  } )
  const { control, formState, handleSubmit, setValue, trigger, watch } = formMethods
  const { showError, showSuccess } = useAlert()
  const { reloadSiteTemplates, siteTemplates } = useSiteTemplates( { isAvailable: true, type } )
  const [ defaultSiteTemplateName, setDefaultSiteTemplateName ] = useState( "" )
  const [ industryHelpText, setIndustryHelpText ] = useState( "" )
  const [ isLoading, setIsLoading ] = useState( true )
  const [ isProcessing, setIsProcessing ] = useState( false )
  const hasNoAddress = watch( FormFieldKeys.HAS_NO_ADDRESS )

  const onSave: SubmitHandler<FormValues> = useCallback( async ( data ) => {
    try {
      if( isProcessing ) {
        return
      }

      setIsProcessing( true )

      await dispatch( siteActions.site.create( merchantId, {
        addressCity: data[ FormFieldKeys.ADDRESS_CITY ],
        addressCountry: data[ FormFieldKeys.ADDRESS_COUNTRY ],
        addressPostalCode: data[ FormFieldKeys.ADDRESS_POSTAL_CODE ],
        addressStreet: data[ FormFieldKeys.ADDRESS_STREET ],
        addressState: data[ FormFieldKeys.ADDRESS_STATE ],
        businessType: data[ FormFieldKeys.BUSINESS_TYPE ],
        facebookId: data[ FormFieldKeys.FACEBOOK_ID ] || undefined,
        hasNoAddress: data[ FormFieldKeys.HAS_NO_ADDRESS ] || false,
        instagramId: data[ FormFieldKeys.INSTAGRAM_ID ] || undefined,
        email: data[ FormFieldKeys.EMAIL ],
        label: data[ FormFieldKeys.LABEL ],
        messagesWidgetEnabled: data[ FormFieldKeys.MESSAGES_WIDGET_ENABLED ],
        name: data[ FormFieldKeys.NAME ],
        phoneNumber: data[ FormFieldKeys.PHONE_NUMBER ],
        reviewsWidgetEnabled: data[ FormFieldKeys.REVIEWS_WIDGET_ENABLED ],
        siteTemplateId: data[ FormFieldKeys.TEMPLATE_ID ],
        type: type,
      } ) )

      showSuccess( "Site generated" )
      if( onGenerated ) {
        onGenerated()
      }
      onClose()

    } catch( err ) {
      setAlert( {
        message: err,
        type: DialogAlertType.ERROR,
      } )
    } finally {
      setIsProcessing( false )
    }
  }, [ dispatch, isProcessing, merchantId, showSuccess, onGenerated, onClose, type ] )

  const modalActions: DialogAction[] = useMemo( () => {
    const actions: DialogAction[] = [
      {
        title: "Cancel",
        type: "dismiss",
      },
      {
        disabled: isLoading,
        icon: ( <CheckIcon style={ { height: 18, width: 18 } }/> ),
        onClick: handleSubmit( onSave, () => {
          setAlert( {
            message: "Please make sure all required fields are filled.",
            type: DialogAlertType.ERROR,
          } )
        } ),
        title: "Generate",
        type: "primary",
      },
    ]

    return actions
  }, [ handleSubmit, isLoading, onSave ] )

  const siteTemplateOptions: Array<SelectOption<string>> = useMemo( () => {
    return ( siteTemplates || [] ).map( ( siteTemplate ) => ( { label: siteTemplate.name, value: siteTemplate.id } ) )
  }, [ siteTemplates ] )

  useEffect( () => {
    trigger()
  }, [ hasNoAddress, trigger ] )

  useEffectOnce( () => {
    async function run() {
      try {
        await Promise.all( [
          reloadSiteTemplates(),
          ( async function() {
            const defaultValues = await siteService.getSiteGenerationValues( merchantId )

            setValue( FormFieldKeys.ADDRESS_CITY, defaultValues.address.city )
            setValue( FormFieldKeys.ADDRESS_COUNTRY, defaultValues.address.country )
            setValue( FormFieldKeys.ADDRESS_POSTAL_CODE, defaultValues.address.postalCode )
            setValue( FormFieldKeys.ADDRESS_STATE, defaultValues.address.state )
            setValue( FormFieldKeys.ADDRESS_STREET, defaultValues.address.street )
            setValue( FormFieldKeys.EMAIL, defaultValues.email )
            setValue( FormFieldKeys.FACEBOOK_ID, defaultValues.facebookId )
            setValue( FormFieldKeys.INSTAGRAM_ID, defaultValues.instagramId )
            setValue( FormFieldKeys.NAME, defaultValues.name )
            setValue( FormFieldKeys.PHONE_NUMBER, defaultValues.phoneNumber )
            if( defaultValues.siteTemplateId ) {
              setValue( FormFieldKeys.TEMPLATE_ID, defaultValues.siteTemplateId )
            } else {
              setDefaultSiteTemplateName( defaultValues.siteTemplateName )
            }
            if( defaultValues.businessType ) {
              setValue( FormFieldKeys.BUSINESS_TYPE, defaultValues.businessType )
            }
            if( defaultValues.primaryIndustry ) {
              if( defaultValues.secondaryIndustry ) {
                setIndustryHelpText( `${ defaultValues.primaryIndustry } - ${ defaultValues.secondaryIndustry }` )
              } else {
                setIndustryHelpText( defaultValues.primaryIndustry )
              }
            }
          } )(),
        ] )
        setIsLoading( false )
      } catch( err ) {
        onClose()
        showError( err )
      }
    }

    run()
  } )

  return (
    <Dialog
      actions={ modalActions }
      alert={ alert }
      contentContainerStyle={ {
        paddingLeft: "24px",
        paddingRight: "24px",
        width: "600px",
      } }
      isOpen={ isOpen }
      loadingText={
        isLoading
          ? "Pulling information..."
          : (
            isProcessing
              ? `Generating ${ siteLabels[ type ] }...`
              : null
          )
      }
      noMaxWidth={ true }
      onClose={ onClose }
      onExited={ onExited }
      showDividers={ false }
      title={ `Generate ${ siteLabels[ type ] }` }
    >
      <StyledText style={ { marginBottom: "25px" } }>Please verify the Business Details, Address, and Social Media links are correct. This data will be displayed on the client&apos;s { siteLabels[ type ] }.</StyledText>
      <FormProvider { ...formMethods }>
        <StyledFormSectionTitle>Business Info</StyledFormSectionTitle>
        <StyledFieldContainer>
          <Grid spacing={ 4 }>
            <GridItem xs={ 6 }>
              <Controller
                control={ control }
                name={ FormFieldKeys.NAME }
                render={ ( { field: { onChange, value } } ) => (
                  <TextField
                    errorMessage={ formState.errors?.[ FormFieldKeys.NAME ]?.message }
                    fullWidth
                    label="Name"
                    onChange={ onChange }
                    required={ true }
                    value={ value || "" }
                  />
                ) }
                rules={ {
                  validate: ( value ) => {
                    value = ( value || "" ).trim()

                    if( ! value ) {
                      return "Name is required"
                    }
                    return undefined
                  },
                } }
              />
            </GridItem>
            <GridItem xs={ 6 }>
              {
                type === Site.Type.LANDING_PAGE && (
                  <Controller
                    control={ control }
                    name={ FormFieldKeys.LABEL }
                    render={ ( { field: { onChange, value } } ) => (
                      <TextField
                        errorMessage={ formState.errors?.[ FormFieldKeys.LABEL ]?.message }
                        fullWidth
                        label="Landing Page Label"
                        onChange={ onChange }
                        required={ true }
                        value={ value || "" }
                      />
                    ) }
                    rules={ {
                      validate: ( value ) => {
                        value = ( value || "" ).trim()

                        if( ! value ) {
                          return "Landing Page Label is required"
                        }
                        return undefined
                      },
                    } }
                  />
                )
              }
            </GridItem>
            <GridItem xs={ 6 }>
              <Controller
                control={ control }
                name={ FormFieldKeys.BUSINESS_TYPE }
                render={ ( { field: { onChange, value } } ) => {
                  const error = formState.errors?.[ FormFieldKeys.BUSINESS_TYPE ]?.message
                  return (
                    <StyledSelect
                      error={ error != null }
                      keyByIndex={ true }
                      helpText={ error || ( industryHelpText ? `From Salesforce: ${ industryHelpText }` : undefined ) }
                      onChange={ onChange }
                      label="Business Type"
                      options={ businessTypeOptions }
                      required={ true }
                      value={ value || "" }
                    />
                  )
                } }
                rules={ {
                  validate: ( value ) => {
                    if( ! value ) {
                      return "Business Type is required"
                    }
                    return undefined
                  },
                } }
              />
            </GridItem>
            <GridItem xs={ 6 }>
              <Controller
                control={ control }
                name={ FormFieldKeys.TEMPLATE_ID }
                render={ ( { field: { onChange, value } } ) => {
                  const error = formState.errors?.[ FormFieldKeys.TEMPLATE_ID ]?.message
                  return (
                    <StyledSelect
                      error={ error != null }
                      helpText={ error || ( defaultSiteTemplateName ? `From Salesforce: ${ defaultSiteTemplateName }` : undefined ) }
                      onChange={ onChange }
                      label="Template"
                      options={ siteTemplateOptions }
                      required={ true }
                      value={ value || "" }
                    />
                  )
                } }
                rules={ {
                  validate: ( value ) => {
                    if( ! value ) {
                      return "Template is required"
                    }
                    return undefined
                  },
                } }
              />
            </GridItem>
            <GridItem xs={ 6 }>
              <Controller
                control={ control }
                name={ FormFieldKeys.EMAIL }
                render={ ( { field: { onChange, value } } ) => (
                  <TextField
                    errorMessage={ formState.errors?.[ FormFieldKeys.EMAIL ]?.message }
                    fullWidth
                    label="Email"
                    onChange={ onChange }
                    required={ true }
                    type="email"
                    value={ value || "" }
                  />
                ) }
                rules={ {
                  validate: ( value ) => {
                    value = ( value || "" ).trim()

                    if( ! value ) {
                      return "Email is required"
                    }

                    if( ! isValidEmail( value ) ) {
                      return "Email is invalid"
                    }

                    return undefined
                  },
                } }
              />
            </GridItem>
            <GridItem xs={ 6 }>
              <Controller
                control={ control }
                name={ FormFieldKeys.PHONE_NUMBER }
                render={ ( { field: { onChange, value } } ) => (
                  <TextField
                    errorMessage={ formState.errors?.[ FormFieldKeys.PHONE_NUMBER ]?.message }
                    fullWidth
                    label="Phone Number"
                    onChange={ onChange }
                    required={ true }
                    type="tel"
                    value={ value || "" }
                  />
                ) }
                rules={ {
                  validate: ( value ) => {
                    value = ( value || "" ).trim()

                    if( ! value ) {
                      return "Phone Number is required"
                    }

                    if( ! isValidPhoneNumber( value ) ) {
                      return "Phone number is invalid"
                    }

                    return undefined
                  },
                } }
              />
            </GridItem>

            <GridItem xs={ 12 } spacing={ 0 }>
              <StyledFormSectionTitle>Address</StyledFormSectionTitle>
            </GridItem>

            <GridItem xs={ 6 }>
              <Controller
                control={ control }
                name={ FormFieldKeys.ADDRESS_STREET }
                render={ ( { field: { onChange, value } } ) => (
                  <TextField
                    disabled={ hasNoAddress }
                    errorMessage={ formState.errors?.[ FormFieldKeys.ADDRESS_STREET ]?.message }
                    fullWidth
                    label="Address Line"
                    onChange={ onChange }
                    required={ ! hasNoAddress }
                    value={ value || "" }
                  />
                ) }
                rules={ {
                  validate: ( value ) => {

                    if( hasNoAddress ) {
                      return undefined
                    }

                    value = ( value || "" ).trim()

                    if( ! value ) {
                      return "Address Line is required"
                    }
                    return undefined
                  },
                } }
              />
            </GridItem>
            <GridItem xs={ 6 }>
              <Controller
                control={ control }
                name={ FormFieldKeys.ADDRESS_POSTAL_CODE }
                render={ ( { field: { onChange, value } } ) => (
                  <TextField
                    disabled={ hasNoAddress }
                    errorMessage={ formState.errors?.[ FormFieldKeys.ADDRESS_POSTAL_CODE ]?.message }
                    fullWidth
                    label="Postal Code"
                    onChange={ onChange }
                    required={ ! hasNoAddress }
                    value={ value || "" }
                  />
                ) }
                rules={ {
                  validate: ( value ) => {
                    if( hasNoAddress ) {
                      return undefined
                    }

                    value = ( value || "" ).trim()

                    if( ! value ) {
                      return "Postal Code is required"
                    }
                    return undefined
                  },
                } }
              />
            </GridItem>

            <GridItem xs={ 4 }>
              <Controller
                control={ control }
                name={ FormFieldKeys.ADDRESS_CITY }
                render={ ( { field: { onChange, value } } ) => (
                  <TextField
                    disabled={ hasNoAddress }
                    errorMessage={ formState.errors?.[ FormFieldKeys.ADDRESS_CITY ]?.message }
                    fullWidth
                    label="City"
                    onChange={ onChange }
                    required={ ! hasNoAddress }
                    value={ value || "" }
                  />
                ) }
                rules={ {
                  validate: ( value ) => {
                    if( hasNoAddress ) {
                      return undefined
                    }

                    value = ( value || "" ).trim()

                    if( ! value ) {
                      return "City is required"
                    }
                    return undefined
                  },
                } }
              />
            </GridItem>
            <GridItem xs={ 4 }>
              <Controller
                control={ control }
                name={ FormFieldKeys.ADDRESS_STATE }
                render={ ( { field: { onChange, value } } ) => (
                  <TextField
                    disabled={ hasNoAddress }
                    errorMessage={ formState.errors?.[ FormFieldKeys.ADDRESS_STATE ]?.message }
                    fullWidth
                    label="State/Province"
                    onChange={ onChange }
                    required={ ! hasNoAddress }
                    value={ value || "" }
                  />
                ) }
                rules={ {
                  validate: ( value ) => {
                    if( hasNoAddress ) {
                      return undefined
                    }

                    value = ( value || "" ).trim()

                    if( ! value ) {
                      return "State/Province is required"
                    }
                    return undefined
                  },
                } }
              />
            </GridItem>
            <GridItem xs={ 4 }>
              <Controller
                control={ control }
                name={ FormFieldKeys.ADDRESS_COUNTRY }
                render={ ( { field: { onChange, value } } ) => (
                  <TextField
                    disabled={ hasNoAddress }
                    errorMessage={ formState.errors?.[ FormFieldKeys.ADDRESS_COUNTRY ]?.message }
                    fullWidth
                    label="Country"
                    onChange={ onChange }
                    required={ ! hasNoAddress }
                    value={ value || "" }
                  />
                ) }
                rules={ {
                  validate: ( value ) => {
                    if( hasNoAddress ) {
                      return undefined
                    }

                    value = ( value || "" ).trim()

                    if( ! value ) {
                      return "Country is required"
                    }
                    return undefined
                  },
                } }
              />
            </GridItem>
            <GridItem xs={ 12 }>
              <Controller
                control={ control }
                name={ FormFieldKeys.HAS_NO_ADDRESS }
                render={ ( { field: { name, onChange, value } } ) => (
                  <CheckBox
                    checkboxColor="primary"
                    checked={ value || false }
                    label="No Address"
                    onChange={ onChange }
                    name={ name }
                    noLabelWrap={ true }
                  />
                ) }
              />
            </GridItem>

            <GridItem xs={ 12 } spacing={ 0 }>
              <StyledFormSectionTitle>Social Media</StyledFormSectionTitle>
            </GridItem>

            <GridItem xs={ 6 }>
              <Controller
                control={ control }
                name={ FormFieldKeys.FACEBOOK_ID }
                render={ ( { field: { onChange, value } } ) => (
                  <TextField
                    errorMessage={ formState.errors?.[ FormFieldKeys.FACEBOOK_ID ]?.message }
                    fullWidth
                    label="Facebook ID"
                    onChange={ onChange }
                    value={ value || "" }
                  />
                ) }
                rules={ {
                  validate: ( value ) => {
                    value = ( value || "" ).trim()
                    if( value ) {
                      if( ! FACEBOOK_INSTAGRAM_ID_REGEX.exec( value ) ) {
                        return "Facebook ID is incorrect"
                      }
                    }
                    return undefined
                  },
                } }
              />
            </GridItem>
            <GridItem xs={ 6 }>
              <Controller
                control={ control }
                name={ FormFieldKeys.INSTAGRAM_ID }
                render={ ( { field: { onChange, value } } ) => (
                  <TextField
                    errorMessage={ formState.errors?.[ FormFieldKeys.INSTAGRAM_ID ]?.message }
                    fullWidth
                    label="Instagram ID"
                    onChange={ onChange }
                    value={ value || "" }
                  />
                ) }
                rules={ {
                  validate: ( value ) => {
                    value = ( value || "" ).trim()

                    if( value ) {
                      if( ! FACEBOOK_INSTAGRAM_ID_REGEX.exec( value ) ) {
                        return "Instagram ID is incorrect"
                      }
                    }
                    return undefined
                  },
                } }
              />
            </GridItem>

            {
              merchantSettings.messenger.status === "live" && (
                <GridItem xs={ 6 }>
                  <Controller
                    control={ control }
                    name={ FormFieldKeys.MESSAGES_WIDGET_ENABLED }
                    render={ ( { field: { name, onChange, value } } ) => (
                      <CheckBox
                        checkboxColor="primary"
                        checked={ value || false }
                        label="Enabled LocalMessages widget"
                        onChange={ onChange }
                        name={ name }
                        noLabelWrap={ true }
                      />
                    ) }
                  />
                </GridItem>
              )
            }
            {
              merchantSettings.reviews.status === "live" && (
                <GridItem xs={ 6 }>
                  <Controller
                    control={ control }
                    name={ FormFieldKeys.REVIEWS_WIDGET_ENABLED }
                    render={ ( { field: { name, onChange, value } } ) => (
                      <CheckBox
                        checkboxColor="primary"
                        checked={ value || false }
                        label="Enabled LocalReviews widget"
                        onChange={ onChange }
                        name={ name }
                        noLabelWrap={ true }
                      />
                    ) }
                  />
                </GridItem>
              )
            }
          </Grid>
        </StyledFieldContainer>
      </FormProvider>
    </Dialog>
  )
}

export interface useGenerateMerchantSiteModalParams {
  merchantId: string
  merchantSettings: MerchantSettings
  onGenerated?(): void
  type: Site.Type
}

export const useGenerateMerchantSiteModal = () => {
  const { showModal, hideModal } = useModalWithParams<useGenerateMerchantSiteModalParams>( ( { open, onExited, params } ) => {
    if( ! params ) {
      return null
    }

    return (
      <GenerateMerchantSiteModal
        isOpen={ open }
        merchantId={ params.merchantId }
        merchantSettings={ params.merchantSettings }
        onGenerated={ params.onGenerated }
        onClose={ hideModal }
        onExited={ onExited }
        type={ params.type }
      />
    )
  }, [] )

  return {
    showGenerateMerchantSiteModal: showModal,
    hideGenerateMerchantSiteModal: hideModal,
  }
}

import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

import type { ModuleStoreConfig, PaginatedItems, PaginatedQueryOptions } from "@onelocal/frontend/common"
import type { RootThunk } from "@onelocal/frontend-dashboard-web/main"
import type { ReviewsService } from "../services/reviewsService"
import { reviewsService } from "../services/reviewsService"
import type { ReviewsSurveyRequestService } from "../services/reviewsSurveyRequestService"
import { reviewsSurveyRequestService } from "../services/reviewsSurveyRequestService"
import type { ReviewsSurveyResponsesService } from "../services/reviewsSurveyResponseService"
import { reviewsSurveyResponseService } from "../services/reviewsSurveyResponseService"
import type { ReviewsSurveyTemplateService } from "../services/reviewsSurveyTemplateService"
import { reviewsSurveyTemplateService } from "../services/reviewsSurveyTemplateService"
import type { ReviewsReview, ReviewsStats, ReviewsSurveyRequest, ReviewsSurveyResponse, ReviewsSurveyScheduledRequest, ReviewsSurveyTemplate } from "../types"

export interface ReviewsState {
  reviews: {
    byId: {
      [id: string]: ReviewsReview
    }
  }
  stats: ReviewsStats | null
  surveyRequests: {
    byId: {
      [id: string]: ReviewsSurveyRequest
    }
  }
  surveyResponses: {
    byId: {
      [id: string]: ReviewsSurveyResponse
    }
  }
  surveyScheduledRequests: {
    byId: {
      [id: string]: ReviewsSurveyScheduledRequest
    }
  }
  surveyTemplates: {
    byId: {
      [id: string]: ReviewsSurveyTemplate
    }
  }
}

const reviewsStoreKey = "reviews"

export const reviewsInitialState: ReviewsState = {
  reviews: {
    byId: {},
  },
  stats: null,
  surveyRequests: {
    byId: {},
  },
  surveyResponses: {
    byId: {},
  },
  surveyScheduledRequests: {
    byId: {},
  },
  surveyTemplates: {
    byId: {},
  },
}

const reviewsSlice = createSlice( {
  name: reviewsStoreKey,
  initialState: reviewsInitialState,
  reducers: {
    deleteSurveyTemplates: ( state, action: PayloadAction<string[]> ) => {
      const surveyTemplateIds = action.payload

      for( const surveyTemplateId of surveyTemplateIds ) {
        delete state.surveyTemplates.byId[ surveyTemplateId ]
      }

      return state
    },
    updateReviews: ( state, action: PayloadAction<ReviewsReview[]> ) => {
      const reviews = action.payload

      for( const review of reviews ) {
        state.reviews.byId[ review.id ] = review
      }

      return state
    },
    updateStats: ( state, action: PayloadAction<ReviewsStats> ) => {
      const stats = action.payload
      state.stats = stats
    },
    updateSurveyRequest: ( state, action: PayloadAction<ReviewsSurveyRequest[]> ) => {
      const surveyRequests = action.payload

      for( const surveyRequest of surveyRequests ) {
        state.surveyRequests.byId[ surveyRequest.id ] = surveyRequest
      }

      return state
    },
    updateSurveyResponses: ( state, action: PayloadAction<ReviewsSurveyResponse[]> ) => {
      const surveyResponses = action.payload

      for( const surveyResponse of surveyResponses ) {
        state.surveyResponses.byId[ surveyResponse.id ] = surveyResponse
      }

      return state
    },
    updateSurveyScheduledRequest: ( state, action: PayloadAction<{ updated?: ReviewsSurveyScheduledRequest[], removeIds?: string[] }> ) => {
      const { removeIds, updated } = action.payload

      if( updated ) {
        for( const surveyScheduledRequest of updated ) {
          state.surveyScheduledRequests.byId[ surveyScheduledRequest.id ] = surveyScheduledRequest
        }
      }

      if( removeIds ) {
        for( const removeId of removeIds ) {
          if( state.surveyScheduledRequests.byId[ removeId ] ) {
            delete state.surveyScheduledRequests.byId[ removeId ]
          }
        }
      }

      return state
    },
    updateSurveyTemplates: ( state, action: PayloadAction<{ surveyTemplates: ReviewsSurveyTemplate[], reset?: boolean }> ) => {
      const { surveyTemplates, reset } = action.payload

      if( reset ) {
        state.surveyTemplates.byId = {}
      }

      for( const surveyTemplate of surveyTemplates ) {
        state.surveyTemplates.byId[ surveyTemplate.id ] = surveyTemplate
      }

      return state
    },
  },
} )

export const reviewsActions = {
  deleteSurvey( merchantId: string, surveyTemplateId: string ): RootThunk<void> {
    return async ( dispatch ) => {
      await reviewsSurveyTemplateService.delete( merchantId, surveyTemplateId )
      dispatch( reviewsSlice.actions.deleteSurveyTemplates( [ surveyTemplateId ] ) )
    }
  },
  getReview( merchantId: string, reviewId: string ): RootThunk<ReviewsReview> {
    return async ( dispatch ) => {
      const review = await reviewsService.getReview( merchantId, reviewId )
      dispatch( reviewsSlice.actions.updateReviews( [ review ] ) )
      return review
    }
  },
  getStats( merchantId: string ): RootThunk<ReviewsStats> {
    return async ( dispatch ) => {
      const stats = await reviewsService.getStats( merchantId )
      dispatch( reviewsSlice.actions.updateStats( stats ) )
      return stats
    }
  },
  getSurveyRequest( merchantId: string, surveyRequestId: string ): RootThunk<ReviewsSurveyRequest> {
    return async ( dispatch ) => {
      const surveyRequest = await reviewsSurveyRequestService.getById( merchantId, surveyRequestId )
      dispatch( reviewsSlice.actions.updateSurveyRequest( [ surveyRequest ] ) )
      return surveyRequest
    }
  },
  getSurveyResponse( merchantId: string, surveyResponseId: string ): RootThunk<ReviewsSurveyResponse> {
    return async ( dispatch ) => {
      const surveyResponse = await reviewsSurveyResponseService.getById( merchantId, surveyResponseId )
      dispatch( reviewsSlice.actions.updateSurveyResponses( [ surveyResponse ] ) )
      return surveyResponse
    }
  },
  getSurveyTemplate( merchantId: string, surveyTemplateId: string ): RootThunk<ReviewsSurveyTemplate> {
    return async ( dispatch ) => {
      const surveyTemplate = await reviewsSurveyTemplateService.getById( merchantId, surveyTemplateId )
      dispatch( reviewsSlice.actions.updateSurveyTemplates( { surveyTemplates: [ surveyTemplate ] } ) )
      return surveyTemplate
    }
  },
  queryReviews( merchantId: string, filter: ReviewsService.QueryFilter, options: ReviewsService.QueryOptions ): RootThunk<PaginatedItems<ReviewsReview>> {
    return async ( dispatch ) => {
      const paginatedReviews = await reviewsService.query( merchantId, filter, options )
      dispatch( reviewsSlice.actions.updateReviews( paginatedReviews.items ) )
      return paginatedReviews
    }
  },
  queryPaginatedReviews( merchantId: string, filter: ReviewsService.QueryFilter, options: ReviewsService.QueryOptions ): RootThunk<PaginatedItems<ReviewsReview>> {
    return async ( dispatch ) => {
      const paginatedReviews = await reviewsService.query( merchantId, filter, options )
      dispatch( reviewsSlice.actions.updateReviews( paginatedReviews.items ) )
      return paginatedReviews
    }
  },
  queryPaginatedSurveyRequests( merchantId: string, filter: ReviewsSurveyRequestService.QueryFilter, options: PaginatedQueryOptions ): RootThunk<PaginatedItems<ReviewsSurveyRequest>> {
    return async ( dispatch ) => {
      const paginatedSurveyRequests = await reviewsSurveyRequestService.query( merchantId, filter, options )
      dispatch( reviewsSlice.actions.updateSurveyRequest( paginatedSurveyRequests.items ) )
      return paginatedSurveyRequests
    }
  },
  queryPaginatedSurveyScheduledRequests( merchantId: string, filter: ReviewsSurveyRequestService.ScheduledRequestQueryFilter, options: PaginatedQueryOptions ): RootThunk<PaginatedItems<ReviewsSurveyScheduledRequest>> {
    return async ( dispatch ) => {
      const paginatedSurveyScheduledRequests = await reviewsSurveyRequestService.queryScheduledRequest( merchantId, filter, options )
      dispatch( reviewsSlice.actions.updateSurveyScheduledRequest( { updated: paginatedSurveyScheduledRequests.items } ) )
      return paginatedSurveyScheduledRequests
    }
  },
  queryPaginatedSurveyResponses( merchantId: string, filter: ReviewsSurveyResponsesService.QueryFilter, options: PaginatedQueryOptions ): RootThunk<PaginatedItems<ReviewsSurveyResponse>> {
    return async ( dispatch ) => {
      const paginatedSurveyResponses = await reviewsSurveyResponseService.query( merchantId, filter, options )
      dispatch( reviewsSlice.actions.updateSurveyResponses( paginatedSurveyResponses.items ) )
      return paginatedSurveyResponses
    }
  },
  querySurveyTemplates( merchantId: string ): RootThunk<ReviewsSurveyTemplate[]> {
    return async ( dispatch ) => {
      const surveyTemplates = await reviewsSurveyTemplateService.query( merchantId )
      dispatch( reviewsSlice.actions.updateSurveyTemplates( { surveyTemplates, reset: true } ) )
      return surveyTemplates
    }
  },
  removeReviewReply( merchantId: string, reviewId: string ): RootThunk<ReviewsReview> {
    return async ( dispatch ) => {
      await reviewsService.removeReply( merchantId, reviewId )
      const review = await reviewsService.getReview( merchantId, reviewId )
      dispatch( reviewsSlice.actions.updateReviews( [ review ] ) )
      return review
    }
  },

  sendRequest( merchantId: string, options: ReviewsSurveyRequestService.SendOptions ): RootThunk<ReviewsSurveyRequest> {
    return async ( dispatch ) => {
      const surveyRequest = await reviewsSurveyRequestService.sendRequest( merchantId, options )
      dispatch( reviewsSlice.actions.updateSurveyRequest( [ surveyRequest ] ) )
      return surveyRequest
    }
  },
  updateReviewAttribution( merchantId: string, reviewId: string, value: string | null ): RootThunk<ReviewsReview> {
    return async ( dispatch ) => {
      const review = await reviewsService.updateAttribution( merchantId, reviewId, value )
      dispatch( reviewsSlice.actions.updateReviews( [ review ] ) )
      return review
    }
  },
  updateReviewReply( merchantId: string, reviewId: string, comment: string ): RootThunk<ReviewsReview> {
    return async ( dispatch ) => {
      const review = await reviewsService.updateReply( merchantId, reviewId, comment )
      dispatch( reviewsSlice.actions.updateReviews( [ review ] ) )
      return review
    }
  },

  updateSurveyTemplateSettings( merchantId: string, templateId: string, options: ReviewsSurveyTemplateService.UpdateSettingsOptions ): RootThunk<ReviewsSurveyTemplate> {
    return async ( dispatch ) => {
      const surveyTemplate = await reviewsSurveyTemplateService.updateSettings( merchantId, templateId, options )
      dispatch( reviewsSlice.actions.updateSurveyTemplates( { surveyTemplates: [ surveyTemplate ] } ) )
      return surveyTemplate
    }
  },

  surveyScheduledRequests: {
    cancel( surveyScheduledRequest: ReviewsSurveyScheduledRequest ): RootThunk<void> {
      return async ( dispatch ) => {
        await reviewsSurveyRequestService.cancelScheduledRequest( surveyScheduledRequest )
        dispatch( reviewsSlice.actions.updateSurveyScheduledRequest( { removeIds: [ surveyScheduledRequest.id ] } ) )
      }
    },
    reschedule( surveyScheduledRequest: ReviewsSurveyScheduledRequest, date: string ): RootThunk<ReviewsSurveyScheduledRequest> {
      return async ( dispatch ) => {
        await reviewsSurveyRequestService.rescheduleScheduledRequest( surveyScheduledRequest, date )
        const updatedSurveyScheduledRequest = await reviewsSurveyRequestService.getScheduledRequestById( surveyScheduledRequest.merchantId, surveyScheduledRequest.id )
        dispatch( reviewsSlice.actions.updateSurveyScheduledRequest( { updated: [ updatedSurveyScheduledRequest ] } ) )
        return updatedSurveyScheduledRequest
      }
    },
  },
}

export const reviewsStoreConfig: ModuleStoreConfig<ReviewsState> = {
  key: reviewsStoreKey,
  initialState: reviewsInitialState,
  reducers: reviewsSlice.reducer,
}

import { createSelector } from "@reduxjs/toolkit"

import type { AuthState, AuthStateSelector } from "./authStore"

export const authSelector = <TRootState extends { auth: AuthState }>( state: TRootState ) => state.auth

export const createAuthSelector = <TRootState extends { auth: AuthState }, TReturnValue>( combiner: ( authState: AuthState ) => TReturnValue ) => {
  return createSelector<AuthStateSelector<TRootState>, TReturnValue>(
    authSelector,
    combiner,
  )
}

const authCurrentSessionSelector = createAuthSelector(
  ( authState ) => authState.currentSession,
)

export const authSelectors = {
  accounts: {
    all: createAuthSelector( ( authState ) => Object.values( authState.accounts.byId ) ),
    current: createSelector(
      authSelector,
      authCurrentSessionSelector,
      ( authState, currentSession ) => {
        if( ! currentSession ) {
          return null
        }

        return authState.accounts.byId[ currentSession.accountId ] || null
      },
    ),
  },
  employees: {
    all: createAuthSelector(
      ( authState ) => Object.values( authState.employees.byId ),
    ),
    byIds: ( employeeIds: string[] ) => (
      createAuthSelector(
        ( authState ) => {
          return employeeIds
            .map( ( employeeId ) => authState.employees.byId[ employeeId ] )
            .filter( ( employee ) => employee != null )
        },
      )
    ),
  },
  session: {
    current: authCurrentSessionSelector,
  },
}

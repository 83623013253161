import CheckIcon from "@mui/icons-material/Check"
import React, { useCallback, useMemo, useState } from "react"
import type { SubmitHandler } from "react-hook-form"
import { Controller, FormProvider } from "react-hook-form"
import styled from "styled-components"

import { useForm } from "@onelocal/frontend/common"
import type { DialogAction, DialogAlert } from "@onelocal/frontend-web/common"
import { Dialog, DialogAlertType, Grid, GridItem, RadioGroup, TextField, useModalWithParams } from "@onelocal/frontend-web/common"
import { utilHelpers } from "@onelocal/shared/common"
import { OnboardingForm } from "../../types"

const StyledFieldContainer = styled.div`
  align-items: center;
  display: flex;
  margin: 20px 0;
`

const itemTypeOptions = [
  {
    label: "Menu",
    value: OnboardingForm.SiteItemType.MENU,
  },
  {
    label: "Page Group",
    value: OnboardingForm.SiteItemType.PAGE_GROUP,
  },
]

enum FormFieldKeys {
  LABEL = "label",
  TYPE = "type",
}

type FormValues = {
  [ FormFieldKeys.LABEL ]: string
  [ FormFieldKeys.TYPE ]: OnboardingForm.SiteItemType
}

export interface OnboardingFormStepSiteAddEditItemModalProps {
  form: OnboardingForm
  item?: OnboardingForm.SiteItem
  isOpen: boolean
  onClose( reOpen?: boolean ): void
  onExited: () => void
  onSaved?: ( item: OnboardingForm.SiteItem ) => void
}

export const OnboardingFormStepSiteAddEditItemModal: React.FC<OnboardingFormStepSiteAddEditItemModalProps> = ( {
  form,
  isOpen,
  item,
  onClose,
  onExited,
  onSaved,
} ) => {
  const [ alert, setAlert ] = useState<DialogAlert | undefined>( undefined )
  const [ isProcessing, setIsProcessing ] = useState( false )
  const formMethods = useForm<FormValues>( {
    defaultValues: {
      [ FormFieldKeys.LABEL ]: item?.label,
      [ FormFieldKeys.TYPE ]: item?.type || OnboardingForm.SiteItemType.MENU,
    },
  } )
  const { control, formState, handleSubmit, setValue } = formMethods

  const resetLabel = useCallback( () => {
    setValue( FormFieldKeys.LABEL, "" )
  }, [ setValue ] )

  const onSave: SubmitHandler<FormValues> = useCallback( async ( data ) => {
    try {
      if( isProcessing ) {
        return
      }
      setIsProcessing( true )
      onClose()

      if( onSaved ) {
        if( item ) {
          if( item.type === OnboardingForm.SiteItemType.MENU || item.type === OnboardingForm.SiteItemType.PAGE_GROUP ) {
            onSaved( {
              ...item,
              label: data.label,
            } )
          }
        } else {
          if( data.type === OnboardingForm.SiteItemType.MENU || data.type === OnboardingForm.SiteItemType.PAGE_GROUP ) {
            onSaved( {
              id: utilHelpers.generateUniqueId(),
              label: data.label,
              items: [],
              type: data.type,
            } )
          }
        }
      }

    } catch( err ) {
      setAlert( {
        message: err,
        type: DialogAlertType.ERROR,
      } )
    } finally {
      setIsProcessing( false )
    }
  }, [ isProcessing, onClose, onSaved, item ] )

  const modalActions: DialogAction[] = useMemo( () => {
    const actions: DialogAction[] = [
      { title: "Cancel", type: "dismiss" },
      {
        icon: ( <CheckIcon style={ { height: 18, width: 18 } }/> ),
        onClick: handleSubmit( onSave, () => {
          setAlert( {
            message: "Please make sure all required fields are filled.",
            type: DialogAlertType.ERROR,
          } )
        } ),
        title: item ? "Save" : "Add",
        type: "primary",
      },
    ]

    return actions
  }, [ handleSubmit, item, onSave ] )

  return (
    <Dialog
      actions={ modalActions }
      alert={ alert }
      contentContainerStyle={ {
        paddingLeft: "24px",
        paddingRight: "24px",
        width: "350px",
      } }
      isOpen={ isOpen }
      noMaxWidth={ true }
      onClose={ onClose }
      onExited={ onExited }
      showDividers={ false }
      title={ `${ item ? "Edit" : "Add" } Item` }
    >
      <FormProvider { ...formMethods }>
        <StyledFieldContainer>
          <Grid spacing={ 4 }>
            {
              item == null && (
                <GridItem xs={ 12 }>
                  <Controller
                    control={ control }
                    name={ FormFieldKeys.TYPE }
                    render={ ( { field: { onChange, value } } ) => (
                      <RadioGroup
                        itemAlignment="row"
                        options={ itemTypeOptions }
                        onChange={ ( v ) => {
                          resetLabel()
                          onChange( v )
                        } }
                        value={ value }
                      />
                    ) }
                  />
                </GridItem>
              )
            }
            <GridItem xs={ 12 }>
              <Controller
                control={ control }
                name={ FormFieldKeys.LABEL }
                render={ ( { field: { onChange, value } } ) => (
                  <TextField
                    errorMessage={ formState.errors?.[ FormFieldKeys.LABEL ]?.message }
                    fullWidth
                    label="Label"
                    max={ 20 }
                    onChange={ onChange }
                    required={ true }
                    value={ value || "" }
                  />
                ) }
                rules={ {
                  validate: ( value ) => {
                    value = value?.trim()

                    if( ! value ) {
                      return "Label is required"
                    }
                    if( value.length > 20 ) {
                      return "Maximum length is 20"
                    }
                    return undefined
                  },
                } }
              />
            </GridItem>
          </Grid>
        </StyledFieldContainer>
      </FormProvider>
    </Dialog>
  )
}

export interface useOnboardingFormStepSiteAddEditItemModalParams {
  form: OnboardingForm
  item?: OnboardingForm.SiteItem
  onSaved( item: OnboardingForm.SiteItem ): void
}

export const useOnboardingFormStepSiteAddEditItemModal = () => {
  const { showModal, hideModal } = useModalWithParams<useOnboardingFormStepSiteAddEditItemModalParams>( ( { open, onExited, params } ) => {
    if( ! params ) {
      return null
    }

    return (
      <OnboardingFormStepSiteAddEditItemModal
        form={ params.form }
        isOpen={ open }
        item={ params.item }
        onClose={ hideModal }
        onExited={ onExited }
        onSaved={ params.onSaved }
      />
    )
  }, [] )

  return {
    showOnboardingFormStepSiteAddEditItemModal: showModal,
    hideOnboardingFormStepSiteAddEditItemModal: hideModal,
  }
}

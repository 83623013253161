/* eslint-disable no-restricted-imports */
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary } from "@mui/material"
import * as React from "react"

export interface AccordionGroupProps {
  items: Array<{
    content: React.ReactNode | string
    title: React.ReactNode | string
  }>
}

export const AccordionGroup: React.FC<AccordionGroupProps> = ( { items } ) => {
  return (
    <>
      {
        items.map( ( item, index ) => (
          <MuiAccordion key={ index }>
            <AccordionSummary
              expandIcon={ <ArrowDownwardIcon /> }
              aria-controls={ `panel${ index }-content` }
              id={ `panel${ index }-header` }
            >
              { item.title }
            </AccordionSummary>
            <AccordionDetails>
              { item.content }
            </AccordionDetails>
          </MuiAccordion>
        ) )
      }
    </>
  )
}

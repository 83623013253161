import { isEmpty, isEqual } from "lodash"
import { useCallback, useRef } from "react"
import { useSelector } from "react-redux"
import type { RequireExactlyOne } from "type-fest"

import type { DefaultThunkAction } from ".."
import { useAsyncDispatch } from ".."
import { InternalError } from "../helpers/errorHelper"

type SelectorWithoutId<TResource, TRootState> = ( state: TRootState ) => ( TResource | null )
type SelectorWithId<TResource, TRootState> = ( itemId: string ) => ( ( state: TRootState ) => ( TResource | null ) )

export type useResourceSingletonOptions<TResource extends { id?: string } | null, TRootState> = RequireExactlyOne<{
  getAction: () => DefaultThunkAction<TRootState, TResource | null>
  selectorWithId: SelectorWithId<TResource, TRootState>
  selector: SelectorWithoutId<TResource, TRootState>
}, "selector" | "selectorWithId">

// eslint-disable-next-line @typescript-eslint/ban-types
export const useResourceSingleton = <TResource extends ( object & { id?: string } ) | null, TRootState>( options: useResourceSingletonOptions<TResource, TRootState> ) => {
  const { getAction } = options
  const resourceIdRef = useRef<string | null>( null )

  if( ! options.selector && ! options.selectorWithId ) {
    throw new InternalError( "selector or selectorWithId is required" )
  }

  const resource = useSelector(
    options.selector
      ? options.selector
      : (
        resourceIdRef.current
          ? options.selectorWithId( resourceIdRef.current )
          : () => null
      ),
  )
  const resourceRef = useRef<TResource | null>( resource )
  resourceRef.current = resource

  const dispatch = useAsyncDispatch()

  const reloadResource = useCallback( async ( useCache?: boolean ) => {
    // Use ref to avoid to recreate the reloadResource function which will cause unwanted re-render
    if( useCache && ! isEmpty( resourceRef.current ) ) {
      return resourceRef.current
    }

    const newItem = await dispatch( getAction() )

    if( newItem?.id ) {
      resourceIdRef.current = newItem.id
    }

    return newItem
  }, [ dispatch, getAction ] )

  // Use a ref so the ref doesn't change on re-render
  if( ! isEqual( resource, resourceRef.current ) ) {
    resourceRef.current = resource
  }

  return {
    resource: resourceRef.current,
    reloadResource,
  }
}

import CheckIcon from "@mui/icons-material/Check"
import React, { useCallback, useMemo, useState } from "react"
import type { SubmitHandler } from "react-hook-form"
import { Controller, FormProvider } from "react-hook-form"
import styled from "styled-components"

import { useAsyncDispatch, useForm } from "@onelocal/frontend/common"
import type { DialogAction, DialogAlert } from "@onelocal/frontend-web/common"
import { Dialog, DialogAlertType, Grid, GridItem, TextField, useAlert, useModalWithParams } from "@onelocal/frontend-web/common"
import { siteActions } from "../store/siteStore"
import { Site } from "../types"

const siteLabels = {
  [ Site.Type.LANDING_PAGE ]: "Landing Page",
  [ Site.Type.WEBSITE ]: "Website",
}

const StyledFieldContainer = styled.div`
  align-items: center;
  display: flex;
  margin: 20px 0;
`

enum FormFieldKeys {
  DUDA_SITE_ID = "duda_site_id",
  LABEL = "label",
}

type FormValues = {
  [ FormFieldKeys.DUDA_SITE_ID ]: string
  [ FormFieldKeys.LABEL ]: string
}

export interface LinkMerchantSiteModalProps {
  isOpen: boolean
  merchantId: string
  onClose( reOpen?: boolean ): void
  onExited: () => void
  onLinked?: () => void
  type: Site.Type
}

export const LinkMerchantSiteModal: React.FC<LinkMerchantSiteModalProps> = ( {
  isOpen,
  merchantId,
  onClose,
  onExited,
  onLinked,
  type,
} ) => {
  const [ alert, setAlert ] = useState<DialogAlert | undefined>( undefined )
  const [ isProcessing, setIsProcessing ] = useState( false )
  const dispatch = useAsyncDispatch()
  const formMethods = useForm<FormValues>( {
    defaultValues: {
      [ FormFieldKeys.DUDA_SITE_ID ]: "",
    },
  } )
  const { control, formState, handleSubmit } = formMethods
  const { showSuccess } = useAlert()

  const onSave: SubmitHandler<FormValues> = useCallback( async ( data ) => {
    try {
      if( isProcessing ) {
        return
      }
      setIsProcessing( true )
      await dispatch( siteActions.site.link( merchantId, {
        dudaSiteId: data[ FormFieldKeys.DUDA_SITE_ID ],
        label: data[ FormFieldKeys.LABEL ],
        type: type,
      } ) )

      showSuccess( "Site Linked" )
      onClose()
      if( onLinked ) {
        onLinked()
      }

    } catch( err ) {
      setAlert( {
        message: err,
        type: DialogAlertType.ERROR,
      } )
    } finally {
      setIsProcessing( false )
    }
  }, [ isProcessing, dispatch, merchantId, type, showSuccess, onClose, onLinked ] )

  const modalActions: DialogAction[] = useMemo( () => {
    const actions: DialogAction[] = [
      { title: "Cancel", type: "dismiss" },
      {
        icon: ( <CheckIcon style={ { height: 18, width: 18 } }/> ),
        onClick: handleSubmit( onSave, () => {
          setAlert( {
            message: "Please make sure all required fields are filled.",
            type: DialogAlertType.ERROR,
          } )
        } ),
        title: `Link ${ siteLabels[ type ] }`,
        type: "primary",
      },
    ]

    return actions
  }, [ handleSubmit, onSave, type ] )

  return (
    <Dialog
      actions={ modalActions }
      alert={ alert }
      contentContainerStyle={ {
        paddingLeft: "24px",
        paddingRight: "24px",
      } }
      isOpen={ isOpen }
      noMaxWidth={ true }
      onClose={ onClose }
      onExited={ onExited }
      showDividers={ false }
      title={ `Link ${ siteLabels[ type ] }` }
    >
      <FormProvider { ...formMethods }>
        <StyledFieldContainer>
          <Grid spacing={ 4 }>
            <GridItem xs={ 6 }>
              <Controller
                control={ control }
                name={ FormFieldKeys.DUDA_SITE_ID }
                render={ ( { field: { onChange, value } } ) => (
                  <TextField
                    errorMessage={ formState.errors?.[ FormFieldKeys.DUDA_SITE_ID ]?.message }
                    fullWidth
                    label="Duda Site ID"
                    onChange={ onChange }
                    required={ true }
                    value={ value || "" }
                  />
                ) }
                rules={ {
                  validate: ( value ) => {
                    value = value.trim()

                    if( ! value ) {
                      return "Name is required"
                    }
                    return undefined
                  },
                } }
              />
            </GridItem>
            <GridItem xs={ 6 }>
              {
                type === Site.Type.LANDING_PAGE && (
                  <Controller
                    control={ control }
                    name={ FormFieldKeys.LABEL }
                    render={ ( { field: { onChange, value } } ) => (
                      <TextField
                        errorMessage={ formState.errors?.[ FormFieldKeys.LABEL ]?.message }
                        fullWidth
                        label="Landing Page Label"
                        onChange={ onChange }
                        required={ true }
                        value={ value || "" }
                      />
                    ) }
                    rules={ {
                      validate: ( value ) => {
                        value = ( value || "" ).trim()

                        if( ! value ) {
                          return "Landing Page Label is required"
                        }
                        return undefined
                      },
                    } }
                  />
                )
              }
            </GridItem>
          </Grid>
        </StyledFieldContainer>
      </FormProvider>
    </Dialog>
  )
}

export interface useLinkMerchantSiteModalParams {
  merchantId: string
  onLinked?(): void
  type: Site.Type
}

export const useLinkMerchantSiteModal = () => {
  const { showModal, hideModal } = useModalWithParams<useLinkMerchantSiteModalParams>( ( { open, onExited, params } ) => {
    if( ! params ) {
      return null
    }

    return (
      <LinkMerchantSiteModal
        isOpen={ open }
        merchantId={ params.merchantId }
        onClose={ hideModal }
        onExited={ onExited }
        onLinked={ params.onLinked }
        type={ params.type }
      />
    )
  }, [] )

  return {
    showLinkMerchantSiteModal: showModal,
    hideLinkMerchantSiteModal: hideModal,
  }
}

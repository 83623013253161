// eslint-disable-next-line @nx/enforce-module-boundaries
import type { SnakeCasedPropertiesDeep } from "type-fest"

import { utilHelpers } from "@onelocal/shared/common"
import type { Booking, CalendarEvent, VisitsMerchantSettings } from "."

export type ApiCalendarEvent = SnakeCasedPropertiesDeep<CalendarEvent>
export type ApiVisitsMerchantSettings = SnakeCasedPropertiesDeep<VisitsMerchantSettings>

export namespace ApiVisitsMerchantSettings {

}

export function parseCalendarEventFromApi( apiCalendarEvent: ApiCalendarEvent ) {
  const calendarEvent = utilHelpers.toCamelCase<CalendarEvent>( apiCalendarEvent )
  // Need to lowercase the field ids otherwise it doesn't match
  if( calendarEvent.booking?.answers ) {
    const answers: Record<string, Booking.Answer> = {}

    for( const fieldId in calendarEvent.booking.answers ) {
      const answer = calendarEvent.booking.answers[ fieldId ]
      if( answer ) {
        answers[ fieldId.toLowerCase() ] = answer
      }
    }

    calendarEvent.booking.answers = answers
  }

  return calendarEvent
}

export function parseVisitsMerchantSettingsFromApi( apiVisitsMerchantSettings: ApiVisitsMerchantSettings ) {
  const visitsMerchantSettings: VisitsMerchantSettings = {
    status: apiVisitsMerchantSettings.status,
  }

  if( apiVisitsMerchantSettings.bookings ) {
    visitsMerchantSettings.bookings = utilHelpers.toCamelCase( apiVisitsMerchantSettings.bookings )
  }

  return visitsMerchantSettings
}

import { useEffect, useState } from "react"

export function useDebounce<T>( value: T, delay: number ) {
  const [ debouncedValue, setDebouncedValue ] = useState( value )

  useEffect(
    () => {
      const handler = setTimeout( () => {
        setDebouncedValue( value )
      }, delay )

      return () => {
        clearTimeout( handler )
      }
    },
    [ delay, value ],
  )

  return { debouncedValue, setImmediateDebouncedValue: setDebouncedValue }
}

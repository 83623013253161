import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"
import { without } from "lodash"

import type { DefaultThunkAction, ModuleStoreConfig, PaginatedItems, PaginatedQueryOptions } from "@onelocal/frontend/common"
import { eventEmitterHelpers, ValidationError } from "@onelocal/frontend/common"
import type { SelectOption } from "@onelocal/frontend-web/common"
import { dateHelpers } from "@onelocal/shared/common"
import type { ConnectService } from "../services"
import { assistantService, connectService, inboxService } from "../services"
import { conversationsService } from "../services/conversationsService"
import { EventType, phoneService } from "../services/phoneService"
import type { ConnectAssistant, ConnectCall, ConnectChannel, ConnectInbox, ConnectVoiceToken, PhoneActiveCall } from "../types"
import { ConnectConversation, PhoneActiveCallState } from "../types"
import { connectSaga } from "./connectSagas"
import { connectSelectors } from "./connectSelectors"

export interface ConnectState {
  assistant: {
    current?: ConnectAssistant
  }
  assistant_flow_template: {
    byId: Record<string, ConnectAssistant.FlowTemplate>
  }
  audio: {
    currentInputId: string
    currentOutputId: string
    inputDevices: Array<SelectOption<string>>
    outputDevices: Array<SelectOption<string>>
  }
  calls: {
    activeCall: PhoneActiveCall | null
    byId: Record<string, ConnectCall>
    incomingCalls: PhoneActiveCall[]
  }
  channels: {
    current?: ConnectChannel
  }
  conversations: {
    byId: { [conversation_id: string]: ConnectConversation }
    byInbox: { [inbox in ConnectInbox.Type]?: string[] }
    byStatus: { [status in ConnectConversation.Status]?: string[] }
  }
  inboxes: {
    all: ConnectInbox[]
  }
  voice: ConnectState.Voice
}

const connectStoreKey = "connect"

export interface RootStateConnect {
  [ connectStoreKey ]: ConnectState
}

type ConnectThunkAction<TReturnType> = DefaultThunkAction<RootStateConnect, TReturnType>

export const connectInitialState: ConnectState = {
  assistant: {},
  assistant_flow_template: {
    byId: {},
  },
  audio: {
    currentInputId: "default",
    currentOutputId: "default",
    inputDevices: [],
    outputDevices: [],
  },
  calls: {
    activeCall: null,
    byId: {},
    incomingCalls: [],
  },
  channels: {},
  conversations: {
    byId: {},
    byInbox: {},
    byStatus: {},
  },
  inboxes: {
    all: [],
  },
  voice: {
    deviceRegisteredAt: null,
    token: null,
  },
}

export namespace ConnectState {
  export interface Voice {
    deviceRegisteredAt: string | null
    token: ConnectVoiceToken | null
  }
}

const connectSlice = createSlice( {
  name: connectStoreKey,
  initialState: connectInitialState,
  reducers: {
    activeCallAddDigits: ( state, action: PayloadAction<string> ) => {
      if( ! state.calls.activeCall ) {
        return state
      }

      state.calls.activeCall.digits += action.payload
      return state
    },
    addIncomingCall: ( state, action: PayloadAction<PhoneActiveCall> ) => {
      state.calls.incomingCalls = [ ...state.calls.incomingCalls, action.payload ]
      return state
    },
    clearIncomingCalls: ( state ) => {
      state.calls.incomingCalls = []
      return state
    },
    setActiveCallMuted: ( state, action: PayloadAction<boolean> ) => {
      if( ! state.calls.activeCall ) {
        return state
      }

      state.calls.activeCall = {
        ...state.calls.activeCall,
        isMuted: action.payload,
      }

      return state
    },
    updateActiveCall: ( state, action: PayloadAction<PhoneActiveCall | null> ) => {
      if( action.payload ) {
        state.calls.activeCall = {
          ...action.payload,
          // maintain the actual startDate if already exists for accuracy
          startedAt: state.calls.activeCall?.startedAt,
        }

        if( action.payload.state === PhoneActiveCallState.CONNECTED && ! state.calls.activeCall.startedAt ) {
          state.calls.activeCall.startedAt = ( new Date() ).toISOString()
        }
      } else {
        state.calls.activeCall = null
      }

      return state
    },
    updateAssistant: ( state, action: PayloadAction<ConnectAssistant> ) => {
      state.assistant = {
        ...state.assistant,
        current: action.payload,
      }
    },
    updateAudioCurrentInputId: ( state, action: PayloadAction<string> ) => {
      state.audio.currentInputId = action.payload
      return state
    },
    updateAudioCurrentOutputId: ( state, action: PayloadAction<string> ) => {
      state.audio.currentOutputId = action.payload
      return state
    },
    updateAudioInputDevices: ( state, action: PayloadAction<Array<SelectOption<string>>> ) => {
      state.audio.inputDevices = action.payload
      return state
    },
    updateAudioOutputDevices: ( state, action: PayloadAction<Array<SelectOption<string>>> ) => {
      state.audio.outputDevices = action.payload
      return state
    },
    updateConnectCall: ( state, action: PayloadAction<ConnectCall> ) => {
      state.calls.byId[ action.payload.id ] = action.payload
      return state
    },
    updateConnectCalls: ( state, action: PayloadAction<ConnectCall[]> ) => {
      for( const call of action.payload ) {
        state.calls.byId[ call.id ] = call
      }

      return state
    },
    updateConnectInboxes: ( state, action: PayloadAction<ConnectInbox[]> ) => {
      state.inboxes.all = action.payload
    },
    updateConversations: ( state, action: PayloadAction<ConnectConversation[]> ) => {
      const conversations = action.payload

      if( ! state.conversations.byId ) {
        state.conversations.byId = {}
      }

      if( ! state.conversations.byStatus ) {
        state.conversations.byStatus = {}
      }

      if( ! state.conversations.byStatus[ ConnectConversation.Status.OPEN ] ) {
        state.conversations.byStatus[ ConnectConversation.Status.OPEN ] = []
      }

      if( ! state.conversations.byStatus[ ConnectConversation.Status.CLOSED ] ) {
        state.conversations.byStatus[ ConnectConversation.Status.CLOSED ] = []
      }

      for( const conversation of conversations ) {
        state.conversations.byId[ conversation.id ] = conversation

        if( ! state.conversations.byStatus ) {
          state.conversations.byStatus = {}
        }

        const includeStatus = conversation.status
        const removeStatus = conversation.status === ConnectConversation.Status.OPEN ? ConnectConversation.Status.CLOSED : ConnectConversation.Status.OPEN

        if( ! state.conversations.byStatus[ includeStatus ]!.includes( conversation.id ) ) {
          state.conversations.byStatus[ includeStatus ]!.push( conversation.id )
        }

        if( state.conversations.byStatus[ removeStatus ]!.includes( conversation.id ) ) {
          state.conversations.byStatus[ removeStatus ] = without(
            state.conversations.byStatus[ removeStatus ]!,
            conversation.id,
          )
        }
      }
    },
    updateCurrentChannel: ( state, action: PayloadAction<ConnectChannel> ) => {
      state.channels = {
        ...state.channels,
        current: action.payload,
      }
      return state
    },
    updateFlowTemplate: ( state, action: PayloadAction<ConnectAssistant.FlowTemplate[]> ) => {
      state.assistant_flow_template = {
        ...state,
        byId: action.payload.reduce( ( result, template ) => ( {
          ...result,
          [ template.id ]: template,
        } ), {} ),
      }

      return state
    },
    updateVoice( state, action: PayloadAction<Partial<ConnectState.Voice>> ) {
      state.voice = {
        ...state.voice,
        ...action.payload,
      }
      return state
    },
    removeIncomingCall: ( state, action: PayloadAction<PhoneActiveCall> ) => {
      state.calls.incomingCalls = state.calls.incomingCalls.filter( ( incomingCall ) => incomingCall.connectCallId !== action.payload.connectCallId )
      return state
    },
    removeIncomingCallById: ( state, action: PayloadAction<string> ) => {
      state.calls.incomingCalls = state.calls.incomingCalls.filter( ( incomingCall ) => incomingCall.connectCallId !== action.payload )
      return state
    },
  },
} )

export const connectActions = {
  activeCall: {
    addDigits( digits: string ) {
      phoneService.sendDigitsToActiveCall( digits )
      return connectSlice.actions.activeCallAddDigits( digits )
    },
    acceptIncomingCallById( id: string ) {
      phoneService.acceptIncomingCallById( id )
      return connectSlice.actions.removeIncomingCallById( id )
    },
    addIncomingCall( incomingCall: PhoneActiveCall ) {
      return connectSlice.actions.addIncomingCall( incomingCall )
    },
    clearIncomingCalls() {
      return connectSlice.actions.clearIncomingCalls()
    },
    declineIncomingCallById( id: string ) {
      phoneService.declineIncomingCallById( id )
      return connectSlice.actions.removeIncomingCallById( id )
    },
    removeIncomingCall( incomingCall: PhoneActiveCall ) {
      return connectSlice.actions.removeIncomingCall( incomingCall )
    },
    removeIncomingCallById( id: string ) {
      return connectSlice.actions.removeIncomingCallById( id )
    },
    setActiveCallMuted( muted: boolean ) {
      phoneService.setActiveCallMuted( muted )
      return connectSlice.actions.setActiveCallMuted( muted )
    },
    startOutboundCall( to: string, merchantId: string ): ConnectThunkAction<PhoneActiveCall | null> {
      return async ( dispatch, getState ) => {
        const isOnCall = phoneService.isOnCall()

        if( isOnCall ) {
          throw new ValidationError( "You cannot place a call if you're already on another call." )
        }

        const state = getState()
        const voice = connectSelectors.voice( state )

        let voiceToken = voice.token

        if( ! voiceToken || dateHelpers.differenceInMinutes( new Date(), voiceToken.expireAt ) > 15 ) {
          voiceToken = await phoneService.getVoiceToken( merchantId )
          dispatch( connectSlice.actions.updateVoice( { token: voiceToken } ) )
        }

        dispatch( connectSlice.actions.updateVoice( { token: voiceToken } ) )

        const connectCall = await connectService.createOutboundCall( merchantId, to )
          .catch( ( err ) => {
            eventEmitterHelpers.emit( EventType.CALL_ERROR, err.message )
            throw err
          } )

        dispatch( connectSlice.actions.updateConnectCall( connectCall ) )

        const activeCall: PhoneActiveCall = {
          connectCallId: connectCall.id,
          digits: "",
          sid: "",
          from: connectCall.from,
          isMuted: false,
          state: PhoneActiveCallState.CONNECTING,
          to,
        }

        dispatch( connectSlice.actions.updateActiveCall( activeCall ) )

        await phoneService.startOutboundCall( { connectCallId: connectCall.id, to } )

        return activeCall
      }
    },
    stopActiveCall() {
      phoneService.stopCall()
      return connectSlice.actions.updateActiveCall( null )
    },
    updateActiveCall( activeCall: PhoneActiveCall | null ) {
      return connectSlice.actions.updateActiveCall( activeCall )
    },
  },
  assistant: {
    createFlow( merchantId: string, assistantId: string, model: ConnectAssistant.FlowCreateModel ): ConnectThunkAction<ConnectAssistant> {
      return async ( dispatch ) => {
        const assistant = await assistantService.createFlow( merchantId, assistantId, model )
        dispatch( connectSlice.actions.updateAssistant( assistant ) )
        return assistant
      }
    },
    updateFlow( merchantId: string, assistantId: string, model: ConnectAssistant.FlowUpdateModel ): ConnectThunkAction<ConnectAssistant> {
      return async ( dispatch ) => {
        const assistant = await assistantService.updateFlow( merchantId, assistantId, model )
        dispatch( connectSlice.actions.updateAssistant( assistant ) )
        return assistant
      }
    },
    deleteOneFlow( merchantId: string, assistantId: string, flowId: string ): ConnectThunkAction<ConnectAssistant> {
      return async ( dispatch ) => {
        const assistant = await assistantService.deleteOneFlow( merchantId, assistantId, flowId )
        dispatch( connectSlice.actions.updateAssistant( assistant ) )
        return assistant
      }
    },
    get( merchantId: string, phoneNumberId: string ): ConnectThunkAction<ConnectAssistant> {
      return async ( dispatch ) => {
        const assistant = await assistantService.getAssistant( merchantId, phoneNumberId )
        dispatch( connectSlice.actions.updateAssistant( assistant ) )
        return assistant
      }
    },
    reorderFlows( merchantId: string, assistantId: string, phoneNumberId: string, flowIds: string[] ): ConnectThunkAction<ConnectAssistant> {
      return async ( dispatch ) => {
        const assistant = await assistantService.reorderFlows( merchantId, assistantId, phoneNumberId, flowIds )
        dispatch( connectSlice.actions.updateAssistant( assistant ) )
        return assistant
      }
    },
    update( merchantId: string, model: ConnectAssistant.UpdateModel ): ConnectThunkAction<ConnectAssistant> {
      return async ( dispatch ) => {
        const assistant = await assistantService.updateAssistant( merchantId, model )
        dispatch( connectSlice.actions.updateAssistant( assistant ) )
        return assistant
      }
    },
  },
  assistantFlowTemplate: {
    get( merchantId: string ): ConnectThunkAction<ConnectAssistant.FlowTemplate[]> {
      return async ( dispatch ) => {
        const result = await assistantService.getAssistantFlowTemplates( merchantId )
        dispatch( connectSlice.actions.updateFlowTemplate( result ) )
        return result
      }
    },
  },
  audio: {
    updateCurrentInputId( audioInputId: string ) {
      phoneService.updateInputDevice( audioInputId )
      return connectSlice.actions.updateAudioCurrentInputId( audioInputId )
    },
    updateCurrentOutputId( audioOutputId: string ) {
      phoneService.updateOutputDevice( audioOutputId )
      return connectSlice.actions.updateAudioCurrentOutputId( audioOutputId )
    },
    updateInputDevices( inputDevices: Array<SelectOption<string>> ) {
      return connectSlice.actions.updateAudioInputDevices( inputDevices )
    },
    updateOutputDevices( outputDevices: Array<SelectOption<string>> ) {
      return connectSlice.actions.updateAudioOutputDevices( outputDevices )
    },
  },
  calls: {
    getById( merchantId: string, connectCallId: string ): ConnectThunkAction<ConnectCall> {
      return async ( dispatch ) => {
        const connectCall = await connectService.getCallById( merchantId, connectCallId )
        dispatch( connectSlice.actions.updateConnectCall( connectCall ) )
        return connectCall
      }
    },
    queryCalls( merchantId: string, filter: ConnectService.QueryFilter, options: PaginatedQueryOptions ): ConnectThunkAction<PaginatedItems<ConnectCall>> {
      return async ( dispatch ) => {
        const paginatedCalls = await connectService.queryCalls( merchantId, filter, options )
        dispatch( connectSlice.actions.updateConnectCalls( paginatedCalls.items ) )
        return paginatedCalls
      }
    },

    setAsView( merchantId: string, connectCallId: string ): ConnectThunkAction<ConnectCall> {
      return async ( dispatch ) => {
        const connectCall = await connectService.setCallAsView( merchantId, connectCallId )
        dispatch( connectSlice.actions.updateConnectCall( connectCall ) )
        return connectCall
      }
    },
  },
  channel: {
    get( merchantId: string ): ConnectThunkAction<ConnectChannel> {
      return async ( dispatch ) => {
        const channel = await connectService.getCurrentChannel( merchantId )
        dispatch( connectSlice.actions.updateCurrentChannel( channel ) )
        return channel
      }
    },
    update( merchantId: string, model: ConnectChannel.UpdateModel ): ConnectThunkAction<ConnectChannel> {
      return async ( dispatch ) => {
        const channel = await connectService.updateChannel( merchantId, model )
        dispatch( connectSlice.actions.updateCurrentChannel( channel ) )
        return channel
      }
    },
  },
  conversations: {
    get( merchantId: string, conversationId: string ): ConnectThunkAction<ConnectConversation> {
      return async ( dispatch ) => {
        const conversation = await conversationsService.getConversation( merchantId, conversationId )
        dispatch( connectSlice.actions.updateConversations( [ conversation ] ) )
        return conversation
      }
    },
  },
  inboxes: {
    get( merchantId: string ): ConnectThunkAction<ConnectInbox[]> {
      return async ( dispatch ) => {
        const connectInboxes = await inboxService.queryConnectInbox( merchantId )
        dispatch( connectSlice.actions.updateConnectInboxes( connectInboxes ) )
        return connectInboxes
      }
    },
  },
  voice: {
    registerDevice( merchantId: string ): ConnectThunkAction<void> {
      return async ( dispatch ) => {
        const token = await phoneService.registerDevice( merchantId )
        dispatch( connectSlice.actions.updateVoice( { deviceRegisteredAt: ( new Date() ).toISOString(), token } ) )
      }
    },
    unregisterDevice(): ConnectThunkAction<void> {
      return async ( dispatch ) => {
        phoneService.unregisterDevice()
        dispatch( connectSlice.actions.updateVoice( { deviceRegisteredAt: null, token: null } ) )
      }
    },
  },
}

export const connectStoreConfig: ModuleStoreConfig<ConnectState> = {
  key: connectStoreKey,
  initialState: connectInitialState,
  reducers: connectSlice.reducer,
  saga: connectSaga,
}

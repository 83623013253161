import { apiHelper } from "@onelocal/frontend/common"
import type { Site, SiteTemplate } from "../types"
import type { ApiSiteTemplate } from "../types/api"
import { parseSiteTemplateFromApi } from "../types/api"

export namespace SiteTemplateService {
  export interface QueryFilter {
    isAvailable?: boolean
    type?: Site.Type
  }

  export interface CreateUpdateModel extends Omit<SiteTemplate, "id" | "dudaId" | "isAvailable"> {
    dudaId: string
    isAvailable: boolean
  }
}

export const siteTemplateService = {
  async query( query?: SiteTemplateService.QueryFilter ) {
    return apiHelper.request( {
      method: "GET",
      params: {
        is_available: query?.isAvailable,
        type: query?.type,
      },
      parsingData: ( apiSiteTemplates: ApiSiteTemplate[] ) => apiSiteTemplates.map( parseSiteTemplateFromApi ),
      url: "/local-site/site-templates",
    } )
  },

  async create( siteTemplate: SiteTemplateService.CreateUpdateModel ) {
    const body = {
      duda_id: siteTemplate.dudaId,
      is_available: siteTemplate.isAvailable,
      name: siteTemplate.name,
      types: siteTemplate.types,
    }

    return apiHelper.request( {
      method: "POST",
      data: body,
      parsingData: ( apiSiteTemplate: ApiSiteTemplate ) => parseSiteTemplateFromApi( apiSiteTemplate ),
      url: "/local-site/site-templates",
    } )
  },

  async update( siteTemplateId: string, siteTemplate: SiteTemplateService.CreateUpdateModel ) {
    const body = {
      duda_id: siteTemplate.dudaId,
      is_available: siteTemplate.isAvailable,
      name: siteTemplate.name,
      types: siteTemplate.types,
    }

    return apiHelper.request( {
      method: "PUT",
      data: body,
      parsingData: ( apiSiteTemplate: ApiSiteTemplate ) => parseSiteTemplateFromApi( apiSiteTemplate ),
      url: `/local-site/site-templates/${ siteTemplateId }`,
    } )
  },

  async delete( siteTemplateId: string ) {
    return apiHelper.request( {
      method: "DELETE",
      url: `/local-site/site-templates/${ siteTemplateId }`,
    } )
  },
}

import { useCallback } from "react"

import { useResourceSingleton } from "@onelocal/frontend/common"
import { authActions } from ".."
import { authSelectors } from "../store/authSelectors"

export const useCurrentInternalAccount = () => {
  const getAction = useCallback( () => {
    return authActions.internalAccounts.getCurrent()
  }, [] )

  const {
    resource,
    reloadResource,
  } = useResourceSingleton( {
    getAction,
    selector: authSelectors.internalAccounts.current,
  } )

  return {
    currentInternalAccount: resource!,
    reloadCurrentInternalAccount: reloadResource,
  }
}

import { EventEmitter } from "eventemitter3"

import { apiHelper, pubSubService } from "@onelocal/frontend/common"
import type { MainActivity } from "../types"
import type { ApiActivity } from "../types/api"
import { parseActivitiesStatsFromApi, parseActivityFromApi } from "../types/api"

export namespace ActivitiesService {
  export interface QueryFilter {
    afterDate?: Date
    category?: MainActivity.Category
    ids?: string[]
    isAssignedToMe?: boolean
    sinceDate?: Date
    status?: MainActivity.Status
  }

  export interface QueryOptions {
    page: number
    perPage: number
  }
}

const eventEmitter = new EventEmitter()

const ACTIVITY_CREATED_EVENT = "activity_created"
const ACTIVITY_DELETED_EVENT = "activity_deleted"
const ACTIVITY_UPDATED_EVENT = "activity_updated"

export const activitiesService = {
  async archiveActivity( merchantId: string, activityId: string ) {
    const activity = await apiHelper.request( {
      method: "POST",
      url: `/merchants/${ merchantId }/main/activities/${ activityId }/archive`,
      parsingData: parseActivityFromApi,
    } )

    activitiesService.emitActivityUpdated( activity )

    return activity
  },
  async deleteActivity( merchantId: string, activityId: string ) {
    const activity = await apiHelper.request( {
      method: "DELETE",
      url: `/merchants/${ merchantId }/main/activities/${ activityId }`,
    } )

    activitiesService.emitActivityDeleted( activityId )

    return activity
  },
  async dismissActivity( merchantId: string, activityId: string ) {
    const activity = await apiHelper.request( {
      method: "POST",
      url: `/merchants/${ merchantId }/main/activities/${ activityId }/dismiss`,
      parsingData: parseActivityFromApi,
    } )

    activitiesService.emitActivityUpdated( activity )

    return activity
  },
  emitActivityCreated( activityId: string ) {
    eventEmitter.emit( ACTIVITY_CREATED_EVENT, activityId )
  },
  emitActivityDeleted( activityId: string ) {
    eventEmitter.emit( ACTIVITY_DELETED_EVENT, activityId )
  },
  emitActivityUpdated( activity: MainActivity ) {
    eventEmitter.emit( ACTIVITY_UPDATED_EVENT, activity )
  },
  async getStats( merchantId: string ) {
    return apiHelper.request( {
      method: "GET",
      url: `/merchants/${ merchantId }/main/activities/stats`,
      parsingData: parseActivitiesStatsFromApi,
    } )
  },
  async markAsRead( merchantId: string, { conversationId, reviewId }: { conversationId?: string, reviewId?: string } ) {
    return apiHelper.request( {
      data: {
        conversation_id: conversationId,
        review_id: reviewId,
      },
      method: "POST",
      url: `/merchants/${ merchantId }/main/activities/read`,
      parsingData: parseActivityFromApi,
    } )
  },
  onActivityCreated( handler: ( activityId: string ) => void ) {
    eventEmitter.addListener( ACTIVITY_CREATED_EVENT, handler )

    return () => {
      eventEmitter.removeListener( ACTIVITY_CREATED_EVENT, handler )
    }
  },
  onActivityDeleted( handler: ( activityId: string ) => void ) {
    eventEmitter.addListener( ACTIVITY_DELETED_EVENT, handler )

    return () => {
      eventEmitter.removeListener( ACTIVITY_DELETED_EVENT, handler )
    }
  },
  onActivityUpdated( handler: ( activity: MainActivity ) => void ) {
    eventEmitter.addListener( ACTIVITY_UPDATED_EVENT, handler )

    return () => {
      eventEmitter.removeListener( ACTIVITY_UPDATED_EVENT, handler )
    }
  },
  async restoreActivity( merchantId: string, activityId: string ) {
    const activity = await apiHelper.request( {
      method: "POST",
      url: `/merchants/${ merchantId }/main/activities/${ activityId }/restore`,
      parsingData: parseActivityFromApi,
    } )

    activitiesService.emitActivityUpdated( activity )

    return activity
  },
  async restoreActivityFromDismiss( merchantId: string, activityId: string ) {
    const activity = await apiHelper.request( {
      method: "POST",
      url: `/merchants/${ merchantId }/main/activities/${ activityId }/restore-from-dismiss`,
      parsingData: parseActivityFromApi,
    } )

    activitiesService.emitActivityUpdated( activity )

    return activity
  },
  async restoreDeletedActivity( merchantId: string, activityId: string ) {
    const activity = await apiHelper.request( {
      method: "POST",
      url: `/merchants/${ merchantId }/main/activities/${ activityId }/restore-deleted`,
      parsingData: parseActivityFromApi,
    } )

    activitiesService.emitActivityUpdated( activity )

    return activity
  },
  async query( merchantId: string, filter: ActivitiesService.QueryFilter, options: ActivitiesService.QueryOptions ) {
    const activities = await apiHelper.getPaginatedItems<ApiActivity, MainActivity>( {
      url: `/merchants/${ merchantId }/main/activities`,
      params: {
        after_date: filter.afterDate?.toISOString(),
        category: filter.category,
        ids: filter.ids ? filter.ids.join( "," ) : undefined,
        is_assigned_to_me: filter.isAssignedToMe,
        page: options.page,
        per_page: options.perPage,
        status: filter.status,
        since_date: filter.sinceDate?.toISOString(),
      },
      parsingData: parseActivityFromApi,
      ...options,
      method: "GET",
    } )

    return activities
  },
}

interface ActivityCreatedEvent {
  activity_id: string
  merchant_id: string
}

pubSubService.on( "activity_created", async ( data: ActivityCreatedEvent ) => {
  activitiesService.emitActivityCreated( data.activity_id )
} )

import React, { useCallback, useMemo } from "react"
import type { LinkProps as RouterLinkProps } from "react-router-dom"
import { Link as RouterLink } from "react-router-dom"
import styled, { css } from "styled-components"

import { utilHelpers } from "@onelocal/shared/common"
import { styleHelpers } from "../helpers/styleHelpers"

interface StyledBaseProps {
  color: string
  hoverColor?: string
  underline?: boolean
}

const StyledBaseCss = css<StyledBaseProps>`
  color: ${ props => props.color };
  cursor: pointer;
  font-weight: 500;
  outline: 0;
  text-decoration: underline;

  &:hover {
    color: ${ props => props.hoverColor || props.color };
  }
`

type StyledLinkProps = React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> & StyledBaseProps

const StyledLink = styled.a<StyledLinkProps>`
  ${ StyledBaseCss }
`

type StyledRouterLinkProps = RouterLinkProps & StyledBaseProps

const StyledRouterLink = styled( RouterLink )<StyledRouterLinkProps>`
  ${ StyledBaseCss }
`

export type LinkColor = "active" | "gray" | "primary"

export interface LinkProps extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  color?: LinkColor
  isInternalLink?: boolean
}

export const Link: React.FC<LinkProps> = ( {
  color,
  isInternalLink,
  onClick,
  href,
  ...otherProps
} ) => {
  color = color ?? "active" as LinkColor

  const colorValue = useMemo( () => {
    const colorName = color ?? "active"

    switch( colorName ) {
      case "active":
        return styleHelpers.colors.active
      case "gray":
        return styleHelpers.colors.darkGray
      case "primary":
        return styleHelpers.colors.primary
      default:
        utilHelpers.assertNever( colorName )
        throw new Error( `Unknown color (${ colorName })` )
    }
  }, [ color ] )

  const isInternalLinkValue = useMemo( () => {
    if( isInternalLink !== undefined ) {
      return isInternalLink
    }

    if( href && /^\/(?!\/)/.test( href ) ) {
      return true
    }

    return false

  }, [ href, isInternalLink ] )

  const onClickHandler = useCallback( ( event: React.MouseEvent<HTMLAnchorElement, MouseEvent> ) => {
    event.preventDefault()

    if( ! href ) {
      return
    }

    if( otherProps.target ) {
      window.open( href, otherProps.target )
    } else {
      window.location.href = href
    }
  }, [ href, otherProps.target ] )

  if( isInternalLinkValue ) {
    return (
      <StyledRouterLink
        color={ colorValue }
        href={ href }
        to={ href }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        { ...otherProps as any }
      />
    )
  }

  return (
    <StyledLink
      color={ colorValue }
      href={ href }
      onClick={ onClick || onClickHandler }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { ...otherProps as any }
    />
  )
}

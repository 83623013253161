// eslint-disable-next-line no-restricted-imports
import type { SelectChangeEvent } from "@mui/material"
// eslint-disable-next-line no-restricted-imports
import { FormControl as MuiFormControl, InputLabel as MuiInputLabel, MenuItem as MuiMenuItem, Select as MuiSelect } from "@mui/material"
import React, { useCallback } from "react"
import styled from "styled-components"

import { styleHelpers } from "../helpers/styleHelpers"
import type { SelectOptionValueType } from "."

const StyledFormControl = styled( MuiFormControl )`
  & {
    background: white;
    border-radius: 3px;
    border: 2px solid #bbb;
    display: inline-flex;
    flex-direction: row;
  }

  & .MuiSelect-select {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    color: ${ styleHelpers.colors.dark };
    font-weight: ${ styleHelpers.fonts.weight.semiBold };
    min-width: 30px;
    padding-bottom: 0;
    padding-top: 0;
    padding: 5px 10px;
  }

  & fieldset {
    border: none;
  }

  & .MuiInputLabel-root {
    color: #777;
    display: block;
    font-size: 12px;
    font-weight: ${ styleHelpers.fonts.weight.semiBold };
    left: initial;
    margin: 0;
    max-width: initial;
    overflow: initial;
    padding: 5px 10px;
    position: initial;
    top: initial;
    transform: none;
    transition: none;
  }

  & .MuiOutlinedInput-root {
    flex: 1;
  }
`

const StyledLabelContainer = styled.div`
  align-items: center;
  border-right: 1px solid #bbb;
  display: inline-flex;
  flex-direction: row;
`

export interface SelectInlineProps<T extends SelectOptionValueType> {
  disabled?: boolean
  fullWidth?: boolean
  label?: string
  onChange?( value: T ): void
  onValueSelected?( value: T ): void
  options: Array<SelectInlineOption<T>>
  style?: React.CSSProperties
  value: T
}

export interface SelectInlineOption<T extends SelectOptionValueType> {
  disabled?: boolean
  label: string
  value: T
}

export const SelectInline = function<T extends SelectOptionValueType>( {
  disabled,
  fullWidth,
  label,
  onChange,
  onValueSelected,
  options,
  style,
  value,
}: SelectInlineProps<T> ) {
  const handleChange = useCallback( ( event: SelectChangeEvent<unknown> ) => {
    if( onChange ) {
      onChange( event.target.value as T )
    }
  }, [ onChange ] )

  const handleClick = useCallback( ( clickedValue: T ) => {
    if( onValueSelected ) {
      onValueSelected( clickedValue )
    }
  }, [ onValueSelected ] )

  return (
    <StyledFormControl disabled={ disabled } fullWidth={ fullWidth } style={ style }>
      <StyledLabelContainer>
        <MuiInputLabel>{ label }</MuiInputLabel>
      </StyledLabelContainer>
      <MuiSelect
        onChange={ handleChange }
        value={ value }
      >
        {
          options.map( ( option ) => (
            <MuiMenuItem
              disabled={ option.disabled }
              key={ option.value }
              onClick={ () => { handleClick( option.value ) } }
              value={ option.value }
            >
              { option.label }
            </MuiMenuItem>
          ) )
        }
      </MuiSelect>
    </StyledFormControl>
  )
}

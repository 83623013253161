import type { Name, ProductTypes } from "@onelocal/shared/common"

export type DashboardContext = DashboardContext.AgencyContext | DashboardContext.MerchantContext

export namespace DashboardContext {
  export enum ContextType {
    AGENCY = "agency",
    MERCHANT = "merchant",
  }

  export interface BaseContext {
    contextType: ContextType
  }

  export interface AgencyContext extends BaseContext {
    agencyId: string
    agency: CommonAgency
    contextType: ContextType.AGENCY
  }

  interface CommonAgency {
    id: string
    flattenGroups?: Array<Omit<AgencyGroup, "groups">>
    groups?: AgencyGroup[]
    groupsIdentifiersMap?: Map<string, string[]>
    groupsMerchantsMap?: Map<string, string[]>
  }

  export interface MerchantContext extends BaseContext {
    agency?: CommonAgency
    contextType: ContextType.MERCHANT
    merchantId: string
    products: ProductTypes[]
    rootMerchantId: string
    timezone: string
  }
}

export interface AgencyGroup {
  id: string
  name: string
  nickname?: string
  type: string
  // merchants?: Merchant[]
  groups?: AgencyGroup[]
  parentGroupId?: string
  stats?: Array<{
    type: string
    count: number
  }>
}

export interface Amount {
  amount: number
  currency: string
}

export interface GataUser {
  id: string
  avatarUrl?: string
  bioText?: string
  calendlyLink?: string
  email: string
  name: {
    family: string
    given: string
  }
  salesforceId?: string
}

export interface Merchant {
  id: string
  agency?: {
    id: string
    groupIds: string[]
  }
  locationNickname?: string
  members: Array<{
    id: string
    ref: "merchants"
  }>
  name: string
  parentId?: string
  referencePrefix?: string
  rootId: string
  slug: string
  timezone: string
}

export interface ProductUpdate {
  action?: ProductUpdate.Action
  assignedExpert?: {
    avatarUrl?: string
    gataUserId?: string
    name?: Name
    salesforceId: string
  }
  created: {
    at: string
  }
  html?: string
  id: string
  merchantId: string
  text: string
  type: ProductUpdate.Type
}

export namespace ProductUpdate {
  export interface Action {
    enabled?: boolean
    label?: string
    status?: Status
    url?: string
  }

  export enum Status {
    COMPLETED = "completed",
    PENDING = "pending",
  }

  export enum Type {
    LOCAL_ADS = "clear_growth",
    LOCAL_SEO = "seo_boost",
    LOCAL_SITE = "first_impression",
  }
}

import { useCallback, useRef } from "react"
import { useSelector } from "react-redux"

// eslint-disable-next-line @nx/enforce-module-boundaries
import { useDashboardContext } from "@onelocal/frontend-dashboard/common"
import { useRootDispatch } from "@onelocal/frontend-dashboard-web/main"
import { settingsSelectors } from "../store/settingsSelector"
import { settingsActions } from "../store/settingsStore"

export const useMerchantSettings = function() {
  const dispatch = useRootDispatch()
  const merchantIdRef = useRef<string | null>( null )
  const { dashboardContext } = useDashboardContext()

  const merchantSettings = useSelector(
    dashboardContext?.merchantId
      ? settingsSelectors.merchantSettings.byId( dashboardContext.merchantId )
      : () => null,
  )

  merchantIdRef.current = dashboardContext?.merchantId || null

  const reloadMerchantSettings = useCallback( async ()=> {
    if( ! merchantIdRef.current ) {
      return
    }

    return dispatch( settingsActions.getMerchantSettings( merchantIdRef.current ) )
  }, [ dispatch ] )

  return {
    merchantSettings,
    reloadMerchantSettings,
  }
}

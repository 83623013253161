import type { PaginatedQueryOptions } from "@onelocal/frontend/common"
import { apiHelper } from "@onelocal/frontend/common"
import type { Activity } from "../types"
import { parseActivityFromApi } from "../types/api"

export namespace ActivitiesService {
  export interface QueryFilter {
    approvalStatuses?: Activity.ApprovalStatus[]
    merchantId?: string
    types?: Activity.Type[]
  }

  export interface UpdateApprovalStatusModel {
    approvalStatus: Activity.ApprovalStatus
  }
}

export const activitiesService = {
  async queryActivities( query: ActivitiesService.QueryFilter, options: PaginatedQueryOptions ) {
    return apiHelper.getPaginatedItems( {
      method: "GET",
      params: {
        approval_statuses: query.approvalStatuses?.join( "," ),
        merchant_id: query.merchantId,
        page: options.page,
        per_page: options.perPage,
        types: query.types?.join( "," ),
      },
      parsingData: parseActivityFromApi,
      url: "/main/activities",
    } )
  },
  async updateActivityApprovalStatus( activityId: string, model: ActivitiesService.UpdateApprovalStatusModel ) {
    return apiHelper.request( {
      data: {
        approval: model.approvalStatus,
      },
      method: "PUT",
      parsingData: parseActivityFromApi,
      url: `/main/activities/${ activityId }`,
    } )
  },
}

import { isNumber } from "lodash"
import type { SnakeCasedPropertiesDeep } from "type-fest"

import { InternalError, sentryHelper } from "@onelocal/frontend/common"
import { utilHelpers } from "@onelocal/shared/common"
import type { ResponseConversation } from "."
import { ResponseMerchantSettings } from "."

export type ApiResponseConversation = SnakeCasedPropertiesDeep<ResponseConversation>

export type ApiResponseMerchantSettings = SnakeCasedPropertiesDeep<ResponseMerchantSettings>

export type ApiResponseFlow = SnakeCasedPropertiesDeep<ResponseMerchantSettings.Flow>

export type ApiResponseFlowAction = SnakeCasedPropertiesDeep<ResponseMerchantSettings.Flow.Action>

// Used from onelocal-mobile
export function parseResponseConversationFromApi( apiConversation: ApiResponseConversation ) {
  const conversation = utilHelpers.toCamelCase<ResponseConversation>( apiConversation )

  if( isNumber( apiConversation.duration ) ) {
    conversation.duration = { total: apiConversation.duration }
  }

  return conversation
}

export function parseResponseMerchantSettingsFromApi( apiMerchantSettings: ApiResponseMerchantSettings ) {
  try {
    const merchantSettings: ResponseMerchantSettings = {
      agent: (
        apiMerchantSettings.agent
          ? {
            greeting: apiMerchantSettings.agent.greeting,
            mode: apiMerchantSettings.agent.mode,
            voice: apiMerchantSettings.agent.voice,
            greetingAudioFileUrl: apiMerchantSettings.agent.greeting_audio_file_url,
          }
          : undefined
      ),
      autopilotAssistantId: apiMerchantSettings.autopilot_assistant_id,
      fallbackFlow: (
        apiMerchantSettings.fallback_flow
          ? {
            id: apiMerchantSettings.fallback_flow.id,
            maxRetries: apiMerchantSettings.fallback_flow.max_retries,
            name: apiMerchantSettings.fallback_flow.name,
            nextAction: parseResponseMerchantSettingsFlowActionFromApi( apiMerchantSettings.fallback_flow.next_action ) as ResponseMerchantSettings.Flow.ActionForward | ResponseMerchantSettings.Flow.ActionHangUp | ResponseMerchantSettings.Flow.ActionSendSms,
            text: apiMerchantSettings.fallback_flow.text,
          }
          : undefined
      ),
      flows: apiMerchantSettings.flows?.map( parseResponseMerchantSettingsFlowFromApi ),
      forwardFlow: (
        apiMerchantSettings.forward_flow
          ? {
            enabled: apiMerchantSettings.forward_flow.enabled,
            id: apiMerchantSettings.forward_flow.id,
            name: apiMerchantSettings.forward_flow.name,
            text: apiMerchantSettings.forward_flow.text,
            to: apiMerchantSettings.forward_flow.to,
          }
          : undefined
      ),
      id: apiMerchantSettings.id,
      monthlyInboundMins: apiMerchantSettings.monthly_inbound_mins,
      status: apiMerchantSettings.status,
      v: apiMerchantSettings.v,
      voicemailFlow: (
        apiMerchantSettings.voicemail_flow
          ? {
            id: apiMerchantSettings.voicemail_flow.id,
            name: apiMerchantSettings.voicemail_flow.name,
            text: apiMerchantSettings.voicemail_flow.text,
            audioFileUrl: apiMerchantSettings.voicemail_flow.audio_file_url,
          }
          : undefined
      ),
      whitelist: apiMerchantSettings.whitelist,
    }

    return merchantSettings
  } catch( err ) {
    sentryHelper.logError(
      err as Error,
      {
        extras: {
          merchantId: apiMerchantSettings.id,
          merchantSettings: JSON.stringify( apiMerchantSettings ),
        },
      },
    )

    return {
      id: apiMerchantSettings.id,
      v: apiMerchantSettings.v,
      monthlyInboundMins: apiMerchantSettings.monthly_inbound_mins,
    } as ResponseMerchantSettings
  }
}

export function parseResponseMerchantSettingsFlowFromApi( apiMerchantSettingsFlow: ApiResponseFlow ) {
  const merchantSettingsFlow: ResponseMerchantSettings.Flow = {
    answer: apiMerchantSettingsFlow.answer,
    enabled: apiMerchantSettingsFlow.enabled,
    id: apiMerchantSettingsFlow.id,
    name: apiMerchantSettingsFlow.name,
    nextAction: parseResponseMerchantSettingsFlowActionFromApi( apiMerchantSettingsFlow.next_action ),
    steps: apiMerchantSettingsFlow.steps.map( ( step ) => {
      switch( step.type ) {
        case ResponseMerchantSettings.Flow.StepType.PROMPT_SMS: {
          return {
            prompt: step.prompt,
            text: step.text,
            type: step.type,
          } as ResponseMerchantSettings.Flow.StepPrompt
        }
        case ResponseMerchantSettings.Flow.StepType.SEND_SMS: {
          return {
            text: step.text,
            type: step.type,
          } as ResponseMerchantSettings.Flow.StepSendSMS
        }
        default:
          utilHelpers.assertNever( step )
          throw new InternalError( `Unknown suggestion type: ${ step }` )
      }
    } ),
    triggers: apiMerchantSettingsFlow.triggers,
  }

  return merchantSettingsFlow
}

export function parseResponseMerchantSettingsFlowActionFromApi( apiFlowAction: ApiResponseFlowAction ) {
  switch( apiFlowAction.type ) {
    case ResponseMerchantSettings.Flow.ActionType.FORWARD: {
      return {
        phoneNumber: apiFlowAction.phone_number,
        text: apiFlowAction.text,
        type: apiFlowAction.type,
      } as ResponseMerchantSettings.Flow.ActionForward
    }
    case ResponseMerchantSettings.Flow.ActionType.HANGUP: {
      return {
        type: apiFlowAction.type,
      } as ResponseMerchantSettings.Flow.ActionHangUp
    }
    case ResponseMerchantSettings.Flow.ActionType.PROMPT: {
      return {
        type: apiFlowAction.type,
      } as ResponseMerchantSettings.Flow.ActionPrompt
    }
    case ResponseMerchantSettings.Flow.ActionType.SEND_SMS: {
      return {
        type: apiFlowAction.type,
        answer: apiFlowAction.answer,
        text: apiFlowAction.text,
      } as ResponseMerchantSettings.Flow.ActionSendSms
    }
    default:
      utilHelpers.assertNever( apiFlowAction )
      throw new InternalError( `Unknown suggestion type: ${ apiFlowAction! }` )
  }
}

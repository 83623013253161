// https://github.com/kuy/redux-saga-chat-example/blob/master/src/client/sagas.js

import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

import type { DefaultThunkAction, ModuleStoreConfig } from "@onelocal/frontend/common"
import type { AccountService } from "../services/accountService"
import { accountService } from "../services/accountService"
import { employeeService } from "../services/employeeService"
import { sessionService } from "../services/sessionService"
import type { AuthAccount, AuthEmployee, AuthSession } from "../types"
import { authSaga } from "./authSagas"
import { authSelectors } from "./authSelectors"

const authStoreKey = "auth"

export interface AuthState {
  accounts: {
    byId: {
      [account_id: string]: AuthAccount
    }
  }
  currentAccount: AuthAccount | null
  currentSession: AuthSession | null
  employees: {
    byId: {
      [employeeId: string]: AuthEmployee
    }
  }
}

type AuthThunkAction<TReturnType> = DefaultThunkAction<{ auth: AuthState}, TReturnType>

export const authInitialState: AuthState = {
  accounts: {
    byId: {},
  },
  currentAccount: null,
  currentSession: null,
  employees: {
    byId: {},
  },
}

export const authSlice = createSlice( {
  name: authStoreKey,
  initialState: authInitialState,
  reducers: {
    updateAccounts: ( state, action: PayloadAction<AuthAccount[]> ) => {
      for( const account of action.payload ) {
        state.accounts.byId[ account.id ] = account
      }
    },
    updateEmployees: ( state, action: PayloadAction<AuthEmployee[]> ) => {
      const employees = action.payload
      for( const employee of employees ) {
        state.employees.byId[ employee.id ] = employee
      }
    },
    updateSession: ( state, action: PayloadAction<AuthSession | null> ) => {
      state.currentSession = action.payload
    },
  },
} )

export const authActions = {
  agreeReviewTerms(): AuthThunkAction<AuthSession> {
    return async ( dispatch ) => {
      const session = await sessionService.agreeReviewTerms()
      dispatch( authSlice.actions.updateSession( session ) )
      return session
    }
  },
  changePassword: ( merchantId: string, accountId: string, currentPassword: string, password: string ): AuthThunkAction<AuthAccount> => {
    return async ( dispatch, getState ) => {
      const state = getState()
      const session = authSelectors.session.current( state )
      await accountService.changePassword( merchantId, accountId, currentPassword, password )
      const account = await accountService.getAccount( session! )
      dispatch( authSlice.actions.updateAccounts( [ account ] ) )
      return account
    }
  },
  createSession: ( username: string, password: string ): AuthThunkAction<AuthSession> => {
    return async ( dispatch ) => {
      const session = await sessionService.createSession( username, password )

      dispatch( authSlice.actions.updateSession( session ) )
      return session
    }
  },
  getAccounts: ( merchantId: string ): AuthThunkAction<AuthAccount[] | null> => {
    return async ( dispatch ) => {
      const accounts = await accountService.getAccounts( merchantId )
      dispatch( authSlice.actions.updateAccounts( accounts ) )
      return accounts
    }
  },
  getCurrentAccount: (): AuthThunkAction<AuthAccount | null> => {
    return async ( dispatch, getState ) => {
      const state = getState()
      const session = authSelectors.session.current( state )

      if( ! session || session.isGataEmployee ) {
        return null
      }

      const account = await accountService.getAccount( session )
      dispatch( authSlice.actions.updateAccounts( [ account ] ) )
      return account
    }
  },
  getCurrentSession: (): AuthThunkAction<AuthSession | null> => {
    return async ( dispatch, getState ) => {
      const state = getState()
      let session = authSelectors.session.current( state )
      session = await sessionService.refreshSession()
      dispatch( authSlice.actions.updateSession( session ) )
      return session
    }
  },
  queryEmployee( merchantId: string ): AuthThunkAction<AuthEmployee[]> {
    return async ( dispatch ) => {
      const employees = await employeeService.query( merchantId )
      dispatch( authSlice.actions.updateEmployees( employees ) )
      return employees
    }
  },
  removeSession: (): AuthThunkAction<void> => {
    return async ( dispatch ) => {
      await sessionService.removeSession()
      dispatch( authSlice.actions.updateSession( null ) )
    }
  },
  setAccountPassword: ( merchantId: string, accountId: string, password: string ): AuthThunkAction<AuthAccount> => {
    return async ( dispatch, getState ) => {
      const state = getState()
      const session = authSelectors.session.current( state )
      await accountService.setPassword( password )
      const account = await accountService.getAccount( session! )
      dispatch( authSlice.actions.updateAccounts( [ account ] ) )
      return account
    }
  },
  updateAccount: ( merchantId: string, accountId: string, model: AccountService.UpdateModel ): AuthThunkAction<AuthAccount> => {
    return async ( dispatch, getState ) => {
      const state = getState()
      const session = authSelectors.session.current( state )
      await accountService.updateAccount( merchantId, accountId, model )
      const account = await accountService.getAccount( session! )
      dispatch( authSlice.actions.updateAccounts( [ account ] ) )
      return account
    }
  },
}

export type AuthStateSelector<TRootState> = [
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ( state: TRootState, ...params: any[] ) => AuthState
];

export const authStoreConfig: ModuleStoreConfig<AuthState> = {
  key: authStoreKey,
  initialState: authInitialState,
  reducers: authSlice.reducer,
  saga: authSaga,
}

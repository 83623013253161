import type { MessagesMessage } from "@onelocal/frontend-dashboard/connect"
import type { ContentBlogPost, ContentSocialPostSuggestionBeforeAfterPhotos, ContentSocialPostSuggestionBlogPost, ContentSocialPostSuggestionBulkMessage, ContentSocialPostSuggestionPhotos, ContentSocialPostSuggestionReview } from "@onelocal/frontend-dashboard/content"
import type { ReviewsReview } from "@onelocal/frontend-dashboard/reviews"
import type { Name } from "@onelocal/shared/common"

export interface MainActivityBase {
  id: string
  approval?: MainActivity.ApprovalStatus
  archived: MainActivity.AccountRef | null
  assignedAccount: MainActivity.AccountRef | null
  date: string
  dismissedBy: MainActivity.UserIdToDate | null
  categories: MainActivity.Category[]
  merchantId: string
  readBy: MainActivity.UserIdToDate | null
  status: MainActivity.Status
  type: MainActivity.Type
}

export interface MainActivityConversation extends MainActivityBase {
  callIds: string[]
  conversationId: string
  customer: MainActivity.Customer
  lastMessage?: MainActivity.Message | null
  messageIds: string[]
  stats: {
    missedCalls: number
    unreadMessages: number
  }
  type: MainActivity.Type.CONVERSATION
}

export interface MainActivityKeywordRankingBlog extends MainActivityBase {
  blogPost: {
    id: string
    keyword: string
    publishDate?: string | null
    status: ContentBlogPost.Status
  }
  blogPostId: string
  type: MainActivity.Type.KEYWORD_RANKING_BLOG
}

export interface MainActivityReview extends MainActivityBase {
  customer: MainActivity.Customer
  reviewId: string
  review: {
    comment: string
    rating: number
    source: ReviewsReview.Source
  }
  type: MainActivity.Type.REVIEW
}

export interface MainActivityShareBeforeAfterPhotos extends MainActivityBase {
  socialPostSuggestion: ContentSocialPostSuggestionBeforeAfterPhotos
  socialPostSuggestionId: string
  type: MainActivity.Type.SHARE_BEFORE_AFTER_PHOTOS
}

export interface MainActivityShareBlogPost extends MainActivityBase {
  blogPostId: string
  socialPostSuggestions: ContentSocialPostSuggestionBlogPost[]
  socialPostSuggestionIds: string[]
  type: MainActivity.Type.SHARE_BLOG_POST
}

export interface MainActivityShareBulkMessage extends MainActivityBase {
  bulkMessageId: string
  socialPostSuggestion: ContentSocialPostSuggestionBulkMessage
  socialPostSuggestionId: string
  type: MainActivity.Type.SHARE_BULK_MESSAGE
}

export interface MainActivityShareReview extends MainActivityBase {
  reviewId: string
  socialPostSuggestion: ContentSocialPostSuggestionReview
  socialPostSuggestionId: string
  type: MainActivity.Type.SHARE_REVIEW
}

export interface MainActivitySharePhotos extends MainActivityBase {
  socialPostSuggestion: ContentSocialPostSuggestionPhotos
  socialPostSuggestionId: string
  type: MainActivity.Type.SHARE_PHOTOS
}

export interface MainActivityTask extends MainActivityBase {
  task: MainTask
  type: MainActivity.Type.TASK
}

export type MainActivityDefault = (
  MainActivityKeywordRankingBlog
  | MainActivityShareBeforeAfterPhotos
  | MainActivityShareBlogPost
  | MainActivityShareBulkMessage
  | MainActivitySharePhotos
  | MainActivityShareReview
)

export type MainActivity = (
  MainActivityConversation
  | MainActivityKeywordRankingBlog
  | MainActivityReview
  | MainActivityShareBeforeAfterPhotos
  | MainActivityShareBlogPost
  | MainActivityShareBulkMessage
  | MainActivitySharePhotos
  | MainActivityShareReview
  | MainActivityTask
)

export namespace MainActivity {
  export interface AccountRef extends Name {
    accountId: string
    date: string
  }

  export enum ActionType {
    ARCHIVE = "archive",
    DELETE = "delete",
    DISMISS = "dismiss",
    RESTORE = "restore",
    RESTORE_DELETED = "restore_deleted",
    RESTORE_FROM_DISMISS = "restoreFromDismiss",
  }

  export const enum ApprovalStatus {
    APPROVED = "approved",
    PENDING = "pending",
    REJECTED = "rejected",
    NOT_REQUIRED = "not_required",
  }

  export const enum Category {
    MESSAGE = "message",
    MISSED_CALL = "missed_call",
    REVIEW = "review",
    TASK = "task",
  }

  export const CategoryLabels = {
    [ Category.MESSAGE ]: "Message",
    [ Category.MISSED_CALL ]: "Missed Call",
    [ Category.REVIEW ]: "Review",
    [ Category.TASK ]: "Task",
  }

  export const CategoryList = [
    Category.MESSAGE,
    Category.MISSED_CALL,
    Category.REVIEW,
  ]

  export interface Customer {
    id?: string
    name?: string
    phoneNumber?: string
  }

  export type ImageSuggestion = (
    ImageSuggestionCustom
    | ImageSuggestionShutterstock
  )

  export namespace ImageSuggestion {
    export const enum Type {
      CUSTOM = "custom",
      SHUTTERSTOCK = "shutterstock",
    }
  }

  export interface ImageSuggestionBase {
    id: string
    tags?: string[]
    type: ImageSuggestion.Type
    url: {
      small: string
      large: string
    }
  }

  export interface ImageSuggestionCustom extends ImageSuggestionBase {
    type: ImageSuggestion.Type.CUSTOM
  }

  export interface ImageSuggestionShutterstock extends ImageSuggestionBase {
    shutterstock: {
      id: string
    }
    type: ImageSuggestion.Type.SHUTTERSTOCK
  }

  export interface ImageUploaded {
    id: string
    filename: string
    originalUrl: string
    tags?: string[]
  }

  export interface Message {
    id: string
    date: string
    text: string
    type: MessagesMessage.Type
  }

  export interface Stats {
    all: number
    assignments: number
    message: number
    missedCall: number
    review: number
    task: number
  }

  export const enum Status {
      ARCHIVE = "archive",
      INBOX = "inbox",
      /**
       * Some of the activity feed item need some processing in order to be fully generated.
       * During this processing period the activity item should not be shown.
       */
      PROCESSING = "processing",
  }

  export const StatusLabel = {
    [ Status.ARCHIVE ]: "Done",
    [ Status.INBOX ]: "Not Done",
    [ Status.PROCESSING ]: "Processing",
  }

  export const enum Type {
    CONVERSATION = "conversation",
    KEYWORD_RANKING_BLOG = "keyword_ranking_blog",
    REVIEW = "review",
    SHARE_BEFORE_AFTER_PHOTOS = "share_before_after_photos",
    SHARE_BLOG_POST = "share_blog_post",
    SHARE_BULK_MESSAGE = "share_bulk_message",
    SHARE_REVIEW = "share_review",
    SHARE_PHOTOS = "share_photos",
    TASK = "task",
  }

  export const TypeLabel = {
    [ Type.CONVERSATION ]: "Conversation",
    [ Type.KEYWORD_RANKING_BLOG ]: "Keyword Ranking Blog",
    [ Type.REVIEW ]: "Review",
    [ Type.SHARE_BEFORE_AFTER_PHOTOS ]: "Share Before After Photos",
    [ Type.SHARE_BLOG_POST ]: "Share Blog Post",
    [ Type.SHARE_BULK_MESSAGE ]: "Share Bulk Message",
    [ Type.SHARE_REVIEW ]: "Share Review",
    [ Type.SHARE_PHOTOS ]: "Share Photos",
    [ Type.TASK ]: "Task",
  }

  export interface UserIdToDate {
    [ accountId: string ]: string
  }
}

export enum MainActivitiesFilters {
  ALL = MainActivity.Status.INBOX,
  ASSIGNMENTS = "assigned_to_me",
  DONE = MainActivity.Status.ARCHIVE,
  MESSAGE = MainActivity.Category.MESSAGE,
  MISSED_CALL = MainActivity.Category.MISSED_CALL,
  REVIEW = MainActivity.Category.REVIEW,
  TASK = MainActivity.Category.TASK,
}

export const MainActivitiesFilterLabels = {
  [ MainActivitiesFilters.ALL ]: "All",
  [ MainActivitiesFilters.ASSIGNMENTS ]: "Assignments",
  [ MainActivitiesFilters.DONE ]: "Done",
  [ MainActivitiesFilters.MESSAGE ]: "Messages",
  [ MainActivitiesFilters.MISSED_CALL ]: "Missed Calls",
  [ MainActivitiesFilters.REVIEW ]: "Reviews",
  [ MainActivitiesFilters.TASK ]: "Tasks",
}

export interface MainPerformanceInsight {
  googleBusinessProfileEvents: MainPerformanceInsight.GoogleBusinessProfileEvents
  lead: MainPerformanceInsight.Lead
  organicSearch: MainPerformanceInsight.OrganicSearch
  paidAd: MainPerformanceInsight.PaidAd
  review: MainPerformanceInsight.Review
  site: MainPerformanceInsight.Site
  summary: MainPerformanceInsight.Summary
}

export namespace MainPerformanceInsight {
  export interface GoogleBusinessProfileEvents {
    delta: {
      trafficRate: number | null
    }
    historical: {
     interactions: HistoricalData[]
    }
    total: {
      all: number
      callClicks: number
      conversations: number
      other: number
      websiteClicks: number
    }
  }

  export interface HistoricalData {
    date: string
    value: number
  }

  export interface Lead {
    delta: {
      totalRate: number | null
    }
    total: {
      all: number
      assistantConversationVoicemail: number
      referral: number
      visit: number
      websiteCallLinkClick: number
      websiteChatMessage: number
      websiteFormSubmission: number
      websiteSMSLinkClick: number
    }
  }

  export interface OrganicSearch {
    delta: {
      conversionsRate: number | null
      trafficRate: number | null
    }
    historical: {
      conversions: HistoricalData[]
      traffic: HistoricalData[]
    }
    total: {
      conversions: number
      traffic: number
    }
  }

  export interface PaidAd {
    delta: {
      conversionsRate: number | null
      trafficRate: number | null
    }
    historical: {
      conversions: HistoricalData[]
      traffic: HistoricalData[]
    }
    total: {
      conversions: number
      traffic: number
    }
  }

  export enum Period {
    LAST_7_DAYS = "last_7_days",
    LAST_30_DAYS = "last_30_days",
    LAST_60_DAYS = "last_60_days",
    LAST_90_DAYS = "last_90_days",
  }

  export interface Review {
    averageRating: number
    delta: {
      totalRate: number | null
    }
    total: {
      all: number
      facebook: number
      google: number
      reviewedge: number
      yellowPages: number
      yelp: number
    }
  }

  export interface Site {
    delta: {
      totalTrafficRate: number | null
    }
    historical: {
      conversions: HistoricalData[]
      traffic: HistoricalData[]
    }
    total: {
      conversions: SiteSourceStats
      traffic: SiteSourceStats
    }
  }

  export interface SiteSourceStats {
    all: number
    direct: number
    organicSearch: number
    paidAds: number
    referral: number
    social: number
  }

  export interface Summary {
    lead?: {
      averageResponseTime: number
      leadChannel: string
      topSource: string
      total: number
    }
    review?: {
      averageRating: number
      topSource: string
      total: number
    }
  }

  export const enum Source {
    GOOGLE_BUSINESS_PROFILE_EVENT = "google_business_profile_event",
    LEAD = "lead",
    ORGANIC_SEARCH = "organic",
    PAID_AD = "paid_ad",
    REVIEW = "review",
    SITE = "site",
  }
}

export interface MainTaskBase {
  category: MainTask.Category
  created: {
    at: string
    by: {
      ref: "accounts" | "internal_accounts" | "merchants"
      id: string
    }
  }
  description: string
  id: string
  merchant_id: string
  title: string
  type: MainTask.Type
}

export interface MainTaskInformation extends MainTaskBase {
  attachments?: Array<{
    contentType: string
    filename: string
    originalFilename: string
    url: string
  }>
  subject: string
  type: MainTask.Type.INFORMATION
}

export interface MainTaskTicket extends MainTaskBase {
  changeType?: MainTask.ChangeType
  initialMessage?: string
  intercom?: {
    accountId: string
    contactId: string
    conversationId: string
  }
  jira: {
    id: string
    key: string
  }
  status: {
    at: string
    details?: string
    labels: MainTask.Labels
    logs: MainTask.StatusLog[]
    type: MainTask.StatusType
  }
  type: MainTask.Type.TICKET
}

export type MainTask = (
  MainTaskInformation
  | MainTaskTicket
)

export namespace MainTask {
  export const enum Category {
    ADS = "ads",
    CLIENT_MANAGEMENT = "client_management",
    ONBOARDING= "onboarding",
    SEO = "seo",
    SITE = "site",
    SOCIAL = "social",
  }

  export const enum ChangeType {
    CUSTOM_CHANGE = "custom",
    OPTIMIZATION = "optimization",
  }

  export interface Labels {
    admin: string
    customer?: string
    jira: string
  }

  export const enum StatusType {
    BLOCKED = "blocked",
    CLIENT_FEEDBACK_SUPPLIED = "client_feedback_supplied",
    CREDENTIALS_REQUIRED = "credentials_required",
    DONE = "done",
    DONE_HIDDEN = "done_hidden",
    FEEDBACK_REQUIRED = "feedback_required",
    IN_PROGRESS = "in_progress",
    IN_PROGRESS_HIDDEN = "in_progress_hidden",
    NOT_REQUIRED = "not_required",
    READY_FOR_CLIENT_REVIEW = "ready_for_client_review",
    READY_FOR_DEVELOPMENT = "ready_for_development",
    WAITING_FOR_SUPPORT = "waiting_for_support",
    WAITING_TO_START = "waiting_to_start",
  }

  export interface StatusLog {
    at: string
    details?: string
    type: StatusType
  }

  export const enum Type {
    INFORMATION = "information",
    TICKET = "ticket",
  }

  export enum IntercomWorkflowType {
    ADS_MODIFY_CAMPAIGN = "ads_modify_campaign",
    ADS_START_NEW_CAMPAIGN = "ads_start_new_campaign",
    BLOG_MANAGE = "blog_manage",
    GBP_EDIT_ADDRESS = "gbp_edit_address",
    GBP_EDIT_SOCIAL_LINKS = "gbp_edit_social_links",
    GBP_PHONE_NUMBER = "gbp_phone_number",
    GBP_SERVICE_AREA = "gbp_service_area",
    GBP_SERVICE_HOURS = "gbp_service_hours",
    HELP_CENTER_EDIT_MY_SITE = "help_center_edit_my_site",
    HELP_CENTER_GBP_EDIT = "help_center_gbp_edit",
    ONBOARDING = "onboarding",
    SEND_A_REQUEST = "send_a_request",
    SITE_ADD_NEW_IFRAME = "site_add_new_iframe",
    SITE_ADD_NEW_PAGE = "site_add_new_page",
    SITE_ADD_PROMOS = "site_add_promos",
    SITE_EDIT_A_PAGE = "site_edit_a_page",
    SITE_MANAGE_FAQS = "site_manage_faqs",
    SITE_MANAGE_JOB_POSTINGS = "site_manage_job_postings",
    SITE_REMOVE_A_PAGE = "site_remove_a_page",
    SITE_TEAM_MEMBERS = "site_team_members",
    SITE_UPDATE_FORMS = "site_update_forms",
    SITE_UPDATE_IMAGES = "site_update_images",
    SOCIAL_MANAGE = "social_manage",
  }
}
